import React, { Component } from 'react';
import AuthContext from '../../../context/auth-context';

class NetworkingPersonDetail extends Component {
    
    static contextType = AuthContext;
    
    render() {

        const photo = this.props.photo !== undefined && this.props.photo !== "" 
            ? <img alt="" src={this.props.photo} />
            : <img alt="" src="/img/default-login-pic.png" />
        

        return (
            
            <div className="networking-right-side-panel-container">
                <div className="person-detail-magnifier-container">
                    <img src="/img/person-detail-magnifier-icon.png" />
                </div>
                <div className="person-detail-container">
                    <div className="person-detail-photo-container">
                        {photo}
                    </div>
                    <div className="person-detail-name-institution">
                    <strong>{this.props.name}</strong><br />
                        {this.props.me}
                        {this.props.company}
                    </div>
                    {this.props.description}
                </div>

                {this.context.id !== this.props.id ?
                    <div className="person-detail-bottom-chat-contact">
                        

                        {/* <div className="person-detail-bottom-chat-send-message-container">
                            <img src="/img/person-detail-bottom-chat-icon-send-message.png" />
                            Enviar mensaje
                        </div> */}
                        <div className="person-detail-bottom-chat-opt-right" onClick={() => this.props.onVoice(this.props.id)}>
                            <img src="/img/person-detail-bottom-chat-icon-phone.png" />
                        </div>
                        <div className="person-detail-bottom-chat-opt-right-separator"></div>
                        <div className="person-detail-bottom-chat-opt-right" onClick={() => this.props.onVideo(this.props.id)}>
                            <img src="/img/person-detail-bottom-chat-icon-video.png" />
                        </div>
                        {/* <div className="person-detail-bottom-chat-opt-right-separator"></div>
                        <div className="person-detail-bottom-chat-opt-right">
                            <img src="/img/person-detail-bottom-chat-icon-group.png" />
                        </div>
                        <div className="person-detail-bottom-chat-opt-right-separator"></div>
                        <div className="person-detail-bottom-chat-opt-right">
                            <img src="/img/person-detail-bottom-chat-icon-add-person.png" />
                        </div> */}
                    </div>
                : null}
                
                <div className="person-detail-magnifier-container cursor-only" onClick={this.props.closeDetail}>
                    <img src="/img/btn-close-badge.png" />
                </div>
            </div>

        );
    }
}

export default NetworkingPersonDetail;