import React from 'react';

function ChatEntry(props) {
    
    const image = props.pic !== undefined && props.pic !== "" ? props.pic : "/img/default-login-pic.png";
    const classEntry = !props.approved ? "econgress-room-chat-text peding-approval" : "econgress-room-chat-text"
    return (
        <React.Fragment>
        <div className="econgress-room-chat-avatar">
            <img alt="" src="/img/default-login-pic.png" />
        </div>
        <div className={classEntry}>
            <span className="econgress-room-chat-text-person-name"><strong>{props.name}</strong> <br /></span>
            {props.text}
            {!props.approved && props.isModerator
            ? <React.Fragment>
                <br></br>
                <span className="cursor-only" onClick={() => props.approveHandler(props.id)}><i><u>aprobar</u></i></span>
            </React.Fragment>
            : null
            }
        </div>
        <div style={{clear:`both`}}></div>
        <div className="econgress-room-chat-separator"></div>
        </React.Fragment>
    );
}

export default ChatEntry;