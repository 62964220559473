import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../../../../context/auth-context'
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class HospitalitySchedule extends Component {

    state = {

        HospitalityScheduleOpen: true

    }

    static contextType = AuthContext;


    render() {



        const HospitalitySchedule = this.state.HospitalityScheduleOpen ? (

            <div className="hospitality-schedule-container">
                <div className="hospitality-schedule-container-wrapper">

                    <div className="hospitality-schedule-details">

                        <div className="hospitality-schedule-details-title-container">

                            <div className="hospitality-schedule-details-title-icon">
                                <img src="/img/hospitality-icon.png" />
                            </div>

                            <div className="hospitality-schedule-details-title-text">
                                Hospitality<br />Suite
                            </div>

                        </div>


                        <div className="hospitality-schedule-details-form-container">


                            <form>

                                <div className="hospitality-form-input-container">

                                    <div className="hospitality-form-input-label">
                                        <strong>Theme</strong>
                                    </div>

                                    <div className="hospitality-form-input">
                                        <input type="text" id="Theme" name="Theme" />
                                    </div>

                                </div>


                                <div className="hospitality-form-input-container-wrapper">


                                    <div className="hospitality-form-input-container date">

                                        <div className="hospitality-form-input-label">
                                            <strong>Date</strong>
                                        </div>

                                        <div className="hospitality-form-input">

                                            <select name="date" id="date">
                                                <option value="1">Saturday, 25 June, 2021</option>
                                                <option value="2">Saturday, 25 June, 2021</option>
                                                <option value="3">Saturday, 25 June, 2021</option>
                                                <option value="4">Saturday, 25 June, 2021</option>
                                            </select>

                                        </div>

                                    </div>

                                    <div className="hospitality-form-input-container start">

                                        <div className="hospitality-form-input-label">
                                            <strong>Start</strong>
                                        </div>

                                        <div className="hospitality-form-input">

                                            <select name="date" id="date">
                                                <option value="1">11h00</option>
                                                <option value="2">11h00</option>
                                                <option value="3">11h00</option>
                                                <option value="4">11h00</option>
                                            </select>

                                        </div>

                                    </div>

                                    <div className="hospitality-form-input-container duration">

                                        <div className="hospitality-form-input-label">
                                            <strong>Duration</strong>
                                        </div>


                                        <div className="hospitality-form-input">

                                            <select name="duration" id="duration">
                                                <option value="1">2h30</option>
                                                <option value="2">2h30</option>
                                                <option value="3">2h30</option>
                                                <option value="4">2h30</option>
                                            </select>

                                        </div>

                                    </div>

                                    <div className="hospitality-form-input-container zone">

                                        <div className="hospitality-form-input-label">
                                            <strong>Zone</strong>
                                        </div>

                                        <div className="hospitality-form-input">

                                            <select name="timezone" id="timezone">
                                                <option value="1">UTC +4</option>
                                                <option value="2">UTC +4</option>
                                                <option value="3">UTC +4</option>
                                                <option value="4">UTC +4</option>
                                            </select>

                                        </div>

                                    </div>


                                </div>


                                <div className="hospitality-form-input-container">

                                    <div className="hospitality-form-input-label">
                                        <strong>Security</strong>
                                    </div>

                                    <input type="radio" id="Password" name="Security" value="" />
                                    <label for="Password">Password</label>  <input className="hospitality-form-password" type="password" id="password" name="password" /><br />
                                    <div className="hospitality-form-input-desc">Only users who have invite link or access code can join the meeting</div>

                                    <input type="radio" id="Waiting-Room" name="Security" value="" />
                                    <label for="Waiting-Room">Waiting Room</label><br />
                                    <div className="hospitality-form-input-desc">Only users invited by the host can join the meeting</div>

                                    <input type="radio" id="other" name="Security" value="other" />
                                    <label for="other">Only authenticated users can join</label>

                                </div>


                                <div className="hospitality-form-input-container">

                                    <div className="hospitality-form-input-label">
                                        <strong>Video</strong>
                                    </div>

                                    <div className="hospitality-form-input-container-wrapper">

                                        <div className="hospitality-form-input-container host-options">

                                            <div className="hospitality-form-input-label">
                                                Host
                                            </div>

                                            <input className="host-option" type="radio" id="host-on" name="host" value="" />
                                            <label className="host-option" for="host-on">On</label>

                                            <input className="host-option" type="radio" id="host-off" name="host" value="" />
                                            <label className="host-option" for="host-off">Off</label>

                                        </div>


                                        <div className="hospitality-form-input-container host-options">

                                            <div className="hospitality-form-input-label">
                                                Participants
                                            </div>

                                            <input className="host-option" type="radio" id="host-on" name="Participants" value="" />
                                            <label className="host-option" for="host-on">On</label>

                                            <input className="host-option" type="radio" id="host-off" name="Participants" value="" />
                                            <label className="host-option" for="host-off">Off</label>

                                        </div>


                                    </div>


                                    <div className="hospitality-form-input-container-wrapper">

                                        <div className="hospitality-form-input-container">

                                            <div className="hospitality-form-input-label">
                                                <strong>Audio</strong>
                                            </div>

                                            <input className="host-option" type="radio" id="host-on" name="audio" value="" />
                                            <label className="host-option" for="host-on">Phone</label>

                                            <input className="host-option" type="radio" id="host-off" name="audio" value="" />
                                            <label className="host-option" for="host-off">Computer</label>

                                            <input className="host-option" type="radio" id="host-off" name="audio" value="" />
                                            <label className="host-option" for="host-off">Phone+Computer</label>

                                        </div>




                                    </div>

                                </div>


                                <div className="hospitality-form-input-container">

                                    <div className="hospitality-form-input-label">
                                        <strong>Advanced Options</strong>
                                    </div>



                                    <input type="radio" id="Waiting-Room" name="Advanced-Options" value="" />
                                    <label for="Waiting-Room">Allow participants to join at any time</label><br />


                                    <input type="radio" id="Waiting-Room" name="Advanced-Options" value="" />
                                    <label for="Waiting-Room">Mute particioants when entering</label><br />

                                    <input type="radio" id="Password" name="Advanced-Options" value="" />
                                    <label for="Password">Alternate Host</label>  <input className="hospitality-form-password" type="text" id="alternate-host" name="alternate-host" /><br />


                                </div>

                                <p>&nbsp;</p>

                                <div className="hospitality-form-input-container">

                                    <div className="hospitality-form-delete-meeting-container">

                                        <div className="hospitality-form-btn delete">
                                            DELETE MEETING
                                        </div>

                                    </div>

                                    <div className="hospitality-form-btns-meeting-container">

                                        <div className="hospitality-form-btn">
                                            CANCEL
                                        </div>

                                        <div className="hospitality-form-btn green">
                                            RECORD
                                        </div>



                                    </div>

                                </div>


                            </form>

                        </div>


                    </div>


                    <div className="hospitality-form-separator"></div>


                    <div className="hospitality-schedule-persons">

                        <div className="hospitality-schedule-details-title-container">

                            <div className="hospitality-schedule-details-title-text persons">
                                Schedule Meeting
                            </div>

                            <div className="hospitality-schedule-details-title-icon persons">
                                <img src="/img/calendar-meeting-icon.png" />
                            </div>



                        </div>


                        <div className="hospitality-persons-tab-list-container">

                            <div className="hospitality-persons-tab">
                                Participants
                            </div>

                            <div className="hospitality-persons-tab selected">
                                Add participants
                            </div>

                            <div className="hospitality-persons-count">
                                015 _ <img src="/img/meeting-settings-persons-icon.png" />
                            </div>

                        </div>



                        <span className="tab-add-participants" style={{ display: "none" }}>

                            <div className="hospitality-search-persons-container">

                                <div className="hospitality-search-persons-search-icon">
                                    <img src="/img/hospitality-search-persons-icon.png" />
                                </div>
                                <div className="hospitality-search-persons-search-input">
                                    <input className="search-persons" type="text" id="search-persons" name="search-persons" placeholder="Search name or metadata" />
                                </div>

                            </div>



                            <div className="hospitality-persons-list-container">

                                <table className="tbl-hospitality-persons-list">
                                    <tr>
                                        <td className="check">&nbsp;</td>
                                        <td className="letter-separator letter" colSpan="2">A</td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                            <div className="invited">INVITED</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check">&nbsp;</td>
                                        <td className="letter-separator letter" colSpan="2">B</td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check">&nbsp;</td>
                                        <td className="letter-separator letter" colSpan="2">C</td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>

                                </table>


                            </div>


                            <div className="hospitality-persons-select-all-container">

                                <input type="checkbox" id="person1" name="person1" value="person1" /> Select all
                            </div>


                            <div className="hospitality-form-btns-meeting-container">
                                <div class="hospitality-form-btn border-green">INVITE</div>
                            </div>

                        </span>








                        <span className="tab-participants">





                            <div className="hospitality-persons-list-container">

                                <table className="tbl-hospitality-persons-list">

                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" checked /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                            <div className="invited">INVITED</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" checked /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check"> <input type="checkbox" id="person1" name="person1" value="person1" /></td>
                                        <td className="pic">
                                            <img src="/img/chat-person-pic1.png" />
                                        </td>
                                        <td className="name">
                                            <strong>Alberto Garcia</strong><br />
                                            <span className="person-department">Jefe Cardiologia Hospital del Mar</span>
                                        </td>
                                    </tr>

                                </table>


                            </div>


                            <div className="hospitality-persons-select-all-container">

                                <input type="checkbox" id="person1" name="person1" value="person1" /> Select all
                            </div>


                            <div className="hospitality-form-btns-meeting-container">
                                <div class="hospitality-form-btn border-red">REMOVE</div>
                            </div>

                        </span>





                    </div>


                </div>
            </div>

        )
            : null

        return (
            <React.Fragment>
                {HospitalitySchedule}
            </React.Fragment>
        );
    }
}

export default withTranslation()(HospitalitySchedule);