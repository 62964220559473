
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Session extends Component {
    
    state = {showTitle : true}

    showTitleHandler = () => {
        /* this.setState({showTitle: true}); */
    }
    hideTitleHandler = () => {
        /* this.setState({showTitle: false}); */
    }

    render() {

        const classSession = this.props.currSessionId === this.props.id  ? "stream-preview-room-schedule-container selected" : "stream-preview-room-schedule-container"

        return (            
            <div key={this.props.id} onClick={() => this.props.changeSession({roomId:this.props.roomId,sessionId:this.props.id})} 
            onMouseEnter={this.showTitleHandler}  
            onMouseLeave={this.hideTitleHandler} 
            className={classSession} 
            style={{backgroundImage:this.props.image}}>
                {this.props.live ? 
                    <div className="stream-preview-room-now-container">LIVE</div> 
                    : <div className="stream-preview-room-time-container">{this.props.time}</div>
                }
                {this.props.upcoming ? <div className="stream-preview-room-backdrop"></div> : null}
                {this.state.showTitle ?
                    <div className="stream-preview-room-title-container">
                        {this.props.title}
                        <div className="stream-preview-more-container">
                            <img alt="" src="/img/stream-preview-more-icon.png" />
                        </div>
                    </div>
                : null}
            </div>
        );
    }
}

export default Session;