import React, { Component } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken'
import AuthContext from "../../context/auth-context";
import { withTranslation } from 'react-i18next';
import configData from '../../config.json';

class SendMessage extends Component { 
    
    state = {
        name:"",
        email:"",
        photo:"",
        subject:"",
        message:""        
    }

    static contextType = AuthContext;

    onChangeSubjectHandler = (e) => {        
        this.setState({ subject: e.target.value });        
      }

    onChangeMessagetHandler = (e) => {
        this.setState({ message: e.target.value });
      }

    
    onSendMessageHandler = () => {
        let config = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            }};
        let params = { 
                "sender_id" : this.props.senderId, 
                "sender_type" : this.props.senderType, 
                "receiver_id": this.props.receiverId,                
                "receiver_type": this.props.receiverType,
                "receiver_email": this.props.receiverEmail,
                "event_id" : this.props.event_id,
                "object_id" : this.props.objectId !== undefined ? this.props.objectId : "",
                "object_type" : this.props.objectType !== undefined ? this.props.objectType : "",
                "name": this.state.name,
                "email": this.state.email,
                "subject" : this.state.subject, 
                "message": this.state.message,
            };

        
        axios.post(configData.SERVER_API + "/messages/" , params, config)
            .then(response => {
                this.props.close();
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {

        let data = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            },
            params: {_id: this.context._id}
          };

        axios.get(configData.SERVER_API + "/users/me" ,data)
            .then(response => {
                const name =  response.data.name !== undefined && response.data.name !== "" ? response.data.name : ""
                const surname =  response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : ""
                const photo =  response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png"
                const email = response.data.email;

                this.setState({name: name + '' + surname, email:email, photo:photo})
              })
            .catch(error => {
                console.log(error.message);
              })
    }

    render() {
        return (
            <div className="backdrop-eposter-detail">
                <div className="eposter-detail-send-message-container">

                    <div className="eposter-detail-send-message-header-container">
                        <div className="eposter-detail-send-message-close-icon-container">
                            <img onClick={this.props.close} alt="" src="/img/icon-close.png" />
                        </div>
                        {this.props.t('message')}
                    </div>

                    <div className="eposter-detail-send-message-area-container">

                        <div className="eposter-detail-send-message-persons-sub-header-container">

                            <div className="eposter-detail-send-message-attendee-wrapper">
                                <div className="eposter-detail-send-message-avatar">
                                    <img alt="" src="/img/default-login-pic.png" />
                                </div>
                                <div className="eposter-detail-send-message-person-name">
                                    <strong>{this.state.name}</strong>
                                    <br />{this.state.email}
                                    <br />{this.state.company}
                                </div>
                            </div>

                            {/* <div className="eposter-detail-message-arrow-container">
                                <img alt="" src="/img/eposter-detail-message-arrow-icon.png" />
                            </div> */}

                            {/* <div className="eposter-detail-send-message-poster-manager-wrapper">
                                <div className="eposter-detail-send-message-person-name">
                                    <strong>POSTER MANAGER</strong><br />Hospital/Company
                                </div>
                                <div className="eposter-detail-send-message-avatar">
                                    <img alt="" src="/img/chat-person-pic1.png" />
                                </div>
                            </div> */}
                            
                        </div>


                        <div className="eposter-detail-message-subject-container">
                            <input type="text" id="subject" onChange={this.onChangeSubjectHandler} name="subject" placeholder={this.props.t('subject')} />
                        </div>

                        <div className="eposter-detail-message-textarea-container">

                            <textarea id="message" name="message" onChange={this.onChangeMessagetHandler}></textarea>
                        
                        </div>


                    </div>

                    <div className="eposter-detail-send-message-footer-container">

                        <div className="eposter-detail-send-message-btn-wrapper" onClick={this.onSendMessageHandler}>
                        {this.props.t('send')} <img alt="" src="/img/eposter-detail-send-msg-icon.png" />
                        </div>

                    </div>

                </div>


            </div>
        );
    }
}

export default withTranslation()(SendMessage);