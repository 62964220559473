import React, { Component } from 'react';
import Message from './Message';
import axios from 'axios';
import AuthContext from '../../../../context/auth-context'
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class MessagesList extends Component {
    
    state = {
        total: 0,
        open:true,
        new: false,
        messages: []
    }

    static contextType = AuthContext;

    onDisplayAllMessages = () =>{
        this.setState({new:false});
    }

    onDisplayNewMessages = () =>{
        this.setState({new:true});
    }

    onShowHideHandler = () =>{
        this.setState((prevState, props) => {
            return {                
                open: !prevState.open
                };
        })    
    }

    onMessageReadHandler = (id) =>{
        const message = this.state.messages.filter((el) => el.id === id );
        message[0].new = false;
        const messages = this.state.messages.filter((el) => el.id !== id );
        const messagesAll = messages.concat(message)
        this.setState({message: messagesAll});
    }

    componentDidMount(){
        this.loadMessage(true);    
        setInterval(() => {
            this.loadMessage(false);    
        }, 60000);
    }

    loadMessage = (initial) => {

        // get the list of delegates for the company
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/stands/" + this.props.standId + "/messages/", data)        
        .then(response => {

            let openClose;
            if (initial) {
                openClose = response.data.length > 0  ? true : false
            } else {
                openClose = this.state.open;
            }
            if (response.data !== undefined && response.data.length > 0 ){
                const total = response.data.length;
                this.setState({
                    total: total,
                    messages : response.data,
                    open: openClose
                })
            } else {
                this.setState({
                    total: 0,                        
                    open: false
                })
            }
        })
        .catch(error => {
            console.log(error.message);
        })

    }

    render() {

        let listOfMessages;
        listOfMessages = this.state.messages.filter(el => {
                if(this.state.new) {
                    return el.new;
                } else {
                    return el;
                }
            }).map((item,index) => {
            return ( <Message key={item.messages._id} 
                id={item.users.id}
                name={item.users.name + ' ' + item.users.surname} 
                email={item.users.email}
                photo={item.users.photo} 
                title={item.messages.subject} 
                message={item.messages.message} 
                time={item.messages.date} 
                new={item.new}
                onRead={() => this.onMessageReadHandler(item.id)}></Message>)
        })

        const classAll = this.state.new ? "stand-detail-messages-bar-header-tab" : "stand-detail-messages-bar-header-tab active"
        const classNew = this.state.new ? "stand-detail-messages-bar-header-tab active" : "stand-detail-messages-bar-header-tab"
        
        const myList = this.state.open ? ( <div className="stand-detail-messages-bar-container">
                <div className="stand-detail-delegates-bar-header">
                    <div className="stand-detail-delegates-bar-header-title-close-container">
                        <div className="stand-detail-messages-bar-header-title-left-container">{this.props.t('messages')}</div>
                        <div className="stand-detail-messages-bar-header-close-right-container" onClick={this.onShowHideHandler}>
                            <img alt="" src="/img/icon-close-login-panel.png" />
                        </div>
                    </div>
                    <div className="stand-detail-messages-bar-header-tabs-container">
                        <div className={classAll} onClick={this.onDisplayAllMessages}>{this.props.t('messages_all')}</div>
                        <div className={classNew} onClick={this.onDisplayNewMessages}>{this.props.t('messages_online')}</div>
                    </div>
                </div>

                <div className="stand-detail-delegates-left-content-container">
                    {listOfMessages}
                </div>
            </div>)
            : null

        return (
            <React.Fragment>
                <div className="stand-detail-messages-bar-open-info-floating-container" onClick={this.onShowHideHandler}>
                    <div className="stand-detail-delegates-bar-open-info-numbers-container">{this.state.total}</div>
                    <div className="stand-detail-messages-bar-open-info-icon-container"></div>
                </div>
                {myList}
            </React.Fragment>
        );
    }
}

export default withTranslation()(MessagesList);