import React from 'react';
import { Link } from 'react-router-dom';
import EposterCategory from '../EpostersCategory/EposterCategory';

function EposterCategories(props) {

    let content = "";


    if (props.categories !== undefined && props.categories.length > 0) {
        content = props.categories.map((item, index) => {
            return (
                <EposterCategory eventid={props.eventid} selectcategory={props.selectcategory} key={item.id} id={item.id} name={item.title} backgroundColor={item.color}
                    sponsorLogo={item.sponsorLogo} qty={item.totalPosters} backgroundImage={item.backgroundImage} />

            )
        })
    }


    if (props.eventid == "378") {

        return (
            <div className="select-poster-cat-wrapper">
                <select onChange={props.selectcategory} name="select" id="select">
                    <option value="">Por favor seleccione...</option>
                    {content}
                </select>
            </div>
        );

    }
    else {

        return (
            <div>{content}</div>
        );

    }


}

export default EposterCategories;