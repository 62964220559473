import React from 'react';
import FileGalleryFile from './FileGalleryFile';
import FileGalleryVideo from './FileGalleryVideo';
import { useTranslation } from 'react-i18next';

function FileGallery(props) {
    
    const { t, i18n } = useTranslation();
    
    const logo = props.logo !== undefined && props.logo !== "" ? <img alt="" src={props.logo} /> : null
    const files = props.content.map((el,inx) =>{        
        if (el.type == "file") {
            return <FileGalleryFile key={el.src} file={el}></FileGalleryFile>
        } if (el.type == "video") {
            return <FileGalleryVideo key={el.src} file={el}></FileGalleryVideo>
        } else {
            return null
        }
    })
    return (
        <div className="video-gallery-container">
            <div className="video-flyer-gallery-company-logo">
                {logo}
            </div>
            <div className="video-gallery-close-btn-container" onClick={props.close}>{t('stand_gallery_close')}</div>
            <div className="video-gallery-wrapper">
                <div className="flyer-viewer-container"> {files} </div>
            </div>
        </div>
    );
}

export default FileGallery;