
import * as actionTypes from '../actions/actionTypes'
import { updateObject} from '../utility'

const initialState = {
    socket:null,
    forceLogout:false
}

// reducer the place to change the state of the store
// heavy logic goes here in the reducer

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SOCKET:
            return {
                ...state,
                socket:action.socket
            }
            break;
        case actionTypes.REMOVE_SOCKET:
            return {
                forceLogout:false,
                socket:null
            }
            break;
        case actionTypes.LOGIN:
            return {
                ...state,
                forceLogout:false
            }
            break;
        case actionTypes.LOGOUT:
            return {
                ...state,
                forceLogout:true
            }
            break;
        default:
            return state;
            break;
    }
    
} 

export default  reducer;