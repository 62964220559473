import React from 'react';
import MyBagComercialGroup from '../../../../../components/MyBagComercial/MyBagComercialGroup';
import MyBagComercial from '../../../../../components/MyBagComercial/MyBagComercial';

function TabComercial(props) {
    
    {/* <table border="0" cellSpacing="0" cellPadding="0" className="tbl-mybag-comercial">
            <tbody>

            <MyBagComercialGroup type="Flyers" />
            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ribbon.png"
                format="PDF"
                size="12.4MB"
                company="El Corte Inglés"
                action="Download" />
            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ad.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />
            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ad.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />

            <MyBagComercialGroup type="Brochures" />
                
            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ad.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />
            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ribbon.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />

            <MyBagComercialGroup type="Video" />

            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ad.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />

            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ribbon.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Download" />


            <MyBagComercialGroup type="Surveys" />

            <MyBagComercial 
                title="Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis."
                icon="/img/icon-ribbon.png"
                format="PDF"
                size="12.4MB"
                company="Bristol Myers Squibb"
                action="Answer" />
                
                </tbody>
            </table> */}
            
    return (<br></br>);
}

export default TabComercial