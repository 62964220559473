import React, { Component } from 'react';
import {connect} from 'react-redux'
import NavBarDays from './NavBarDays';
import { matchPath } from 'react-router';
import { array } from 'prop-types';
import Session from './Session';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../../context/auth-context';
import configData from '../../../config.json';

class NavBar extends Component {
    
    state = {
        sessions:[],
        rooms: [],
        days: [],
        room:"",
        day:""
    }

    static contextType = AuthContext;

    nextRoomHandler = () => {        
        this.setState((prevState, props) => {
            return {center: prevState.center + 1 <= prevState.rooms.length - 1 ?  prevState.center + 1 : 0};            
        })
    }

    previousRoomHandler = () => {
        this.setState((prevState, props) => {
            return {center: prevState.center -1 < 0 ?  prevState.rooms.length - 1: prevState.center - 1};
        })
    }

    scrollRightRoomHandler = () => {
        let currWidth = document.getElementById('nav-bar-rooms').parentElement.offsetWidth;
        let currScroll = document.getElementById('nav-bar-rooms').scrollLeft
        let limit = currScroll + currWidth;
        let scroll = setInterval(() => {
                    
            let currScrollLeft = document.getElementById('nav-bar-rooms').scrollLeft;

            if(currScrollLeft >= limit ) {
                clearInterval(scroll);
            } else {
                document.getElementById('nav-bar-rooms').scrollLeft = document.getElementById('nav-bar-rooms').scrollLeft + 100;    
                if (currScrollLeft === document.getElementById('nav-bar-rooms').scrollLeft){
                    clearInterval(scroll);
                }
            }
        }, 50);
        
    }

    scrollLeftRoomHandler = () => {
        let currWidth = document.getElementById('nav-bar-rooms').parentElement.offsetWidth;
        let currScroll = document.getElementById('nav-bar-rooms').scrollLeft
        let limit = currScroll - currWidth;
        let scroll = setInterval(() => {
                    
            let currScrollLeft = document.getElementById('nav-bar-rooms').scrollLeft;

            if(currScrollLeft <= limit ) {
                clearInterval(scroll);
            } else {
                document.getElementById('nav-bar-rooms').scrollLeft = document.getElementById('nav-bar-rooms').scrollLeft - 100;    
                if (currScrollLeft === document.getElementById('nav-bar-rooms').scrollLeft){
                    clearInterval(scroll);
                }
            }
        }, 50);
        
    }

    scrollRightStreamHandler = (scrollTotal) => {
    
        let currWidth = document.getElementById('nav-bar-sessions').offsetWidth;
        let currScroll = document.getElementById('nav-bar-sessions').scrollLeft
        let limit;

        if (scrollTotal > 0){
            limit = scrollTotal
        } else {
            limit = currScroll + currWidth;
        }

        let scroll = setInterval(() => {
                    
            let currScrollLeft = document.getElementById('nav-bar-sessions').scrollLeft;

            if(currScrollLeft >= limit ) {
                clearInterval(scroll);
            } else {
                document.getElementById('nav-bar-sessions').scrollLeft = document.getElementById('nav-bar-sessions').scrollLeft + 100;    
                if (currScrollLeft === document.getElementById('nav-bar-sessions').scrollLeft){
                    clearInterval(scroll);
                }
            }
        }, 50);
        
    }

    scrollLeftStreamHandler = () => {
        let currWidth = document.getElementById('nav-bar-sessions').offsetWidth;
        let currScroll = document.getElementById('nav-bar-sessions').scrollLeft
        let limit = currScroll - currWidth;
        let scroll = setInterval(() => {
                    
            let currScrollLeft = document.getElementById('nav-bar-sessions').scrollLeft;

            if(currScrollLeft <= limit ) {
                clearInterval(scroll);
            } else {
                document.getElementById('nav-bar-sessions').scrollLeft = document.getElementById('nav-bar-sessions').scrollLeft - 100;    
                if (currScrollLeft === document.getElementById('nav-bar-sessions').scrollLeft){
                    clearInterval(scroll);
                }
            }
        }, 50);
        
    }

    onChangeDayHandler = (day) =>{
        this.setState({day:day});
    }

    onChangeRoomHandler = (roomid) =>{
        this.setState({room:roomid});
    }

    componentDidUpdate() {    
        // position the current sessio visible in the middle of the screen
        const sessionSelected = document.querySelector(".stream-preview-room-schedule-container.selected");
        if (sessionSelected){
            const rect = sessionSelected.getBoundingClientRect();
            const w = window.innerWidth;
            if (rect.left > Math.round(w/2)){  
                const scrollTotal = rect.left - Math.round(w/2)  
                this.scrollRightStreamHandler(scrollTotal);                
            }
        }
    }

    componentDidMount(){

        // check if programme is available globally in the context
        
        if (this.context.scientific_programme !== undefined && this.context.scientific_programme !== null & this.context.scientific_programme.length > 0){
            
            const sessions = this.context.scientific_programme;
            const auditoria = this.context.rooms;
            const days = this.context.days;
            const defaultAuditorium = auditoria === undefined || auditoria.length == 0 ? '' : auditoria[0];
            const defaultDay = days === undefined || days.length == 0 ? '' : days[0];

            this.setState({
                sessions:sessions,
                rooms: auditoria,
                days: days,
                room:this.props.sessionRoom,
                day:this.props.sessionDay
            })

        } else {

            let data = {
                headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
                },
                params: {}
            };

            axios.get(configData.SERVER_API + "/sessions/" ,data)
            .then(response => {

                const sessions = response.data;
                const auditoriaObj = [...new Set(sessions.filter(i => i.room !== "").map(item => { return {id:item.room_id, room:item.room, order:item.room_order} } ))]
                const auditoriaSorted = auditoriaObj.sort((a, b) => (a.order*1 > b.order*1) ? 1 : -1)        
                const auditoriaList = [...new Set(auditoriaSorted.filter(i => i.room !== "").map(item => item))]

                const auditoria = auditoriaList.filter((aud, index) => {
                    const _aud = JSON.stringify(aud);
                    return index === auditoriaList.findIndex(obj => {
                        return JSON.stringify(obj) === _aud;
                    });
                    });

                const days = [...new Set(sessions.filter(i => i.day !== "").map(item => item.day))]                    
                const defaultAuditorium = auditoria === undefined || auditoria.length == 0 ? '' : auditoria[0];
                const defaultDay = days === undefined || days.length == 0 ? '' : days[0];

                this.setState({
                    sessions:sessions,
                    rooms: auditoria,
                    days: days,
                    room:this.props.sessionRoom,
                    day:this.props.sessionDay
                })

                this.context.scientific_programme = sessions;
                this.context.days = days;
                this.context.rooms = auditoria;
                
            })
            .catch(error => {
                console.log(error.message);
            })
        }

    }

    render() {
        
        let roomsNavBar = "";
        if(this.state.rooms.length>1){
            roomsNavBar = this.state.rooms.map((item, index) => {
                const classSelected = this.state.room === item.id ? "nav-rooms-entry-wrapper selected" : "nav-rooms-entry-wrapper";
                return(
                    <React.Fragment key={item.id}>
                    <div className={classSelected} onClick={() => this.onChangeRoomHandler(item.id)}>{item.room}</div>
                    <div className="nav-rooms-separator-wrapper"></div>
                    </React.Fragment>
                )
            });
        }

        const daysNavBar = this.state.days.map((item, index) => {
                const classSelected = this.state.day === item ? "btn-days-nav selected" : "btn-days-nav";
                return(
                    <div key={item} className={classSelected} onClick={() => this.onChangeDayHandler(item)}>{item}</div>
                )
            });


        let image;

        const sessionList = this.state.sessions
        .filter(i => i.day === this.state.day && i.room_id === this.state.room && (i.showInProgramme == undefined || i.showInProgramme))
        .map((item, index) => {
            
            let dt;
            if (item.time_start.split("T")[1]){
                dt = item.time_start.split("T")[1].substring(0, 5);
            }

            if (item.isSponsores & item.sponsor_logo !== ""){
                image = "url(" + item.sponsor_logo +")"
            } else {
                switch (this.context.event_id) {
                    case "303":
                        image = "url(/img/neumo-madrid-sessions-preview.jpg)";
                        break;
                    case "326":
                        image = "url(/img/seacv-session-preview.jpg)";
                        break;
                    case "358":
                        image = "url(https://www.emma.events/static/virtualvenue/ests2020/rooms/session_generic_280x155px.jpg)";
                        break;
                    default:
                        image = "url()";
                        break;
                }
                
            }
            
            const isLiveSession = this.props.live.filter((el) => el._id === item._id)
            let isLive = false;
            if (isLiveSession !== undefined && isLiveSession.length > 0){
                isLive = true;
            }

            const dateStart = item.time_start.replace(/T/g, ' ').replace(/Z/g, '')
            const dateEnd = item.time_end.replace(/T/g, ' ').replace(/Z/g, '')            

            const date_start =  new Date(dateStart);
            const date_end =  new Date(dateEnd);
            //const now = Date.getUTCDate()

            return(
                <Session 
                    key={item._id}
                    image={image}
                    upcoming={false}
                    live={isLive}
                    title={item.title}
                    time={dt}
                    roomId={item.room_id}
                    id={item._id}
                    currSessionId={this.props.sessionId}
                    changeSession={this.props.changeSession}
                    externalURL={item.externalURL}
                />
            )
        })

        return (
            
            <div className="bottom-rooms-videos-navigation-container">

                <div className="bottom-rooms-videos-navigation-days-nav"> {daysNavBar} </div>

                <div className="rooms-navigation-container">

                    <div className="rooms-navigation-arrows-container left-arrows">
                        <div className="arrow-room-nav-container">
                            <img onClick={this.scrollLeftRoomHandler} alt="" src="/img/left-arrow-room-nav.png" />
                        </div>
                        <div className="arrow-streams-nav-container">
                            <img onClick={this.scrollLeftStreamHandler} alt="" src="/img/left-arrow-streams-nav.png" />
                        </div>
                    </div>
                    <div className="rooms-navigation-arrows-container right-arrows">
                        <div className="arrow-room-nav-container">
                            <img onClick={this.scrollRightRoomHandler} alt="" src="/img/right-arrow-room-nav.png" />
                        </div>
                        <div className="arrow-streams-nav-container">
                            <img onClick={this.scrollRightStreamHandler} alt="" src="/img/right-arrow-streams-nav.png" />
                        </div>
                    </div>

                    <div className="nav-rooms-streams-container">
                        <div className="nav-rooms-streams-content" id="nav-bar-rooms"> {roomsNavBar} </div>
                        <div className="nav-rooms-streams-separator"></div>
                        <div className="nav-rooms-streams-content" id="nav-bar-sessions"> {sessionList} </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return{
        live: state.live.sessions        
    }
}

export default connect(mapStateToProps)(NavBar);
            