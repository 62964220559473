
import React, { Component } from 'react';
import MyBagCertificateGroup from '../../../../../components/MyBagCertificate/MyBagCertificateGroup';
import MyBagCertificate from '../../../../../components/MyBagCertificate/MyBagCertificate';
import axios from 'axios'
import configData from '../../../../../config.json';
import AuthContext from '../../../../../context/auth-context';
import { withTranslation } from 'react-i18next';


class TabCertificates extends Component {

    static contextType = AuthContext;

    state = { certificates: [] }


    componentDidMount() {

        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/users/me/certificates", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    this.setState({ certificates: response.data })
                }
            })
            .catch(error => {
            })
    }

    render() {

        // --- assitance certificates ------------------------
        const assistance = this.state.certificates
            .filter(element => element.type === 'Asistencia' || element.type === 'Attendance')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} type={el.type} code={el.code} />)
            })

        const assistance_section = assistance !== undefined && assistance.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name={this.props.t('certificates_heading_attendance')} icon="/img/icon-presence-certificates.png" />
                {assistance}
            </React.Fragment>
            : null


        // --- poster certificates ------------------------
        const poster = this.state.certificates
            .filter(element => element.type === 'Poster')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const poster_section = poster !== undefined && poster.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Poster" icon="/img/icon-poster-certificates.png" />
                {poster}
            </React.Fragment>
            : null


        // --- clinical case certificates ------------------------
        const clinicalcase = this.state.certificates
            .filter(element => element.type === 'Caso Clínico')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const clinicalcase_section = clinicalcase !== undefined && clinicalcase.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Caso Clínico" icon="/img/icon-poster-certificates.png" />
                {clinicalcase}
            </React.Fragment>
            : null


        // --- oral certificates ------------------------
        const oral = this.state.certificates
            .filter(element => element.type === 'Comunicación Oral' || element.type === 'Oral')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const oral_section = oral !== undefined && oral.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Comunicación Oral" icon="/img/icon-poster-certificates.png" />
                {oral}
            </React.Fragment>
            : null


        // --- speaker certificates ------------------------
        const speaker = this.state.certificates
            .filter(element => element.type === 'Ponente' || element.type === 'Speaker')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const speaker_section = speaker !== undefined && speaker.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name={this.props.t('certificates_heading_speaker')} icon="/img/icon-prelector-certificates.png" />
                {speaker}
            </React.Fragment>
            : null


        // --- moderator certificates ------------------------
        const moderator = this.state.certificates
            .filter(element => element.type === 'Moderador')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const moderator_section = moderator !== undefined && moderator.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Moderador" icon="/img/icon-moderator-certificates.png" />
                {moderator}
            </React.Fragment>
            : null


        // --- coordinator certificates ------------------------
        const coordinator = this.state.certificates
            .filter(element => element.type === 'Coordinador')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const coordinator_section = coordinator !== undefined && coordinator.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Coordinador" icon="/img/icon-moderator-certificates.png" />
                {coordinator}
            </React.Fragment>
            : null



        // --- scientific comittee certificates ------------------------
        const scientific_comittee = this.state.certificates
            .filter(element => element.type === 'Comité Científico' || element.type === 'Scientific Committee')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const scientific_comittee_section = scientific_comittee !== undefined && scientific_comittee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name={this.props.t('certificates_heading_scientific_committee')} icon="/img/icon-moderator-certificates.png" />
                {scientific_comittee}
            </React.Fragment>
            : null


        // --- scientific comittee certificates ------------------------
        const student_comittee = this.state.certificates
            .filter(element => element.type === 'Student Committee')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const student_comittee_section = student_comittee !== undefined && student_comittee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Student Committee" icon="/img/icon-moderator-certificates.png" />
                {student_comittee}
            </React.Fragment>
            : null



        // --- organizer comittee certificates ------------------------
        const organizer_comittee = this.state.certificates
            .filter(element => element.type === 'Comité Organizador' || element.type === 'Organizing Committee')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const organizer_comittee_section = organizer_comittee !== undefined && organizer_comittee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name={this.props.t('certificates_heading_organizing_committee')} icon="/img/icon-moderator-certificates.png" />
                {organizer_comittee}
            </React.Fragment>
            : null


        // --- local comittee certificates ------------------------
        const local_comittee = this.state.certificates
            .filter(element => element.type === 'Comité Local')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const local_comittee_section = local_comittee !== undefined && local_comittee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name={this.props.t('certificates_heading_local_committee')} icon="/img/icon-moderator-certificates.png" />
                {local_comittee}
            </React.Fragment>
            : null


        // --- board comitee certificates ------------------------
        const board_comittee = this.state.certificates
            .filter(element => element.type === 'Junta Directiva')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const board_comittee_section = board_comittee !== undefined && board_comittee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Junta Directiva" icon="/img/icon-moderator-certificates.png" />
                {board_comittee}
            </React.Fragment>
            : null



        // --- scientific abstracts reviewing committee certificates ------------------------
        const scientific_abstracts_reviewing_committee = this.state.certificates
            .filter(element => element.type === 'Scientific Abstracts Reviewing Committee')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const scientific_abstracts_reviewing_committee_section = scientific_abstracts_reviewing_committee !== undefined && scientific_abstracts_reviewing_committee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Scientific Abstracts Reviewing Committee" icon="/img/icon-moderator-certificates.png" />
                {scientific_abstracts_reviewing_committee}
            </React.Fragment>
            : null


        // --- chair certificates ------------------------
        const chair = this.state.certificates
            .filter(element => element.type === 'Chair')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const chair_section = chair !== undefined && chair.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Chair" icon="/img/icon-moderator-certificates.png" />
                {chair}
            </React.Fragment>
            : null


        // --- communication committee certificates ------------------------
        const communication_committee = this.state.certificates
            .filter(element => element.type === 'Communication Committee')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const communication_committee_section = communication_committee !== undefined && communication_committee.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Communication Committee" icon="/img/icon-moderator-certificates.png" />
                {communication_committee}
            </React.Fragment>
            : null



        // --- coordinator scientific module certificates ------------------------
        const coordinator_scientific_module = this.state.certificates
            .filter(element => element.type === 'Coordinator Scientific Module')
            .map(el => {
                return (<MyBagCertificate key={el._id}
                    title={el.desc} file={el.file} eventid={this.context.event_id} />)
            })

        const coordinator_scientific_module_section = coordinator_scientific_module !== undefined && coordinator_scientific_module.length > 0
            ? <React.Fragment>
                <MyBagCertificateGroup name="Coordinator Scientific Module" icon="/img/icon-moderator-certificates.png" />
                {coordinator_scientific_module}
            </React.Fragment>
            : null



        return (
            <table border="0" cellSpacing="0" cellPadding="0" className="tbl-mybag-certificates">
                <tbody>
                    {assistance_section}
                    {speaker_section}
                    {moderator_section}
                    {coordinator_section}
                    {chair_section}
                    {coordinator_scientific_module_section}
                    {oral_section}
                    {poster_section}
                    {scientific_comittee_section}
                    {organizer_comittee_section}
                    {local_comittee_section}
                    {board_comittee_section}
                    {scientific_abstracts_reviewing_committee_section}
                    {communication_committee_section}
                    {student_comittee_section}
                    {clinicalcase_section}
                </tbody>
            </table>
        );
    }
}


export default withTranslation()(TabCertificates);