import React, { Component } from 'react';
import TabInserts from './TabInserts';
import TabComercial from './TabComercial'
import TabCertificates from './TabCertificates';
import TabScientific from './TabScientific';
import { withTranslation } from 'react-i18next';

class MyBag extends Component {

    state = {
        tabInserts: true,
        tabComercial: this.props.eventId === "367" ? true : false,
        tabCertificates: this.props.eventId === "289" || this.props.eventId === "367" || this.props.eventId === "353" || this.props.eventId === "314" || this.props.eventId === "363" || this.props.eventId === "347" || this.props.eventId === "369" || this.props.eventId === "366" || this.props.eventId === "315" || this.props.eventId === "342" || this.props.eventId === "376" || this.props.eventId === "391" || this.props.eventId === "397" || this.props.eventId === "320" || this.props.eventId === "379" || this.props.eventId === "420" || this.props.eventId === "336" ? true : false,
        tabScientific: false,
        sponsored: this.props.eventId === "367" || this.props.eventId === "366" || this.props.eventId === "363" || this.props.eventId === "405" || this.props.eventId === "314" || this.props.eventId === "372" || this.props.eventId === "373" || this.props.eventId === "320" || this.props.eventId === "336" ? true : false,
        sponsorLogo: "",
        tabVisible: "inserts"
    }

    onTabHandler = (tab) => {        
        if (this.state.tabVisible === tab) {
            //this.setState({ tabVisible: '' })
        } else {
            this.setState({ tabVisible: tab })
        }
    }



    render() {

        let tabContainer = null;
        let classItemInserts = "menu-tab-item cursor-only";
        let classItemComercial = "menu-tab-item cursor-only";
        let classItemCertificates = "menu-tab-item cursor-only";
        let classItemScientific = "menu-tab-item cursor-only";

        switch (this.state.tabVisible) {
            case "inserts":
                classItemInserts = "menu-tab-item cursor-only selected"
                tabContainer = <div className="tabs-my-bag" id="tab-inserts">
                    <TabInserts />
                </div>
                break;
            case "comercial":
                classItemComercial = "menu-tab-item cursor-only selected"
                tabContainer = <div className="tabs-my-bag" id="tab-comercial">
                    <TabComercial />
                </div>
                break;
            case "certificates":
                classItemCertificates = "menu-tab-item cursor-only selected"
                tabContainer = <div className="tabs-my-bag" id="tab-certificates">
                    <TabCertificates />
                </div>
                break;
            case "scientific":
                classItemScientific = "menu-tab-item cursor-only selected"
                tabContainer = <div className="tabs-my-bag" id="tab-scientific">
                    <TabScientific />
                </div>
                break;
        }

        const tabHeaderInserts = this.state.tabInserts
            ? <div className={classItemInserts} onClick={() => this.onTabHandler('inserts')}>{this.props.t('my_bag_inserts')}</div>
            : null


        const tabHeaderComercial = this.state.tabComercial
            ? <div className={classItemComercial} onClick={() => this.onTabHandler('comercial')}>{this.props.t('my_bag_comercial')}</div>
            : null

        const tabHeaderCertificates = this.state.tabCertificates
            ? <div className={classItemCertificates} onClick={() => this.onTabHandler('certificates')}>{this.props.t('my_bag_certificates')}</div>
            : null

        const tabHeaderScientific = this.state.tabScientific
            ? <div className={classItemScientific} onClick={() => this.onTabHandler('scientific')}>SCIENTIFIC</div>
            : null

        let sponsored = ""

        switch (this.props.eventId) {
            case "367":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="/img/sponsor-my-bag-cnp2020.jpg" alt="" />
                    </div>
                    : null
                break;
            case "366":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="https://www.emma.events/static/virtualvenue/aea2020/mybag/sponsor-my-bag-aea2020.png" alt="" />
                    </div>
                    : null
                break;
            case "363":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag-logo.png" alt="" />
                    </div>
                    : null
                break;
            case "405":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag-logo.png" alt="" />
                    </div>
                    : null
                break;
            case "314":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag-logo.png" alt="" />
                    </div>
                    : null
                break;
            case "372":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <p>Patrocinado Por:</p>
                        <img src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/astrazeneca-mybag.png" alt="" />
                    </div>
                    : null
                break;
            case "373":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <p>Patrocinado Por:</p>
                        <img src="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/industria/logos/estevin-logo.jpg" alt="" />
                    </div>
                    : null
                break;

            case "320":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <p>&nbsp;</p>
                        <p>Bronze Sponsor:</p>
                        <a target="_blank" href="https://aawv.net"><img src="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/cuenca-bronze-sponsor.png" alt="" /></a>
                    </div>
                    : null
                break;


            case "336":
                sponsored = this.state.sponsored
                    ? <div className="company-logo">
                        <img src="https://www.emma.events/static/virtualvenue/sefc2022/mybag/sefc2022-mybag-logo.jpg" alt="" />
                    </div>
                    : null
                break;


        }



        return (
            <React.Fragment>

                {sponsored}

                <div style={{ clear: `both` }}></div>

                {tabHeaderInserts}
                {tabHeaderComercial}
                {tabHeaderCertificates}
                {tabHeaderScientific}

                {/* <div className="tab-items-received">
                <span className="number">127</span><br /><span className="items">ITEMS</span><br /><span className="received">RECEIVED</span>    
            </div> */}
                <div style={{ clear: `both` }}></div>
                {tabContainer}
            </React.Fragment>
        );
    }
}

export default withTranslation()(MyBag);