
import * as actionTypes from '../actions/actionTypes';
import { updateObject} from '../utility'

const initialState = {
    counter:0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INCREMENT:
            return state;
            break;
        case actionTypes.DECREMENT:
            return state;
            break;
        default:
            return state;
            break;
    }
    
} 

export default  reducer;