import React from 'react';
import { useTranslation } from 'react-i18next';

function WelcomeBadge(props) {

    const { t, i18n } = useTranslation();

    let role;
    switch (props.profile) {
        case "participant":
            role = t('role_participant') //"Asistente"
            break;
        case "participant-full":
            role = t('role_participant') //"Asistente"
            break;
        case "participant-pay-per-view":
            role = t('role_participant') // "Asistente"
            break;
        case "delegate":
            role = t('role_delegate') // "Delegado"
            break;
        case "delegate-stand":
            role = t('role_delegate') // "Delegado"
            break;
        case 'delegate-full':
            role = t('role_delegate') // "Delegado"
            break;
        case 'delegate-assistant':
            role = t('role_delegate') // "Delegado"
            break;
        case 'delegate-congress':
            role = t('role_delegate') // "Delegado"
            break;
        case 'delegate-stand-checker':
            role = t('role_delegate') // "Delegado"
            break;
        case "secretariat":
            role = t('role_secretariat') // "Secretaria"
            break;
        case "press":
            role = t('role_press') // "Secretaria"
            break;
        default:
            role = t('role_participant') // "Asistente"
            break;
    }

    let photo;
    if (props.userPhoto !== undefined && props.userPhoto !== "") {
        photo = <img alt="" src={props.userPhoto} className="img-badge-avatar" />
    } else {
        photo = <img alt="" src="/img/profile-avatar.jpg" className="img-badge-avatar" />
    }

    if (props.eventId == "327" || props.eventId == "336") {
        photo = "";
    }

    let background;
    if (props.background !== "") {
        background = "url(" + props.background + ")";
    } else {
        background = "url(/img/person-badge-background.png)"
    }

    let sponsorLogoMain;
    if (props.sponsorLogoMain !== "") {
        sponsorLogoMain = <div className="person-badge-sponsor-main-container">
            <img src={props.sponsorLogoMain} />
        </div>
    } else {
        sponsorLogoMain = <div className="person-badge-barcode-container">
            {props.eventName}<br />
            <img src="/img/bar-code-badge.png" />
        </div>
    }

    if (props.eventId == "315" || props.eventId == "378" || props.eventId == "327" || props.eventId == "336") {
        sponsorLogoMain = "";
    }

    let positionClass;
    if (props.position === "left") {
        positionClass = "person-badge-container left"
    } else {
        positionClass = "person-badge-container"
    }

    let personNameClass = "badge-person-name " + props.eventCode.toLowerCase();
    let personBadgeSpacer = "person-badge-spacer " + props.eventCode.toLowerCase();
    let personBadgeWelcome = "badge-congress-title " + props.eventCode.toLowerCase();
    let personBadgeBottom = "person-badge-bottom vip " + props.eventCode.toLowerCase();

    return (
        <div className={positionClass}>
            <div className="person-badge-company-logo">
                <img src={props.sponsorLogo} />
            </div>

            <div className="person-badge-area" style={{ backgroundImage: background }}>


                <div className={personBadgeSpacer}>&nbsp;</div>


                <span className={personBadgeWelcome}>{t('badge_welcome')}</span>
                {photo}
                <span className={personNameClass}>{props.userName}</span><br />
                <span className="badge-person-desc">{props.intitution}</span><br />

                {sponsorLogoMain}

            </div>
            <div className={personBadgeBottom}>{role}</div>
        </div>
    );
}

export default WelcomeBadge;