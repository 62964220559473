import React from 'react';

function ChatHeader(props) {
    const closed = props.isClosed ? "closed" : "";
    return (
        <div className={`header-chat-window ${closed}`}>
            <div className="header-chat-window-peson-name">
            <strong>{props.title}<br/>{props.subtitle}</strong>
            </div>
            <div className="icon-chat-window-close">
                <img alt="" src="/img/icon-close-chat-window.png" />
            </div>
            <div className="icon-chat-window-video cursor-only" onClick={props.showHide}>
                <img alt="" src="/img/icon-expand-chat-window.png" />
            </div>
            {/* <div className="icon-chat-window-video">
                <img alt="" src="/img/icon-definitions-chat-window.png" />
            </div> */}
            <div className="icon-chat-window-video">
                <img alt="" src="/img/icon-call-chat-window.png" />
            </div>
            <div className="icon-chat-window-video">
                <img alt="" src="/img/icon-video-chat-window.png" />
            </div>
        </div>
    );
}

export default ChatHeader;