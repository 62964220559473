import React, { Component } from 'react';
import configData from '../../../../../config.json';
import AuthContext from '../../../../../context/auth-context';
import axios from 'axios';
import MyBagCertificate from '../../../../../components/MyBagCertificate/MyBagCertificate';
import { withTranslation } from 'react-i18next';

class MyCredits extends Component {



    state = {
        complete: false,
        q1: null,
        q1o: null,
        q2: null,
        q2o: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        q9: null,
        q10: null,
        q11: null,
        q12: null,
        q13: null,
        q14: null,
        q15: null,
        q16: null,
        q17: null,
        q18: null,
        q19: null,
        q20: null,
        q21: null,
        q22: null,
        q23: null,
        q24: null,
        q25: null,
        q26: null,
        q27: null,
        q28: null,
        q29: null,
        q30: null,
        q31: null,
        q32: null,
        q33: null,
        q33o: null,
        q34: null,
        q35: null,
        q36: null,
        q37: null,
        q38: null,
        q39: null,
        q40: null,
        q41: null,
        q42: null,
        q43: null,
        q44: null,
        q45: null,
        q46: null,
        q47: null,
        q48: null,
        q49: null,
        q50: null,
        q51: null,
        q52: null,
        q53: null,
        q54: null,
        q55: null,
        q56: null,
        creditsList: []
    }

    static contextType = AuthContext;

    componentDidMount() {



        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/users/me/survey", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    axios.get(configData.SERVER_API + "/users/me/credits-congress", data)
                        .then(response => {
                            //console.log(response.data)
                            this.setState({ complete: true, creditsList: response.data })
                        })
                        .catch(error => {
                            this.setState({ complete: true })
                        })
                }
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    onSurveySaveHandler = () => {


        let config = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };


        // SET THE SURVEY TO BE STORES --------------------------
        let mySurvey;

        if (this.props.eventId === "342") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,

            }

        }



        if (this.props.eventId === "420") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,
                q29: this.state.q30,
                q29: this.state.q31,
                q29: this.state.q32,

            }

        }


        if (this.props.eventId === "489") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,

            }

        }


        if (this.props.eventId === "575") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,

            }

        }


        if (this.props.eventId === "397") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,
                q30: this.state.q30,
                q31: this.state.q31,
                q32: this.state.q32,
                q33: this.state.q33,
                q34: this.state.q34,
                q35: this.state.q35,
                q36: this.state.q36,
                q37: this.state.q37,
                q38: this.state.q38,
                q39: this.state.q39,
                q40: this.state.q40,
                q41: this.state.q41,
                q42: this.state.q42,
                q43: this.state.q43,
                q44: this.state.q44,
                q45: this.state.q45,
                q46: this.state.q46,
                q47: this.state.q47,
                q48: this.state.q48,
                q49: this.state.q49,
                q50: this.state.q50,
                q51: this.state.q51,
                q52: this.state.q52,
                q53: this.state.q53,
                q54: this.state.q54,
                q55: this.state.q55,
                q56: this.state.q56,


            }

        }

        if (this.props.eventId === "367") {
            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
            }
        }

        if (this.props.eventId === "315") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q2o: this.state.q2o,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,
                q30: this.state.q30,
                q31: this.state.q31,
                q32: this.state.q32,
                q33: this.state.q33,
                q33o: this.state.q33o,
                q34: this.state.q34,
                q35: this.state.q35,
                q36: this.state.q36,
                q37: this.state.q37,
                q38: this.state.q38
            }

        }



        if (this.props.eventId === "372") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,

            }

        }


        if (this.props.eventId === "373") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,

            }

        }


        if (this.props.eventId === "424") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,

            }

        }


        if (this.props.eventId === "425") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,

            }

        }


        if (this.props.eventId === "379") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q1o: this.state.q1o,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,

            }

        }


        if (this.props.eventId === "376") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,
                q30: this.state.q30,
                q31: this.state.q31,
                q32: this.state.q32,
                q33: this.state.q33,
                q34: this.state.q34,
                q35: this.state.q35,
                q36: this.state.q36,
                q37: this.state.q37,
                q38: this.state.q38,
                q39: this.state.q39,

            }

        }



        if (this.props.eventId === "32000") {

            mySurvey = {
                user_id: this.context.id,
                q1: this.state.q1,
                q2: this.state.q2,
                q3: this.state.q3,
                q4: this.state.q4,
                q5: this.state.q5,
                q6: this.state.q6,
                q7: this.state.q7,
                q8: this.state.q8,
                q9: this.state.q9,
                q10: this.state.q10,
                q11: this.state.q11,
                q12: this.state.q12,
                q13: this.state.q13,
                q14: this.state.q14,
                q15: this.state.q15,
                q16: this.state.q16,
                q17: this.state.q17,
                q18: this.state.q18,
                q19: this.state.q19,
                q20: this.state.q20,
                q21: this.state.q21,
                q22: this.state.q22,
                q23: this.state.q23,
                q24: this.state.q24,
                q25: this.state.q25,
                q26: this.state.q26,
                q27: this.state.q27,
                q28: this.state.q28,
                q29: this.state.q29,
                q30: this.state.q30,
                q31: this.state.q31,
                q32: this.state.q32,
                q33: this.state.q33,
                q34: this.state.q34,
                q35: this.state.q35,
                q36: this.state.q36,
                q37: this.state.q37,
                q38: this.state.q38,
                q39: this.state.q39,
                q40: this.state.q40,
                q41: this.state.q41,
                q42: this.state.q42,
                q43: this.state.q43,
                q44: this.state.q44,
                q45: this.state.q45,
                q46: this.state.q46,
                q47: this.state.q47,
                q48: this.state.q48,
                q49: this.state.q49,
                q50: this.state.q50,
                q51: this.state.q51,
                q52: this.state.q52,
                q53: this.state.q53,
                q54: this.state.q54

            }

        }


        // VALIDATE THE FORM ------------------------------------
        if (this.props.eventId === "342") {

            if (this.state.q1 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }




        if (this.props.eventId === "420") {

            if (this.state.q1 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null &&
                this.state.q30 !== null &&
                this.state.q31 !== null &&
                this.state.q32 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }



        if (this.props.eventId === "489") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId === "575") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }     


        if (this.props.eventId === "397") {

            if (this.state.q1 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null &&
                this.state.q30 !== null &&
                this.state.q31 !== null &&
                this.state.q32 !== null &&
                this.state.q33 !== null &&
                this.state.q34 !== null &&
                this.state.q35 !== null &&
                this.state.q36 !== null &&
                this.state.q37 !== null &&
                this.state.q38 !== null &&
                this.state.q39 !== null &&
                this.state.q40 !== null &&
                this.state.q41 !== null &&
                this.state.q42 !== null &&
                this.state.q43 !== null &&
                this.state.q44 !== null &&
                this.state.q45 !== null &&
                this.state.q46 !== null &&
                this.state.q47 !== null &&
                this.state.q48 !== null &&
                this.state.q49 !== null &&
                this.state.q50 !== null &&
                this.state.q51 !== null &&
                this.state.q52 !== null &&
                this.state.q53 !== null &&
                this.state.q54 !== null &&
                this.state.q55 !== null &&
                this.state.q56 !== null) {



                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                console.log(mySurvey);
                alert('Please complete all questions.')
            }

        }






        if (this.props.eventId === "32000") {

            if (this.state.q1 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null &&
                this.state.q30 !== null &&
                this.state.q31 !== null &&
                this.state.q32 !== null &&
                this.state.q33 !== null &&
                this.state.q34 !== null &&
                this.state.q35 !== null &&
                this.state.q36 !== null &&
                this.state.q37 !== null &&
                this.state.q38 !== null &&
                this.state.q39 !== null &&
                this.state.q40 !== null &&
                this.state.q41 !== null &&
                this.state.q42 !== null &&
                this.state.q43 !== null &&
                this.state.q44 !== null &&
                this.state.q45 !== null &&
                this.state.q46 !== null &&
                this.state.q47 !== null &&
                this.state.q48 !== null &&
                this.state.q49 !== null &&
                this.state.q50 !== null &&
                this.state.q51 !== null &&
                this.state.q52 !== null &&
                this.state.q53 !== null &&
                this.state.q54 !== null) {



                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                console.log(mySurvey);
                alert('Please complete all questions.')
            }

        }





        if (this.props.eventId == "315") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null &&
                this.state.q30 !== null &&
                this.state.q31 !== null &&
                this.state.q32 !== null &&
                this.state.q33 !== null &&
                this.state.q34 !== null &&
                this.state.q35 !== null &&
                this.state.q36 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }

        if (this.props.eventId == "367") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "372") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "373") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "424") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "425") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "379") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


        if (this.props.eventId == "376") {

            if (this.state.q1 !== null &&
                this.state.q2 !== null &&
                this.state.q3 !== null &&
                this.state.q4 !== null &&
                this.state.q5 !== null &&
                this.state.q6 !== null &&
                this.state.q7 !== null &&
                this.state.q8 !== null &&
                this.state.q9 !== null &&
                this.state.q10 !== null &&
                this.state.q11 !== null &&
                this.state.q12 !== null &&
                this.state.q13 !== null &&
                this.state.q14 !== null &&
                this.state.q15 !== null &&
                this.state.q16 !== null &&
                this.state.q17 !== null &&
                this.state.q18 !== null &&
                this.state.q19 !== null &&
                this.state.q20 !== null &&
                this.state.q21 !== null &&
                this.state.q22 !== null &&
                this.state.q23 !== null &&
                this.state.q24 !== null &&
                this.state.q25 !== null &&
                this.state.q26 !== null &&
                this.state.q27 !== null &&
                this.state.q28 !== null &&
                this.state.q29 !== null &&
                this.state.q30 !== null &&
                this.state.q31 !== null &&
                this.state.q32 !== null &&
                this.state.q33 !== null &&
                this.state.q34 !== null &&
                this.state.q35 !== null &&
                this.state.q36 !== null &&
                this.state.q37 !== null &&
                this.state.q38 !== null &&
                this.state.q39 !== null) {

                axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                    .then(response => {
                        this.setState({ complete: true })
                    })
                    .catch(error => {
                        //console.log(error)
                    })
            } else {
                alert('Por favor complete todas las preguntas.')
            }

        }


    }

    onSetAnwserHandler = (event) => {
        switch (event.target.name) {
            case "credits-form1":
                this.setState({ q1: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form2":
                this.setState({ q2: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form3":
                this.setState({ q3: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form4":
                this.setState({ q4: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form5":
                this.setState({ q5: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form6":
                this.setState({ q6: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form7":
                this.setState({ q7: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form8":
                this.setState({ q8: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form9":
                this.setState({ q9: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form10":
                this.setState({ q10: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form11":
                this.setState({ q11: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form12":
                this.setState({ q12: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form13":
                this.setState({ q13: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form14":
                this.setState({ q14: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form15":
                this.setState({ q15: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form16":
                this.setState({ q16: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form17":
                this.setState({ q17: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form18":
                this.setState({ q18: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form19":
                this.setState({ q19: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form20":
                this.setState({ q20: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form21":
                this.setState({ q21: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form22":
                this.setState({ q22: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form23":
                this.setState({ q23: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form24":
                this.setState({ q24: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form25":
                this.setState({ q25: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form26":
                this.setState({ q26: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form27":
                this.setState({ q27: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form28":
                this.setState({ q28: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form29":
                this.setState({ q29: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form30":
                this.setState({ q30: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form31":
                this.setState({ q31: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form32":
                this.setState({ q32: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form33":
                this.setState({ q33: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form34":
                this.setState({ q34: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form35":
                this.setState({ q35: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form36":
                this.setState({ q36: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form37":
                this.setState({ q37: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form38":
                this.setState({ q38: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form39":
                this.setState({ q39: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form40":
                this.setState({ q40: event.target.value !== "" ? event.target.value : null })
                break;


            case "credits-form41":
                this.setState({ q41: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form42":
                this.setState({ q42: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form43":
                this.setState({ q43: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form44":
                this.setState({ q44: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form45":
                this.setState({ q45: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form46":
                this.setState({ q46: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form47":
                this.setState({ q47: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form48":
                this.setState({ q48: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form49":
                this.setState({ q49: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form50":
                this.setState({ q50: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form51":
                this.setState({ q51: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form52":
                this.setState({ q52: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form53":
                this.setState({ q53: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form54":
                this.setState({ q54: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form55":
                this.setState({ q55: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form56":
                this.setState({ q56: event.target.value !== "" ? event.target.value : null })
                break;

            default:
                break;
        }
    }

    onCommentsHandler = (text, event) => {

        if (this.props.eventId == "342") {

            switch (event.target.name) {

                case "credits-form2":
                    this.setState({ q2: text });
                    break;

                case "credits-form5":
                    this.setState({ q5: text });
                    break;

                case "credits-form6":
                    this.setState({ q6: text });
                    break;

                case "credits-form24":
                    this.setState({ q24: text });
                    break;

                case "credits-form26":
                    this.setState({ q26: text });
                    break;


                case "credits-form28":
                    this.setState({ q28: text });
                    break;


                default:
                    break;
            }

        }




        if (this.props.eventId == "420") {

            switch (event.target.name) {

                case "credits-form2":
                    this.setState({ q2: text });
                    break;

                case "credits-form5":
                    this.setState({ q5: text });
                    break;

                case "credits-form6":
                    this.setState({ q6: text });
                    break;

                case "credits-form27":
                    this.setState({ q27: text });
                    break;

                case "credits-form29":
                    this.setState({ q29: text });
                    break;


                case "credits-form31":
                    this.setState({ q31: text });
                    break;


                default:
                    break;
            }

        }



        if (this.props.eventId == "489") {

            switch (event.target.name) {


                case "credits-form10":
                    this.setState({ q10: text });
                    break;


                default:
                    break;
            }

        }


        if (this.props.eventId == "575") {

            switch (event.target.name) {


                case "credits-form10":
                    this.setState({ q10: text });
                    break;


                default:
                    break;
            }

        }



        if (this.props.eventId == "397") {

            switch (event.target.name) {

                case "credits-form2":
                    this.setState({ q2: text });
                    break;

                case "credits-form5":
                    this.setState({ q5: text });
                    break;

                case "credits-form6":
                    this.setState({ q6: text });
                    break;

                case "credits-form51":
                    this.setState({ q51: text });
                    break;

                case "credits-form53":
                    this.setState({ q53: text });
                    break;


                case "credits-form55":
                    this.setState({ q55: text });
                    break;


                default:
                    break;
            }

        }




        if (this.props.eventId == "367") {
            this.setState({ q10: text });
        }

        if (this.props.eventId == "315") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;

                case "credits-form2-otra":
                    this.setState({ q2o: text });
                    break;

                case "credits-form33-otra":
                    this.setState({ q33o: text });
                    break;

                case "credits-form37":
                    this.setState({ q37: text });
                    break;

                case "credits-form38":
                    this.setState({ q38: text });
                    break;

                default:
                    break;
            }
        }




        if (this.props.eventId == "372") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;


                case "credits-form11":
                    this.setState({ q11: text });
                    break;

                default:
                    break;
            }
        }



        if (this.props.eventId == "373") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;


                case "credits-form11":
                    this.setState({ q11: text });
                    break;

                default:
                    break;
            }
        }


        if (this.props.eventId == "424") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;


                case "credits-form11":
                    this.setState({ q11: text });
                    break;

                default:
                    break;
            }
        }


        if (this.props.eventId == "425") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;


                case "credits-form11":
                    this.setState({ q11: text });
                    break;

                default:
                    break;
            }
        }


        if (this.props.eventId == "379") {
            switch (event.target.name) {

                case "credits-form1-otra":
                    this.setState({ q1o: text });
                    break;


                case "credits-form11":
                    this.setState({ q11: text });
                    break;

                default:
                    break;
            }
        }



        if (this.props.eventId == "376") {

            switch (event.target.name) {

                case "credits-form2":
                    this.setState({ q2: text });
                    break;

                case "credits-form5":
                    this.setState({ q5: text });
                    break;

                case "credits-form6":
                    this.setState({ q6: text });
                    break;

                case "credits-form34":
                    this.setState({ q34: text });
                    break;

                case "credits-form36":
                    this.setState({ q36: text });
                    break;


                case "credits-form38":
                    this.setState({ q38: text });
                    break;


                default:
                    break;
            }

        }


    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {

        //alert(this.props.eventId);

        let creditsList;
        if (this.state.creditsList !== undefined && this.state.creditsList.length > 0) {
            const creditsArray = this.state.creditsList.map((el) => {
                return (<MyBagCertificate key={el._id} title="Certificado de Creditos" file={el.file} eventid={this.context.event_id} />)
            })
            creditsList = <React.Fragment>
                <table border="0" cellSpacing="0" cellPadding="0" className="tbl-mybag-certificates">
                    <tbody>
                        {creditsArray}
                    </tbody>
                </table>
            </React.Fragment>
        }
        else {


            if (this.props.eventId == "372" || this.props.eventId == "373" || this.props.eventId == "379" || this.props.eventId == "424" || this.props.eventId == "425") {

                creditsList = <div><p>&nbsp;</p><p><strong> Gracias a su colaboración podremos mejorar en próximos cursos aquellos aspectos que usted nos haga constar</strong></p></div>

            }


            if (this.props.eventId == "489") {

                creditsList = <div><p>&nbsp;</p><p><strong> Formulario de evaluación enviado con éxito</strong></p></div>

            }


            if (this.props.eventId == "575") {

                creditsList = <div><p>&nbsp;</p><p><strong> Formulario de evaluación enviado con éxito</strong></p></div>

            }




        }



        return (
            <React.Fragment>
                <div style={{ clear: `both` }}></div>
                <div className="menu-tab-item">{this.props.t('my_credits')}</div>
                <div style={{ clear: `both` }}></div>
                <div className="tabs-my-bag" id="tab-credits">
                    <div style={{ fontFamily: `MyriadPro` }}>

                        {this.props.eventId == "342" ?

                            !this.state.complete ?
                                <p>Por favor, cumplimente el cuestionario para generar y descargar su certificado de créditos</p>
                                : null

                            : null
                        }

                        {this.props.eventId == "420" ?

                            !this.state.complete ?
                                <p>Por favor, cumplimente el cuestionario para generar y descargar su certificado de créditos</p>
                                : null

                            : null
                        }


                        {this.props.eventId == "489" ?

                            !this.state.complete ?
                                <p>Por favor, cumplimente el cuestionario para generar y descargar su certificado de créditos</p>
                                : null

                            : null
                        }

                        {this.props.eventId == "575" ?

                            !this.state.complete ?
                                <div>
                                <p> Le informamos que sólo obtendrán acreditación los participantes que asistan a las sesiones en directo, es decir, que tienen lugar en el horario y día determinado en programa científico del congreso.<br />La asistencia a las sesiones en diferido no está acreditada.<br />Una vez finalice el congreso y previa cumplimentación del cuestionario de evaluación* se generará el certificado de créditos correspondiente en el apartado “Mis Créditos”, a partir del lunes 27 de noviembre.</p>
                                <p>*CUESTIONARO DE EVALUACIÓN:<br />En esta edición se remitirá vía e-mail el sábado 25 de noviembre, al finalizar el congreso, para que pueda cumplimentarlo, como parte del proceso de solicitud de acreditación. </p>
                                </div>
                                : null

                            : null
                        }


                        {this.props.eventId == "656" ?

                        !this.state.complete ?
                            <div>
                            <p> 
                            Le informamos que sólo obtendrán acreditación los participantes que asistan a las sesiones en directo, es decir, que tienen lugar en el horario y día determinado en programa científico del congreso.
                            <br />                                
                            La asistencia a las sesiones en diferido no está acreditada.
                            <br />
                            Una vez finalice el congreso y previa cumplimentación del cuestionario de evaluación* se generará el certificado de créditos correspondiente en el apartado “Mis Créditos”, a partir del lunes 28 de octubre.
                            </p>
                            <p>*CUESTIONARO DE EVALUACIÓN:<br />En esta edición se remitirá vía e-mail el sábado 19 de octubre, al finalizar el congreso, para que pueda cumplimentarlo, como parte del proceso de solicitud de acreditación.</p>
                            </div>
                            : null

                        : null
                        }

                        {this.props.eventId == "367" ?

                            <div>
                                <p>En total se han asignado 10 créditos para el congreso distribuidos de la siguiente manera:</p>

                                <p>
                                    JUEVES 29 OCTUBRE: 4 créditos <br />
                                    VIERNES 30 OCTUBRE: 4 créditos <br />
                                    SÁBADO 31 OCTUBRE: 2 créditos <br />
                                </p>

                                <p>Podrá descargar su certificado de créditos previa cumplimentación del cuestionario de valoración del congreso.</p>
                                <p>El certificado de créditos estará disponible a partir del 1 de noviembre 2020 .</p>
                            </div>

                            : null
                        }

                        {this.props.eventId == "315" ?

                            <div>
                                <p>REQUISITOS NECESARIOS PARA OBTENER LA ACREDITACIÓN</p>

                                <p>Les informamos que para obtener los 6,1 créditos concedidos por la Agencia de Formación Continuada (AFOC), es necesario cumplir los tres requisitos siguientes:</p>

                                <ol>
                                    <li>Asistir al 100% de las sesiones del curso</li>
                                    <li>Cumplimentar la encuesta de satisfacción</li>
                                    <li>Aprobar el test de conocimientos post-curso</li>
                                </ol>

                                <p>Para facilitar la asistencia a las ponencias, las mismas estarán disponibles en la plataforma del congreso <strong>hasta el día 21 de marzo de 2021</strong>. A partir de esa fecha no será posible acceder a ese requisito imprescindible para obtener la acreditación</p>

                                <p>Las personas que hayan completado el 100% de la asistencia y los dos test indicados por la AFOC recibirán el correspondiente certificado acreditativo por correo electrónico</p>

                                <p>Les recordamos que, según la normativa vigente de la AFOC, los médicos en formación no pueden obtener la acreditación oficial del curso.</p>
                            </div>



                            : null
                        }


                        {this.props.eventId == "372" ?

                            <div>
                                <p>Para la presente 1ª Reunión Virtual de las Áreas SEPAR 2021 se ha solicitado acreditación oficial de la Comisión de Formación Continuada del Sistema Nacional de Salud. </p>

                                <p>Las actividades que cuentan con acreditación son:<br />
                                    - Las Conferencias, Mesas y Simposiums de las Áreas de Asma, Circulación Pulmonar, Cirugía Torácica, Fisioterapia Respiratoria, Oncología Torácica, Técnicas y Trasplante Pulmonar.
                                </p>

                                <p>
                                    Los asistentes que estando inscritos en la Reunión deseen obtener la acreditación en alguno de los actos citados, deben cumplir las siguientes condiciones:<br />
                                    1. Estar inscrito con sus datos personales a la 1ª Reunión Virtual de las Áreas SEPAR 2021.<br />
                                    2. Haber permanecido en cada sesión el menos un 100% del tiempo establecido en el programa.<br />
                                    3. Realizar la encuesta antes de la finalización de la Reunión. <br />
                                    <strong>La asistencia en las sesiones en diferido no se acreditarán.</strong>
                                </p>

                                <p>Cumplidos todos estos requisitos recibirá la acreditación oficial correspondiente cuando recibamos respuesta del organismo</p>

                            </div>



                            : null
                        }



                        {this.props.eventId == "373" ?

                            <div>
                                <p>Para la presente 2ª Reunión Virtual de las Áreas SEPAR 2021 se ha solicitado acreditación oficial de la Comisión de Formación Continuada del Sistema Nacional de Salud. </p>

                                <p>Las actividades que cuentan con acreditación son:<br />
                                    - Las Conferencias, Mesas y Simposiums de las Áreas de Enfermería Respiratoria, EPID, EPOC, EROM, Neumología Pediátrica, Tabaquismo, TIR y TRS-VM-CRC
                                </p>

                                <p>
                                    Los asistentes que estando inscritos en la Reunión deseen obtener la acreditación en alguno de los actos citados, deben cumplir las siguientes condiciones:<br />
                                    1. Estar inscrito con sus datos personales a la 2ª Reunión Virtual de las Áreas SEPAR 2021.<br />
                                    2. Haber permanecido en cada sesión el menos un 100% del tiempo establecido en el programa.<br />
                                    3. Realizar la encuesta antes de la finalización de la Reunión. <br />
                                    <strong>La asistencia en las sesiones en diferido no se acreditarán.</strong>
                                </p>

                                <p>Cumplidos todos estos requisitos recibirá la acreditación oficial correspondiente cuando recibamos respuesta del organismo</p>

                            </div>



                            : null
                        }






                        {this.props.eventId == "379" ?

                            <div>
                                <p>Para el presente XXVI Congreso de Neumomadrid se ha solicitado la Acreditación de Actividades de Formación Continuada de Profesiones Sanitarias de la Comunidad de Madrid. </p>

                                <p>Las actividades que cuentan con acreditación son:<br />
                                    - Las Conferencias y Mesas de los Grupos de Trabajo de Neumomadrid.
                                </p>

                                <p>
                                    Los asistentes que estando inscritos en el Congreso deseen obtener la acreditación en alguno de los actos citados, deben cumplir las siguientes condiciones:<br />
                                    1. Estar inscrito con sus datos personales al XXVI Congreso Neumomadrid 2021.<br />
                                    2. Haber permanecido en cada sesión el menos un 100% del tiempo establecido en el programa.<br />
                                    3. Realizar la encuesta antes de la finalización del Congreso. <br />

                                    <strong>La asistencia en las sesiones en diferido no se acreditarán.</strong>
                                </p>

                                <p>Cumplidos todos estos requisitos recibirá la acreditación oficial correspondiente cuando recibamos respuesta del organismo.</p>

                            </div>



                            : null
                        }



                        {this.props.eventId == "376" ?

                            !this.state.complete ?
                                <p>Por favor, cumplimente el cuestionario para generar y descargar su certificado de créditos</p>
                                : null

                            : null
                        }


                        {this.props.eventId == "424" ?

                            <div>
                                <p>Para la presente 3ª Reunión Virtual de las Áreas SEPAR 2022 se ha solicitado acreditación oficial de la Comisión de Formación Continuada del Sistema Nacional de Salud.  </p>

                                <p>Las actividades que cuentan con acreditación son:<br />
                                    - Las mesas de las Áreas de Asma, Circulación Pulmonar, Cirugía Torácica, Enfermería Respiratoria, Oncología Torácica, Técnicas y Trasplante Pulmonar y TRS-VM-CRC<br />
                                    No cuentan con acreditación las reuniones administrativas, grupos emergentes ni sesiones de PII.

                                </p>

                                <p>
                                    Los asistentes que estando inscritos en la Reunión deseen obtener la acreditación en alguno de los actos citados, deben cumplir las siguientes condiciones:<br />
                                    1. Estar inscrito con sus datos personales a la 3ª Reunión Virtual de las Áreas SEPAR 2022.<br />
                                    2. Haber permanecido en cada sesión el menos un 100% del tiempo establecido en el programa.<br />
                                    3. Realizar la encuesta antes de la finalización de la Reunión.<br />
                                    <strong>La asistencia en las sesiones en diferido no se acreditarán</strong>

                                </p>

                                <p>Cumplidos todos estos requisitos recibirá la acreditación oficial correspondiente cuando recibamos respuesta del organismo.</p>

                            </div>



                            : null
                        }



                        {this.props.eventId == "425" ?

                            <div>
                                <p>Para la presente 4ª Reunión Virtual de las Áreas SEPAR 2022 se ha solicitado acreditación oficial de la Comisión de Formación Continuada del Sistema Nacional de Salud. </p>

                                <p>Las actividades que cuentan con acreditación son:<br />
                                    - Las mesas de las Áreas de EPID, EPOC, EROM, Fisioterapia Respiratoria, Tabaquismo y TIR. <br />
                                    No cuentan con acreditación las reuniones administrativas, grupos emergentes ni sesiones de PII.


                                </p>

                                <p>
                                    Los asistentes que estando inscritos en la Reunión deseen obtener la acreditación en alguno de los actos citados, deben cumplir las siguientes condiciones:<br />
                                    1. Estar inscrito con sus datos personales a la 4ª Reunión Virtual de las Áreas SEPAR 2022.<br />
                                    2. Haber permanecido en cada sesión el menos un 100% del tiempo establecido en el programa.<br />
                                    3. Realizar la encuesta antes de la finalización de la Reunión.<br />
                                    <strong>La asistencia en las sesiones en diferido no se acreditarán</strong>

                                </p>

                                <p>Cumplidos todos estos requisitos recibirá la acreditación oficial correspondiente cuando recibamos respuesta del organismo.</p>

                            </div>



                            : null
                        }



                        {this.props.eventId == "32000" ?

                            !this.state.complete ?

                                <div>
                                    <h3>Do you know how sustainable your research is?<br /> Let’s learn more about that! </h3>

                                    <p>Welcome to this questionnaire on sustainability, made for the EWDA conference 2021 participants. The questionnaire was created by the EWDA sustainability committee. We would like to learn more about the impact of our research activities so we can advise on better ways to promote sustainability in our day to day practices. </p>

                                    <p>If you are involved in wildlife health research, we would like to invite you to fill this short questionnaire (15 minutes) on your research practices. You can choose to answer the questions related to your current work but you can also base your answers on your previous experiences. A summary of all answers will be presented at the EWDA conference this year. Please answer this questionnaire to the best of your ability.</p>




                                </div>
                                : null

                            : null
                        }


                        {!this.state.complete ?

                            <form className={'form-my-credits ' + this.context.event_code.toLowerCase()} onSubmit={this.onSubmit}> 

                                {this.props.eventId == "342" ?


                                    <div>

                                        <p><strong>EACCME® Evaluación del participante</strong></p>

                                        <p><strong><u>1) Calidad del evento</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">¿Encontró esta actividad útil para su actividad profesional?</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy útil">Muy útil</option>
                                                <option value="Útil">Útil</option>
                                                <option value="Poco Útil">Poco Útil</option>
                                                <option value="Nada Útil">Nada Útil</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Si esta actividad no ha sido útil, por favor explique porqué:</label>
                                            <textarea id="credits-form2" name="credits-form2" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">¿Cúal fue su impresión general de este evento? - Programa</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">¿Cúal fue su impresión general de este evento? - Organización</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">¿Cual ha sido el mejor aspecto de este evento? </label>
                                            <textarea id="credits-form5" name="credits-form5" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">¿Cúal ha sido el peor aspecto de este evento?  </label>
                                            <textarea id="credits-form6" name="credits-form6" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>2) Importancia del evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">¿Cumplió la actividad con los fines educativos y los resultados de aprendizaje esperados?</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">¿La información presentada estaba respaldada y equilibrada por una base de evidencia científica válida?</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <p>¿Le fueron de utilidad las diferentes sesiones?</p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">MESA 1: INFECCIÓN</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">TALLER 1: ESTÁNDAR DEL MANEJO LOCAL EN LA ÚLCERA DE PIE DIABÉTICO: DESBRIDAMIENTO Y DESCARGA</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Simposio Satélite 1 - BBRAUN - "Seguridad en el abordaje de la herida infectada de pie diabético”</label>
                                            <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form12">MESA 2: VASCULAR</label>
                                            <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form13">Simposio Satélite 3. CONVATEC</label>
                                            <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form14">MESA 3: MISCELÁNEA</label>
                                            <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form15">Simposio Sátelite 5 - ABBOTT "La importancia de la derivación precoz de los pacientes"</label>
                                            <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form16">MESA 4: PREVENCIÓN Y BIOMECÁNICA</label>
                                            <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form17">TALLER 2: SALVAMENTO DE LA EXTREMIDAD</label>
                                            <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form18">Simposio Satélite 2 - PODARTIS. PODiapp una herramienta innovadora para seleccionar el correcto zapato terapéutico según el grado de riesgo del paciente</label>
                                            <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form19">Simposio Satélite 4 - MÖLNLYCKE</label>
                                            <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form20">MESA 5: LATAM</label>
                                            <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form21">Simposio Satélite 6 - URGO MEDICAL. "Octosulfato de Sacarosa: Como reducir el tiempo de cicatrización con la máxima evidencia científica”</label>
                                            <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form22">TALLER 3: ¿CÓMO, CUÁNDO Y HASTA DÓNDE REVASCULARIZAR EL PIE?</label>
                                            <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <p><strong><u>3) Idoneidad de los formatos utilizados en el evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form23">¿Consideró que hubo tiempo suficiente para los debates, las preguntas y respuestas y la participación de los alumnos? </label>
                                            <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Sí, Siempre/ casi siempre">Sí, Siempre/ casi siempre</option>
                                                <option value="Sí, a veces">Sí, a veces</option>
                                                <option value="Rara vez">Rara vez</option>
                                                <option value="Nunca">Nunca</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form24">¿Puede indicar algún elemento innovador durante la actividad? </label>
                                            <textarea id="credits-form24" name="credits-form24" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>4) Formas en que el evento afecta la práctica clínica</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form25">¿La información aprendida la implementará en su práctica? </label>
                                            <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form26">¿Puede dar un ejemplo de cómo esta actividad influirá en su futura práctica? </label>
                                            <textarea id="credits-form26" name="credits-form26" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>5) Sesgo comercial</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form27">¿Todos los ponentes proporcionaron su potencial declaración de conflicto de intereses con el patrocinador o patrocinadores como una segunda diapositiva de su presentación? </label>
                                            <select name="credits-form27" id="credits-form27" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Si, todos">Si, todos</option>
                                                <option value="Si, la mayoría">Si, la mayoría</option>
                                                <option value="Si, solo una parte">Si, solo una parte</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form28">¿Puede dar un ejemplo de presentación sesgada en esta actividad? </label>
                                            <textarea id="credits-form28" name="credits-form28" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form29">¿Está de acuerdo en que la información estaba en general libre de sesgos comerciales y de otro tipo?  </label>
                                            <select name="credits-form29" id="credits-form29" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy de acuerdo">Muy de acuerdo</option>
                                                <option value="De acuerdo">De acuerdo</option>
                                                <option value="Desacuerdo">Desacuerdo</option>
                                                <option value="Muy en desacuerdo">Muy en desacuerdo</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                    </div>

                                    : null
                                }



                                {this.props.eventId == "420" ?


                                    <div>

                                        <p><strong>EACCME® Evaluación del participante</strong></p>

                                        <p><strong><u>1) Calidad del evento</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">¿Encontró esta actividad útil para su actividad profesional?</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy útil">Muy útil</option>
                                                <option value="Útil">Útil</option>
                                                <option value="Poco Útil">Poco Útil</option>
                                                <option value="Nada Útil">Nada Útil</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Si esta actividad no ha sido útil, por favor explique porqué:</label>
                                            <textarea id="credits-form2" name="credits-form2" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">¿Cúal fue su impresión general de este evento? - Programa</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">¿Cúal fue su impresión general de este evento? - Organización</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">¿Cual ha sido el mejor aspecto de este evento? </label>
                                            <textarea id="credits-form5" name="credits-form5" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">¿Cúal ha sido el peor aspecto de este evento?  </label>
                                            <textarea id="credits-form6" name="credits-form6" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>2) Importancia del evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">¿Cumplió la actividad con los fines educativos y los resultados de aprendizaje esperados?</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">¿La información presentada estaba respaldada y equilibrada por una base de evidencia científica válida?</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <p>¿Le fueron de utilidad las diferentes sesiones?</p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Biomarcadores de respuesta en el Trastorno Obsesivo-Compulsivo: ¿podemos individualizar los abordajes terapéuticos?</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">La convivencia entre psicofármacos y psicoterapias: ¿amor o matrimonio de conveniencia?</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Revista de Psiquiatría y Salud Mental</label>
                                            <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form12">Biomarcadores centrales y periféricos en trastornos psicóticos</label>
                                            <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form13">Manejo clínico de las sales de litio: novedades</label>
                                            <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form14">Measuring and Treating Cognitive Deficits in Depression</label>
                                            <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form15">Aplicación de las nuevas tecnologías en salud mental: desde el screening hasta la intervención</label>
                                            <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form16">Wisdom versus Loneliness in the Era of Pandemics</label>
                                            <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form17">Lifestyle Psychiatry: encarando el futuro, volviendo al pasado</label>
                                            <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form18">Encuentro con el Ministerio de Sanidad sobre la creación de la especialidad de Psiquiatría de la Infancia y de la Adolescencia</label>
                                            <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form19">Efecto de la amenaza de parto pretérmino sobre la diada madre-hijo y el riesgo de trastorno mental infantil: un nuevo cluster a definir</label>
                                            <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form20">Eating Disorders, Heredity and Environmental Activation: Things clinicians, patients and carers need to know</label>
                                            <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form21">Autolesiones sin finalidad suicida, ¿la verdadera quinta ola, una nueva pandemia en occidente o la patoplastia de la ideación suicida en el siglo XXI?</label>
                                            <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form22">Immunometabolic depression: ready for personalized medicine?</label>
                                            <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form23">Nuevos abordajes de la depresión y abuso de sustancias en personas con dolor crónico</label>
                                            <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form24">Abordaje psiquiátrico del fenotipo conductual de enfermedades raras</label>
                                            <select name="credits-form24" id="credits-form24" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form25">Bioética y Psiquiatría. Retos en la era post - Covid</label>
                                            <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <p><strong><u>3) Idoneidad de los formatos utilizados en el evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form26">¿Consideró que hubo tiempo suficiente para los debates, las preguntas y respuestas y la participación de los alumnos? </label>
                                            <select name="credits-form26" id="credits-form26" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Sí, Siempre/ casi siempre">Sí, Siempre/ casi siempre</option>
                                                <option value="Sí, a veces">Sí, a veces</option>
                                                <option value="Rara vez">Rara vez</option>
                                                <option value="Nunca">Nunca</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form27">¿Puede indicar algún elemento innovador durante la actividad? </label>
                                            <textarea id="credits-form27" name="credits-form27" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>4) Formas en que el evento afecta la práctica clínica</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form28">¿La información aprendida la implementará en su práctica? </label>
                                            <select name="credits-form28" id="credits-form28" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form29">¿Puede dar un ejemplo de cómo esta actividad influirá en su futura práctica? </label>
                                            <textarea id="credits-form29" name="credits-form29" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>5) Sesgo comercial</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form30">¿Todos los ponentes proporcionaron su potencial declaración de conflicto de intereses con el patrocinador o patrocinadores como una segunda diapositiva de su presentación? </label>
                                            <select name="credits-form30" id="credits-form30" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Si, todos">Si, todos</option>
                                                <option value="Si, la mayoría">Si, la mayoría</option>
                                                <option value="Si, solo una parte">Si, solo una parte</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form31">¿Puede dar un ejemplo de presentación sesgada en esta actividad? </label>
                                            <textarea id="credits-form31" name="credits-form31" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form32">¿Está de acuerdo en que la información estaba en general libre de sesgos comerciales y de otro tipo?  </label>
                                            <select name="credits-form32" id="credits-form32" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy de acuerdo">Muy de acuerdo</option>
                                                <option value="De acuerdo">De acuerdo</option>
                                                <option value="Desacuerdo">Desacuerdo</option>
                                                <option value="Muy en desacuerdo">Muy en desacuerdo</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                    </div>

                                    : null
                                }




                                {this.props.eventId == "489" ?


                                    <div>

                                        <p><strong>HOJA DE EVALUACIÓN GENERAL DEL CONGRESO</strong></p>

                                        <p><strong>Señale su grado de acuerdo con las siguientes afirmaciones teniendo en cuenta que puntuar 0 supone estar totalmente en desacuerdo y puntual 10 supone estar totalmente de acuerdo. Recuerde que sus respuestas son anónimas.</strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">Se ha cumplido el horario del programa</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos del congreso me han resultado interesantes</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los contenidos del congreso han sido útiles para mi trabajo</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">Las instalaciones en que se han desarrollado han sido adecuadas</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">Los ponentes han conseguido generar interés</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">Los ponentes han demostrado ser expertos en su campo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">En general, la organización del congreso ha sido correcta</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Se han cumplido mis expectativas en relación con este congreso</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Sesgo comercial</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">Comentarios u observaciones:</label>
                                            <textarea id="credits-form10" name="credits-form10" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p>Las Sociedades organizadoras del Congreso le agradeceríamos muchísimo que nos ayudara cumplimentando esta evaluación. Sus opiniones son muy importantes de cara a poder mejorar futuras ediciones, así como para lograr la certificación de la Comisión de Evaluación que ha acreditado esta actividad.</p>

                                        <p>Agradecemos de antemano su colaboración.</p>


                                    </div>

                                    : null
                                }


                                {this.props.eventId == "397" ?


                                    <div>

                                        <p><strong>EACCME® Evaluación del participante</strong></p>

                                        <p><strong><u>1) Quality of the event</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">How useful for your professional activity did you find this event?</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">If this activity was not useful, please explain why:</label>
                                            <textarea id="credits-form2" name="credits-form2" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">What was your overall impression of this event? - Programme</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Excellent">Excellent</option>
                                                <option value="Good">Good</option>
                                                <option value="Fairly good">Fairly good</option>
                                                <option value="Poor">Poor</option>
                                                <option value="Very poor">Very poor</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">What was your overall impression of this event? - Organisation</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Excellent">Excellent</option>
                                                <option value="Good">Good</option>
                                                <option value="Fairly good">Fairly good</option>
                                                <option value="Poor">Poor</option>
                                                <option value="Very poor">Very poor</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">What was the best aspect of this event?</label>
                                            <textarea id="credits-form5" name="credits-form5" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">What was the worst aspect of this event? </label>
                                            <textarea id="credits-form6" name="credits-form6" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>2) Relevance of the event</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">Did the event fulfil your educational goals and expected learning outcomes?</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Very much">Very much</option>
                                                <option value="Somewhat">Somewhat</option>
                                                <option value="Not much">Not much</option>
                                                <option value="Not at all">Not at all</option>
                                                <option value="Undecided">Undecided</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Was the presented information well balanced and consistently supported by a valid scientific evidence base?</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Very much">Very much</option>
                                                <option value="Somewhat">Somewhat</option>
                                                <option value="Not much">Not much</option>
                                                <option value="Not at all">Not at all</option>
                                                <option value="Undecided">Undecided</option>
                                            </select>
                                        </div>

                                        <p>How useful to you personally was each session?</p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Webinar | Spinal cord pathways</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">Webinar | Physiological aspects of the spinal cord</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Teaching Course: Safety</label>
                                            <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form12">Teaching course: Anesthesia</label>
                                            <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form13">How I do it: Types of electrodes</label>
                                            <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form14">Recipes: SEP</label>
                                            <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form15">How I do it: SEP</label>
                                            <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form16">Teaching course: Warnings in IOM</label>
                                            <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form17">Recipes: MEP</label>
                                            <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form18">How I Do It: MEP</label>
                                            <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form19">Webinar | Intrinsic spinal cord tumors</label>
                                            <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form20">Webinar | Extrinsic spinal cord tumors</label>
                                            <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form21">Teaching course | IOM in endovascular procedures</label>
                                            <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form22">Recipes | D-Wave</label>
                                            <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form23">How I do it | D-Wave</label>
                                            <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form24">Recipes | DC-Mapping</label>
                                            <select name="credits-form24" id="credits-form24" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form25">How I do it | DC-Mapping</label>
                                            <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form26">Teaching course | IOM of spinal cord tumors</label>
                                            <select name="credits-form26" id="credits-form26" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form27">Grand round | Lesson from spinal cord tumor</label>
                                            <select name="credits-form27" id="credits-form27" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form28">Teaching course | IOM in scoliosis</label>
                                            <select name="credits-form28" id="credits-form28" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form29">Grand round | Lesson from scoliosis</label>
                                            <select name="credits-form29" id="credits-form29" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form30">Webinar | Scoliosis surgery</label>
                                            <select name="credits-form30" id="credits-form30" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form31">Webinar | Spinal fusions surgery</label>
                                            <select name="credits-form31" id="credits-form31" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form32">Recipes | Spinal Reflexes</label>
                                            <select name="credits-form32" id="credits-form32" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form33">How I do it | Spinal Reflexes</label>
                                            <select name="credits-form33" id="credits-form33" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form34">Recipes | Cauda equina mapping</label>
                                            <select name="credits-form34" id="credits-form34" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form35">How I do it | Cauda equina mapping</label>
                                            <select name="credits-form35" id="credits-form35" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form36">Grand round | Lesson from cauda equina</label>
                                            <select name="credits-form36" id="credits-form36" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form37">Recipes | Pedical screw mapping</label>
                                            <select name="credits-form37" id="credits-form37" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form38">How I do it | Pedical screw mapping</label>
                                            <select name="credits-form38" id="credits-form38" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form39">Teaching course | IOM of spinal fusions</label>
                                            <select name="credits-form39" id="credits-form39" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form40">Grand round | Lesson from spinal fussions</label>
                                            <select name="credits-form40" id="credits-form40" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form41">Webinar | Surgery of tethered cord</label>
                                            <select name="credits-form41" id="credits-form41" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form42">Webinar | Descending aorta surgery</label>
                                            <select name="credits-form42" id="credits-form42" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form43">Improving intraoperative evoked potentials at short latency by a novel neuro-stimulation technology with delayed return discharge</label>
                                            <select name="credits-form43" id="credits-form43" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form44">Teaching course | IOM in carotid procedures</label>
                                            <select name="credits-form44" id="credits-form44" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form45">Grand round | Lesson from carotid monitoring</label>
                                            <select name="credits-form45" id="credits-form45" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form46">Teaching course | IOM in aorta procedures</label>
                                            <select name="credits-form46" id="credits-form46" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form47">Grand round | Lesson from aorta monitoring</label>
                                            <select name="credits-form47" id="credits-form47" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form48">MASTERCLASS | Clinical Neurophysiology of the Injured Spinal Cord</label>
                                            <select name="credits-form48" id="credits-form48" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form49">Chicago Presentation</label>
                                            <select name="credits-form49" id="credits-form49" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Extremely useful">Extremely useful</option>
                                                <option value="Useful">Useful</option>
                                                <option value="Fairly useful">Fairly useful</option>
                                                <option value="Not useful">Not useful</option>
                                                <option value="Undecided/DNA">Undecided/DNA</option>
                                            </select>
                                        </div>


                                        <p><strong><u>3) Suitability of formats used during the event</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form50">Was there adequate time available for discussions, questions & answers and learner engagement?</label>
                                            <select name="credits-form50" id="credits-form50" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Yes, always/almost always">Yes, always/almost always</option>
                                                <option value="Yes, sometimes">Yes, sometimes</option>
                                                <option value="Only rarely">Only rarely</option>
                                                <option value="Never">Never</option>
                                                <option value="Undecided">Undecided</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form51">Can you indicate any innovative elements during the activity? </label>
                                            <textarea id="credits-form51" name="credits-form51" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>4) Ways the event affects clinical practice</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form52">Will the information you learnt be implemented in your practice?</label>
                                            <select name="credits-form52" id="credits-form52" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Very much">Very much</option>
                                                <option value="Somewhat">Somewhat</option>
                                                <option value="Not much">Not much</option>
                                                <option value="Not at all">Not at all</option>
                                                <option value="Undecided">Undecided</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form53">Can you provide ONE example how this event will influence your future practice? </label>
                                            <textarea id="credits-form53" name="credits-form53" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>5) Commercial bias</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form54">Did all the faculty members provide their potential conflict of interest declaration with the sponsor(s) as a second slide of their presentation? </label>
                                            <select name="credits-form54" id="credits-form54" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Yes, all">Yes, all</option>
                                                <option value="Yes, for the majority">Yes, for the majority</option>
                                                <option value="Yes, but only a small part">Yes, but only a small part</option>
                                                <option value="No">No</option>
                                                <option value="Undecided/ don’t know">Undecided/ don’t know</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form55">Can you provide an example of biased presentation in this activity? </label>
                                            <textarea id="credits-form55" name="credits-form55" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form56">Do you agree that the information was overall free of commercial and other bias?  </label>
                                            <select name="credits-form56" id="credits-form56" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="Strongly agree">Strongly agree</option>
                                                <option value="Rather agree">Rather agree</option>
                                                <option value="Rather disagree">Rather disagree</option>
                                                <option value="Strongly disagree">Strongly disagree</option>
                                                <option value="Undecided/ don’t know">Undecided/ don’t know</option>
                                            </select>
                                        </div>


                                    </div>

                                    : null
                                }


                                {this.props.eventId == "367" ?

                                    <div>

                                        <p><strong>HOJA DE EVALUACIÓN GENERAL DEL CONGRESO</strong></p>

                                        <p>Señale su grado de acuerdo con las siguientes afirmaciones teniendo en cuenta que puntuar 0 supone estar totalmente en desacuerdo y puntual 10 supone estar totalmente de acuerdo. Recuerde que sus respuestas son anónimas.</p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">Se ha cumplido el horario del programa</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos del congreso me han resultado interesantes</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los contenidos del congreso han sido útiles para mi trabajo</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La plataforma en que se han desarrollado ha sido adecuada</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">Los ponentes han conseguido generar interés</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">Los ponentes han demostrado ser expertos en su campo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">En general, la organización del congreso ha sido correcta</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Se han cumplido mis expectativas en relación con este congreso</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Sesgo comercial</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">Comentarios u observaciones:</label>
                                            <textarea id="credits-form10" name="credits-form10" rows="10" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value)}></textarea>
                                        </div>

                                    </div>

                                    : null

                                }

                                {this.props.eventId == "315" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN SOBRE EL DESARROLLO Y ORGANIZACIÓN DEL CURSO V PRACTICVM DE DERMATOLOGÍA PEDIÁTRICA_Madrid 2021 12-13 marzo_Edición Virtual</strong></p>

                                        <p><strong><u>1) ESPECIALIDAD DEL ASISTENTE</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">Por favor, indique su especialidad</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Pediatría">Pediatría</option>
                                                <option value="Dermatología">Dermatología</option>
                                                <option value="Otra. Por favor indique cuál: ">Otra. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>2) OBJETIVOS</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">¿El Curso ha cubierto sus expectativas?</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Sí </option>
                                                <option value="Buena">No </option>
                                                <option value="Regular">A medias</option>
                                            </select>
                                            <label htmlFor="credits-form2-otra">¿Qué ha faltado? </label>
                                            <textarea id="credits-form2-otra" name="credits-form2-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>

                                        <p><strong><u>3) ORGANIZACIÓN</u></strong></p>
                                        <label htmlFor="credits-form4">Por favor, valore los siguientes aspectos del Curso.</label>
                                        <br></br>
                                        <label htmlFor="credits-form4">Utilice una escala del 1 al 5 donde 1 es MALO y 5 es EXCELENTE.</label>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">3.1 Organización del Curso en general</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">3.2 Temas tratados</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">3.3 Duración de las charlas teóricas</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">3.4 Tiempo dedicado al coloquio</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">3.5 Participación en el coloquio por medio de chat</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">3.6 Duración del Curso</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong><u>4) PONENCIAS</u></strong></p>
                                        <label htmlFor="credits-form9-a">Por favor, valore calidad de las ponencias.</label>
                                        <br></br>
                                        <label htmlFor="credits-form9-b">Utilice una escala del 1 al 5 donde 1 es MALO y 5 es EXCELENTE.</label>

                                        <p><strong>4.1 Manchas buenas, feas y malas: Dra. Angela Hernández</strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Claridad de la exposición  </label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">Aplicación práctica   </label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.2 Yo lo miro todo con lupa: Dra. Lucero Noguer</strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Claridad de la exposición  </label>
                                            <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form12">Aplicación práctica   </label>
                                            <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.3 Mosaicismo para principiantes: Dr. Antonio Torrelo </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form13">Claridad de la exposición  </label>
                                            <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form14">Aplicación práctica   </label>
                                            <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.4 Ronchas que pican: Dr. Pablo Rodríguez </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form15">Claridad de la exposición  </label>
                                            <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form16">Aplicación práctica   </label>
                                            <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.5 Inteligencia artificial: Dr. Antonio Nuñez </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form17">Claridad de la exposición  </label>
                                            <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form18">Aplicación práctica   </label>
                                            <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.6 10 FAQS en nevus melanocíticos: Dra. Angela Hernández </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form19">Claridad de la exposición  </label>
                                            <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form20">Aplicación práctica   </label>
                                            <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.7 Lesiones insólitas: Dr Angel Vera </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form21">Claridad de la exposición  </label>
                                            <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form22">Aplicación práctica   </label>
                                            <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.8 Manchas fresa: Dra. Eulalia Baselga  </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form23">Claridad de la exposición  </label>
                                            <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form24">Aplicación práctica   </label>
                                            <select name="credits-form24" id="credits-form24" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.9 Les das la mano y te enseñan el pie: Dr. Raúl de Lucas  </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form25">Claridad de la exposición  </label>
                                            <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form26">Aplicación práctica   </label>
                                            <select name="credits-form26" id="credits-form26" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.10	Con la venia: Prof. Carmen Glez León  </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form27">Claridad de la exposición  </label>
                                            <select name="credits-form27" id="credits-form27" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form28">Aplicación práctica   </label>
                                            <select name="credits-form28" id="credits-form28" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><strong>4.11 Qué hay de nuevo en Dermatología y Pediatría: Dra Raquel Jiménez  </strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form29">Claridad de la exposición  </label>
                                            <select name="credits-form29" id="credits-form29" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form30">Aplicación práctica   </label>
                                            <select name="credits-form30" id="credits-form30" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><strong><u>5) ORGANIZACIÓN Y LOGÍSTICA</u></strong></p>
                                        <label htmlFor="credits-form4">Utilice una escala del 1 al 5 donde 1 es MALO y 5 es EXCELENTE.</label>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form31">5.1 Secretaría Técnica</label>
                                            <select name="credits-form31" id="credits-form31" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form32">5.2 Facilidad para inscribirse en el Curso</label>
                                            <select name="credits-form32" id="credits-form32" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><strong><u>6) DIFUSIÓN</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form33">¿Cómo se enteró de la celebración del Curso?</label>
                                            <select name="credits-form33" id="credits-form33" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="A través de Internet">A través de Internet (foros de profesionales, páginas de sociedades científicas, etc)</option>
                                                <option value="A través de E- mail">A través de E- mail</option>
                                                <option value="A través del Centro de Trabajo">A través del Centro de Trabajo</option>
                                                <option value="Compañeros">Compañeros</option>
                                                <option value="Otros medios">Otros medios</option>
                                            </select>
                                            <label htmlFor="credits-form33-otra">Explicar, por favor </label>
                                            <textarea id="credits-form33-otra" name="credits-form33-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>7) PRÓXIMA EDICIÓN</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form34">¿Está usted interesado en acudir al VI Practicvm de Dermatología Pediátrica en 2022?</label>
                                            <select name="credits-form34" id="credits-form34" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Si">Si</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form35">¿Qué prefiere?</label>
                                            <select name="credits-form35" id="credits-form35" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Edición presencial">Edición presencial</option>
                                                <option value="Edición virtual">Edición virtual </option>
                                            </select>
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form36">¿Desea recibir información cuando se abra el plazo de inscripción?</label>
                                            <select name="credits-form36" id="credits-form36" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Si">Si</option>
                                                <option value="No ">No </option>
                                            </select>
                                        </div>


                                        <p><strong><u>8) SUGERENCIAS (TEXTO LIBRE)</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form37">¿Qué temas desearía que se incluyeran en futuras ediciones del Curso?</label>
                                            <textarea id="credits-form37" name="credits-form37" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form38">¿Qué aspectos del curso podrían mejorar?</label>
                                            <textarea id="credits-form38" name="credits-form38" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }


                                {this.props.eventId == "372" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN</strong></p>

                                        <p><strong>TIPO DE USUARIO</strong></p>

                                        <div className="input-wrapper">

                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Neumólogo">Neumólogo</option>
                                                <option value="Enfermero">Enfermero</option>
                                                <option value="Fisioterapeuta">Fisioterapeuta</option>
                                                <option value="Otros. Por favor indique cuál: ">Otros. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong>VALORE DE 1 A 5 (1: insatisfacción total, 5: satisfacción total)</strong></p>

                                        <p><u>CONTENIDO</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos han cubierto sus expectativas</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los temas se han tratado con la profundidad que esperaba</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>METODOLOGÍA ORGANIZACIÓN</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La duración del curso ha sido adecuada al programa y objetivos</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">La metodología se ha adecuado a los contenidos</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">La plataforma ha sido adecuada para facilitar el proceso formativo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>PROFESORADO</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">El profesor domina la materia</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Los contenidos se han expuesto con la debida claridad</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Motiva y despierta interés en los asistentes</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><u>VALORACIÓN SUGERENCIAS</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">El curso recibido es útil para mi formación</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Señale aspectos que en su opinión permitirían mejorar la calidad de esta acción formativa:</label>
                                            <textarea id="credits-form11" name="credits-form11" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }




                                {this.props.eventId == "373" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN</strong></p>

                                        <p><strong>TIPO DE USUARIO</strong></p>

                                        <div className="input-wrapper">

                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Cirujano">Cirujano torácico</option>
                                                <option value="Neumólogo">Neumólogo</option>
                                                <option value="Enfermero">Enfermero</option>
                                                <option value="Fisioterapeuta">Fisioterapeuta</option>
                                                <option value="Otros. Por favor indique cuál: ">Otros. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong>VALORE DE 1 A 5 (1: insatisfacción total, 5: satisfacción total)</strong></p>

                                        <p><u>CONTENIDO</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos han cubierto sus expectativas</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los temas se han tratado con la profundidad que esperaba</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>METODOLOGÍA ORGANIZACIÓN</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La duración del curso ha sido adecuada al programa y objetivos</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">La metodología se ha adecuado a los contenidos</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">La plataforma ha sido adecuada para facilitar el proceso formativo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>PROFESORADO</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">El profesor domina la materia</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Los contenidos se han expuesto con la debida claridad</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Motiva y despierta interés en los asistentes</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><u>VALORACIÓN SUGERENCIAS</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">El curso recibido es útil para mi formación</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Señale aspectos que en su opinión permitirían mejorar la calidad de esta acción formativa:</label>
                                            <textarea id="credits-form11" name="credits-form11" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }




                                {this.props.eventId == "424" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN</strong></p>

                                        <p><strong>TIPO DE USUARIO</strong></p>

                                        <div className="input-wrapper">

                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Cirujano">Cirujano torácico</option>
                                                <option value="Neumólogo">Neumólogo</option>
                                                <option value="Enfermero">Enfermero</option>
                                                <option value="Fisioterapeuta">Fisioterapeuta</option>
                                                <option value="Otros. Por favor indique cuál: ">Otros. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong>VALORE DE 1 A 5 (1: insatisfacción total, 5: satisfacción total)</strong></p>

                                        <p><u>CONTENIDO</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos han cubierto sus expectativas</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los temas se han tratado con la profundidad que esperaba</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>METODOLOGÍA ORGANIZACIÓN</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La duración del curso ha sido adecuada al programa y objetivos</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">La metodología se ha adecuado a los contenidos</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">La plataforma online ha sido adecuada para facilitar el proceso formativo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>PROFESORADO</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">El profesor domina la materia</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Los contenidos se han expuesto con la debida claridad</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Motiva y despierta interés en los asistentes</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><u>VALORACIÓN SUGERENCIAS</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">El curso recibido es útil para mi formación</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Señale aspectos que en su opinión permitirían mejorar la calidad de esta acción formativa:</label>
                                            <textarea id="credits-form11" name="credits-form11" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }



                                {this.props.eventId == "425" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN</strong></p>

                                        <p><strong>TIPO DE USUARIO</strong></p>

                                        <div className="input-wrapper">

                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Cirujano">Cirujano torácico</option>
                                                <option value="Neumólogo">Neumólogo</option>
                                                <option value="Enfermero">Enfermero</option>
                                                <option value="Fisioterapeuta">Fisioterapeuta</option>
                                                <option value="Otros. Por favor indique cuál: ">Otros. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong>VALORE DE 1 A 5 (1: insatisfacción total, 5: satisfacción total)</strong></p>

                                        <p><u>CONTENIDO</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos han cubierto sus expectativas</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los temas se han tratado con la profundidad que esperaba</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>METODOLOGÍA ORGANIZACIÓN</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La duración del curso ha sido adecuada al programa y objetivos</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">La metodología se ha adecuado a los contenidos</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">La plataforma online ha sido adecuada para facilitar el proceso formativo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>PROFESORADO</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">El profesor domina la materia</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Los contenidos se han expuesto con la debida claridad</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Motiva y despierta interés en los asistentes</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><u>VALORACIÓN SUGERENCIAS</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">El curso recibido es útil para mi formación</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Señale aspectos que en su opinión permitirían mejorar la calidad de esta acción formativa:</label>
                                            <textarea id="credits-form11" name="credits-form11" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }


                                {this.props.eventId == "379" ?

                                    <div>

                                        <p><strong>ENCUESTA DE SATISFACCIÓN</strong></p>

                                        <p><strong>TIPO DE USUARIO</strong></p>

                                        <div className="input-wrapper">

                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Cirujano">Cirujano torácico</option>
                                                <option value="Neumólogo">Neumólogo</option>
                                                <option value="Enfermero">Enfermero</option>
                                                <option value="Fisioterapeuta">Fisioterapeuta</option>
                                                <option value="Otros. Por favor indique cuál: ">Otros. Por favor indique cuál: </option>
                                            </select>
                                            <textarea id="credits-form1-otra" name="credits-form1-otra" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong>VALORE DE 1 A 5 (1: insatisfacción total, 5: satisfacción total)</strong></p>

                                        <p><u>CONTENIDO</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Los contenidos han cubierto sus expectativas</label>
                                            <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">Los temas se han tratado con la profundidad que esperaba</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>METODOLOGÍA ORGANIZACIÓN</u></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">La duración del curso ha sido adecuada al programa y objetivos</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">La metodología se ha adecuado a los contenidos</label>
                                            <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">Las condiciones ambientales (aula, mobiliario, recursos utilizados) han sido adecuadas para facilitar el proceso formativo</label>
                                            <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <p><u>PROFESORADO</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">El profesor domina la materia</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">Los contenidos se han expuesto con la debida claridad</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">Motiva y despierta interés en los asistentes</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>


                                        <p><u>VALORACIÓN SUGERENCIAS</u></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">El curso recibido es útil para mi formación</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Señale aspectos que en su opinión permitirían mejorar la calidad de esta acción formativa:</label>
                                            <textarea id="credits-form11" name="credits-form11" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>



                                    </div>

                                    : null
                                }



                                {this.props.eventId == "376" ?

                                    <div>

                                        <p><strong>EACCME® Evaluación del participante</strong></p>

                                        <p><strong><u>1) Calidad del evento</u></strong></p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form1">¿Encontró esta actividad útil para su actividad profesional?</label>
                                            <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy útil">Muy útil</option>
                                                <option value="Útil">Útil</option>
                                                <option value="Poco Útil">Poco Útil</option>
                                                <option value="Nada Útil">Nada Útil</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form2">Si esta actividad no ha sido útil, por favor explique porqué:</label>
                                            <textarea id="credits-form2" name="credits-form2" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form3">¿Cúal fue su impresión general de este evento? - Programa</label>
                                            <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form4">¿Cúal fue su impresión general de este evento? - Organización</label>
                                            <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Buena">Buena</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Pobre">Pobre</option>
                                                <option value="Muy pobre">Muy pobre</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form5">¿Cual ha sido el mejor aspecto de este evento? </label>
                                            <textarea id="credits-form5" name="credits-form5" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form6">¿Cúal ha sido el peor aspecto de este evento?  </label>
                                            <textarea id="credits-form6" name="credits-form6" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>2) Importancia del evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form7">¿Cumplió la actividad con los fines educativos y los resultados de aprendizaje esperados?</label>
                                            <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form8">¿La información presentada estaba respaldada y equilibrada por una base de evidencia científica válida?</label>
                                            <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <p>¿Le fueron de utilidad las diferentes sesiones?</p>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form9">CURSO CIRUGÍA RADIOGUIADA. TODO LO QUE DEBES SABER SOBRE LA CIRUGÍA RADIOGUIADA CON 125I SEMILLAS</label>
                                            <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form10">CURSO SERFA</label>
                                            <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>

                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form11">Comunicaciones Orales. Las conquistas de las Cirugías Radioguiadas</label>
                                            <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form12">HACIA LA CIRUGÍA DE PRECISIÓN MOLECULARMENTE DIRIGIDA</label>
                                            <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form13">SIMPOSIO TERUMO: Radioterapia interna selectiva con microesferas de Holmio-166. Experiencia en el Hospital Germans Trias i Pujol</label>
                                            <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form14">SIMPOSIO BOSTON SCIENTIFIC. Optimización de la planificación de radioembolización con microesferas de Y-90</label>
                                            <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form15">18F-FDG PET CARDIACO EN INFECCIÓN E INFLAMACIÓN CARDIO-VASCULAR</label>
                                            <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form16">JORNADAS SERFA</label>
                                            <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form17">Comunicaciones Orales. Top 10 de la Oncología Nuclear</label>
                                            <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form18">ACTUALIZACIÓN EN CÁNCER DE PRÓSTATA</label>
                                            <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form19">Sesión patrocinada por Bayer</label>
                                            <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form20">SIMPOSIO CURIUM PHARMA. Uso del PET/CT PSMA en Cáncer de próstata. Entorno clínico y normativo</label>
                                            <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form21">NEUROIMAGEN. ACTUALIZACIÓN EN EL DIAGNÓSTICO DE LA ENCEFALITIS</label>
                                            <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form22">Comunicaciones Orales. Radiofarmacia</label>
                                            <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form23">SIMPOSIO PFIZER. Amiloidosis cardíaca por transtiretina: ¿por dónde empezar?</label>
                                            <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form24">Comunicaciones Orales. Medicina Nuclear en el medrar de la neurociencia</label>
                                            <select name="credits-form24" id="credits-form24" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form25">COVID-19 Y MEDICINA NUCLEAR</label>
                                            <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form26">SIMPOSIO AAA</label>
                                            <select name="credits-form26" id="credits-form26" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form27">SIMPOSIO GENERAL ELECTRIC. PET/CT: Última generación de equipos digitales y reconstrucciones</label>
                                            <select name="credits-form27" id="credits-form27" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form28">PAPEL DE LA RADIOTERAPIA Y OTROS TRATAMIENTOS LOCALES EN EL CÁNCER DIFERENCIADO DE TIROIDES</label>
                                            <select name="credits-form28" id="credits-form28" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form29">SIMPOSIO SIRTEX. SIR-Spheres® Y90 : Optimizando el tratamiento SIRT de tumores hepáticos</label>
                                            <select name="credits-form29" id="credits-form29" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form30">Simposio Spectrum. Experiencias de Rutina Clínica con Cámara Digitales : VERITON-CT & D-SPECT</label>
                                            <select name="credits-form30" id="credits-form30" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form31">Comunicaciones Orales. Endocrinología nuclear, gastroenterología y otros clásicos muy actuales</label>
                                            <select name="credits-form31" id="credits-form31" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form32">MUSCULOESQUELÉTICO. RETOS DE LA MEDICINA NUCLEAR EN LA PATOLOGÍA OSTEOARTICULAR PEDIÁTRICA</label>
                                            <select name="credits-form32" id="credits-form32" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="Nada">Nada</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <p><strong><u>3) Idoneidad de los formatos utilizados en el evento</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form33">¿Consideró que hubo tiempo suficiente para los debates, las preguntas y respuestas y la participación de los alumnos? </label>
                                            <select name="credits-form33" id="credits-form33" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Sí, Siempre/ casi siempre">Sí, Siempre/ casi siempre</option>
                                                <option value="Sí, a veces">Sí, a veces</option>
                                                <option value="Rara vez">Rara vez</option>
                                                <option value="Nunca">Nunca</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>



                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form34">¿Puede indicar algún elemento innovador durante la actividad? </label>
                                            <textarea id="credits-form34" name="credits-form34" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>4) Formas en que el evento afecta la práctica clínica</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form35">¿La información aprendida la implementará en su práctica? </label>
                                            <select name="credits-form35" id="credits-form35" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Mucho">Mucho</option>
                                                <option value="Regular">Regular</option>
                                                <option value="No mucho">No mucho</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form36">¿Puede dar un ejemplo de cómo esta actividad influirá en su futura práctica? </label>
                                            <textarea id="credits-form36" name="credits-form36" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <p><strong><u>5) Sesgo comercial</u></strong></p>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form37">¿Todos los ponentes proporcionaron su potencial declaración de conflicto de intereses con el patrocinador o patrocinadores como una segunda diapositiva de su presentación? </label>
                                            <select name="credits-form37" id="credits-form37" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Si, todos">Si, todos</option>
                                                <option value="Si, la mayoría">Si, la mayoría</option>
                                                <option value="Si, solo una parte">Si, solo una parte</option>
                                                <option value="No">No</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form38">¿Puede dar un ejemplo de presentación sesgada en esta actividad? </label>
                                            <textarea id="credits-form38" name="credits-form38" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                        </div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form39">¿Está de acuerdo en que la información estaba en general libre de sesgos comerciales y de otro tipo?  </label>
                                            <select name="credits-form39" id="credits-form39" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Por favor seleccione</option>
                                                <option value="Muy de acuerdo">Muy de acuerdo</option>
                                                <option value="De acuerdo">De acuerdo</option>
                                                <option value="Desacuerdo">Desacuerdo</option>
                                                <option value="Muy en desacuerdo">Muy en desacuerdo</option>
                                                <option value="NS/NC">NS/NC</option>
                                            </select>
                                        </div>


                                    </div>

                                    : null
                                }









                                {this.props.eventId == "32000" ?

                                    <div>


                                        <div className="input-wrapper">
                                            <label htmlFor="credits-form54">From 1 to 10, how sustainable are your research activities?<br />Being 10 the most sustainable and 1 the least)</label>
                                            <select name="credits-form54" id="credits-form54" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                <option value="" defaultValue>Please select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-1">

                                            <p><strong>A bit about yourself…</strong></p>

                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form1">1. Age:</label>
                                                <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Between 25 and 35">Between 25 and 35</option>
                                                    <option value="Between 35 and 50">Between 35 and 50</option>
                                                    <option value="Between 50 and 65">Between 50 and 65</option>
                                                    <option value="More than 65">More than 65</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form2">2. Gender:</label>
                                                <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Prefer not to say">Prefer not to say</option>

                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form3">3. How many years have you been working in research?:</label>
                                                <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Less than 2 years">Less than 2 years</option>
                                                    <option value="Between 2 and 5 years">Between 2 and 5 years</option>
                                                    <option value="Between 5 and 10 years">Between 5 and 10 years</option>
                                                    <option value="Between 10 and 20 years">Between 10 and 20 years</option>
                                                    <option value="More than 20 years">More than 20 years</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form4">4. What type of organization do you work for?</label>
                                                <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="University">University</option>
                                                    <option value="NGO">NGO</option>
                                                    <option value="Private laboratory">Private laboratory</option>
                                                    <option value="Private practice">Private practice</option>
                                                    <option value="National bodies (e.g. state laboratories)">National bodies (e.g. state laboratories)</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form5">5. What is your position?</label>
                                                <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Undergraduate student">Undergraduate student</option>
                                                    <option value="Master student">Master student</option>
                                                    <option value="Doctoral / PhD student">Doctoral / PhD student</option>
                                                    <option value="Researcher">Researcher</option>
                                                    <option value="Intern">Intern</option>
                                                    <option value="Resident">Resident</option>
                                                    <option value="Licensed veterinarian">Licensed veterinarian</option>
                                                    <option value="Academic">Academic</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form6">6. Anything else you would like us to know about you?</label>
                                                <textarea id="credits-form6" name="credits-form6" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                            </div>


                                        </div>

                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-2">


                                            <p><strong>General awareness</strong></p>

                                            <p>This section contains some questions about general awareness and education on sustainability in your organization/institution. Please select the most appropriate answer </p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form7">7. Does your organization have a sustainability /green policy? </label>
                                                <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form8">If so, is it publicly accessible?</label>
                                                <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form9">If you answered yes to the previous question, please add the name of your organization:</label>
                                                <textarea id="credits-form9" name="credits-form9" rows="1" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>

                                            </div>




                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form10">8.Are researchers, instructors, students, assistants, and other staff in your institution educated about sustainability in the lab? </label>
                                                <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form11">9 Does your organization have any planned/ongoing sustainability project or initiative?  </label>
                                                <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form12">10.	Are there any initiatives in your laboratory to raise the awareness of staff about sustainability in the lab? </label>
                                                <select name="credits-form12" id="credits-form12" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form13">11.	Are researchers, instructors, students, assistants, and other staff in your institution educated about sustainability in the field? </label>
                                                <select name="credits-form13" id="credits-form13" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form14">12. Is sustainability discussed before students and staff arrive in the field?</label>
                                                <select name="credits-form14" id="credits-form14" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form15">13. Does your institution have a sustainability contract for researchers to sign before leaving for fieldwork? </label>
                                                <select name="credits-form15" id="credits-form15" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form16">14. Does your institution have a minimum impact protocol in the guidelines for fieldwork? </label>
                                                <select name="credits-form16" id="credits-form16" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>




                                        </div>


                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-3">

                                            <p><strong>Habits in the lab</strong></p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form17">Do you currently work in a laboratory or have worked in one in the past?</label>
                                                <select name="credits-form17" id="credits-form17" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>

                                            <p>This section contains some questions about sustainable habits in the different laboratories of your organization/institution. Please select the most appropriate answer</p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form18">15.	How is the temperature of your laboratory regulated?</label>
                                                <select name="credits-form18" id="credits-form18" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Each room individually by the individuals using the room">Each room individually by the individuals using the room</option>
                                                    <option value="Each room individually by a certain person in charge">Each room individually by a certain person in charge</option>
                                                    <option value="The whole building by a certain person in charge">The whole building by a certain person in charge</option>
                                                    <option value="I do not know">I do not know</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form19">16. Are equipment, chemicals and/or surplus of chemicals shared between labs in your institution?</label>
                                                <select name="credits-form19" id="credits-form19" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form20">17. When incinerators are used, is there an effort to reduce the input?</label>
                                                <select name="credits-form20" id="credits-form20" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form21">18. 1.	When incinerators are used, is the heat generated used for something else? </label>
                                                <select name="credits-form21" id="credits-form21" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form22">19.	Are you aware if there is an effort to using more environmentally friendly chemicals for cleaning? </label>
                                                <select name="credits-form22" id="credits-form22" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form23">20.	Is mercury used in your lab? </label>
                                                <select name="credits-form23" id="credits-form23" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form24">21. Are there any efforts in your lab towards minimizing the use of chemicals?</label>
                                                <select name="credits-form24" id="credits-form24" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form25">22.	Is glass used instead of disposable plastic whenever useful?</label>
                                                <select name="credits-form25" id="credits-form25" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="When possible, it is used.">When possible, it is used.</option>
                                                    <option value="Rarely">Rarely</option>
                                                    <option value="No glass material is used.">No glass material is used.</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form26">23.	Do your suppliers take back material for recycling? </label>
                                                <select name="credits-form26" id="credits-form26" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form27">24. Is there an appropriate disposal of chemicals in your lab?</label>
                                                <select name="credits-form27" id="credits-form27" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form28">25.	Do you use and maintain a chemical inventory?</label>
                                                <select name="credits-form28" id="credits-form28" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form29">26.	Do you turn off devices when not needed (e.g. fume hood)?</label>
                                                <select name="credits-form29" id="credits-form29" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="Irregularly">Irregularly</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form30">27. Do you try to use the smallest number or smallest size of plastic items possible during your work activities? </label>
                                                <select name="credits-form30" id="credits-form30" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                        </div>



                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-4">


                                            <p><strong>Habits in the office and computer usage</strong></p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form31">Does part/most/all of your current work take place at an office or have you worked in one in the past?</label>
                                                <select name="credits-form31" id="credits-form31" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>


                                            <p>This section contains some questions about sustainable habits in the offices of your organization/institution and electronic usage. Please select the most appropriate answer (tick the box).</p>

                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form32">28. How is the temperature of your office space regulated?</label>
                                                <select name="credits-form32" id="credits-form32" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Each room individually by the individuals using the room">Each room individually by the individuals using the room</option>
                                                    <option value="Each room individually by a certain person in charge">Each room individually by a certain person in charge</option>
                                                    <option value="The whole building by a certain person in charge">The whole building by a certain person in charge</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form33">29.	Are the routers and modems turned off at night? </label>
                                                <select name="credits-form33" id="credits-form33" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="Sometimes">I do not know or no answer</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form34">30.	Are computers from your facility recycled when no longer needed? </label>
                                                <select name="credits-form34" id="credits-form34" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="Sometimes">I do not know or no answer</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form35">31.	How often are the computers upgraded at your organization? </label>
                                                <select name="credits-form35" id="credits-form35" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Less than 5 years">Less than 5 years</option>
                                                    <option value="Between each 5 and 10 years">Between each 5 and 10 years</option>
                                                    <option value="More than each 10 years">More than each 10 years</option>
                                                    <option value="I don’t know">I don’t know</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form36">32.	Where is your research data stored? (choose 1 or more options) </label>
                                                <select name="credits-form36" id="credits-form36" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Hard disk">Hard disk</option>
                                                    <option value="Cloud">Cloud</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form37">33. What devices do you use in your workspace without sharing? (choose 1 or more options).</label>
                                                <select name="credits-form37" id="credits-form37" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="TV">TV</option>
                                                    <option value="Desktop">Desktop</option>
                                                    <option value="Laptop">Laptop</option>
                                                    <option value="Tablet">Tablet</option>
                                                    <option value="Smartphone">Smartphone</option>
                                                    <option value="None of the above">None of the above</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form38">34. Do you turn off your computer when you leave your office? .</label>
                                                <select name="credits-form38" id="credits-form38" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="Sometimes ">Sometimes </option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                        </div>



                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-5">

                                            <p><strong>In vivo research</strong></p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form39">Are you currently working with in vivo laboratory research or have you worked on it in the past?</label>
                                                <select name="credits-form39" id="credits-form39" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>


                                            <p>This section contains some questions related to in vivo research, regarding animal experiments that were performed in the laboratory. Please select the most appropriate answer </p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form40">35. In the past 10 years, how many animals in total were included in in vivo studies in the laboratory that you co-authored?</label>
                                                <select name="credits-form40" id="credits-form40" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Less than 5">Less than 5</option>
                                                    <option value="Between 5 and 15">Between 5 and 15</option>
                                                    <option value="Between 15 and 50">Between 15 and 50</option>
                                                    <option value="Between 50 and 100">Between 50 and 100</option>
                                                    <option value="Between 100 and 200">Between 100 and 200</option>
                                                    <option value="More than 200">More than 200</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form41">36. Were there any efforts to reduce the amount of impact from these animal experiments? (e.g. composting of cage materials, food from sustainable sources, reducing the amount of waste or energy from the animal containment.)</label>
                                                <select name="credits-form41" id="credits-form41" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="No efforts ">No efforts </option>
                                                    <option value="Large efforts ">Large efforts </option>
                                                    <option value="Small efforts ">Small efforts </option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form42">37. Any specific efforts you would like to share with us?</label>
                                                <textarea id="credits-form42" name="credits-form42" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>
                                            </div>


                                        </div>



                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-6">

                                            <p><strong>Field research</strong></p>

                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form43">Do you have any experience in field research?</label>
                                                <select name="credits-form43" id="credits-form43" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>

                                            <p>This section contains some questions related to research done in the field and sustainability at the field sites. Please select the most appropriate answer </p>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form44">38.	In the past 10 years, how many animals in total were included in studies done in the field that you co-authored?</label>
                                                <select name="credits-form44" id="credits-form44" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Less than 5">Less than 5</option>
                                                    <option value="Between 5 and 15">Between 5 and 15</option>
                                                    <option value="Between 15 and 50">Between 15 and 50</option>
                                                    <option value="Between 50 and 100">Between 50 and 100</option>
                                                    <option value="Between 100 and 200">Between 100 and 200</option>
                                                    <option value="More than 200">More than 200</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form45">39.	What type of field research was done in these past studies? (Choose 1 or more options)</label>
                                                <select name="credits-form45" id="credits-form45" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Observations / distance sampling">Observations / distance sampling</option>
                                                    <option value="Biological sampling without direct contact with live animals (e.g. faeces, urine, hair)">Biological sampling without direct contact with live animals (e.g. faeces, urine, hair)</option>
                                                    <option value="Biological sampling including limited contacts">Biological sampling including limited contacts</option>
                                                    <option value="Biological sampling including handling and/or anesthetizing animals">Biological sampling including handling and/or anesthetizing animals</option>
                                                    <option value="Destructive biological sampling (animal sacrifice required)">Destructive biological sampling (animal sacrifice required)</option>
                                                    <option value="Interventions in diseases">Interventions in diseases</option>

                                                </select>
                                            </div>



                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form46">40. Waste management in the field (choose 1 or more options):</label>
                                                <select name="credits-form46" id="credits-form46" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="There was a recycling program">There was a recycling program</option>
                                                    <option value="Recyclable waste was taken to the nearest urban recycling containers">Recyclable waste was taken to the nearest urban recycling containers</option>
                                                    <option value="Waste was burned">Waste was burned</option>
                                                    <option value="Organic waste was composted">Organic waste was composted</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                    <option value="Other">Other</option>

                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form47">41.	Were plastics used in field sampling even if a more sustainable option was available? </label>
                                                <select name="credits-form47" id="credits-form47" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form48">42. How many samples were taken in a single field research activity?  </label>
                                                <select name="credits-form48" id="credits-form48" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Less than 10">Less than 10</option>
                                                    <option value="Between 10 and 50">Between 10 and 50</option>
                                                    <option value="Between 50 and 100">Between 50 and 100</option>
                                                    <option value="More than 100">More than 100</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form49">43. Energy use in the field excluding transport to the site (choose 1 or more options):</label>
                                                <select name="credits-form49" id="credits-form49" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Energy was obtained through solar panels">Energy was obtained through solar panels</option>
                                                    <option value="Energy was publicly available">Energy was publicly available</option>
                                                    <option value="Energy was obtained through gas motors">Energy was obtained through gas motors</option>
                                                    <option value="There was no energy">There was no energy</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form50">44.	Did you observe the presence of consumables or equipment from previous research activities in the field?:</label>
                                                <select name="credits-form50" id="credits-form50" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>

                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form51">45. Did you collect trash you encountered while outside doing transects, observations, sample collections, etc.?:</label>
                                                <select name="credits-form51" id="credits-form51" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I do not know or no answer">I do not know or no answer</option>

                                                </select>
                                            </div>

                                        </div>


                                        <p>&nbsp;</p>
                                        <hr />

                                        <div className="section-7">

                                            <p><strong>You’re done! Just a few questions to wrap it up…</strong></p>

                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form52">46. Did your opinion about sustainable practices change after completing the questions above?</label>
                                                <select name="credits-form52" id="credits-form52" onChange={(event) => this.onSetAnwserHandler(event)}>
                                                    <option value="" defaultValue>Please select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>


                                            <div className="input-wrapper">
                                                <label htmlFor="credits-form53">47.	Do you have ideas or suggestions on how to reduce the impact of research on the environment?</label>
                                                <textarea id="credits-form53" name="credits-form53" rows="5" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value, event)}></textarea>

                                            </div>


                                            <p>Thank you for answering the EWDA sustainability questionnaire! Your answers are extremely valuable. We are looking forward to discussing some of the results of this questionnaire with you at the EWDA conference! Have a nice day </p>

                                            <p>The EWDA Sustainability Committee</p>


                                        </div>


                                    </div>

                                    : null
                                }










                                <div className="input-wrapper">
                                    <input type="submit" value={this.props.t('send')} className="btn-submit" onClick={this.onSurveySaveHandler} />
                                </div>

                            </form>

                            : creditsList}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


export default withTranslation()(MyCredits);