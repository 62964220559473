import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';
import SpeakersCV from '../../../components/SpeakersCV/SpeakersCV';

class SpeakersHtml extends Component {

    static contextType = AuthContext;

    state = {
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false,
        SpeakersCVPopUpOn: false
    }

    //componentDidMount() {}
    //componentDidUpdate() {}


    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    onSpeakersCVOpenHandler = (speakerlink) => {
        this.setState({ SpeakersCVPopUpOn: true, SpeakerName: speakerlink });
        //alert(speakerlink);
    }

    onSpeakersCVCloseHandler = () => {
        this.setState({ SpeakersCVPopUpOn: false, SpeakerName: "" });
    }

    render() {

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;
        const SpeakersCVPopUp = this.state.SpeakersCVPopUpOn ? <SpeakersCV speaker_cv={this.state.SpeakerName} event_id={this.context.event_settings.event_id} ononSpeakersCVClose={this.onSpeakersCVCloseHandler}></SpeakersCV> : null;

        if (this.context.event_settings.event_id == "2") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/urovi2021/lobby/urovi2021-lobby-bck2.jpg")';
        } else if (this.context.event_settings.event_id == "4") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby.jpg")';
        }

        return (
            <React.Fragment>

                {SpeakersCVPopUp}

                {CookiesPolicyPopUp}
                {LegalNoticePopUp}
                {TermsConditionsPopUp}
                {PrivacyPolicyPopUp}
                <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>






                {this.context.event_settings.event_id == "2" ?

                    <div className="programme-speakers-content-container UROVI2021">

                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Jose-Luis-Alvarez-Ossorio.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. José Luis Álvarez-Ossorio Fernández.<br />
                                            Director de la UGC de Urología del Hospital Universitario Puerta del Mar (Cádiz). Vocal de Actividades Científicas de la AEU.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />
                                        <ul>
                                            <li>Jefe de Servicio y Director de la Unidad de Gestión Clínica de Urología de Hospital Universitario Puerta del Mar de Cádiz. Profesor Asociado de Urología en la Facultad de Medicina de Cádiz. Obtuvo el grado de licenciatura en 1982 y se doctoró en Medicina por la Universidad de Cádiz en 1990: “Correlación anatomoclínica de los parámetros morfométricos del carcinoma prostático”. </li>

                                            <li>Vocal de Actividades Científicas de la Asociación Española de Urología. Coordinador Nacional de Endourología. Laparoscopia y Robótica Urológica de la Asociación Española de Urología desde el año 2012 al 2016. Autor de múltiples publicaciones en revistas de la especialidad y participante en congresos y reuniones como ponente de las áreas de su interés. Investigador principal en diversos Ensayos Clínicos fase II, III y IV.</li>

                                            <li>Acreditado en nivel Excelente por la Agencia de Calidad Sanitaria de Andalucía.</li>

                                            <li>Es miembro de la Asociación Española de Urología, Asociación Europea de Urología, Asociacion Americana de Urología , de la Sociedad Española de Cirugía Laparoscópica, de la Sociedad Iberoamericana de Neurología y Uroginecología así como de las Sociedades Andaluza y Gallega de su especialidad.  </li>

                                        </ul>
                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Victor-Carrero.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Víctor Carrero López<br />
                                            Jefe de Servicio de Urología del Hospital Universitario Infanta Leonor (Madrid) Presidente de la SUM.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <ul>
                                            <li>JEFE DE SERVICIO DE UROLOGIA DEL HOSPITAL UNIVERSITARIO INFANTA LEONOR</li>
                                            <li>PROFESOR ASOCIADO DE UROLOGIA DE LA UNIVESIDAD COMPLUTENSE DE MADRID</li>
                                            <li>PRESIDENTE DE LA SOCIEDAD UROLÓGICA MADRILEÑA (SUM)</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>



                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Jose-Manuel-Cozar.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. José Manuel Cózar Olmo<br />
                                            Hospital Universitario Virgen de las Nieves de Granada. Director del Patronato rector de la FIU.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />
                                        <ul>

                                            <li>LICENCIADO EN MEDICINA Y CIRUGIA POR LA FACULTAD DE MEDICINA DE GRANADA.</li>
                                            <li>DOCTOR EN MEDICINA POR LA FACULTAD DE MEDICINA DE LA UNIVERSIDAD COMPLUTENSE DE MADRID. 1994</li>
                                            <li>MIR EN UROLOGIA EN EL HOSPITAL LA PAZ DE MADRID, 1987-1991.</li>
                                            <li>PROFESOR DE UROLOGIA DE LA FACULTAD DE MEDICINA DE GRANADA 2006-2021. TITULAR POR ANECA DESDE 2013.</li>
                                            <li>JEFE DE SERVICIO H. UNIVERSITARIO VIRGEN DE LAS NIEVES, GRANADA, 2009-2020.</li>
                                            <li>PRESIDENTE DE LA ASOCIACION ESPAÑOLA DE UROLOGIA AEU  Y FIU,  2013 -2017.</li>
                                            <li>DIRECTOR DEL PATRONATO RECTOR DE LA FIU, 2017-2021</li>
                                            <li>AUTOR O COAUTOR DE MAS DE 250 ARTICULOS PUBLICADOS EN REVISTAS NACIONALES O INTERNACIONALES SCI-JCR.</li>
                                            <li>OBTENCIÓN DE 24 PREMIOS POR TRABAJOS DE INVESTIGACION.</li>
                                            <li>INVESTIGADOR EN GENETICA DE TM UROLOGICOS EN CENTRO GENYO, GRANADA.</li>


                                        </ul>
                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Mario-Alvarez-Maestro.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Mario Álvarez Maestro<br />
                                            Hospital Universitario La Paz, Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <ul>

                                            <li>Licenciado en Medicina y Cirugía en Universidad Complutense de Madrid (1997-2002).</li>
                                            <li>Médico Residente (MIR) de Urología en Hospital Universitario La Paz, Madrid (2003-2008)</li>
                                            <li>Médico adjunto Servicio Urología Hospital Universitario La Paz, Madrid (desde 2015)</li>
                                            <li>Miembro de múltiples Comités y Sociedades europeas y nacionales sobre Urología y Urología Oncológica.</li>
                                            <li>Profesor en cursos de posgrado impartidos por la Universidad de Salamanca, la Universidad Complutense de Madrid, la Universidad Europea de Madrid y el Centro de Estudios Biosanitarios.</li>
                                            <li>Miembro del equipo editorial de diversas revistas nacionales e internacionales del área urológica.</li>
                                            <li>Autor o coautor de 73 publicaciones internacionales.</li>
                                            <li>Autor o coautor de 17 ponencias en congresos internacionales y asistente en más de 50 congresos y simposios de carácter nacional e internacional.</li>
                                            <li> Investigador Principal de 3 estudios e Investigador Colaborador en 13 ensayos clínicos financiados por diferentes entidades públicas o privadas.</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>



                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Roberto-Ballestero-Diego.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Roberto Ballestero Diego<br />
                                            Hospital Universitario Marqués de Valdecilla, Santander.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        TÍTULOS ACADÉMICOS<br />

                                        <ul>


                                            <li>Licenciado en Medicina por la Facultad de Medicina de la universidad de Cantabria en la XXV promoción (1995-2001).</li>
                                            <li>Títulación de Especialista en Urología tras realización de formación MIR (2003-2008) en el Hospital Universitario Marqués de Valdecilla (Santander)</li>
                                            <li>Fellow of the European Board of Urology (FEBU) en Junio 2015.</li>



                                        </ul>

                                        <br />EJERCICIO PROFESIONAL<br />

                                        <ul>


                                            <li>MÉDICO ESPECIALISTA DE ÁREA de la especialidad de Urología en el hospital Universitario Marqués de Valdecilla (Santander) desde el 2008 hasta la actualidad</li>

                                            <li>Facultativo especialista perteneciente a la Unidad de Litiasis y uro onco-pelvis. Especialmente dedicado a cirugía laparoscópica y robótica oncológica y reconstructiva avanzada. Hospital Universitario Marqués de Valdecilla desde 2008.</li>

                                        </ul>


                                        <br />INFORMACIÓN ESPECÍFICA<br />

                                        <ul>



                                            <li>Miembro de la Asociación Española de Urología desde 2004.</li>

                                            <li>AUTOR Y COAUTOR DE 7 CAPÍTULOS EN LIBROS DEDICADOS A UROLOGÍA</li>

                                            <li>AUTOR Y COAUTOR DE 43 DOCUMENTOS EN REVISTAS DE UROLOGÍA</li>

                                            <li>AUTOR Y COAUTOR DE más 200 PRESENTACIONES EN REUNIONES UROLÓGICAS</li>

                                            <li>PONENTE DE MÁS DE 25 PRESENTACIÓNES EN CONGRESOS/ACTIVIDADES UROLÓGICAS</li>



                                        </ul>


                                        <br />OTROS MÉRITOS DE LA ESPECIALIDAD<br />

                                        <ul>



                                            <li>Miembro de la Asociación Española de Urología desde junio del 2005</li>

                                            <li>Miembro de la Sociedad Cántabra de Urología desde el 2005</li>

                                            <li>Diploma Universitario en cirugía laparoscópica Urológica de la Université de Strasbourg. Estrasburgo 2010.</li>

                                            <li>Instructor de los cursos de cirugía urológica en la sección de Urología del Hospital Virtual Valdecilla desde el año 2008.</li>

                                            <li>Director de los cursos de Urología del Hospital virtual Valdecilla, especialmente del área de laparoscopia y robótica desde 2009.</li>

                                            <li>Curso de acreditación de instalaciones de rayos X con fines diagnósticos. Segundo nivel de formación en protección radiológica orientado a la práctica intervencionista. Santander. Septiembre del 18 al 20 de Noviembre de 2010.</li>

                                            <li>Curso de entrenamiento para instructores. Instructor Training: Simulation as a Teaching tool. Santander 14-17 de Junio de 2011 (28 hrs) por el Center for medical simulation (Harvard University)</li>

                                            <li>Certificate of single-site training as a console surgeon. Da Vinci Single-site training in Paris April 24, 2014.</li>

                                            <li>Proctor robótico de la empresa Abbex</li>


                                        </ul>


                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Manuel-Esteban-Fuertes.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Manuel Esteban Fuertes<br />
                                            Hospital Nacional de Parapléjicos, Toledo. Presidente de la AEU.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <ul>


                                            <li>Jefe de Servicio Urología. Hospital Nacional de Parapléjicos de Toledo (desde 2007)</li>
                                            <li>Presidente electo de la Asociación Española de Urología (junio 2017)</li>
                                            <li>Presidente de la FIU (Fundación para la Investigación en Urología) (junio 2017)</li>
                                            <li>Vicepresidente de la Comisión Nacional de la Especialidad Ministerio de Sanidad, Servicios Sociales e Igualdad.</li>
                                            <li>Coordinador electo Grupo Urología Funcional, Femenina y Urodinámica de la AEU (2011-2014)</li>
                                            <li>Junta Directiva Permanente (Tesorero) electo Asociación Española de Urología(2013-2017)</li>
                                            <li>Patrono de la Fundación para la Investigaciónón en Urología de AEU (desde 2013)</li>
                                            <li>Experto Universitario en Gestión de Unidades Clínicas por la UNED, en colaboración con la Escuela Nacional de Sanidad y el Instituto de Salud Carlos III.</li>
                                            <li>Participación en 28 Comités de Expertos y 5 Direcciones de Grupos Multidisciplinares de Investigación Clínica.</li>
                                            <li>Profesor Asociado Universidad Castilla La Mancha (UCLM).</li>
                                            <li>Director de 11 Tesis Doctorales.</li>
                                            <li>Editor de 24 Libros en Urólogía.</li>
                                            <li>18 Premios/menciones nacionales e internacionales.</li>


                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>




                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Juan-Antonio-Galan.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Juan Antonio Galán Llopis<br />
                                            Hospital General Universitario de Alicante.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />



                                        <ul>



                                            <li>Licenciado en medicina por la Universidad de Valencia (1982-1988)</li>

                                            <li>Médico Residente (MIR) en el Hospital Son Dureta, Palma de Mallorca (1990 – 1994)</li>

                                            <li>Doctor en Medicina y Cirugía por la Universidad de Valencia (1995)</li>

                                            <li>Especialista en Urología, en Hospital General de Castellón (1995), Hospital General Universitario de Elche (1995-2005) y Hospital General Universitario de Alicante (2005-2010)</li>

                                            <li>Jefe del Servicio de Urología del Hospital del Vinalopó (Elche) (2010-2018) y del Servicio de Urología del Hospital General Universitario de Alicante (desde 2019)</li>

                                            <li>Miembro de la Junta Directiva de la SECLA (desde 2015) y de la Sección de Urolitiasis de la EAU (desde 2013).</li>

                                            <li>Profesor Asociado en el Departamento de Patología y Cirugía de la Universidad Miguel Hernández de Elche (2001-2003); y colaborador con las Universidades de Illes Balears, Alicante, Elche, Murcia y Orsay (París).</li>

                                            <li>Autor o coautor de 30 publicaciones internacionales y 68 publicaciones nacionales, así como de 9 capítulos de libros y 1 atlas.</li>

                                            <li>Ha participado como investigador en 7 estudios clínicos de ámbito nacional e internacional.</li>
                                            <li>Revisor habitual de trabajos para los congresos europeo y español de Urología desde hace 5 y 9 años.</li>

                                            <li>Ha asistido a más de 150 cursos y congresos de carácter nacional o internacional.</li>



                                        </ul>



                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Carmen-Gonzalez-Enguita.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dra. Carmen González Enguita<br />
                                            Hospital Universitario Fundación Jiménez Díaz (UAM) y Hospitales Públicos del Grupo Quirón Salud Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <ul>



                                            <li>Licenciada en Medicina y Cirugia por la Universidad de Zaragoza (1979-85)</li>
                                            <li>Médico Interno Residente en el Hospital Universitario Miguel Servet (Zaragoza 1986-90)</li>
                                            <li>Doctora en Medicina y Cirugía por la Universidad de Zaragoza (1991)</li>
                                            <li>Especialista del Servicio de Urología del Hospital Universitario Fundación Jiménez Díaz (HUFJD) (Madrid), con la responsabilidad de creación y desarrollo de la Unidad de Litiasis y LEOC (Litotricia Extracorpórea por Ondas de Choque)</li>
                                            <li>Jefe del Servicio de Urología del HUFJD (desde 2008) y Jefe Corporativo Urología de los Hospitales de Asistencia Pública del Grupo Quirón-Salud en Madrid</li>
                                            <li>Profesor Asociado en Ciencias de la Salud de la Universidad Autónoma de Madrid (desde 2005); y colaboradora con la Universidad Europea de Madrid, Universidad Rey Juan Carlos, Universidad Complutense de Madrid y Universidad de Extremadura.</li>
                                            <li>Su gestión clínica y dirección de equipos ha llevado al Equipo Corporativo de Urología de los Hospitales de asistencia pública, del Grupo QuirónSalud Madrid, a ser reconocido como Mejor Servicio de Urología en Atención al Paciente urológico durante 10 años consecutivos (2011-20) (Premios BIC-Best in Class).  El BIC 2020 es 5 estrellas por la consecucion de varios BIC</li>
                                            <li>Miembro de la Sociedad Urológica de Madrid (SUM), Asociación Española de Urología (AEU), European Association of Urology (EAU), Sociedad IberoAmericana de NeuroUrología y UroGinecología (SINUG), International Continence Society (ICS) y de la Asociación Latino Americana de Piso Pélvico (ALAPP).</li>
                                            <li>Miembro de diferentes Proyectos como E-Res Red (para evaluar los resultados en salud y en experiencia de paciente con dos procesos urológicos) o
                                                y Fundaciones como la Fundación “Miguel Litton” y del equipo de colaboradores de las Brigadas Urológicas;  4H-URO Humanización de la Urología (cura, cuida y adelántate en salud y bienestar urológico con Humildad, Honestidad, Humanidad y Humor)</li>
                                            <li>Ha dirigido o está dirigiendo 10 Tesis Doctorales en los últimos años en la Universidad Autónoma de Madrid y la Universidad Rey Juan Carlos.</li>
                                            <li>Autora de numerosas publicaciones en Revistas Científicas (nacionales e internacional) y capítulos en libros. Pertenece al equipo de redacción de 3 revistas de impacto y es revisor de 5.</li>
                                            <li>Ha recibido diferentes reconocimientos, honores y distinciones como el Premio Dr. Peña (1994) de la Real Academia Nacional de Medicina y mejor Revisor de la Revista Archivos Españoles de Urologia en dos ocasiones (2013 y 2019).</li>
                                            <li>Participa como investigadora en numerosos proyectos y promueve la investigación urológica en el IIS-FJD (Instituto de Investigaciones Sanitarias HUFJD) y es responsable de la investigación urológica del Grupo de Urologia como Grupo Asociado al de investigación pertenecientes al Área de Enfermedades Renales, Metabólicas y Cardiovasculares.</li>




                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>





                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Pilar-Gonzalez-Peramato.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dra. Pilar González-Peramato<br />
                                            Hospital Universitario La Paz, Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />



                                        <ul>

                                            <li>Licenciada en Medicina y Cirugía en Universidad de Extremadura (1976-1983).</li>

                                            <li>Médico Residente (MIR) de Anatomía Patológica en Hospital Universitario La Paz, Madrid (1985-1989).</li>

                                            <li>Doctora en Medicina por la Universidad Autónoma de Madrid (UAM) (1989).</li>

                                            <li>Especialista en Anatomía Patológica (1989).</li>

                                            <li>3 estancias en el extranjero (Washington DC y 2 en Nicaragua); y una estancia nacional en el Hospital Universitario La Paz de Madrid.</li>

                                            <li>Jefa de Sección de Uropatología en el Servicio de Anatomía Patológica en el Hospital Universitario La Paz, Madrid (desde 2016).</li>

                                            <li>Profesora Titular de Anatomía Patológica en la Facultad de Medicina de UAM (desde 2011).</li>

                                            <li>Directora Dpto de Anatomía Patológica de la Facultad de Medicina de UAM (desde 2017).</li>

                                            <li>Profesora en 12 Cursos de Doctorado y 43 cursos de Citología y Uropatología en Programa de Formación Continuada. </li>

                                            <li>Autora o coautora de 130 publicaciones en revistas, 4 libros y 59 capítulos de libros, nacionales e internacionales; así como de 3 monografías.</li>

                                            <li>Ponente en 53 congresos (21 internacionales y 32 nacionales) y autora o coautora de 233 comunicaciones orales y pósteres.</li>

                                            <li>Investigador Principal de 2 proyectos e Investigador Colaborador en 11 proyectos de Investigación financiados por diferentes entidades públicas o privadas.</li>


                                        </ul>



                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Roberto-Llarena-Ibarguren.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Roberto Llarena Ibarguren<br />
                                            Hospital Universitario de Cruces, Bilbao.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <ul>




                                            <li>Licenciado en Medicina y Cirugía en la Universidad del Pais Vasco (UPV-EHU) en 1982.</li>
                                            <li>MIR en el Hospital Universitario de Cruces en el Servicio de Urología entre 1984 y 1989.</li>
                                            <li>FEA en el mismo Servicio desde 1989 hasta 2011.</li>
                                            <li>Jefe de Seccion de Urología Clínica y Oncologia desde 2011.</li>
                                            <li>Profesor Asociado de Cirugía II en la Facultad de Medicina de la UPV-EHU desde 2001.</li>




                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>





                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Juan-Antonio-Mainez-Rodriguez.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Juan Antonio Mainez Rodríguez<br />
                                            Hospital Universitario La Paz, Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />


                                        <br />DATOS ACADEMICOS<br />


                                        <ul>


                                            <li>Licenciado en Medicina y Cirugía. Universidad Complutense, Madrid. 2001-2007.</li>
                                            <li>Especialista en Urología vía MIR en Hospital Universitario Río Hortega, Valladolid. 2010-2015.</li>


                                        </ul>


                                        <br />LUGAR DE TRABAJO ACTUAL:<br />


                                        <ul>


                                            <li>Facultativo Especialista Adjunto en el Servicio de Urología Hospital Universitario La Paz, Madrid desde Diciembre 2017.</li>

                                            <li>Ejercicio de Medicina privada en Centro Médico Pinar, Hospital La Milagrosa y Hospital San José en Madrid y Ginemédica en Valladolid desde 2015 a la actualidad.</li>


                                        </ul>


                                        <br />MIEMBRO DE ASOCIACIONES CIENTÍFICAS Y PARTICIPACIONES EN PROGRAMA OFICIAL DE CONGRESOS:<br />


                                        <ul>


                                            <li>Miembro de la Asociación Española de Urología.</li>

                                            <li>Associate member de EULIS (sección de litiasis de la Asociación Europea de Urología)</li>

                                            <li>Ponente en las 1ª jornadas nefrourológicas castellano y leonesas en Valladolid, Junio 2017.</li>

                                            <li>Ponente en el 2º Curso Internacional de Endourología en Valladolid, Diciembre 2017.</li>

                                            <li>Ponente en la reunión nacional del grupo español de Litiasis, laparoscopia, endourología y robótica en Córdoba, enero 2018.</li>
                                            <li>Ponente en I workshop en ureteroscopia de EULIS en Madrid, mayo 2018.</li>
                                            <li>Ponente en la XI reunión de actualización en urología en noviembre 2018.</li>
                                            <li>Ponente en el 8º Congreso anual de la International Alliance of Urolithiasis en Guangzhou, China, Septiembre 2019.</li>
                                            <li>Cirugía en directo: Resección vesical en bloque con Hybridknife en las jornadas gallegas Urología, Monforte de Lemos, octubre de 2019. </li>

                                            <li>Ponente en la XII reunión de actualización en urología en noviembre 2019</li>
                                            <li>Ponente en la reunión nacional del grupo español de Litiasis, laparoscopia, endourología y robótica en Barcelona enero 2020.</li>
                                            <li>Ponente en el ciclo de sesiones formativas de la Sociedad Panameña de Urología, Julio 2020.</li>
                                            <li>Ponente en Urowebinar de EULIS: medical management of urolitiasis, octubre 2020.</li>
                                            <li>Ponente en la reunión nacional del grupo español de Litiasis, laparoscopia, endourología y robótica virtual enero 2021.</li>
                                            <li>Ponente del taller “litiasis” del Congreso Urovi en Madrid, Marzo 2021.</li>



                                        </ul>


                                        <br />PUBLICACIONES:<br />


                                        <ul>

                                            <li>Más de 100 trabajos presentados en congresos nacionales e internacionales.</li>
                                            <li>Más de 10 artículos y capítulos de libros en el ámbito de la urología.</li>


                                        </ul>



                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Rafael-Antonio-Medina.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Rafael Antonio Medina López<br />
                                            Hospital Universitario Virgen del Rocío, Sevilla<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>
                                            <li>Doctor en Medicina y Cirugía por la Universidad de Sevilla</li>
                                            <li>Jefe del Servicio de Urología del H.U. Virgen del Rocío.</li>
                                            <li>Director de la Unidad de Gestión Clínica de Urología y Nefrología del HUVR</li>
                                            <li>Coordinador del grupo de investigación adscrito al Instituto de Investigación Biomédica de Sevilla (IBiS) “Cirugía Avanzada en Urología. Terapias Urológicas”. </li>
                                            <li>Profesor Asociado del Departamento de Cirugía (Área de Conocimiento: Urología) de la Universidad de Sevilla. </li>
                                            <li>Entre sus líneas de investigación se encuentran el estudio de tratamientos para el cáncer de próstata de bajo y alto riesgo, estudio genómico para pronóstico en cáncer de próstata, cirugía robótica y estudio del uso de nuevas tecnologías en el trasplante renal.</li>
                                            <li>Coordinador de la Unidad “CSUR 60.03 Tumores Renales con Afectación Vascular” del HUVR.</li>
                                            <li>Coordinador del Programa de Cirugía Robótica del HUVR. </li>
                                            <li>Revisor Externo Agencia Evaluación Tecnología Sanitaria de Andalucía de la Consejería de Salud. </li>
                                            <li>Miembro del Panel de Expertos para Segunda Opinión Médica en Urología del Sistema Sanitario Público de Andalucía. </li>
                                            <li>Miembro de los grupos de elaboración: “Proceso Asistencial Integrado Tumor Vesical” y “Proceso Asistencial Integrado Hiperplasia Benigna de Próstata-Cáncer de Próstata” y del Grupo de Análisis “Uso Racional del Medicamento en el Proceso Asistencia Hiperplasia Benigna de Próstata- Cáncer de Próstata” de la Consejería de Salud de Andalucía.</li>

                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </div>





                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Juan-Moreno.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Juan Moreno Jiménez<br />
                                            Hospital Universitario de Jaén.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>

                                            <li>LICENCIATURA EN MEDICINA Y CIRUGÍA . Universidad de Granada. 1982</li>
                                            <li>DOCTOR EN MEDICINA Y CIRUGÍA . “Reactantes de fase aguda en el Rechazo Renal Crónico”. Apto Cum laudem . 1997</li>
                                            <li>Becario Hospital Princesa de España. Jaén .Servicio Medicina Interna 1984-1989</li>
                                            <li>Médico Residente de Urología. Hospital Virgen de las Nieves. Granada. 1990-1994.</li>
                                            <li>Facultativo Especialista Área Urología. Complejo Hospitalario Jaén. 1995-2009.</li>
                                            <li>Director Unidad de Gestión Clínica Urología . Complejo Hospitalario Jaén desde año 2010</li>
                                            <li>Jefe de Servicio de Urología. Complejo Hospitalario Jaén desde año 2013.</li>


                                        </ul>


                                        <br />FORMACIÓN<br />


                                        <ul>


                                            <li>Mas de 55 creditos en formación en diversos cursos de la especialidad, especialmente orientados a la Urooncología y el desarrollo de la cirugía laparoscópica. </li>

                                        </ul>


                                        <br />COMUNICACIONES<br />


                                        <ul>



                                            <li>Mas de 50 comunicaciones a Congresos Regionales, Nacionales e Internacionales.</li>


                                        </ul>


                                        <br />PUBLICACIONES<br />


                                        <ul>




                                            <li>Mas de 30 publicaciones en revistas, y capítulos de libros. </li>



                                        </ul>


                                        <br />OTROS<br />


                                        <ul>


                                            <li>Participacion en Consenso de Expertos sobre patología prostática.</li>
                                            <li>Responsable de la adaptación e implantación del Proceso de Hiperplasia Benigna de Próstata-Cáncer de Próstata de la Consejería de Salud de la Junta de Andalucía en el Área de Jaén. 2002-2005</li>
                                            <li>Coautor del Proceso de Urolitiasis de la Consejería de Salud de la Junta de Andalucía. 2012</li>
                                            <li>Acreditado  con el nivel de experto por la ACSA ( Agencia de Calidad Sanitaria de Andalucia)</li>
                                            <li>Nivel excelente  en  carrera profesional.</li>
                                            <li>Docente de IAVANTE (Consejeria de Salud de la Junta de Andalucia) en Formación Laparoscopica.</li>
                                            <li>Colaborador  en el desarrollo del manual de Competencias profesionales del Urologo . (ACSA) ( Agencia de Calidad Sanitaria de Andalucia)</li>
                                            <li>Evaluador de Profesionales de la ACSA (Agencia de Calidad Sanitaria de Andalucia)</li>
                                            <li>Presidente  Asociación Andaluza de Urología  </li>


                                        </ul>



                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Carlos-Muller.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Carlos Müller-Arteaga.<br />
                                            Hospital Álvaro Cunqueiro, Vigo. <br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <br />FORMACIÓN:<br />

                                        <br />Especialista en Urología

                                        <ul>
                                            <li>Hospital Clínico Universitario de Valladolid.</li>
                                        </ul>


                                        <br />Doctorado en Cirugía

                                        <ul>
                                            <li>Universidad de Salamanca. Calificación Sobresaliente CumLaude.</li>
                                            <li>Premio “Ponce de León” de la Asociación española de Urología a la mejor tesis doctoral en Urología funcional 2017.</li>

                                        </ul>



                                        <br />Master en Urología Funcional, femenina y urodinámica

                                        <ul>
                                            <li>Universidad de Salamanca. AEU. </li>

                                        </ul>



                                        <br />Fellow European Board of Urology (FEBU).

                                        <ul>
                                            <li>Union Européenne des Médecins Spécialistes U.E.M.S.</li>

                                        </ul>



                                        <br />Acreditación en Urodinámica

                                        <ul>
                                            <li>International Continence Society (ICS). Asociación Continentia</li>

                                        </ul>



                                        <br />ACTIVIDAD LABORAL

                                        <ul>
                                            <li>Unidad de Urología funcional, reconstructiva y Urodinámica. Complejo Hospitalario Universitario de Ourense hasta 2020.</li>

                                            <li>Urología funcional. Hospital Povisa hasta 2013.</li>


                                        </ul>



                                        <br />Miembro de Sociedades científicas

                                        <ul>
                                            <li>European Association of Urology (EAU)</li>
                                            <li>Asociación española de Urología (AEU)</li>
                                            <li>Sociedad Iberoamericana de Neurourologia y Uroginecologia (SINUG) (Miembro de la Junta directiva desde 2017)</li>
                                            <li>International Continence Society (ICS)</li>
                                            <li>Sociedad gallega de Urología.</li>



                                        </ul>


                                        Participación como Investigador y co-investigador en diversos protocolos clínicos. Múltiple participación activa en congresos nacionales e internacionales y publicaciones en revistas nacionales e internacionales.




                                    </div>

                                </div>

                            </div>

                        </div>




                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Alfredo-Rodriguez-Antolin.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Alfredo Rodríguez Antolín<br />
                                            Hospital 12 de Octubre, Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>



                                            <li>Licenciado en Medicina y Cirugía en 1985 por la Universidad de Valladolid</li>
                                            <li>Doctorado en Urología por la Universidad Complutense de Madrid en 2000 (Cum Laude)</li>
                                            <li>Residencia en Urología en el Hospital 12 de Octubre de Madrid</li>
                                            <li>EBU 1992</li>
                                            <li>Jefe de Servicio de Urología Hospital 12 de Octubre de Madrid (enero 2019)</li>
                                            <li>Responsable de la Unidad de Patología Próstática del H.U. 12 de Octubre desde 1993</li>
                                            <li>Autor o coautor de 95 publicaciones indexadas fundamentalmente en cáncer de próstata y HBP</li>
                                            <li>Investigador Principal en 23 estudios clínicos controlados fase II, III y IV fundamentalmente en cáncer de próstata avanzado y resistente a castración</li>
                                            <li>Profesor Asociado de Urología de la Universidad Complutense de Madrid desde 2003 hasta el momento actual</li>
                                            <li>Tesorero de la Asociación Española de Urología desde 2009-2013</li>
                                            <li>Vicepresidente de la Asociación Española de Urología 2013-2017</li>



                                        </ul>





                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Antonio-Rosales-Bordes.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Antonio Rosales Bordes<br />
                                            Fundacio Puigvert, Barcelona. <br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>


                                            <li>Centro de trabajo: Fundació Puigvert - Barcelona</li>
                                            <li>Jefe clínico del Servicio de Urología</li>
                                            <li>Miembro de la Unidad de Urología Oncológica del Servicio de Urología</li>
                                            <li>Cirujano-profesor en técnica quirúrgica laparoscópica</li>

                                            <li>4.6.84 - Especialista en Urología post-residencia en el Hospital del Mar de Barcelona</li>

                                            <li>1984-1985 - Becario del Departamento de Ciencias Morfológicas de la Universidad Autónoma de Barcelona.</li>

                                            <li>24.2.92 - Doctorado en Medicina y cirugía por la Universidad Autónoma de Barcelona.</li>

                                            <li>1993 - Premio J.M. Gil-Vernet de la Real Academia de Medicina por su tesis doctoral Desarrollo de la uretra anterior y del tejido eréctil del pene.</li>

                                            <li>Autor y coautor de un número superior a 50 artículos publicados en revistas y varios libros de la especialidad.</li>

                                            <li>Participante en varios proyectos de investigación llevados a cabo en la Fundació Puigvert</li>

                                            <li>Capacitación en Cirugía Robótica Da Vinci.</li>

                                            <li>Ganador de 10 premios nacionales de la especialidad en el área de la laparoscopia.</li>

                                            <li>Conferencias nacionales e internacionales impartidas con profesor invitado en número superior a 200.</li>

                                            <li>Vídeos científicos como autor y coautor en número superior a 100.</li>

                                            <li>Viajes científicos para aprendizaje de técnicas quirúrgicas.</li>

                                            <li>Miembro de la Asociación Española de Urología y de la European Association of
                                                Urology</li>

                                            <li>Miembro de la Reial Acadèmia de Medicina i Cirugia de Catalunya.</li>

                                            <li>Coordinador del grupo de Endoscopia, laparoscópia y robótica de la AEU 2016-2020</li>



                                        </ul>



                                    </div>

                                </div>

                            </div>

                        </div>



                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Alvaro-Serrano.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Álvaro Serrano Pascual<br />
                                            Hospital Clínico San Carlos, Madrid.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <br />Formación Académica:<br />

                                        <ul>

                                            <li>Licenciado en Medicina y Cirugía en 1983. Facultad Medicina. Universidad de Extremadura.</li>
                                            <li>MIR en Urología en Hospital Ramón y Cajal (Madrid) de 1988-1992.</li>
                                            <li>Doctor en Medicina en 1994. Facultad Medicina. Universidad Autónoma. Madrid.</li>


                                        </ul>


                                        <br />Situación Profesional:<br />

                                        <ul>


                                            <li>Miembro del Servicio de Urología del Hospital Clínico San Carlos. Madrid.</li>
                                            <li>Urología. Complejo Hospitalario Ruber Juan Bravo. Madrid.</li>
                                            <li>Director del Programa de Urología del Centro de Cirugía de Mínima Invasión Jesús Usón. Cáceres.</li>
                                            <li>Coordinador Nacional de Endourología, Laparoscopia y Robótica Urológica desde Junio de 2008 a Junio de 2012.</li>
                                            <li>Director de la Oficina de Formación Continuada de la AEU desde Octubre de 2013 a Enero de 2019.</li>
                                            <li>Vocal de Actas y miembro de la Junta Permanente de la AEU desde Junio de 2018.</li>
                                            <li>Coordinador, Responsable de asignatura de Patología Médica y Profesor del Máster Oficial en Cirugía de Mínima Invasión Urológica Avanzada  en sus 5 Ediciones: 2020-2021, 2019-2020, 2018-2019, 2017-2018 y 2016-2017.</li>
                                            <li>Editor de la versión Española de la Revista Journal of Endourología durante los años 2012 Y 2013.</li>
                                            <li>Miembro del Comité Editorial de la Revista Archivos Españoles de Urología. </li>
                                            <li>Revisor de las siguientes revistas: Archivos Españoles de Urología, Actas Urológicas Españolas, Urología Internationalis, International Journal of Medical Robotics and Computer  Assisted Surgery, International Journal of Urology, Journal of Endourology y Scandinavian Journal of Urology.</li>



                                        </ul>



                                        <br />Actividad Profesional: Publicaciones y Presentaciones<br />

                                        <ul>



                                            <li>Libros completos: 6</li>
                                            <li>Autor de capítulos de libros: 67</li>
                                            <li>Artículos publicados en revistas Nacionales e Internacionales: 122</li>
                                            <li>Presentaciones científicas en Congresos Internacionales: 45</li>
                                            <li>Presentaciones científicas en Congreso Nacionales: 401</li>
                                            <li>Organizador de Congresos-Reuniones y Director-Profesor de Cursos de Formación: Más de 100</li>




                                        </ul>





                                    </div>

                                </div>

                            </div>

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Rocio-Velasco-Guzman-Lazaro.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dra. Rocío Velasco Guzmán de Lázaro<br />
                                            Hospital Universitario La Paz, Madrid. <br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>



                                            <li>Licenciada en Medicina y Cirugía por la Universidad Complutense de Madrid </li>

                                            <li>Residencia en Geriatría en el Hospital Universitario de Getafe (Madrid)</li>

                                            <li>Especialista del Servicio de Geriatría del Hospital Universitario La Paz  desde Abril 2013. (OPE 2009)</li>

                                            <li>Master en Administración y Dirección de Servicios Sanitarios (MADS) Instituto de Formación Continuada  (IDEC). Universidad Pompeu y Fabra</li>

                                            <li>Profesor asociado Universidad Europea de Madrid. Gestión Sanitaria y Salud Pública, 3º curso Grado de Medicina 2013 </li>

                                            <li>Colaborador clínico docente de la Universidad Autónoma de Madrid </li>

                                            <li>Investigador/Colaborador en el estudio multicéntrico: Registro Nacional de Fracturas de Cadera durante los años 2016-2017 </li>

                                            <li>Subinvestigador en estudios clínicos controlados fase II, y III fundamental en Enfermedad de Alzheimer </li>

                                            <li>Autor y coautor de publicaciones indexadas fundamentalmente en Paciente anciano con fractura de cadera </li>

                                            <li>Premio a la mejor sesión clínica Hospital La Paz 2017: “ Envejecimiento y fragilidad” </li>

                                            <li>Medalla de Bronce al Servicio de Ortogeriatría de La Paz. FSL. Fundación Internacional de Osteoporosis “Capture the Fracture” </li>

                                            <li>Miembro de la Junta Directiva de la Asociación Española de Medicina Geriátrica</li>



                                        </ul>



                                    </div>

                                </div>

                            </div>

                        </div>



                        <div className="speakers-row">

                            <div className="speaker-entry">

                                <div className="speaker-container">

                                    <div className="speaker-photo-name">
                                        <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/Antoni-Vilaseca.jpg" />
                                    </div>
                                    <div className="speaker-cv">
                                        <span className="speaker-name">
                                            Dr. Antoni Vilaseca Cabo<br />
                                            Hospital Clinic, Barcelona.<br /><br />
                                        </span>

                                        <strong>Curriculum Vitae</strong><br /><br />

                                        <ul>
                                            <li>Licenciado en Medicina por la Universidad de Barcelona (2009). Máster en Técnicas Quirúrgicas Endoscópicas por la Universidad Autónoma de Barcelona (2011).</li>
                                            <li>Especialista vía MIR en Urología por el Hospital Clínic de Barcelona (2014).</li>
                                            <li>Fellowship en Urología Oncológica en el Memorial Sloan Kettering Cancer Center de Nueva York (2015-2016).</li>
                                            <li>Doctor internacional en Medicina e Investigación Traslacional por la Universidad de Barcelona (2017).</li>
                                            <li>Adjunto del Servicio de Urología y miembro de la Unidad de Urología Oncológica del Hospital Clínic desde 2016.</li>
                                            <li>Profesor asociado al Máster de Competencias Médicas Avanzadas (Urología Oncológica) de la Universidad de Barcelona desde 2017.</li>
                                            <li>Autor de varios artículos sobre Urología Oncológica publicados en revistas nacionales e internacionales.</li>
                                            <li>Investigador en más de 15 ensayos clínicos de Uro-Oncología.</li>
                                        </ul>




                                    </div>

                                </div>

                            </div>



                        </div>



                    </div>

                    : this.context.event_settings.event_id == "387" ?

                        <div className="programme-speakers-content-container">

                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/GEITDAH%20enero%202021/Prof.%20Dr.%20Francisco%20Montan%CC%83e%CC%81s.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Prof. Dr. Francisco Montañés<br />
                                                Hospital Universitario Fundación Alcorcón. Madrid<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Reponsable de Psiquiatría del Centro de Salud Mental y Hospital Universitario de Alcorcón</li>
                                                <li>Profesor de Psiquiatría de la Universidad Rey Juan Carlos</li>
                                                <li>Coordinador del Programa de TDAH</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/Dr.%20Xavier%20Gastaminza.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dr. Xavier Gastaminza,<br />
                                                Hospital Universitario Vall d’Hebron, UAB. Barcelona.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />

                                            Licenciado en Medicina y Cirugía por la Universidad Autónoma de Barcelona (UAB), Especialista en Psiquiatría. Completa su formación de postgrado con la realización de:

                                            <br /><br />

                                            <ul>
                                                <li>Máster en Psiquiatría y Psicología Social por la Universidad de Barcelona.</li>
                                                <li>Diplomatura en Psiquiatría infanto-juvenil (UAB)</li>
                                                <li>Máster en Psiquiatría de la infancia y de la adolescencia (UAB).</li>
                                                <li>Ha sido Presidente de la Sociedad Catalana de Psiquiatría Infantil, Secretario de la Asociación Española de Psiquiatría infanto-juvenil y secretario de la Sección de Psiquiatría Infantil de la Asociación Española de Pediatría. También es miembro de la American Academy of Child and Adolescent (AACAP).</li>
                                                <li>Ha sido ponente en numerosos congresos nacionales e internacionales.</li>
                                                <li>Ha publicado alrededor de 100 artículos en revistas de la especialidad. Ha sido autor, haciendo capítulos, en unos 50 libros.</li>
                                                <li>Siendo coautor de dos.</li>
                                                <li>Ha sido co-director de la edición española de la Journal of American Academy of Child & Adolescent Psychiatry.</li>
                                                <li>Actualmente es facultativo especialista en el Hospital Universitari Vall d´Hebrón, en la Unidad de Paidopsiquiatría, siendo desde 2011 Profesor asociado clínico de Psiquiatría del Dpto. de Medicina Legal i Psiquiatría (Facultad de Medicina) de la UAB.</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>

                            </div>




                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Captura%20de%20pantalla%202021-01-20%20a%20las%2014.00.17.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dr. Manuel Ajoy<br />
                                                Hospital General Fuerteventura. Fuerteventura<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Doctor en Medicina Universidad Católica Santiago de Guayaquil-Ecuador.</li>
                                                <li>Especialidad de Psiquiatría en el Hospital General Gregorio Marañon de Madrid.</li>
                                                <li>Médico Psiquiatra de la Unidad de Salud Mental del Niño y Adolescente de Fuerteventura. Servicio Canario de Salud.</li>
                                                <li>Jefe del Servicio de Psiquiatría Hospital General Virgen de la Peña - Fuerteventura.</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>



                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Dra.%20Mari%CC%81a%20Dolores%20Mojarro.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Prof. Dra. María Dolores Mojarro<br />
                                                Universidad de Sevilla. Sevilla.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />

                                            <strong>FORMACIÓN ACADÉMICA</strong><br /><br />

                                            <ul>
                                                <li>Licenciatura Medicina y Cirugía (20/sept/1984)</li>
                                                <li>Centro de expedición Universidad de Sevilla Doctorado en Medicina (10/9/1990)</li>
                                            </ul>

                                            <br />

                                            <strong>DATOS PROFESIONALES</strong><br /><br />

                                            <ul>
                                                <li>Situación profesional actual: Prof..titular de Universidad. Fecha de inicio: 1989 Organismo Universidad de Sevilla, Departamento de Psiquiatría</li>

                                            </ul>

                                            <br />

                                            <strong>LÍNEAS DE INVESTIGACIÓN O DESARROLLO EN QUE HA TRABAJADO</strong><br /><br />

                                            <ul>
                                                <li>"Epidemiología de los trastornos atencionales y de conducta en una población escolar sevillana". Centro: facultad de Medicina Sevilla. Fecha 2000/2001</li>
                                                <li>"Estudio de la prevalencia de los trastornos mentales infantiles en la comunidad autónoma Andaluza". Centro: facultad de Medicina Sevilla. Fecha 2000</li>
                                                <li>Líneas. Grupo Andaluz de investigación en psiquiatría Infanto-juvenil (GAIPIJ) Centro: Universidad de Sevilla. Fecha actual.</li>
                                                <li>Asesoramiento técnico y asesoramiento de investigación e informes técnicos en psicopatología infantil. Centro: Universidad Sevilla/Fundación gota de leche.</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>


                            </div>






                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Dra.%20Maite%20Ferri%CC%81n.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dra. Maite Ferrín.<br />
                                                NHS Haringey CAMHS, London. ReCognition Health, University of Southampton, UK.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Graduada en Medicina y Cirugía por la Universidad de Navarra en 2000, y especialista en Psiquiatría vía MIR en 2004. Realizó el Máster de Psiquiatría del Niño y Adolescente en el Instituto de Psiquiatría del Maudsley de Londres en 2005, y finalizó su tesis doctoral europea en Neurociencias en 2010 por la universidad de Granada. A nivel académico su trabajo se ha centrado en trastornos de neurodesarrollo, y en particular en TDAH. Ha publicado más de 50 artículos y capítulos en revistas de impacto internacional.</li>
                                                <li>Miembro del grupo de Guías Europeas de TDAH (EAGG European ADHD Guidelines Group) y del Grupo Eunethydis, con ellos ha publicado varios metaanálisis y consensos terapéuticos en tratamiento de TDAH. Es también miembro del comité organizador del programa Continuum, un programa europeo de educación de profesionales en TDAH.</li>
                                                <li>En la actualidad trabaja como psiquiatra infantil en el NHS en Haringey, Londres, donde es la responsable de la unidad de TDAH, y en Re:Cognition Health en Londres, dónde además coordina diferentes estudios randomizados en nuevos tratamientos de autismo. Es profesora asociada de la Universidad de Southampton y vicepresidenta de la Asociación Internacional de Psiquiatría Infantojuvenil (IACAPAP).</li>

                                            </ul>


                                        </div>

                                    </div>

                                </div>



                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Dra.%20Ana%20Gago.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dra. Ana Gago<br />
                                                Hospital Universitario Pontevedra - USM Vilagarcía de Arousa, Pontevedra.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Médico especialista en psiquiatría desde el año 2000, residencia en el Hospital Clínico de Santiago de Compostela; durante la residencia 6 meses de rotación en el Hospital Bellevue de la New York University en las Unidades de Hospitalización Psiquiatría Infantil. Doctora en Medicina desde el año 2003</li>
                                                <li>Desde el año 2000 al 2006 trabajo en el Hospital Sant Joan de Déu de Barcelona como psiquiatra infantil en hospitalización primero y después como coordinadora del Hospital de Día de Adolescentes de Vilanova i la Geltrú perteneciente también al Hospital Sant Joan de Déu</li>
                                                <li>En los años 2006 y 2007 Coordinación del Centro de Salud Mental Infantil y Juvenil de Sant Martí Nord (perteneciente al Hospital del Mar de Barcelona).</li>
                                                <li>Año 2007-2009 psiquiatra Unidad Infanto-Juvenil del Hospital Clínico de Santiago.</li>
                                                <li>En el 2011 obtención plaza en el Servicio Galego de Saúde (Sanidad Pública: SERGAS)</li>
                                                <li>Actualmente: Desde junio del 2018 adjunto de psiquiatría en la Unidad de Salud Mental de Vilagarcía de Arousa (Pontevedra), perteneciente al Departamento de Psiquiatría del Complejo Hospitalario de Pontevedra (SERGAS)</li>
                                            </ul>

                                            <br />

                                            <strong>INVESTIGACIÓN Y DOCENCIA:</strong><br /><br />

                                            <ul>
                                                <li>Doctora en Medicina desde el 2003</li>
                                                <li>Supervisora de casos en la Fundación Orienta-Barcelona (2002-2006)</li>
                                                <li>Desde el 2014 integrante del Grupo de Trabajo sobre Psiquiatría de la Sociedad Gallega de Pediatría. En el año 2015 Primer Premio GEITDAH e integrante posteriormente del grupo.</li>
                                                <li>Publicación en Schizophrenia Research (año 2016) sobre la Estimulación Transcraneal Magnética en colaboración con la Universidad de A Coruña</li>
                                            </ul>

                                        </div>

                                    </div>

                                </div>


                            </div>




                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Dr.%20O%CC%81scar%20Herreros.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dr. Óscar Herreros<br />
                                                Hospital Universitarios Virgen de las Nieves. Granada.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Médico especialista en Psiquiatría Infanto Juvenil en el Hospital Universitario de Canarias. Participa en hasta 19 cursos y seminarios de la especialidad así como docente en sesiones clínicas. En los últimos 3 años ha participado en un total de 17 libros y capítulos de libros con su actividad investigadora. Así como en 9 artículos científicos, y 14 pósteres y comunicaciones a congresos nacionales e internacionales.</li>
                                                <li>Representante de la Organización Médica Colegial Española en la Sección de Especialistas en Psiquiatría y Psicoterapia del Niño y del Adolescente de la Unión Europea de Médicos Especialistas (UEMS), desde el año 2011 hasta la actualidad. Desde el año 2011 hasta la actualidad es miembro de la European Society for Child and Adolescent Psychiatry.</li>
                                                <li>Recientemente ha participado en el Grupo de Trabajo “Optimización en el abordaje del Trastorno por Déficit de Atención / Hiperactividad” dentro de la Estrategia en Salud Mental del Sistema Nacional de Salud del Ministerio de Sanidad, Servicios Sociales e Igualdad.</li>

                                            </ul>


                                        </div>

                                    </div>

                                </div>



                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/Martinez-Luna.jpg" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dra. Nieves Martínez Luna<br />
                                                Centre de Salut Mental d´Adults de Sarrià-Sant Gervasi. Barcelona.<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />

                                            <p>Formación:</p>

                                            <ul>
                                                <li>Doctorado en Psiquiatría: “Comorbilidad Psiquiátrica y Evolución de Pacientes con TUS y TDAH”. UAB, 11/ 2019.</li>
                                                <li>Máster en Psiquiatría Infanto-Juvenil por la UAB.  Barcelona 2007-2009.</li>
                                                <li>Especialista en Psiquiatría (Residencia). Universidad de San Carlos de Guatemala y el IGSS.  2003-2006.  Guatemala, C.A.</li>
                                                <li>Licenciatura como Médico y Cirujano, Universidad de San Carlos de Guatemala 1996- 2002.  Guatemala, C.A. </li>

                                            </ul>

                                            <br />

                                            <p>Experiencia Laboral:</p>

                                            <ul>
                                                <li>Psiquiatra Adjunto del CSMA-CSMIJ en Lescorts Centre de Higiene Mental (actual)</li>
                                                <li>Psiquiatra adjunto del CSMA Santa Coloma de Gramenet. Instituto de Neuropsiquiatría y Adicciones, Parc de Salut Mar (2020/2021).</li>
                                                <li>Psiquiatra adjunto del CAS Vall d’ Hebron, Coordinadora de los grupos de atención de CANNABIS y REDAN, diciembre de. ICS, Hospital Universitario VALL D'HEBRON. 08/2009-8/2020</li>
                                                <li>Psiquiatra adjunto, Fundación Instituto de Recerca Vall d’ Hebrón.  12/2008 - 8/2009.</li>

                                            </ul>

                                            <br />

                                            <p>Experiencia Investigación y Docencia:</p>

                                            <ul>
                                                <li>Investigadora colaboradora de Proyectos VHIR.  Coautor en capítulos de libro, artículos científicos  y revisiones (Psiquiatría y Adicciones).</li>
                                                <li>Asociado clínico del Departamento de Psiquiatría y Medicina Legal de la UAB del año 2010 al 2021.</li>
                                                <li>Docente colaborador del Máster de Adicciones del Espacio Europeo de la UAB, Y docente en cursos de adicciones en las temáticas de Cannabis y Reducción de Daños.  Ponente tanto en congresos como jornadas, en su mayoría del ámbito de adicciones.</li>

                                            </ul>


                                        </div>

                                    </div>

                                </div>


                            </div>





                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/Dr._Juan_Ortiz..png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dr. Juan J. Ortiz<br />
                                                Hospital Universitario Sant Joan de Déu. Esplugues de Llobregat. Barcelona<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>
                                                <li>Especialista Senior de la Unidad Especializada en TDAH y en la Unidad de Hospitalización del Servicio de Psiquiatría y Psicología en el Hospital Sant Joan de Déu, Esplugues de Llobregat, Barcelona.</li>
                                                <li>Licenciatura en Medicina. Universidad Industrial de Santander en Bucaramanga, Colombia</li>
                                                <li>Especialidad en Psiquiatría en la Universidad Nacional de Colombia en Bogotá, Colombia</li>
                                                <li>Máster en Psiquiatría de Niños y Adolescentes, Universidad Autónoma de Barcelona</li>
                                                <li>Suficiencia Investigadora del Programa de Doctorado en Psicopatología de los Niños, Adolescentes y Adultos de la Universidad Autónoma de Barcelona</li>
                                                <li>Asociación Española de Psiquiatría del Niño y Adolescente</li>
                                                <li>Grupo de Especial Interés en TDAH (GEITDAH)</li>
                                                <li>Presentación de la Guía de Práctica Clínica para el TDAH en niños y adolescentes. Ministerio de Sanidad y Política social de España. Barcelona Julio de 2010</li>
                                                <li>Guía de Práctica Clínica para el TDAH en niños y adolescentes. Ministerio de Sanidad y Política social de España como parte del Plan de Calidad del Sistema Nacional de Salud</li>
                                                <li>Validación de la escala ADHD Rating Scale – IV versiones padres y profesores para población catalana</li>
                                                <li>Consenso del GEITDAH sobre diagnóstico y tratamiento del Trastorno por Déficit de Atención / Hiperactividad</li>
                                            </ul>


                                        </div>

                                    </div>

                                </div>



                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://emma.events/static/upload/ow122/events/ev387/Site/files/SoniaPerez.png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dra. Sonia Pérez<br />
                                                Hospital Universitario Fundación Alcorcón. Madrid<br /><br />
                                            </span>

                                            <ul>

                                                <li>Hospital Universitario Fundación Alcorcón.</li>
                                                <li>Profesora del Máster de Neurociencia cognitiva y Neuropsicología URJC.</li>
                                                <li>Autora de varias publicaciones en revistas científicas y del libro "Ayuda a tu hijo a adaptarse al mundo. Manual para padres de niños con TDA/H."</li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>


                            </div>




                            <div className="speakers-row">

                                <div className="speaker-entry">

                                    <div className="speaker-container">

                                        <div className="speaker-photo-name">
                                            <img src="https://eventos.rovi.es/hubfs/imagenes/2021/Medikinet/GEITDAH%202021/GEITDAH%20enero%202021/Dra.%20Bele%CC%81n%20Rubio..png" />
                                        </div>
                                        <div className="speaker-cv">
                                            <span className="speaker-name">
                                                Dra. Belén Rubio<br />
                                                Médico psiquiatra<br /><br />
                                            </span>

                                            <strong>Curriculum Vitae</strong><br /><br />
                                            <ul>

                                                <li>1993-1999: Licenciada en Medicina y Cirugía. Facultad de Medicina de la Universidad de Valencia.</li>
                                                <li>Tesis Doctoral en el Programa de Ciencias Biomédicas: “Evaluación de la eficacia neuropsicológica de la atomoxetina y el metilfenidato en el TDAH”.</li>
                                                <li>Médico Adjunto de Psiquiatría. Hospital Universitario de Canarias, Unidad de Psiquiatría Infanto-Juvenil, Interconsulta Psiquiatría Infantil y Hospitalización (Abril del 2006- hasta la actualidad).</li>
                                                <li>Colaborador Docente del Departamento de Psiquiatría de la Facultad de Medicina de la Universidad de La Laguna (ULL) (2006-actualidad).</li>

                                            </ul>


                                        </div>

                                    </div>

                                </div>




                            </div>

                        </div>


                        : this.context.event_settings.event_id == "4" ?

                            <div className="programme-speakers-content-container UROVI2021">

                                <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">



                                    <tr className="title-tr-tbl-programme-urovi2021">
                                        <td>COORDINADORES:</td>
                                    </tr>

                                    <tr>
                                        <td className="encuentrosexperto2021">
                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Pere-Domenech-Santasusana.jpg" /><br />
                                            <strong>Dr. Pere Doménech Santasusana</strong><br />Servicio de Hematología y Hemoterapia. Hospital Universitario de Bellvitge, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Pere-Domenech-Santasusana')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Ramon-Lecumberri-Villamediana.jpg" /><br />
                                            <strong>Dr. Ramón Lecumberri Villamediana</strong><br />Servicio de Hematología. Clínica Universidad de Navarra, Pamplona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Ramon-Lecumberri-Villamediana')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                        </td>


                                    </tr>


                                    <tr className="title-tr-tbl-programme-urovi2021">
                                        <td>PONENTES:</td>
                                    </tr>


                                    <tr>
                                        <td className="encuentrosexperto2021">
                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Jose-Luis-Ferreiro-Gutierrez.jpg" /><br />
                                            <strong>Dr. José Luis Ferreiro Gutiérrez</strong><br />Servicio de Cardiología. Hospital Universitario de Bellvitge, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Jose-Luis-Ferreiro-Gutierrez')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Pedro-Ruiz-Artacho.jpg" /><br />
                                            <strong>Dr. Pedro Ruiz Artacho</strong><br />Servicio de Medicina Interna. Clínica Universidad de Navarra, Madrid.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Pedro-Ruiz-Artacho')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Joan-Carles-Reverter-Calatayud.jpg" /><br />
                                            <strong>Dr. Joan Carles Reverter Calatayud</strong><br />Servicio de Hematología y Hemoterapia. Hospital Clínic, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Joan-Carles-Reverter-Calatayud')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Nuria-Ruiz-Gimenez-Arrieta.jpg" /><br />
                                            <strong>Dra. Nuria Ruiz-Giménez Arrieta</strong><br />Servicio de Medicina Interna. Hospital Universitario de la Princesa, Madrid.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Nuria-Ruiz-Gimenez-Arrieta')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Carmen-Fernandez-Capitan.jpg" /><br />
                                            <strong>Dra. Carmen Fernández Capitán</strong><br />Servicio de Medicina Interna. Hospital Universitario La Paz, Madrid.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Carmen-Fernandez-Capitan')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Joan-Carles-Souto-Andres.jpg" /><br />
                                            <strong>Dr. Joan Carles Souto Andrés</strong><br />Servicio de Hematología. Hospital de la Santa Creu i Sant Pau, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Joan-Carles-Souto-Andres')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Enrique-Gallardo-Diaz.jpg?ver=2" /><br />
                                            <strong>Dr. Enrique Gallardo Díaz</strong><br />Servicio de Oncología. Hospital de Sabadell, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Enrique-Gallardo-Diaz')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />
                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Elena-M-Pina-Pascual.jpg" /><br />
                                            <strong>Dra. Elena Mª Pina Pascual</strong><br />Servicio de Hematología y Hemoterapia. Hospital Universitario de Bellvitge, Barcelona.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Elena-M-Pina-Pascual')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Luis-Jara-Palomares.jpg" /><br />
                                            <strong>Dr. Luis Jara Palomares</strong><br />Servicio de Neumología. Hospital Universitario Virgen del Rocío, Sevilla.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Luis-Jara-Palomares')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            <hr />

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Ignacio-Leal-Lorenzo.jpg" /><br />
                                            <strong>Dr. Ignacio Leal Lorenzo</strong><br />Servicio de Cirugía Vascular. Clínica Universidad de Navarra, Madrid.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Ignacio-Leal-Lorenzo')} className="tromboencuentros2021-cv-ponente">Más información</a>

                                            <hr />
                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Olga-Gavin-Sebastian.jpg" /><br />
                                            <strong>Dra. Olga Gavín Sebastián</strong><br />Servicio de Hematología. Hospital Clínico Universitario Lozano Blesa, Zaragoza.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Olga-Gavin-Sebastian')} className="tromboencuentros2021-cv-ponente">Más información</a>

                                            <hr />
                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Vanesa-Roldan-Schilling.jpg" /><br />
                                            <strong>Dra. Vanesa Roldán Schilling</strong><br />Servicio de Hematología y Hemoterapia. Hospital Morales Meseguer, Murcia.<br />
                                            <a onClick={() => this.onSpeakersCVOpenHandler('Vanesa-Roldan-Schilling')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                        </td>


                                    </tr>




                                </table>

                            </div>

                            : null}



            </React.Fragment>
        );
    }
}

export default SpeakersHtml;