import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../../context/auth-context'
import { withTranslation } from 'react-i18next';
import configData from '../../config.json';
import Webcam from "react-webcam";
import {Link, withRouter} from 'react-router-dom';


class HospitalityMeetPreview extends Component {

    state = {}

    static contextType = AuthContext;

    goBack = () => {
        this.props.history.goBack();
    }

    render() {


        const video = this.props.video
            ? <img onClick={this.props.onVideo} src="/img/video-icon-meet-preview.png"/>
            : <img onClick={this.props.onVideo} src="/img/video-icon-meet-preview-inactive.png" />

        const sound = this.props.sound
            ? <img onClick={this.props.onSound} src="/img/mic-icon-meet-preview.png"/>
            : <img onClick={this.props.onSound} src="/img/mic-icon-meet-preview-inactive.png" />

        const webcam = this.props.video
            ? <Webcam audio={this.props.sound} height={150} width={280}/>
            : null       

        return (

            <div className="hospitality-meet-preview-container">

                <div className="hospitality-schedule-details-title-container meet-preview">
                    <div className="hospitality-schedule-details-title-icon">
                        <img src="/img/hospitality-icon.png" />
                    </div>
                    <div className="hospitality-schedule-details-title-text">
                        Hospitality<br />Suite
                    </div>
                    <div className="hospitality-schedule-details-title"> Meet</div>
                </div>

                {/* <div className="hospitality-meet-preview-title">
                    <span className="meet-title">{this.props.meetingName}</span>
                </div> */}


                {this.props.state === undefined || this.props.state === "" || this.props.state ===  "enter"  ?

                <React.Fragment>
                    <div className="hospitality-meet-preview-image">
                        {webcam}
                        <div className="hospitality-meet-preview-name">{this.props.name} [{this.props.profile}]</div>
                    </div>
                    <div className="hospitality-meet-preview-settings">
                        <div className="hospitality-meet-preview-btn-mic">
                            {sound}
                        </div>
                        <div className="hospitality-meet-preview-btn-video">
                            {video}
                        </div>
                    </div>

                    <div className="hospitality-meet-preview-password-container">
                        {/* <label htmlFor="fname">Insert Password:</label><br />
                        <input type="password" id="password" name="password" /> */}
                    </div>


                    {this.props.meetingId !== undefined && this.props.meetingId !== "" ?
                    <div className="hospitality-meet-preview-action-btns-container">
                        <div className="hospitality-meet-preview-action-btn start" onClick={this.props.connectHandler}>
                            {this.props.profile === 'host' ? 'START' : 'JOIN'}
                        </div>
                        <Link to={this.props.exitLink}><div className="hospitality-meet-preview-action-btn abort">EXIT</div></Link>
                    </div>
                    
                    : <React.Fragment>
                        <div className="hospitality-meet-preview-title">Meeting ID not found</div>
                        <Link to="/congress/industria"><div className="hospitality-meet-preview-action-btn abort">BACK</div></Link>
                        </React.Fragment>
                    }
                
                </React.Fragment>
                
                : this.props.state === "waiting"
                    ? <div className="hospitality-meet-preview-action-btns-container">
                        <div className="hospitality-meet-preview-title">
                            <p>Waiting for the host to let you in...</p>
                            <div className="hospitality-meet-preview-action-btn start" onClick={this.props.cancelRequest}> CANCEL </div>                            
                            <Link to={this.props.exitLink}><div className="hospitality-meet-preview-action-btn abort">EXIT</div></Link>
                        </div>
                    </div>
                    :  null 
                
                }

            </div>
        );
    }
}

export default withTranslation()(withRouter(HospitalityMeetPreview));