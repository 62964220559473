import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';
import SpeakersCV from '../../../components/SpeakersCV/SpeakersCV';

class ProgrammeHtml extends Component {



    static contextType = AuthContext;

    state = {
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false,
        SpeakersCVPopUpOn: false,
        SpeakerName: ""
    }

    //componentDidMount() {}
    //componentDidUpdate() {}

    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    onSpeakersCVOpenHandler = (speakerlink) => {
        this.setState({ SpeakersCVPopUpOn: true, SpeakerName: speakerlink });
        //alert(speakerlink);
    }

    onSpeakersCVCloseHandler = () => {
        this.setState({ SpeakersCVPopUpOn: false, SpeakerName: "" });
    }



    render() {

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;
        const SpeakersCVPopUp = this.state.SpeakersCVPopUpOn ? <SpeakersCV speaker_cv={this.state.SpeakerName} event_id={this.context.event_settings.event_id} ononSpeakersCVClose={this.onSpeakersCVCloseHandler}></SpeakersCV> : null;


        if (this.context.event_settings.event_id == "2") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/urovi2021/lobby/urovi2021-lobby-bck2.jpg")';
        } else if (this.context.event_settings.event_id == "3") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/encuentrosexperto2021/lobby/encuentrosexperto2021-lobby.jpg")';
        } else if (this.context.event_settings.event_id == "4") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby.jpg")';
        } else if (this.context.event_settings.event_id == "5") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/viitdah2022/lobby/viitdah2022-backgound-06122021.jpg")';
        }

        return (
            <React.Fragment>

                {SpeakersCVPopUp}

                {CookiesPolicyPopUp}
                {LegalNoticePopUp}
                {TermsConditionsPopUp}
                {PrivacyPolicyPopUp}
                <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>


                {this.context.event_settings.event_id == "2" ?
                    <div className="programme-speakers-content-container UROVI2021">
                        <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                            <tr>
                                <td colSpan="3" className="day">24 MARZO 2021</td>
                            </tr>

                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">17:00 - 17:15 h.</td>
                                <td colSpan="2">BIENVENIDA</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2">
                                    Directores Científicos<br />
                                    Presidente de la AEU <br />
                                    y/o representante de ROVI
                                </td>

                            </tr>


                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">17:15 - 18:15 h.</td>
                                <td colSpan="2">Bloque 1 - HOT TOPIC</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td>Director Científico:</td>
                                <td>Dr. José Luis Álvarez-Ossorio</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td>Moderador:</td>
                                <td>Dr. Alfredo Rodríguez Antolín</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Papel actual del bloqueo hormonal en el cáncer de próstata por estadios</span></td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Antoni Vilaseca Cabo</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Cáncer renal metastásico</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Roberto Llarena Ibarguren</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Casos clínicos: Cáncer de próstata resistente a la castración y cáncer renal</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Juan Moreno Jiménez</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Debate y dudas de la audiencia</span></td>
                            </tr>



                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">18:15 -19:15 h.</td>
                                <td colSpan="2">Bloque 2 – UROLOGÍA FUNCIONAL</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Director Científico:</td>
                                <td>Dr. Víctor Carrero López</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Moderadora:</td>
                                <td>Dra. Carmen González Enguita</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Tratamiento de la Vejiga Hiperactiva idiopática tras fracaso del tratamiento farmacológico</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Carlos Müller-Arteaga</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Papel del Geriatra en un servicio quirúrgico: Desarrollo de procesos y protocolos consensuados</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dra. Rocío Velasco Guzmán de Lázaro</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Debate y dudas de la audiencia</span></td>
                            </tr>


                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">19:15 -19:30 h.</td>
                                <td colSpan="2">Conclusiones y cierre de la primera jornada</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2">Directores Científicos</td>

                            </tr>



                        </table>


                        <p>&nbsp;</p>


                        <table cellPadding="0" cellSpacing="0" className="tbl-programme">
                            <tr>
                                <td colSpan="3" className="day">25 MARZO 2021</td>
                            </tr>

                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">17:00 - 17:15 h.</td>
                                <td colSpan="2">Bienvenida segunda jornada</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2">Directores Científicos</td>

                            </tr>

                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">17:15 - 18:15 h.</td>
                                <td colSpan="2">Bloque 3 – CIRUGÍA</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td>Director Científico:</td>
                                <td>Dr. José Manuel Cózar Olmo</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td>Moderador:</td>
                                <td>Dr. Rafael Medina López</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Caso clínico de HBP</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Mario Álvarez Maestro</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Cirugía Robótica en el tratamiento de patologías urológicas funcionales</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Roberto Ballestero Diego</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Manejo de masas renales pequeñas</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponente:</td>
                                <td>Dr. Antonio Rosales Bordes</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Debate y dudas de la audiencia</span></td>
                            </tr>


                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">18:15 - 19:15 h.</td>
                                <td colSpan="2">TALLERES</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Taller A - Novedades en patología tumoral urológica. Principales novedades en los últimos años.</span></td>
                            </tr>



                            <tr>
                                <td>&nbsp;</td>
                                <td>Directores Científicos:</td>
                                <td>Dr. José Manuel Cózar Olmo<br />Dr. José Luis Álvarez-Ossorio</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponentes:</td>
                                <td>Dra. Pilar González-Peramato<br />Dr. Álvaro Serrano Pascual</td>
                            </tr>



                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2"><span className="subtitle-urovi2021">Taller B - Litiasis urinaria. Casos Clínicos</span></td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Director Científico:</td>
                                <td>Dr. Víctor Carrero López</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Moderador:</td>
                                <td>Dr. Manuel Esteban Fuertes</td>
                            </tr>


                            <tr>
                                <td>&nbsp;</td>
                                <td>Ponentes:</td>
                                <td>Dr. Juan Antonio Mainez Rodríguez<br />Dr. Juan Antonio Galán Llopis</td>
                            </tr>



                            <tr className="title-tr-tbl-programme-urovi2021">
                                <td className="time">19:15 - 19:30 h.</td>
                                <td colSpan="2">Conclusiones y cierre de la segunda jornada</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2">Directores Científicos</td>

                            </tr>

                        </table>

                    </div>
                    : this.context.event_settings.event_id == "387" ?

                        <div className="programme-speakers-content-container">

                            <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                                <tr>
                                    <td colSpan="3" className="day">3 MARZO 2021</td>
                                </tr>

                                <tr className="title-tr-tbl-programme">
                                    <td>17:00 - 17:05 h.</td>
                                    <td colSpan="2">BIENVENIDA AL CURSO</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Coordinadores:</td>
                                    <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                </tr>


                                <tr className="title-tr-tbl-programme">
                                    <td>17:05 - 18:05 h.</td>
                                    <td colSpan="2">ACTUALIZACIONES EN TDAH</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Moderador:</td>
                                    <td>Dr. Manuel Ajoy</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Biomarcadores no genéticos</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dr. Juan J. Ortiz</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Nuevos abordajes en el tratamiento del TDAH</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dr. Óscar Herreros</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Coloquio / debate</span></td>
                                </tr>

                                <tr className="title-tr-tbl-programme">
                                    <td>18:05 -19:05 h.</td>
                                    <td colSpan="2">MANEJO CLÍNICO DEL TDAH</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Moderador:</td>
                                    <td>Dr. Xavier Gastaminza</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">TDAH y delitos</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dra. Ana Gago</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">TDAH y autolesiones</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dra. Belén Rubio</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Coloquio/ debate</span></td>
                                </tr>


                                <tr className="title-tr-tbl-programme">
                                    <td>19:05 -19:15 h.</td>
                                    <td colSpan="2">RESUMEN Y CONCLUSIONES</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Coordinadores:</td>
                                    <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                </tr>


                            </table>

                            <p>&nbsp;</p>

                            <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                                <tr>
                                    <td colSpan="3" className="day">4 MARZO 2021</td>
                                </tr>

                                <tr className="title-tr-tbl-programme">
                                    <td>17:00 - 17:05 h.</td>
                                    <td colSpan="2">BIENVENIDA A LA 2ª JORNADA DEL CURSO</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Coordinadores:</td>
                                    <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                </tr>

                                <tr className="title-tr-tbl-programme">
                                    <td>17:05 - 18:20 h.</td>
                                    <td colSpan="2">TDAH A LO LARGO DE LA VIDA</td>
                                </tr>


                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Moderadora:</td>
                                    <td>Prof. Dra. María Dolores Mojarro</td>
                                </tr>


                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">TDAH y género</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dra. Sonia Pérez.</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Transición asistencial desde el servicio infantojuvenil al de adulto</span></td>
                                </tr>


                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dra. Maite Ferrín</td>
                                </tr>


                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">TDAH y Patología Dual, ¿hasta dónde llega el iceberg?</span></td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Ponente:</td>
                                    <td>Dra. Nieves Martínez</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan="2"><span className="subtitle">Coloquio/debate</span></td>
                                </tr>


                                <tr className="title-tr-tbl-programme">
                                    <td>18:20 - 18:35 h.</td>
                                    <td colSpan="2">CLAUSURA DEL CURSO</td>
                                </tr>

                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Coordinadores:</td>
                                    <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                </tr>

                            </table>


                        </div>

                        : this.context.event_settings.event_id == "5" ?

                            <div className="programme-speakers-content-container">

                                <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                                    <tr>
                                        <td colSpan="3" className="day">25 FEBRERO 2022</td>
                                    </tr>

                                    <tr className="title-tr-tbl-programme">
                                        <td>09:00 - 09:10 h.</td>
                                        <td colSpan="2">BIENVENIDA AL CURSO.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Coordinadores:</td>
                                        <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                    </tr>


                                    <tr className="title-tr-tbl-programme">
                                        <td>09:10 - 10:40 h.</td>
                                        <td colSpan="2">BLOQUE I: ACTUALIZACIONES EN TDAH.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Moderador:</td>
                                        <td>Prof. Dr. Francisco Montañés</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Desregulación emocional y TDAH ¿qué evidencias tenemos? <small>(25 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dra. Ana Gago</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle-coloquio">Coloquio <small>(20 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">La adherencia terapéutica en el TDAH en niños y adolescentes. <small>(25 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dra. Maite Ferrín</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle-coloquio">Coloquio <small>(20 min)</small></span></td>
                                    </tr>

                                    <tr className="title-tr-tbl-programme">
                                        <td>10:40 - 11:55 h.</td>
                                        <td colSpan="2">BLOQUE II: TDAH EN EL ADULTO - DE LA TEORÍA A LA PRÁCTICA:<br /> TRES SITUACIONES FRECUENTES EN LA CLÍNICA.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Moderador:</td>
                                        <td>Dr. Xavier Gastaminza</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Paciente adulto que acude a consulta con antecedentes de diagnóstico<br />en la infancia y sin tratamiento actual. <small>(15 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dra. María del Juncal Sevilla</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Paciente adulto que acude a consulta sin antecedentes<br />de diagnóstico previo de TDAH. <small>(15 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dra. Nieves Martínez Luna</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Paciente adulto que acude a consulta presentando Patología Dual. <small>(15 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dr. Antonio Terán</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle-coloquio">Coloquio <small>(30 min)</small></span></td>
                                    </tr>

                                    <tr className="title-tr-tbl-programme">
                                        <td>11:55 - 12:15 h.</td>
                                        <td colSpan="2">DESCANSO.</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="3">&nbsp;</td>

                                    </tr>


                                    <tr className="title-tr-tbl-programme">
                                        <td>12:15 - 13:45 h.</td>
                                        <td colSpan="2">BLOQUE III: RETOS CLÍNICOS DEL TDAH<br />EN LA PSIQUIATRÍA DE LA INFANCIA Y DE LA ADOLESCENCIA.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Moderador:</td>
                                        <td>Prof. Dr. Francisco Montañés</td>
                                    </tr>


                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Impacto y repercusiones en la vida diaria del paciente con TDAH. <small>(25 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Prof. Dr. Pedro Ruiz</td>
                                    </tr>


                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle-coloquio">Coloquio <small>(20 min)</small></span></td>
                                    </tr>


                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle">Diagnóstico del TDAH en niños preescolares<br />¿Es posible, problemas que plantea y hay ventajas? <small>(25 min)</small></span></td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponente:</td>
                                        <td>Dr. Óscar Herreros</td>
                                    </tr>


                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="2"><span className="subtitle-coloquio">Coloquio <small>(20 min)</small></span></td>
                                    </tr>



                                    <tr className="title-tr-tbl-programme">
                                        <td>13:45 - 14:05 h.</td>
                                        <td colSpan="2">EVIDENCIAS BASADAS EN EL CONSENSO INTERNACIONAL DE TDAH 2021.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Moderadora:</td>
                                        <td>Prof. Dra. María Dolores Mojarro</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Ponentes:</td>
                                        <td>Dr. Xavier Gastaminza<br />Prof. Dr. Francisco Montañés</td>
                                    </tr>



                                    <tr className="title-tr-tbl-programme">
                                        <td>14:05 - 14:10 h.</td>
                                        <td colSpan="2">CONCLUSIONES Y CIERRE.</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Coordinadores:</td>
                                        <td>Prof. Dr. Francisco Montañés<br />Dr. Xavier Gastaminza</td>
                                    </tr>


                                </table>

                                <p>&nbsp;</p>




                            </div>


                            : this.context.event_settings.event_id == "3" ?

                                <div className="programme-speakers-content-container UROVI2021">



                                    <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">

                                        <tr>
                                            <td colSpan="3" className="day">
                                                Manejo de la TVP residual y prevención de posibles secuelas<br />
                                                30 noviembre | 17:30-18:45h</td>
                                        </tr>

                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Moderador:</td>
                                        </tr>

                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Rodrigo-Rial-Horcajo.jpg" /><br />
                                                <strong>Dr. Rodrigo Rial Horcajo</strong><br />Presidente del CEFyL. Servicio Angiología y Cirugía Vascular del Hospital HM Torrelodones.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Rodrigo-Rial-Horcajo')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>


                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Ponentes:</td>
                                        </tr>


                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Beatriz-Pombo-Vide.jpg" /><br />
                                                <strong>Dra. Beatriz Pombo Vide</strong><br />Servicio de Medicina Interna del Hospital Universitario Lucus Augusti. Lugo.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Beatriz-Pombo-Vide')} className="tromboencuentros2021-cv-ponente">Más información</a><br /><br />

                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Edelmira-Marti-Saez.jpg" /><br />
                                                <strong>Dra. Edelmira Martí Saez</strong><br />Servicio de Hematología y Hemoterapia del Hospital Clínico Universitario de Valencia.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Edelmira-Marti-Saez')} className="tromboencuentros2021-cv-ponente">Más información</a><br /><br />

                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Ignacio-Lojo-Rocamonde.jpg" /><br />
                                                <strong>Dr. Ignacio Lojo Rocamonde</strong><br />Servicio de Angiología y Cirugía Vascular del Hospital Quirón de A Coruña.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Ignacio-Lojo-Rocamonde')} className="tromboencuentros2021-cv-ponente">Más información</a><br /><br />

                                            </td>


                                        </tr>




                                    </table>

                                    <p>&nbsp;</p>



                                    <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">

                                        <tr>
                                            <td colSpan="3" className="day">
                                                ETV asociada a metástasis del sistema nervioso central y su manejo<br />
                                                26 octubre | 17:30-18:45h</td>
                                        </tr>

                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Moderador:</td>
                                        </tr>

                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Enrique-Gallardo.jpg" /><br />
                                                <strong>Dr. Enrique Gallardo</strong><br />Servicio de Oncología Médica. Consorci Corporació Sanitària Parc Taulí.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Enrique-Gallardo')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>


                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Ponentes:</td>
                                        </tr>


                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Virginia-Martinez.jpg" /><br />
                                                <strong>Dra. Virginia Martínez</strong><br />Servicio de Oncología Médica del Hospital. Universitario de La Paz.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Virginia-Martinez')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Mercedes-Biosca.jpg" /><br />
                                                <strong>Dra. Mercedes Biosca.</strong><br />Servicio de Medicina Interna - Equipo de Asistencia Compartida y Medicina Hospitalista en el Servicio de Neurocirugía del Hospital Universitari Vall d’Hebron.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Mercedes-Biosca')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Pere-Cardona.jpg" /><br />
                                                <strong>Dr. Pere Cardona</strong><br />Coordinador Patología Neurovascular del Hospital Universitario de Bellvitge.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Pere-Cardona')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Ana-Margarita-Redondo.jpg" /><br />
                                                <strong>Dra. Ana Margarita Redondo</strong><br />Servicio de Hematología y Hemoterapia. Complejo Hospitalario de Navarra. Pamplona.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Ana-Margarita-Redondo')} className="tromboencuentros2021-cv-ponente">Más información</a>


                                            </td>


                                        </tr>




                                    </table>

                                    <p>&nbsp;</p>


                                    <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">

                                        <tr>
                                            <td colSpan="3" className="day">
                                                Diagnóstico y manejo del TEP en la paciente gestante<br />
                                                6 julio | 17:30-18:45h</td>
                                        </tr>

                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Coordinadora:</td>
                                        </tr>

                                        <tr>
                                            <td className="encuentrosexperto2021">

                                                <strong>Dra. Remedios Otero Candelera</strong><br />Servicio de Neumología. Hospital Universitario Virgen del Rocío, Sevilla.
                                            </td>


                                        </tr>


                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Ponentes:</td>
                                        </tr>


                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Elisa-Llurba-Olive.jpg" /><br />
                                                <strong>Dra. Elisa Llurba Olivé</strong><br />Servicio de Ginecología y Obstetricia. Hospital de la Santa Creu i Sant Pau, Barcelona.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Elisa-Llurba-Olive')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Begona-Fernandez-Jimenez.jpg" /><br />
                                                <strong>Dra. Begoña Fernandez Jimenez</strong><br />Servicio de Hematología. Hospital Universitario de Móstoles, Madrid<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Begona-Fernandez-Jimenez')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Raquel-Lopez-Reyes.jpg" /><br />
                                                <strong>Dra. Raquel López Reyes</strong><br />Servicio de Neumología. Hospital Universitario y Politécnico de La Fe, Valencia.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Raquel-Lopez-Reyes')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>




                                    </table>

                                    <p>&nbsp;</p>


                                    <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">

                                        <tr>
                                            <td colSpan="3" className="day">
                                                Paciente oncológico en cuidados paliativos y ETV<br />
                                                15 Junio 2021 | 17:30-18:45h</td>
                                        </tr>

                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Coordinador:</td>
                                        </tr>

                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Jose-Maria-Pedrajas.jpg" /><br />
                                                <strong>Dr. José María Pedrajas</strong><br />Servicio de Medicina Interna del Hospital Clínico de Madrid<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Jose-Maria-Pedrajas')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>


                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Ponentes:</td>
                                        </tr>


                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <strong>Dra. Maite Antonio</strong><br />Unidad de Oncohematogeriatría del Instituto Oncológico Catalán de Barcelona<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Maite-Antonio')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Carmen-Beato.jpg" /><br />
                                                <strong>Dra. Carmen Beato</strong><br />Servicio Oncología Médica del Hospital Virgen Macarena de Sevilla<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Carmen-Beato')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Leyre-Diez.jpg" /><br />
                                                <strong>Dra. Leyre Díez</strong><br />Unidad de Cuidados Paliativos. Hospital La Paz. Madrid<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Leyre-Diez')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>




                                    </table>

                                    <p>&nbsp;</p>



                                    <table cellPadding="0" cellSpacing="0" className="tbl-programme encuentrosexperto2021">

                                        <tr>
                                            <td colSpan="3" className="day">
                                                Manejo del paciente frágil con ETV<br />
                                                26 Mayo 2021 | 17:30-18:45h</td>
                                        </tr>

                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Coordinadora:</td>
                                        </tr>

                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Carmen-Fernandez-Capitan.jpg" /><br />
                                                <strong>Dra. Carmen Fernández Capitán.</strong> Servicio de Medicina Interna.<br />Hospital Universitario La Paz, Madrid.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Carmen-Fernandez-Capitan')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                            </td>


                                        </tr>


                                        <tr className="title-tr-tbl-programme-encuentrosexperto2021">
                                            <td>Ponentes:</td>
                                        </tr>


                                        <tr>
                                            <td className="encuentrosexperto2021">
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Vladimir-Salazar-Rosa.jpg" /><br />
                                                <strong>Dr. Vladimir Salazar Rosa.</strong> Servicio de Medicina Interna.<br />Hospital Universitario Virgen de la Arrixaca, Murcia.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Vladimir-Salazar-Rosa')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Rocio-Velasco-Guzman-Lazaro.jpg" /><br />
                                                <strong>Dra. Rocío Velasco Guzmán de Lázaro.</strong> Servicio de Geriatría.<br />Hospital de Cantoblanco - La Paz, Madrid.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Rocio-Velasco-Guzman-Lazaro')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Juan-Jose-Lopez-Nunez.jpg" /><br />
                                                <strong>Dr. Juan José López Núñez.</strong> Servicio de Medicina Interna.<br />Hospital Germans Trias i Pujol, Barcelona.<br />
                                                <a onClick={() => this.onSpeakersCVOpenHandler('Juan-Jose-Lopez-Nunez')} className="tromboencuentros2021-cv-ponente">Más información</a>
                                                <br /><br />
                                            </td>


                                        </tr>




                                    </table>

                                </div>

                                : this.context.event_settings.event_id == "4" ?

                                    <div className="programme-speakers-content-container UROVI2021">

                                        <div class="programme-entry-viencuentroetev">

                                            <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                                                <tr>
                                                    <td colSpan="3" className="day etev2021">LUNES, 21 JUNIO</td>
                                                </tr>

                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">17:00 - 17:05</td>
                                                    <td colSpan="2">Presentación</td>
                                                </tr>

                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">17:05 - 17:35</td>
                                                    <td colSpan="2">Pro/Contra:<br />¿Antiagregación en pacientes COVID-19?</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2">
                                                        <span className="speaker">Dr. José Luis Ferreiro Gutiérrez - Pro</span><br />
                                                        <span className="speaker">Dr. Pedro Ruiz Artacho - Contra</span>
                                                    </td>

                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">17:35 - 18:05</td>
                                                    <td colSpan="2">La visión del experto:<br />Controversias acerca del dímero D en pacientes con COVID-19</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2"><span className="speaker">Dr. Pere Doménech Santasusana</span></td>
                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021 taller">
                                                    <td className="time">18:05 - 18:50</td>
                                                    <td colSpan="2"><u>Taller 1:</u><br />HBPM en situaciones especiales en el COVID-19: Insuficiencia Renal, Pesos extremos, Paciente crítico</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2"><span className="speaker">Dr. Joan Carles Reverter Calatayud</span><br />
                                                        <span className="speaker">Dra. Nuria Ruiz-Giménez Arrieta</span>
                                                    </td>
                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">18:50 - 19:15</td>
                                                    <td colSpan="2">Entrega de premios y exposición de los ganadores del IV Concurso de pósteres para el tratamiento de la ETEV</td>
                                                </tr>


                                            </table>

                                        </div>

                                        <div class="programme-entry-viencuentroetev">

                                            <table cellPadding="0" cellSpacing="0" className="tbl-programme">

                                                <tr>
                                                    <td colSpan="3" className="day etev2021">MARTES, 22 JUNIO</td>
                                                </tr>

                                                <tr className="title-tr-tbl-programme-urovi2021 taller">
                                                    <td className="time">17:00 - 17:45</td>
                                                    <td colSpan="2"><u>Taller 2:</u><br />Terapia puente: Una visión práctica</td>
                                                </tr>


                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2"><span className="speaker">Dra. Olga Gavín Sebastián</span><br />
                                                        <span className="speaker">Dra. Vanesa Roldán Schilling</span>
                                                    </td>
                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">17:45 - 18:45</td>
                                                    <td colSpan="2">Debate Multidisciplinar:<br />Cáncer y trombosis</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2">

                                                        MODERADOR:<br />
                                                        <span className="speaker">Dr. Ramón Lecumberri Villamediana</span><br /><br />

                                                        PONENTES:<br />
                                                        <span className="speaker">Dr. Enrique Gallardo Díaz</span><br />
                                                        <span className="speaker">Dra. Elena Mª Pina Pascual</span><br />
                                                        <span className="speaker">Dr. Luis Jara Palomares</span><br />
                                                        <span className="speaker">Dr. Ignacio Leal Lorenzo</span>

                                                    </td>
                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">18:45 - 19:15</td>
                                                    <td colSpan="2">Pro/Contra:<br />¿Se debe tratar el TEP subsegmentario?</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td colSpan="2">

                                                        <span className="speaker">Dra. Carmen Fernández Capitán - Pro</span><br />
                                                        <span className="speaker">Dr. Joan Carles Souto Andrés - Contra</span>

                                                    </td>
                                                </tr>


                                                <tr className="title-tr-tbl-programme-urovi2021">
                                                    <td className="time">19:15</td>
                                                    <td colSpan="2">Cierre</td>
                                                </tr>


                                            </table>

                                        </div>

                                    </div>



                                    :
                                    null
                }

            </React.Fragment>
        );
    }
}

export default ProgrammeHtml;