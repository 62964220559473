import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import { Provider } from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import globalReducer from './store/reducers/global'
import counterReducer from './store/reducers/counter'
import liveReducer from './store/reducers/live'
import secretariatReducer from './store/reducers/secretariat'

import './fonts/Dotmatrx.ttf';
import './fonts/MyriadPro-Regular.otf';
import './index.css';
import './delegates-bar-vc.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { CookiesProvider } from 'react-cookie';

const rootReducer = combineReducers({
  global: globalReducer,
  crt: counterReducer,
  live: liveReducer,
  secretariat: secretariatReducer
})

const logger = store => {
  return next => {
    return action => {
        console.log('Middleware dispathing', action);
        const result = next(action);
        console.log('Middleware next state', store.getState());
        return result;
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider><App /></CookiesProvider>
  </Provider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
