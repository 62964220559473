import React, { Component } from 'react'
import { withTranslation } from "react-i18next"

class ContactRequest extends Component {
    

    render() {

        let titleText 
        switch (this.props.request.type) {
            case "video":
                titleText = this.props.t('contact_video_await');
                break;
            case "voice":
                titleText = this.props.t('contact_call_await')
                break;
            case "txt":
                titleText = this.props.t('contact_text_await')
                break;
            default:
                break;
        }

        const photo = this.props.request.photo !== undefined && this.props.request.photo !== "" ? <img alt="" src={this.props.request.photo} /> : <img src="/img/default-login-pic.png" className="img-avatar" alt="" />

        return (

            <div className="stand-detail-available-delegates-container">
                
                <div className="stand-detail-available-delegates-close-icon" onClick={this.props.closeContactRequest}> </div>

                <div className="stand-detail-available-delegates-header-container">
                    {titleText} <br />
                <span className="description">{this.props.t('contact_accept_text')} </span>
                </div>

                <div className="stand-detail-available-delegates-content-container">
                    <div className="stand-detail-available-delegates-content-entry">
                        <div className="stand-detail-available-delegates-photo-container">
                            <div className="stand-detail-available-delegates-photo">{photo}</div>
                        </div>
                        <div className="stand-detail-available-delegates-name-container">
                            <div className="stand-detail-available-delegates-bottom-contact-container"></div>
                            <strong>{this.props.request.name}</strong><br />{this.props.request.institution}
                        </div>
                    </div>
                    <div className="stand-detail-available-delegates-content-entry">
                        <div className="contact-request-button-accept" onClick={this.props.accept}>{this.props.t('contact_request_accept')}</div>
                        <div className="contact-request-button-decline" onClick={this.props.decline}>{this.props.t('contact_request_cancel')}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ContactRequest);