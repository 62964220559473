import React, { Component } from 'react';
import Stand from '../../../components/Stand/Stand';
import StandLogo from '../../../components/StandLogo/StandLogo';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import axios from 'axios';
import configData from '../../../config.json';
import StandDisclaimer from '../../../components/StandDisclaimer/StandDisclaimer';

class Industria extends Component {

    state = {
        areaSelected: "",
        areas: [],
        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'industria', ''),
        renderType: 'stands',
        warningOn: false,
        warningText: "",

    }

    static contextType = AuthContext;

    expoNavigationHandler = (area) => {
        this.setState({ areaSelected: area });
    }

    onWarningCloseHandler = () => {
        this.setState({ warningOn: false });
    }


    componentDidMount() {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/industria/", data)
            .then(response => {

                if (response.data !== undefined && response.data.length > 0) {

                    const industria = response.data[0];

                    this.setState({
                        areaSelected: industria.areaSelected,
                        areas: industria.areas,
                        backgroundImage: industria.backgroundImage,
                        renderType: industria.renderType !== undefined ? industria.renderType : 'stands',
                        warningOn: industria.warningOn !== undefined ? industria.warningOn : false,
                        warningText: industria.warningText !== undefined ? industria.warningText : true,
                    });


                } else {
                    this.setState({
                        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'industria', '')
                    })
                }
            })
            .catch(error => {
                this.setState({
                    backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'industria', '')
                })
                console.log(error.message);
            })
    }

    render() {

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';

        const nAreas = this.state.areas.length;
        let expoNav;
        let line1, line2, line3
        let classAreaContainer;

        let renderOutput;

        if (nAreas > 1) {
            expoNav = this.state.areas.map((item) => {
                const classMenuEntry = item.area === this.state.areaSelected
                    ? "expo-menu-entry selected"
                    : "expo-menu-entry";
                return <div key={item.area} onClick={() => this.expoNavigationHandler(item.area)} className={classMenuEntry}>{item.area}</div>
            })
        }


        // render logic for the rows and stands
        const area = this.state.areas.filter((e) => {
            return e.area === this.state.areaSelected;  // get only the details of the area where we are
        })

        if (this.state.renderType == 'logos') {

            classAreaContainer = "industria-logos-area-container " + this.context.event_code;

            if (nAreas > 0) {
                for (let index = 0; index < area[0].rows.length; index++) { // circle rows available in the area
                    const row = area[0].rows[index];

                    let classContainer = "industria-logos-area-container-row row-1"
                    switch (row.level) {
                        case 1:

                            classContainer = "industria-logos-area-container-row row-1";
                            line1 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            return <StandLogo key={item.id} standType={item.type} logo={item.logo} link={link}></StandLogo>
                                        } else {
                                            if (this.context.event_id == "336") {
                                                return <StandLogo eventId={this.context.event_id} standType={item.type} logo={item.logo}></StandLogo>
                                            }
                                            else {
                                                return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><StandLogo eventId={this.context.event_id} standType={item.type} logo={item.logo}></StandLogo></Link>
                                            }

                                        }
                                    } else {
                                        return <StandLogo key={item.id} standType={item.type} logo={item.logo}></StandLogo>
                                    }
                                })}
                            </div>)
                            break;

                        case 2:

                            classContainer = "industria-logos-area-container-row row-2";
                            line2 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            return <StandLogo key={item.id} standType={item.type} logo={item.logo} link={link}></StandLogo>
                                        } else {
                                            return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><StandLogo key={item.id} standType={item.type} logo={item.logo}></StandLogo></Link>
                                        }
                                    } else {
                                        return <StandLogo key={item.id} standType={item.type} logo={item.logo}></StandLogo>
                                    }
                                })}
                            </div>)
                            break;
                        case 3:

                            classContainer = "industria-logos-area-container-row row-3"
                            line3 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            return <StandLogo key={item.id} standType={item.type} logo={item.logo} link={link}></StandLogo>
                                        } else {
                                            return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><StandLogo key={item.id} standType={item.type} logo={item.logo}></StandLogo></Link>
                                        }
                                    } else {
                                        return <StandLogo key={item.id} standType={item.type} logo={item.logo}></StandLogo>
                                    }
                                })}
                            </div>)
                            break;
                        default:
                            break;
                    }

                }
            }

            renderOutput = <div className={classAreaContainer}>
                <div className="industria-logos-area-wrapper">
                    {line1}
                    {line2}
                    {line3}

                </div>
            </div>

        } else {

            if (nAreas > 0) {
                for (let index = 0; index < area[0].rows.length; index++) { // circle rows available in the area
                    const row = area[0].rows[index];

                    let classContainer = "industria-stands-row1"
                    switch (row.level) {
                        case 1:

                            classAreaContainer = "industria-stands-area-container total-rows-1";
                            classContainer = "industria-stands-row1";
                            line1 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            console.log(link)
                                            return <Stand key={item.id} standType={item.type} logo={item.logo} link={link}></Stand>
                                        } else {
                                            return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><Stand standType={item.type} logo={item.logo}></Stand></Link>
                                        }
                                    } else {
                                        return <Stand key={item.id} standType={item.type} logo={item.logo}></Stand>
                                    }
                                })}
                            </div>)
                            break;

                        case 2:

                            classAreaContainer = "industria-stands-area-container total-rows-2";
                            classContainer = "industria-stands-row2";
                            line2 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            return <Stand key={item.id} standType={item.type} logo={item.logo} link={link}></Stand>
                                        } else {
                                            return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><Stand key={item.id} standType={item.type} logo={item.logo}></Stand></Link>
                                        }
                                    } else {
                                        return <Stand key={item.id} standType={item.type} logo={item.logo}></Stand>
                                    }
                                })}
                            </div>)
                            break;
                        case 3:
                            classAreaContainer = "industria-stands-area-container total-rows-3"
                            classContainer = "industria-stands-row3"
                            line3 = (<div className={classContainer}>
                                {row.stands.map((item, index) => {
                                    if (item.open) {
                                        const link = item.url !== undefined && item.url !== "" ? item.url : "";
                                        if (link !== "") {
                                            return <Stand key={item.id} standType={item.type} logo={item.logo} link={link}></Stand>
                                        } else {
                                            return <Link key={item.id} to={`/congress/industria-stand/${item.code}`}><Stand key={item.id} standType={item.type} logo={item.logo}></Stand></Link>
                                        }
                                    } else {
                                        return <Stand key={item.id} standType={item.type} logo={item.logo}></Stand>
                                    }
                                })}
                            </div>)
                            break;
                        default:
                            break;
                    }

                }
            }

            renderOutput = <div className={classAreaContainer}>
                {line3}
                {line2}
                {line1}
            </div>

        }

        //alert(this.state.warningOn);

        const warningPopUp = this.state.warningOn ? <StandDisclaimer text={this.state.warningText} onWarningClose={this.onWarningCloseHandler}></StandDisclaimer> : null;

        return (
            <React.Fragment>
                {expoNav ?
                    <div className="expo-menu-container">
                        <div className="expo-menu-wrapper">
                            {expoNav}
                        </div>
                    </div>
                    : null
                }

                {/* <div className="industria-intro-text-container">
                    <span className="industria-intro-text-subtitle"> Le exposición comercial estará disponible a partir del próximo lunes 14 de septiembre. </span>
                </div> */}

                {renderOutput}
                {warningPopUp}
            </React.Fragment>
        );
    }
}

export default Industria;