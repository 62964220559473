import React from 'react';

function Session(props) {
    const locked = props.locked ? <img alt="" src="/img/icon-lock.png" /> : "";
    const companyLogo = props.companyLogo ? <React.Fragment><br /><img alt="" src={props.companyLogo} /></React.Fragment> : null;
    return (
        <tr>
            <td className="td-time">{props.time}</td>
            <td className={props.noTheme ? `td-title not-theme` : `td-title`}>
                {props.title}
                {companyLogo}
            </td>
            <td className="td-icon">{locked}</td>
        </tr>
    );
}

export default Session;