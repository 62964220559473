import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectSocket, disconnectSocket } from '../../store/actions/global';
import AuthContext from '../../context/auth-context';
import { Redirect } from 'react-router';
import axios from 'axios';
import io from "socket.io-client";
import { withTranslation } from 'react-i18next'
import configData from '../../config.json';
import MyOpenTok from '../OpenTok/OpenTok';
/* import { throwStatement } from '@babel/types'; */
/* import MeetingAccess from '../../components/MeetingAccess/MeetingAccess'; */

class Meeting extends Component {

    state = {
        id : "", 
        type : "", 
        company_id : "", 
        host_id : "", 
        host_name : "", 
        host_surname : "", 
        host_email : "", 
        title : "", 
        description : "", 
        date_start : "", 
        date_end : "", 
        security : "",
        waiting_room : null, 
        default_owner_video : null, 
        default_owner_sound : null, 
        default_participant_video : null, 
        default_participant_sound : null, 
        connect_at_anytime : null, 
        silence_participant_on_enter : null, 
        nParticipants: 0,
        locked : null, 
        state : '',
        participants_in:[],
        participants_waiting:[],
        myProfile: '',
        myAccess: 'enter',
        myName: '',
        myEmail:'',
        myRole: '',
        mySound: true,
        myVideo: true,
        openTok_apiKey:"",
        openTok_sessionId:"",
        openTok_token:"",
        messages:[],
        message:"",
        message_to:"",
        screenShare:false
    }

    static contextType = AuthContext;

    
    

    componentDidMount() {
        
        this.loadMeeting(this.props.match.params.meetingId)

        //this.initSocketConnection();

        /* fetch(configData.VIDEO_API + this.props.match.params.meetingId)
            .then(data => data.json())
            .then((credentials) => {
                this.setState({
                    openTokApiKey: credentials.apiKey,
                    openTokSessionId: credentials.sessionId,
                    openTolToken: credentials.token
                })
            })
            .catch((err) => {
                console.error('Failed to get session credentials', err);
                this.setState({ error: 'Failed to get opentok sessionId and token. Make sure you have updated the config.js file.' });
            });
 */
    }

    loadMeeting(meeting_id) {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/meetings/" + meeting_id, data)
            .then(response => {
                
                if (response.data !== undefined && response.data.length > 0){
                    const meeting = response.data[0];
                    
                    this.setState({
                        id : meeting._id, 
                        type : meeting.type, 
                        company_id : meeting.company_id, 
                        host_id : meeting.host_id, 
                        host_name : meeting.host_name, 
                        host_surname : meeting.host_surname, 
                        host_email : meeting.host_email, 
                        title : meeting.title, 
                        description : meeting.description, 
                        date_start : meeting.date_start,
                        date_end : meeting.date_end, 
                        security : meeting.security,
                        waiting_room : meeting.waiting_room, 
                        default_owner_video : meeting.default_owner_video, 
                        default_owner_sound : meeting.default_owner_sound, 
                        default_participant_video : meeting.default_participant_video, 
                        default_participant_sound : meeting.default_participant_sound, 
                        connect_at_anytime : meeting.connect_at_anytime, 
                        silence_participant_on_enter : meeting.silence_participant_on_enter, 
                        nParticipants: meeting.participants,
                        locked : meeting.locked, 
                        state : meeting.state,
                    })        
                }
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    initSocketConnection = () => {

        const server = configData.SOCKET_API_INDUSTRY + "/meeting";
        this.socket = io.connect(server, { transports: ['websocket'], query: { token: this.context.token } });
        this.socket.on('connect', () => {

            const data = {
                meeting_id: this.props.match.params.meetingId
            }
            // #### USERS IN THE ROOM -------------------------------------------
            this.socket.on("joined", data => {   // I joined the meeting
                
                const meeting = data.meeting;
                
                const participants_in = data.participants !== undefined ? data.participants.filter((element,index) =>{
                    return(
                        element.state === "in" 
                    )
                }) : [];

                const participants_waiting = data.participants !== undefined ? data.participants.filter((element,index) =>{
                    return(
                        element.state === "waiting" && element.user_id !== this.context.id
                    )
                }) : [];

                const me = data.participants !== undefined ? data.participants.filter((element,index) =>{
                    return(
                        element.user_id === this.context.id
                    )
                }) : [];

                if (me[0].meeting_role === "host"){                    
                    this.socket.emit('start', {meeting_id:this.state.id});
                }

                this.setState({
                    id : meeting._id, 
                    type : meeting.type, 
                    company_id : meeting.company_id, 
                    host_id : meeting.host_id, 
                    host_name : meeting.host_name, 
                    host_surname : meeting.host_surname, 
                    host_email : meeting.host_email, 
                    title : meeting.name, 
                    description : meeting.description, 
                    date_start : meeting.date_start,
                    date_end : meeting.date_end, 
                    security : meeting.security,
                    waiting_room : meeting.waiting_room, 
                    default_owner_video : meeting.default_owner_video, 
                    default_owner_sound : meeting.default_owner_sound, 
                    default_participant_video : meeting.default_participant_video, 
                    default_participant_sound : meeting.default_participant_sound, 
                    connect_at_anytime : meeting.connect_at_anytime, 
                    silence_participant_on_enter : meeting.silence_participant_on_enter, 
                    nParticipants: meeting.participants,
                    locked : meeting.locked, 
                    state : meeting.state,                    
                    participants_in:participants_in,
                    participants_waiting:participants_waiting,
                    myProfile: me !== undefined ? me[0].meeting_role : 'attendee',
                    myAccess: me !== undefined ? me[0].state : '',
                    myName: me !== undefined ? me[0].name : '',
                    myPicture: me !== undefined ? me[0].picture : '',
                    myEmail: me !== undefined ? me[0].email : '',
                    myRole: me !== undefined ? me[0].role : '',
                    mySound: me !== undefined ? me[0].sound_on : '',
                    myVideo: me !== undefined ? me[0].video_on : '',
                    messages:[]
                })

                
            });      
            
            this.socket.on("in", data => {   // a new participant entered the meeting
                
                let newParticipants_in;
                let newParticipantes_waiting

                let add = false;

                if (data!== undefined && data.state === "in"){
                
                    // check if it is already in 
                    const alreadyIn = this.state.participants_in.filter(el => el.user_id === data.user_id)
                    if (alreadyIn!== undefined && alreadyIn.length>0){
                        // already in, nothing to do 
                    } else {
                        newParticipants_in = this.state.participants_in;
                        newParticipants_in.push(data);
                        add = true;
                    }

                    // remove from the waiting room, if needed
                    newParticipantes_waiting = this.state.participants_waiting.filter(el => el.user_id !== data.user_id)
                    
                    this.setState({
                        participants_in: add ? newParticipants_in : this.state.participants_in,
                        participants_waiting: newParticipantes_waiting
                    })

                }

                
                if (data!== undefined && data.state === "waiting" && this.state.myProfile === "host"){
                    
                    // check if it is already waiting
                    const alreadyWaiting = this.state.participants_waiting.filter(el => el.user_id === data.user_id)
                    if (alreadyWaiting!== undefined && alreadyWaiting.length>0){
                        // already in, nothing to do 
                    } else {
                        newParticipantes_waiting = this.state.participants_waiting;
                        newParticipantes_waiting.push(data);
                        add = true;
                    }

                    // remove from the waiting room, if needed
                    newParticipants_in = this.state.participants_in.filter(el => el.user_id !== data.user_id)

                    this.setState({
                        participants_in: newParticipants_in,
                        participants_waiting: add ? newParticipantes_waiting : this.state.participants_waiting
                    })

                }
            });      

            this.socket.on("leave", data => {   // a participant left then meeting                
                const newParticipants_in = this.state.participants_in.filter(el => el.user_id !== data.user_id)
                const newParticipantes_waiting = this.state.participants_waiting.filter(el => el.user_id !== data.user_id)
                this.setState({
                    participants_in: newParticipants_in,
                    participants_waiting: newParticipantes_waiting
                })
            });


            this.socket.on("start", data => {   // meeting started
                if(data !== undefined){
                    this.setState({
                        state: "started",
                        openTok_apiKey: data.openTok.apiKey !== undefined ? data.openTok.apiKey : "",
                        openTok_sessionId: data.openTok.sessionId !== undefined ? data.openTok.sessionId : "",
                        openTok_token: data.openTok.token !== undefined ? data.openTok.token : ""
                    })
                }
            });

            this.socket.on("end", data => {   // meeting ends
                this.endSocketConnection();
                this.setState({
                    state:"ended",
                    myAccess:"enter",
                    participants_waiting:[],
                    participants_in:[],
                    openTok_apiKey:"",
                    openTok_sessionId:"",
                    openTok_token:""
                })
            });

            this.socket.on("accepted", (data) => {   // host accepts or declines participant in meeting -> messat to the own user
                this.setState({
                    participants_in: data.participants,
                    myAccess: "in",
                    openTok_apiKey: data.openTok.apiKey !== undefined ? data.openTok.apiKey : "",
                    openTok_sessionId: data.openTok.sessionId !== undefined ? data.openTok.sessionId : "",
                    openTok_token: data.openTok.token !== undefined ? data.openTok.token : ""
                })
            });

            this.socket.on("declined", () => {   // host accepts or declines participant in meeting -> messat to the own user        
                console.log('declined')
                this.endSocketConnection();
                this.setState({
                    myAccess:"enter",
                    participants_waiting:[],
                    participants_in:[]
                })
            });

            this.socket.on("waiting", data => {   // host puts me into the waiting room
                this.setState({
                    myAccess:"waiting",
                    participants_waiting:[],
                    participants_in:[],
                    openTok_apiKey:"",
                    openTok_sessionId:"",
                    openTok_token:""
                })
            });

            this.socket.on("withdraw", data => {   // host withdraw a participant from the room
                 this.endSocketConnection();
                this.setState({
                    myAccess:"enter",
                    participants_waiting:[],
                    participants_in:[],
                    openTok_apiKey:"",
                    openTok_sessionId:"",
                    openTok_token:""
                })
            });

            this.socket.on("host", data => {   // host makes another user host
                console.log(data)
            });

            this.socket.on("mute-video", data => {   // mute video of a user
                this.setState({myVideo:data})
            });
            this.socket.on("mute-sound", data => {   // mute sound of a user
                this.setState({mySound:data})
            });
            this.socket.on("mute-sound-all", ()=> {   // mute all 
                if (this.state.myProfile !== "host"){
                    this.setState({mySound:false})
                }
            });

            this.socket.on("screen-share", data => {   // host shares-screen
                console.log(data)
            });

            this.socket.on("message", data => {   // host shares-screen
                console.log(data)
                if(data !== undefined){
                    let messages = this.state.messages;
                    messages.push(data);
                    this.setState({messages:messages})
                }
            });



            this.socket.on("disconnect", data => {
                //console.log('force disconnect')
            });

            
            this.socket.emit('join', data); // join the meeting 

        });

    }

    endSocketConnection() {
        if (this.socket !== undefined && this.socket.connected){
            this.socket.disconnect();
        }
    }

    exitMeeting = () => {
        if (this.state.myProfile === "attendee"){
            this.endSocketConnection();
            this.setState({
                myAccess:"enter",
                participants_waiting:[],
                participants_in:[],
                openTok_apiKey:"",
                openTok_sessionId:"",
                openTok_token:""
            })
        }
    }

    endMeeting = () => {
        if (this.state.myProfile === "host"){
            this.setState({
                participants_waiting:[],
                participants_in:[]
            })
            this.socket.emit("end", {meeting_id:this.state.id, user_id:this.context.id}) 
        }
    }

    acceptWaiting = (user_id) => {
        if (this.state.myProfile === "host"){
            this.socket.emit("accept", {meeting_id:this.state.id, user_id:user_id}) 
        }
    }

    declineWaiting = (user_id) => {
        if (this.state.myProfile === "host"){            
            this.socket.emit("decline", {meeting_id:this.state.id, user_id:user_id}) 
        }
    }

    withdrawParticipant = (user_id) => {
        if (this.state.myProfile === "host"){            
            this.socket.emit("withdraw", {meeting_id:this.state.id, user_id:user_id}) 
        }
    }

    backToWaiting = (user_id) => {
        if (this.state.myProfile === "host"){            
            this.socket.emit("waiting", {meeting_id:this.state.id, user_id:user_id}) 
        }
    }

    onSound = () => {
        this.setState((prevState, props) => {
            return { mySound: !prevState.mySound };
        })
    }

    onVideo = () => {
        this.setState((prevState, props) => {
            return { myVideo: !prevState.myVideo };
        })
    }

    logoutHandler = () => {

        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

        // close of the socket

    }

    exitHandler = () => {
        this.props.history.push('/congress/lobby-scientific')
    }

    onMessageHandler = (text) => {
        this.setState({ message: text });
    }

    onMessageToHandler = (value) => {
        this.setState({ message_to: value !== "" ? value : null });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    sendMessage = () => {
        if (this.socket !== undefined && this.socket.connected){
            this.socket.emit('message', {
                meeting_id:this.state.id, 
                user_id:this.context.id,
                message:this.state.message,
                message_to:this.state.message_to
            })
        }
    }

    muteSoundParticipant = (user_id, on) => {
        if (this.socket !== undefined && this.socket.connected){
            this.socket.emit('mute-sound', {
                sound: on,
                meeting_id: this.state.id, 
                user_id: user_id
            })
        }
    }

    muteVideoParticipant = (user_id, on) => {
        if (this.socket !== undefined && this.socket.connected){
            this.socket.emit('mute-video', {
                video: on,
                meeting_id: this.state.id, 
                user_id: user_id
            })
        }
    }

    muteSoundAll = () => {
        if (this.socket !== undefined && this.socket.connected){
            this.socket.emit('mute-sound-all', {
                meeting_id: this.state.id
            })
        }
    }

    shareScreen = () => {
        this.setState((prevState, props) => {
            return { screenShare: !prevState.screenShare };
        })
    }

    render() {

        if (this.props.forceLogout) {
            this.logoutHandler();
        }

        //document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';
        
        const openTokenRender = this.state.openTok_apiKey !== "" && this.state.openTok_sessionId !== "" && this.state.openTok_token !== "" 
            ? <MyOpenTok
                apiKey={this.state.openTok_apiKey}
                sessionId={this.state.openTok_sessionId}
                token={this.state.openTok_token}
                video={this.state.myVideo}
                audio={this.state.mySound}
                name={this.state.myName}
                picture={this.state.myPicture}
                screenShare={this.state.screenShare}
            />
            : null

        
        const participants_waiting_access = this.state.participants_waiting !== undefined 
        ?this.state.participants_waiting.map((el, index) => {
            return(
                <p key= {el.user_id}>
                    Id: {el.user_id}<br/>
                    Name: {el.name}<br/>
                    Email: {el.email}<br/>
                    Meeting profile: {el.meeting_role}<br/>
                    Sound: {el.sound_on ? 'true' : 'false'}<br/>
                    Video: {el.video_on ? 'true' : 'false'}<br/>
                    <span className="cursor-only" onClick={() => this.acceptWaiting(el.user_id)}> ACCEPT </span> <br/> 
                    <span className="cursor-only" onClick={() => this.declineWaiting(el.user_id)}> DECLINE </span>
                    <br/>-----------------------
                </p>
            )
        })
        : null;

        const participants_in_meeting = this.state.participants_in !== undefined 
        ?this.state.participants_in.map((el, index) => {
            const class_name = el.user_id === this.context.id ? "meeting-me" : "";
            return(
                <p key= {el.user_id} className={class_name}>
                    Id: {el.user_id}<br/>
                    Name: {el.name}<br/>
                    Email: {el.email}<br/>
                    Meeting profile: {el.meeting_role}<br/>
                    Sound: {el.sound_on ? <span onClick={() => this.muteSoundParticipant(el.user_id, true)}>true</span> : <span onClick={() => this.muteSoundParticipant(el.user_id, false)}>false</span>}<br/>
                    Video: {el.video_on ? <span onClick={() => this.muteVideoParticipant(el.user_id, true)}>true</span> : <span onClick={() => this.muteVideoParticipant(el.user_id, false)}>false</span>}<br/>
                    {el.user_id !== this.context.id ?
                    <React.Fragment>
                        <span className="cursor-only" onClick={() => this.withdrawParticipant(el.user_id)}> WIDHDRAW </span> <br/>
                        <span className="cursor-only" onClick={() => this.backToWaiting(el.user_id)}> PUT IN WAITING </span>
                        <br/>
                    </React.Fragment>
                    : null}
                    -----------------------
                </p>
            )
        })
        : null;

        const selectParticipants = this.state.participants_in !== undefined 
        ? <select onChange={(event) => this.onMessageToHandler(event.target.value)}>
            <option key="0" value={0}>To all participants</option>
            {this.state.participants_in.map((el, index) => {
                
                if(el.user_id !== this.context.id){
                    return(<option key={el.user_id} value={el.user_id}>{el.name}</option>)
                }
                
            })}
            </select>
        : null;
        
        const chatMessages = this.state.messages.map((element, index) => {
            return(
                <p key={element._id}>
                    {element.author} | {element.date}<br/>
                    {element.message}
                </p>
            )
        })

        return this.context.login(this.context.token) || this.context.autoLogin() ? (
            <React.Fragment>
                <table cellPadding="5" cellSpacing="5"><tbody>
                    <tr>
                        <td colSpan="3">
                            <b>{this.context.email}</b> | {this.state.myProfile} &nbsp; 
                            [{this.socket !== undefined ? this.socket.connected ? 'connected' : 'disconnected' : 'disconnected'}]<br/>
                            {this.state.myName} |
                            {this.state.myEmail} |
                            Sound: {this.state.mySound ? 'true' : 'false'} |
                            Video: {this.state.myVideo ? 'true' : 'false'} 
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <b>ACTIONS</b>
                            <p>
                            {this.state.myAccess === "enter"
                                ? <span className="cursor-only" onClick={this.initSocketConnection}>[Connect to Meeting]</span> 
                                : this.state.myProfile === "host" 
                                    ? <span className="cursor-only" onClick={this.endMeeting}>End Meeting</span>
                                    : <span className="cursor-only" onClick={this.exitMeeting}>Exit Meeting</span>
                            } 
                            | <span className="cursor-only" onClick={this.onSound}> [{this.state.mySound ? "Turn Off Sound" : "Turn on Sound"}] </span>
                            | <span className="cursor-only" onClick={this.onVideo}> [{this.state.myVideo ? "Turn Off Video" : "Turn On Video"}] </span>
                            | {this.state.myProfile === "host"
                                ? <span onClick={this.muteSoundAll}>Mute all</span>
                                : null}
                            | {this.state.myProfile === "host"
                                ? <span onClick={this.shareScreen}>
                                    { this.state.screenShare ? 'Stop Share': 'Share Screen'}
                                    </span>
                                : null}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="1">
                            <form onSubmit={this.onSubmit}>
                                <p>{selectParticipants}</p>
                                <textarea onChange={(event) => this.onMessageHandler(event.target.value)}></textarea>
                                <p><button onClick={this.sendMessage}>Enviar</button></p>
                            </form>
                        </td>
                        <td colSpan="2">{chatMessages}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            {openTokenRender}
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p><b>MEETING DETAILS</b></p>
                        Id: {this.state.id}<br/>
                        type: {this.state.type}<br/> 
                        title: {this.state.title}<br/>

                        -------------<br/>
                        state: {this.state.state}<br/>
                        security: {this.state.security}<br/>
                        Waiting Room: {this.state.waiting_room ? 'true' : 'false'}<br/>
                        locked: {this.state.locked ? 'true' :'false'}<br/>
                        -------------<br/>
                        
                        company_id: {this.state.company_id}<br/>
                        host_id: {this.state.host_id}<br/>
                        host_name: {this.state.host_name}<br/>
                        host_surname: {this.state.host_surname}<br/>
                        host_email: {this.state.host_email}<br/>
                        
                        description: {this.state.description}<br/>
                        date_start: {this.state.date_start}<br/>
                        date_end: {this.state.date_end}<br/>
                        
                        
                        default_owner_video: {this.state.default_owner_video ? 'true' :'false'}<br/>
                        default_owner_sound: {this.state.default_owner_sound ? 'true' :'false'}<br/>
                        default_participant_video: {this.state.default_participant_video ? 'true' :'false'}<br/>
                        default_participant_sound: {this.state.default_participant_sound ? 'true' :'false'}<br/>
                        connect_at_anytime: {this.state.connect_at_anytime? 'true' :'false'}<br/>
                        silence_participant_on_enter: {this.state.silence_participant_on_enter? 'true' :'false'}<br/>
                        nParticipantes:{this.state.nParticipants}<br/>
                        
                        </td>
                        <td valign="top">
                            <p><b>PARTICIPANTES IN</b></p> 
                            {participants_in_meeting}
                        </td>
                        <td valign="top">
                            <p><b>WAITING ROOM</b></p>   
                            {participants_waiting_access}
                        </td>
                    
                    </tr>

                </tbody></table>
            </React.Fragment>
        ) : (
                <Redirect to="/" />
            );
    }
}

const mapStateToProps = state => {
    return {
        forceLogout: state.global.forceLogout
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Meeting));