import React from 'react';
import { useTranslation } from 'react-i18next';

function VideoPlayer(props) {
    
    const { t, i18n } = useTranslation();

    return (
        <div className="video-gallery-container">
            <div className="video-flyer-gallery-company-logo">
                {props.logo 
                ? <img alt= "" src={props.logo} />
                : null}
            </div>
            <div className="video-gallery-wrapper">
                
                    <iframe src={props.video} frameBorder="0" width="100%" height="80%" allow="autoplay; fullscreen" allowFullScreen></iframe>
                
            </div>
            <div onClick={props.close} className="video-gallery-close-btn-container">{t('stand_gallery_close')}</div>
        </div>
    );
}

export default VideoPlayer;