import React, { Component } from 'react';
import Delegate from './Delegate';
import axios from 'axios';
import AuthContext from '../../../../context/auth-context'
import { element } from 'prop-types';
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class DelegatesList extends Component {
    
    state = {        
        delegates:[]
    }

    static contextType = AuthContext;
 
    componentDidMount(){
        
        // get the list of current delegates on the apge
        const currDelegates = this.props.delegatesInRoom;

        // get the list of delegates for the company
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/stands/" + this.props.standId + "/delegates/", data)
        .then(response => {
            this.setState({
                delegates : response.data
            })                
        })
        .catch(error => {
            console.log(error.message);
        })

    }

    onTxtHandler = (id) => {
        this.props.onTxt(id);
    }
    onVoiceHandler = (id) => {
        this.props.onVoice(id);
    }
    onVideoHandler = (id) => {
        this.props.onVideo(id);
    }

    
    
    render() {        

        let myDelegates = []
        myDelegates = this.state.delegates.filter((ins) => {return ins.active})
        .map((el, index) => {

            let isThere = false;
            if ( this.props.delegatesInRoom !== undefined && this.props.delegatesInRoom.length > 0){
                const inTheRoom = this.props.delegatesInRoom.filter((elem) => { return elem.user.id === el.delegateId})
                if (inTheRoom !== undefined && inTheRoom.length > 0){
                    isThere = true
                } 
            }

            return <Delegate key={el.delegateId}
                id={el.delegateId} 
                userId={this.context.id}
                email={el.email} 
                stand={el.stand} 
                name={el.name} 
                departament={el.department} 
                photo={el.photo} 
                inRoom={isThere}
                busy={false} 
                sendMessage={this.props.sendMessage}
                onTxt={this.onTxtHandler}
                onVoice={this.onVoiceHandler}
                onVideo={this.onVideoHandler}
                    />
        }) 
        

        return (

            <div className="stand-detail-available-delegates-container">

                <div className="stand-detail-available-delegates-close-icon" onClick={this.props.closeDelegatesList}> </div>

                <div className="stand-detail-available-delegates-header-container">
                    {this.props.t('available_delegates')} <br />
                    <span className="description"> {this.props.t('available_delegates_subtitle')} </span>
                </div>

                <div className="stand-detail-available-delegates-content-container">
                    {myDelegates}                    
                </div>

            </div>
        );
    }
}

export default withTranslation()(DelegatesList);