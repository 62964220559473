import React from 'react';

function NetworkingVideoWall(props) {
    return (
        <div className="networking-content-wrapper">
            <div className="networking-social-media-video-container">

                <div className="networking-social-media-video-frame-area-container">

                    <div className="networking-video-image-entry">

                        {props.eventId == "1" ?
                            <a target="_blank" href="http://www.portdebarcelona.cat/en/">
                                <img src="https://www.emma.events/static/virtualvenue/portdebarcelona2021/networking/networking-video-left.jpg" />
                            </a>
                            :
                            <a target="_blank" href="https://www.profesionalessanitarios.novartis.es/areas-terapeuticas/respiratorio">
                                <img src="https://www.emma.events/static/virtualvenue/separ2020/networking-lounge/networking-video-left.jpg" />
                            </a>
                        }
                    </div>

                    <div className="networking-video-video-entry">

                        {props.eventId == "1" ?
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bGoPHK5-qY8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                            <iframe src="https://player.vimeo.com/video/478347275" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                        }
                    </div>

                    <div className="networking-video-image-entry">

                        {props.eventId == "1" ?
                            <a target="_blank" href="http://www.portdebarcelona.cat/en/">
                                <img src="https://www.emma.events/static/virtualvenue/portdebarcelona2021/networking/networking-video-right.jpg" />
                            </a>
                            :
                            <a target="_blank" href="https://www.profesionalessanitarios.novartis.es/areas-terapeuticas/respiratorio">
                                <img src="https://www.emma.events/static/virtualvenue/separ2020/networking-lounge/networking-video-right.jpg" />
                            </a>
                        }
                    </div>

                </div>

                <div className="networking-company-logo-container">
                    {props.eventId == "1" ?
                        null
                        :
                        <img src="https://www.emma.events/static/virtualvenue/separ2020/networking-lounge/logo-novartis-white-networking.png" />
                    }
                </div>


            </div>
        </div>
    );
}

export default NetworkingVideoWall;