import React from 'react';
import ReactHtmlParser from 'react-html-parser';

function MyCongressInfoEntry(props) {
    
    const newEntry = props.new ? <span className="span-circle"></span> : null;
    
    return (
        <React.Fragment>
        <div className="info-wrapper">
            <div className="circle-info"> {newEntry} </div>
            <div className="info-text notification-highlight">
                <strong>{props.title}</strong><br />
                <span className="sub-text">{ReactHtmlParser(props.text)}</span>
            </div>
            <div className="info-text-time">{props.time} ></div>
        </div>
        <div className="info-separator"></div>
        </React.Fragment>
    );
}
export default MyCongressInfoEntry;
