import React, { Component } from 'react';

class Message extends Component {

    state = {
        expanded:false,
    }

    onExpandHandler = (id) =>{        
        this.setState((prevState, props) => {
            return {                
                expanded: !prevState.expanded
                };
        })    
        if (this.props.new){
            setTimeout(() => {
                this.props.onRead(id);    
            }, 500);
        }
    }
    

    render() {

        const classContainer = this.state.expanded ? "stand-detail-messages-entry-container expanded" : "stand-detail-messages-entry-container";
        const newIcon = this.props.new ? <div className="stand-detail-messages-new-circle-container"></div> : null;
        const photo =  this.props.photo !== undefined && this.props.photo !== "" ? this.props.photo : "/img/default-login-pic.png"
        const msgTime = this.props.time.substring(0, 16).replace("T",' ')

        return (
            <div className={classContainer} onClick={() => this.onExpandHandler(this.props.id)}>
                <div className="stand-detail-messages-entry-wrapper">
                    {/* <div className="stand-detail-messages-time">{this.props.time}</div> */}

                    <div className="stand-detail-messages-photo-container">
                        {newIcon}
                        <div className="stand-detail-messages-photo">                       
                            <img alt="" src={photo} />
                        </div>
                    </div>
            
                    <div className="stand-detail-messages-name-container">
                        <strong>{this.props.name} </strong><br />
                        {this.props.email}<br />
                        {msgTime}<br /><br />
                        {this.props.title}
                        <p>{this.props.message}</p>
                    </div>

                    <div className="stand-detail-messages-contact-bottom-container">
                        <div className="stand-detail-messages-expand-icon-container"></div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default Message;