import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MyOpenTok from '.././OpenTok/OpenTok';
import configData from './../../config.json';


class AssistantVideo extends Component {
    
    state = {
        apiKey:"",
        sessionId:"",
        token:""
    }

    componentDidMount(){
        
        fetch(configData.VIDEO_API + this.props.room)
        .then(data => data.json())
        .then((credentials) => {
            this.setState({
                apiKey:credentials.apiKey,
                sessionId:credentials.sessionId,
                token:credentials.token
            })
        })
        .catch((err) => {
            console.error('Failed to get session credentials', err);
            this.setState({error:'Failed to get opentok sessionId and token. Make sure you have updated the config.js file.'});
        });    
    } 

    render (){

        const openTokenRender = this.state.sessionId !== undefined && this.state.sessionId !== ""
            ? <MyOpenTok
                    apiKey={this.state.apiKey}
                    sessionId={this.state.sessionId}
                    token={this.state.token}
                    video={this.props.video}
                    />
            : null

        const closeBtn = this.props.vide ? <img alt="" src="/img/icon-close-video-call.png" /> : <img alt="" src="/img//img/icon-close-phone-call.png" />

        return (
            <React.Fragment>
            <div className="virtual-assistant-call-footer">
                {/* <div className="virtual-assistant-call-mute cursor-only">
                    <img alt="" src="/img/icon-mute-video.png" />
                </div>
                <div className="virtual-assistant-call-change-type cursor-only">
                    <img alt="" src="/img/icon-mute-sound.png" />
                </div> */}
                <div className="virtual-assistant-call-video-close cursor-only" onClick={this.props.close}>
                    {closeBtn}
                </div>
            </div>

            <div className="virtual-assistant-chat-header">
                <div className="virtual-assistant-icon-network">
                    <img alt="" src="/img/icon-chat-network.png" />
                </div>
                {/* <div className="virtual-assistant-sponsored-text">Sponsored by</div> */}
                {/* <div className="virtual-assistant-company-logo">
                    <img alt="" src="/img/chat-company-logo.png" />
                </div> */}
                <div className="virtual-assistant-close-icon cursor-only" onClick={this.props.close}>
                    <img alt="" src="/img/icon-chat-close.png" />
                </div>
            </div>

            <div className="virtual-assistant-video-call-video-assitant">
                {/* <img alt="" src="/img/assistant-video-feed-preview.png" /> */}
                {openTokenRender}
            </div>

            <div className="virtual-assistant-video-call-video-person">               
                {/* <img alt="" src="/img/person-video-feed-preview.png" /> */}                
            </div>
            </React.Fragment>
        );
    }
}

/* function renderOpenTok(credentials) {
    const { apiKey, sessionId, token } = credentials;
    ReactDOM.render(
      <MyOpenTok
        apiKey={apiKey}
        sessionId={sessionId}
        token={token}
        />,
      document.getElementById('opentok-id')
    );
  } */

export default AssistantVideo;