import React from 'react';
import {connect} from 'react-redux'

function Audiance(props) {
    return (
        <div className="right-info-wrapper">
            <div className="right-info-item">
                <div className="right-info-icon-wrapper">
                    <div className="left-wrapper-icon">
                        <img src="/img/icon-people.png" alt="" />
                    </div>
                    <div className="right-wrapper-icon-text">
                        {props.global_counter}<br />0
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return{
        global_counter: state.crt.counter,
        liveSession: state.live.sessions 
    }
}

export default connect(mapStateToProps)(Audiance);
