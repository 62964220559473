import React from 'react';

function ChatEntry(props) {
    const classPic = props.type === 'receiver'? 'chat-msg-receiver-pic' : 'chat-msg-sender-pic';
    const classText = props.type === 'receiver'? 'chat-msg-receiver-text' : 'chat-msg-sender-text';
    return (
        <React.Fragment>
            { props.pic ?
            <div className={classPic}>
                <img alt="" src={props.pic} />
            </div>
            : null
            }
            <div className={classText}>
                {props.text}
            </div>
            <div className="chat-text-separator"></div>
            <div style={{clear:`both`}}></div>
        </React.Fragment>
    );
}

export default ChatEntry;