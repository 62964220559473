import React from 'react';

function Badge(props) {
    return (
        <div className="person-badge-container">
            
            <div className="btn-close-badge-container">
                <img alt="" src="/img/btn-close-badge.png" />
            </div>
    
            <div className="person-badge-company-logo">
                <img alt="" src="/img/company-logo-badge.png" />
            </div>
    
            <div className="person-badge-area">
    
                <span className="badge-congress-title">SPANISH<br />PEDIATRICS ASSOCIATION VIRTUAL CONGRESS</span>

                <img alt="" src="/img/profile-avatar.jpg" className="img-badge-avatar" />

                <span className="badge-person-name">John McMahon</span><br />
                <span className="badge-person-desc">Lorem Ipsum Dolor Sit Amet</span><br />
    
                <div className="badge-bottom-btns">
                    <img alt="" src="/img/badge-icon-connect.png" /><br />Connect
                </div>
                <div className="badge-bottom-btns margin-btns">
                    <img alt="" src="/img/badge-icon-meeting.png" /><br />Meeting Request
                </div>
                <div className="badge-bottom-btns">
                    <img alt="" src="/img/badge-icon-message.png" /><br />Send Message
                </div>
    
            </div>
    
            <div className="person-badge-bottom">ATTENDEE</div>
    
        </div>
            
    );
}

export default Badge;