import React, { Component } from 'react';
import { connect } from 'react-redux'
import Secretariat from './Secretariat';
/* import axios from 'axios'; */
import AuthContext from '../../context/auth-context';
import { element } from 'prop-types';
import { withTranslation } from 'react-i18next';
/* import configData from '../../config.json'; */

class SecretariatList extends Component {

    state = {
        secretariat: []
    }

    static contextType = AuthContext;

    componentDidMount() {

    }

    onTxtHandler = (id) => {
        this.props.onTxt(id);
    }
    onVoiceHandler = (id) => {
        this.props.onVoice(id);
    }
    onVideoHandler = (id) => {
        this.props.onVideo(id);
    }



    render() {

        let mySecretariat = []
        let mySecretariatFilter = []

        mySecretariatFilter = this.props.secretariat.secretariat
            .filter((element) => {
                return (element.event_id === this.context.event_id)
            })

        if (mySecretariatFilter !== undefined && mySecretariatFilter !== null && mySecretariatFilter.length > 0) {
            mySecretariat = mySecretariatFilter[0].secretariat.map((el, index) => {

                if (el !== null && el !== undefined) {

                    return <Secretariat key={el.staffId}
                        id={el.staffId}
                        userId={this.context.id}
                        email={el.email}
                        name={el.name}
                        department={el.department}
                        photo={el.photo}
                        inRoom={true}
                        busy={false}
                        sendMessage={false}
                        onTxt={this.onTxtHandler}
                        onVoice={this.onVoiceHandler}
                        onVideo={this.onVideoHandler}
                        event_id={this.context.event_id}
                    />

                } else {
                    return null;
                }
            })
        }

        return (
            <div className="stand-detail-available-delegates-container">

                <div className="stand-detail-available-delegates-close-icon" onClick={this.props.closeSecretariatList}> </div>

                <div className="stand-detail-available-delegates-header-container">
                    {this.props.t('available_secretariat')} <br />
                    <span className="description">

                        {this.context.event_settings.event_id != "4" ?
                            this.props.t('available_secretariat_subtitle')
                            : null}
                    </span>
                </div>

                <div className="stand-detail-available-delegates-content-container">
                    {mySecretariat}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        secretariat: state.secretariat
    }
}

export default connect(mapStateToProps)(withTranslation()(SecretariatList));