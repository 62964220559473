import React, { Component } from 'react';
import { withRouter } from 'react-router'
import axios from 'axios';
import AuthContext from "../../../../context/auth-context";
import ReactHtmlParser from 'react-html-parser';
import Rating from '../../../../components/Rating/Rating';
import Ratings from '../Ratings/Ratings';
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';
import SendMessage from '../../../../components/SendMessage/SendMessage';

class EposterDetail extends Component {

    static contextType = AuthContext;

    state = {
        ratingOpen: false,
        avgRating: 0,
        message: false,
        email: "",
        userId: ""
    }

    componentDidMount() {

        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        // get the poster details
        axios.get(configData.SERVER_API + "/posters/" + this.props.match.params.id, data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    const avgRating = Math.round(
                        response.data[0].rating_1 * 1 +
                        response.data[0].rating_2 * 2 +
                        response.data[0].rating_3 * 3 +
                        response.data[0].rating_4 * 4 +
                        response.data[0].rating_5 * 5)
                        /
                        (response.data[0].rating_1 +
                            response.data[0].rating_2 +
                            response.data[0].rating_3 +
                            response.data[0].rating_4 +
                            response.data[0].rating_5)

                    this.setState({
                        ...response.data[0],
                        avgRating: avgRating,
                        email: response.data[0].email !== undefined ? response.data[0].email : "",
                        userId: response.data[0].user_id
                    });

                    // get poster video 


                } else {
                    console.log('unable to find poster')
                }
            })
            .catch(error => {
                console.log(error);
            })

        let EposterImageMaxWidth = 100;

        document.getElementById("zoomin").addEventListener("click", function () {
            if (EposterImageMaxWidth < 200) {
                EposterImageMaxWidth = EposterImageMaxWidth + 10;

                document.getElementById('eposterimage').style.maxWidth = EposterImageMaxWidth + '%';
            }
        });

        document.getElementById("zoomout").addEventListener("click", function () {
            if (EposterImageMaxWidth > 100) {
                EposterImageMaxWidth = EposterImageMaxWidth - 10;
                document.getElementById('eposterimage').style.maxWidth = EposterImageMaxWidth + '%';
            }
        });

        var _window = window;
        var _document = document;
        var mousemove = 'mousemove';
        var mouseup = 'mouseup';
        var mousedown = 'mousedown';
        var EventListener = 'EventListener';
        var addEventListener = 'add' + EventListener;
        var removeEventListener = 'remove' + EventListener;
        var newScrollX, newScrollY;

        var dragged = [];
        var reset = function (i, el) {
            for (i = 0; i < dragged.length;) {
                el = dragged[i++];
                el = el.container || el;
                el[removeEventListener](mousedown, el.md, 0);
                _window[removeEventListener](mouseup, el.mu, 0);
                _window[removeEventListener](mousemove, el.mm, 0);
            }

            // cloning into array since HTMLCollection is updated dynamically
            dragged = [].slice.call(_document.getElementsByClassName('eposterdrag'));
            for (i = 0; i < dragged.length;) {
                (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
                    (cont = el.container || el)[addEventListener](
                        mousedown,
                        cont.md = function (e) {
                            if (!el.hasAttribute('nochilddrag') ||
                                _document.elementFromPoint(
                                    e.pageX, e.pageY
                                ) == cont
                            ) {
                                pushed = 1;
                                lastClientX = e.clientX;
                                lastClientY = e.clientY;

                                e.preventDefault();
                            }
                        }, 0
                    );

                    _window[addEventListener](
                        mouseup, cont.mu = function () { pushed = 0; }, 0
                    );

                    _window[addEventListener](
                        mousemove,
                        cont.mm = function (e) {
                            if (pushed) {
                                (scroller = el.scroller || el).scrollLeft -=
                                    newScrollX = (-lastClientX + (lastClientX = e.clientX));
                                scroller.scrollTop -=
                                    newScrollY = (-lastClientY + (lastClientY = e.clientY));
                                if (el == _document.body) {
                                    (scroller = _document.documentElement).scrollLeft -= newScrollX;
                                    scroller.scrollTop -= newScrollY;
                                }
                            }
                        }, 0
                    );
                })(dragged[i++]);
            }
        }

        if (_document.readyState == 'complete') {
            reset();
        } else {
            _window[addEventListener]('load', reset, 0);
        }
    }

    closeHandler = () => {
        this.props.history.goBack();
    }

    onRatingsShowHideHandler = () => {
        this.setState((prevState, props) => {
            return {
                ratingOpen: !prevState.ratingOpen
            };
        })
    }

    onCloseRatingsHandler = () => {
        this.setState({ ratingOpen: false })
    }

    onSendHandler = () => {
        this.setState({ message: false });
    }

    onCloseHandler = () => {
        this.setState({ message: false });
    }

    onMessageBoxHandler = () => {
        this.setState({
            message: true
        });
    }


    render() {

        let classContainerText;
        if (this.state.video !== undefined && this.state.video !== "" && this.state.layout !== "video") {
            classContainerText = "eposter-detail-text-background-container"
        } else {
            classContainerText = "eposter-detail-text-background-container-no-video"
        }

        let posterRatings;
        posterRatings = this.state.ratingOpen ?
            <Ratings
                rating_1={this.state.rating_1}
                rating_2={this.state.rating_2}
                rating_3={this.state.rating_3}
                rating_4={this.state.rating_4}
                rating_5={this.state.rating_5}
                posterId={this.props.match.params.id}
                userId={this.context.id}
                close={this.onCloseRatingsHandler}></Ratings>
            : null;

        let EposterRatingDropClass = "";

        if (this.state.ratingOpen == true) { EposterRatingDropClass = "eposter-detail-rating-container opened"; }
        else { EposterRatingDropClass = "eposter-detail-rating-container closed"; }

        let messageBox;
        if (this.state.message) {
            messageBox = <SendMessage
                event_id={this.context.event_id}
                senderId={this.context.id}
                senderType="user"
                receiverId={this.state.userId}
                receiverEmail={this.state.email}
                receiverType="poster-author"
                objectId={this.state.pid}
                objectType="poster"
                sent={this.onSendHandler}
                close={this.onCloseHandler} >
            </SendMessage>;
        } else {
            messageBox = null;
        }


        let FullEposter;
        if (this.context.event_id == "4") {
            FullEposter = "full-eposter";
        }


        return (
            <div className="eposter-detail-top-div" style={{ backgroundColor: `#484a4a` }}>
                <div className="eposter-detail-top-bar-container" style={{ backgroundColor: `#60378c` }}>
                    <div className="eposter-detail-top-category">
                        {this.context.event_id == "4" ?
                            <span>{this.state.title}</span>
                            :
                            <span>#{this.state.pid} - {this.state.title}</span>

                        }
                    </div>


                    <div className="eposter-detail-top-rating-views-comments-close-container">

                        {this.context.event_id != "4" ?

                            <span>

                                <div className="eposter-detail-top-rating-container">
                                    <div className={EposterRatingDropClass}> {posterRatings} </div>
                                    <div onClick={this.onRatingsShowHideHandler} className="cursor-only">
                                        <Rating rate={this.state.avgRating}></Rating>
                                    </div>
                                </div>



                                <div className="eposter-detail-top-views-comments-container">
                                    <img alt="" src="/img/eposter-detail-views.png" />&nbsp;{this.state.views_total}
                                </div>

                                <div className="eposter-detail-top-views-comments-container">
                                    <img alt="" src="/img/eposter-detail-comments.png" />&nbsp;{this.state.comments_total}
                                </div>

                            </span>

                            : null}


                        <div className="eposter-detail-top-close-btn-container" onClick={this.closeHandler}>
                            <div className="eposter-detail-top-close-btn">
                                {this.props.t('poster_detail_close')}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="eposter-detail-controls-container">

                    {this.state.layout !== "video" ?
                        <React.Fragment>
                            <div className="eposter-detail-control-wrapper" id="zoomin">
                                <img alt="" src="/img/eposter-detail-control-zoom-in-icon.png" />
                            </div>
                            <div className="eposter-detail-control-wrapper" id="zoomout">
                                <img alt="" src="/img/eposter-detail-control-zoom-out-icon.png" />
                            </div>
                        </React.Fragment>
                        : null}
                </div>

                <div className={"eposter-detail-left-content-container " + FullEposter}>

                    {this.state.layout !== "video" ?
                        <div className="eposter-viewer-container">
                            <div id="eposter-viewer" className="eposter-viewer-wrapper eposterdrag">
                                <img alt="" src={this.state.poster} id="eposterimage" />
                            </div>
                        </div>
                        :
                        <div className="eposter-viewer-container">
                            <iframe width="100%" height="100%" src={this.state.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>}


                    {this.context.event_id === "326" ?
                        <div className="eposter-detail-sponsor-container">
                            <div className="eposter-detail-top-company-text">
                                Espacio patrocinado por &nbsp;
                                <img alt="" src="/img/CryoLife-Logo-Horizontal-Blue-300dpi.jpg" />
                            </div>
                        </div>
                        : this.context.event_id === "314" && (this.state.category_id === "1285" || this.state.category_id === "1289" || this.state.category_id === "1280" || this.state.category_id === "1300") ?
                            <div className="eposter-detail-sponsor-container">
                                <div className="eposter-detail-top-company-text">
                                    Con la colaboración de &nbsp;

                                    {this.state.category_id === "1285" ?
                                        <img alt="" src="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/janssen-logo-industria.png" />
                                        : this.state.category_id === "1289" ?
                                            <img alt="" src="https://www.emma.events/static/virtualvenue/separ2020/mybag/roche-logo-sponsor.png" />
                                            : this.state.category_id === "1280" ?
                                                <img alt="" src="https://www.emma.events/static/virtualvenue/separ2020/eposters/olympus-poster-sponsor.jpg" />
                                                : this.state.category_id === "1300" ?
                                                    <img alt="" src="https://www.emma.events/static/virtualvenue/separ2020/eposters/vitalaire2-poster-sponsor.jpg" />
                                                    : null}


                                </div>
                            </div>
                            : null}

                </div>

                <div className={"eposter-detail-right-content-container " + FullEposter}>

                    {this.state.video !== undefined && this.state.video !== "" && this.state.layout !== "video" ?
                        <div className="eposter-detail-video-container">
                            <iframe width="100%" height="100%" src={this.state.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        : null}

                    <div className={classContainerText}>

                        {/* <div id="author-detail" className="eposter-author-details-container">

                            <div className="eposter-author-detail-send-message-btn-container">

                                <div className="eposter-author-detail-send-message-btn-wrapper">
                                    <img alt="" src="/img/author-detail-send-msg-icon.png" /> Send Message
                                </div>

                            </div>

                            <div className="eposter-author-detail-avatar">
                                <img alt="" src="/img/chat-person-pic2.png" />
                            </div>

                            <span className="author-name">C Surribas Murillo</span><br />
                            <span className="author-institution">Hospital Central de Chicago Lateral</span>

                            <div className="author-text">
                                // Sed lobortis porttitor turpis vel  ultricies.<br />
                                // Sed lobortis porttitor turpis vel  ultricies.
                            </div>

                            

                        </div> */}


                        {this.context.event_id != "4" ?
                            <div className="eposter-detail-btns-container">

                                <div className="eposter-detail-text-btns-container" onClick={this.onMessageBoxHandler}>
                                    <img alt="" src="/img/eposter-detailt-btns-message-icon.png" />&nbsp;&nbsp;{this.props.t('poster_detail_sendmessage')}
                                </div>



                                {/* <div className="eposter-detail-text-btns-container">
                                <img alt="" src="/img/eposter-detailt-btns-chat-icon.png" />&nbsp;&nbsp;Abrir chat
                            </div>
                            <div className="eposter-detail-text-btns-separator"></div> */}
                                {this.state.type != "guest-society" && this.state.type != "oral-papers" && this.state.type != "videotecnica" && this.state.prevent_download != true ?

                                    <div>
                                        <div className="eposter-detail-text-btns-separator"></div>

                                        <div className="eposter-detail-text-btns-container">
                                            <a className="a-no-style" href={this.state.poster} target="_blank" download><img alt="" src="/img/eposter-detailt-btns-download-icon.png" />&nbsp;&nbsp;

                                                {this.context.event_id == "408" ?
                                                    <span>View full size poster</span>
                                                    :
                                                    <span>{this.props.t('poster_detail_download')}</span>
                                                }

                                            </a>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div style={{ clear: `both` }}></div>

                            </div>
                            : null

                        }


                        <div className="eposter-detail-text-wrapper">

                            {this.state.type != "guest-society" ?

                                <div className="eposter-detail-text-tab selected">
                                    {this.props.t('poster_detail_abstract')}
                                </div>
                                :
                                null
                            }

                            <div style={{ clear: `both` }}></div>

                            <div className="eposter-detail-text-container">

                                <span className={'eposter-detail-text-title ' + this.state.type}>
                                    {this.state.title}
                                </span>

                                <br /><br />

                                <div id="author1" className="eposter-detail-text-author">
                                    {this.state.authors}
                                </div>
                                {/* <div id="author2" className="eposter-detail-text-author">
                                    <div className="eposter-detail-text-author-availability on"></div>
                                    Jimenez de Olivera Sanchez
                                </div>
                                <div id="author3" className="eposter-detail-text-author">                             
                                    <div className="eposter-detail-text-author-availability"></div>
                                    T Basso Angel
                                </div>
                                <div id="author4" className="eposter-detail-text-author">                             
                                    <div className="eposter-detail-text-author-availability on"></div>
                                    M Sánchez Olivera
                                </div>
                                <div id="author5" className="eposter-detail-text-author">
                                    <div className="eposter-detail-text-author-availability on"></div>
                                    MC López Martinez
                                </div>
                                <div id="author6" className="eposter-detail-text-author">
                                    <div className="eposter-detail-text-author-availability"></div>
                                    MC López Martinez
                                </div>
 */}
                                <div style={{ clear: `both` }}></div>

                                <br />
                                <React.Fragment>
                                    {ReactHtmlParser(this.state.abstract)}
                                </React.Fragment>

                            </div>

                        </div>

                    </div>

                </div>

                {messageBox}
            </div>


        );
    }
}

export default withTranslation()(withRouter(EposterDetail));