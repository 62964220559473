import React from 'react';

function MyBagCertificate(props) {

    let fileURL = "";

    switch (props.eventid) {
        case "289":
            fileURL = "https://www.emma.events/static/virtualvenue/seip2021/certificates/" + props.file + '.pdf'
            break;
        case "314":
            fileURL = "https://www.emma.events/static/virtualvenue/separ2020/certificates/" + props.file + '.pdf'
            break;
        case "315":
            fileURL = "https://www.emma.events/static/virtualvenue/vpracticvm/certificates/" + props.file + '.pdf'
            break;
        case "342":
            fileURL = "https://www.emma.events/static/virtualvenue/piediabetico2021/certificates/" + props.file + '.pdf'
            break;
        case "347":
            fileURL = "https://www.emma.events/static/virtualvenue/gemav2020/certificates/" + props.file + '.pdf'
            break;
        case "353":
            fileURL = "https://www.emma.events/static/virtualvenue/seaic2020/certificates/" + props.file + '.pdf'
            break;
        case "367":
            fileURL = "https://www.emma.events/static/virtualvenue/cnp2020/certificates/" + props.file + '.pdf'
            break;
        case "363":
            fileURL = "https://www.emma.events/static/virtualvenue/lahrs2020/certificates/" + props.file + '.pdf'
            break;
        case "366":
            if (props.type === 'Asistencia') {
                fileURL = "https://certificates.emma.events/?aea2020"
            }
            else {
                fileURL = "https://www.emma.events/static/virtualvenue/aea2020/certificates/" + props.file + '.pdf'
            }
            break;
        case "369":
            fileURL = "https://www.emma.events/static/virtualvenue/cmm2020/certificates/" + props.file + '.pdf'
            break;
        case "376":
            fileURL = "https://www.emma.events/static/virtualvenue/semnim2021/certificates/" + props.file + '.pdf'
            break;
        case "391":
            fileURL = "https://www.emma.events/static/virtualvenue/seavirtual2021/certificates/" + props.file + '.pdf'
            break;
        case "397":
            fileURL = "https://www.emma.events/static/virtualvenue/isin2021/certificates/" + props.file + '.pdf'
            break;
        case "320":
            fileURL = "https://www.emma.events/static/virtualvenue/cuenca2021/certificates/" + props.file + '.pdf'
            break;
        case "379":
            fileURL = "https://www.emma.events/static/virtualvenue/neumomadrid2021/certificates/" + props.file + '.pdf'
            break;
        case "420":
            fileURL = "https://www.emma.events/static/virtualvenue/cnp2021/certificates/" + props.file + '.pdf'
            break;
        case "336":
            fileURL = "https://www.emma.events/static/virtualvenue/sefc2022/certificates/" + props.file + '.pdf'
            break;
        default:
            fileURL = null;
            break;
    }


    let certificate_code = props.code !== undefined && props.code !== ""
        ? <span className="td-download-code"><br />Código: {props.code}</span>
        : null


    return (
        <tr>
            <td className="td-preview"><img alt="" src="/img/preview-certificate-pdf.png" /></td>
            <td className="td-title">{props.title}</td>
            <td className="td-authors">{props.authors}</td>
            <td className="td-date-category">{props.date}</td>
            <td className="td-download"><a href={fileURL} target='_blank'>Descargar</a>{certificate_code}</td>
        </tr>
    );
}

export default MyBagCertificate;