import React, { Component } from 'react';
import Days from './Days';
import Badge from '../../Badge/Badge';
import Room from './Room';
import RoomDetailed from './RoomDetailed';

class LobbyScientificDetails extends Component {
    
    state = {
        rooms:[
            {id:1, name:"Sala Auditorio compostela"},
            {id:2, name:"Sala Auditorio Obradoiro"},
            {id:3, name:"Sala Lisboa"},
            {id:4, name:"Paris City Room"},
            {id:5, name:"The Hidden Room"},
        ],
        center: 0,
        badgeOn: false
    }

    nextHandler = () => {        
        this.setState((prevState, props) => {
            return {center: prevState.center + 1 <= prevState.rooms.length - 1 ?  prevState.center + 1 : 0};            
        })
    }

    previousHandler = () => {
        this.setState((prevState, props) => {
            return {center: prevState.center -1 < 0 ?  prevState.rooms.length - 1: prevState.center - 1};
        })
    }

    render() {        
        
        const div = document.getElementById('root');
        const thisBackgroundImage = 'url("/img/lobby-days-sessions-background.jpg")';
        div.style.backgroundImage = thisBackgroundImage;

        const size = this.state.rooms.length;
        const prev = this.state.center - 1 >= 0 ?  this.state.center - 1 : size - 1
        const next = this.state.center + 1 <= size - 1 ? this.state.center + 1 : 0
        const backDropBadge = this.state.badgeOn ? "backdrop-days-sessions-detail" : "";
        
        
        if (this.state.rooms.length >= 3) {
            return (
                <div className={backDropBadge}>
                    {this.state.badgeOn ? <Badge></Badge> : null}
                    <div className="days-sessions-detail-container">
                        <Days></Days> <br />            
                        <div className="room-sessions-detail-arrow-container left-arrow">
                            <img onClick={this.previousHandler} alt="" src="/img/icon-left-arrow-sessions-days.png" />
                        </div>
                        <Room name={this.state.rooms[prev].name} isPrevious={true} />
                        <RoomDetailed name={this.state.rooms[this.state.center].name}></RoomDetailed>
                        <Room name={this.state.rooms[next].name} isPrevious={false} />
                        <div className="room-sessions-detail-arrow-container right-arrow">
                            <img onClick={this.nextHandler} alt="" src="/img/icon-right-arrow-sessions-days.png" />
                        </div>            
                    </div>
                </div>
            );
        } else {
            return (
                <div className={backDropBadge}>
                    {this.state.badgeOn ? <Badge></Badge> : null}
                    <div className="days-sessions-detail-container">
                        <Days></Days> <br />            
                        <div className="room-sessions-detail-arrow-container left-arrow"></div>
                        {this.state.rooms.map((item, index) => {
                            return <RoomDetailed name={item.name}></RoomDetailed>
                        })}
                        <div className="room-sessions-detail-arrow-container right-arrow"></div>            
                    </div>
                </div>
            );
        }
    }
    
}

export default LobbyScientificDetails;