import React, { Component } from 'react';
import Days from './Days';
import Rooms from './Rooms';
import Categories from './Categories';
import Room from './Room';
import axios from "axios";
import AuthContext from '../../../context/auth-context';
import { withTranslation } from 'react-i18next';
import configData from '../../../config.json';
import RoomVPRACTICVM from './RoomVPRACTICVM';
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';

class LobbyScientific extends Component {

    state = {
        render: "auditoria",
        day: "",
        auditorium: "",
        category: "",
        days: [],
        auditoria: [],
        categories: [],
        sessions: [],
        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby-scientific', ''),
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false
    }

    static contextType = AuthContext;

    onDayChangeHandler = (day) => {
        this.context.day = day;
        this.setState({ day: day });
    }

    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    componentDidMount() {

        // check if programme is available globally in the context
        if (this.context.scientific_programme.length > 0) {

            const sessions = this.context.scientific_programme;
            const categories = [...new Set(sessions.filter(i => i.category !== "").map(item => item.category))]
            const auditoria = this.context.rooms;
            const days = this.context.days;
            const render = auditoria === undefined || auditoria.length == 0 || auditoria.length == 1 ? "days" : "auditoria"
            const defaultAuditorium = auditoria === undefined || auditoria.length == 0 ? '' : auditoria[0];
            const defaultDay = days === undefined || days.length == 0 ? '' : days[0];

            this.setState({
                render: render,
                day: this.context.day ? this.context.day : defaultDay,
                days: days,
                auditorium: defaultAuditorium,
                auditoria: auditoria,
                categories: categories,
                sessions: sessions,
                backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby-scientific', '')
            })

        } else {

            let data = {
                headers: {
                    "x-auth-token": this.context.token,
                    "content-type": "application/json",
                },
                params: {}
            };

            axios.get(configData.SERVER_API + "/sessions/", data)
                .then(response => {

                    const sessions = response.data;
                    const categories = [...new Set(sessions.filter(i => i.category !== "").map(item => item.category))]

                    const auditoriaObj = [...new Set(sessions.filter(i => i.room !== "").map(item => { return { id: item.room_id, room: item.room, order: item.room_order } }))]
                    const auditoriaSorted = auditoriaObj.sort((a, b) => (a.order * 1 > b.order * 1) ? 1 : -1)
                    const auditoriaList = [...new Set(auditoriaSorted.filter(i => i.room !== "").map(item => item))]

                    const auditoria = auditoriaList.filter((aud, index) => {
                        const _aud = JSON.stringify(aud);
                        return index === auditoriaList.findIndex(obj => {
                            return JSON.stringify(obj) === _aud;
                        });
                    });

                    const days = [...new Set(sessions.filter(i => i.day !== "" && (i.showInProgramme == true || i.showInProgramme === undefined)).map(item => item.day))]
                    const render = auditoria === undefined || auditoria.length == 0 || auditoria.length == 1 ? "days" : "auditoria"
                    const defaultAuditorium = auditoria === undefined || auditoria.length == 0 ? '' : auditoria[0];
                    const defaultDay = days === undefined || days.length == 0 ? '' : days[0];

                    this.setState({
                        render: render,
                        day: this.context.day ? this.context.day : defaultDay,
                        days: days,
                        auditorium: defaultAuditorium,
                        auditoria: auditoria,
                        categories: categories,
                        sessions: sessions,
                        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby-scientific', '')
                    })

                    this.context.scientific_programme = sessions;
                    this.context.days = days;
                    this.context.rooms = auditoria;

                })
                .catch(error => {
                    console.log(error.message);
                    this.setState({
                        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby-scientific', '')
                    })
                })
        }
    }

    render() {

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';

        const categories = this.state.categories === undefined || this.state.categories.length == 0
            ?
            this.state.auditoria === undefined || this.state.auditoria == 0 || this.state.auditoria.length == 1
                ? null
                : <div className="select-theme-container"></div>

            : null /* <Categories categories={this.state.categories}></Categories> */

        let topNavLinks = null;
        let blocks = [];
        let sessions = null;

        switch (this.state.render) {
            case "auditoria":
                topNavLinks = this.state.days === undefined || this.state.categories.days == 0
                    ? <div className="select-days-container"></div>
                    : <Days days={this.state.days} defaultDay={this.state.day} changeDay={this.onDayChangeHandler}></Days>

                this.state.auditoria.forEach(element => {
                    sessions = this.state.sessions.filter((item) => item.room_id === element.id && item.day === this.state.day && (item.showInProgramme == true || item.showInProgramme === undefined))
                    if (sessions !== undefined && sessions.length > 0) {
                        blocks.push(<Room eventid={this.context.event_settings.event_id} key={element.id} name={element.room} sessions={sessions} ></Room>);
                    }
                });

                break;
            case "days":
                topNavLinks = this.state.auditoria === undefined || this.state.auditoria == 0 || this.state.auditoria.length == 1    // apenas um, nao vale a pena meter o nome do auditorium
                    ?
                    this.state.categories === undefined || this.state.categories.length == 0
                        ? null
                        : <div className="select-days-container"></div>

                    : <Rooms days={this.state.auditoria} defaultDay={this.state.auditorium}></Rooms>



                if (this.context.event_settings.event_id == "315") {

                    blocks.push(<RoomVPRACTICVM></RoomVPRACTICVM>);
                }
                else {

                    this.state.days.forEach(element => {
                        sessions = this.state.sessions.filter((item) => item.day === element)
                        blocks.push(<Room eventid={this.context.event_settings.event_id} key={element} name={element} sessions={sessions}></Room>);

                    });

                }



                break;
        }



        if (this.context.event_settings.event_id == "3") {

            return (

                <React.Fragment>

                    {CookiesPolicyPopUp}
                    {LegalNoticePopUp}
                    {TermsConditionsPopUp}
                    {PrivacyPolicyPopUp}
                    <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>

                    <div className="days-sessions-container rooms3">

                        {categories}
                        {topNavLinks}

                        <div style={{ clear: `both` }}></div>

                        <div className="room-sessions-container">

                            {blocks}
                        </div>

                        <div className="slide-bar rooms3"></div>

                        <div className="slide-bar-btn rooms3">
                            <div className="slide-bar-btn-container">
                                {/* <img alt="" src="/img/slide-bar-btn-arrow-up.png" /> */}
                                <br />
                                {this.state.render === "auditoria" ? this.props.t('meeting_rooms') : this.props.t('days')}
                            </div>
                        </div>



                    </div>
                </React.Fragment>

            )

        } else {


            return (



                <div className="days-sessions-container rooms3">
                    {this.context.event_settings.event_id == "405" ?
                        <p className="days-sessions-container-info">Schedule times are in Central Standard Time (CST)</p>
                        : null
                    }

                    {categories}
                    {topNavLinks}

                    <div style={{ clear: `both` }}></div>

                    <div className="room-sessions-container">

                        {blocks}
                    </div>

                    <div className="slide-bar rooms3"></div>

                    <div className="slide-bar-btn rooms3">
                        <div className="slide-bar-btn-container">
                            {/* <img alt="" src="/img/slide-bar-btn-arrow-up.png" /> */}
                            <br />
                            {this.state.render === "auditoria" ? this.props.t('meeting_rooms') : this.props.t('days')}
                        </div>
                    </div>



                </div>
            );
        }

    }

}

//export default withTranslation()(LobbyScientific);
export default withTranslation()(LobbyScientific);