import React, { Component } from 'react';
import AuthContext from '../../context/auth-context';
import axios from "axios";
import configData from '../../config.json';
import { CSVLink } from "react-csv";

class Admin extends Component {
    
    state = {
        login:[],
        nologin:[]
    }

    static contextType = AuthContext;
    
    componentDidMount(){
        if (this.context.login(this.context.token) || this.context.autoLogin()){
            this.loadLogin();
            this.loadNoLogin();
        }
    }

    loadLogin = () => {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };
        axios.get(configData.SERVER_API + "/logs/login/user", data)
            .then(response => {                
                console.log(response)
                this.setState({ login: response.data })                
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    loadNoLogin = () => {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };
        axios.get(configData.SERVER_API + "/logs/nlogin", data)
            .then(response => {                
                this.setState({ nologin: response.data })
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    render() {

        const contentLogin = this.state.login !== undefined && this.state.login.length > 0 
            ? <CSVLink data={this.state.login} filename={"listado-accesos.csv"}>Descargar login</CSVLink>
            : "LOGIN - NO DATA"
        const contentNoLogin = this.state.nologin !== undefined && this.state.login.length > 0 
            ? <CSVLink data={this.state.nologin} filename={"listado-sin-acceder.csv"}>Descargar No login</CSVLink>
            : "NO LOGIN - NO DATA"
        
        return (
            <React.Fragment>
                <div> <p>{contentLogin}</p></div>
                <div> <p>{contentNoLogin}</p></div>
            </React.Fragment>
        );

    }
}

export default Admin;

