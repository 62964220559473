import React from 'react';
import Chat from './Chat';

function Chats(props) {
    return (
        <div className="active-chats-container">
            <Chat title="The" subtitle="Frankensteins" isClosed={false}></Chat>
            <Chat title="The" subtitle="Frankensteins" isClosed={true}></Chat>
        </div>
    );
}

export default Chats;