import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logPathname } from '../../store/actions/global';
import TopBar from './TopBar/TopBar';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Lobby from './Lobby/Lobby'
import LobbyScientific from './LobbyScientific/LobbyScientific'
import Industria from './Industria/Industria'
import IndustriaStand from './IndustriaStand/IndustriaStand'
import Networking from './Networking/Networking'
import NetworkingJuniorRoom from './NetworkingJuniorRoom/Networking'
import NetworkingEstsHouse from './NetworkingEstsHouse/Networking'
import Assistant from '../../containers/Assistant/Assistant'
import Navigation from './Navigation/Navigation'
import ChatContainer from './Chat/ChatContainer';
import LobbyScientificDetails from './LobbyScientificDetails/LobbyScientificDetails';
import Eposters from './Eposters/Eposters';
import AuthContext from '../../context/auth-context';
import VideoPills from './VideoPills/VideoPills';
import ProgrammeHtml from './ProgrammeHtml/ProgrammeHtml';
import SpeakersHtml from './SpeakersHtml/SpeakersHtml';
import SponsorsHtml from './SponsorsHtml/SponsorsHtml';
import PreviousEditionsHtml from './PreviousEditionsHtml/PreviousEditionsHtml';
import SocietiesHtml from './SocietiesHtml/SocietiesHtml';
import NetworkingCuenca2021 from './NetworkingCuenca2021/NetworkingCuenca2021';


class Congress extends Component {

    static contextType = AuthContext;

    componentDidMount() {
        this.onRouteChanged(this.props.location);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged(this.props.location);
        }
    }

    onRouteChanged(location) {
        this.props.onPathname({
            type: "ENTER",
            object: "CONGRESS",
            pathname: window.location.pathname
        });
    }

    render() {

        const pagein = window.location.href;
        const renderVirtualAssistant = !pagein.includes('industria-stand');

        {
            this.context.event_settings.event_id == "4" ?
                document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby.jpg")'
                :
                document.getElementById('root').style.backgroundImage = 'url("/img/eposter-background.jpg")'
        }



        const virtualAssistant = this.context.event_settings.virtual_secretariat && renderVirtualAssistant ?
            <div>
                <Assistant></Assistant>
            </div>
            : null;

        return this.context.login(this.context.token) || this.context.autoLogin() ? (
            <React.Fragment>
                <TopBar></TopBar>
                <Switch>
                    <Route path={this.props.match.url + '/lobby'} exact component={Lobby} />
                    <Route path={this.props.match.url + '/lobby-scientific'} exact component={LobbyScientific} />
                    <Route path={this.props.match.url + '/lobby-scientific-detail'} exact component={LobbyScientificDetails} />
                    {this.context.event_settings.event_id == "263" ?
                        <div>
                            <Route path={this.props.match.url + '/e-posters/'} render={(props) => <Eposters type="e-poster" {...props} />} />
                            <Route path={this.props.match.url + '/oral-papers/'} render={(props) => <Eposters type="oral-papers" {...props} />} />
                            <Route path={this.props.match.url + '/case-report/'} render={(props) => <Eposters type="case-report" {...props} />} />
                            <Route path={this.props.match.url + '/guest-societies/'} render={(props) => <Eposters type="guest-society" {...props} />} />
                        </div>

                        : this.context.event_settings.event_id == "351" ?
                            <div>
                                <Route path={this.props.match.url + '/e-posters/'} render={(props) => <Eposters type="eposter" {...props} />} />
                                <Route path={this.props.match.url + '/snapshots/'} render={(props) => <Eposters type="snapshot" {...props} />} />
                            </div>



                            :
                            <Route path={this.props.match.url + '/e-posters/'} render={(props) => <Eposters  {...props} />} />
                    }
                    <Route path={this.props.match.url + '/e-posters-serod/'} render={(props) => <Eposters type="eposter" {...props} />} />
                    <Route path={this.props.match.url + '/videotecnicas/'} render={(props) => <Eposters type="videotecnica" {...props} />} />
                    <Route path={this.props.match.url + '/industria'} exact component={Industria} />
                    <Route path={this.props.match.url + '/industria-stand/:name'} exact component={IndustriaStand} />
                    <Route path={this.props.match.url + '/networking'} exact component={Networking} />
                    <Route path={this.props.match.url + '/junior-room'} exact component={NetworkingJuniorRoom} />
                    <Route path={this.props.match.url + '/ests-house'} exact component={NetworkingEstsHouse} />
                    <Route path={this.props.match.url + '/video-pills'} exact component={VideoPills} />
                    <Route path={this.props.match.url + '/programme'} exact component={ProgrammeHtml} />
                    <Route path={this.props.match.url + '/networking-cuenca'} exact component={NetworkingCuenca2021} />
                    <Route path={this.props.match.url + '/speakers'} exact component={SpeakersHtml} />
                    <Route path={this.props.match.url + '/sponsors'} exact component={SponsorsHtml} />
                    <Route path={this.props.match.url + '/societies'} exact component={SocietiesHtml} />
                    <Route path={this.props.match.url + '/previous-editions'} exact component={PreviousEditionsHtml} />
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
                {/* <Navigation></Navigation> */}
                {/* <ChatContainer></ChatContainer> */}
                {virtualAssistant}
            </React.Fragment>
        ) : (<Redirect to={{ pathname: '/' }} />
        );
    }
}

function NoMatch() {
    return (
        <Redirect to="/congress/lobby" />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onPathname: (data) => dispatch(logPathname(data))
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Congress));