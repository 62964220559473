
// live
export const LIVE = 'LIVE';

// demo - not in use
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

// global
export const ADD_SOCKET = 'ADD_SOCKET';
export const REMOVE_SOCKET = 'REMOVE_SOCKET';
export const LOGIN = 'LOGIN';       // not in use
export const LOGOUT = 'LOGOUT';     // not in use

export const EVENT_QTY = 'EVENT_QTY';
export const WATCH = 'WATCH';
export const LOCAL_QTY = 'LOCAL_QTY';
export const LOCAL_ASSISTANTS = 'LOCAL_ASSISTANTS';

export const FRIENDS = 'FRIENDS';
export const EMIT_CONTACT_REQUEST = 'EMIT_CONTACT_REQUEST';
export const EMIT_CHAT_MESSAGE_POST = 'EMIT_CHAT_MESSAGE_POST';

// secretariat
export const SECRETARIAT_UPDATE = 'SECRETARIAT_UPDATE';
export const SECRETARIAT_CONTACT_REQUEST_START = 'SECRETARIAT_CONTACT_REQUEST_START';
export const SECRETARIAT_CONTACT_REQUEST_RECEIVE = 'SECRETARIAT_CONTACT_REQUEST_RECEIVE';
export const SECRETARIAT_CONTACT_CLOSE = 'SECRETARIAT_CONTACT_CLOSE';
export const SECRETARIAT_CONTACT_REPLY = 'SECRETARIAT_CONTACT_REPLY';
export const SECRETARIAT_CHAT_MESSAGE_RENDER = 'SECRETARIAT_CHAT_MESSAGE_RENDER';
export const SECRETARIAT_CHAT_MESSAGE_POST = 'SECRETARIAT_CHAT_MESSAGE_POST';