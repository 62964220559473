import React from 'react';
import { useTranslation } from 'react-i18next';

function MyBagInsert(props) {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="tab-insert-content-wrapper">
                <a href={props.file} className="a-no-style" target="_blank">
                    <div className="flyer-container">
                        <img alt="" src={props.flyer} />
                    </div>
                    <div className="flyer-text-container">
                        <div className="flyer-company-logo-container">
                            <img alt="" src={props.logo} />
                        </div>
                        <div className="flyer-text-wrapper">
                            <strong>{props.title}</strong><br />
                            {props.desc}

                            {props.fichatecnica !== undefined ?
                                <p><a target="_blank" href={props.fichatecnica}>Ficha Técnica</a></p>
                                :
                                null
                            }

                        </div>
                    </div>
                    <div className="flyer-download-container">
                        {t('download')}
                    </div>
                </a>
            </div>
            <div className="tab-insert-content-wrapper-separator"></div>
        </div>
    );
}

export default MyBagInsert;