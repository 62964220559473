import React, { Component } from 'react';
import { connect } from 'react-redux'
import { connectSocket, disconnectSocket } from '../../../../store/actions/global';
import { withRouter } from 'react-router-dom';
import AuthContext from '../../../../context/auth-context'

class Search extends Component {

    static contextType = AuthContext;

    logoutHandler = () => {

        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.email = '';
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

        // close of the socket

    }

    render() {

        if (this.props.forceLogout) {
            this.logoutHandler();
        }

        let SponsorLogosRotator = "";

        if (this.context.event_id == "314") {
            SponsorLogosRotator = <img src="https://www.emma.events/static/virtualvenue/separ2020/myprofile/separ2020-sponsor-boehringer-navbar.jpg" alt="" />;
        }
        else if (this.context.event_id == "372" || this.context.event_id == "373") {
            SponsorLogosRotator = <img src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/lobby/gsk-logo-top-1reunionvirtualareas2021.jpg" alt="" />;
        }
        else if (this.context.event_id == "424") {
            SponsorLogosRotator = <img className="sponsorlogosrotator 3reunionvirtualareas2022" src="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/lobby/sanofi-logo-top-3reunionvirtualareas2022-final.jpg?ver=6" alt="" />;
        }


        return (
            <div className="search-logout-wrapper">
                <div className="logout-wrapper">
                    <img onClick={this.logoutHandler} alt="" src="/img/icon-logout.png" /><br />
                    <span onClick={this.logoutHandler}>LOGOUT</span>
                </div>
                <div className="search-input-wrapper">
                    {/* <div className="search-btn">
                        <img alt="" src="/img/search-btn-input.png" />
                    </div> */}
                    {/* <div className="search-input">
                        <input type="text" id="search" name="search" placeholder="Search..." />
                    </div> */}
                    {SponsorLogosRotator}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        forceLogout: state.global.forceLogout
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));