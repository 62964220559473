
import * as actionTypes from '../actions/actionTypes'
import { updateObject} from '../utility'

const initialState = {
    secretariat:[],
    chatType:"txt",
    chatActive: false,
    chatRoom: "",
    chatMessages: [],
    chatRecipientId:"",
    contactRequestActive: false,
    contactRequest:{}
}

// reducer the place to change the state of the store
// heavy logic goes here in the reducer

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.SECRETARIAT_UPDATE:
            
            const event_id = action.availableSecretariat.event_id
            
            const newSecretariat = state.secretariat.filter((el) =>{
                return (el.event_id !== event_id)
            })
            newSecretariat.push(action.availableSecretariat);

            return {
                ...state,
                secretariat: newSecretariat};
                break;

        case actionTypes.SECRETARIAT_CONTACT_REQUEST_START:
            return {
                ...state,
                chatActive: action.contact.chatActive,
                chatType: action.contact.chatType,
                chatRoom: action.contact.chatRoom,
                chatRecipientId: action.contact.chatRecipientId
            };
            break;

        case actionTypes.SECRETARIAT_CONTACT_REQUEST_RECEIVE:
            if (state.chatActive && state.chatRoom !== null && state.chatRoom !== ""){
                return state
            } else {
                return {
                    ...state,
                    chatType: action.contact.type,
                    contactRequest: action.contact,
                    contactRequestActive: true,
                    chatRoom: action.contact.roomAddress
                };
            }
            break;


        case actionTypes.SECRETARIAT_CONTACT_CLOSE:
            return {
                ...state,
                chatRoom: action.contact.chatRoom,
                chatActive: action.contact.chatActive
            };
            break;


        case actionTypes.SECRETARIAT_CONTACT_REPLY:
            let newContact
            if (action.reply.type === 'accept'){
                newContact = {
                    ...state,
                    contactRequestActive: action.reply.contactRequestActive,
                    contactRequest: action.reply.contactRequest,
                    chatActive: action.reply.chatActive,
                    chatMessages: action.reply.chatMessages
                }
            }
            if (action.reply.type === 'decline'){
                newContact = {
                    ...state,
                    contactRequestActive: action.reply.contactRequestActive,
                    contactRequest: action.reply.contactRequest
                }
            }
            if (action.reply.type === 'close'){
                newContact = {
                    ...state,
                    contactRequestActive: action.reply.contactRequestActive
                }
            }
            return (newContact);
            break;


        case actionTypes.SECRETARIAT_CHAT_MESSAGE_RENDER:
            if (action.message.chatId === state.chatRoom){
                let newArrayRender = state.chatMessages.slice();
                newArrayRender.splice(state.chatMessages.length, 0, action.message);    
                return {
                    ...state,
                    chatMessages:newArrayRender
                    };
                break;
            } else {
                return state;
            }
            


        case actionTypes.SECRETARIAT_CHAT_MESSAGE_POST:
            let newArrayPost = state.chatMessages.slice();
            newArrayPost.splice(state.chatMessages.length, 0, action.message);    
            return {
                ...state,
                chatMessages:newArrayPost
                };
            break;

        default:
            return state;
            break;
    }
     
} 


export default  reducer;