import React, { Component } from 'react';
import Participant from './Participant';
import AuthContext from "../../../../context/auth-context"
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class ParticipantsList extends Component {

    state = {
        open:this.props.open !== undefined ? this.props.open : false,
        online: true,
        accesses:[]
    }

    static contextType = AuthContext;

    onDisplayAll = () =>{
        this.setState({online:false});
        this.loadAccesses("init");
    }

    onDisplayOnline = () =>{
        this.setState({online:true});
    }

    onShowHideHandler = () =>{
        this.setState((prevState, props) => {
            return {                
                open: !prevState.open
                };
        })    
    }

    loadAccesses = (initial) => {

        // get the list of delegates for the company
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/stands/" + this.props.standId + "/accesses/", data)
        .then(response => {
            this.setState({
                accesses: response.data,
            })
        })
        .catch(error => {
            console.log(error.message);
        })
    }

    onTxtHandler = (id) => {
        this.props.onTxt(id);
    }
    onVoiceHandler = (id) => {
        this.props.onVoice(id);
    }
    onVideoHandler = (id) => {
        this.props.onVideo(id);
    }


    render() {

        let listOfParticipants;

        if (this.state.online){

        
            listOfParticipants = this.props.participants.map((item,index) => {
                return ( <Participant key={item.id} 
                    id={item.id}
                    email={item.email} 
                    name={item.name}                
                    photo={item.photo} 
                    institution={item.institution} 
                    online={true}
                    social={item.social} 
                    txt={item.txt} 
                    sendMessage={item.sendMessage}
                    voice={item.voice} 
                    video={item.video}
                    timeIn={item.time_in}
                    standLevel={this.props.standLevel}
                    inOut={""}
                    onTxt={this.onTxtHandler}
                    onVoice={this.onVoiceHandler}
                    onVideo={this.onVideoHandler}
                    ></Participant>)
            })

        } else {

            listOfParticipants = this.state.accesses.map((item,index) => {
                return ( <Participant key={item._id} 
                    id={item.user.id}
                    email={item.user.email} 
                    name={item.user.name}                
                    photo={item.user.photo} 
                    institution={item.user.institution} 
                    online={false}
                    social={"standard"} 
                    txt={false} 
                    sendMessage={item.sendMessage}
                    voice={false} 
                    video={false}
                    timeIn={item.user.date}
                    standLevel={this.props.standLevel}
                    inOut={item.inout}
                    onTxt={this.onTxtHandler}
                    onVoice={this.onVoiceHandler}
                    onVideo={this.onVideoHandler}
                    ></Participant>)
            })

        }
        
        const classOnline = this.state.online ? "stand-detail-participants-bar-header-tab" : "stand-detail-participants-bar-header-tab active"
        const classAll = this.state.online ? "stand-detail-participants-bar-header-tab active" : "stand-detail-participants-bar-header-tab"

        const myParticipants = this.state.open ? ( <div className="stand-detail-delegates-bar-container">
                <div className="stand-detail-delegates-bar-header">
                    <div className="stand-detail-delegates-bar-header-title-close-container">
                        <div className="stand-detail-delegates-bar-header-close-left-container" onClick={this.onShowHideHandler}>
                            <img alt="" src="/img/icon-close-login-panel.png" />
                        </div>
                        <div className="stand-detail-delegates-bar-header-title-right-container"> {this.props.t('participant_in_stand')} </div>
                    </div>
                    <div className="stand-detail-messages-bar-header-tabs-container">
                        <div className={classAll} onClick={this.onDisplayOnline}>{this.props.t('participants_online')}</div>
                        <div className={classOnline} onClick={this.onDisplayAll}>{this.props.t('participants_all')}</div>
                    </div>
                </div>

                <div className="stand-detail-delegates-left-content-container">
                    {listOfParticipants}
                </div>
            </div>)
            : null

        return (
            <React.Fragment>
                <div className="stand-detail-delegates-bar-open-info-floating-container" onClick={this.onShowHideHandler}>
                    <div className="stand-detail-delegates-bar-open-info-numbers-container">{this.props.participants.length}</div>
                    <div className="stand-detail-delegates-bar-open-info-icon-container"></div>
                </div>

                {myParticipants}

                </React.Fragment>
        );
    }
}

export default withTranslation()(ParticipantsList);