import React, { Component } from 'react';
import VotingPoll from '../VotingPoll/VotingPoll';
import configData from '../../../../config.json';
import AuthContext from '../../../../context/auth-context';
import io from "socket.io-client";

class Voting extends Component {
    
    state = {
        questions: [],
    }

    static contextType = AuthContext;

    // props: sessionId

    componentDidMount(){
        this.initSocketConnection();
        this.setupSocketListeners();
    }

    initSocketConnection() {
        
        const server = configData.SOCKET_API_VOTING + "/voting" ;
        this.socket = io.connect(server, {transports: [ 'websocket' ], query: {token: this.context.token} });
        this.socket.on('connect', () => {
            this.socket.emit('sign-in', { session_id: this.props.sessionId });
        });
        
    }

    setupSocketListeners() {
        // recebe todas as que estao guardadas
        this.socket.on("sign-in", data => {
            let questions = [];
            data.forEach(element => {
                questions.push(element);
            });
            this.setState({ questions:questions}); 
        });
        // adiciona uma mensagem nova, se ainda nao estiver

        this.socket.on("active", data => {
            if(data[0].session_id === this.props.sessionId) {
                let question = this.state.questions.filter(el => el._id === data[0]._id);  // check if the question is already there
                if (question !== undefined && question.length > 0){

                } else {
                    let questions = this.state.questions;
                    questions.push(data[0])
                    this.setState({ questions:questions});
                }
            }
        });
        // remove uma mensagem
        this.socket.on("inactive", data => {
            let questions = this.state.questions.filter(el => el._id !== data._id);
            this.setState({ questions:questions});
        });
        // substitui uma mensagem ao abrir
        this.socket.on("open", data => {
            let questions = this.state.questions.filter(el => el._id !== data[0]._id);
            questions.push(data[0])
            this.setState({ questions:questions});
        });
        // substitui uma mensagem ao fechar
        this.socket.on("close", data => {
            console.log(data)
            let questions = this.state.questions.filter(el => el._id !== data[0]._id);
            questions.push(data[0])
            this.setState({ questions:questions});
        });
        // gere a mensagem recebida da votacao
        this.socket.on("vote", data => {
            alert(data);
        });

      }

     onVoteHandler = (question_id, option_id)  => {
        const vote = {
            session_id: this.props.sessionId,
            question_id: question_id,
            option_id: option_id,
            user_id: this.context.id
        }
        this.socket.emit('vote', vote);
     }

    render() {

        const renderQuestions = this.state.questions.map((el, index) => {
            return (
                <React.Fragment key={el._id}>
                    <VotingPoll question={el} vote={this.onVoteHandler}></VotingPoll>
                    <hr/>
                </React.Fragment>
                )
        })

        return (
            <React.Fragment>
            <div className="econgress-room-accordion-header">
                <div className="econgress-room-accordion-header-icon-container">
                    <img alt="" src="/img/icon-arrow-bottom-accordion.png" />
                </div>
                Voting 
            </div>

            <div className={`econgress-room-vote-area-container ` + this.props.classBlock}>
                {renderQuestions}
            </div>
            </React.Fragment>
        );
    }
}

export default Voting;