import { func } from 'prop-types';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';


function Cookies(props) {

    const [cookies, setCookie] = useCookies(['name']);
    const [cookieIsSet, setCookieIsSet] = useState(false);  // initialize component without cookie accepted
    const [cookieChecked, setCookieChecked] = useState(false);

    function setCookieAcccepted() {
        setCookie('cookies-read.' + props.event_id, 'true', { path: '/' });
        setCookieIsSet(true);
        setCookieChecked(true);
    }

    if (cookieChecked === false) {
        // check if the cookies are authorized for this event
        for (var propName in cookies) {
            const propValue = cookies[propName]
            if (propName === 'cookies-read.' + props.event_id && propValue) {
                setCookieIsSet(true);
                setCookieChecked(true);
            }
        }
    }


    // render the content 
    const cookieOutput = !cookieIsSet ?

        <div className="cookies-info-container">


            <div className="cookies-accept-text-container">

                <p>Esta web utiliza cookies técnicas que son indispensables para navegar por la misma. Los datos de contacto del propietario de la web son accesibles en la pestaña de <a onClick={props.onLegalNoticeOpen} href="#">Aviso Legal</a>.</p>
                <p>Si desea más información acerca de las cookies, <a onClick={props.onCookiesPolicyOpen} href="#">clique aquí</a></p>

                <p>&nbsp;</p>
                <span onClick={setCookieAcccepted} className="btn-accept-cookies">ACEPTO</span>

            </div>

        </div>

        : null

    return (cookieOutput);
}

export default Cookies;