import React from 'react';

function TextEntry(props) {

    const classTet = props.entryType === "assistant" ? "person-chat-text" : "assistant-chat-text";

    return (
        <React.Fragment>
            <div className={classTet}>{props.message}</div>
            <div style={{clear:`both`}}></div> 
        </React.Fragment>
    );
}

export default TextEntry;