import React from 'react';


function StandDiv(props) {

    let divContent;

    if (props.data != undefined) {

        switch (props.data.type) {
            case "":
                if (props.data.cover.type === "img") {
                    divContent = <img alt="" src={props.data.cover.src} />
                }
                if (props.data.cover.type === "video") {
                    if (props.data.cover.format === "vimeo") {
                        divContent = <iframe width="100%" height="100%" src={props.data.cover.src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                    }
                }
                break;
            case "link":
                divContent = <img alt="" onClick={() => window.open(props.data.cover.link)} src={props.data.cover.src} />
                //divContent = <a href={props.data.cover.link} target={props.data.cover.target}><img alt="" onclick={link} src={props.data.cover.src} /></a>
                break;
            case "video-player":
                divContent = <img onClick={() => props.playVideo(props.data.content[0].src)} alt="" src={props.data.cover.src} />
                break;
            case "file-download":
                divContent = <img alt="" onClick={() => window.open(props.data.content[0].src)} src={props.data.cover.src} />
                break;
            case "video-gallery":
                divContent = <img alt="" src={props.data.cover.src} />
                break;
            case "file-gallery":
                divContent = <img alt="" src={props.data.cover.src} />
                break;
            case "pdf-gallery":
                divContent = <img onClick={() => props.loadFileGallery(props.data.content)} alt="" src={props.data.cover.src} />
                break;
            case "vote":
                divContent = <img onClick={() => props.votePopup(props.data.cover.votetitle)} alt="" src={props.data.cover.src} />
                break;
        }
    }


    return (
        <React.Fragment>{divContent}</React.Fragment>
    );
}

export default StandDiv;