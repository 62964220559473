import React, { Component } from 'react';
import MyProfile from './MyProfile/MyProfile'
import MyBag from './MyBag/MyBag'
import MyCredits from './MyCredits/MyCredits'
import MyCreditsSession from './MyCredits/MyCreditsSession'
import MySchedule from './MySchedule/MySchedule';
import AuthContext from '../../../../context/auth-context'
import { withTranslation } from 'react-i18next';

class ProfileMenu extends Component {

    static contextType = AuthContext;

    state = {
        tabProfile: this.context.event_settings.my_profile,
        tabMyBag: this.context.event_settings.my_bag,
        tabMyCredits: this.context.event_settings.my_credits,
        tabMySchedule: this.context.event_settings.my_schedule,
        tabVisible: ""
    }

    onTabHandler = (tab) => {
        if (this.state.tabVisible === tab) {
            this.setState({ tabVisible: '' })
        } else {
            this.setState({ tabVisible: tab })
        }
    }

    render() {

        const eventName = this.context.event_settings.name;

        let tabContainer = null;
        switch (this.state.tabVisible) {
            case "my_profile":
                tabContainer = <MyProfile
                    userId={this.context.id}
                    eventId={this.context.event_id}
                    name={""}
                    surname={""}
                    email={this.context.email}
                    profile={this.context.profile}
                    position={this.context.event_settings.badge_position}
                    userPhoto={""}
                    userRole={this.context.profile}
                    privacyPolicy={true}
                ></MyProfile>

                break;
            case "my_schedule":
                tabContainer = <MySchedule></MySchedule>
                break;
            case "my_bag":
                tabContainer = <MyBag eventId={this.context.event_settings.event_id}></MyBag>;
                break;
            case "my_credits":
                if (this.context.event_id === "367" || this.context.event_id === "342" || this.context.event_id === "315" || this.context.event_id === "372" || this.context.event_id === "373" || this.context.event_id === "376" || this.context.event_id === "397" || this.context.event_id === "320" || this.context.event_id === "379" || this.context.event_id === "420" || this.context.event_id === "424" || this.context.event_id === "425" || this.context.event_id === "489" || this.context.event_id === "575" || this.context.event_id === "656") {
                    tabContainer = <MyCredits eventId={this.context.event_id}></MyCredits>
                } else if (this.context.event_id === "289" || this.context.event_id === "353" || this.context.event_id === "391") {
                    tabContainer = <MyCreditsSession eventId={this.context.event_id}></MyCreditsSession>
                } else {
                    tabContainer = null;
                }
                break;
        }

        const myProfileTab = this.state.tabProfile
            ? <div className="profile-menu-item cursor-only" onClick={() => this.onTabHandler('my_profile')}>
                <img alt="" src="/img/icon-my-profile.png" /><br />
                {this.props.t('my_profile')}
                <div className="profile-menu-item-separator"></div>
            </div>
            : null

        const myScheduleTab = this.state.tabMySchedule
            ? <div className="profile-menu-item cursor-only" onClick={() => this.onTabHandler('my_schedule')}>
                <img alt="" src="/img/icon-my-schedule.png" /><br />
                MY SCHEDULE
                <div className="profile-menu-item-separator"></div>
            </div>
            : null

        const myBagTab = this.state.tabMyBag
            ? <div className="profile-menu-item cursor-only" onClick={() => this.onTabHandler('my_bag')}>
                <img alt="" src="/img/icon-my-bag.png" /><br />
                {this.props.t('my_bag')}
                <div className="profile-menu-item-separator"></div>
            </div>
            : null

        const myCreditsTab = this.state.tabMyCredits
            ? <div className="profile-menu-item cursor-only" onClick={() => this.onTabHandler('my_credits')}>
                <img alt="" src="/img/icon-my-credits.png" /><br />
                {this.props.t('my_credits')}
            </div>
            : null

        return (
            <React.Fragment>
                <div className="profile-menu-container">

                    {myProfileTab}
                    {myScheduleTab}
                    {myBagTab}
                    {myCreditsTab}

                </div>

                {tabContainer !== "" && tabContainer !== null
                    ? <div className="profile-menu-area-container">
                        <div className="congress-name-date">
                            <span className="congress-name">{eventName}</span><br />
                        </div>
                        {tabContainer}
                    </div>
                    : null}

            </React.Fragment>
        );
    }
}

export default withTranslation()(ProfileMenu);