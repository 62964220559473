import React from 'react';
import { useTranslation } from 'react-i18next';

function LegalNotice(props) {

    const { t, i18n } = useTranslation();

    return (

        props.event_id === "387" || props.event_id === "2" || props.event_id === "3" || props.event_id === "4" || props.event_id === "5" ?

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container">


                    <h3><strong>AVISO LEGAL</strong></h3>

                    <p>1.- El titular de esta página Web es la sociedad Laboratorios Farmacéuticos Rovi, S.A. (en adelante, Rovi), con domicilio social en C/ Julián Camarillo, 35, 28037, Madrid, CIF A- 28041283 e inscrita en el Registro Mercantil de Madrid, Tomo 3823, Folio 1, Sección 8, Hoja M-64245. Su teléfono de contacto es 91.375.62.30 su fax es 91.304.78.81 y su dirección de correo electrónico departamentolegal@rovi.es.</p>

                    <p>Rovi es un Laboratorio Farmacéutico autorizado con el Nº 2808E por la Agencia Española del Medicamento y Productos Sanitarios.</p>

                    <p>Puede consultar los códigos de conducta a los que está adherido Rovi en los siguientes enlaces:</p>

                    <p><a target="_blank" href="https://www.codigofarmaindustria.org/servlet/sarfi/elcodigo.html">Código de Buenas Prácticas de la Industria</a></p>

                    <p><a target="_blank" href="https://cdn2.hubspot.net/hubfs/369605/Documentos/Legales/C%C3%B3digo%20%C3%89tico%20Rovi%202018.pdf">Código ético</a></p>

                    <p>La utilización de esta página Web atribuye la condición de Usuario a quien lo haga e implica la aceptación de todas las condiciones incluidas en la Política de Privacidad, los Términos y Condiciones de Uso y este Aviso Legal.</p>

                    <p>2.- El Usuario deberá leer dichas condiciones cada vez que acceda a esta Web, ya que pueden ser modificadas en lo sucesivo. Los aspectos de esta página Web que, por su especialidad, estén sujetos a condiciones o reglas particulares que pueden sustituir, completar o modificar este Aviso Legal también deben ser aceptadas por el Usuario que los utilice o acceda a ellos.</p>

                    <p>3.- Queda prohibido alterar o modificar ninguna parte de la Web o de los contenidos de la misma, eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir, desactivar o manipular) las funciones de seguridad de la Web u otras funciones del programa y utilizar la Web o sus contenidos para un fin comercial o publicitario. Queda prohibido el uso de esta Web con la finalidad de lesionar bienes, derechos o intereses de Rovi o de terceros. Queda igualmente prohibido realizar cualquier otro uso de esta Web que altere, dañe o inutilice las redes, servidores, equipos, productos y programas informáticos de Rovi o de terceros.</p>

                    <p>4.- Todos los contenidos de esta Web (textos, fotografías, gráficos, imágenes, tecnologia, software, links, contenidos audiovisuales o sonoros, diseño gráfico, código fuente, etc.), así como las marcas y demás signos distintivos son propiedad de Rovi o de terceros, no adquiriendo el Usuario ningún derecho sobre ellos por el mero uso de esta Web. El Usuario, deberá abstenerse de: a) reproducir, copiar, distribuir, poner a disposición de terceros, comunicar públicamente, transformar o modificar los contenidos de esta Web, salvo en los casos contemplados en la ley o expresamente autorizados por Rovi o por el titular de dichos derechos; b) reproducir o copiar para uso privado el software o las bases de datos existentes en esta Web, así como comunicarlos públicamente o ponerlos a disposición de terceros cuando ello conlleve su reproducción; c) extraer o reutilizar todo o parte sustancial de los contenidos integrantes de esta Web.</p>

                    <p>5.- Rovi no es responsable de controlar que en esta Web no existan programas maliciosos o cualquier otro elemento informático dañino. Corresponde al Usuario, en todo caso, disponer de herramientas adecuadas para la detección y desinfección de estos elementos. De acuerdo con ello, Rovi no se responsabiliza de los daños producidos a equipos informáticos durante el acceso a la presente Web. Igualmente, Rovi no será responsable de los daños producidos a los Usuarios cuando dichos daños tengan su origen en fallos o desconexiones en las redes de telecomunicaciones que interrumpan el servicio de esta Web.</p>

                    <p>6.- Esta Web podría incluir dispositivos técnicos de enlace que permiten al Usuario acceder a otras páginas de Internet (enlaces). En estos casos, Rovi sólo será responsable de los contenidos y servicios suministrados a través de los enlaces en cuanto conozca su ilicitud y no los haya desactivado diligentemente. Si un Usuario considerase que existe un enlace con contenidos ilícitos o inadecuados, podrá indicárselo a Rovi, sin que ello suponga que este quede obligado por ello a retirar el correspondiente enlace. Rovi no siempre conoce los contenidos y servicios de los enlaces y por tanto no se hace responsable por los daños derivados de su falta de calidad, desactualización, indisponibilidad, error, inutilidad o ilegalidad y no responde de las manifestaciones realizadas o de los contenidos o servicios proporcionados a través de ellos, salvo que sea directamente responsable de la prestación de dichos servicios. Si cualquier usuario tiene conocimiento de que los enlaces remiten a páginas cuyos contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o inmorales podrá ponerse en contacto con Rovi indicándolo.</p>

                    <p>7.- El Usuario se compromete a hacer un uso correcto de esta Web de conformidad con la Ley, con el presente Aviso Legal, así corno con las demás condiciones, reglamentos e instrucciones que, en su caso, pudieran ser de aplicación El Usuario responderá frente a Rovi y frente a terceros, de cualesquiera daños o perjuicios que pudieran causarse por incumplimiento de estas obligaciones.</p>

                    <p>8.- El presente Aviso Legal se rige íntegramente por la legislación española. Para la resolución de cualquier conflicto relativo a la interpretación o aplicación de este Aviso Legal, el Usuario se somete expresamente a la jurisdicción de los tribunales de Madrid (España).</p>

                </div>
                <div className="legal-text-btn-container">
                    <div onClick={props.onLegalNoticeClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                </div>
            </div>

            : null
    );
}

export default LegalNotice;