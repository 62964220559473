import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";


class SponsorsHtml extends Component {

    static contextType = AuthContext;

    state = {
        //CookiesPolicyPopUpOn: false,
        //LegalNoticePopUpOn: false,
        //TermsConditionsPopUpOn: false,
        //PrivacyPolicyPopUpOn: false
    }

    //componentDidMount() {}
    //componentDidUpdate() {}



    render() {



        return (
            <React.Fragment>

                <div className="programme-speakers-content-container">

                    {this.context.event_settings.event_id == "378" ?

                        <div className="sponsors-logos-container 2congresocovid">

                            <p>
                                <a target="_blank" href="https://www.astrazeneca.es/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/site/patrocinadores/ASTRAZENECA-LOGO-300x300-2.png" />
                                </a>

                                <a target="_blank" href="https://www.gilead.com/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/GILEAD_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://es.gsk.com/es-es/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/GSK_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.janssen.com/spain/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/Janssen_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.roche.es/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/ROCHE_300x300(1).jpg" />
                                </a>

                                <a target="_blank" href="https://www.shionogi.com/eu-es/es/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/SHIONOGI_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://vegenatnutricion.es/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/VHC-Grupo-nutrisens-2020_vertical.jpg" />
                                </a>

                                <a target="_blank" href="https://www.xolomon.com/">
                                    <img className="sponsor" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/XOLOMON-LOGO-300x300.jpg" />
                                </a>
                            </p>


                            <p>
                                <a target="_blank" href="https://www.dentaid.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/DENTAID-LOGO_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.esteveteijin.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/Esteve_Teijin_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.lilly.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/LILLY-LOGO-300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.msd.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/MSD_Inventing-for-life-LOGO.jpg" />
                                </a>

                                <a target="_blank" href="https://www.neuraxpharm.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/NEURAXPHARMA_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.pfizer.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/PFIZER.jpg" />
                                </a>

                                <a target="_blank" href="https://www.sanofi.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/SANOFI-LOGO_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://sobi.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/SOBI_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.cfsource.es/es">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/VERTEX_300x300.jpg" />
                                </a>

                                <a target="_blank" href="https://www.vircell.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/patrocinadores/Vircell_300x300.jpg" />
                                </a>

                            </p>



                        </div>



                        :
                        <div className="sponsors-logos-container">

                            <a target="_blank" href="https://www.emma.events/static/virtualvenue/vpracticvm/colaboradores/colabs.png">
                                <img src="https://www.emma.events/static/virtualvenue/vpracticvm/colaboradores/colabs.png" />
                            </a>

                        </div>
                    }


                </div>

            </React.Fragment>
        );
    }
}

export default SponsorsHtml;