import React from 'react';
import Session from './Session';

function RoomVPRACTICVM(props) {

    function onSessionClickHandler(link) {

        window.open(link, "_self")
    }


    return (
        <div>

            <div className="room-sessions-wrapper">
                <div className="room-sessions-header-name">
                    Viernes,  12 DE MARZO
            </div>
                <div className="room-sessions-area">



                    <table border="0" cellSpacing="0" cellPadding="0" className="tbl-room-sessions-panel">
                        <tbody>
                            <tr>
                                <td onClick={() => onSessionClickHandler("/room/2442/6049d2b8aec8de2520bd0473")}>
                                    <p>15:25 INICIO DEL CURSO</p>

                                    <p>BIENVENIDA<br />
                                    MANCHAS BUENAS, FEAS Y MALAS<br />
                                    DRA. ANGELA HERNANDEZ<br />
                                    COLOQUIO
                                    </p>

                                    <p>YO LO MIRO TODO CON LUPA<br />
                                    DRA. LUCERO NOGUERA<br />
                                    COLOQUIO
                                    </p>

                                    <p>MOSAICISMO PARA PRINCIPIANTES<br />
                                    DR. ANTONIO TORRELO<br />
                                    COLOQUIO
                                    </p>

                                    <p>INTELIGENCIA ARTICIAL: ¿DÓNDE ESTAMOS?<br />
                                    DR. ANTONIO NUÑEZ<br />
                                    COLOQUIO
                                    </p>

                                    <p>FIN DE LA JORNADA</p>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div className="room-sessions-wrapper">
                <div className="room-sessions-header-name">
                    Sabado, 13 DE MARZO
            </div>
                <div className="room-sessions-area">



                    <table border="0" cellSpacing="0" cellPadding="0" className="tbl-room-sessions-panel">
                        <tbody>
                            <tr>
                                <td onClick={() => onSessionClickHandler("/room/2442/6049d2b8aec8de2520bd0474")}>
                                    <p>09:00 INICIO DE LA JORNADA</p>

                                    <p>MANCHAS FRESA<br />
                                    DRA. EULALIA BASELGA<br />
                                    COLOQUIDO
                                    </p>

                                    <p>LESIONES INSÓLITAS<br />
                                    DR. ANGEL VERA<br />
                                    COLOQUIO
                                    </p>


                                    <p>CON LA VENIA<br />
                                    PROF. CARMEN GONZÁLEZ LEÓN<br />
                                    COLOQUIO
                                    </p>

                                    <p><strong>PAUSA</strong></p>

                                    <p>10 FAQS EN NEVUS MELANOCÍTICOS<br />
                                    DRA. ANGELA HERNANDEZ
                                    </p>

                                    <p>LES DAS LA MANO Y TE ENSEÑAN EL PIE<br />
                                    DR. RAÚL DE LUCAS<br />
                                    COLOQUIO
                                    </p>

                                    <p>QUÉ HAY DE NUEVO EN DERMATOLOGIA PEDIATRICA<br />
                                    DRA. RAQUEL JIMENEZ
                                    </p>


                                    <p>13:30 CIERRE DEL CURSO</p>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>


        </div>

    );
}

export default RoomVPRACTICVM;