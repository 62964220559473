import React from 'react';
import Session from './Session';

function SessionISIN2021(props) {


    return (

        <div>

            {props.day == "Saturday, May 29th 2021" ?

                <table border="0" cellspacing="0" cellpadding="0" class="tbl-room-sessions-panel">
                    <tbody>

                        <tr>
                            <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 1ST PART</td>
                        </tr>

                        <tr>
                            <td class="td-time">13:55</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d65">Presentation of the sessions of the day</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">14:00</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d5c">Teaching Course: Safety</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">14:30</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d5d">Teaching course: Anesthesia</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">15:00</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d5e">How I do it: Types of electrodes</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">15:10</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d5f">Recipes: SEP</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">15:20</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d60">How I do it: SEP</a></td>
                            <td class="td-icon"></td>
                        </tr>

                        <tr>
                            <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 2ND PART</td>
                        </tr>

                        <tr>
                            <td class="td-time">15:50</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d61">Coffee with the experts: Q&A on the last Tuesday webinars </a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">16:05</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d62">Teaching course: Warnings in IOM</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">16:35</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d63">Recipes: MEP</a></td>
                            <td class="td-icon"></td>
                        </tr>
                        <tr>
                            <td class="td-time">16:45</td>
                            <td class="td-title">
                                <a href="/room/2868/60a8b17e0637020f34672d64">How I Do It: MEP</a></td>
                            <td class="td-icon"></td>
                        </tr>
                    </tbody>
                </table>

                : props.day == "Saturday, June 5th 2021" ?

                    <table border="0" cellspacing="0" cellpadding="0" class="tbl-room-sessions-panel">
                        <tbody>

                            <tr>
                                <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 1ST PART</td>
                            </tr>

                            <tr>
                                <td class="td-time">13:55</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">Presentation of the sessions of the day</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">14:00</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">Teaching course | IOM in endovascular procedures</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">14:30</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">Recipes | D-Wave</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">14:40</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">How I do it | D-Wave</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">14:55</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">Recipes | DC-Mapping</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">15:05</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d72">How I do it | DC-Mapping</a></td>
                                <td class="td-icon"></td>
                            </tr>




                            <tr>
                                <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 2ND PART</td>
                            </tr>


                            <tr>
                                <td class="td-time">15:40</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d6d">Coffee with the experts: Q&A on the last Tuesday webinars</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">15:55</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d6d">Teaching course | IOM of spinal cord tumors</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">16:25</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d6d">Grand round | Lesson from spinal cord tumor</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">16:40</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d6d">Teaching course | IOM in scoliosis</a></td>
                                <td class="td-icon"></td>
                            </tr>
                            <tr>
                                <td class="td-time">17:25</td>
                                <td class="td-title">
                                    <a href="/room/2868/60a8b17e0637020f34672d6d">Grand round | Lesson from scoliosis</a></td>
                                <td class="td-icon"></td>
                            </tr>
                        </tbody>
                    </table>


                    : props.day == "Saturday, June 12th 2021" ?

                        <table border="0" cellspacing="0" cellpadding="0" class="tbl-room-sessions-panel">
                            <tbody>

                                <tr>
                                    <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 1ST PART</td>
                                </tr>

                                <tr>
                                    <td class="td-time">13:55</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">Presentation of the sessions of the day</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">14:00</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">Recipes | Spinal Reflexes</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">14:10</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">How I do it | Spinal Reflexes</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">14:25</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">Recipes | Cauda equina mapping</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">14:35</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">How I do it | Cauda equina mapping</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">14:50</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">Teaching Course | IOM of cauda equina</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">15:20</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d80">Grand round | Lesson from cauda equina</a></td>
                                    <td class="td-icon"></td>
                                </tr>



                                <tr>
                                    <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 2ND PART</td>
                                </tr>

                                <tr>
                                    <td class="td-time">15:55</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d7b">Coffee with the experts: Q&A on the last Tuesday webinars</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">16:10</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d7b">Recipes | Pedical screw mapping</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">16:20</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d7b">How I do it | Pedical screw mapping</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">16:35</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d7b">Teaching course | IOM of spinal fusions</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                                <tr>
                                    <td class="td-time">17:05</td>
                                    <td class="td-title">
                                        <a href="/room/2868/60a8b17e0637020f34672d7b">Grand round | Lesson from spinal fussions</a></td>
                                    <td class="td-icon"></td>
                                </tr>
                            </tbody>
                        </table>


                        : props.day == "Saturday, June 19th 2021" ?


                            <table border="0" cellspacing="0" cellpadding="0" class="tbl-room-sessions-panel">
                                <tbody>

                                    <tr>
                                        <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 1ST PART</td>
                                    </tr>

                                    <tr>
                                        <td class="td-time">13:25</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Improving intraoperative evoked potentials at short latency by a novel neuro-stimulation technology with delayed return discharge
                                        <br />
                                                <img alt="" src="https://www.emma.events/static/virtualvenue/isin2021/room/inomed-room-sponsor.jpg" /></a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">13:55</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Presentation of the sessions of the day</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">14:00</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Teaching course | IOM in carotid procedures</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">14:30</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Grand round | Lesson from carotid monitoring</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">14:45</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Teaching course | IOM in aorta procedures</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">15:15</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d8c">Grand round | Lesson from aorta monitoring</a></td>
                                        <td class="td-icon"></td>
                                    </tr>



                                    <tr>
                                        <td colSpan="3" class="td-title isin2021-separator">SCIENTIFIC SESSION: 2ND PART</td>
                                    </tr>

                                    <tr>
                                        <td class="td-time">15:50</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d87">Coffee with the experts: Q&A on the last Tuesday webinars</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">16:05</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d87">MASTERCLASS | Clinical Neurophysiology of the Injured Spinal Cord</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">17:00</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d87">Chicago Presentation</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-time">17:10</td>
                                        <td class="td-title">
                                            <a href="/room/2868/60a8b17e0637020f34672d87">Adjourn</a></td>
                                        <td class="td-icon"></td>
                                    </tr>
                                </tbody>
                            </table>

                            : null}

        </div>

    );
}

export default SessionISIN2021;