import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectSocket, disconnectSocket } from '../../store/actions/global';
import axios from 'axios';
import io from "socket.io-client";
import configData from '../../config.json';
import MyOpenTok from './OpenTok/OpenTok';
import AuthContext from '../../context/auth-context'
import { withTranslation } from 'react-i18next';
import HospitalityMeetPreview from '../HospitalityMeetPreview/HospitalityMeetPreview';
import dateFormat from 'dateformat'

class HospitalityMeeting extends Component {

    state = {
        id: "",
        type: "",
        company_id: "",
        exitLink: "",
        logo: "",
        host_id: "",
        host_name: "",
        host_surname: "",
        host_email: "",
        title: "",
        description: "",
        date_start: "",
        date_end: "",
        security: "",
        waiting_room: null,
        default_owner_video: null,
        default_owner_sound: null,
        default_participant_video: null,
        default_participant_sound: null,
        connect_at_anytime: null,
        silence_participant_on_enter: null,
        nParticipants: 0,
        locked: null,
        state: 'scheduled',
        participants_in: [],
        participants_waiting: [],
        participants_waiting_count: 0,
        myProfile: '',
        myShare: false,
        myAccess: 'enter',
        myName: '',
        myInstitution: "",
        myEmail: '',
        myRole: '',
        mySound: true,
        myVideo: true,
        openTok_apiKey: "",
        openTok_sessionId: "",
        openTok_token: "",
        messages: [],
        message: "",
        message_to: "",
        screenShare: false,
        viewLayout: 'full',
        sideBarOn: true,
        sideBarParticipantsOn: true,
        sideBarChatOn: true,
        sideBarParticipantListOrWaiting_Participants: true,
        sideBarParticipantListOrWaiting_Waiting: false

    }

    static contextType = AuthContext;

    componentDidMount() {
        this.loadMeeting(this.props.match.params.meetingId)
    }

    loadMeeting(meeting_id) {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/meetings/" + meeting_id, data)
            .then(response => {

                if (response.data !== undefined && response.data.length > 0) {
                    const meeting = response.data[0];

                    this.setState({
                        id: meeting._id,
                        type: meeting.type,
                        company_id: meeting.company_id,
                        exitLink: meeting.exit_link,
                        logo: meeting.logo,
                        host_id: meeting.host_id,
                        host_name: meeting.host_name,
                        host_surname: meeting.host_surname,
                        host_email: meeting.host_email,
                        title: meeting.title,
                        description: meeting.description,
                        date_start: meeting.date_start,
                        date_end: meeting.date_end,
                        security: meeting.security,
                        waiting_room: meeting.waiting_room,
                        default_owner_video: meeting.default_owner_video,
                        default_owner_sound: meeting.default_owner_sound,
                        default_participant_video: meeting.default_participant_video,
                        default_participant_sound: meeting.default_participant_sound,
                        connect_at_anytime: meeting.connect_at_anytime,
                        silence_participant_on_enter: meeting.silence_participant_on_enter,
                        nParticipants: meeting.participants,
                        locked: meeting.locked,
                        state: meeting.state,
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    initSocketConnection = () => {

        const server = configData.SOCKET_API_INDUSTRY + "/meeting";
        this.socket = io.connect(server, { transports: ['websocket'], query: { token: this.context.token } });
        this.socket.on('connect', () => {

            const data = {
                meeting_id: this.props.match.params.meetingId
            }
            // #### USERS IN THE ROOM -------------------------------------------
            this.socket.on("joined", data => {   // I joined the meeting

                const meeting = data.meeting;

                console.log(data)

                const participants_in = data.participants !== undefined ? data.participants.filter((element, index) => {
                    return (
                        element.state === "in"
                    )
                }) : [];

                const participants_waiting = data.participants !== undefined ? data.participants.filter((element, index) => {
                    return (
                        element.state === "waiting" && element.user_id !== this.context.id
                    )
                }) : [];

                const me = data.participants !== undefined ? data.participants.filter((element, index) => {
                    return (
                        element.user_id === this.context.id
                    )
                }) : [];

                if (me[0].meeting_role === "host" || data.autoStart) {
                    this.socket.emit('start', { meeting_id: this.state.id });
                }

                this.setState({
                    id: meeting._id,
                    type: meeting.type,
                    company_id: meeting.company_id,
                    exitLink: meeting.exit_link,
                    logo: meeting.logo,
                    host_id: meeting.host_id,
                    host_name: meeting.host_name,
                    host_surname: meeting.host_surname,
                    host_email: meeting.host_email,
                    title: meeting.title,
                    description: meeting.description,
                    date_start: meeting.date_start,
                    date_end: meeting.date_end,
                    security: meeting.security,
                    waiting_room: meeting.waiting_room,
                    default_owner_video: meeting.default_owner_video,
                    default_owner_sound: meeting.default_owner_sound,
                    default_participant_video: meeting.default_participant_video,
                    default_participant_sound: meeting.default_participant_sound,
                    connect_at_anytime: meeting.connect_at_anytime,
                    silence_participant_on_enter: meeting.silence_participant_on_enter,
                    nParticipants: meeting.participants,
                    locked: meeting.locked,
                    state: meeting.state,
                    participants_in: participants_in,
                    participants_waiting: participants_waiting,
                    myProfile: me !== undefined ? me[0].meeting_role : 'attendee',
                    myShare: me !== undefined ? me[0].share : false,
                    myAccess: me !== undefined ? me[0].state : '',
                    myName: me !== undefined ? me[0].name : '',
                    myInstitution: me !== undefined ? me[0].institution : '',
                    myPicture: me !== undefined ? me[0].picture : '',
                    myEmail: me !== undefined ? me[0].email : '',
                    myRole: me !== undefined ? me[0].role : '',
                    //mySound: me !== undefined ? me[0].sound_on : '',
                    //myVideo: me !== undefined ? me[0].video_on : '',
                    messages: [],
                    message: "",
                    message_to: "",
                    sideBarParticipantListOrWaiting_Participants: me !== undefined ? me[0].meeting_role === "host" ? false : true : true,
                    sideBarParticipantListOrWaiting_Waiting: me !== undefined ? me[0].meeting_role === "host" ? true : false : false
                })


            });

            this.socket.on("in", data => {   // a new participant entered the meeting

                let newParticipants_in;
                let newParticipantes_waiting

                let oldParticipants_waiting_count = this.state.participants_waiting_count;
                let newParticipantes_waiting_count;

                let add = false;

                if (data !== undefined && data.state === "in") {

                    // check if it is already in 
                    const alreadyIn = this.state.participants_in.filter(el => el.user_id === data.user_id)
                    if (alreadyIn !== undefined && alreadyIn.length > 0) {
                        // already in, nothing to do 
                    } else {
                        newParticipants_in = this.state.participants_in;
                        newParticipants_in.push(data);
                        add = true;
                    }

                    // remove from the waiting room, if needed
                    newParticipantes_waiting = this.state.participants_waiting.filter(el => el.user_id !== data.user_id)

                    this.setState({
                        participants_in: add ? newParticipants_in : this.state.participants_in,
                        participants_waiting: newParticipantes_waiting
                    })

                }


                if (data !== undefined && data.state === "waiting" && this.state.myProfile === "host") {

                    // check if it is already waiting
                    const alreadyWaiting = this.state.participants_waiting.filter(el => el.user_id === data.user_id)
                    if (alreadyWaiting !== undefined && alreadyWaiting.length > 0) {
                        // already in, nothing to do 
                    } else {
                        newParticipantes_waiting = this.state.participants_waiting;
                        newParticipantes_waiting.push(data);
                        add = true;
                    }

                    // remove from the waiting room, if needed
                    newParticipants_in = this.state.participants_in.filter(el => el.user_id !== data.user_id)

                    this.setState({
                        participants_in: newParticipants_in,
                        participants_waiting: add ? newParticipantes_waiting : this.state.participants_waiting
                    })

                }
            });

            this.socket.on("leave", data => {   // a participant left then meeting                
                const newParticipants_in = this.state.participants_in.filter(el => el.user_id !== data.user_id)
                const newParticipantes_waiting = this.state.participants_waiting.filter(el => el.user_id !== data.user_id)
                this.setState({
                    participants_in: newParticipants_in,
                    participants_waiting: newParticipantes_waiting
                })
            });


            this.socket.on("start", data => {   // meeting started                
                if (data !== undefined) {
                    this.setState({
                        state: "started",
                        openTok_apiKey: data.openTok.apiKey !== undefined ? data.openTok.apiKey : "",
                        openTok_sessionId: data.openTok.sessionId !== undefined ? data.openTok.sessionId : "",
                        openTok_token: data.openTok.token !== undefined ? data.openTok.token : ""
                    })
                }
            });

            this.socket.on("end", data => {   // meeting ends
                this.endSocketConnection();
                this.setState({
                    state: "ended",
                    myAccess: "enter",
                    participants_waiting: [],
                    participants_in: [],
                    openTok_apiKey: "",
                    openTok_sessionId: "",
                    openTok_token: ""
                })
            });

            this.socket.on("accepted", (data) => {   // host accepts or declines participant in meeting -> messat to the own user
                this.setState({
                    participants_in: data.participants,
                    myAccess: "in",
                    openTok_apiKey: data.openTok.apiKey !== undefined ? data.openTok.apiKey : "",
                    openTok_sessionId: data.openTok.sessionId !== undefined ? data.openTok.sessionId : "",
                    openTok_token: data.openTok.token !== undefined ? data.openTok.token : ""
                })
            });

            this.socket.on("declined", () => {   // host accepts or declines participant in meeting -> messat to the own user        
                console.log('declined')
                this.endSocketConnection();
                this.setState({
                    myAccess: "enter",
                    participants_waiting: [],
                    participants_in: []
                })
            });

            this.socket.on("waiting", data => {   // host puts me into the waiting room
                this.setState({
                    myAccess: "waiting",
                    participants_waiting: [],
                    participants_in: [],
                    openTok_apiKey: "",
                    openTok_sessionId: "",
                    openTok_token: ""
                })
            });

            this.socket.on("withdraw", data => {   // host withdraw a participant from the room
                this.endSocketConnection();
                this.setState({
                    myAccess: "enter",
                    participants_waiting: [],
                    participants_in: [],
                    openTok_apiKey: "",
                    openTok_sessionId: "",
                    openTok_token: ""
                })
            });

            this.socket.on("host", data => {   // host makes another user host
                console.log(data)
            });

            this.socket.on("mute-video", data => {   // mute video of a user
                this.setState({ myVideo: data })
            });
            this.socket.on("mute-sound", data => {   // mute sound of a user
                this.setState({ mySound: data })
            });
            this.socket.on("mute-sound-all", () => {   // mute all 
                if (this.state.myProfile !== "host") {
                    this.setState({ mySound: false })
                }
            });

            this.socket.on("screen-share", data => {   // host shares-screen
                console.log(data)
            });

            this.socket.on("message", data => {   // host shares-screen
                console.log(data)
                if (data !== undefined) {
                    let messages = this.state.messages;
                    messages.push(data);
                    this.setState({ messages: messages })
                }
            });



            this.socket.on("disconnect", data => {
                //console.log('force disconnect')
            });


            this.socket.emit('join', data); // join the meeting 

        });

    }

    endSocketConnection() {
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.disconnect();
        }
    }

    exitMeeting = () => {
        this.endSocketConnection();
        this.setState({
            myAccess: "enter",
            participants_waiting: [],
            participants_in: [],
            openTok_apiKey: "",
            openTok_sessionId: "",
            openTok_token: ""
        })
    }

    endMeeting = () => {
        if (this.state.myProfile === "host") {
            this.setState({
                participants_waiting: [],
                participants_in: []
            })
            this.socket.emit("end", { meeting_id: this.state.id, user_id: this.context.id })
        }
    }

    acceptWaiting = (user_id) => {
        if (this.state.myProfile === "host") {
            this.socket.emit("accept", { meeting_id: this.state.id, user_id: user_id })
        }
    }

    declineWaiting = (user_id) => {
        if (this.state.myProfile === "host") {
            this.socket.emit("decline", { meeting_id: this.state.id, user_id: user_id })
        }
    }

    withdrawParticipant = (user_id) => {
        if (this.state.myProfile === "host") {
            this.socket.emit("withdraw", { meeting_id: this.state.id, user_id: user_id })
        }
    }

    backToWaiting = (user_id) => {
        if (this.state.myProfile === "host") {
            this.socket.emit("waiting", { meeting_id: this.state.id, user_id: user_id })
        }
    }

    onSound = () => {
        this.setState((prevState, props) => {
            return { mySound: !prevState.mySound };
        })
    }

    onVideo = () => {
        this.setState((prevState, props) => {
            return { myVideo: !prevState.myVideo };
        })
    }

    logoutHandler = () => {

        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

        // close of the socket

    }

    exitHandler = () => {
        this.props.history.push('/congress/lobby-scientific')
    }

    onMessageHandler = (text) => {
        this.setState({ message: text });
    }

    onMessageToHandler = (value) => {
        this.setState({ message_to: value !== "" ? value : null });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    sendMessage = () => {
        console.log(this.state.message_to)
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.emit('message', {
                meeting_id: this.state.id,
                user_id: this.context.id,
                message: this.state.message,
                message_to: this.state.message_to
            })
        }
    }

    muteSoundParticipant = (user_id, on) => {
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.emit('mute-sound', {
                sound: on,
                meeting_id: this.state.id,
                user_id: user_id
            })
        }
    }

    muteVideoParticipant = (user_id, on) => {
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.emit('mute-video', {
                video: on,
                meeting_id: this.state.id,
                user_id: user_id
            })
        }
    }

    muteSoundAll = () => {
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.emit('mute-sound-all', {
                meeting_id: this.state.id
            })
        }
    }

    shareScreen = () => {
        this.setState((prevState, props) => {
            return { screenShare: !prevState.screenShare };
        })
    }

    onParticipants = () => {
        this.setState((prevState, props) => {
            return {
                sideBarParticipantsOn: !prevState.sideBarParticipantsOn,
                sideBarOn: (!prevState.sideBarParticipantsOn || prevState.sideBarChatOn)
            };
        })
    }

    onChat = () => {
        this.setState((prevState, props) => {
            return {
                sideBarChatOn: !prevState.sideBarChatOn,
                sideBarOn: (!prevState.sideBarChatOn || prevState.sideBarParticipantsOn)
            };
        })
    }

    onParticipantsOrWaitingToggle = (activetab) => {
        this.setState((prevState, props) => {
            if (prevState.myProfile === "host") {
                if (activetab === "participants") {
                    return {
                        sideBarParticipantListOrWaiting_Participants: true,
                        sideBarParticipantListOrWaiting_Waiting: false
                    };
                }
                if (activetab === "waiting") {
                    return {
                        sideBarParticipantListOrWaiting_Participants: false,
                        sideBarParticipantListOrWaiting_Waiting: true
                    };
                }

            }
        })
    }

    currentViewLayout = "grid";

    onLayoutHandler = (layout, clicked) => {

        let gridArea = document.getElementById('hospitality-meeting-videos-grid-area')
        let fullArea = document.getElementById('hospitality-meeting-videos-full-area')
        let fullArea_Persons = document.getElementById('hospitality-meeting-full-video-persons-container')
        let full_AreaVideo = document.getElementById('hospitality-meeting-full-video')

        let publisher_nodes = document.getElementsByClassName('hospitality-meeting-video-container-publisher') // pode ter + de um, se estivermos com screen shares
        let subcriber_nodes = document.getElementsByClassName('hospitality-meeting-video-container-subscriber') // pode ter vários
        let shared_nodes = document.getElementsByClassName('hospitality-meeting-video-container-share') // pode ter vários

        
        // click on a video -> put in full mode -> move that video do the Main -> move all the remaining to persons area
        if (layout == "highlight") {
            // moves to full 
            gridArea.style.display = "none";    // desliga a area anteior para começar a mexer


            // mete todos com as dimensoes da barra de personas em baixo
            const subcriber_videos = document.getElementsByClassName('OTSubscriberContainer')
            for (let index = 0; index < subcriber_videos.length; index++) {
                subcriber_videos[index].style.width = '134px';
                subcriber_videos[index].style.height = '90px';
            }
            const publisher_videos = document.getElementsByClassName('OTPublisherContainer')
            for (let index = 0; index < publisher_videos.length; index++) {
                publisher_videos[index].style.width = '134px';
                publisher_videos[index].style.height = '90px';
            }
    

            // put the clicked video con as dimensoes da full area -> sobrepondo ao que foi feito anteiormente
            const highlight = clicked;
            const highlightDiv = highlight.children[0]
            const highlight_video = highlightDiv.children[0]
            highlight_video.style.width = 'calc(75vw - 90px)';
            highlight_video.style.height = 'calc((75vw - 130px)*96/134)';
            highlight_video.style.maxheight = '100px';


            fullArea.style.display = "block";   // liga a nova area para começar a colocar os objectos no sitio

            // mete todos em baixo
            
            if (shared_nodes !== undefined && shared_nodes.length > 0) {
                for (let index = 0; index < shared_nodes.length; index++) {
                    fullArea_Persons.appendChild(shared_nodes[index])
                }
            }
            if (subcriber_nodes !== undefined && subcriber_nodes.length > 0) {
                for (let index = 0; index < subcriber_nodes.length; index++) {
                    fullArea_Persons.appendChild(subcriber_nodes[index])
                }
            }
            if (publisher_nodes !== undefined && publisher_nodes.length > 0) {
                for (let index = 0; index < publisher_nodes.length; index++) {
                    fullArea_Persons.appendChild(publisher_nodes[index])
                }
            }

            // mete o video clicado em destaque 
            if (clicked !== undefined) {
                full_AreaVideo.appendChild(clicked)
            }

            this.currentViewLayout = "full";
        }


        // "grid" show grid 
        //  -> move all the the grid, first publisher, then subscribers 
        //  -> set the dimensions of all to the correct: equal to same 
        if (layout == "grid" && this.currentViewLayout !== "grid") {
            // change the layout
            fullArea.style.display = "none"


            const publisher_videos = document.getElementsByClassName('OTPublisherContainer')
            for (let index = 0; index < publisher_videos.length; index++) {
                publisher_videos[index].style.width = '202px';
                publisher_videos[index].style.height = '153px';

            }
            // set the size of the subscribers 
            const subcriber_videos = document.getElementsByClassName('OTSubscriberContainer')
            for (let index = 0; index < subcriber_videos.length; index++) {
                subcriber_videos[index].style.width = '202px';
                subcriber_videos[index].style.height = '153px';

            }

            gridArea.style.display = "block"

            // move the shared
            if (shared_nodes !== undefined && shared_nodes.length > 0) {
                for (let index = 0; index < shared_nodes.length; index++) {
                    gridArea.children[0].appendChild(shared_nodes[0])
                }
            }
            // move the publisher
            if (publisher_nodes !== undefined && publisher_nodes.length > 0) {
                for (let index = 0; index < publisher_nodes.length; index++) {
                    gridArea.appendChild(publisher_nodes[0])
                }
            }
            // move the subscribers
            if (subcriber_nodes !== undefined && subcriber_nodes.length > 0) {
                for (let index = 0; index < subcriber_nodes.length; index++) {
                    gridArea.appendChild(subcriber_nodes[0])
                }
            }

            // set the size of the Publishers
            this.currentViewLayout = "grid";
        }


        // "full" show full 
        //  -> move publisher to Main, 
        //  -> move all subcribers to persons area 
        //  -> set the dimensions of the Main and the dimensions of the others
        if (layout == "full" && this.currentViewLayout !== "full") {

            gridArea.style.display = "none"


            // primeiro mete todos os subscribers com dimensao de baixo
            const publisher_videos = document.getElementsByClassName('OTPublisherContainer')
            for (let index = 0; index < publisher_videos.length; index++) {
                publisher_videos[index].style.width = '134px';
                publisher_videos[index].style.height = '90px';
            }

            // para depois alterar o primeiro publisher que será enviado para o full
            publisher_videos[0].style.width = 'calc(75vw - 130px)';
            publisher_videos[0].style.height = 'calc((75vw - 130px)*90/134)';
            publisher_videos[0].style.maxheight = '100px';

            // set the size of the subscribers 
            const subcriber_videos = document.getElementsByClassName('OTSubscriberContainer')
            for (let index = 0; index < subcriber_videos.length; index++) {
                subcriber_videos[index].style.width = '134px';
                subcriber_videos[index].style.height = '90px';
            }

            fullArea.style.display = "block"

            // Se houver shared, o primeiro shared é que vai para o full  / // se nao tiver manda o Publisher
            if (shared_nodes !== undefined && shared_nodes.length > 0) {
                full_AreaVideo.appendChild(shared_nodes[0])                
                if (publisher_nodes !== undefined && publisher_nodes.length > 0) {
                    for (let index = 0; index < publisher_nodes.length; index++) {
                        fullArea_Persons.appendChild(publisher_nodes[index])
                    }
                }
            } else {
                full_AreaVideo.appendChild(publisher_nodes[0]) 
            }
            
            // os restantes shared vao para baixo, junto com todos os subscribers
            /* if (shared_nodes !== undefined && shared_nodes.length > 0) {
                for (let index = 0; index < shared_nodes.length; index++) {
                    fullArea_Persons.appendChild(shared_nodes[index])
                }
            } */
            // os restantes publishes vao para baixo, junto com todos os subscribers
            /* if (publisher_nodes !== undefined && publisher_nodes.length > 0) {
                for (let index = 0; index < publisher_nodes.length; index++) {
                    console.log('c')
                    fullArea_Persons.appendChild(publisher_nodes[index])
                }
            } */
            if (subcriber_nodes !== undefined && subcriber_nodes.length > 0) {
                for (let index = 0; index < subcriber_nodes.length; index++) {
                    fullArea_Persons.appendChild(subcriber_nodes[index])
                }
            }
            this.currentViewLayout = "full";
        }
    }

    render() {

        if (this.props.forceLogout) {
            this.logoutHandler();
        }

        //document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';

        const openTokenRender = this.state.openTok_apiKey !== "" && this.state.openTok_sessionId !== "" && this.state.openTok_token !== ""
            ? <MyOpenTok
                apiKey={this.state.openTok_apiKey}
                sessionId={this.state.openTok_sessionId}
                token={this.state.openTok_token}
                video={this.state.myVideo}
                audio={this.state.mySound}
                name={this.state.myName}
                picture={this.state.myPicture}
                institution={this.state.myInstitution}
                screenShare={this.state.screenShare}
                layout={this.state.viewLayout}
                pubWidth="800"
                pubHeight="400"
                highlightMe={this.onLayoutHandler}
            />
            : null




        const participants_waiting_access = this.state.myProfile === "host" && this.state.participants_waiting !== undefined
            ? this.state.participants_waiting.map((el, index) => {
                return (
                    <div className="meeting-sidebar-waiting-room-entry-container" key={el.user_id}>
                        <div className="meeting-sidebar-waiting-room-entry-pic">
                            <img src="/img/default-login-pic.png" />
                        </div>
                        <div className="meeting-sidebar-waiting-room-entry-name">
                            {el.name}
                        </div>
                        <div className="meeting-sidebar-waiting-room-btn deny" onClick={() => this.declineWaiting(el.user_id)}>DENY</div>
                        <div className="meeting-sidebar-waiting-room-btn accept" onClick={() => this.acceptWaiting(el.user_id)}>ACCEPT</div>
                    </div>
                )
            })
            : null;

        const participants_in_meeting = this.state.participants_in !== undefined
            ? this.state.participants_in.map((el, index) => {

                const action_buttons = this.state.myProfile === "host" && el.meeting_role !== "host"
                    ? <React.Fragment>
                        <div className="meeting-sidebar-participants-icon video">
                            {el.video_on
                                ? <img src="/img/meeting-participants-video-icon.png" onClick={() => this.muteVideoParticipant(el.user_id, true)} />
                                : <img src="/img/meeting-participants-video-icon-inactive.png" onClick={() => this.muteVideoParticipant(el.user_id, false)} />
                            }
                        </div>
                        <div className="meeting-sidebar-participants-icon mic">
                            {el.sound_on
                                ? <img src="/img/meeting-participants-mic-icon.png" onClick={() => this.muteSoundParticipant(el.user_id, true)} />
                                : <img src="/img/meeting-participants-mic-icon-inactive.png" onClick={() => this.muteSoundParticipant(el.user_id, false)} />
                            }
                        </div>
                        <div className="meeting-sidebar-participants-icon mic" onClick={() => this.withdrawParticipant(el.user_id)}>
                            <img src="/img/meeting-participants-out-icon.png" />
                        </div>
                        <div className="meeting-sidebar-participants-icon mic" onClick={() => this.backToWaiting(el.user_id)}>
                            <img src="/img/meeting-waiting-room-tab-icon.png" />
                        </div>
                    </React.Fragment>
                    : null

                return (
                    <div className="meeting-sidebar-waiting-room-entry-container" key={el.user_id}>
                        <div className="meeting-sidebar-waiting-room-entry-pic">
                            <img src="/img/default-login-pic.png" />
                        </div>
                        <div className="meeting-sidebar-waiting-room-entry-name">
                            {el.name} {el.meeting_role === "host" ? '[host]' : null}
                        </div>
                        {action_buttons}
                    </div>
                )
            })
            : null;

        const selectParticipants = this.state.participants_in !== undefined
            ? <select onChange={(event) => this.onMessageToHandler(event.target.value)}>
                <option key="0" value={0}>To all participants</option>
                {this.state.participants_in.map((el, index) => {
                    if (el.user_id !== this.context.id) {
                        return (<option key={el.user_id} value={el.user_id}>{el.name}</option>)
                    }

                })}
            </select>
            : null;

        dateFormat.masks.hammerTime = 'dd/mm HH:MM';

        const chatMessages = this.state.messages.map((element, index) => {
            const myDate = dateFormat(element.date, "hammerTime")
            const className = element.user_id === this.context.id ? "hospitality-meeting-chat-message-me" : "hospitality-meeting-chat-message";
            return (
                <p key={element._id} className={className}>
                    {element.name} | {myDate}<br />
                    {element.message}
                </p>
            )
        })

        // two optional layout of the view of the participants 
        const HospitalityMeetingGrid = <div id="hospitality-meeting-videos-grid-area" className="hospitality-meeting-videos-grid-area" >
            {openTokenRender}
            {/* <div className="hospitality-meeting-video-container host" style={{ backgroundImage: "url('/img/meet-preview-pic.png')" }}>
                                <div className="hospitality-meet-preview-name">Rui Mimoso [host]</div>
                            </div> */}
        </div>

        const HospitalityMeetingFullArea = <div id="hospitality-meeting-videos-full-area" className="hospitality-meeting-videos-full-area" style={{ display: "none" }}>

            <div id="hospitality-meeting-full-video" className="hospitality-meeting-full-video"></div>

            <div className="hospitality-meeting-full-video-persons-videos-area">
                <div id="hospitality-meeting-full-video-persons-container" className="hospitality-meeting-full-video-persons-container">


                </div>
            </div>
        </div>

        const HospitalityMeeting = this.state.state === "started" && this.state.myAccess === 'in'
            ? <React.Fragment>
                {HospitalityMeetingFullArea}
                {HospitalityMeetingGrid}
            </React.Fragment>
            : <HospitalityMeetPreview
                meetingId={this.state.id}
                sound={this.state.mySound}
                video={this.state.myVideo}
                onSound={this.onSound}
                onVideo={this.onVideo}
                meetingName={this.state.title}
                name={this.state.myName}
                profile={this.state.myProfile}
                exitLink={this.state.exitLink}
                connectHandler={this.initSocketConnection}
                state={this.state.myAccess}
                cancelRequest={this.exitMeeting}></HospitalityMeetPreview>


        const exitLeave = this.state.myProfile === 'host'
            ? <img onClick={this.endMeeting} src="/img/hospitality-meeting-end-icon.png" />
            : <img onClick={this.exitMeeting} src="/img/hospitality-meeting-leave-icon.png" />

        const HospitalityMeetingFooter = this.state.state === "started" && this.state.myAccess == 'in'
            ? <div className="hospitality-meeting-footer">
                <div className="hospitality-meeting-footer-comms-settings">
                    <div className="hospitality-meeting-footer-comms-settings-icon">
                        {this.state.mySound
                            ? <img src="/img/hospitality-meeting-mic-selected-icon.png" onClick={this.onSound} />
                            : <img src="/img/hospitality-meeting-mic-icon.png" onClick={this.onSound} />
                        }
                    </div>
                    <div className="hospitality-meeting-footer-comms-settings-icon">
                        {this.state.myVideo
                            ? <img src="/img/hospitality-meeting-video-selected-icon.png" onClick={this.onVideo} />
                            : <img src="/img/hospitality-meeting-video-icon.png" onClick={this.onVideo} />
                        }
                    </div>
                </div>

                <div className="hospitality-meeting-footer-options">

                    <div className="hospitality-meeting-footer-option-icon">
                        {/* <img src="/img/hospitality-meeting-participants-icon.png" onClick={this.onParticipants} /> */}
                    </div>

                    <div className="hospitality-meeting-footer-option-icon">
                        {this.state.myProfile === "host" ||  this.state.myShare ?
                            this.state.screenShare
                                ? <img src="/img/hospitality-meeting-share-selected-icon.png" onClick={this.shareScreen} />
                                : <img src="/img/hospitality-meeting-share-selected-icon.png" onClick={this.shareScreen} />
                            : null}
                    </div>

                    <div className="hospitality-meeting-footer-option-icon">
                        {/* <img src="/img/hospitality-meeting-chat-icon.png" onClick={this.onChat} /> */}
                    </div>
                </div>

                <div className="hospitality-meeting-footer-actions">
                    <div className="hospitality-meeting-footer-action-icon">
                        {exitLeave}
                    </div>

                    {/* <div className="hospitality-meeting-footer-action-icon">
                        <img src="/img/hospitality-meeting-lock-icon.png" style={{ display: "none" }} />
                        <img src="/img/hospitality-meeting-unlock-icon.png" />
                    </div> */}
                </div>
            </div>
            : null

        const HospitalityMeetingSideBarParticipantsList = this.state.sideBarParticipantListOrWaiting_Participants
            ? <div className="meeting-sidebar-waiting-room-container">

                {participants_in_meeting}

                {/* <div className="meeting-sidebar-waiting-room-entry-container">
                        <div className="meeting-sidebar-waiting-room-btn deny-all">DENY ALL</div>
                        <div className="meeting-sidebar-waiting-room-btn accept-all">ACCEPT ALL</div>
                    </div> */}
            </div>
            : null

        const classParticipantsList = this.state.sideBarParticipantListOrWaiting_Participants ? "hospitality-meeting-sidebar-tab participants selected" : "hospitality-meeting-sidebar-tab participants"
        const classWaiting = this.state.sideBarParticipantListOrWaiting_Waiting ? "hospitality-meeting-sidebar-tab waiting-room selected" : "hospitality-meeting-sidebar-tab waiting-room"


        const HospitalityMeetingSideBarWaiting = this.state.myProfile === "host" && this.state.sideBarParticipantListOrWaiting_Waiting
            ? <div className="meeting-sidebar-participants-container">
                {participants_waiting_access}
                {/* <div className="meeting-sidebar-participants-settings-container">
                                <div className="meeting-sidebar-participants-settings-modal">
                                    Mute participants when entering
                                    <hr />
                                    Allow participants to activate themselves
                                </div>
                                <div className="meeting-sidebar-participants-settings-icon">
                                    <img src="/img/meeting-sidebar-participants-settings-icon.png" />
                                </div>
                                <div className="meeting-sidebar-participants-settings-mute-all-icon">
                                    <img src="/img/meeting-sidebar-participants-settings-mute-all-icon.png" />
                                </div>

                            </div> */}

            </div>
            : null

        const HospitalyMeetingSideBar = this.state.sideBarOn && this.state.state === "started" && this.state.myAccess == 'in'
            ? <div className="hospitality-meeting-sidebar chat participants-waiting-room">
                <div className="hospitality-meeting-sidebar-wrapper">

                    {this.state.sideBarParticipantsOn
                        ? <div className="hospitality-meeting-sidebar-participants-waiting-room hospitality-meeting-sidebar-chat-section-2">
                            <div className="hospitality-meeting-sidebar-tabs-container">
                                <div className={classParticipantsList} onClick={() => this.onParticipantsOrWaitingToggle('participants')}>
                                    <img src="/img/meeting-participants-tab-icon.png" /> &nbsp;&nbsp;Participants ({this.state.participants_in.length})
                                </div>
                                {this.state.myProfile === "host" ?
                                    <div className={classWaiting} onClick={() => this.onParticipantsOrWaitingToggle('waiting')}>
                                        <img src="/img/meeting-waiting-room-tab-icon.png" /> &nbsp;&nbsp;Waiting room ({this.state.participants_waiting.length})
                                    </div>
                                    : null}

                            </div>
                            <div className="meeting-sidebar-participants-waiting-room-container meeting-sidebar-participants-waiting-room-section-2">
                                {HospitalityMeetingSideBarParticipantsList}
                                {HospitalityMeetingSideBarWaiting}
                            </div>
                        </div>
                        : null}

                    {this.state.sideBarChatOn
                        ? <div className="hospitality-meeting-sidebar-chat hospitality-meeting-sidebar-chat-section-2">

                            <div className="hospitality-meeting-sidebar-tab-chat-container">

                                <div className="hospitality-meeting-sidebar-tab participants selected">
                                    <img src="/img/meeting-chat-tab-icon.png" /> &nbsp;&nbsp;Chat
                                </div>

                            </div>


                            <div className="meeting-sidebar-chat-container">
                                {chatMessages}
                            </div>

                            <div className="meeting-sidebar-chat-input-container">

                                <form onSubmit={this.onSubmit}>

                                    <div className="meeting-sidebar-chat-to-container">
                                        <label htmlFor="person-chat">To: &nbsp;&nbsp;</label>
                                        {selectParticipants}
                                    </div>

                                    <div className="meeting-sidebar-chat-to-input-container">
                                        <div className="meeting-sidebar-chat-to-input">
                                            <input type="text" id="chat-input" name="chat-input" onChange={(event) => this.onMessageHandler(event.target.value)} placeholder="Type to write a message" />
                                        </div>
                                        <div className="meeting-sidebar-chat-to-btn" onClick={this.sendMessage}>
                                            <img src="/img/meeting-sidebar-chat-to-btn-icon.png" />
                                        </div>
                                    </div>

                                </form>
                            </div>


                        </div>
                        : null}

                </div>
            </div>
            : null;

        const classHospitalityContainer = this.state.sideBarOn && this.state.state === "started" && this.state.myAccess === 'in' ? 'hospitality-meeting-wrapper sidebar-on' : 'hospitality-meeting-wrapper';

        const logo = this.state.logo !== undefined && this.state.logo !== "" ? <img src={this.state.logo} /> : <img src="/img/econgress-logo-meeting.png" />;

        return (

            <div className={classHospitalityContainer}>

                <div className="hospitality-meeting-container">

                    <div className="hospitality-meeting-videos-grid-arrow left" style={{ display: "none" }}>
                        <img src="/img/meeting-arrow-left.png" />
                    </div>
                    <div className="hospitality-meeting-videos-grid-arrow right" style={{ display: "none" }}>
                        <img src="/img/meeting-arrow-right.png" />
                    </div>

                    <div className="hospitality-meeting-area">

                        <div className="hospitality-meeting-header">

                            <div className="hospitality-meeting-header-logo">
                                {logo}
                            </div>

                            <div className="hospitality-meeting-header-title">
                                {this.state.title}<br /><span className="date">{this.state.date_start} // {this.state.date_end}</span>
                            </div>

                            {this.state.state === "started" && this.state.myAccess === 'in' ?
                                <div className="hospitality-meeting-header-views">

                                    <div className="hospitality-meeting-header-view-label">View</div>

                                    <div className="hospitality-meeting-header-view" onClick={() => this.onLayoutHandler('full')}>
                                        <img src="/img/meeting-view-full-icon.png" />
                                    </div>
                                    <div className="hospitality-meeting-header-view" onClick={() => this.onLayoutHandler('grid')}>
                                        <img src="/img/meeting-view-grid-selected-icon.png" />
                                    </div>

                                </div>
                                : null}

                        </div>

                        {HospitalityMeeting}
                        {HospitalityMeetingFooter}

                    </div>

                </div>
                {HospitalyMeetingSideBar}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        forceLogout: state.global.forceLogout
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HospitalityMeeting));
