import React from 'react';

function SessionSpeaker(props) {
    return (
        <div className="participant-wrapper">
            {props.portrait ?
                <div className="participant-pic"><img alt="" src={props.portrait} /></div>
                : null
            }
            <div className="participant-name">
                <strong>{props.name}</strong><br />
                {props.company}
            </div>
        </div>
    );
}

export default SessionSpeaker;