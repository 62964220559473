import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";


class SocietiesHtml extends Component {

    static contextType = AuthContext;

    state = {
        //CookiesPolicyPopUpOn: false,
        //LegalNoticePopUpOn: false,
        //TermsConditionsPopUpOn: false,
        //PrivacyPolicyPopUpOn: false
    }

    //componentDidMount() {}
    //componentDidUpdate() {}



    render() {



        return (
            <React.Fragment>

                <div className="programme-speakers-content-container">

                    {this.context.event_settings.event_id == "378" ?

                        <div className="sponsors-logos-container 2congresocovid">


                            <p>
                                <a target="_blank" href="https://aedv.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEDV-LOGO.jpg" /></a>
                                <a target="_blank" href="https://seeiuc.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEEIUC_300x300.jpg" /></a>
                                <a target="_blank" href="http://www.aceese.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ACEESE-logoogo-300px.jpg" /></a>
                                <a target="_blank" href="http://enfermeriacomunitaria.org/web/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEC-LOGO.jpg" /></a>
                                <a target="_blank" href="https://enfermeriadeltrabajo.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AET_300x300.jpg" /></a>
                                <a target="_blank" href="https://adenfermero.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ADENFERM_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.adenyd.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ADENYD_300x300.jpg" /></a>
                                <a target="_blank" href="http://aeepyci.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEEPYCI_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.aeesme.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEESME-LOGO.jpg" /></a>
                                <a target="_blank" href="https://www.enfermeriaencardiologia.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEEC_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.enfermeriadelainfancia.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEEP_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.aeeq.net/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEEQ-LOGO-300X300.jpg" /></a>
                                <a target="_blank" href="http://www.aeemt.com/web/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEEMT_300x300.jpg" /></a>
                                <a target="_blank" href="http://www.aefi.net/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEF_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.aegastro.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEG_LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://aegh.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEGH_300x300.jpg" /></a>
                                <a target="_blank" href="https://aen.es/asociacion-espanola-de-neuropsiquiatria-profesionales-de-la-salud-mental/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEN-LOGO.jpg" /></a>
                                <a target="_blank" href="https://www.vacunas.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEV_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.aeped.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AEP_300x300.jpg" /></a>
                                <a target="_blank" href="https://ancei.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ANCEI_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.ande.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ANDE_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.enfermeriayvacunas.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ANENVAC_300x300.jpg" /></a>
                                <a target="_blank" href="http://www.anmtas.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/ANMTAS_300x300.jpg" /></a>
                                <a target="_blank" href="https://amece.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/AMECE-LOGO.jpg" /></a>
                                <a target="_blank" href="http://cirugiaendovascular.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/CCE_300x300.jpg" /></a>

                                <a target="_blank" href="https://www.faecap.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/faecap-logo.jpg" /></a>


                                <a target="_blank" href="https://facme.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/FACME_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.federacion-matronas.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/FAME_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.fedeet.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/FEDEET_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.grupo40enfermeras.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/GRUPO40-LOGO.jpg" /></a>
                                <a target="_blank" href="http://inursingn.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/INesuN_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.scele.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SCELEC_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.seap.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEAP_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sedar.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEDAR_300x300.jpg" /></a>
                                <a target="_blank" href="https://seacv.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEACV-logo-300x300px.jpg" /></a>
                                <a target="_blank" href="https://secardiologia.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEC-CARDIOLOGIA-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://www.secpal.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SECPAL_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.seen.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEEN-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://seimc.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEIMC_300x300.jpg" /></a>
                                <a target="_blank" href="http://seegg.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEEGG.jpg" /></a>
                                <a target="_blank" href="https://sedene.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEDENE_300x300.jpg" /></a>
                                <a target="_blank" href="http://www.enfermeriaradiologica.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEER_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sefac.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEFAC_Logo.jpg" /></a>
                                <a target="_blank" href="https://www.sefh.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEFH_300x300.jpg" /></a>

                                <a target="_blank" href="https://se-fc.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEF_Farmacologia_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.segg.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEGG_300x300.jpg" /></a>
                                <a target="_blank" href="https://sego.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEGO-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sehh.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEHH_300x300.jpg" /></a>
                                <a target="_blank" href="https://seh-lelha.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEH-LELHA_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sehad.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEHAD_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.inmunologia.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEI_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semae.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMAE-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semes.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMES_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semfyc.es/medicos/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMFYC_300x300.jpg" /></a>
                                <a target="_blank" href="https://semicyuc.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMICYUC_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.fesemi.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMI_LOGO.jpg" /></a>
                                <a target="_blank" href="https://www.sempsph.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMPSPH_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semst.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMST_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semergen.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMERGEN-LOGO.jpg" /></a>
                                <a target="_blank" href="https://www.semg.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEMG_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.semicrobiologia.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEM-_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.senefro.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEN.jpg" /></a>
                                <a target="_blank" href="https://www.separ.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEPAR_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sen.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEN_NEUROLOGIA_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.oftalmoseo.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEO_300x300.jpg" /></a>
                                <a target="_blank" href="https://seom.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEOM_LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://seor.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEOR-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://sepd.es/inicio">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEPD_300X300.jpg" /></a>
                                <a target="_blank" href="https://www.sepa.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEPA-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="http://www.sepsiq.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEP_PSIQUIATRIA_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sociedadpuericultura.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEP_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.seqc.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEQC_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.seram.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SERAM_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sermef.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SERMEF_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.ser.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SER_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sesp.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SESP-LOGO-300x300.jpg" /></a>
                                <a target="_blank" href="https://www.setrasplante.org/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SET_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.seth.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SETH-LOGO.jpg" /></a>
                                <a target="_blank" href="http://sevirologia.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SEV_300x300.jpg" /></a>
                                <a target="_blank" href="https://www.sedolor.es/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/SED_300x300.jpg" /></a>
                                <a target="_blank" href="https://uesce.com/">
                                    <img className="company" src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/sociedades/UESCE-LOGO.jpg" /></a>


                            </p>






                        </div>



                        :
                        null
                    }


                </div>

            </React.Fragment>
        );
    }
}

export default SocietiesHtml;