import React, { Component } from 'react';

class Delegate extends Component {
    
    render() {

        const photo = this.props.photo !== undefined && this.props.photo !== "" ? <img alt="" src={this.props.photo} /> : <img src="/img/default-login-pic.png" className="img-avatar" alt="" />
        let classBusy = this.props.busy ? "stand-detail-available-delegates-photo red-connected" : "stand-detail-available-delegates-photo green-connected"
        if (!this.props.inRoom) {
            classBusy = "stand-detail-available-delegates-photo";
        }
        
        return (
            <div className="stand-detail-available-delegates-content-entry">
                <div className="stand-detail-available-delegates-photo-container">
                    <div className={classBusy}>
                    {photo}     
                    </div>
                </div>

                <div className="stand-detail-available-delegates-name-container">
                    <div className="stand-detail-available-delegates-bottom-contact-container">
                        
                        {this.props.inRoom && this.props.userId !== this.props.id ?
                        <React.Fragment>
                            <div className="stand-detail-delegate-bottom-chat-opt-right-separator"></div>

                            <div className="stand-detail-delegate-bottom-chat-opt-right" onClick={() => this.props.onTxt(this.props.id)}>
                                <img alt="" src="/img/stand-detail-person-write-icon-active.png" />
                            </div>
                            <div className="stand-detail-delegate-bottom-chat-opt-right-separator"></div>

                            <div className="stand-detail-delegate-bottom-chat-opt-right" onClick={() => this.props.onVideo(this.props.id)}>
                                <img alt="" src="/img/stand-detail-person-video-icon-active.png" />
                            </div>
                            <div className="stand-detail-delegate-bottom-chat-opt-right-separator"></div>

                            <div className="stand-detail-delegate-bottom-chat-opt-right" onClick={() => this.props.onVoice(this.props.id)}>
                                <img alt="" src="/img/stand-detail-person-call-icon-active.png" />
                            </div>
                        </React.Fragment>
                        : null}
                    
                    </div>
                <strong>{this.props.name}</strong><br /><span className="division">{this.props.department}</span>
                </div>

                </div>

        );
    }
}

export default Delegate;