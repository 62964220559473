import React from 'react';

function NavBarDays(props) {
    return (
        <div className="bottom-rooms-videos-navigation-days-nav">
            <div className="btn-days-nav">Wednsday, 24</div>
            <div className="btn-days-nav selected">Thursday, 25</div>
            <div className="btn-days-nav">Friday, 26</div>
        </div>
    );
}

export default NavBarDays;