import React, { Component } from 'react';
import SessionSpeaker from '../../../components/SessionSpeaker/SessionSpeaker';

class SessionDetailed extends Component {

    state = { open: false }

    showHideHandler = () => {
        this.setState((prevState, props) => {
            return { open: !prevState.open };
        })
    }

    render() {

        const locked = this.props.locked ? <img alt="" src="/img/icon-lock.png" /> : "";
        const companyLogo = this.props.companyLogo ? <React.Fragment><br /><img alt="" src={this.props.companyLogo} /></React.Fragment> : null;
        const widthDetail = this.props.withDetail ?
            this.state.open ? <img alt="" src="/img/icon-hide-detail.png" /> : <img alt="" src="/img/icon-show-detail.png" />
            : null;

        return (
            <tr>
                <td className="td-time">{this.props.time}</td>
                <td className={this.props.noTheme ? `td-title not-theme` : `td-title`} onClick={this.showHideHandler}>
                    {this.props.title}
                    {companyLogo}
                    {widthDetail}

                    {this.state.open ?
                        <div className="session-detail">
                            <SessionSpeaker
                                name="PERSON NAME" portrait="/img/chat-person-pic1.png"
                                company="Hospital/Company" />
                            <SessionSpeaker
                                name="PERSON NAME" portrait="/img/chat-person-pic1.png"
                                company="Hospital/Company" />
                        </div>
                        : null}

                </td>
                <td className="td-icon">{locked}</td>
            </tr>
        );
    }
}

export default SessionDetailed;