import React, { Component } from 'react';

class ZepellinAnimation extends Component {



    render() {

        let zepellinImg

        if (this.props.event_id == "314") {
            zepellinImg = <img src="https://www.emma.events/static/virtualvenue/separ2020/zepellin/Zeppelin_Left.png" />;
        }
        else if (this.props.event_id == "372" || this.props.event_id == "373") {
            zepellinImg = <img src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/zepellin/zeppelin-1reunionvirtualareas2021.png" />;
        }
        else if (this.props.event_id == "424") {
            zepellinImg = <img src="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/zepellin/zeppelin-3reunionvirtualareas2022-final-sanofi.png" />;
        }

        return (

            <div className="zepellin-container">
                <div className="zeppelin-img-container">
                    {zepellinImg}
                </div>
            </div>

        );
    }
}

export default ZepellinAnimation;



