import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



function Nav(props) {

    //state = {mobileMenuOpen:false}
    const [MobileMenuOpen, setMobileMenuOpen] = useState(false);

    function mobileMenuOpen() {
        if (MobileMenuOpen == false) {
            setMobileMenuOpen(true);
        }
        else {
            setMobileMenuOpen(false);
        }

    }

    const { t, i18n } = useTranslation();

    let lobbyscientificLink;
    let lobbyscientificLinkMobile;
    switch (props.eventId) {
        case "382": // GEMAV
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;
        case "387": // VITDAH
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;
        case "2": // VITDAH
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;
        case "3": // ENCUENTROSEXPERTO2021
            lobbyscientificLink = <MenuLink to="/congress/lobby-scientific" mobile="false" activeOnlyWhenExact="true" image="icon-video-gallery" >Grabaciones</MenuLink>
            lobbyscientificLinkMobile = <MenuLink closeMobile={mobileMenuOpen} to="/congress/lobby-scientific" mobile="true" activeOnlyWhenExact="true" image="icon-video-gallery" >Grabaciones</MenuLink>
            break
            break;
        case "4": // ETEV2021
            lobbyscientificLink = <MenuLink to="/congress/lobby-scientific" mobile="false" activeOnlyWhenExact="true" image="icon-video-gallery" >Grabaciones</MenuLink>
            lobbyscientificLinkMobile = <MenuLink closeMobile={mobileMenuOpen} to="/congress/lobby-scientific" mobile="true" activeOnlyWhenExact="true" image="icon-video-gallery" >Grabaciones</MenuLink>
            break;
        case "5": // VIITDAH2022
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;
        case "390": // ASEPB21
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;
        case "408": // AERC2022
            lobbyscientificLink = null
            lobbyscientificLinkMobile = null
            break;

        default:
            lobbyscientificLink = <MenuLink to="/congress/lobby-scientific" mobile="false" activeOnlyWhenExact="true" image="icon-scientific-area" >{t('menu_scientific1')}<br />{t('menu_scientific2')}</MenuLink>
            lobbyscientificLinkMobile = <MenuLink closeMobile={mobileMenuOpen} to="/congress/lobby-scientific" mobile="true" activeOnlyWhenExact="true" image="icon-scientific-area" >{t('menu_scientific1')} {t('menu_scientific2')}</MenuLink>
            break;
    }

    let posterLink;
    let posterLinkMobile;
    switch (props.eventId) {
        case "1": // PORTDEBARCELONA2021
            posterLink = null
            break;
        case "367": // CNP
            posterLink = (<div className="menu-item"><a href="http://congresonacionaldepsiquiatria.es/cnps/expoposters-2020/" target="_blank"><img src="/img/icon-eposters.png" alt="" /><br />POSTERS<br />ELECTRÓNICOS</a></div>)
            posterLinkMobile = (<div className="menu-item"><a href="http://congresonacionaldepsiquiatria.es/cnps/expoposters-2020/" target="_blank"><img src="/img/icon-eposters.png" alt="" /><br />POSTERS ELECTRÓNICOS</a></div>)
            break;
        case "366": // AEA
            posterLink = <MenuLink mobile="false" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >CASOS<br />CLÍNICOS</MenuLink>
            posterLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >CASOS CLÍNICOS</MenuLink>
            break;
        case "347": // GEMAV
            posterLink = null
            posterLinkMobile = null
            break;
        case "369": // CMM2020
            posterLink = null
            posterLinkMobile = null
            break;
        case "382": // PSOE2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "387": // VITDAH
            posterLink = null
            posterLinkMobile = null
            break;
        case "315": // vpracticvm
            posterLink = null
            posterLinkMobile = null
            break;
        case "2": // UROVI2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "3": // ENCUENTROSEXPERTO2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "4": // ETEV2021
            posterLink = <MenuLink mobile="false" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >GALERIA DE PÓSTERES</MenuLink>
            posterLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >GALERIA DE PÓSTERES</MenuLink>
            break;
        case "5": // VIITDAH2022
            posterLink = null
            posterLinkMobile = null
            break;
        case "390": // ASEPB21
            posterLink = null
            posterLinkMobile = null
            break;
        case "372": // 1REUNIONVIRTUALAREAS2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "373": // 1REUNIONVIRTUALAREAS2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "397": // ISIN2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "263": // EACMFS2021
            posterLink = <MenuLink mobile="false" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >EPOSTERS</MenuLink>
            posterLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >EPOSTERS</MenuLink>
            break;
        case "337": // SEROD2021
            posterLink = <MenuLink mobile="false" to="/congress/e-posters-serod" activeOnlyWhenExact="false" image="icon-eposters" >EPOSTERS</MenuLink>
            posterLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/e-posters-serod" activeOnlyWhenExact="false" image="icon-eposters" >EPOSTERS</MenuLink>
            break;
        case "420": // CNP2021
            posterLink = null
            posterLinkMobile = null
            break;
        case "424": // 3REUNIONVIRTUALAREAS2022
            posterLink = null
            posterLinkMobile = null
            break;
        case "425": // 4REUNIONVIRTUALAREAS2022
            posterLink = null
            posterLinkMobile = null
            break;
        case "327": // SEAIC2021
            posterLink = null
            posterLinkMobile = null
            break;

        case "410": // EACMFS2022
            posterLink = (<div className="menu-item"><a href="https://eposters.emma.events/eacmfs2022eposters" target="_blank"><img src="/img/icon-eposters.png" alt="" /><br />E-POSTERS</a></div>)
            posterLinkMobile = (<div className="menu-item"><a href="https://eposters.emma.events/eacmfs2022eposters" target="_blank"><img src="/img/icon-eposters.png" alt="" /><br />E-POSTERS</a></div>)
            break;

        case "489": // CNP2022
            posterLink = null
            posterLinkMobile = null
            break;

        case "575": // CNP2023
            posterLink = null
            posterLinkMobile = null
            break; 
            
        case "656": // CNP2024
            posterLink = null
            posterLinkMobile = null
            break;     

        default:
            posterLink = <MenuLink mobile="false" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >{t('menu_posters1')}<br />{t('menu_posters2')}</MenuLink>
            posterLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/e-posters" activeOnlyWhenExact="false" image="icon-eposters" >{t('menu_posters1')} {t('menu_posters2')}</MenuLink>
            break;
    }


    let oralpapersLink;
    let oralpapersLinkMobile;
    switch (props.eventId) {

        case "263": // EACMFS2021
            oralpapersLink = <MenuLink mobile="false" to="/congress/oral-papers" activeOnlyWhenExact="false" image="icon-eposters" >ORAL<br />PAPERS</MenuLink>
            oralpapersLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/oral-papers" activeOnlyWhenExact="false" image="icon-eposters" >ORAL<br />PAPERS</MenuLink>
            break;

    }

    let casereportsLink;
    let casereportsLinkMobile;
    switch (props.eventId) {

        case "263": // EACMFS2021
            casereportsLink = <MenuLink mobile="false" to="/congress/case-report" activeOnlyWhenExact="false" image="icon-eposters" >CASE<br />REPORTS</MenuLink>
            casereportsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/case-report" activeOnlyWhenExact="false" image="icon-eposters" >CASE<br />REPORTS</MenuLink>
            break;

    }


    let guestsocietyLink;
    let guestsocietyLinkMobile;
    switch (props.eventId) {

        case "263": // EACMFS2021
            guestsocietyLink = <MenuLink mobile="false" to="/congress/guest-societies" activeOnlyWhenExact="false" image="icon-eposters" >GUEST<br />SOCIETIES</MenuLink>
            guestsocietyLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/guest-societies" activeOnlyWhenExact="false" image="icon-eposters" >GUEST<br />SOCIETIES</MenuLink>
            break;

    }



    let snapshotsLink;
    let snapshotsLinkMobile;
    switch (props.eventId) {

        case "351": // SECEC2021
            snapshotsLink = <MenuLink mobile="false" to="/congress/snapshots" activeOnlyWhenExact="false" image="icon-eposters" >SNAPSHOTS</MenuLink>
            snapshotsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/snapshots" activeOnlyWhenExact="false" image="icon-eposters" >SNAPSHOTS</MenuLink>
            break;

        case "337": // SEROD2021
            snapshotsLink = <MenuLink mobile="false" to="/congress/videotecnicas" activeOnlyWhenExact="false" image="icon-eposters" >VIDEOTÉCNICAS</MenuLink>
            snapshotsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/videotecnicas" activeOnlyWhenExact="false" image="icon-eposters" >VIDEOTÉCNICAS</MenuLink>
            break;


    }


    let sponsorhipLink;
    let sponsorhipLinkMobile;
    switch (props.eventId) {

        case "337": // SEROD2021

            sponsorhipLink = (<div className="menu-item"><a href="https://www.emma.events/static/upload/ow37/events/ev337/Site/files/patrocinadores(1).pdf" target="_blank"><img src="/img/icon-networking.png" alt="" /><br />PATROCINADORES</a></div>)
            sponsorhipLinkMobile = (<div className="menu-item"><a href="https://www.emma.events/static/upload/ow37/events/ev337/Site/files/patrocinadores(1).pdf" target="_blank"><img src="/img/icon-networking.png" alt="" /><br />PATROCINADORES</a></div>)
            break;

    }


    let exhibitionLink;
    let exhibitionLinkMobile;
    switch (props.eventId) {
        case "347": // GEMAV
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria-stand/gemav" activeOnlyWhenExact="true" image="icon-industry" >GEMAV</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria-stand/gemav" activeOnlyWhenExact="true" image="icon-industry" >GEMAV</MenuLink>
            break;
        case "314": // SEPAR
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >ÁREA <br /> COMERCIAL</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >ÁREA COMERCIAL</MenuLink>
            break;
        case "382": // PSOE2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "387": // VITDAH
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "289": // SEIP2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "315": // vpracticvm
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "2": // UROVI2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "3": // UROVI2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "4": // ETEV2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "5": // VIITDAH2022
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "390": // ASEPB21
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "378": // 2congresocovid
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "372": // 1reunionvirtualareas2021
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;

        case "373": // 2reunionvirtualareas2021
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;

        case "383": // SEPAR2021
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;

        case "397": // 2congresocovid
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "351": // secec
            exhibitionLink = (<div className="menu-item"><a href="https://www.emma.events/static/virtualvenue/secec2021/industria/sponsors_e_congress.pdf" target="_blank"><img src="/img/icon-industry.png" alt="" /><br />SPONSORSHIP</a></div>)
            exhibitionLinkMobile = (<div className="menu-item"><a href="https://www.emma.events/static/virtualvenue/secec2021/industria/sponsors_e_congress.pdf" target="_blank"><img src="/img/icon-industry.png" alt="" /><br />SPONSORSHIP</a></div>)
            break;
        case "420": // CNP2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "424": // 3reunionvirtualareas2022
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;
        case "425": // 4reunionvirtualareas2022
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;
        case "327": // SEAIC2021
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "336": // sefc2022
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >COLABORADORES</MenuLink>
            break;
        case "408": // AERC2022
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "410": // EACMFS2022
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;
        case "489": // CNP2022
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;

        case "575": // CNP2023
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;  
            
        case "656": // CNP2024
            exhibitionLink = null
            exhibitionLinkMobile = null
            break;     

        default:
            exhibitionLink = <MenuLink mobile="false" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >{t('menu_exhibition1')}<br />{t('menu_exhibition2')}</MenuLink>
            exhibitionLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/industria" activeOnlyWhenExact="true" image="icon-industry" >{t('menu_exhibition1')} {t('menu_exhibition2')}</MenuLink>
            break;
    }

    let networkinLink;
    let networkinLinkMobile;
    let juniorRoomLink;
    let juniorRoomLinkMobile;
    let ESTSHouseLink;
    let ESTSHouseLinkMobile;

    switch (props.eventId) {
        case "314": // GEMAV
            networkinLink = <MenuLink mobile="false" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')}<br />{t('menu_networking_lounge2')}</MenuLink>
            networkinLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')} {t('menu_networking_lounge2')}</MenuLink>
            break;
        case "1": // GEMAV
            networkinLink = <MenuLink mobile="false" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">ÁREA<br />NETWORKING</MenuLink>
            networkinLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">ÁREA NETWORKING</MenuLink>
            break;

        case "341": // ESTS 2021
            networkinLink = <MenuLink mobile="false" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')}<br />{t('menu_networking_lounge2')}</MenuLink>
            networkinLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/networking" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')} {t('menu_networking_lounge2')}</MenuLink>
            juniorRoomLink = <MenuLink mobile="false" to="/congress/junior-room" activeOnlyWhenExact="true" image="icon-networking">JUNIOR <br /> ROOM</MenuLink>
            juniorRoomLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/junior-room" activeOnlyWhenExact="true" image="icon-networking"> JUNIOR <br /> ROOM</MenuLink>
            ESTSHouseLink = <MenuLink mobile="false" to="/congress/ests-house" activeOnlyWhenExact="true" image="icon-networking">ESTS <br /> HOUSE</MenuLink>
            ESTSHouseLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/ests-house" activeOnlyWhenExact="true" image="icon-networking"> ESTS <br /> HOUSE</MenuLink>
            break;

        default:
            networkinLink = null;
            networkinLinkMobile = null;
            juniorRoomLink = null;
            juniorRoomLinkMobile = null;
            ESTSHouseLink = null;
            ESTSHouseLinkMobile = null;
            break;
    }



    let networkinCuencaLink;
    let networkinCuencaLinkMobile;

    switch (props.eventId) {
        case "320": // cuenca
            networkinCuencaLink = <MenuLink mobile="false" to="/congress/networking-cuenca" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')}<br />{t('menu_networking_lounge2')}</MenuLink>
            networkinCuencaLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/networking-cuenca" activeOnlyWhenExact="true" image="icon-networking">{t('menu_networking_lounge1')} {t('menu_networking_lounge2')}</MenuLink>
            break;
        default:
            networkinLink = null;
            networkinLinkMobile = null;
            juniorRoomLink = null;
            juniorRoomLinkMobile = null;
            ESTSHouseLink = null;
            ESTSHouseLinkMobile = null;
            break;
    }



    let videopillsLink;
    let videopillsLinkMobile;
    switch (props.eventId) {
        case "358": // ESTS2020VC
            videopillsLink = <MenuLink mobile="false" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS<br />&nbsp;</MenuLink>
            videopillsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS</MenuLink>
            break;
        case "325": // semcpt2021
            videopillsLink = <MenuLink mobile="false" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEOTÉCNICAS<br />&nbsp;</MenuLink>
            videopillsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEOTÉCNICAS</MenuLink>
            break;
        case "341": // ESTS2020VC
            videopillsLink = <MenuLink mobile="false" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS<br />&nbsp;</MenuLink>
            videopillsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS</MenuLink>
            break;
        case "263": // EACMFS2021
            videopillsLink = <MenuLink mobile="false" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS<br />&nbsp;</MenuLink>
            videopillsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/video-pills" activeOnlyWhenExact="true" image="icon-networking">VIDEO PILLS</MenuLink>
            break;
        default:
            videopillsLink = null;
            videopillsLinkMobile = null;
            break;
    }

    let programmeLink;
    let programmeLinkMobile;
    switch (props.eventId) {
        case "387": // vitdah
            programmeLink = <MenuLink mobile="false" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            programmeLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            break;
        case "2": // UROVI2021
            programmeLink = <MenuLink mobile="false" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            programmeLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            break;
        case "3": // ENCUENTROSEXPERTO2021
            programmeLink = <MenuLink mobile="false" to="/congress/programme" activeOnlyWhenExact="true" image="icon-eposters">PROGRAMAS &<br />PONENTES</MenuLink>
            programmeLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/programme" activeOnlyWhenExact="true" image="icon-eposters">PROGRAMAS &<br />PONENTES</MenuLink>
            break;
        case "4": // ETEV2021
            programmeLink = <MenuLink mobile="false" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            programmeLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            break;
        case "5": // viitdah2022
            programmeLink = <MenuLink mobile="false" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            programmeLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/programme" activeOnlyWhenExact="true" image="icon-scientific-area">PROGRAMA<br />&nbsp;</MenuLink>
            break;
        default:
            programmeLink = null;
            programmeLinkMobile = null;
            break;
    }


    let speakersLink;
    let speakersLinkMobile;
    switch (props.eventId) {
        case "387": // vitdah
            speakersLink = <MenuLink mobile="false" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">PONENTES<br />&nbsp;</MenuLink>
            speakersLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">PONENTES<br />&nbsp;</MenuLink>
            break;
        case "2": // UROVI2021
            speakersLink = <MenuLink mobile="false" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">PONENTES<br />&nbsp;</MenuLink>
            speakersLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">PONENTES<br />&nbsp;</MenuLink>
            break;
        case "4": // ETEV2021
            speakersLink = <MenuLink mobile="false" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">COORDINADORES<br />PONENTES</MenuLink>
            speakersLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/speakers" activeOnlyWhenExact="true" image="icon-networking">COORDINADORES<br />PONENTES</MenuLink>
            break;

        default:
            speakersLink = null;
            speakersLinkMobile = null;
            break;
    }



    let sponsorsLink;
    let sponsorsLinkMobile;
    switch (props.eventId) {
        case "315":
            sponsorsLink = <MenuLink mobile="false" to="/congress/sponsors" activeOnlyWhenExact="true" image="icon-networking">COLABORADORES<br />&nbsp;</MenuLink>
            sponsorsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/sponsors" activeOnlyWhenExact="true" image="icon-networking">COLABORADORES<br />&nbsp;</MenuLink>
            break;
        case "378":
            sponsorsLink = <MenuLink mobile="false" to="/congress/sponsors" activeOnlyWhenExact="true" image="icon-industry">PATROCINADORES<br />&nbsp;</MenuLink>
            sponsorsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/sponsors" activeOnlyWhenExact="true" image="icon-industry">PATROCINADORES<br />&nbsp;</MenuLink>
            break;
        default:
            sponsorsLink = null;
            sponsorsLinkMobile = null;
            break;
    }



    let previouseditionsLink;
    let previouseditionsLinkMobile;
    switch (props.eventId) {
        case "2":
            previouseditionsLink = <MenuLink mobile="false" to="/congress/previous-editions" activeOnlyWhenExact="true" image="icon-eposters">EDICIONES<br />ANTERIORES</MenuLink>
            previouseditionsLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/previous-editions" activeOnlyWhenExact="true" image="icon-eposters">EDICIONES<br />ANTERIORES</MenuLink>
            break;
        default:
            previouseditionsLink = null;
            previouseditionsLinkMobile = null;
            break;
    }



    let societiesLink;
    let societiesLinkMobile;
    switch (props.eventId) {
        case "378":
            societiesLink = <MenuLink mobile="false" to="/congress/societies" activeOnlyWhenExact="true" image="icon-networking">SOCIEDADES<br />&nbsp;</MenuLink>
            societiesLinkMobile = <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/societies" activeOnlyWhenExact="true" image="icon-networking">SOCIEDADES<br />&nbsp;</MenuLink>
            break;
        default:
            societiesLink = null;
            societiesLinkMobile = null;
            break;
    }

    let logoutLinkMobile;
    logoutLinkMobile = <div onClick={props.logoutHandler} class="mobile-menu-entry-wrapper"><a href="#"><img alt="" src="/img/icon-logout.png" /><br />LOGOUT<br />&nbsp;</a></div >




    let menuOrder;
    let menuOrderMobile;

    if (props.eventId === "3") {
        menuOrder = <div>{lobbyscientificLink}{programmeLink}</div>;
        menuOrderMobile = <div>{lobbyscientificLinkMobile}{programmeLinkMobile}{logoutLinkMobile}</div>;
    }
    else if (props.eventId === "4") {
        menuOrder = <div>{posterLink}{programmeLink}{speakersLink}{lobbyscientificLink}</div>;
        menuOrderMobile = <div>{posterLinkMobile}{programmeLinkMobile}{speakersLinkMobile}{lobbyscientificLinkMobile}{logoutLinkMobile}</div>;
    }
    else {
        menuOrder = <div>{lobbyscientificLink}{oralpapersLink}{posterLink}{snapshotsLink}{casereportsLink}{guestsocietyLink}{exhibitionLink}{sponsorhipLink}{networkinLink}{juniorRoomLink}{ESTSHouseLink}{videopillsLink}{programmeLink}{speakersLink}{sponsorsLink}{previouseditionsLink}{societiesLink}{networkinCuencaLink}</div>;
        menuOrderMobile = <div>{lobbyscientificLinkMobile}{oralpapersLinkMobile}{posterLinkMobile}{snapshotsLinkMobile}{casereportsLinkMobile}{guestsocietyLinkMobile}{exhibitionLinkMobile}{sponsorhipLinkMobile}{networkinLinkMobile}{juniorRoomLinkMobile}{ESTSHouseLinkMobile}{videopillsLinkMobile}{programmeLinkMobile}{speakersLinkMobile}{sponsorsLinkMobile}{previouseditionsLinkMobile}{societiesLinkMobile}{networkinCuencaLinkMobile}{logoutLinkMobile}</div>;
    }



    return (

        <div>



            {MobileMenuOpen == true ?

                <div className="mobile-menu-container">


                    {props.eventId === "3" || props.eventId === "4" || props.eventId === "5" ?
                        <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/lobby" activeOnlyWhenExact="true" image="icon-lobby" >HOME</MenuLink>
                        : props.eventId === "408" || props.eventId === "410" ?
                            null
                            :
                            <MenuLink closeMobile={mobileMenuOpen} mobile="true" to="/congress/lobby" activeOnlyWhenExact="true" image="icon-lobby" >{t('menu_lobby')}</MenuLink>
                    }

                    {menuOrderMobile}



                </div>

                : null}

            <div className="menu-container">
                <div className="menu-items-wrapper">
                    <div className="menu-items-desktop">

                        {props.eventId === "3" || props.eventId === "4" || props.eventId === "5" ?
                            <MenuLink mobile="false" to="/congress/lobby" activeOnlyWhenExact="true" image="icon-lobby" >HOME</MenuLink>
                            : props.eventId === "408" || props.eventId === "410" ?
                                null
                                :
                                <MenuLink mobile="false" to="/congress/lobby" activeOnlyWhenExact="true" image="icon-lobby" >{t('menu_lobby')}</MenuLink>
                        }

                        {menuOrder}
                    </div>
                    <div className="menu-items-mobile">
                        <div className="menu-mobile-btn" onClick={() => mobileMenuOpen()}>
                            <img src="/img/mobile-menu-bars.jpg" />
                        </div>

                    </div>
                </div>

            </div>
        </div>

    );
}




function MenuLink(props) {


    let match = useRouteMatch({
        path: props.to,
        exact: props.activeOnlyWhenExact
    });

    let menuimage;

    if (match) {
        menuimage = "/img/" + props.image + "-selected.png";
    }
    else {
        menuimage = "/img/" + props.image + ".png";
    }


    if (props.mobile == "true") {
        return (
            <div className={match ? "mobile-menu-entry-wrapper active" : "mobile-menu-entry-wrapper"}>
                <Link onClick={props.closeMobile} to={props.to}><img src={menuimage} alt="" /><br />{props.children}</Link>
            </div>
        );
    }
    else {
        return (
            <div className={match ? "menu-item active" : "menu-item"}>
                <Link to={props.to}><img src={menuimage} alt="" /><br />{props.children}</Link>
            </div>
        );
    }


}


export default Nav;

