import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EposterSearch from './EposterSearch/EposterSearch';
import EposterDetail from './EposterDetail/EposterDetail';
import axios from 'axios';
import EposterCategories from './EposterCategories/EposterCategories';
import EpostersList from './EpostersList/EpostersList';
import AuthContext from "../../../context/auth-context";
import configData from '../../../config.json';
import EposterDiscussion from '../../../components/EposterDiscussion/EposterDiscussion';

class Eposters extends Component {

    static contextType = AuthContext;

    state = {
        categories: [],
        eposters: [],
        categoryName: null,
        categoryBckColor: null,
        categoryId: "",
        searchText: "",
        mostViewed: false,
        bestRated: false,
        mostCommented: false,
        posterDiscussionModal: false
    }

    search = {
        searchType: "",
        searchText: "",
        categoryId: "",
        mostViewed: false,
        bestRated: false,
        mostCommented: false
    }

    searchTextHandler = (event) => {
        this.setState({
            searchText: event.target.value,
            mostViewed: false,
            bestRated: false,
            mostCommented: false
        });
    }

    mostViewedHandler = () => {
        this.setState((prevState, props) => {
            return {
                mostViewed: !prevState.mostViewed
            };
        })
    }

    bestRatedHandler = () => {
        this.setState((prevState, props) => {
            return {
                bestRated: !prevState.bestRated
            };
        })
    }

    mostCommenteddHandler = () => {
        this.setState((prevState, props) => {
            return {
                mostCommented: !prevState.mostCommented
            };
        })
    }

    cleanSearchHandler = () => {
        this.setState({ searchText: "" })
    }

    cleanCategoryFilterHandler = () => {
        this.setState({
            categoryId: "",
            categoryName: null,
            categoryBckColor: null
        })
    }


    categorySelectHandler = (event) => {


        let filterId = "";

        if (event.target.id == "select") {
            filterId = event.target.value;
        }
        else {
            filterId = event.target.id;
        }

        let catSelected = this.state.categories.filter((category) => {
            return category.id == filterId;

        })
        this.setState({
            categoryId: catSelected[0].id,
            categoryName: catSelected[0].title,
            categoryBckColor: catSelected[0].color
        })
    }


    componentDidMount() {

        document.getElementById("search-eposter").focus();

        //alert(this.props.type);

        const type = this.props.type !== undefined ? this.props.type : "";

        let data;

        let params = {};
        if (type !== '') {
            this.search.searchType = type;
            params = { type: type, active: true };
        }

        data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: params
        };

        axios.get(configData.SERVER_API + "/posters-categories/", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    if (response.data.length == 1) {
                        this.setState({
                            categories: response.data,
                            categoryId: response.data[0].id,
                            categoryName: response.data[0].title,
                            categoryBckColor: response.data[0].color
                        });
                    }
                    else {
                        this.setState({
                            categories: response.data
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
            })
    }


    componentDidUpdate() {

        const type = this.props.type !== undefined ? this.props.type : "";

        const type_changed = type !== this.search.searchType ? true : false;

        let api;
        if (type_changed) {
            api = "/posters-categories/"
            this.setState({
                categoryId: "",
                categoryName: null,
                categoryBckColor: null,
                searchText: "",
                mostViewed: false,
                bestRated: false,
                mostCommented: false,
            })

        } else if (
            this.state.searchText !== "" ||
            this.state.categoryId !== "" ||
            this.state.bestRated ||
            this.state.mostCommented ||
            this.state.mostViewed
        ) {
            if (this.state.categoryId && this.state.categoryId !== "") {
                api = "/posters-categories/" + this.state.categoryId + "/posters";
            } else {
                api = "/posters/";
            }
        } else {
            api = "/posters-categories/"
        }

        let params = {};
        params = { text: this.state.searchText, active: true };

        if (type !== "") {
            params.type = type;
            this.search.searchType = type;
        }

        if (this.state.mostViewed) params.views = 1;
        if (this.state.mostCommented) params.comments = 1;
        if (this.state.bestRated) params.rate = 1;

        if ((this.state.eposters === undefined && this.state.eposters.length == 0 && this.state.searchText === "") ||
            this.search.searchText !== this.state.searchText ||
            this.search.categoryId !== this.state.categoryId ||
            this.search.bestRated !== this.state.bestRated ||
            this.search.mostCommented !== this.state.mostCommented ||
            this.search.mostViewed !== this.state.mostViewed ||
            type_changed
        ) {

            let data = {
                headers: {
                    "x-auth-token": this.context.token,
                    "content-type": "application/json",
                },
                params: params
            };

            axios.get(configData.SERVER_API + api, data)
                .then(response => {
                    if (response.data !== undefined && response.data.length > 0) {
                        if (type_changed) {
                            if (response.data.length == 1) {
                                this.setState({
                                    categories: response.data,
                                    categoryId: response.data[0].id,
                                    categoryName: response.data[0].title,
                                    categoryBckColor: response.data[0].color
                                });
                            }
                            else {
                                this.setState({
                                    categories: response.data
                                });
                            }
                        } else {
                            this.setState({ eposters: response.data });
                        }
                    } else {
                        this.setState({ eposters: [] });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
            this.search.searchText = this.state.searchText;
            this.search.categoryId = this.state.categoryId;
            this.search.bestRated = this.state.bestRated;
            this.search.mostCommented = this.state.mostCommented;
            this.search.mostViewed = this.state.mostViewed;
        }
    }


    render() {

        const pagein = window.location.href;
        const renderExtraClass = pagein.includes('/congress/e-posters/');
        if (renderExtraClass) {
            document.getElementById('root').className += " open-poster-detail";
        } else {
            document.getElementById('root').className = "full-container";
            //document.getElementById('root').removeClass('open-poster-detail')
        }

        return (
            <React.Fragment>

                <EposterSearch onSearch={this.searchTextHandler}
                    onMostViewed={this.mostViewedHandler}
                    onBestScored={this.bestRatedHandler}
                    onMoreComments={this.mostCommenteddHandler}
                    categorySelectedName={this.state.categoryName}
                    categoryBackGroundColor={this.state.categoryBckColor}
                    cleanSearch={this.cleanSearchHandler}
                    cleanCategoryFilter={this.cleanCategoryFilterHandler}
                    searchText={this.state.searchText}
                    mostViewed={this.state.mostViewed}
                    bestRated={this.state.bestRated}
                    mostCommented={this.state.mostCommented}
                    eventid={this.context.event_id} />


                <div className="eposter-list-content-container">

                    {this.context.event_id === "314" && this.state.categoryId !== "" ?
                        <EposterDiscussion categoryid={this.state.categoryId}></EposterDiscussion>
                        : null}

                    <Switch>
                        <Route path={this.props.match.url + '/categories/:id'} render={() => <EpostersList eventid={this.context.event_id} categoryid={this.state.categoryId} eposters={this.state.eposters} url={this.props.match.url} />} />
                        {/* <Route path={this.props.match.url + '/categories/'} render={() => <EposterCategories selectcategory={this.categorySelectHandler} categories={this.state.categories} />} /> */}
                        <Route path={this.props.match.url + '/:id'} component={EposterDetail} />
                        <Route path={this.props.match.url + '/'} render={() => {
                            if (this.state.searchText !== "" || this.state.categoryId !== "" || this.state.mostViewed || this.state.bestRated || this.state.mostCommented) {
                                return <EpostersList eventid={this.context.event_id} eposters={this.state.eposters} url={this.props.match.url} />
                            } else {
                                return (
                                    <React.Fragment>
                                        <EposterCategories eventid={this.context.event_id} selectcategory={this.categorySelectHandler} categories={this.state.categories} />
                                    </React.Fragment>
                                )
                            }
                        }} />
                        <Route path="*">
                            <Redirect to={{ pathname: '/congress' }} />
                        </Route>
                    </Switch>
                </div>

                {this.context.event_id === "326" ?
                    <div className="e-posters-sponsor-container">
                        <div className="eposter-intro-text-container">
                            Espacio patrocinado por &nbsp;&nbsp;&nbsp;
                            <img alt="" src="/img/CryoLife-Logo-Horizontal-Blue-300dpi.jpg" />
                        </div>
                    </div>
                    : null}

            </React.Fragment>
        );
    }
}

export default Eposters;