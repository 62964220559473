import React from 'react';

function SessionFile(props) {
    return (
        <div className="econgress-room-files-container">

            <div className="econgress-room-files-area-container">
                <table border="0" cellspacing="0" cellpadding="0" className="tbl-econgress-room-files">
                    <tbody>

                    <tr>
                        <td className="session-time">09:00</td>
                        <td className="session-title" colspan="4">LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>

                    <tr>
                        <td className="session-time">09:00</td>
                        <td className="session-title" colspan="4">LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>


                    <tr>
                        <td className="session-time">09:00</td>
                        <td className="session-title" colspan="4">LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td className="presentation-title">Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla. Aenean sapien elit, eleifend vitae dapibus eu, facilisis a arcu.</td>
                        <td className="presentation-filetype">PDF</td>
                        <td className="presentation-size">1,6MB</td>
                        <td className="presentation-download">download</td>
                    </tr>
                    </tbody>

                </table>
            </div>

        </div>

    );
}

export default SessionFile;