

export const PrivacyPolicyVITDAH = `
<p><strong>1. ¿Quién es el Responsable del Tratamiento de los datos personales?</strong><br>A continuación, encontrarás la información registral y los datos de contacto de la sociedad Responsable del tratamiento de sus datos personales, así como de su Delegado de Protección de Datos (en adelante, “DPD”).</p>
<p style="padding-left: 40px;">Identidad: Laboratorios Farmacéuticos ROVI, SA. (Rovi) – CIF: A-28041283.</p>
<p style="padding-left: 40px;">Dirección postal: Julián Camarillo, 35- 28037 Madrid<br>Teléfono: 91 375 62 30.<br>Correo electrónico: <a href="mailto:protecciondedatos@rovi.es" rel="noopener">protecciondedatos@rovi.es</a>&nbsp;<br>Contacto DPD: <a href="mailto:dporovi@rovi.es" rel="noopener">dporovi@rovi.es</a>&nbsp;</p>
<p><strong>2. ¿Qué datos personales tratamos?</strong></p>
<p>ROVI trata los datos facilitados de los Usuarios, y Asistentes (en adelante, “Usuario” o “Usuarios” indistintamente), a través de los distintos formularios y canales habilitados en la Página Web de la que es titular ROVI, así como aquellos que se obtengan durante su navegación, asistencia o participación en los eventos organizados o patrocinados por ROVI. En cualquier caso, tratamos exclusivamente los datos personales facilitados para las finalidades indicadas en la presente Política de Privacidad.</p>
<p>Salvo que expresamente se indique lo contrario, todos los datos personales solicitados en los formularios habilitados en la Página Web para la recogida de datos son de obligada aportación al ser indispensables y si no los facilita, no podremos atender a su consulta, gestionar su registro o garantizar el correcto funcionamiento de la Página Web. Por tanto, los Usuarios deben cumplimentar los campos marcados como “obligatorios” en los formularios de las distintas Páginas Web, lo contrario podría implicar la imposibilidad de prestar el servicio solicitado o un incorrecto funcionamiento de la Página Web.</p>
<p>En caso de facilitar datos de terceros, el Usuario que los facilite garantiza que estos han sido obtenidos y facilitados a ROVI previa información y autorización de los titulares de dichos datos, de conformidad con la normativa en materia de protección de datos. A este respecto, el Usuario <br>mantendrá indemne a ROVI de cualquier responsabilidad derivada de la inobservancia de esta garantía.</p>
<p>El Usuario confirma y garantiza la veracidad y exactitud de los datos aportados, y que éstos se ajustan a su estado actual. En este sentido, el Usuario se compromete a comunicar cualquier modificación que se produjera en los mismos, de acuerdo con el procedimiento establecido en el apartado séptimo de la presente Política de Privacidad.</p>
<p><strong>3. ¿Con qué finalidad tratamos los datos personales? ¿Cuál es la legitimación para el tratamiento de los datos?</strong><br>El tratamiento de los datos personales variará en función de la Página Web que utilice, ya que cada una actúa de forma independiente y no todas tienen las mismas funcionalidades ni ofrecen los mismos servicios.</p>
<p><strong>Páginas Web de Eventos y reuniones Científicas y Profesionales:</strong></p>
<ul>
<li>ROVI tratará los datos de los Usuarios a las distintas reuniones científicas y profesionales organizados y/o patrocinados por la Compañía con las siguientes finalidades:
<ul>
<li>Permitir el acceso a la Web y a sus contenidos, y en caso de que sea necesario, gestionar el registro reuniones científicas y profesionales.</li>
<li>Gestionar la asistencia de los mismos, incluida la emisión del correspondiente certificado de asistencia en su caso.</li>
<li>Gestionar la retransmisión de las reuniones en formato streaming. En caso de que la retransmisión en streaming se lleve a cabo a través de un proveedor externo que integra el servicio de video de la página web del evento pertinente, sus datos se tratarán con la finalidad de permitir dicha retransmisión.</li>
<li>La participación de los Asistentes en el evento (televoto y/o preguntas y/o consultas) así como la evaluación de la calidad del evento mediante encuestas.</li>
</ul>
</li>
</ul>
<p>La base que legitima el tratamiento de estos datos es el consentimiento facilitado por el Usuario al completar su registro libre y voluntariamente, así como el interés legítimo de ROVI en responder a las consultas remitidas por los Asistentes.</p>
<ul>
<li>Igualmente, con base en su interés legítimo, ROVI enviará a los Asistentes comunicaciones electrónicas relacionadas con el evento o eventos en los que se hayan registrado. Si el Asistente lo consiente marcando la casilla correspondiente, ROVI enviará comunicaciones electrónicas relacionadas con futuros eventos organizados o patrocinados por ROVI.</li>
<li>En el caso de que los Asistentes faciliten sus datos en el marco de la presentación de casos clínicos o concursos organizados en ROVI, los datos serán tratados para gestionar su participación, lo que puede incluir la entrega de premios y la publicación de sus datos en el sitio web, en redes sociales, o en cualquier medio de difusión utilizado por ROVI con fines de dar publicidad al concurso y en su caso al ganador/ganadores y con fines de transparencia. La base que legitima el tratamiento de los datos es el consentimiento expreso otorgado por los Asistentes que participen en el concurso organizado por ROVI, a través de la aceptación expresa de las bases del concurso.</li>
<li>Gestionar el check in de los profesionales sanitarios en los hoteles donde se vayan a alojar como consecuencia de su asistencia al evento correspondiente. El suministro de los datos con este fin es obligado, impidiéndose en otro caso que sus datos puedan ser comunicados al hotel que corresponda para que formalice el check in y disponga de la reserva de una habitación.</li>
</ul>
<p>La mera navegación por la Página Web no implicará el tratamiento de datos personales de los Usuarios, salvo los relativos a su dirección IP o MAC (en caso de que se acceda con un dispositivo móvil) y datos relativos a la navegación por la misma, con la finalidad de gestionar y optimizar la Página Web, así como para preservar la seguridad e integridad de ésta. Dicho tratamiento se basa en el interés legítimo de ROVI de garantizar el buen&nbsp; uncionamiento de la Página Web y la seguridad de la red y de la información.</p>
<p><strong>4. ¿Por cuánto tiempo se conservarán los datos?</strong></p>
<p>Los datos personales recabados con la finalidad de atender una solicitud o petición de un determinado trámite serán conservados durante el tiempo necesario para atender dicha solicitud o petición.</p>
<p>Por su parte los datos personales recabados como consecuencia del registro en la Página Web se conservarán mientras no se dé de baja dicho registro, ya sea a petición del propio Usuario o como consecuencia de la finalización de la relación que unía a éste con la Compañía.</p>
<p>Por último, los datos personales recabados a través de la Página Web, en el marco de un evento de ROVI, serán conservados durante el tiempo necesario para gestionar su registro, asistencia y participación en dicho evento ROVI.</p>
<p>Una vez transcurridos los plazos anteriores tramitadas todas las acciones necesarias para gestionar y dar por concluida cualquier obligación que pudiera quedar remanente entre las partes, se procederá a la eliminación de los datos personales. Sin perjuicio de lo anterior, los datos serán conservados debidamente bloqueados, mientras puedan derivarse responsabilidades.</p>
<p><strong>5. ¿A qué destinatarios se comunicarán los datos?</strong></p>
<p>ROVI podrá comunicar sus datos personales a las Autoridades Públicas, reguladores u órganos gubernamentales o jurisdiccionales en aquellos supuestos en que es obligado hacerlo por ley, normativa local o en el cumplimiento de obligaciones regulatorias. Igualmente, en el marco de <br>eventos y reuniones científico profesionales patrocinados por ROVI, se podrán comunicar sus datos a las entidades patrocinadas en cada caso.</p>
<p>Al margen de las anteriores comunicaciones de datos, ROVI cuenta con la colaboración de algunos terceros proveedores de servicios que tienen acceso a sus datos personales y que tratan los referidos datos en nombre y por cuenta de ROVI como consecuencia de una prestación de <br>servicios.</p>
<p>ROVI sigue unos criterios estrictos de selección de proveedores de servicios con el fin de dar cumplimiento a sus obligaciones en materia de protección de datos y se suscribe con ellos el correspondiente contrato de tratamiento de datos.</p>
<p><strong>6. ¿Se realizan transferencias internacionales?</strong></p>
<p>ROVI podrá contar con terceros colaboradores o prestadores de servicios ubicados fuera del territorio de la Unión Europea o del Espacio Económico Europeo (EEE). En esos casos, ROVI adopta las garantías legales oportunas para permitir el desarrollo de tal trasferencia conforme a la normativa vigente en materia de protección de datos. Usted podrá obtener una copia de las medidas exigidas por ROVI poniéndose en contacto con el Delegado de Protección de Datos.</p>
<p><strong>7. ¿Cuáles son los derechos de los Usuarios cuando facilitan sus datos personales?</strong></p>
<p>El Usuario puede ejercer su derecho de acceso a los datos personales, así como solicitar la rectificación de los datos inexactos o, en su caso, su supresión. También podrá solicitar la limitación, portabilidad y oposición al tratamiento de sus datos.</p>
<p>Igualmente, el Usuario tiene derecho a revocar, en cualquier momento, el consentimiento, en su caso prestado, sin que ello afecte de forma retroactiva al tratamiento de datos personales realizado hasta ese momento.</p>
<p>El Usuario podrá ejercitar los derechos referidos anteriormente, en los términos previstos en la legislación vigente, dirigiendo un escrito a Laboratorios Farmacéuticos Rovi, S.A., c/ Julián Camarillo, número 35, 28037, Madrid o a la dirección de correo electrónico <a href="mailto:protecciondedatos@rovi.es" rel="noopener">protecciondedatos@rovi.es</a> , adjuntando una copia de su DNI u otro documento oficial que acredite su identidad.</p>
<p>Cuando lo considere oportuno, especialmente en el supuesto de que no obtenga una respuesta satisfactoria, podrá formular una reclamación ante a la Agencia Española de Protección de Datos (<a href="http://www.aepd.es" rel="noopener" target="_blank">www.aepd.es</a> - C/ Jorge Juan, 6 de Madrid).</p>
<p><strong>8. Contacta con ROVI</strong></p>
<p>En caso de dudas o cuestiones respecto al tratamiento de los datos personales que realiza ROVI, el Usuario puede ponerse en contacto con el Delegado de Protección de Datos de la Sociedad en la siguiente dirección de correo electrónico: <a href="mailto:dporovi@rovi.es">dporovi@rovi.es</a></p>
<p>Última actualización: 12 de febrero de 2021.</p>

`