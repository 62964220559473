import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';

function NetworkingSocialMedia(props) {

    return (

        <div className="networking-content-wrapper">
            <div className="networking-social-media-video-container">

                <div className="networking-social-media-video-frame-area-container">

                    {/* 3 social media */}

                    <div className="networking-social-media-entry">
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="NovartisSpain"
                            autoHeight={true}
                        />
                    </div>
                    <div className="networking-social-media-separator"></div>
                    <div className="networking-social-media-entry facebook">
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fseparrespira%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                            width="366" height="460" style={{ border: `none;overflow:hidden` }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                    <div className="networking-social-media-separator">
                        <InstagramEmbed
                            url='"https://www.instagram.com/p/CASprIDgOOO/?utm_source=ig_embed&amp;utm_campaign=loading'
                            clientAccessToken='123|456'
                            maxWidth={320}
                            hideCaption={false}
                            containerTagName='div'
                            protocol=''
                            injectScript
                            onLoading={() => { }}
                            onSuccess={() => { }}
                            onAfterRender={() => { }}
                            onFailure={() => { }}
                        />
                    </div>
                    <div className="networking-social-media-entry">
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="SeparRespira"
                            autoHeight={true}
                        />
                    </div>



                    {/* 2 social media */}

                    {/* <div className="networking-social-media-entry">
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnike&tabs=timeline&width=366&height=465&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="366" height="465" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>   
                    </div>

                    <div className="networking-social-media-separator"></div>

                    <div className="networking-social-media-entry">
                        <div className="networking-social-media-entry-position">
                            <img src="/img/congress-image-example.png" />
                        </div>
                    </div>

                    <div className="networking-social-media-separator"></div>

                    <div className="networking-social-media-entry">
                        <a className="twitter-timeline" data-width="366" data-height="465" data-theme="light" href="https://twitter.com/Nike?ref_src=twsrc%5Etfw">Tweets by Nike</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div> */}



                    {/* 1 social media */}

                    {/* <div className="networking-social-media-entry">
                        <div className="networking-social-media-entry-position">
                            <img src="/img/congress-image-example.png" />
                        </div>
                    </div>

                    <div className="networking-social-media-separator"></div>

                    <div className="networking-social-media-entry">
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnike&tabs=timeline&width=366&height=465&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="366" height="465" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>   
                    </div>

                    
                    <div className="networking-social-media-separator"></div>

                    <div className="networking-social-media-entry">
                        <div className="networking-social-media-entry-position">
                            <img src="/img/congress-image-example.png" />
                        </div>
                    </div> */}


                </div>


                <div className="networking-company-logo-container">
                    {props.eventId == "1" ?
                        null
                        :
                        <img src="https://www.emma.events/static/virtualvenue/separ2020/networking-lounge/logo-novartis-white-networking.png" />
                    }
                </div>


            </div>
        </div>

    );
}

export default NetworkingSocialMedia;