import React, { Component } from 'react';
import AuthContext from '../../context/auth-context';
import axios from "axios";
import configData from '../../config.json';
import { Link } from 'react-router-dom';

class Question extends Component {
    
    state = {
        recalculate: false,
        question:{}
    }
    static contextType = AuthContext;
    
    componentDidMount(){
        this.loadQuestion(this.props.match.params.sessionId, this.props.match.params.questionId);            
    }

    loadQuestion = (session_id, question_id) => {
        console.log(this.context.token)
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {recalculate:this.state.recalculate}
        };
        
        axios.get(configData.SERVER_API + "/sessions/" + session_id + "/questions/" + question_id, data)
            .then(response => {                
                this.setState({question: response.data !== undefined && response.data !== null && response.data.length > 0 ? response.data[0] : []});
            })
            .catch(error => {
                console.log(error.message);
            })
    }
    
    render() {

        //console.log(this.state.question)

        const _id = this.state.question._id
        const title = this.state.question.question;
        const open = false;
        const show_correct = this.state.question.show_correct;
        const show_results = true;
        const show_count = this.state.question.show_count !== undefined ? this.state.question.show_count : true;
        const show_percentage = this.state.question.show_percentage !== undefined ? this.state.question.show_percentage : true;
        

        let total_votes = 0;
        if (this.state.question.votes !== undefined) {
            this.state.question.votes.forEach(element => {
                total_votes += element.total;
            });
        }

        const options = this.state.question.options !== undefined ? this.state.question.options.map((el, index) => {
            
            // count the number of votes and calculate the percentages
            const votes = this.state.question.votes !== undefined ? this.state.question.votes.filter(element => element.id === el.id) : undefined;
            const percentage = votes !== undefined && votes.length > 0 && total_votes > 0 ?  (votes[0].total / total_votes * 100).toFixed(1) : 0;

            // contruct the label to be displayed
            const votes_label = show_count 
                ? (votes !== undefined && votes.length > 0) ? '(' + votes[0].total + ')' : '(' + 0 + ')'
                : null;

            const percentage_label = show_percentage 
                ? percentage + '%' 
                : null;

            // build the final string to be rendered, depending on the settings in the database
            const results = show_results 
                ? <div className="bar-percentage" style={{width: percentage + `%`}}><b>{percentage_label} {votes_label}</b></div> 
                : null

                
            if (open){
                return (
                    <div key={el.id}>
                        <p><label><input type="radio" name={_id} value={el.id} onClick={() => this.onSelectOptionHandler(el.id)} /> {el.option} </label></p>
                        {results}
                    </div>
                )
            } else {
                return (                    
                    <div key={el.id}>                        
                        <p>{ el.correct !== undefined && el.correct && show_correct ? <img src="/img/chat-pic-checked.png" /> : null} <label>{el.option} </label></p>
                        {results}
                    </div>                    
                )
            }
        }) : null;

        return (
            <div className="econgress-admin-question-container">
                <p className="econgress-admin-question-title">{title}</p>
                {options}
                {open ?
                <div className="econgress-room-right-panel-btn" onClick={this.onVoteHandler}>VOTAR</div>
                : null}
            </div>
        );
    }
}

export default Question;