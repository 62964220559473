import React, { Component } from 'react';
import axios from "axios";
import configData from '../../config.json';
import AuthContext from "../../context/auth-context";

class SessionAgenda extends Component {

    state = {
        photos: []
    }


    componentDidMount() {

        /* const listOfIds = ["87034","107847","107848"]
        
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: { "ids": listOfIds }
        };

        axios.get(configData.SERVER_API + "/users/photos", data)
            .then(response => {
                console.log(response)
                //this.setState({ photos: response.data })
            })
            .catch(error => {
                console.log(error.message);
            }) */
    }

    render() {

        const moderators = this.props.moderators !== undefined && this.props.moderators.length > 0 ?
            this.props.moderators.map((el) => {
                return (
                    <React.Fragment>
                        <div style={{ clear: `both` }}></div>
                        <div className="econgress-room-agenda-separator"></div>

                        <div className="presentation-time">10:00 // 10:15</div>
                        <div className="presentation-wrapper">
                            <div className="presenter-avatar"><img alt="" src="/img/chat-person-pic4.png" /></div>
                            <div className="presenter-name">
                                <strong>PERSON NAME</strong><br />Hospital/Company
                            </div>
                            <div style={{ clear: `both` }}></div>
                            <div className="presentation-title">
                                Praesent et purus nec dui tempus posuere. Integer bibendum cursus nulla.
                            </div>
                        </div>
                    </React.Fragment>
                )
            })
            : null;

        const speakers = this.props.agenda.map((el) => {

            // get the time
            let speaker_time;
            if (el.start_time !== "" || el.end_time !== "") {
                speaker_time = el.start_time + " // " + el.end_time
            } else {
                speaker_time = <span>&nbsp;</span>
            }

            // get the lastest video 
            let latestVideoId;
            if (el.videos !== undefined && el.videos.length > 0) {
                const latestVideo = el.videos[el.videos.length - 1];
                latestVideoId = latestVideo.id;
            }

            // get the photo
            const photo = el.photo !== undefined && el.photo !== ""
                ? <img alt="" src={el.photo} />
                : <img alt="" src="/img/default-login-pic.png" />

            return (
                <React.Fragment key={el.id}>

                    <div style={{ clear: `both` }}></div>
                    <div className="econgress-room-agenda-separator"></div>

                    <div className={'presentation-time ' + this.props.eventCode}> {speaker_time} </div>
                    <div className="presentation-wrapper">
                        {this.props.liveAgenda && latestVideoId !== undefined
                            ? <img alt="" className="presenter-play-button" src="/img/icon-video-gallery.png" onClick={() => this.props.loadVideoHandler(latestVideoId)} />
                            : null}
                        <div className="presenter-avatar">
                            {photo}
                        </div>
                        <div className="presenter-name">
                            <strong>{el.name + ' ' + el.surname} </strong><br />{el.company}
                        </div>
                        <div style={{ clear: `both` }}></div>
                        <div className="presentation-title">
                            {el.title}
                        </div>
                    </div>
                </React.Fragment>
            )
        })

        return (

            <div className="econgress-room-agenda-container">

                <img alt="" className="agenda-close-button" src="/img/btn-close-badge.png" onClick={this.props.closeAgenda} />
                <div className="session-time">{this.props.timeStart} // {this.props.timeEnd}</div>
                <div className="session-title">{this.props.title}</div>

                {/* {moderators} */}
                {speakers}

            </div>

        );
    }
}

export default SessionAgenda;