import React from 'react';

function MyBagCertificateGroup(props) {
    return (
        <tr>
            <th className="th-icon-type"><img alt="" src={props.icon} /></th>
            <th colSpan="4">{props.name}</th>
        </tr>
    );
}

export default MyBagCertificateGroup;