import React from 'react';

function SessionOverview(props) {
    return (
        <div className="econgress-room-overview-container">

            <div className="sponsored-by">PAROCINADO POR</div>

            <span className="session-type">Free Paper Session</span><br />
            10:00 // 11:00_Sala Platerias (Hotel Puerta del Camino)<br /><br />
            <span className="presentation-title">A PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO</span><br />
            <span className="presentation-authors">B Basso Abad // J Gonzalez de Buitrago Amigo // C Surribas Murillo // M Sánchez Conejero // M Gutierrez Agujetas // MC Cabello Anoya</span>
            <br /><br />
            <span className="presentation-overview">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem sem, feugiat sit amet sodales sed, auctor eget dui. Donec ornare scelerisque neque posuere pulvinar. Sed dictum leo eu risus molestie ut pharetra metus lacinia. Quisque ac diam sit amet velit euismod egestas. Sed ac sagittis mi. Proin eu molestie elit. Fusce sed urna ipsum, at porttitor felis. Phasellus commodo mollis euismod. Sed in eros ac felis convallis dignissim eget nec lorem. Duis faucibus nisi massa. Nam vestibulum molestie lobortis. Integer ac aliquet ipsum. Sed condimentum, risus a rhoncus egestas, justo nibh imperdiet sapien, nec fringilla magna nunc vitae tortor. Nunc vehicula viverra lacinia. Pellentesque feugiat rutrum molestie.</span>
            <br /><br />
            <div className="moderator-wrapper">

                <div className="moderatored-by">SESIÓN MODERADA POR</div>
                <div className="moderator-avatar"><img alt="" src="/img/chat-person-pic4.png" /></div>
                <div className="moderator-name">
                    <strong>NOMBRE PERSONA</strong><br />
                    Hospital/Empresa
                </div>

            </div>
        </div>
    );
}

export default SessionOverview;