import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';

class PreviousEditionsHtml extends Component {

    static contextType = AuthContext;

    state = {
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false
    }

    //componentDidMount() {}
    //componentDidUpdate() {}


    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    render() {

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;


        if (this.context.event_settings.event_id == "2") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/urovi2021/lobby/urovi2021-lobby-bck2.jpg")';
        }

        return (
            <React.Fragment>

                {CookiesPolicyPopUp}
                {LegalNoticePopUp}
                {TermsConditionsPopUp}
                {PrivacyPolicyPopUp}
                <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>






                <div className="programme-speakers-content-container UROVI2021">

                    <div className="previous-editions-row">

                        <div className="previous-editions-entry">

                            <div className="previous-editions-container">


                                <div className="previous-editions-text">
                                    <p><span className="year">2015</span></p>

                                    Nuestro constante interés por promover la formación entre los profesionales sanitarios nos hizo detectar la necesidad de promover un espacio de conocimiento científico y debate en el campo de la urología y sus pacientes.
                                    <br /><br />
                                    De esta forma, en el año 2015 nace en Sevilla el I Congreso UROVI, organizado por Laboratorios ROVI y con el aval científico de la Asociación Española de Urología (AEU); combinación que se ha mantenido a lo largo de todas las ediciones posteriores.
                                    <br /><br />
                                    Bajo la coordinación de los Dres. Víctor Carrero López, José Manuel Cózar Olmo y Bernardino Miñana López pudimos disfrutar de un conjunto de ponencias del más alto nivel que abarcaban los aspectos más característicos de la HBP y de los síntomas del tracto urinario inferior.

                                </div>

                                <div className="previous-editions-img" style={{ backgroundImage: 'url("https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/previous-editions-2015.jpg")' }}>

                                </div>

                            </div>

                        </div>



                    </div>




                    <div className="previous-editions-row">

                        <div className="previous-editions-entry">

                            <div className="previous-editions-container">

                                <div className="previous-editions-img" style={{ backgroundImage: 'url("https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/previous-editions-2016.jpg")' }}>

                                </div>


                                <div className="previous-editions-text">
                                    <p><span className="year">2016</span></p>

                                    Con más ilusión, si cabe, que el año anterior, y tras el interés despertado en la primera edición, celebramos el II Congreso UROVI, que tuvo lugar en Córdoba.
                                    <br /><br />
                                    De nuevo bajo la coordinación de los Dres. Víctor Carrero López, José Manuel Cózar Olmo y Bernardino Miñana López, el programa científico diseñado tuvo un carácter muy práctico e interactivo, por las ponencias planteadas y los talleres sobre HBP y STUI. Otra gran novedad de esta edición fue el concurso de casos clínicos, que aportó dinamismo y favoreció la participación por parte de los asistentes.
                                </div>



                            </div>

                        </div>



                    </div>





                    <div className="previous-editions-row">

                        <div className="previous-editions-entry">

                            <div className="previous-editions-container">



                                <div className="previous-editions-text">
                                    <p><span className="year">2017</span></p>

                                    Llegado el año 2017, se celebró el III Congreso UROVI en Madrid. Se mantuvo el esquema de ponencias de actualidad y talleres participativos que tanto éxito tuvieron en las pasadas ediciones. En esta ocasión, se puso el foco en el tratamiento farmacológico de la HBP o las técnicas quirúrgicas empleadas para sus pacientes complejos, los métodos de diagnóstico para pacientes con cáncer de próstata o el uso de la linfadenectomía en Urología.
                                    <br /><br />
                                    Acompañando este programa de tan elevada calidad, el Dr. Ignacio Medrano, realizó una interesantísima conferencia sobre el impacto que el Big Data tendrá a nivel médico en los próximos años.
                                </div>

                                <div className="previous-editions-img" style={{ backgroundImage: 'url("https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/previous-editions-2017.jpg")' }}>

                                </div>



                            </div>

                        </div>



                    </div>





                    <div className="previous-editions-row">

                        <div className="previous-editions-entry">

                            <div className="previous-editions-container">


                                <div className="previous-editions-img" style={{ backgroundImage: 'url("https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/previous-editions-2018.jpg")' }}>

                                </div>

                                <div className="previous-editions-text">
                                    <p><span className="year">2018</span></p>

                                    Durante el año 2018, tuvo lugar el IV Congreso UROVI, coordinado por los Dres. Víctor Carrero López, José Manuel Cózar Olmo y José Luis Álvarez-Ossorio Fernández.
                                    <br /><br />
                                    En esta edición, las ponencias y talleres preparados permitieron la discusión en cuestiones como el tratamiento en CPRC, cirugía robótica o tratamientos antitrombóticos en pacientes urológicos. Además, pudimos disfrutar de un nuevo formato de mesa redonda, donde se abordó la necesidad de un nuevo cuestionario de valoración de síntomas relacionados con la HBP.
                                </div>




                            </div>

                        </div>



                    </div>






                    <div className="previous-editions-row">

                        <div className="previous-editions-entry">

                            <div className="previous-editions-container">


                                <div className="previous-editions-text">
                                    <p><span className="year">2019</span></p>



                                    El V Congreso UROVI se celebró el pasado 2019 en Madrid. De la mano de los Directores Científicos, recorrimos tres bloques científicos que versaron sobre cirugía, HBP y STUI, y tumores urológicos. Los talleres que se celebraron por la tarde, de temática variada, nos ayudaron a comprender cómo abordar la estenosis de uretra y nos iniciaron en el camino para saber publicar sobre Urología en revistas y redes sociales.
                                    <br /><br />
                                    De hecho, las nuevas tecnologías se abren paso de manera inexorable en prácticamente cada aspecto de nuestra sociedad y vida cotidiana; y sobre ellas y su influencia en la Urología trató la conferencia que acompañaba esta edición.

                                </div>


                                <div className="previous-editions-img" style={{ backgroundImage: 'url("https://emma.events/static/upload/ow122/events/ev387/Site/files/urovi2021/previous-editions-2019.jpg")' }}>

                                </div>




                            </div>

                        </div>



                    </div>





                </div>





            </React.Fragment>
        );
    }
}

export default PreviousEditionsHtml;