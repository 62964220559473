import React from 'react';
import { OTSession, OTStreams, preloadScript } from 'opentok-react';
import Publisher from './Publisher';
import Subscriber from './Subscriber';


class App extends React.Component {
  
  state = {
    error: null,
    connected: false   
  };

  componentDidMount(){
    //console.log('opentok mounted')
  }

  componentDidUpdate(){
    //console.log('opentok updated')
  }

  sessionEvents = {
    sessionConnected: () => {
      this.setState({ connected: true });
    },
    sessionDisconnected: () => {
      this.setState({ connected: false });
    }
  };

  // src/App.js
  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
  }

  render() {

    const screenShareComponent = this.props.screenShare 
      ? <Publisher video={true} 
          audio={true} 
          videoSource={'screen'} />
      : null
    
    return (

        <OTSession
          apiKey={this.props.apiKey}
          sessionId={this.props.sessionId}
          token={this.props.token}
          eventHandlers={this.sessionEvents}
          onError={this.onError} >  
          {this.state.error ? <div id="error">{this.state.error}</div> : null}
          <Publisher  video={this.props.video} 
                      audio={this.props.audio} 
                      name={this.props.name} 
                      picture={this.props.picture} />

          {screenShareComponent}

          <OTStreams>
            <Subscriber />
          </OTStreams>
        </OTSession>
      );
    }

}
export default preloadScript(App);

