import React from 'react';
import i18n from 'i18next';

function withPermission(props) {   

    const userId = props.userId;
    const userEmail = props.userEmail !== undefined ? props.userEmail : "";

    const role = props.role;                                    
    /* os roles disponveis sao 
        participant; 
        participant-full; -> igual ao anterior, mas pode ver todas as sessoes
        participant-pay-per-view; 
        delegate; 
        delegate-full; -> igual ao anterior, mas pode ver todas as sessoes
        delegate-assistant;
        delegate-checker; 
        delegate-stand; 
        secretariat;  --> pode ver todas as sessoes, mesmo que nao esteja configurado*
        press; --> same as participant-full mais o acesso a agenda da sessao */
    
    const objectType = props.objectType;                        // session | stand | networking-lounge -> the objects where to apply the permissions
    const objectSubType = props.objectSubType !== undefined ? props.objectSubType : "";
    const userPermission = props.userPermission;
    const rolePermission = props.rolePermission;
    const objectPermission = props.objectPermission;
    const eventId = props.eventId !== undefined ? props.eventId : ""
    const isPublic = props.isPublic !== undefined ? props.isPublic : true;

    let access = true;
    let res;


    // ############# DEFAULT RULES ##################################################
    
    // --- SESSION ----------------------------------------------------
    let RULE_SESSION_PRIVATE = true;
    let RULE_SESSION_PROFILE = true;

    if (objectType === "session"){     

        // por defeito, session privadas, apenas os users na lista de acccess, podem aceder, 
        // ou então tem um dos seguinte perfis: "delegate-full" ou "participant-full" ou "secretariat"
        if (!isPublic && role !== "secretariat" && role !== "participant-full" && role !== "delegate-full" && role !== "press") {
            RULE_SESSION_PRIVATE = false;
            if (objectPermission !== undefined && objectPermission.length>0){
                const authorized = objectPermission.filter((i) =>{
                    return  (i === userId || i === userEmail)
                });    
                if (authorized.length>0){
                    RULE_SESSION_PRIVATE = true;
                }
            }
        }

        // por defeito, certos perfis nao podem acceder a NENHUMA session, a nao se que estejam nos access
        if (role === "delegate" || role === "delegate-stand" || role === "participant-pay-per-view"){

            RULE_SESSION_PROFILE = false;
            if (objectPermission !== undefined && objectPermission.length>0){
                const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                if (authorized.length>0){
                    RULE_SESSION_PROFILE = true;
                }
            }
        }

        // por defeito o o stand-checker nao ve nenhuma sessao
        if(role==="delegate-stand-checker"){
            RULE_SESSION_PROFILE = false;
        }

    }

    // --- STAND ----------------------------------------------------
    // -- por defeito todos os perfis entram em todos os stands,
    // -- excepto o stand-checker
    let RULE_STAND_PROFILE = true;
    if (objectType === "stand"){  
        
        // por defeito o stand-checker nao acede a nenhum stand, só aquele onde está no access
        // todos os outros perfis tem accesso aos stands
        if(role==="delegate-stand-checker"){
            RULE_STAND_PROFILE = false; 
            if (objectPermission !== undefined && objectPermission.length>0){
                const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                if (authorized.length>0){
                    RULE_STAND_PROFILE = true;
                }
            }    
        }

    }

    // --- NETWORKING LOUNGE ----------------------------------------------------
    // -- por defeito todos os perfis entram no NETWORKING LOUNGE ,
    // -- excepto os delegates que tenha autorização
    let RULE_NETWORKING_PROFILE = true;
    if (objectType === "networking-lounge"){
        
        if(role==="delegate-stand-checker"){
            RULE_NETWORKING_PROFILE = false; // stand-checher cannot not access the networking
        }

        // por defeito nenhum delegado pode aceder ao networking lounge
        //  a nao ser que esteja nos access (por agora esta hard-coded)
        if(role==="delegate-full" || role==="delegate-stand" || role==="delegate-assistant" || role==="delegate-congress"){
            RULE_NETWORKING_PROFILE = false; 
            if (objectPermission !== undefined && objectPermission.length>0){
                const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                if (authorized.length>0){
                    RULE_NETWORKING_PROFILE = true;
                }
            }    
        }

        // se a sessao for privada, só acedem mesmo os utilizados que estao no object Persmission
        //  a nao ser que esteja nos access (por agora esta hard-coded)
        if(!isPublic){
            RULE_NETWORKING_PROFILE = false; 
            if (objectPermission !== undefined && objectPermission.length>0){
                const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                if (authorized.length>0){
                    RULE_NETWORKING_PROFILE = true;
                }
            }    
        }
        

    }


    // ############# CUSTOM RULES BY EVENT ##################################################
    // -- overrights the other rules
    
    // ESTS 2020 = 358
    if( eventId === "358" ){

        if (objectType === "session"){
            
            if (role === "participant-pay-per-view"){   // only particpant-pay-per-view have restrictions at the session level    
                RULE_SESSION_PROFILE = false;
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }
        }

        if (objectType === "stand"){            

            if(role==="delegate-stand" || role==="delegate"){       // the delegates are not allowed into other companies stands 
                RULE_STAND_PROFILE = false;                          // delegates can only access stands where they are in access
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_STAND_PROFILE = true;
                    }
                }
            }
    
        }

    } 

    // SEAIC : 353
    // LAHRS : 363
    if( eventId === "353" || eventId === "363"){    
        
        // everyone can access everything no SEAIC & LAHRS,
        // excepto o stand-checker
        if(role !=="delegate-stand-checker"){
            RULE_SESSION_PRIVATE = true;
            RULE_SESSION_PROFILE = true;
            RULE_STAND_PROFILE = true;
            RULE_NETWORKING_PROFILE = true;
        }
    }

    // NEUMO : 379
    if( eventId === "379"){    
        
        // delegate-congress can access everything
        if(role ==="delegate-congress"){
            RULE_SESSION_PRIVATE = true;
            RULE_SESSION_PROFILE = true;
            RULE_STAND_PROFILE = true;
            RULE_NETWORKING_PROFILE = true;
        }
    }

    // ESTS = 358
    if( eventId === "342" ){

        RULE_SESSION_PROFILE = true;

        /* if (objectType === "session"){
            
            if (role === "participant-pay-per-view"){   // only particpant-pay-per-view have restrictions at the session level    
                RULE_SESSION_PROFILE = false;
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId);
                    
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }
        } */
    }


    // SEMNIM = 376
    if( eventId === "376" ){
        if (objectType === "session"){    
            
            // por defeito todos podem ver todas as sessoes que não sao privadas. 
            RULE_SESSION_PROFILE = true;
            
            // por defeito o o stand-checker nao ve nenhuma sessao
            if(role==="delegate-stand-checker"){
                RULE_SESSION_PROFILE = false;
            }
        }
    }


    // SEAVIRTUAL = 391
    if( eventId === "391" ){
        if (objectType === "session"){    
            
            // por defeito todos podem ver todas as sessoes que não sao privadas. 
            RULE_SESSION_PROFILE = true;
            
            // por defeito o o stand-checker nao ve nenhuma sessao
            if(role==="delegate-stand-checker"){
                RULE_SESSION_PROFILE = false;
            }
        }
    }


    // ESTS 2021 = 341
    if( eventId === "341" ){

        if (objectType === "session"){

            // por defeito, os delegados tem acceso a tudo, excepto as sessoes tipo "Industry Session" que noa tem a nenhuma, excepto se estiverem nas permissoes
            if (role === "delegate" || role === "delegate-stand"){
                RULE_SESSION_PROFILE = true;
                if (objectSubType === "Industry Session"){
                    RULE_SESSION_PROFILE = false;
                }
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }


        }

        if (objectType === "stand"){            

            if(role==="delegate-stand" || role==="delegate" || role==="delegate-full"){         // the delegates are not allowed into other companies stands 
                RULE_STAND_PROFILE = false;                                                     // delegates can only access stands where they are in access
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_STAND_PROFILE = true;
                    }
                }
            }
    
        }

    }

    // EACMFS : 263
    // ESTS 2021 = 341
    if( eventId === "263" ){

        if (objectType === "session"){

            // por defeito, os delegados tem acceso a tudo, excepto as sessoes tipo "Industry Session" que noa tem a nenhuma, excepto se estiverem nas permissoes
            if (role === "delegate" || role === "delegate-stand"){
                RULE_SESSION_PROFILE = true;
                if (objectSubType === "Industry Symposium"){
                    RULE_SESSION_PROFILE = false;
                }
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }


        }

        if (objectType === "stand"){            

            if(role==="delegate-stand" || role==="delegate" || role==="delegate-full"){         // the delegates are not allowed into other companies stands 
                RULE_STAND_PROFILE = false;                                                     // delegates can only access stands where they are in access
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_STAND_PROFILE = true;
                    }
                }
            }
    
        }

    }

    // SECEC 2021 = 351
    if( eventId === "351" ){

        if (objectType === "session"){

            // por defeito, os delegados tem acceso a tudo, excepto as sessoes tipo "Industry Session" que noa tem a nenhuma, excepto se estiverem nas permissoes
            if (role === "delegate" || role === "delegate-stand"){
                RULE_SESSION_PROFILE = true;
                if (objectSubType === "Industry Symposium"){
                    RULE_SESSION_PROFILE = false;
                }
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }


        }

    }


    // LAHRS 2021 = 405
    if( eventId === "405" ){

        if (objectType === "session"){

            // por defeito, os delegados tem acceso a tudo, excepto as sessoes tipo "Industry Session" que noa tem a nenhuma, excepto se estiverem nas permissoes
            if (role === "delegate" || role === "delegate-stand"){
                RULE_SESSION_PROFILE = true;
                if (objectSubType === "Industry Symposium"){
                    RULE_SESSION_PROFILE = false;
                }
                if (objectPermission !== undefined && objectPermission.length>0){
                    const authorized = objectPermission.filter((i) => i === userId || i === userEmail);
                    if (authorized.length>0){
                        RULE_SESSION_PROFILE = true;
                    }
                }
            }


        }

    }



    if( eventId === "320" ){    
        
        // everyone can access everything no CUENCA 2021
        // excepto o stand-checker
        if(role !=="delegate-stand-checker"){
            RULE_SESSION_PRIVATE = true;
            RULE_SESSION_PROFILE = true;
        }
    }



    access = RULE_SESSION_PRIVATE 
                & RULE_SESSION_PROFILE 
                & RULE_STAND_PROFILE 
                & RULE_NETWORKING_PROFILE;


    // build message output ---
    let message;
    if( objectType === "session"){
        message = i18n.t('access_denied_session');
    }
    if( objectType === "stand"){
        message = i18n.t('access_denied_stand');
    }
    if( objectType === "networking-lounge"){
        message = i18n.t('access_denied_network');
    }
    // build message output ---


    // OUTPUT ---------------------------------------------------------
    if (access){
        res = props.children
    } else {
        res =  (<div className="access-denied">
                    {i18n.t('access_denied')}
                    <div className="sub-title">{message}</div>
                </div>)
    }
    // OUTPUT ---------------------------------------------------------

    
    return (
        res
    );
}

export default withPermission;


