import React from 'react';

function ChatSideBar(props) {
    return (
        <div className="chat-side-bar">

            <div className="chat-side-bar-header">
                <div className="chat-side-bar-header-title">PEOPLE</div>
                <div className="chat-side-bar-header-add-people">
                    <img alt="" src="/img/icon-add-person-chat.png" />
                </div>
            </div>

            <div className="chat-people-area">
                
                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-online.png" />
                    </div>
                    <div className="chat-person-pic">
                        <div className="chat-person-pic-checked"><img alt="" src="/img/chat-pic-checked.png" /></div>
                        <img alt="" src="/img/chat-person-pic1.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>

                <div style={{clear:`both`}}></div>

                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-online.png" />
                    </div>
                    <div className="chat-person-pic">
                        <div className="chat-person-pic-checked"><img alt="" src="/img/chat-pic-checked.png" /></div>
                        <img alt="" src="/img/chat-person-pic2.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>

                <div style={{clear:`both`}}></div>

                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-offline.png" />
                    </div>
                    <div className="chat-person-pic">
                        <img alt="" src="/img/chat-person-pic3.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>

                <div style={{clear:`both`}}></div>

                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-online.png" />
                    </div>
                    <div className="chat-person-pic">
                        <img alt="" src="/img/chat-person-pic4.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>

                <div style={{clear:`both`}}></div>

                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-online.png" />
                    </div>
                    <div className="chat-person-pic">
                        <img alt="" src="/img/chat-person-pic4.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>

                <div style={{clear:`both`}}></div>

                <div className="chat-person-wrapper">
                    <div className="chat-person-online">
                        <img alt="" src="/img/chat-person-online.png" />
                    </div>
                    <div className="chat-person-pic">
                        <img alt="" src="/img/chat-person-pic4.png" />
                    </div>
                    <div className="chat-person-text">
                        <strong>PERSON NAME</strong><br />Hospital/Company
                    </div>
                </div>
                
                <div style={{clear:`both`}}></div>

            </div>


        </div>
    );
}

export default ChatSideBar;