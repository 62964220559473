import React from 'react';
import { useTranslation } from 'react-i18next';

function CookiesPolicy(props) {

    const { t, i18n } = useTranslation();

    return (

        props.event_id === "387" || props.event_id === "2" || props.event_id === "3" || props.event_id === "4" || props.event_id === "5" ?

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container">


                    <h3><strong>POLÍTICA DE COOKIES</strong></h3>

                    <p>Laboratorios Farmacéuticos Rovi S.A, a través de esta página web (en adelante, la “Web”) utiliza cookies y/u otros archivos de funcionalidad similar (en adelante, las “Cookies”).</p>

                    <p><strong>¿Qué son las Cookies y para qué se utilizan?</strong></p>

                    <p>Las Cookies son archivos de texto que se instalan en el dispositivo del Usuario desde el que se accede a esta web y, almacenan y envían, de forma automática, información a nuestro servidor mientras Ud. hace uso de la misma. </p>

                    <p>Tenga en cuenta que las Cookies no dañan su dispositivo ni ralentizan su funcionamiento. Asimismo, se le informa de que Usted puede eliminarlas en cualquier momento, modificarlas o rechazarlas a través del Panel de Configuración o configurando el programa de navegación que utiliza tal y como detallamos más adelante a lo largo de la presente política.</p>

                    <p>Le informamos que Laboratorios Farmacéuticos Rovi S.A no recaba datos de carácter personal sobre Ud. a través de las Cookies de esta web, por ello le informamos de que nuestras actividades no se encuentran sujetas a la aplicación del Reglamento (UE) 2016/679 de Protección de Datos (en adelante, "RGPD").</p>

                    <p>En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, para que funcione correctamente nuestra web o para realizar estadísticas sobre el uso de la misma. Los usos concretos que hacemos de estas tecnologías se describen a continuación.</p>

                    <p><strong>¿Qué tipos de Cookies utiliza nuestra Web y cuál es su finalidad?</strong></p>

                    <p>Laboratorios Farmaceuticos Rovi S.A, a través de su web utiliza distintos tipos de Cookies que permiten al usuario navegar por la website y usar los servicios y opciones que existen en ellas como: </p>

                    <ul>
                        <li>Cookies de sesión: Son cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que 	solo interesa conservar para la prestación del servicio solicitado por el usuario en una 	sola ocasión.</li>
                        <li>Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en 	el terminal y a los que se puede acceder y tratar durante un periodo definido por el 	responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
                    </ul>

                    <p><strong>¿Cómo se pueden eliminar o desactivar las Cookies?</strong></p>

                    <p>Aunque puede gestionar las cookies de esta web en el Panel de Configuración anterior, si desea también puede gestionar la instalación de cookies a través de la configuración de su navegador web de su dispositivo móvil. La mayoría de navegadores web permiten gestionar, en cualquier momento, las preferencias del Usuario sobre el uso de las Cookies. Usted puede ajustar su navegador para que rechace Cookies o elimine determinadas Cookies según su criterio. Tenga en cuenta que algunos de nuestros servicios no funcionarán si su navegador no acepta cookies. </p>

                    <p>Para la configuración de las Cookies el Usuario puede acceder a los siguientes links sobre cómo gestionar el uso de cookies en función del navegador utilizado:</p>

                    <ul>
                        <li>Google Chrome:<br /><a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a></li>
                        <li>Mozilla Firefox:<br /><a target="_blank" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a></li>
                        <li>Internet Explorer:<br /><a target="_blank" href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                        <li>Safari:<br /><a target="_blank" href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a></li>
                        <li>Safari (IOS):<br /><a target="_blank" href="https://support.apple.com/es-es/HT201265">https://support.apple.com/es-es/HT201265</a></li>
                        <li>Opera:<br /><a target="_blank" href="https://help.opera.com/en/latest/web-preferences/#cookies">https://help.opera.com/en/latest/web-preferences/#cookies</a></li>
                        <li>Microsoft Edge:<br /><a target="_blank" href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a></li>

                    </ul>

                    <p>Usted puede revocar su consentimiento para el uso de cookies en su navegador a través de las indicaciones anteriores.</p>

                    <p><strong>Actualizaciones y cambios en la Política de Cookies</strong></p>

                    <p>Laboratorios Farmacéuticos Rovi S.A, puede modificar esta Política de Cookies en función de nuevas exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos. </p>

                    <p>Cuando se produzcan cambios significativos en esta Política de Cookies será comunicado mediante un aviso informativo en la web.</p>

                    <p>Si tiene alguna duda sobre nuestra política de cookies, póngase en contacto con nosotros a través del siguiente correo electrónico: <a href="mailto:protecciondedatos@rovi.es">protecciondedatos@rovi.es</a> </p>


                </div>
                <div className="legal-text-btn-container">
                    <div onClick={props.onCookiesPolicyClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                </div>
            </div>

            : null
    );
}

export default CookiesPolicy;