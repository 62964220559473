import React, { Component } from 'react';
import NetworkingDelegates from './NetworkingDelegates';
import NetworkingSocialMedia from './NetworkingSocialMedia';
import NetworkingVideoWall from './NetworkingVideoWall';
import axios from 'axios'
import configData from '../../../config.json';
import AuthContext from '../../../context/auth-context';
import { withTranslation } from 'react-i18next'
import io from "socket.io-client";
import WithPermission from '../../../hoc/WithPermission'
import Video from '../../../containers/Assistant/AssistantVideo';
import ContactRequest from '../../../containers/Congress/IndustriaStand/ContactRequest'

class Networking extends Component {

    state = {
        backgroundImage: "https://www.emma.events/static/virtualvenue/cnp2020/lobby-scientific-cnp2020.jpg",
        page: "delegates",
        persons: [],
        access: ["54434","144186","144402","144403","44331"],
        chatActive: false,
        chatType: "video",
        chatRoom: "",
        chatRecipientId: ""
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.initSocketConnection();
        this.setupSocketListeners();
    }

    componentWillUnmount() {
        this.closeSocketConnection();
    }

    initSocketConnection() {
        const server = configData.SOCKET_API_LOUNGE + "/junior-room";
        this.socket = io.connect(server, { transports: ['websocket'], query: { token: this.context.token } });
        this.socket.on('connect', () => {
            this.socket.emit('sign-in', this.context.id);
        });
    }

    closeSocketConnection() {
        if (!this.socket.disconnected) {
            this.socket.disconnect();
        }
    }

    setupSocketListeners() {
        this.socket.on("sign-in", data => { this.onEnter(data); });
        this.socket.on("enter", data => { this.onPersonEnter(data); });
        this.socket.on("exit", data => { this.onPersonExit(data); });
        this.socket.on("contact-request-push", data => {
            this.setState({
                contactRequest: data,
                contactRequestActive: true
            })
        });
    }

    onSelectPage = (page) => {
        this.setState({ page: page })
    }

    onEnter = (persons) => {
        this.setState({ persons: persons })
        /* let newArray = [];
        if (persons !== undefined && persons.length > 0 ){
            for (let index = 0; index < persons.length; index++) {
                const element = persons[index];
                newArray.push(element)
            }
            this.setState({persons: newArray})
        } */
    }

    onPersonEnter = (newPerson) => {
        const arrayPersons = this.state.persons
        let alreadyExist = false;
        let indexDelete = 0;
        if (arrayPersons !== undefined && arrayPersons.length > 0) {
            if (newPerson.id !== undefined && newPerson.id !== null) {

                // check if already exists -> needs to delete first before adding
                for (let index = 0; index < arrayPersons.length; index++) {
                    const element = arrayPersons[index];
                    if (element.id === newPerson.id) {
                        indexDelete = index;
                        alreadyExist = true;
                        break;
                    }
                }

                if (alreadyExist) {
                    arrayPersons.splice(indexDelete, 1)
                }
                arrayPersons.unshift(newPerson);
                this.setState({ persons: arrayPersons })
            }
        } else {
            arrayPersons.unshift(newPerson);
            this.setState({ persons: arrayPersons })
        }
    }

    onPersonExit = (personId) => {
        const arrayPersons = this.state.persons
        if (arrayPersons !== undefined && arrayPersons.length > 0) {
            const newArray = arrayPersons.filter((el) => {
                return (el.id !== personId)
            })
            this.setState({ persons: newArray })
        }
    }

    onMainVoiceHandler = (id) => {
        this.setState({
            chatActive: true,
            chatType: "voice",
            chatRoom: this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId: id
        })
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId: id,
            type: "voice",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room     
    }
    onMainVideoHandler = (id) => {
        this.setState({
            chatActive: true,
            chatType: "video",
            chatRoom: this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId: id
        })

        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId: id,
            type: "video",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room     
    }

    // CHAT CLOSE EVENTS ----------------------
    onCloseChatHandler = () => {
        this.setState({
            chatRoom: null,
            chatActive: false
        })
    }

    onVoiceSetHandler = () => {
        this.setState({
            chatActive: true,
            chatType: "voice"
        })
    }

    onVideoSetHandler = () => {
        this.setState({
            chatActive: true,
            chatType: "video"
        })
    }

    closeContactRequest = () => {
        this.setState({
            contactRequestActive: false
        })
    }

    acceptContactHandler = () => {
        this.setState({
            contactRequestActive: false,
            contactRequest: null,
            chatType: this.state.contactRequest.type,
            chatRoom: this.state.id + '.' + this.state.contactRequest.id + '.' + this.context.id,
            chatActive: true,
            chatMessages: []
        })
    }

    declineContactHandler = () => {
        this.setState({
            contactRequestActive: false,
            contactRequest: null
        })
    }

    render() {

        let content;
        let delegateClass = "networking-top-btn-container cursor-only";
        let socialClass = "networking-top-btn-container social-video cursor-only";
        let videoWallClass = "networking-top-btn-container social-video cursor-only";

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';

        switch (this.state.page) {
            case 'delegates':
                content = <NetworkingDelegates persons={this.state.persons}
                    onVoice={this.onMainVoiceHandler}
                    onVideo={this.onMainVideoHandler} eventId={this.context.event_id}></NetworkingDelegates>
                delegateClass = "networking-top-btn-container selected"
                break;
            case 'social':
                content = <NetworkingSocialMedia eventId={this.context.event_id}></NetworkingSocialMedia>
                socialClass = "networking-top-btn-container social-video selected";
                break;
            case 'videowall':
                content = <NetworkingVideoWall eventId={this.context.event_id}></NetworkingVideoWall>
                videoWallClass = "networking-top-btn-container social-video selected";
                break;
            default:
                break;
        }


        let myChat
        if (this.state.chatActive) {

            if (this.state.chatType === "voice") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={false} close={this.onCloseChatHandler} room={this.state.chatRoom} />
                    </div>
                </div>

            if (this.state.chatType === "video") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={true} close={this.onCloseChatHandler} room={this.state.chatRoom} />
                    </div>
                </div>


        } else {
            myChat = null;
        }

        const myContactRequest = this.state.contactRequestActive ?
            <ContactRequest
                request={this.state.contactRequest}
                closeContactRequest={this.closeContactRequest}
                accept={this.acceptContactHandler}
                decline={this.declineContactHandler} />
            : null

        return (
            <React.Fragment>
                <div className="networking-main-area-container">

                    <div className={delegateClass} onClick={() => this.onSelectPage('delegates')} style={{width:`400px`}}>

                        <div className="networking-top-btn-wrapper" >

                            <div className="networking-top-btn-icon-delegates">
                                <img alt="" src="/img/networking-top-btn-icon-delegates.png" />
                            </div>

                            <div className="networking-top-btn-text-delegates">
                            JUNIOR ROOM <br />08:15-08:45 h AND 13:00-13:45 h CET

                                

                        </div>

                        </div>

                    </div>
{/* 
                    <div className={socialClass} onClick={() => this.onSelectPage('social')}>

                        <div className="networking-top-btn-wrapper">

                            <div className="networking-top-btn-icon-social">
                                <img alt="" src="/img/networking-top-btn-icon-social-fb.png" />
                            </div>

                            <div className="networking-top-btn-icon-social-spacer">&nbsp;</div>

                            <div className="networking-top-btn-icon-social">
                                <img alt="" src="/img/networking-top-btn-icon-social-instagram.png" />
                            </div>

                            <div className="networking-top-btn-icon-social-spacer">&nbsp;</div>

                            <div className="networking-top-btn-icon-social">
                                <img alt="" src="/img/networking-top-btn-icon-social-twitter.png" />
                            </div>

                            <div className="networking-top-btn-text-social-video">
                                REDES SOCIALES
                        </div>

                        </div>

                    </div>

                    <div className={videoWallClass} onClick={() => this.onSelectPage('videowall')}>
                        <div className="networking-top-btn-wrapper">

                            <div className="networking-top-btn-icon-social">
                                <img alt="" src="/img/networking-top-btn-icon-video.png" />
                            </div>

                            <div className="networking-top-btn-text-social-video">
                                VIDEOS
                        </div>

                        </div>

                    </div>
 */}
                </div>

                <WithPermission userId={this.context.id} role={this.context.profile} objectType={"networking-lounge"} objectPermission={this.state.access} eventId={this.context.event_id}>
                    {content}
                </WithPermission>

                {myChat}
                {myContactRequest}

            </React.Fragment>
        );
    }

}

export default Networking;