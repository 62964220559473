import React, { Component } from 'react';

class QA extends Component {
    render() {
        return (
            <React.Fragment>
            <div className="econgress-room-accordion-header">
                <div className="econgress-room-accordion-header-icon-container">
                    <img alt="" src="/img/icon-arrow-bottom-accordion.png" />
                </div>
                Preguntas & Respuestas
            </div>

            <div className="econgress-room-questions-area-container">
                <label><input type="radio" id="op1" name="select-questions-show" value="1" /> MOSTRAR TODAS LAS PREGUNTAS</label>
                <label><input type="radio" id="op2" name="select-questions-show" value="2" /> MOSTRAR SOLO PREGUNTAS RESPONDIDAS</label>


                <div className="econgress-room-questions-wrapper">

                    <div className="econgress-room-question-icon">
                        <img alt="" src="/img/econgress-room-question-icon-closed.png" />
                    </div>

                    <div className="econgress-room-question-text">
                        <span className="econgress-room-question-text-question">Q_Donec eget justo quis nisi facilisis fringilla. Vivamus quam erat, luctus eu porttitor at, facilisis sit amet nisi?</span>
                    </div>

                    <div className="econgress-room-question-icon-answer">
                        <img alt="" src="/img/econgress-room-question-icon-not-answered.png" />
                    </div>

                </div>

                <div style={{clear:`both`}}></div>

                <div className="econgress-room-questions-wrapper">

                    <div className="econgress-room-question-icon">
                        <img alt="" src="/img/econgress-room-question-icon-opened.png" />
                    </div>

                    <div className="econgress-room-question-text">
                        <span className="econgress-room-question-text-question">Q_Donec eget justo quis nisi facilisis fringilla. Vivamus quam erat, luctus eu porttitor at, facilisis sit amet nisi?</span><br />
                        Rui Mimoso, 17:38:<br />
                        <strong>A</strong>_Ut tincidunt sagittis euismod. Phasellus leo leo, imperdiet id molestie a, rutrum id nisl.<br />
                        <span style={{float:`right`}}>Johnny Walker, 19:32</span>
                    </div>

                    <div className="econgress-room-question-icon-answer">
                        <img alt="" src="/img/econgress-room-question-icon-answered.png" />
                    </div>

                </div>


                <div style={{clear:`both`}}></div>


                <div className="econgress-room-questions-wrapper">

                    <div className="econgress-room-question-icon">
                        <img alt="" src="/img/econgress-room-question-icon-closed.png" />
                    </div>

                    <div className="econgress-room-question-text">
                        <span className="econgress-room-question-text-question">Q_Donec eget justo quis nisi facilisis fringilla. Vivamus quam erat, luctus eu porttitor at, facilisis sit amet nisi?</span>                       
                    </div>

                    <div className="econgress-room-question-icon-answer">
                        <img alt="" src="/img/econgress-room-question-icon-answered.png" />
                    </div>

                </div>

            </div>
            </React.Fragment>
        );
    }
}

export default QA;