import React from 'react';

function EPoster(props) {

    const rate = Math.round(props.score);

    let entryContainer = 'eposter-entry-container';

    if (props.eventid == "336") {
        entryContainer = entryContainer + ' sefc2022'
    }

    return (
        <div className={entryContainer}>
            <div className="eposter-entry-cat-color" style={{ backgroundColor: props.backgroundColor }}></div>
            <div className="eposter-entry-content-container">
                <div className="eposter-entry-content-title-authors">


                    {props.eventid == "4" && (props.id == "51" || props.id == "84" || props.id == "27") ?
                        <span className="eposter-entry-title">  <span className="poster-prize-tag">PREMIADO</span><br />{props.title}</span>
                        :
                        <span className="eposter-entry-title">{props.title}</span>
                    }

                    <br /><br />
                    <span className="eposter-entry-authors">{props.authors}</span>
                </div>
                {props.active
                    ? <div className="eposter-entry-content-poster-preview"><img src={props.thumbnail} /></div>
                    : null}

                <div style={{ clear: `both` }}></div>

                {props.eventid != "4" ?

                    <div className="eposter-entry-bottom-container">
                        <div className="eposter-entry-rating-container">
                            {rate > 0 ? <img src="/img/eposter-rating-star-filled.png" /> : <img src="/img/eposter-rating-star-not-filled.png" />}
                            {rate > 1 ? <img src="/img/eposter-rating-star-filled.png" /> : <img src="/img/eposter-rating-star-not-filled.png" />}
                            {rate > 2 ? <img src="/img/eposter-rating-star-filled.png" /> : <img src="/img/eposter-rating-star-not-filled.png" />}
                            {rate > 3 ? <img src="/img/eposter-rating-star-filled.png" /> : <img src="/img/eposter-rating-star-not-filled.png" />}
                            {rate > 4 ? <img src="/img/eposter-rating-star-filled.png" /> : <img src="/img/eposter-rating-star-not-filled.png" />}
                        </div>

                        <div className="eposter-entry-views-container">
                            <img src="/img/eposter-rating-views.png" /> {props.views}
                        </div>

                        <div className="eposter-entry-comments-container">
                            <img src="/img/eposter-comments.png" /> {props.comments}
                        </div>
                        <div className="eposter-entry-number"> #{props.number} </div>
                    </div>

                    : null

                }

            </div>
        </div>
    );
}

export default EPoster;