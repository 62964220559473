import React, { Component } from 'react';
import Live from '../Live/Live';
import { Link } from 'react-router-dom';

class LiveFeed extends Component {

    
    state = {
        items: this.props.live
      };

    static getDerivedStateFromProps(props, state){
        
        if (props.live.length !== state.items.length) {
            return {
              items: props.live
            };
          }
          return null;
    }
    /*

    componentDidMount(){
        console.log('mount props: ' + this.props.live.length)
        console.log('mount state: ' + this.state.items.length)
    }

    componentDidUpdate(){
        console.log('update props: ' + this.props.live.length)
        console.log('update state: ' + this.state.items.length)
        if (this.state.items.length > 1){
            setTimeout(() => {
                this.rotate();
            }, 5000);
        }
    }

    rotate = () => {
        console.log('a')
        this.setState((prevState) => {
            let tempOrdered = prevState.items;
            let moveToLastItem = tempOrdered.shift();
            return { items: tempOrdered.push(moveToLastItem) };
        })
    } */

        
    render() {

        const liveSessions = this.state.items.map((el, index) => {
            const classN = 'info' + (index + 1);
            return (
                <Link key={el._id} to={`/room/${el.room_id}/${el._id}`}>
                    <Live classes={classN} id={el._id} title={el.title} room={el.room_id} sponsor={el.sponsored_text}></Live>
                </Link>
            )
        })

        return (
            <div>{liveSessions}</div>
        );
    }
}

export default LiveFeed;