import React, { Component } from 'react';

class VotingPoll extends Component {
    
    state = {
        option:""
    }

    onSelectOptionHandler = (id) => {
        this.setState({option:id})
    }

    onVoteHandler = () => {
        this.props.vote(this.props.question._id, this.state.option) 
    }

    render() {

        const _id = this.props.question._id
        const title = this.props.question.question;
        const open = this.props.question.open;
        const show_correct = this.props.question.show_correct;
        const show_results = this.props.question.show_results;
        const show_count = this.props.question.show_count !== undefined ? this.props.question.show_count : true;
        const show_percentage = this.props.question.show_percentage !== undefined ? this.props.question.show_percentage : true;
        
        let total_votes = 0;
        if (this.props.question.votes !== undefined) {
            this.props.question.votes.forEach(element => {
                total_votes += element.total;
            });
        }

        const options = this.props.question.options.map((el, index) => {
            
            // count the number of votes and calculate the percentages
            const votes = this.props.question.votes !== undefined ? this.props.question.votes.filter(element => element.id === el.id) : undefined;
            const percentage = votes !== undefined && votes.length > 0 && total_votes > 0 ?  (votes[0].total / total_votes * 100).toFixed(1) : 0;

            // contruct the label to be displayed
            const votes_label = show_count 
                ? (votes !== undefined && votes.length > 0) ? '(' + votes[0].total + ')' : '(' + 0 + ')'
                : null;

            const percentage_label = show_percentage 
                ? percentage + '%' 
                : null;

            // build the final string to be rendered, depending on the settings in the database
            const results = show_results 
                ? <div className="bar-percentage" style={{width: percentage + `%`}}><b>{percentage_label} {votes_label}</b></div> 
                : null

            if (open){
                return (
                    <div key={el.id}>
                        <p><label><input type="radio" name={_id} value={el.id} onClick={() => this.onSelectOptionHandler(el.id)} /> {el.option} </label></p>
                        {results}
                    </div>
                )
            } else {
                return (                    
                    <div key={el.id}>
                        <p>{ el.correct !== undefined && el.correct && show_correct ? <img src="/img/chat-pic-checked.png" /> : null} <label>{el.option} </label></p>
                        {results}
                    </div>                    
                )
            }
        })

        return (
            <div className="econgress-room-vote-question-container">
                <p><b>{title}</b></p>
                {options}
                {open ?
                <div className="econgress-room-right-panel-btn" onClick={this.onVoteHandler}>VOTE</div>
                : null}
            </div>
        );
    }
}

export default VotingPoll;