import React, { Component } from 'react';

class Participant extends Component {

    state = {
        time_entry: null,
        time_in:null
    }

    startTimeElapse(){

    }

    componentDidMount(){
        const diffSec = this.dateDiff(new Date(this.props.timeIn), new Date())        
        const elapsed = this.secondToMinSec(diffSec)
        this.setState({time_in:elapsed})
        setInterval(() => {
            const diffSec = this.dateDiff(new Date(this.props.timeIn), new Date())        
            const elapsed = this.secondToMinSec(diffSec)
            this.setState({time_in:elapsed})
        }, 1000);
    }

    dateDiff(date1, date2){
        const diffTime = Math.abs(date2 - date1);
        const diffSec = Math.ceil(diffTime / (1000)); 
        return diffSec;
    }

    secondToMinSec(time){
        const min = Math.floor(time/60)
        const sec = time - min * 60
        return ('0'+min).slice(-2) + ':' + ('0'+sec).slice(-2);
    }
    
    render() {

        const photo =  this.props.photo !== undefined && this.props.photo !== "" ? this.props.photo : "/img/default-login-pic.png"

        /* const message = this.props.standLevel === 'premium' && this.props.sendMessage ? (
            <div className="person-detail-bottom-chat-send-message-container">
                <img alt="" src="/img/person-detail-bottom-chat-icon-send-message.png" />
                Enviar mensaje
            </div> ) : null; */
        
        const voice = this.props.standLevel === 'premium' && this.props.voice ? (
            <React.Fragment>
            <div className="person-detail-bottom-chat-opt-right" onClick={() => this.props.onVoice(this.props.id)}>
                <img alt="" src="/img/person-detail-bottom-chat-icon-phone.png" />
            </div>
            <div className="person-detail-bottom-chat-opt-right-separator"></div>
            </React.Fragment> ) : null;

        const video = this.props.standLevel === 'premium' && this.props.video ? (
            <React.Fragment>
            <div className="person-detail-bottom-chat-opt-right" onClick={() => this.props.onVideo(this.props.id)}>
                <img alt="" src="/img/person-detail-bottom-chat-icon-video.png" />
            </div>
            <div className="person-detail-bottom-chat-opt-right-separator"></div>
            </React.Fragment>) : null;

        const txt = this.props.standLevel === 'premium' && this.props.txt ? (
            <React.Fragment><div className="person-detail-bottom-chat-opt-right" onClick={() => this.props.onTxt(this.props.id)}>
                <img alt="" src="/img/person-detail-bottom-chat-icon-group.png" />
            </div>
            </React.Fragment>) : null;

        /* const txt = null; */

        const InOut = this.props.inOut === "in" ? "IN" : "OUT"

        return (
            <div className="stand-detail-delegate-entry-container">
                
                <div className="networking-person-connection"></div>
                {/* <div className="networking-person-connection connected"></div> */}

                <div className="stand-detail-delegate-photo-container">
                    <div className="stand-detail-delegate-photo">
                        {this.props.online ?
                        <div className="stand-detail-delegate-photo-time-container">{this.state.time_in}</div>
                        : null}
                        <img alt="" src={photo} />
                    </div>
                </div>
            
                <div className="stand-detail-delegate-name-container">

                    <div className="stand-detail-delegate-contact-bottom-container">
                        
                        {/* {message} */}
                        {voice}
                        {video}
                        {txt}

                        {/* <div className="person-detail-bottom-chat-opt-right">
                            <img alt="" src="/img/person-detail-bottom-chat-icon-add-person.png" />
                        </div> */}

                    </div>

                    { !this.props.online ?
                    <div className="stand-detail-delegate-contact-bottom-container person-time">
                        <div className="person-detail-bottom-chat-opt-right">{InOut}</div>
                        <div className="person-detail-bottom-chat-opt-right-separator"></div>
                        <div className="person-detail-bottom-chat-opt-right">
                            {this.props.timeIn} &nbsp;&nbsp;
                            <img alt="" src="/img/icon-person-time.png" />
                        </div>
                    </div> : null}


                    <strong>{this.props.name}</strong><br />
                    {this.props.institution}
                </div>

            </div>

        );
    }
}

export default Participant;