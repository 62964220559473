import React from 'react';
import { useTranslation } from 'react-i18next';

function TermsConditions(props) {

    const { t, i18n } = useTranslation();

    return (

        props.event_id === "387" || props.event_id === "2" || props.event_id === "3" || props.event_id === "4" || props.event_id === "5" ?

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container">


                    <h3><strong>TÉRMINOS Y CONDICIONES DE USO </strong></h3>

                    <p>1.-Estas Condiciones de Uso regulan las reglas a que se sujeta la utilización de los contenidos de esta página Web (en adelante, los Contenidos). El acceso, la descarga o la utilización de los Contenidos atribuye la condición de Usuario a quien lo haga e implica la aceptación de todas las condiciones incluidas en este documento y en la Política de Privacidad y el Aviso Legal de esta página Web.</p>
                    <p>El Usuario debería leer estas condiciones cada vez que acceda, se descargue o utilice los Contenidos, ya que podrían ser modificadas en lo sucesivo.</p>
                    <p>2.- Los Contenidos, así como las marcas y demás signos distintivos que figuren en ellos son propiedad de los respectivos titulares o de Laboratorios Farmacéuticos Rovi, S.A. (en adelante, Rovi). Los Usuarios podrán acceder a los Contenidos con posibilidad de consultarlos y descargarlos.</p>
                    <p>3.- Queda prohibido eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir, desactivar o manipular) las funciones de seguridad u otras y utilizar los Contenidos para un fin comercial o publicitario. Asimismo, se prohíbe el uso de los Contenidos con la finalidad de lesionar bienes, derechos o intereses de Rovi, los autores o terceros.</p>
                    <p>El Usuario, deberá abstenerse de: a) transformar o modificar los contenidos; b) suprimir o alterar el nombre del autor en los Contenidos; c) extraer o reutilizar en otras obras todo o parte sustancial de los Contenidos, salvo autorización expresa del autor.</p>
                    <p>4.- El Usuario se compromete a hacer un uso correcto de los Contenidos, de conformidad con la Ley, con los presentes Términos y Condiciones de Uso y con los demás reglamentos e instrucciones que, en su caso, pudieran ser de aplicación. El Usuario responderá frente a Rovi, frente al autor y frente a terceros de cualesquiera daños o perjuicios que pudieran causarse por incumplimiento de estas obligaciones.</p>
                    <p>5.- Los profesionales sanitarios podrán seguir vía streaming las diferentes ponencias celebradas como consecuencia de la actividad promocionada en la presente Web, así como formular consultas a los ponentes. Rovi no se hace responsable de las opiniones manifestadas por los ponentes y de los comentarios y preguntas formuladas por los asistentes. Los participantes en este evento deberán abstenerse de realizar cualquier comentario discriminatorio, vejatorio, que atente contra la dignidad o intimidad personal o cualquier otro que pueda resultar contrario a la Ley. Teniendo en cuenta lo anterior, y para garantizar la privacidad, queda totalmente prohibido revelar datos personales a la hora de formular las consultas a los ponentes,&nbsp; debiendo limitarse el contenido de las mismas a la esfera médica y profesional.</p>
                    <p>6.- No se solicitarán datos identificativos de carácter personal en la realización de encuestas.</p>
                    <p>7.-Rovi no se hace responsable de las opiniones manifestadas por los ponentes y de los comentarios de los participantes en el concurso ni del contenido de los materiales que presenten a tales efectos. Los participantes en el concurso deberán abstenerse de realizar cualquier comentario discriminatorio, vejatorio, que atente contra la dignidad o intimidad personal o cualquier otro que pueda resultar contrario a la Ley.</p>
                    <p>8.- Estas Condiciones de Uso se rigen íntegramente por la legislación española. Para la resolución de cualquier conflicto relativo a su interpretación o aplicación, el Usuario se somete expresamente a la jurisdicción de los tribunales de Madrid (España).</p>



                </div>
                <div className="legal-text-btn-container">
                    <div onClick={props.onTermsConditionsClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                </div>
            </div>

            : null
    );
}

export default TermsConditions;