import React from 'react';

function ChatFooter(props) {
    return (
        <div className="chat-footer">
            <div className="chat-input">
                <input type="text" id="chat-msg" name="chat-msg" placeholder="Write message..."/>
            </div>
        </div>
    );
}

export default ChatFooter;