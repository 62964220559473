import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../../../../context/auth-context'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class HospitalityList extends Component {

    state = {

        HospitalityOpen: true

    }

    onShowHideHandler = () =>{
        this.setState((prevState, props) => {
            return {                
                HospitalityOpen: !prevState.HospitalityOpen
                };
        })    
    }
    

    static contextType = AuthContext;


    render() {


        let HospitalityList = null
        
        if (this.state.HospitalityOpen && this.props.company === "mectron") {
            
            HospitalityList =  
            <div className="stand-detail-hospitality-bar-container">
            <div className="stand-detail-delegates-bar-header">
                <div className="stand-detail-delegates-bar-header-title-close-container">
                    <div className="stand-detail-messages-bar-header-title-left-container">Hospitality Suite</div>
                    <div className="stand-detail-messages-bar-header-close-right-container" onClick={this.onShowHideHandler}>
                        <img alt="" src="/img/icon-close-login-panel.png" />
                    </div>
                </div>
                <div className="stand-detail-messages-bar-header-tabs-container">
                    <div className="stand-detail-messages-bar-header-tab active" onClick={this.onDisplayAllMessages}>Meeting</div>
                </div>
            </div>

            <div className="stand-detail-delegates-left-content-container meeting">

                {/* <div className="stand-hospitality-row-day">
                    <div className="stand-hospitality-day">
                        JUL<br /><span className="stand-hospitality-day-text">14</span><br />Wednesday
                    </div>
                    <div className="stand-hospitality-meeting-entry">
                    

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>14:30H</strong><br />
                                Prof. Giovanni Gerbino, IT "Piezosurgery and computer assisted surgery"<br />
                                <Link to="/meetings/60ee8d739c93d728096c22f4">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>15:00H</strong><br />
                                Dr. Simon Jones, UK "Piezosurgery technology in Othognathic surgery and post-operative advantages"<br />
                                <Link to="/meetings/60ee8d7b9c93d728096c22f5">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>16:00H</strong><br />
                                Prof. Bilal Al Nawas, DE "Use of Piezosurgery in craniosynostosis"<br />
                                <Link to="/meetings/60ee8d839c93d728096c22f6">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                    </div>
                </div> */}

                <div className="stand-hospitality-row-day">
                    <div className="stand-hospitality-day">
                        JUL<br /><span className="stand-hospitality-day-text">15</span><br />Thursday
                    </div>
                    <div className="stand-hospitality-meeting-entry">
                        
                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>14:00H</strong><br />
                                Dr. David Alfi, US "Orthognathic Surgery Reinvented with Today’s Tools"<br />
                                <Link to="/meetings/60ee8d8c9c93d728096c22f7">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>14:30H</strong><br />
                                Dr. Leo Cheng, UK "Use of Piezosurgery in TMJ surgery"<br />
                                <Link to="/meetings/60ee8d939c93d728096c22f8">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>15:00H</strong><br />
                                Prof. Samer Srouji, IL  "The use of Piezosurgery  in Maxillofacial surgery from TMJ surgery to preprosthetic surgery"<br />
                                <Link to="/meetings/60ee8d9a9c93d728096c22f9">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <strong>15:30H</strong><br />
                                Prof. Bilal Al Nawas, DE "Use of Piezosurgery in craniosynostosis"<br />
                                <Link to="/meetings/60ee8d839c93d728096c22f6">
                                    <div className="btn-start-meeting green">ENTER THE MEETING</div>
                                </Link>
                            </div>    
                        </div>

                    </div>
                </div>

            </div>
            </div>
        }

        if (this.state.HospitalityOpen && this.props.company === "brainlab") {
            
            HospitalityList =  
            <div className="stand-detail-hospitality-bar-container">
            <div className="stand-detail-delegates-bar-header">
                <div className="stand-detail-delegates-bar-header-title-close-container">
                    <div className="stand-detail-messages-bar-header-title-left-container">Hospitality Suite</div>
                    <div className="stand-detail-messages-bar-header-close-right-container" onClick={this.onShowHideHandler}>
                        <img alt="" src="/img/icon-close-login-panel.png" />
                    </div>
                </div>
                <div className="stand-detail-messages-bar-header-tabs-container">
                    <div className="stand-detail-messages-bar-header-tab active" onClick={this.onDisplayAllMessages}>Meeting</div>
                </div>
            </div>

            <div className="stand-detail-delegates-left-content-container meeting">

                <div className="stand-hospitality-row-day">
                    
                    <div className="stand-hospitality-meeting-entry">
                    

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Sinah Albrecht</b><br />
                                <Link to="/meetings/60eee1bc9c93d728096c2453">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>

                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Achmet Guerle</b><br />
                                <Link to="/meetings/60eee1c79c93d728096c2458">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Scott Dawber</b><br />
                                <Link to="/meetings/60eee1cf9c93d728096c2459">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Sarah Williams</b><br />
                                <Link to="/meetings/60eee1d69c93d728096c245a">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Geoffroy Rouffy</b><br />
                                <Link to="/meetings/60eee1de9c93d728096c245b">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Christopher Schwarz</b><br />
                                <Link to="/meetings/60eee1e59c93d728096c245c">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Thomas Heiliger</b><br />
                                <Link to="/meetings/60eee1ec9c93d728096c245d">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - David Frankham</b><br />
                                <Link to="/meetings/60eee1f69c93d728096c245e">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - William Fischer</b><br />
                                <Link to="/meetings/60eee1ff9c93d728096c245f">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>


                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                <b>Brainlab Room - Teresa Cuesta</b><br />
                                <Link to="/meetings/60eee2069c93d728096c2460">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>

                    </div>
                </div>
                
            </div>
            </div>
        }

        
        if (this.state.HospitalityOpen && this.props.company === "intuitive") {
        
            HospitalityList =  (<div className="stand-detail-hospitality-bar-container">
            <div className="stand-detail-delegates-bar-header">
                <div className="stand-detail-delegates-bar-header-title-close-container">
                    <div className="stand-detail-messages-bar-header-title-left-container">Hospitality Suite</div>
                    <div className="stand-detail-messages-bar-header-close-right-container" onClick={this.onShowHideHandler}>
                        <img alt="" src="/img/icon-close-login-panel.png" />
                    </div>
                </div>
                <div className="stand-detail-messages-bar-header-tabs-container">
                    <div className="stand-detail-messages-bar-header-tab active" onClick={this.onDisplayAllMessages}>Meeting</div>
                </div>
            </div>

            <div className="stand-detail-delegates-left-content-container meeting">


                <div className="stand-hospitality-row-day">
                    <div className="stand-hospitality-day">
                        JUNE<span className="stand-hospitality-day-text"></span><br />21-22
                    </div>
                    <div className="stand-hospitality-meeting-entry">
                        <div className="stand-hospitality-meeting-entry-wrapper last-meeting">
                            <div className="stand-hospitality-meeting">
                                INTUITIVE HOSPITALITY SUITE<br /><br />
                                <Link to="/meetings/60d06a2e80d9f93214060462">
                                    <div className="btn-start-meeting green">ENTER THE ROOM</div>
                                </Link>
                            </div>    
                        </div>

                    </div>
                </div>
            </div>
        </div>)
        }

        return (
            <React.Fragment>
                <div className="stand-detail-hospitality-bar-open-info-floating-container" onClick={this.onShowHideHandler}>
                    {/* <div className="stand-detail-delegates-bar-open-info-numbers-container">2</div> */}
                    <div className="stand-detail-hospitality-bar-open-info-icon-container"></div>
                </div>
                {HospitalityList}
            </React.Fragment>
        );
    }
}

export default withTranslation()(HospitalityList);