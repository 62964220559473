
import * as actionTypes from './actionTypes';
import configData from '../../config.json';
import io from "socket.io-client";
import {live} from './live';
import {secretariat, 
        secretariat_contact_request_receive,
        secretariat_chat_message_render} from './secretariat';


        // action creators
export const addSocket = (socket) =>{
    return {
        type: actionTypes.ADD_SOCKET,
        socket: socket
    }
}

export const connectSocket = ( token) => {    

    return (dispatch, getState) => {        
        const server = configData.SOCKET_API_GLOBAL;
        let socket = io.connect(server, {transports: [ 'websocket' ], query: {token: token} });
        socket.on('connect', () => {        

            // logs the entry 
            socket.emit('user-pathname-log', {
                type: "ENTER",
                object: "SOCKET-CONNECT",
                pathname: window.location.pathname
            });

            socket.on("logout", data => {                
                dispatch(logout());
            });

            socket.on("live-session-update", data => {                
                dispatch(live(data));
            });

            socket.on("secretariat-available", data => {    
                dispatch(secretariat(data));    // apenas faz o dispatch se estivermos no evento correcto 
            });

            socket.on("secretariat-contact-request", data => { 
                dispatch(secretariat_contact_request_receive(data));
            }); 
            
            socket.on("secretariat-chat-message", data => { 
                dispatch(secretariat_chat_message_render(data));
            }); 

            dispatch(addSocket(socket));

        });
    }
}

export const removeSocket = () =>{
    return {
        type: actionTypes.REMOVE_SOCKET
    }
}

export const disconnectSocket = () => {
    return (dispatch, getState) => {        
        const currentSocket= getState().global.socket;
        if (currentSocket!== undefined && currentSocket !== null){
            currentSocket.close();
        }
        dispatch(removeSocket());
    }
}

export const logWatch = (data) =>{
    return (dispatch, getState) => {        
        
        const currentSocket= getState().global.socket;        
        
        if (currentSocket !== undefined && currentSocket !== null) {
            currentSocket.emit('user-watch-log', data);
        } else {
            //console.log('no socket yet')
        }
        
    }
}

export const logPathname = (data) =>{
    return (dispatch, getState) => {        
        
        const currentSocket= getState().global.socket;        
        
        if (currentSocket !== undefined && currentSocket !== null) {
            currentSocket.emit('user-pathname-log', data);
        } else {
            //console.log('no socket yet')
        }
        
    }
}

export const login = (userId) =>{
    return {
        type: actionTypes.LOGIN,
        userId: userId
    }
}

export const logout = () =>{
    return {
        type: actionTypes.LOGOUT
    }
}


