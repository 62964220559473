import React, { Component } from 'react';
import AuthContext from "../../../../context/auth-context";
import axios from 'axios';
import Rating from '../../../../components/Rating/Rating';
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class Ratings extends Component {
    
    state = {
        one:0,
        two:0,
        three:0,
        four:0,
        five:0,
        myScore:0
    }

    static contextType = AuthContext;


    onSendScoreHandler = () => {
        let config = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            }};
        let params = { 
                "poster_id" : this.props.posterId, 
                "rating" : this.state.myScore,
                "user_id": this.context.id
            };

        axios.post(configData.SERVER_API + "/posters/" + this.props.posterId + "/ratings" , params, config)        
        .then(response => {                 
            this.props.close();
        })
        .catch(error => {
            console.log(error)
        })
    }

    onScore = (e) => {        
        this.setState({myScore:e.target.alt * 1})
    }

    onSendMessageHandler = () => {
        let config = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            }};
        let params = { 
                "sender_id" : this.props.senderId, 
                "sender_type" : this.props.senderType, 
                "receiver_id": this.props.receiverId,                
                "receiver_type": this.props.receiverType,
                "receiver_email": this.props.receiverEmail,
                "event_id" : this.props.event_id,
                "name": this.state.name,
                "email": this.state.email,
                "subject" : this.state.subject, 
                "message": this.state.message,
            };

        axios.post(configData.SERVER_API + "/messages/" , params, config)
        .then(response => {
        })
        .catch(error => {
            console.log(error)
        })
    }

    componentDidMount(){
        this.setState({
            one:this.props.rating_1,
            two:this.props.rating_2,
            three:this.props.rating_3,
            four:this.props.rating_4,
            five:this.props.rating_5
        })
    }
    
    render() {
        return (
            <React.Fragment>
            <div className="eposter-detail-rating-arrow-container">
                <img alt="" src="/img/arrow-icon-top-modal-eposter-detail.png" />
            </div>
            <div className="eposter-detail-rating-content-container">
                <div className="eposter-detail-rating-text">{this.props.t('poster_rating')}</div>
                
                <div className="eposter-detail-rating-starts-container">
                    <Rating rate="5" transparent={true}></Rating>
                </div>
                <div className="eposter-detail-rating-starts-separator-container"></div>
                <div className="eposter-detail-rating-starts-number-container">({this.state.five})</div>
                
                <div style={{clear:`both`}}></div>
                
                <div className="eposter-detail-rating-starts-container">
                    <Rating rate="4" transparent={true}></Rating>
                </div>
                <div className="eposter-detail-rating-starts-separator-container"></div>
                <div className="eposter-detail-rating-starts-number-container">({this.state.four})</div>

                <div style={{clear:`both`}}></div>

                <div className="eposter-detail-rating-starts-container">
                    <Rating rate="3" transparent={true}></Rating>
                </div>
                <div className="eposter-detail-rating-starts-separator-container"></div>
                <div className="eposter-detail-rating-starts-number-container">({this.state.three})</div>

                <div style={{clear:`both`}}></div>

                <div className="eposter-detail-rating-starts-container">
                    <Rating rate="2" transparent={true}></Rating>
                </div>
                <div className="eposter-detail-rating-starts-separator-container"></div>
                <div className="eposter-detail-rating-starts-number-container">({this.state.two})</div>

                <div style={{clear:`both`}}></div>

                <div className="eposter-detail-rating-starts-container">
                    <Rating rate="1" transparent={true}></Rating>
                </div>
                <div className="eposter-detail-rating-starts-separator-container"></div>
                <div className="eposter-detail-rating-starts-number-container">({this.state.one})</div>

                <div style={{clear:`both`}}></div>

                <hr />

                <div className="eposter-detail-rating-text">{this.props.t('poster_your_rating')}</div>
                <Rating rate={this.state.myScore} transparent={true} scoreHandler={this.onScore}></Rating>
                <div style={{clear:`both`}}></div>

                <div className="eposter-detail-rating-btn" onClick={this.onSendScoreHandler}>{this.props.t('poster_your_rating_send')}</div>
            </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(Ratings);