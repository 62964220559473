import React from 'react';

function Days(props) {
    return (
        <div className="select-days-container rooms4">
            <div className="select-days-wrapper">
                <div className="btn-day" style={{width:`33.3%`}}>Miércoles, 24</div>
                <div className="btn-day selected" style={{width:`33.3%`}}>Jueves, 25</div>
                <div className="btn-day" style={{width:`33.3%`}}>Viernes, 26</div>
            </div>
        </div>  
    );
}

export default Days;

