import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";

class VideoPills extends Component {

    static contextType = AuthContext;

    //componentDidMount() {}
    //componentDidUpdate() {}


    render() {



        return (
            <React.Fragment>



                {this.context.event_id == "325" ?

                    <div className="video-pills-content-container">

                        <div className="video-pill-entries-row">

                            <div className="video-pill-entry-container">

                                <p><strong>Osteotomía percutánea de calcáneo 2.0.</strong><br /><small>Raúl Torre Puente, Mauri Rotinen Díaz, Mikel Ayerra Sanz, Susana Cimiano Pérez</small></p>

                                <div className="video-pill-entry-logo" style={{ display: "none" }}>
                                    <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/bd_stand_300x160px.png" />
                                </div>
                                <div className="video-pill-entry-video">
                                    <iframe src="https://player.vimeo.com/video/557601350" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>

                            </div>

                            <div className="video-pill-entry-separator"></div>

                            <div className="video-pill-entry-container">

                                <p><strong>Tratamiento quirúrgico del pie plano valgo del adulto grado IIC</strong><br /><small>Ana Abarquero Diezhandino, Laura González Miramontes, Jesús Vilá y Rico</small></p>

                                <div className="video-pill-entry-logo" style={{ display: "none" }}>
                                    <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/ethicon_stand_300x125px.png" />
                                </div>
                                <div className="video-pill-entry-video">
                                    <iframe src="https://player.vimeo.com/video/557607851" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>

                            </div>

                        </div>

                    </div>

                    : this.context.event_id == "263" ?

                        <div className="video-pills-content-container">

                            <div className="video-pill-entries-row">

                                <div className="video-pill-entry-container">

                                    <div className="video-pill-entry-logo">
                                        <img src="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/johnson-logo.jpg" />
                                    </div>
                                    <div className="video-pill-entry-video">
                                        <iframe src="https://player.vimeo.com/video/572491146" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                    </div>

                                </div>

                                <div className="video-pill-entry-separator"></div>

                                <div className="video-pill-entry-container">

                                    <div className="video-pill-entry-logo">
                                        <img src="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/stryker-logo.jpg" />
                                    </div>
                                    <div className="video-pill-entry-video">
                                        <iframe src="https://player.vimeo.com/video/572490905" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                    </div>

                                </div>

                            </div>



                            <div className="video-pill-entries-row">

                                <div className="video-pill-entry-container">

                                    <div className="video-pill-entry-logo">
                                        <img src="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/brainlab-logo-room.jpg" />
                                    </div>
                                    <div className="video-pill-entry-video">
                                        <iframe src="https://player.vimeo.com/video/572490797" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                    </div>

                                </div>

                                <div className="video-pill-entry-separator"></div>



                            </div>


                        </div>

                        : this.context.event_id == "358" ?

                            <div className="video-pills-content-container">

                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/bd_stand_300x160px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489350627" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/ethicon_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489350795" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>


                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/fengh_stand_300x160px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489351991" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/intuitive_stand_300x160px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489354663" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>



                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/kls_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489357119" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/medela_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489362721" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>



                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/medela_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489365318" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/medela_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489367068" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>



                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/medtronic_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489358541" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/medtronic_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489360050" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>



                                <div className="video-pill-entries-row">

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/industria/logos/reach_stand_300x125px.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489361533" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                    <div className="video-pill-entry-separator"></div>

                                    <div className="video-pill-entry-container">

                                        <div className="video-pill-entry-logo">
                                            <img src="https://www.emma.events/static/virtualvenue/ests2020/mybag/roche-mybag-logo.png" />
                                        </div>
                                        <div className="video-pill-entry-video">
                                            <iframe src="https://player.vimeo.com/video/489362349" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        </div>

                                    </div>

                                </div>

                            </div>



                            : this.context.event_id == "341" ?

                                <div className="video-pills-content-container">

                                    <div className="video-pill-entries-row">

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ethicon-logo.jpg" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565095888" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                        <div className="video-pill-entry-separator"></div>

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565096993" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                    </div>




                                    <div className="video-pill-entries-row">

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565096235" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                        <div className="video-pill-entry-separator"></div>

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565096817" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                    </div>





                                    <div className="video-pill-entries-row">

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565094110" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                        <div className="video-pill-entry-separator"></div>

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565095111" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                    </div>





                                    <div className="video-pill-entries-row">

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medela-logo.jpg" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565097897" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                        <div className="video-pill-entry-separator"></div>

                                        <div className="video-pill-entry-container">

                                            <div className="video-pill-entry-logo">
                                                <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/zimmer-logo.jpg" />
                                            </div>
                                            <div className="video-pill-entry-video">
                                                <iframe src="https://player.vimeo.com/video/565095825" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            </div>

                                        </div>

                                    </div>




                                </div>


                                : null
                }







            </React.Fragment>
        );
    }
}

export default VideoPills;