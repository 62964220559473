import React from 'react';

function MyBagScientificPoster(props) {
    
    return (
        <tr>
            <td className="td-preview">

                <div className="eposter-preview-container">

                    <div className="eposter-preview-wrapper">

                        <div className="eposter-preview-cat-color" style={{backgroundColor:`#007dff`}}></div>
                        <div className="eposter-preview-img"></div>

                    </div>

                    <div className="eposter-preview-number-wrapper">#097</div>

                </div>

            </td>
            <td className="td-title">Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis.</td>
            <td className="td-authors">B Basso Abad // J Gonzalez de Buitrago Amigo // C Surribas Murillo // M Sanchez Conejero // M Gutierrez Agujetas</td>
            <td className="td-category">PEDIATRIA HOSPITALARIA</td>
            <td className="td-download">Download</td>
        </tr>
    );
}


export default MyBagScientificPoster;