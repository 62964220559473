import React, { Component } from 'react';

class EposterDiscussion extends Component {

    state = {
        onPosterDiscussionModalState: false,
        url: ""
    }


    onPosterDiscussionModalOpen = (id) => {
        this.setState({ onPosterDiscussionModalState: true, url: "https://player.vimeo.com/video/" + id })
    }

    onPosterDiscussionModalClose = () => {
        this.setState({ onPosterDiscussionModalState: false })
    }

    render() {

        let EposterDiscusionRendering = "";
        let EposterDiscusionLinks = "";

        switch (this.props.categoryid) {
            case "1284":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("470116230")} className="eposter-discussion-link">Asma PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("477920810")} className="eposter-discussion-link">Asma PD2</div>
                    </div>;

                break;
            case "1285":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469925894")} className="eposter-discussion-link">Circulación Pulmonar PD1</div>
                    </div>;
                break;
            case "1286":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("470117575")} className="eposter-discussion-link">Cirugía Torácica PD1</div>
                    </div>;
                break;
            case "1287":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("477921023")} className="eposter-discussion-link">Enfermería Respiratoria PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("470118312")} className="eposter-discussion-link">Enfermería Respiratoria PD2</div>
                    </div>;
                break;
            case "1289":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469922130")} className="eposter-discussion-link">EPID PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469923914")} className="eposter-discussion-link">EPID PD2</div>
                    </div>;
                break;
            case "1288":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469924413")} className="eposter-discussion-link">EPOC PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469925001")} className="eposter-discussion-link">EPOC PD2</div>
                    </div>;
                break;
            case "1290":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("470117096")} className="eposter-discussion-link">EROM PD1</div>
                    </div>;
                break;
            case "1291":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469925546")} className="eposter-discussion-link">Fisioterapia Respiratoria PD1</div>
                    </div>;
                break;
            case "1292":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469825429")} className="eposter-discussion-link">Oncología Torácica PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469825873")} className="eposter-discussion-link">Oncología Torácica PD2</div>
                    </div>;
                break;
            case "1293":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469827748")} className="eposter-discussion-link">Tabaquismo PD1</div>
                    </div>;
                break;
            case "1280":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469828883")} className="eposter-discussion-link">Técnicas y Trasplante PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469869762")} className="eposter-discussion-link">Técnicas y Trasplante PD2</div>
                    </div>;
                break;
            case "1294":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469870143")} className="eposter-discussion-link">TIR PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469787446")} className="eposter-discussion-link">TIR PD2</div>
                    </div>;
                break;
            case "1300":
                EposterDiscusionLinks =
                    <div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469787998")} className="eposter-discussion-link">TRS / VM / CRC PD1</div>
                        <div onClick={() => this.onPosterDiscussionModalOpen("469824035")} className="eposter-discussion-link">TRS / VM / CRC PD2</div>
                    </div>;
                break;

            default:
                EposterDiscusionLinks = null;
                break;
        }

        if (this.state.onPosterDiscussionModalState) {
            EposterDiscusionRendering =

                <div className="eposter-discussion-modal-container">
                    <iframe id="EposterDiscusionVimeo" src={this.state.url} width="70%" height="70%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    <div onClick={this.onPosterDiscussionModalClose} className="stand-disclaimer-close-btn-container">Cerrar</div>
                </div>

                ;
        }
        else {
            EposterDiscusionRendering = EposterDiscusionLinks;
        }


        return (

            <div className="eposter-discussion-links-container">{EposterDiscusionRendering}</div>

        );
    }
}

export default EposterDiscussion;



