import React, { Component } from 'react';
import { PrivacyPolicyCNP2020 } from './PrivacyPolicy-CNP2020'
import { PrivacyPolicyPIEDIABETICO2021 } from './PrivacyPolicy-PIEDIABETICO2021'
import { PrivacyPolicyVPRACTICVM } from './PrivacyPolicy-VPRACTICVM'
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import configData from "../../config.json";
import AuthContext from "../../context/auth-context";
import { PrivacyPolicyVITDAH } from './PrivacyPolicy-VITDAH';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import { PrivacyPolicySEMNIM2021 } from './PrivacyPolicy-SEMNIM2021';
import { withTranslation } from 'react-i18next';
import { PrivacyPolicyCNP2021 } from './PrivacyPolicy-CNP2021';
import { PrivacyPolicyCNP2023 } from './PrivacyPolicy-CNP2023';


class MyProfile extends Component {

    state = {
        privacy_policy: this.props.privacy_policy,
        PrivacyPolicyPopUpOn: false,
        PrivacyInfo1: false,
        PrivacyInfo2: false
    }

    static contextType = AuthContext;

    onCheckPrivacyInfoHandler = (e) => {

        let PrivacyInfoCheck = "PrivacyInfo" + e.target.value;
        let isPrivacyInfoChecked = e.target.checked;
        var PrivacyInfoState = {};

        PrivacyInfoState[PrivacyInfoCheck] = isPrivacyInfoChecked;
        this.setState(PrivacyInfoState);


    }

    onCheckHandler = () => {
        this.setState((prevState, props) => {
            return {
                privacy_policy: !prevState.privacy_policy
            };
        })
    }

    onSaveMyProfileHandler = () => {




        if (this.state.privacy_policy) {

            let config = {
                headers: {
                    "x-auth-token": this.context.token,
                    "content-type": "application/json",
                }
            };

            let params = {
                "privacy_policy": this.state.privacy_policy,
                "privacy_info1": this.state.PrivacyInfo1,
                "privacy_info2": this.state.PrivacyInfo2
            };

            axios.post(configData.SERVER_API + "/users/me/policy", params, config)
                .then(response => {
                    if (response.data !== undefined && response.data.privacy_policy) {
                        this.props.onPolicyAccepted();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } else {

        }
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    render() {

        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;

        let titleText, welcomeText, policyText, policyIntro, policyCheckText
        let sponsor = "";

        switch (this.context.event_id) {
            //case '0': // CNP 2020


            case "314":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL  53 º CONGRESO VIRTUAL SEPAR 2020';
                policyIntro = `
                <p><strong>La Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente al 53 Congreso Nacional Virtual de la SEPAR, accede a ser grabado en las intervenciones en las que participe así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/separ2020/myprofile/separ2020-myprofile-logo.png" />
                </div>

                break;


            case "383":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL 54º CONGRESO SEPAR';
                policyIntro = `
                    <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR 
                    (C/ Provença, 108, Bajos 2ª 08029  Barcelona - España)</p>
                    <p>Rogamos tenga en cuenta que, en calidad de asistente al 54º Congreso SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                    `;
                policyText = `
                    <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                    <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                    
                    `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/separ2020/myprofile/separ2020-myprofile-logo.png" />
                </div>

                break;

            case "315":

                titleText = 'Política de Privacidad';
                welcomeText = 'V PRACTICVM DERMATOLOGIA PEDIATRICA 2021';
                policyIntro = ''
                policyText = PrivacyPolicyVPRACTICVM;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el curso."
                break;

            case "342":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al Simposio de Abordaje Integral del Pie Diabético 2021.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes.</p>
                <p>El Comité Organizador no se responsabiliza del contenido presentado en los expositores del Exhibition Area. </p>
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, el Comité Organziador se reserva el derecho de restricción de acceso al simposio. </p>
                `
                policyText = PrivacyPolicyPIEDIABETICO2021;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el simposio."
                break;


            case "353":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al 32º Congreso Virtual de la Sociedad Española de Alergología e Inmunología Clínica 2020.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible para todos los asistentes.</p>

                <p>La SEAIC no se responsabiliza del contenido presentado en los expositores del Área de expositores. </p>
                
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la SEAIC se reserva el derecho de restricción de acceso al congreso.</p>`
                policyText = "<a href='https://www.seaic.org/inicio/privacidad' target='_blank'>POLÍTICA PRIVACIDAD https://www.seaic.org/inicio/privacidad</a>";
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                sponsor = <div className="login-company-logo">
                    <img src="/img/Allergy-Therapeutics-LogoTransparente-2019.png" />
                </div>

                break;

            case "363":

                titleText = 'Privacy Policy';
                welcomeText = 'Welcome to 3st LAHRS Congress,';
                policyIntro = `
                    <p>Once you access the congress, your presence and interaction in it will be recorded and stored on the server. Your participation in the session chats will be visible to all attendees.</p>
    
                    <p>LAHRS is not responsible for the content showed by the exhibitors in the Exhibition Area.</p>
                    
                    <p>By accessing the virtual platform, you acknowledge that the information you share is true and real. In the event of detecting that is not the case, LAHRS reserves the right to restrict the access to the congress.</p>`
                policyText = "<a href='https://www.emma.events/static/virtualvenue/lahrs2020/policy/lahrs2020-privacy-policy.pdf' target='_blank'>PRIVACY POLICY</a>";
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;

            case "405":

                titleText = 'Privacy Policy';
                welcomeText = 'Welcome to 4th LAHRS Congress,';
                policyIntro = `
                        <p>Once you access the congress, your presence and interaction in it will be recorded and stored on the server. Your participation in the session chats will be visible to all attendees.</p>
        
                        <p>LAHRS is not responsible for the content showed by the exhibitors in the Exhibition Area.</p>
                        
                        <p>By accessing the virtual platform, you acknowledge that the information you share is true and real. In the event of detecting that is not the case, LAHRS reserves the right to restrict the access to the congress.</p>`
                policyText = "<a href='https://www.emma.events/static/virtualvenue/lahrs2021/policy/lahrs2021-privacy-policy.pdf' target='_blank'>PRIVACY POLICY</a>";
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;


            case "367":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXIII Congreso Nacional de Psiquiatría 2020.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes. </p>

                <p>La FEPSM, SEP y SEPB no se responsabiliza del contenido presentado en los expositores del Exhibition Area. </p>
                
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM, SEP y SEPB se reservan el derecho de restricción de acceso al congreso. </p>`
                policyText = PrivacyPolicyCNP2020;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;


            case "378":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL 2º CONGRESO NACIONAL MULTIDISCIPLINAR COVID19';
                policyIntro = `
                <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente al 2º Congreso Nacional Multidisciplinar COVID19, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                `
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                break;

            case "387":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al Curso de Actualización Avances en el manejo clínico del TDAH';
                policyIntro = ''
                policyText = `
                <table class="tbl-privacy-policy-vitdah">
                        <tr>
                            <td class="title" style="text-align:center" colspan="2">Información básica sobre Protección de Datos</td>
                        </tr>
                        <tr>
                            <td class="title">Responsable</td>
                            <td>Laboratorios Farmacéuticos Rovi, S.A.– Ver tabla “Información General”</td>
                        </tr>
                        <tr>
                            <td class="title">Finalidad</td>
                            <td>Las indicadas en la presente Política de Privacidad.</td>
                        </tr>
                        <tr>
                            <td class="title">Legitimación</td>
                            <td>Los datos personales serán tratados atendiendo al artículo 6 del RGPD – ver detalle a continuación</td>
                        </tr>
                        <tr>
                            <td class="title">Destinatarios</td>
                            <td>Los Usuarios de la Web, los Accionistas o Inversores de la Compañía o los Asistentes a los eventos.</td>
                        </tr>
                        <tr>
                            <td class="title">Derechos</td>
                            <td>Podrá ejercer en cualquier momento los derechos reconocidos en materia de protección de datos dirigiéndose a los Laboratorios Farmacéuticos Rovi, S.A. en la dirección indicada en la tabla “Información General”</td>
                        </tr>
                        <tr>
                            <td class="title">Información adicional</td>
                            <td>Le invitamos a leer nuestra Política de Privacidad a continuación para entender en detalle el uso que haremos de sus datos personales y los derechos de los que dispone en relación con los mismos.</td>
                        </tr>
                    </table>
                `;
                //policyCheckText = "*He leído y acepto la política de privacidad"
                policyCheckText = <span> *He leído y acepto la política de privacidad <a onClick={this.onPrivacyPolicyOpenHandler} href="#" rel="noopener"><u>Política de privacidad</u></a> </span>
                break;


            case "2":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al VI CONGRESO 2021 - UROVI VIRTUAL';
                policyIntro = ''
                policyText = `
                <table class="tbl-privacy-policy-vitdah">
                        <tr>
                            <td class="title" style="text-align:center" colspan="2">Información básica sobre Protección de Datos</td>
                        </tr>
                        <tr>
                            <td class="title">Responsable</td>
                            <td>Laboratorios Farmacéuticos Rovi, S.A.– Ver tabla “Información General”</td>
                        </tr>
                        <tr>
                            <td class="title">Finalidad</td>
                            <td>Las indicadas en la presente Política de Privacidad.</td>
                        </tr>
                        <tr>
                            <td class="title">Legitimación</td>
                            <td>Los datos personales serán tratados atendiendo al artículo 6 del RGPD – ver detalle a continuación</td>
                        </tr>
                        <tr>
                            <td class="title">Destinatarios</td>
                            <td>Los Usuarios de la Web, los Accionistas o Inversores de la Compañía o los Asistentes a los eventos.</td>
                        </tr>
                        <tr>
                            <td class="title">Derechos</td>
                            <td>Podrá ejercer en cualquier momento los derechos reconocidos en materia de protección de datos dirigiéndose a los Laboratorios Farmacéuticos Rovi, S.A. en la dirección indicada en la tabla “Información General”</td>
                        </tr>
                        <tr>
                            <td class="title">Información adicional</td>
                            <td>Le invitamos a leer nuestra Política de Privacidad a continuación para entender en detalle el uso que haremos de sus datos personales y los derechos de los que dispone en relación con los mismos.</td>
                        </tr>
                    </table>
                `;
                //policyCheckText = "*He leído y acepto la política de privacidad"
                policyCheckText = <span> *He leído y acepto la política de privacidad <a onClick={this.onPrivacyPolicyOpenHandler} href="#" rel="noopener"><u>Política de privacidad</u></a> </span>
                break;


            case "3":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDO AL TROMBO ENCUENTROS 2021 - MANEJO DEL PACIENTE FRÁGIL CON ETV - JORNADA VIRTUAL';
                policyIntro = ''
                policyText = `
                <table class="tbl-privacy-policy-vitdah">
                        <tr>
                            <td class="title" style="text-align:center" colspan="2">Información básica sobre Protección de Datos</td>
                        </tr>
                        <tr>
                            <td class="title">Responsable</td>
                            <td>Laboratorios Farmacéuticos Rovi, S.A.– Ver información general en la política de privacidad completa</td>
                        </tr>
                        <tr>
                            <td class="title">Finalidad</td>
                            <td>Las indicadas en la presente Política de Privacidad.</td>
                        </tr>
                        <tr>
                            <td class="title">Legitimación</td>
                            <td>Los datos personales serán tratados atendiendo al artículo 6 del RGPD – ver detalle a continuación</td>
                        </tr>
                        <tr>
                            <td class="title">Destinatarios</td>
                            <td>Los Usuarios de la Web, los Asistentes y ponentes de los eventos.</td>
                        </tr>
                        <tr>
                            <td class="title">Derechos</td>
                            <td>Podrá ejercer en cualquier momento los derechos reconocidos en materia de protección de datos dirigiéndose a los Laboratorios Farmacéuticos Rovi, S.A. en la dirección indicada en la información general de la política de privacidad completa.</td>
                        </tr>
                        <tr>
                            <td class="title">Información adicional</td>
                            <td>Le invitamos a leer nuestra Política de Privacidad a continuación para entender en detalle el uso que haremos de sus datos personales y los derechos de los que dispone en relación con los mismos.</td>
                        </tr>
                    </table>


                    

                `;
                //policyCheckText = "*He leído y acepto la política de privacidad"
                policyCheckText = <span> *He leído y acepto la <a onClick={this.onPrivacyPolicyOpenHandler} href="#" rel="noopener"><u>política de privacidad</u></a> </span>
                break;


            case "4":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDO AL VI AMPLIANDO HORIZONTES EN LA ETEV 2021';
                policyIntro = ''
                policyText = `
                <table class="tbl-privacy-policy-vitdah">
                        <tr>
                            <td class="title" style="text-align:center" colspan="2">Información básica sobre Protección de Datos</td>
                        </tr>
                        <tr>
                            <td class="title">Responsable</td>
                            <td>Laboratorios Farmacéuticos Rovi, S.A.– Ver información general en la política de privacidad completa</td>
                        </tr>
                        <tr>
                            <td class="title">Finalidad</td>
                            <td>Las indicadas en la presente Política de Privacidad.</td>
                        </tr>
                        <tr>
                            <td class="title">Legitimación</td>
                            <td>Los datos personales serán tratados atendiendo al artículo 6 del RGPD – ver detalle a continuación</td>
                        </tr>
                        <tr>
                            <td class="title">Destinatarios</td>
                            <td>Los Usuarios de la Web, los Asistentes y ponentes de los eventos.</td>
                        </tr>
                        <tr>
                            <td class="title">Derechos</td>
                            <td>Podrá ejercer en cualquier momento los derechos reconocidos en materia de protección de datos dirigiéndose a los Laboratorios Farmacéuticos Rovi, S.A. en la dirección indicada en la información general de la política de privacidad completa.</td>
                        </tr>
                        <tr>
                            <td class="title">Información adicional</td>
                            <td>Le invitamos a leer nuestra Política de Privacidad a continuación para entender en detalle el uso que haremos de sus datos personales y los derechos de los que dispone en relación con los mismos.</td>
                        </tr>
                    </table>


                    

                `;
                //policyCheckText = "*He leído y acepto la política de privacidad"
                policyCheckText = <span> He leído y acepto la <a onClick={this.onPrivacyPolicyOpenHandler} href="#" rel="noopener"><u>política de privacidad</u></a> </span>
                break;


            case "5":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDO AL VII CURSO DE ACTUALIZACIÓN AVANCES EN EL MANEJO CLÍNICO DEL TDAH 2022';
                policyIntro = ''
                policyText = `
                <table class="tbl-privacy-policy-vitdah">
                        <tr>
                            <td class="title" style="text-align:center" colspan="2">Información básica sobre Protección de Datos</td>
                        </tr>
                        <tr>
                            <td class="title">Responsable</td>
                            <td>Laboratorios Farmacéuticos Rovi, S.A.– Ver información general en la política de privacidad completa</td>
                        </tr>
                        <tr>
                            <td class="title">Finalidad</td>
                            <td>Las indicadas en la presente Política de Privacidad.</td>
                        </tr>
                        <tr>
                            <td class="title">Legitimación</td>
                            <td>Los datos personales serán tratados atendiendo al artículo 6 del RGPD – ver detalle a continuación</td>
                        </tr>
                        <tr>
                            <td class="title">Destinatarios</td>
                            <td>Los Usuarios de la Web, los Asistentes y ponentes de los eventos.</td>
                        </tr>
                        <tr>
                            <td class="title">Derechos</td>
                            <td>Podrá ejercer en cualquier momento los derechos reconocidos en materia de protección de datos dirigiéndose a los Laboratorios Farmacéuticos Rovi, S.A. en la dirección indicada en la información general de la política de privacidad completa.</td>
                        </tr>
                        <tr>
                            <td class="title">Información adicional</td>
                            <td>Le invitamos a leer nuestra Política de Privacidad a continuación para entender en detalle el uso que haremos de sus datos personales y los derechos de los que dispone en relación con los mismos.</td>
                        </tr>
                    </table>


                    

                `;
                //policyCheckText = "*He leído y acepto la política de privacidad"
                policyCheckText = <span> He leído y acepto la <a onClick={this.onPrivacyPolicyOpenHandler} href="#" rel="noopener"><u>política de privacidad</u></a> </span>
                break;


            case "372":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 1ª REUNIÓN VIRTUAL ÁREAS SEPAR Y I SIMPOSIO MULTIDISCIPLINAR';
                policyIntro = `
                <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR</p> 
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España)</p> 
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 1ª Reunión Virtual Áreas SEPAR y I Simposio Multidisciplinar, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."


                break;


            case "373":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 2ª REUNIÓN VIRTUAL ÁREAS SEPAR Y II SIMPOSIO MULTIDISCIPLINAR';
                policyIntro = `
                <p>La Sociedad Española de Neumología y Cirugía Torácica (SEPAR) pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 2ª Reunión Virtual Áreas SEPAR y II Simposio Multidisciplinar, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."


                break;

            case "424":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 3ª REUNIÓN VIRTUAL ÁREAS SEPAR';
                policyIntro = `
                <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 3ª Reunión Virtual Áreas SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."


                break;


            case "425":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 4ª REUNIÓN VIRTUAL ÁREAS SEPAR ';
                policyIntro = `
                    <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                    <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                    <p>Rogamos tenga en cuenta que, en calidad de asistente a la 4ª Reunión Virtual Áreas SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                    `;
                policyText = `
                    <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                    <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                    
                    `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."


                break;


            case "336":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL XXXI CONGRESO NACIONAL DE LA SEFC';
                policyIntro = `
                    <p>La Sociedad Española de Farmacología Clínica (SEFC) pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEFC. Los datos facilitados tienen carácter OBLIGATORIO para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEFC</p>
                    <p>(Santa Isabel, 51 (Ilustre Colegio de Médicos de Madrid) Madrid 28012 España)</p>
                    <p>Rogamos tenga en cuenta que, en calidad de asistente al XXXI Congreso Nacional de la SEFC, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma.</p>
                    `;
                policyText = `
                    <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                    <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEFC para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEFC en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                    
                    `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su consentimiento para el tratamiento de sus datos personales por parte de SEFC de acuerdo a lo expuesto."


                break;


            case "376":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al 38 Congreso Nacional de la Sociedad Española de Medicina Nuclear e Imagen Molecular 2021.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes. </p>
                <p>El Comité Organizador no se responsabiliza del contenido presentado en los expositores de la Exposición Comercial. </p>
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, el Comité Organizador se reserva el derecho de restricción de acceso al simposio. </p>
                `;
                policyText = PrivacyPolicySEMNIM2021;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el simposio."
                break;


            case "391":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXXIII Congreso Virtual de la Sociedad Española de Arteriosclerosis';
                policyIntro = `SOCIEDAD ESPAÑOLA DE ARTERIOESCLEROSIS como responsable del tratamiento tratará tus datos con la finalidad de
                gestionar tu participación como participante en nuestro Congreso (nombrar congreso especifico a participar). La base
                legítima es tu consentimiento el cual puedes revocar en cualquier momento comunicándolo a secretaria@searteriosclerosis.
                org. No se cederán datos a terceros salvo obligación legal. Puedes acceder, rectificar y suprimir tus
                datos, así como ejercer otros derechos consultando la información adicional y detallada sobre protección de datos en
                nuestra Política de Privacidad en la siguiente direccion<br /><a target="_blank" href="http://www.se-arteriosclerosis.org/">http://www.se-arteriosclerosis.org/</a>`;
                policyText = "";
                policyCheckText = "*He leído y acepto las condiciones contenidas en la política de privacidad sobre el tratamiento de mis datos para participar en elñ congreso de la SOCIEDAD ESPAÑOLA DE ARTERIOESCLEROSIS."
                break;


            case "397":

                titleText = 'Privacy Policy';
                welcomeText = 'You are about to enter a Symposium organized by inomed Medizintechnik GmbH';
                policyIntro = `In order to enable the organizer to send you commertial information & materials regarding the content of this event, TORRES PARDO, must collect confirmation of your explicit consent in the transferring of personal data to inomed Medizintechnik GmbH.`;
                policyText = `
                <pThe personal data that TORRES PARDO will transfer to inomed Medizintechnik GmbH, is the following:</p>
                <p><strong>Your name</strong></p>
                <p><strong>Your adress</strong></p>
                <p><strong>Your position and work place</strong></p>
                <p><strong>Your professional email  </strong></p>
                <p>inomed Medizintechnik GmbH will include all transferred data in a data base property of inomed Medizintechnik GmbH. We would like to remind you that the right of access, rectification & erasure, restriction of data processing, data portability and to object an automated individual decision making, can be exercised at any time. </p>
                <p>For further information on how inomed Medizintechnik GmbH processes personal data and on how to exercise your rights, please follow this link:</p>
                <p><a target="_blank" href="https://www.en.inomed.com/privacy-policy/">https://www.en.inomed.com/privacy-policy/</a></p>
                `;
                policyCheckText = "I consent TORRES PARDO to transfer my personal data (name, adress, position and work place & email) to inomed Medizintechnik GmbH."
                break;


            case "325":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al Congreso Virtual SEMCPT // 3-4 de junio del 2021';
                policyIntro = `Acepto compartir mis datos en el caso de asistir a una Sesión Patrocinada para recibir información sobre el producto, temática, técnica etc presentados en dicha Sesión.`;
                policyText = "";
                policyCheckText = "*Doy mi consentimiento a TORRES PARDO para transferir mis datos (nombre, dirección, lugar de trabajo y correo electrónico) a las Empresas organizadoras de las Sesiones Patrocinadas a las que asista."
                break;


            case "341":

                titleText = 'Privacy Policy';
                welcomeText = 'Welcome to 29th Meeting of the European Society of Thoracic Surgeons // 20-22 June 2021 - Virtual Meeting';
                policyIntro = `Purpose of this privacy policy.`;
                policyText = `
                <p>This privacy policy aims to give you information on how we collect and processes your personal data. This privacy policy also describes your privacy rights and how the law protects you.</p>
                <p>Much of this this privacy policy is concerned with your use of our website but it applies to any personal data you provide to us, including as a result of your membership.</p>
                <p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
                <p><a target="_blank" href="https://www.emma.events/static/virtualvenue/ests2021/privacy-policy/ESTS2021-Privacy-Policy.pdf">Read the full Privacy Policy</a></p>
                
                `;
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;


            case "351":

                titleText = 'Privacy Policy';
                welcomeText = 'Welcome to 29 SECEC-ESSSE CONGRESS // POZNAN 2021 · POLAND · SEPTEMBER 15-18 // FACE TO FACE AND STREAMING FORMAT';
                policyIntro = `Purpose of this privacy policy.`;
                policyText = `
                    <p>This privacy policy aims to give you information on how we collect and processes your personal data. This privacy policy also describes your privacy rights and how the law protects you.</p>
                    <p>Much of this this privacy policy is concerned with your use of our website but it applies to any personal data you provide to us, including as a result of your membership.</p>
                    <p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
                    <p><a target="_blank" href="https://emma.events/static/upload/ow51/events/ev351/Site/files/privacy_policy_secec2021.pdf">Read the full Privacy Policy</a></p>
                    
                    `;
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;

            case "263":

                titleText = 'Privacy Policy';
                welcomeText = '25th Congress of the European Association for Cranio Maxillo Facial Surgery // 14-16 July 2021 - Virtual Meeting';
                policyIntro = `Purpose of this privacy policy.`;
                policyText = `
                    <p>This privacy policy aims to give you information on how we collect and processes your personal data. This privacy policy also describes your privacy rights and how the law protects you.</p>
                    <p>Much of this this privacy policy is concerned with your use of our website but it applies to any personal data you provide to us, including as a result of your membership.</p>
                    <p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
                    
                    
                    `;
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;


            case "410":

                titleText = 'Privacy Policy';
                welcomeText = '26th Congress of the European Association for Cranio Maxillo Facial Surgery';
                policyIntro = `Purpose of this privacy policy.`;
                policyText = `
                        <p>This privacy policy aims to give you information on how we collect and processes your personal data. This privacy policy also describes your privacy rights and how the law protects you.</p>
                        <p>Much of this this privacy policy is concerned with your use of our website but it applies to any personal data you provide to us, including as a result of your membership.</p>
                        <p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
                        
                        
                        `;
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;



            case "320":

                titleText = 'Privacy Policy';
                welcomeText = '69th WDA – 14th EWDA Joint Conference of Cuenca';
                policyIntro = `By completing the registration to the 69th WDA – 14th EWDA Joint Conference of Cuenca (Spain) in 2020 I understand that the WDA/ EWDA Joint Conference is an initiative of the (European) Wildlife Disease Association. The Organizing Committee, and ‘Viajes El Corte Inglés’ on behalf of it, are collecting personal data of the participants during registration in order to establish a fluid communication with the Conference participants, inform them on any aspect of their interest only in relation to the Conference and for logistic reasons. The personal data involved are names, address, email addresses, telephone numbers, and profesional data. These data will be kept privately by ‘Viajes El Corte Inglés’ and the Organizing Committee only for the purpose of the Conference and will be erased once the Conference is finished and any required communication with participants has come to an end. All data provided will remain hidden. You have a right to receive a copy of the data that you provided, to lodge a complaint with the Data Protection Authority, and to withdraw consent for your data to be used in the 2020 WDA-EWDA Joint Conference at any time`;
                policyText = `
                    <p><strong>Organizational details</strong></p>
                    <p><strong>PRIVACY:</strong><br /> We hereby inform you that personal data such as your contact details, your nutritional preferences, your payment details will be processed by us in connection with this conference. These data will be processed solely for the purpose of organising your participation in the event and to contact you during the event. Data economy and the security of your data as well as your rights as data subjects according to the EU-DSGVO are very important to us. Please find more detailed information on this in our data protection declaration.</p>
                    <p><strong>DATA PROTECTION STATEMENT FOR EVENT PARTICIPANTS:</strong></p>
                    <p>- Name and address of the responsible person The responsible person within the meaning of the EU data protection basic regulation ("EU-DSGVO") and other national data protection laws of the member states as well as other data protection regulations is</p>
 
                    <p>- Processing of participant data. If you participate in an event of the 69th WDA – 24th EWDA Joint Conference, we process your personal data. This includes your surname, first name, professional affiliation/employer, position, e-mail address, photographs and video recordings as well as your telephone number if you provide it voluntarily. We do not plan to process special categories of personal data about you. If you provide us with such personal data, the processing by us will include such data.</p>
                    
                    <p>- Purpose and legal basis. We process your personal data for the purpose of the event for which you register. The legal basis for this data processing is Art. 6 paragraph 1 sentence 1 lit. EU-DSGVO. These are the interests in the documentation of such events for the purpose of public relations, for the documentation.</p>
                    
                    <p>- Duration of storage. We delete your personal data that you have provided to us for the purpose of attending an event no later than two years after the end of the year in which you attended the event.</p>
                    
                    <p>- Consequences of not providing. The provision of personal data is necessary to participate in the 69th WDA – 14th EWDA Joint Conference. You are not obliged to provide us with these personal data. If you do not provide us with the personal data required for the event, we may not be able to consider you as a participant of the respective event.</p>
                    
                    <p>- Recipient of personal data. We ensure that we only disclose your personal data to persons who need to know the dates for the purposes described in this privacy statement. If you register for our event via website/company name, website/company name will also process your personal data for the purpose of your registration for XYZ events. We have concluded an order processing agreement with ‘Viajes El Corte Inglés’ in accordance with Art. 28 Para. 3 DS-GVO.</p>
                    
                    <p>- Rights of the data subject and further information. If personal data is processed about you, you are a data subject or affected person within the meaning of the EU-DSGVO and you have rights against us as the person responsible. In particular, you have the right to object at any time to the processing of personal data relating to you on the basis of Art. 6 Para. 1 S.1 f) EU-DSGVO. All rights to which you are entitled as a data subject can be found in the general data protection declaration.</p>
                    `;
                policyCheckText = "*By accepting, you agree the Privacy Policy and the storage of your activity at the congress."
                break;


            case "379":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL XXVI CONGRESO NEUMOMADRID 2021';
                policyIntro = `<p>La <strong>Sociedad Madrileña de Neumología y Cirugía Torácica (NEUMOMADRID)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de NEUMOMADRID. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a NEUMOMADRID</p>
                                <p>(C/ Cea Bermúdez, 46 28003 Madrid - España)</p>
                                <p>Rogamos tenga en cuenta que, en calidad de asistente al XXVI Congreso Neumomadrid 2021, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma.</p>`;
                policyText = `<hr /><p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                             <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la NEUMOMADRID para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la NEUMOMADRID  en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>`;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de Neumomadrid de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/vivisol-mybag.jpg" />
                </div>
                break;


            case "337":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL 8º Congreso Conjunto AEA-SEROD 2021 - 22 a 25 de septiembre // 29º Curso de Enfermería en Artroscopia y Rodilla';
                policyIntro = `<p>La <strong>AEA-SEROD</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de AEA-SEROD. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a AEA-SEROD</p>
                                
                                <p>Rogamos tenga en cuenta que, en calidad de asistente al 8º Congreso Conjunto AEA-SEROD 2021, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma.</p>`;
                policyText = `<hr /><p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                             <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la AEA-SEROD para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la AEA-SEROD  en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>`;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de AEA-SEROD de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/vivisol-mybag.jpg" />
                </div>
                break;


            case "420":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXIV Congreso Nacional de Psiquiatría 2021.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes.</p>
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM, SEP y SEPB se reservan el derecho de restricción de acceso al congreso. </p>`
                policyText = PrivacyPolicyCNP2021;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;

            case "489":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXV Congreso Nacional de Psiquiatría 2022.';
                policyIntro = `
                    <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes.</p>
                    <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM, SEP y SEPB se reservan el derecho de restricción de acceso al congreso. </p>`
                policyText = PrivacyPolicyCNP2021;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;

            case "575":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXVI Congreso Nacional de Psiquiatría 2023.';
                policyIntro = `
                    <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes.</p>
                    <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM se reserva el derecho de restricción de acceso al congreso.</p>`
                policyText = PrivacyPolicyCNP2023;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;  
                
                
                case "656":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXVII Congreso Nacional de Psiquiatría 2024.';
                policyIntro = `
                    <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes.</p>
                    <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM se reserva el derecho de restricción de acceso al congreso.</p>`
                policyText = PrivacyPolicyCNP2023;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;      



            default:
                titleText = '';
                welcomeText = '';
                policyIntro = '';
                policyText = ''
                policyCheckText = "";
                break;
        }

        let role;
        switch (this.props.profile) {
            case 'participant':
                role = "Asistente"
                break;
            case 'participant-pay-per-view':
                role = "Asistente"
                break;
            case 'secretariat':
                role = "Secretaria"
                break;
            case 'delegate':
                role = "Delegado"
                break;
            case 'delegate-stand':
                role = "Delegado"
                break;
            case 'delegate-congress':
                role = "Delegado"
                break;
            case 'delegate-assistant':
                role = "Delegado"
                break;
            case 'delegate-congress':
                role = "Delegado"
                break;
            case 'delegate-stand-checker':
                role = "Delegado"
                break;
            default:
                role = "Asistente"
                break;
        }

        return (

            <div>
                {PrivacyPolicyPopUp}
                <div className="login-my-profile-container">


                    {this.context.event_id === "353" || this.context.event_id === "314" || this.context.event_id === "383" ? <React.Fragment>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-title">Mi perfil</span>
                        </div>

                        <div className="login-my-profile-spacer"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-sponsored-by">PATROCINADO POR</span>
                        </div>

                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">

                            <div className="avatar-profile-add">
                                {this.props.userPhoto !== undefined && this.props.userPhoto !== ""
                                    ? <img src={this.props.userPhoto} style={{ borderRadius: `50%` }} />
                                    : <img src="/img/default-login-pic.png" />}
                            </div>
                        </div>

                        <div className="login-my-profile-spacer"></div>

                        <div className="login-my-profile-wrapper-split">
                            {sponsor}
                        </div>

                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>
                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-subtitle">Datos personales</span>
                        </div>

                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Nombre</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="name" name="name" placeholder="Nombre" readOnly disable="true" value={this.props.name} />
                            </div>
                        </div>

                        <div className="login-my-profile-spacer"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Apellidos</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="surname" name="surname" placeholder="Apellidos" readOnly disable="true" value={this.props.surname} />
                            </div>
                        </div>

                        <div style={{ clear: `both` }}></div>
                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Email</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="name" name="name" placeholder="Email" readOnly disable="true" value={this.props.email} />
                            </div>
                        </div>


                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">ROL</span>
                            <div className="login-my-profile-roll">{role}</div>
                        </div>

                        <div style={{ clear: `both` }}></div>
                        <div className="login-my-profile-separator"></div>
                    </React.Fragment>

                        : null}


                    <div className="login-my-profile-wrapper-split">
                        <span className="login-my-profile-subtitle">{titleText}</span>
                    </div>

                    <div style={{ clear: `both` }}></div>
                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{welcomeText}</div>

                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{ReactHtmlParser(policyIntro)}</div>

                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{ReactHtmlParser(policyText)}</div>

                    <div className="login-my-profile-separator"></div>

                    <div className="login-my-profile-rgpd-check">
                        {this.state.privacy_policy
                            ? <input type="checkbox" name="optnetworking" checked onClick={this.onCheckHandler} />
                            : <input type="checkbox" name="optnetworking" onClick={this.onCheckHandler} />
                        }
                    </div>

                    <div className="login-my-profile-rgpd-text">{policyCheckText}</div>

                    <div style={{ clear: `both` }}></div>

                    <div className="login-my-profile-separator"></div>

                    {this.context.event_id === "391" ?


                        <div>


                            <p style={{ fontFamily: `MyriadPro` }}><strong>Nos gustaría que nos prestaras tu consentimiento para:</strong></p>

                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy1" value="1" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">Enviarte información comercial sobre las novedades, eventos y actividades organizadas, por la SOCIEDAD ESPAÑOLA DE ARTERIOESCLEROSIS (vía email).</div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy2" value="2" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">Enviarte información de terceros relacionados con las actividades de la Sociedad Española de Arterioesclerosis y la Fundación Española de Arteriosclerosis (vía email)</div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>

                        </div>

                        : null

                    }


                    {this.context.event_id === "397" ?


                        <div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy1" value="1" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">I fully understand that inomed Medizintechnik GmbH will use my data to contact me after the Symposium, offering me commertial information and materials regarding the content of the Symposium and hereby consent.</div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>

                        </div>

                        : null

                    }



                    {this.context.event_id === "341" || this.context.event_id === "351" ?


                        <div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy1" value="1" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">(In case of attending / watching a Symposium or Sponsored Session) I agree that the Company will use my data to contact me after the Symposium or Sponsored Session offering me commercial information and materials regarding the content of the Symposium / Sponsored Session, I consent the Technical Secretariat to transfer my personal data (name, institution & email) to Company for this purpose  </div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>

                        </div>

                        : null

                    }


                    {this.context.event_id === "263" ?


                        <div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy1" value="1" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">Consent the Technical Secretariat to transfer my personal data (name, city & email) to those companies whose booth I will visit during the congress. </div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy2" value="2" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">(In case of attending / watching a Symposium or Sponsored Session) I agree that the Company will use my data to contact me after the Symposium or Sponsored Session offering me commercial information and materials regarding the content of the Symposium / Sponsored Session, I consent the Technical Secretariat to transfer my personal data (name, city & email) to Company for this purpose </div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>

                        </div>

                        : null

                    }


                    {this.context.event_id === "325" ?


                        <div>


                            <div className="login-my-profile-rgpd-check">
                                <input type="checkbox" name="privacy1" value="1" onChange={this.onCheckPrivacyInfoHandler} />
                            </div>

                            <div className="login-my-profile-rgpd-text">Entiendo y acepto que la/s Empresa/s utilizarán mis datos para comunicarse conmigo después de la Sesión Patrocinada, ofreciéndome información comercial y materiales relacionados con el contenido de dicha Sesión.</div>


                            <div style={{ clear: `both` }}></div>

                            <div className="login-my-profile-separator"></div>

                        </div>

                        : null

                    }


                    <div className="login-my-profile-send-btn" style={{ textTransform: "uppercase" }} onClick={this.onSaveMyProfileHandler}>{this.props.t('contact_request_accept')}</div>

                </div>

            </div>
        );
    }
}

export default withTranslation()(MyProfile);