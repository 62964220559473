import React from 'react';

function MyBagInfo(props) {

    let sponsorLogo;
    let qtd = 0;
    switch (props.eventId) {
        case "367":
            sponsorLogo = <img src="/img/mybag_176x41.png" alt="" />
            break;
        case "353":
            sponsorLogo = <img src="/img/Allergy-Therapeutics-LogomyProfil2.jpg" alt="" />
            break;
        case "363":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag-logo.png" alt="" />
            break;
        case "405":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag-logo.png" alt="" />
            break;
        case "314":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybaginfo-logo.png" alt="" />
            qtd = 17;
            break;
        case "342":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/simposiopiediabetico2021-logo-mybag.jpg" alt="" />
            break;
        case "289":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/seip2021/lobby/seip2021-logo-mybag.jpg" alt="" />
            break;
        case "372":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/astrazeneca-mybag.png" alt="" />
            qtd = 2;
            break;

        case "373":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/industria/logos/estevin-logo.jpg" alt="" />
            qtd = 2;
            break;
        case "376":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/semnim2021/mybag/semnim2021-mybag-top.jpg" alt="" />
            break;
        case "320":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/cuenca2021-mybag.png" alt="" />
            break;
        case "379":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/vivisol-mybag.jpg" alt="" />
            break;
        case "420":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag_cnp2021.png" alt="" />
            break;
        case "489":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag_cnp2022.png" alt="" />
            break;
        case "575":
            sponsorLogo = <img src="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag_cnp2023.png" alt="" />
            break;     

        case "383":
            sponsorLogo = "";
            qtd = 10;
            break;
        case "424":
            sponsorLogo = "";
            qtd = 2;
            break;
        case "425":
            sponsorLogo = "";
            qtd = 4;
            break;

        default:
            sponsorLogo = null;
            break;
    }

    return (
        <div className="right-info-wrapper" onClick={props.showHide}>
            <div className="right-info-item">
                <div className="number-wrapper number-my-bag">
                    {qtd > 0 ?
                        <span className="span-number" alt="">{qtd}</span>
                        : null}
                    <br />
                </div>
                <div className="right-info-icon-wrapper">
                    <img src="/img/icon-my-bag.png" alt="" />
                    &nbsp;{sponsorLogo}
                </div>
            </div>
        </div>
    );
}

export default MyBagInfo;