
import * as actionTypes from './actionTypes';
import {} from './global';

// action creators
export const secretariat = (availableSecretariat) =>{
    return {
        type: actionTypes.SECRETARIAT_UPDATE,
        availableSecretariat: availableSecretariat
    }
}

export const secretariat_contact_request_start = (contact_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CONTACT_REQUEST_START,
        contact: contact_in
    }
}

export const secretariat_contact_request_receive = (contact_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CONTACT_REQUEST_RECEIVE,
        contact: contact_in
    }
}
export const secretariat_contact_request_EMIT = (contact_in) => {
    return (dispatch, getState) => {        
        const currentSocket = getState().global.socket;
        const data = contact_in.emit;

        currentSocket.emit('secretariat-contact-request', data)

        dispatch(secretariat_contact_request_start(contact_in));

    }
}

export const secretariat_contact_close = (contact_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CONTACT_CLOSE,
        contact: contact_in
    }
}

export const secretariat_contact_reply = (reply_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CONTACT_REPLY,
        reply: reply_in
    }
}

export const secretariat_chat_message_render = (message_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CHAT_MESSAGE_RENDER,
        message: message_in
    }
}
export const secretariat_chat_message_post = (message_in) =>{
    return {
        type: actionTypes.SECRETARIAT_CHAT_MESSAGE_POST,
        message: message_in
    }
}

export const secretariat_chat_message_post_EMIT = (message_in) => {
    return (dispatch, getState) => {        
        const currentSocket = getState().global.socket;
        const currentChatId = getState().secretariat.chatRoom;
        const data = {
            ...message_in,
            chatId: currentChatId
        }
        
        currentSocket.emit('secretariat-chat-message', data)

        //dispatch(secretariat_chat_message_post(message_in));
    }
}
