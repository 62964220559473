export const PrivacyPolicyCNP2023 = `

<p>
	Denominaci&oacute;n Social: Fundaci&oacute;n Espa&ntilde;ola de Psiquiatr&iacute;a y Salud Mental<br />
	Domicilio Social: C/ Arturo Soria, 311, 1&ordm; B, 28033 Madrid<br />
	CIF: G82940776<br />
	Correo electr&oacute;nico:&nbsp;<u><a href="mailto:fepsm@fepsm.org">fepsm@fepsm.org</a></u><br />
	Nombre de dominio:&nbsp;<u><a target="_blank" href="https://congresonacionaldepsiquiatria.es/">www.congresonacionaldepsiquiatria.es</a></u><br />
	Registro de Fundaciones del Ministerio de Educaci&oacute;n con el n&uacute;mero 566.</p>
<p>
	Privacidad y protecci&oacute;n de datos</p>
<p>
	Estas normas rigen para la web &quot;congresonacionaldepsiquiatria.es&quot; de titularidad de la Fundaci&oacute;n Espa&ntilde;ola de Psiquiatr&iacute;a y Salud Mental (en adelante FEPSM).</p>
<p>
	Para saber c&oacute;mo se procede con los datos personales, rogamos lea las siguientes normas que aconsejamos consultar con regularidad, dado que pueden ser actualizadas.</p>
<p>
	El usuario se hace responsable y garantiza que los datos personales que facilita a FEPSM son veraces y cuenta, cuando proceda, con la debida autorizaci&oacute;n para ello del titular de los mismos.</p>
<p>
	FEPSM tratar&aacute; los datos de car&aacute;cter personal vinculados a sus espacios webs respetando las exigencias de la legislaci&oacute;n vigente, resaltando:</p>
<p>
	Reglamento de Protecci&oacute;n de Datos</p>
<p>
	A partir del 25 de Mayo de 2018 es aplicable el nuevo Reglamento de Protecci&oacute;n de Datos. El Reglamento UE 2016/679 armoniza la legislaci&oacute;n en materia de Protecci&oacute;n de Datos en toda la Uni&oacute;n Europea, incrementado la protecci&oacute;n de las personas f&iacute;sicas titulares de datos personales y otorg&aacute;ndoles un mayor control sobre &eacute;stos.</p>
<p>
	En FEPSM siempre nos hemos preocupado por la protecci&oacute;n de los datos personales. Por ello, hemos actualizado nuestra Pol&iacute;tica de Privacidad a fin de ajustarla a la nueva regulaci&oacute;n e informarte sobre los cambios que afectan a tus derechos:</p>
<p>
	Los datos que FEPSM recaba por las diferentes v&iacute;as (p&aacute;gina web, correo electr&oacute;nico, formularios electr&oacute;nicos o en papel) dentro de su actividad de servicios, pasan a formar parte de un fichero de tratamiento del cual es responsable.</p>
<p>
	&nbsp;</p>
<p>
	Finalidades para las cuales se recaban datos:</p>
<p>
	La gesti&oacute;n de la relaci&oacute;n de los socios e inscritos.<br />
	La prestaci&oacute;n de servicios a los socios ya a personas o representantes de empresas relacionadas .<br />
	El mantenimiento de la relaci&oacute;n institucional entre la FEPSM y las instituciones p&uacute;blicas o privadas que representan.<br />
	El env&iacute;o de comunicaciones sobre nuestras actividades, cursos y servicios.</p>
<p>
	Duraci&oacute;n de la conservaci&oacute;n de los datos:</p>
<p>
	En el caso de los Proveedores, Clientes, Socios y Personal, conservamos sus datos mientras dure la relaci&oacute;n contractual con FEPSM.</p>
<p>
	Legitimaci&oacute;n de los tratamientos:</p>
<p>
	Los datos tratados por FEPSM son aquellos obtenidos con el consentimiento del interesado, as&iacute; como aquellos suministrados voluntariamente por los interesados por cualquier medio.</p>
<p>
	Los datos necesarios para el mantenimiento de una relaci&oacute;n contractual (contrato: prestaci&oacute;n de servicios; laboral; mercantil; entre otros).</p>
<p>
	Destinatarios de los datos.</p>
<p>
	Los datos cedidos a terceros son datos identificativos y de contacto (nombre, apellidos, email y direcci&oacute;n), con el fin de organizar congresos y convenciones sobre psiquiatr&iacute;a.</p>
<p>
	En caso de cesi&oacute;n, ser&aacute; previamente informado de la cesi&oacute;n, finalidad e identificaci&oacute;n del cesionario.</p>
<p>
	Derechos de los titulares de los datos:</p>
<p>
	Cualquier persona tiene derecho a conocer si FEPSM, realiza tratamientos de sus datos, o no. Y tiene derecho a:</p>
<p>
	Acceder a sus datos personales.<br />
	Solicitar la rectificaci&oacute;n de los datos inexactos.<br />
	Solicitar su supresi&oacute;n cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.<br />
	Oponerse al tratamiento de tus datos, por motivos relacionados con su situaci&oacute;n particular, solicitando que no sean tratados por FEPSM.<br />
	En determinadas circunstancias, se podr&aacute; solicitar la limitaci&oacute;n del tratamiento de tus datos, en cuyo caso &uacute;nicamente se conservaran para el ejercicio o la defensa de reclamaciones.<br />
	Retirar, el consentimiento prestado, sin que ello afecte a la licitud del tratamiento que se haya realizado con anterioridad a dicha retirada.<br />
	Cuando se ejerciten los derechos de supresi&oacute;n, oposici&oacute;n, limitaci&oacute;n o se retire el consentimiento, FEPSM dejar&aacute; de tratar los datos, salvo por motivos leg&iacute;timos imperiosos o el ejercicio o la defensa de posibles reclamaciones.</p>
<p>
	El titular de los datos podr&aacute; ejercitar los derechos contemplados en el Cap&iacute;tulo III del Reglamento General de Protecci&oacute;n de Datos, as&iacute; como de la LOPD, dirigi&eacute;ndose a Fundaci&oacute;n Espa&ntilde;ola de Psiquiatr&iacute;a y Salud Mental, en la calle Arturo Soria, 311, 1&ordm; B, 28033 Madrid (Espa&ntilde;a) o si lo prefiere por correo electr&oacute;nico por correo electr&oacute;nico a trav&eacute;s del siguiente: fepsm@fepsm.org</p>
<p>
	Siempre que se ejerciten los derechos expuestos, se deber&aacute;n de acompa&ntilde;ar a la solicitud una copia de su DNI o documento equivalente que permita comprobar la identidad.</p>
<p>
	Asimismo, si no est&aacute; conforme con c&oacute;mo se han atendido sus derechos, podr&aacute; presentar una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, a trav&eacute;s de la p&aacute;gina Web www.agpd.es.</p>
<p>
	Procedencia de los datos:</p>
<p>
	FEPSM obtiene los datos de car&aacute;cter personal directamente del interesado a trav&eacute;s de los siguientes canales:</p>
<p>
	Correo electr&oacute;nico<br />
	Formularios/ Cuestionarios (Web , electr&oacute;nicos o formato papel)<br />
	Intercambio de tarjetas de presentaci&oacute;n.<br />
	Las categor&iacute;as de datos:<br />
	Datos de identificaci&oacute;n<br />
	Direcciones electr&oacute;nicas<br />
	Informaci&oacute;n comercial<br />
	Datos econ&oacute;micos<br />
	No se tratan datos especialmente protegidos.<br />
	Env&iacute;o de Comunicaciones comerciales:</p>
<p>
	De conformidad con la LSSSI podr&aacute; darse de baja de cualquiera de los servicios de subscripci&oacute;n as&iacute; como manifestar su oposici&oacute;n a no recibir informaciones comerciales, enviando la petici&oacute;n a la direcci&oacute;n anteriormente indicada.</p>
<p>
	Medidas de seguridad:</p>
<p>
	FEPSM le informa de que aplica las medidas de seguridad necesarias para evitar el robo, alteraci&oacute;n o acceso no autorizado a los datos, teniendo en cuenta el estado de la t&eacute;cnica, los costes de aplicaci&oacute;n, y la naturaleza, el alcance, el contexto y los fines del tratamiento, as&iacute; como riesgos de probabilidad y gravedad variables para los derechos y libertades de las personas f&iacute;sicas, as&iacute; como en caso de subcontrataci&oacute;n de servicios exigir&aacute; y velar&aacute; por que el encargado del tratamiento aplique medidas t&eacute;cnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado a los riesgos existentes, conforme se recoge en el art. 32 del Reglamento General de Protecci&oacute;n de Datos.</p>
<p>
	Pol&iacute;tica de cookies y del fichero de actividad.</p>
<p>
	En cumplimento del art&iacute;culo 21 de la Ley 34/2002 de 11 de julio de servicios de sociedad de la informaci&oacute;n y comercio electr&oacute;nico (LSSI), le informamos que este sitio web puede utilizar Cookies (peque&ntilde;os archivos de informaci&oacute;n que el servidor env&iacute;a al ordenador de quien accede a la p&aacute;gina).</p>
<p>
	Las Cookies que utiliza este sitio web son Cookies t&eacute;cnicas:</p>
<p>
	Estas Cookies se utilizan para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualizaci&oacute;n del sitio web. Son de car&aacute;cter temporal, y tienen como &uacute;nica finalidad hacer m&aacute;s eficaz la transmisi&oacute;n por una red de comunicaciones electr&oacute;nicas o, en la medida que resultan estrictamente necesarias, para la prestaci&oacute;n de un servicio expresamente solicitado por el usuario, y desaparecen al terminar la sesi&oacute;n. En ning&uacute;n caso, este tipo de cookies se utilizan para recoger informaci&oacute;n de car&aacute;cter personal.</p>
<p>
	Si se desactivan estas cookies, no podr&aacute; recibir correctamente nuestros contenidos y servicios.</p>
<p>
	Direcciones IP: Los servidores del sitio web podr&aacute;n detectar de manera autom&aacute;tica la direcci&oacute;n IP y el nombre de dominio utilizados por el usuario. Una direcci&oacute;n IP es un n&uacute;mero asignado autom&aacute;ticamente a un ordenador cuando &eacute;ste se conecta a Internet. Toda esta informaci&oacute;n es registrada en un fichero de actividad del servidor que permite el posterior procesamiento de los datos con el fin de obtener mediciones &uacute;nicamente estad&iacute;sticas que permitan conocer el n&uacute;mero de impresiones de p&aacute;ginas, el n&uacute;mero de visitas realizadas a los servicios web, el orden de visitas, el punto de acceso, etc.</p>
<p>
	Se puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las opciones del navegador instalado en el mismo. Para ello, puede visitar la secci&oacute;n de Ayuda de su navegador donde encontrar&aacute; informaci&oacute;n sobre c&oacute;mo cambiar la configuraci&oacute;n del uso de cookies. La opci&oacute;n de bloqueo de Cookies en su navegador puede no permitirte el uso pleno de todas las funcionalidades del website.</p>
<p>
	Para cambiar la configuraci&oacute;n de las cookies, puede acceder a:</p>
<p>
	Safari: https://www.apple.com/legal/privacy/es/cookies/<br />
	Google Chrome: https://support.google.com/chrome/answer/95647?hl=es<br />
	Mozilla Firefox: https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-<br />
	Microsoft Internet Explorer: https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11.</p>
<p>
	Derechos de Propiedad Intelectual e Industrial.</p>
<p>
	Este Sitio Web se rige por las leyes espa&ntilde;olas y se encuentra protegido por la legislaci&oacute;n nacional e internacional sobre propiedad intelectual e industrial.</p>
<p>
	La web &ldquo;congresonacionaldepsiquiatria.es&rdquo; es titularidad de la Fundaci&oacute;n Espa&ntilde;ola de Psiquiatr&iacute;a y Salud Mental (FEPSM).</p>
<p>
	Todos los elementos (marcas, dise&ntilde;os, textos, enlaces, logos, im&aacute;genes, v&iacute;deos, elementos sonoros, software, bases de datos, c&oacute;digos...) contenidos en la p&aacute;gina de FEPSM congresonacionaldepsiquiatria.es est&aacute;n protegidos por el derecho nacional e internacional de propiedad intelectual. Estos elementos son propiedad exclusiva de FEPSM.</p>
<p>
	Cualquier reproducci&oacute;n, transmisi&oacute;n, adaptaci&oacute;n, traducci&oacute;n, modificaci&oacute;n, comunicaci&oacute;n al p&uacute;blico, o cualquier otra explotaci&oacute;n de todo o parte del contenido de este Sitio Web, efectuada de cualquier forma o por cualquier medio, electr&oacute;nico, mec&aacute;nico u otro, est&aacute;n estrictamente prohibidos salvo autorizaci&oacute;n previa por escrito de FEPSM. Cualquier infracci&oacute;n de estos derechos puede dar lugar a procedimientos extrajudiciales o judiciales civiles o penales que correspondan.</p>
<p>
	FEPSM no concede ninguna licencia o autorizaci&oacute;n de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el portal, los servicios o los contenidos del mismo.</p>
<p>
	La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los a los contenidos aportados por los Usuarios es de la exclusiva responsabilidad de los mismo.</p>
<p>
	Queda expresamente prohibida la copia o reproducci&oacute;n de la informaci&oacute;n publicada mediante cualquier soporte electr&oacute;nico (p&aacute;ginas Web, bases de datos o publicaciones electr&oacute;nicas) que permita la redifusi&oacute;n de cualquier informaci&oacute;n publicada en este sitio Web a m&uacute;ltiples usuarios sin el previo consentimiento por escrito de FEPSM.</p>
<p>
	Jurisdicci&oacute;n</p>
<p>
	FEPSM y el usuario, con renuncia expresa a cualquier otro fuero, se someten para cualquier controversia que pudiera derivarse del acceso o uso de la Web, al de los Juzgados y Tribunales de la ciudad de Madrid (Espa&ntilde;a).</p>
<p>
	Aceptaci&oacute;n y contacto legal</p>
<p>
	Al navegar por la p&aacute;gina web de FEPSM manifiesta que ha le&iacute;do, comprende, conoce y acepta, sin reserva, todas las condiciones legales de la misma. Para la resoluci&oacute;n de cualquier duda sobre el contenido o alcance de la presente pol&iacute;tica de privacidad p&oacute;ngase en contacto con nosotros en fepsm@fepsm.org</p>
<p>
	&nbsp;</p>



`