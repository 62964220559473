import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import SendMessage from '../../../components/SendMessage/SendMessage'
import AuthContext from "../../../context/auth-context";
import axios from 'axios';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import StandDiv from '../../../components/Stand/StandDiv';
import WithPermission from '../../../hoc/WithPermission';
import ParticipantsList from './ParticipantsList/ParticipantsList';
import MessagesList from './MessagesList/MessagesList';
import DelegatesList from './DelegastesList/DelegatesList';
import io from "socket.io-client";
import Text from '../../../containers/Assistant/AssistantText'
/* import Voice from '../../../containers/Assistant/AssistantVoice' */
import Video from '../../../containers/Assistant/AssistantVideo'
import ContactRequest from '../../../containers/Congress/IndustriaStand/ContactRequest'
import FileGallery from '../../../components/FileGallery/FileGallery';
import { withTranslation } from 'react-i18next';
import configData from '../../../config.json';
import StandDisclaimer from '../../../components/StandDisclaimer/StandDisclaimer'
import VotePopup from '../../../components/VotePopup/VotePopup';
import HospitalityList from './HospitalityList/HospitalityList';
import HospitalitySchedule from './HospitalitySchedule/HospitalitySchedule';

class IndustriaStand extends Component {

    contentBaseUrl = 'https://www.emma.events/static/virtualvenue/seacv/industria/stands/';

    static contextType = AuthContext;

    state = {
        id: "",
        layout: "",
        img: "",
        access: [],
        company_id: "",
        logo: "",
        email: "",
        type: "industry",
        width: 1420,
        height: 769,
        topPos: 0,
        leftPos: 0,
        currStandWidth: 0,
        currStandHeight: 0,
        mapsDiv1: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv2: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv3: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv4: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv5: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv6: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv7: { width: 0, height: 0, x: 0, y: 0 },
        mapsDiv8: { width: 0, height: 0, x: 0, y: 0 },
        isLoaded: false,
        message: false,
        videoplay: false,
        videoPlaying: null,
        level: 'standard',
        delegates: [],
        delegatesShow: false,
        participants: [],
        openParticipantsList: true,
        delegatesInRoom: [],
        chatType: "txt",
        chatActive: false,
        chatRoom: "",
        chatMessages: [],
        chatRecipientId: "",
        contactRequestActive: false,
        contactRequest: {},
        fileGalleryShow: false,
        fileGalleryContent: [],
        warningOn: false,
        warningText: "",
        chatOn: false,
        messageOn: false,
        warningHealthProfessional: false,
        warningHealthProfessionalText: "",
        votePopup: false,
        voteTitle: ""
    }

    stand = {}

    standImageLoadedHandler = () => {
        this.setState({ isLoaded: true });
        this.updateDimensions();
    }

    messageBoxHandler = () => {
        this.setState({
            message: true,
            delegatesShow: false
        });
    }

    mapDiv = (mapDivID, mapWidth, mapHeight, mapX, mapY, currImgWidth, currImgHeight, imgWidth, imgHeight, objLeftPos, objTopPos) => {
        var CurrMapWidth = parseInt(mapWidth * currImgWidth / imgWidth);
        var CurrMapHeight = parseInt(mapHeight * currImgHeight / imgHeight);
        var CurrMapX = parseInt(mapX * currImgWidth / imgWidth) + objLeftPos;
        var CurrMapY = parseInt(mapY * currImgWidth / imgWidth) + objTopPos;
        var d = document.getElementById(mapDivID);
        d.style.left = CurrMapX + 'px';
        d.style.top = CurrMapY + 'px';
        d.style.width = CurrMapWidth + 'px';
        d.style.height = CurrMapHeight + 'px';
    }

    updateDimensions = () => {
        const obj = document.getElementById(this.state.layout);

        if (obj !== undefined && obj !== null) {
            const leftPos = obj.offsetLeft;
            const topPos = obj.offsetTop;
            const currImgWidth = obj.clientWidth;
            const currImgHeight = obj.clientHeight;

            this.mapDiv('StandType2-map1', this.state.mapsDiv1.width, this.state.mapsDiv1.height, this.state.mapsDiv1.x, this.state.mapsDiv1.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map2', this.state.mapsDiv2.width, this.state.mapsDiv2.height, this.state.mapsDiv2.x, this.state.mapsDiv2.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map3', this.state.mapsDiv3.width, this.state.mapsDiv3.height, this.state.mapsDiv3.x, this.state.mapsDiv3.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map4', this.state.mapsDiv4.width, this.state.mapsDiv4.height, this.state.mapsDiv4.x, this.state.mapsDiv4.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map5', this.state.mapsDiv5.width, this.state.mapsDiv5.height, this.state.mapsDiv5.x, this.state.mapsDiv5.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map6', this.state.mapsDiv6.width, this.state.mapsDiv6.height, this.state.mapsDiv6.x, this.state.mapsDiv6.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map7', this.state.mapsDiv7.width, this.state.mapsDiv7.height, this.state.mapsDiv7.x, this.state.mapsDiv7.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);
            this.mapDiv('StandType2-map8', this.state.mapsDiv8.width, this.state.mapsDiv8.height, this.state.mapsDiv8.x, this.state.mapsDiv8.y,
                currImgWidth, currImgHeight, this.state.width, this.state.height, leftPos, topPos);

            this.setState({
                topPos: topPos,
                leftPos: leftPos,
                currStandWidth: currImgWidth,
                currStandHeight: currImgHeight
            })
        }

    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions);

        this.initSocketConnection();

        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/stands/" + this.props.match.params.name, data)
            .then(response => {

                const standIn = response.data;

                let width, height;
                let mapsDiv1, mapsDiv2, mapsDiv3, mapsDiv4, mapsDiv5, mapsDiv6, mapsDiv7, mapsDiv8;

                if (standIn != undefined && standIn.length > 0) {

                    this.stand = standIn[0];

                    switch (standIn[0].layout) {
                        case "StandType1OneSide":
                            width = 1635.05;
                            height = 769;
                            mapsDiv1 = { width: 324, height: 191, x: 655, y: 19 }
                            mapsDiv2 = { width: 456, height: 251, x: 582, y: 435 }
                            mapsDiv3 = { width: 260, height: 409, x: 279, y: 56 }
                            mapsDiv4 = { width: 260, height: 409, x: 1095, y: 56 }
                            mapsDiv5 = { width: 0, height: 0, x: 0, y: 0 }
                            mapsDiv6 = { width: 0, height: 0, x: 0, y: 0 }
                            mapsDiv7 = { width: 0, height: 0, x: 0, y: 0 }
                            mapsDiv8 = { width: 0, height: 0, x: 0, y: 0 }
                            break;
                        case "StandType2OneSide":
                            width = 1458;
                            height = 769;
                            mapsDiv1 = { width: 147, height: 80, x: 296, y: 515 }
                            mapsDiv2 = { width: 218, height: 128, x: 454, y: 308 }
                            mapsDiv3 = { width: 131, height: 158, x: 722, y: 542 }
                            mapsDiv4 = { width: 333, height: 132, x: 732, y: 293 }
                            mapsDiv5 = { width: 65, height: 79, x: 1191, y: 476 }
                            mapsDiv6 = { width: 37, height: 47, x: 1208, y: 566 }
                            mapsDiv7 = { width: 0, height: 0, x: 0, y: 0 }
                            mapsDiv8 = { width: 0, height: 0, x: 0, y: 0 }
                            break;
                        case "StandType3OneSide":
                            width = 1420;
                            height = 769;
                            mapsDiv1 = { width: 104, height: 192, x: 179, y: 414 }
                            mapsDiv2 = { width: 104, height: 192, x: 1151, y: 414 }
                            mapsDiv3 = { width: 203, height: 113, x: 614, y: 583 }
                            mapsDiv4 = { width: 180, height: 107, x: 621, y: 307 }
                            mapsDiv5 = { width: 195, height: 316, x: 387, y: 272 }
                            mapsDiv6 = { width: 195, height: 316, x: 839, y: 272 }
                            mapsDiv7 = { width: 0, height: 0, x: 0, y: 0 }
                            mapsDiv8 = { width: 0, height: 0, x: 0, y: 0 }
                            break;
                        case "StandType1TwoSides":
                            width = 1601;
                            height = 769;
                            mapsDiv1 = { width: 133, height: 246, x: 164, y: 343 }
                            mapsDiv2 = { width: 172, height: 104, x: 547, y: 115 }
                            mapsDiv3 = { width: 314, height: 186, x: 530, y: 249 }
                            mapsDiv4 = { width: 251, height: 148, x: 1304, y: 276 }
                            mapsDiv5 = { width: 56, height: 66, x: 1338, y: 545 }
                            mapsDiv6 = { width: 56, height: 66, x: 1436, y: 545 }
                            mapsDiv7 = { width: 30, height: 38, x: 1351, y: 618 }
                            mapsDiv8 = { width: 30, height: 38, x: 1452, y: 618 }
                            break;
                        case "StandType2TwoSides":
                            width = 1668;
                            height = 769;
                            mapsDiv1 = { width: 216, height: 364, x: 217, y: 171 }
                            mapsDiv2 = { width: 508, height: 91, x: 617, y: 45 }
                            mapsDiv3 = { width: 310, height: 183, x: 707, y: 183 }
                            mapsDiv4 = { width: 313, height: 91, x: 712, y: 600 }
                            mapsDiv5 = { width: 52, height: 62, x: 82, y: 529 }
                            mapsDiv6 = { width: 52, height: 62, x: 1507, y: 529 }
                            mapsDiv7 = { width: 33, height: 41, x: 90, y: 598 }
                            mapsDiv8 = { width: 33, height: 41, x: 1521, y: 598 }
                            break;
                        case "StandType3TwoSides":
                            width = 1621;
                            height = 769;
                            mapsDiv1 = { width: 215, height: 373, x: 86, y: 196 }
                            mapsDiv2 = { width: 215, height: 373, x: 349, y: 196 }
                            mapsDiv3 = { width: 484, height: 88, x: 871, y: 82 }
                            mapsDiv4 = { width: 362, height: 214, x: 867, y: 259 }
                            mapsDiv5 = { width: 246, height: 66, x: 923, y: 483 }
                            mapsDiv6 = { width: 120, height: 65, x: 797, y: 601 }
                            mapsDiv7 = { width: 120, height: 65, x: 1421, y: 601 }
                            mapsDiv8 = { width: 182, height: 89, x: 1117, y: 640 }
                            break;

                    }

                    this.setState({
                        id: standIn[0].id,
                        access: standIn[0].access,
                        layout: standIn[0].layout,
                        img: standIn[0].img,
                        company_id: standIn[0].company_id,
                        level: standIn[0].level !== undefined && standIn[0].level !== "" ? standIn[0].level : 'standard',
                        delegates: standIn[0].delegates !== undefined ? standIn[0].delegates : [],
                        email: standIn[0].email,
                        logo: standIn[0].logo,
                        width: width,
                        height: height,
                        mapsDiv1: mapsDiv1 === undefined ? this.state.mapsDiv1 : mapsDiv1,
                        mapsDiv2: mapsDiv2 === undefined ? this.state.mapsDiv2 : mapsDiv2,
                        mapsDiv3: mapsDiv3 === undefined ? this.state.mapsDiv3 : mapsDiv3,
                        mapsDiv4: mapsDiv4 === undefined ? this.state.mapsDiv4 : mapsDiv4,
                        mapsDiv5: mapsDiv5 === undefined ? this.state.mapsDiv5 : mapsDiv5,
                        mapsDiv6: mapsDiv6 === undefined ? this.state.mapsDiv6 : mapsDiv6,
                        mapsDiv7: mapsDiv7 === undefined ? this.state.mapsDiv7 : mapsDiv7,
                        mapsDiv8: mapsDiv8 === undefined ? this.state.mapsDiv8 : mapsDiv8,
                        warningOn: standIn[0].warningOn !== undefined ? standIn[0].warningOn : false,
                        warningText: standIn[0].warningText !== undefined ? standIn[0].warningText : true,
                        chatOn: standIn[0].chatOn !== undefined ? standIn[0].chatOn : true,
                        messageOn: standIn[0].messageOn !== undefined ? standIn[0].messageOn : true,
                        warningHealthProfessional: standIn[0].warningHealthProfessional !== undefined ? standIn[0].warningHealthProfessional : true,
                        warningHealthProfessionalText: standIn[0].warningHealthProfessionalText !== undefined ? standIn[0].warningHealthProfessionalText : true

                    })

                    this.updateDimensions();

                    let config = {
                        headers: {
                            "x-auth-token": this.context.token,
                            "content-type": "application/json",
                        }
                    };
                    let params = {
                        "type": "stand-entrance",
                        "details": "",
                        "user_id": this.context.id,
                        "event_id": this.context.event_id,
                        "stand_id": standIn[0].company_id,
                        "stand": standIn[0].stand
                    };

                    axios.post(configData.SERVER_API + "/logs/", params, config)
                        .then(response => { })
                        .catch(error => { })
                }

            })
            .catch(error => {
                console.log(error.message);
            })


    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        this.socket.disconnect();
    }

    onSendHandler = () => {
        this.setState({ message: false });
    }

    onCloseHandler = () => {
        this.setState({ message: false });
    }

    playVideo = (videoToPlay) => {
        this.setState({ videoplay: true, videoPlaying: videoToPlay });
    }

    loadFileGallery = (content) => {
        this.setState({ fileGalleryShow: true, fileGalleryContent: content });
    }

    votePopup = (VoteTitle) => {

        this.setState({ votePopup: true, voteTitle: VoteTitle });
    }

    onCloseVoteHandler = () => {
        this.setState({ votePopup: false, voteTitle: "" });
    }

    onCloseVideoHandler = () => {
        this.setState({ videoplay: false });
    }

    onCloseFileGallery = () => {
        this.setState({ fileGalleryShow: false });
    }

    isStandDelegate(userid, delegates) {
        let res = false;
        if (delegates !== undefined && delegates.length > 0) {
            res = delegates.includes(userid)
        }
        return res
    }

    onDelegatesShow = () => {
        this.setState({ delegatesShow: true })
    }
    onDelegatesHide = () => {
        this.setState({ delegatesShow: false })
    }

    initSocketConnection() {

        const server = configData.SOCKET_API_INDUSTRY + "/industria";
        this.socket = io.connect(server, { transports: ['websocket'], query: { token: this.context.token } });
        this.socket.on('connect', () => {

            const data = {
                room: this.props.match.params.name,
                id: this.context.id
            }

            this.socket.emit('join-room', data); // join the room 

            if (this.context.profile === "delegate-stand" || this.context.profile === "delegate-assistant") {
                this.socket.emit('users-in-room', data); // get the current list of user in the room
            }

            this.socket.emit('delegates-in-room', data); // ge the current list of delegates in the room 

            if (this.context.profile === "delegate-stand" || this.context.profile === "delegate-assistant") {

                // #### USERS IN THE ROOM -------------------------------------------
                this.socket.on("users-in-room", data => {   // recives the list of user in the room 

                    let output = [];
                    let newUser;

                    /* console.log(data); */

                    data.forEach(element => {
                        if (element.profile !== "delegate-stand" && element.profile !== "delegate-assistant") {
                            newUser = {
                                id: element.user.id,
                                email: element.user.email,
                                name: element.user.name,
                                photo: element.user.photo,
                                institution: element.user.intitution,
                                link: true,
                                new: true,
                                social: 'standard',
                                txt: true,
                                voice: true,
                                video: true,
                                time_in: element.user.date,
                                sendMessage: true,
                                profile: element.user.profile
                            }
                            output.push(newUser)
                        }
                    });

                    const show = output.length > 0 ? true : false;
                    this.setState({
                        participants: output,
                        openParticipantsList: show
                    })

                });


                // #### USER ENTERED ROOM -------------------------------------------
                this.socket.on("user-entered-room", data => {   // new user added to the room
                    if (data.user.profile !== "delegate-stand" && data.user.profile !== "delegate-assistant" && this.isStandDelegate(this.context.id, this.state.delegates)) {
                        const newUser = {
                            id: data.user.id,
                            email: data.user.email,
                            name: data.user.name,
                            photo: data.user.photo,
                            institution: data.user.intitution,
                            link: true,
                            new: true,
                            social: 'standard',
                            txt: true,
                            voice: true,
                            video: true,
                            time_in: data.user.date,
                            sendMessage: true,
                            profile: data.user.profile
                        }
                        let output = this.state.participants;
                        output.unshift(newUser);

                        this.setState({
                            participants: output,
                            openParticipantsList: true
                        })



                    }
                });


                // #### USER LEFT ROOM ---------------------------------------------
                this.socket.on("user-left-room", data => {
                    const allExceptThis = this.state.participants.filter((per) => {
                        return per.id !== data.user.id
                    })
                    this.setState({
                        participants: allExceptThis
                    })
                });
            }

            // #### DELEGATES STATE  ---------------------------------------------
            this.socket.on("delegates-in-room", data => {
                this.setState({
                    delegatesInRoom: data
                })
            });



            // #### CONTACT REQUEST  ---------------------------------------------
            this.socket.on("contact-request", data => {
                this.setState({
                    contactRequest: data,
                    contactRequestActive: true
                })
            });


            // #### CHAT MESSAGE  ---------------------------------------------
            this.socket.on("chat-message", data => {
                if (this.state.chatRoom === data.chatId) {
                    if (data.message !== "") {
                        let messages = this.state.chatMessages;
                        messages.push(data);
                        this.setState({
                            chatMessages: messages
                        })
                    }
                }
            });


            this.socket.on("disconnect", data => {
                //console.log('force disconnect')
            });
        });

    }

    // CHAT START UP EVENTS -------------------
    onMainTxtHandler = (id) => {
        this.setState({
            chatActive: true,
            chatType: "txt",
            chatRoom: this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId: id
        })
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId: id,
            type: "txt",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room     
    }
    onMainVoiceHandler = (id) => {
        this.setState({
            chatActive: true,
            chatType: "voice",
            chatRoom: this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId: id
        })
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId: id,
            type: "voice",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room     
    }
    onMainVideoHandler = (id) => {
        this.setState({
            chatActive: true,
            chatType: "video",
            chatRoom: this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId: id
        })

        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId: id,
            type: "video",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room     
    }

    // CHAT CLOSE EVENTS ----------------------
    onCloseChatHandler = () => {
        this.setState({
            chatRoom: null,
            chatActive: false
        })
    }

    onVoiceSetHandler = () => {
        this.setState({
            chatActive: true,
            chatType: "voice"
        })
    }

    onVideoSetHandler = () => {
        this.setState({
            chatActive: true,
            chatType: "video"
        })
    }

    closeContactRequest = () => {
        this.setState({
            contactRequestActive: false
        })
    }

    acceptContactHandler = () => {
        this.setState({
            contactRequestActive: false,
            contactRequest: null,
            chatType: this.state.contactRequest.type,
            chatRoom: this.state.id + '.' + this.state.contactRequest.id + '.' + this.context.id,
            chatActive: true,
            chatMessages: []
        })
    }

    declineContactHandler = () => {
        this.setState({
            contactRequestActive: false,
            contactRequest: null
        })
    }

    sendChatMessage = (message) => {
        const data = {
            chatId: this.state.chatRoom,
            sender_id: this.context.id,
            message: message
        }
        this.socket.emit('chat-message', data);
    }


    onWarningCloseHandler = () => {
        this.setState({ warningOn: false });
    }

    onwarningHealthProfessionalNegativeHandler = () => {
        this.props.history.push('/congress/industria')
    }


    render() {

        document.getElementById('root').style.backgroundImage = 'url("/img/industria-background.jpg")';

        let messageBox;
        if (this.state.message) {
            messageBox = <SendMessage
                event_id={this.context.event_id}
                senderId={this.context.id}
                senderType="user"
                receiverId={this.state.company_id}
                receiverEmail={this.state.email}
                receiverType={this.state.type}
                sent={this.onSendHandler}
                close={this.onCloseHandler} >
            </SendMessage>;
        } else {
            messageBox = null;
        }

        let videoPlayer;
        if (this.state.videoplay) {
            videoPlayer = <VideoPlayer
                close={this.onCloseVideoHandler}
                video={this.state.videoPlaying}
                logo={this.state.logo}
            />;
        }

        let votingPopup;
        if (this.state.votePopup) {
            votingPopup = <VotePopup
                close={this.onCloseVoteHandler}
                eventid={this.context.event_id}
                standid={this.state.id}
                votetitle={this.state.voteTitle}
            />;
        }

        let fileGallery;
        if (this.state.fileGalleryShow) {
            fileGallery = <FileGallery
                close={this.onCloseFileGallery}
                content={this.state.fileGalleryContent}
                logo={this.state.logo}
            />;
        }


        let myParticipantList;
        let myMessageList;
        let myDelegatesList;
        let myHospitalityList;
        let myHospitalitySchedule;


        /* if (this.context.profile === "participant") {} */

        if ((this.context.profile === "delegate-stand" || this.context.profile === "delegate-assistant") && this.isStandDelegate(this.context.id, this.state.delegates)) {

            myParticipantList = <ParticipantsList
                standLevel={this.state.level}
                standId={this.state.id}
                participants={this.state.participants}
                open={this.state.openParticipantsList}
                onTxt={this.onMainTxtHandler}
                onVoice={this.onMainVoiceHandler}
                onVideo={this.onMainVideoHandler}
            />

            myMessageList = <MessagesList standId={this.state.company_id}></MessagesList>
            
            /* myHospitalitySchedule = <HospitalitySchedule></HospitalitySchedule> */
            
        }
        
        myHospitalityList = null;

        if (this.state.id === "medela") {
            myHospitalityList = <HospitalityList company="medela"></HospitalityList>
        } else if (this.state.id === "intuitive") {
            myHospitalityList = <HospitalityList company="intuitive"></HospitalityList>
        } else if (this.state.id === "mectron") {
            myHospitalityList = <HospitalityList company="mectron"></HospitalityList>
        } else if (this.state.id === "brainlab") {
            myHospitalityList = <HospitalityList company="brainlab"></HospitalityList>
        }                
        

        if (this.state.delegatesShow) {
            //if (this.context.profile === "participant" || this.context.profile === "participant-pay-per-view" || this.context.profile === "secretariat"){
            myDelegatesList = <DelegatesList
                standId={this.state.id}
                delegatesInRoom={this.state.delegatesInRoom}
                closeDelegatesList={this.onDelegatesHide}
                sendMessage={this.messageBoxHandler}
                onTxt={this.onMainTxtHandler}
                onVoice={this.onMainVoiceHandler}
                onVideo={this.onMainVideoHandler}
            ></DelegatesList>
            /* } else {
                myDelegatesList = null;
            } */
        } else {
            myDelegatesList = null;
        }

        let myChat
        if (this.state.chatActive) {

            if (this.state.chatType === "txt") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Text messages={this.state.chatMessages} userid={this.context.id} sendMessage={this.sendChatMessage} voice={this.onVoiceSetHandler} video={this.onVideoSetHandler} close={this.onCloseChatHandler} />
                    </div>
                </div>

            if (this.state.chatType === "voice") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={false} close={this.onCloseChatHandler} room={this.state.chatRoom} />
                    </div>
                </div>

            if (this.state.chatType === "video") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={true} close={this.onCloseChatHandler} room={this.state.chatRoom} />
                    </div>
                </div>


        } else {
            myChat = null;
        }

        const myContactRequest = this.state.contactRequestActive ?
            <ContactRequest
                request={this.state.contactRequest}
                closeContactRequest={this.closeContactRequest}
                accept={this.acceptContactHandler}
                decline={this.declineContactHandler} />
            : null



        const warningPopUp = this.state.warningOn ? <StandDisclaimer warningMedical={this.state.warningHealthProfessional} warningMedicalText={this.state.warningHealthProfessionalText} onwarningMedicalNegativeHandler={this.onwarningHealthProfessionalNegativeHandler} text={this.state.warningText} onWarningClose={this.onWarningCloseHandler}></StandDisclaimer> : null;

        return (
            <React.Fragment>
                <WithPermission userId={this.context.id} userEmail={this.context.email} role={this.context.profile} objectType={"stand"} objectPermission={this.state.access} eventId={this.context.event_id}>
                    {/* 
                <div className="stand-detail-colors-container" style={{display:`none`}}>
                    <div className="area-color-stand-detail yellow" style={{width:`calc(28% - 1vh)`, height:`100%`, left:`0`, top:`0`}}>
                        NUEVO PRODUCTO
                    </div>
                    <div className="area-color-stand-detail orange" style={{width:`calc(25% - 1vh)`, height:`100%`, backgroundColor:`rgba(255, 150, 0, 0.8)`, left:`28%`, top:`0`}}></div>
                    <div className="area-color-stand-detail green" style={{width:`calc(25% - 1vh)`, height:`100%`, backgroundColor:`rgba(63, 135, 56, 0.8)`, left:`53%`, top:`0`, display:`none`}}></div>
                    <div className="area-color-stand-detail blue" style={{width:`22%`, height:`calc(70% - 1vh)`, backgroundColor:`rgba(6, 135, 209, 0.8)`, left:`78%`, top:`0`}}>
                        Ver una sesión patrocinada
                    </div>
                    <div className="area-color-stand-detail purple" style={{width:`22%`, height:`30%`, backgroundColor:`rgba(213, 0, 190, 0.8)`, left:`78%`, top:`70%`}}>
                        NUEVO PRODUCTO
                    </div>
                </div> 
                */}

                    <div className="stand-detail-container">


                        <div id="StandType2-map1" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div1} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        <div id="StandType2-map2" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div2} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        <div id="StandType2-map3" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div3} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        <div id="StandType2-map4" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div4} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        <div id="StandType2-map5" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div5} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        <div id="StandType2-map6" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div6} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /> </div>

                        {this.stand.div7
                            ? <div id="StandType2-map7" className="mapcontainer"><StandDiv votePopup={this.votePopup} data={this.stand.div7} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /></div>
                            : <div id="StandType2-map7" className="mapcontainer nocontent"></div>
                        }

                        {this.stand.div8
                            ? <div id="StandType2-map8" className="mapcontainer"> <StandDiv votePopup={this.votePopup} data={this.stand.div8} playVideo={this.playVideo} loadFileGallery={this.loadFileGallery} /></div>
                            : <div id="StandType2-map8" className="mapcontainer nocontent"></div>
                        }

                        <img alt="" id={this.state.layout} onLoad={this.standImageLoadedHandler} className="stand-detail-img" src={this.state.img} />
                    </div>

                    {this.state.chatOn || this.state.messageOn ?
                        <div className="assistant-stand-detail-container">

                            {this.state.chatOn
                                ? <div className="assistant-stand-detail-image-container" onClick={this.onDelegatesShow}>
                                    <div className="assistant-stand-audio-btn-container">
                                        <img alt="" src="/img/audio-btn-assistant-stand.png" />
                                    </div>
                                    {/* <img alt="" src="/img/assistant-stand-detail-image.png" /> */}
                                </div>
                                : null}

                            {this.state.messageOn
                                ? <div className="assistant-stand-detail-option-container" onClick={this.messageBoxHandler}>
                                    <img alt="" src="/img/assistant-stand-detail-option-container-icon-send-msg.png" />
                                    {this.props.t('send_message')}
                                </div>
                                : null}

                            {this.state.chatOn
                                ? <div className="assistant-stand-detail-status-container cursor-only" onClick={this.onDelegatesShow}>
                                    <div className="assistant-stand-detail-status-title">
                                        {this.props.t('let_us_talk')}
                                        <div className="switch-assistant-stand-detail-status">
                                            {this.props.t('available')}  &nbsp;
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round">status</span>
                                            </label>
                                            &nbsp; {this.props.t('busy')}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {this.state.messageOn
                                ? <div className="assistant-stand-detail-option-container" onClick={this.messageBoxHandler}>
                                    <img alt="" src="/img/assistant-stand-detail-option-container-icon-meeting.png" />
                                    {this.props.t('request_meeting')}
                                </div>
                                : null}

                        </div>
                        : null}

                    {messageBox}
                    {videoPlayer}
                    {fileGallery}
                    {votingPopup}

                </WithPermission>

                <Link to="/congress/industria">
                    <div className="stand-detail-btns-view-container">
                        <div className="stand-detail-btn-view-wrapper">
                            <img alt="" src="/img/bottom-btn-icon-vestibulo-comercial.png" />
                            {this.props.t('menu_exhibition1') + ' ' + this.props.t('menu_exhibition2')}
                        </div>
                        {/* <div className="stand-detail-btn-view-wrapper">
                            <img alt="" src="/img/bottom-btn-icon-areas-estandes.png" />
                            AREAS DE ESTANDES
                        </div> */}
                    </div>
                </Link>

                {myParticipantList}
                {myMessageList}
                {myHospitalityList}
                {myHospitalitySchedule}
                {myDelegatesList}
                {myChat}
                {myContactRequest}

                {warningPopUp}

            </React.Fragment>
        );
    }
}

export default withTranslation()(IndustriaStand);