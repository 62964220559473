import React from 'react';
import { useTranslation } from 'react-i18next';

function Login(props) {

    const { t, i18n } = useTranslation();
    //const error = props.loginError ? <div className="form-error-msg">{t('login_error' + props.loginErrorCode)}</div> : null;

    let error;

    //console.log(props.loginEventId);

    if (props.loginEventId === "387") {
        error = props.loginError ? <div className="form-error-msg">Usuario incorrecto.</div> : null;
    } else if (props.loginEventId === "3" || props.loginEventId === "4" || props.loginEventId === "5") {
        error = props.loginError ? <div className="form-error-msg">Email incorrecto.</div> : null;
    } else {
        if (props.loginEventActive === true) {
            error = props.loginError ? <div className="form-error-msg">{t('login_error' + props.loginErrorCode)}</div> : null;
        }
        else {
            error = props.loginError ? <div className="form-error-msg">{t('login_error430')}</div> : null;
        }

    }

    return (
        <div className={'login-area-container ' + props.loginEventCode.toLowerCase()}>
            <span className="login-title">{t('start_session')} {props.loginEventActive}</span>
            <div className="avatar-login-container">
                <img src="/img/default-login-pic.png" alt="" />
            </div>
            <form className="form-login">
                <div className="form-field-wrapper">
                    <div className="form-field-icon-container">
                        <img src="/img/login-form-field-user-icon.png" alt="" />
                    </div>
                    <div className="form-field-input-container">

                        {props.loginEventId === "3" || props.loginEventId === "4" || props.loginEventId === "5" ?
                            <input type="text" onChange={props.emailChange} id="username" name="username" placeholder="Email" />
                            :
                            <input type="text" onChange={props.emailChange} id="username" name="username" placeholder={t('username')} />
                        }
                    </div>
                </div>

                {!props.passwordFree ?
                    <React.Fragment>
                        <div className="form-fields-login-separator"></div>
                        <div className="form-field-wrapper">
                            <div className="form-field-icon-container">
                                <img src="/img/login-form-field-passwordr-icon.png" alt="" />
                            </div>
                            <div className="form-field-input-container">
                                <input type="password" onChange={props.passwordChange} id="password" name="password" placeholder={t('password')} />
                            </div>
                        </div>
                    </React.Fragment>
                    : null}

                {error}
                <div onClick={props.loginSubmit} className="login-form-button">{t('start_session')}</div>

                {props.loginEventId === "3" || props.loginEventId === "4" ?
                    <p className="p-login-support-contacts">El titular de esta página Web es la sociedad Laboratorios Farmacéuticos Rovi, S.A. Si tiene problemas para acceder contáctenos a:<br /><br />Email: <a href="mailto:secretaria@viajeseci.es">secretaria@viajeseci.es</a><br />Teléfono: 628 14 30 81</p>
                    : null
                }

                {props.loginEventId === "5" ?
                    <p className="p-login-support-contacts">La información contenida en este sitio web está dirigida exclusivamente a profesionales sanitarios, ya que requiere una formación especializada para su correcta interpretación. No compartir los contenidos en redes sociales ni por cualquier otro médio.<br /><br />Email: <a href="mailto:secretaria@viajeseci.es">secretaria@viajeseci.es</a><br />Teléfono: 628 14 30 81</p>
                    : null
                }



                {props.loginEventId === "378" ?
                    <div className="login-form-registration-container"><a onClick={props.onRegistrationFormOpen} href="#" rel="noopener">Inscríbete {t('recover_access2')} </a><br /><br /></div>
                    : null
                }

                {props.passwordRecover !== undefined && props.passwordRecover !== "" && props.passwordRecover !== null
                    && !props.passwordFree
                    ?


                    props.loginEventActive === true ?

                        props.loginEventId === "378" ?
                            <div className="login-form-recover-password-container">{t('recover_access')} <a onClick={props.onRecoverPasswordOpen} href="#" rel="noopener">{t('recover_access2')}</a></div>
                            : <div className="login-form-recover-password-container">{t('recover_access')} <a href={props.passwordRecover} rel="noopener">{t('recover_access2')}</a></div>

                        : null

                    : null}

                {props.loginEventId === "376" ?
                    <p className="p-login-support-contacts semnim2021">Si tiene problemas para acceder, por favor contacte con la Secretaría Técnica en <a href="mailto:medicinanuclear@viajeseci.es">medicinanuclear@viajeseci.es</a></p>
                    : null
                }

                {props.loginEventId === "391" ?
                    <p className="p-login-support-contacts semnim2021">Si tiene problemas para acceder contáctenos a:<br /><br />Email: <a href="mailto:sea@viajeseci.es">sea@viajeseci.es</a><br />Tlf. (+34) 91 103 52 06 / 639 764 062</p>
                    : null
                }

                {props.loginEventId === "373" ?
                    <p className="p-login-support-contacts semnim2021">Atención Secretaría: Tlf. (+34) 629-068-075</p>
                    : null
                }

                {props.loginEventId === "320" ?
                    <p className="p-login-support-contacts semnim2021">If you are unable to access contact <a href="mailto:cuenca2020@viajeseci.es">cuenca2020@viajeseci.es</a></p>
                    : null
                }


                {props.loginEventId === "405" ?
                    <p className="p-login-support-contacts semnim2021"><br />Technical Secretariat<br /><a href="mailto:lahrscongress@viajeseci.es">lahrscongress@viajeseci.es</a></p>
                    : null
                }


                {props.loginEventId === "424" && props.loginEventActive === false ?
                    <p className="p-login-support-contacts">La plataforma estará disponible a partir del 10 Febrero a las 14:00 hrs.</p>
                    : null
                }


                {props.loginEventId === "425" && props.loginEventActive === false ?
                    <p className="p-login-support-contacts">Le informamos que  la plataforma estará abierta el <strong>viernes 11 Marzo a partir de las 13:00 hrs.</strong></p>
                    : null
                }


                {props.loginEventId === "575" ?
                    <div style={{textAlign: "center", marginTop: "40px"}}>
                        <p className="p-login-support-contacts">Track Virtual patrocinado por:</p>
                        <img src="https://www.emma.events/static/virtualvenue/cnp2023/entrance/rovi-logo.jpg" style={{padding:"5px", backgroundColor:"#ffffff", borderRadius:"5px"}} />
                    </div>
                    
                    : null
                }

               


                {/*  {props.loginEventId === "410" && props.loginEventActive === false ?
                    <p className="p-login-support-contacts">The access to this platform will only be possible from <strong>27 September 2022.</strong></p>
                    : null
                }*/}


                {/* {props.loginEventId === "341" ?
                    <p className="p-login-support-contacts semnim2021">THE PLATFORM WILL BE OPEN ON SUNDAY JUNE 20th AT 8am CET</p>
                    : null
                } */}




            </form>
        </div>
    );
}

export default Login;