
import React, { Component } from 'react';
import ChatBar from '../../../components/Chat/ChatBar';
import ChatSideBar from '../Chat/ChatSideBar';
import Chats from '../Chat/Chats'

class ChatContainer extends Component {
    
    state = {show:false}
    
    onShowHideChatSideBar = () => {
        this.setState((prevState, props) => {
            return {
                show: !prevState.show
            };
        })
    }

    render() {

        const chatSideBar = this.state.show ? <ChatSideBar></ChatSideBar> : null;

        return (
            <React.Fragment>
                {chatSideBar}
                <Chats></Chats>
                <ChatBar showHide={this.onShowHideChatSideBar} ></ChatBar> 
            </React.Fragment>
        );
    }
}

export default ChatContainer;