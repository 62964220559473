
import React, { Component } from 'react';
import ProfileMenu from './ProfileMenu';
import axios from 'axios';
import AuthContext from '../../../../context/auth-context';
import configData from '../../../../config.json';

class Profile extends Component {
    
    state = {
        name: "",
        photo: "/img/default-login-pic.png"
    }
    
    static contextType = AuthContext;

    componentDidMount(){

        let data = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            },
            params: {_id: this.context._id}
          };

        axios.get(configData.SERVER_API + "/users/me" ,data)
        .then(response => {
            const name =  response.data.name !== undefined && response.data.name !== "" ? response.data.name : ""
            const surname =  response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : ""
            const photo =  response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png"

            this.setState({name: name + '' + surname, photo:photo})
            })
        .catch(error => {
            console.log(error.message);
            })
    }

    render() {
        
        return (
            <React.Fragment>
            <div className="profile-container cursor-only">
                <div className="avatar-profile" onClick={this.props.showHide}>
                    <div className="div-img-avatar-wrapper">
                        <img src={this.state.photo} className="img-avatar" alt="" />
                    </div>
                    {/* <a href="https://www.sapo.pt"><img src="/img/btn-open-profile-menu.png" alt="" /></a> */}
                </div>
                <div className="name-profile">
                    <div className="div-name-wrapper">
                        <p className="p-profile-name">{this.state.name}</p>
                        {/* <p><strong>Lorem Ipsum Dolor Sit Amet</strong></p>
                        <p>Lorem Ipsum Dolor Sit Amet</p> */}
                    </div>
                </div>
            </div>
            
            {this.props.showProfileMenu 
            ? <ProfileMenu></ProfileMenu> 
            : null}
            
            </React.Fragment>
        );
    }
}

export default Profile;