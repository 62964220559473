import React from 'react';

function FileGalleryVideo(props) {
    
    const {type, img, title, desc, format, src} = props.file;
    
    return (
        <div className="flyer-viewer-entry">
            <div className="video-gallery-wrapper">
                    <iframe src={src} frameBorder="0" width="100%" height="80%" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
        </div>
    );
}

export default FileGalleryVideo;