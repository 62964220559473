import React from 'react';
import { useTranslation } from 'react-i18next';

function EposterSearch(props) {

    const { t, i18n } = useTranslation();

    const mostViewed = <input type="checkbox" name="most-filters" onClick={props.onMostViewed} />

    const bestScored = <input type="checkbox" name="most-filters" onClick={props.onBestScored} />

    const mostCommented = <input type="checkbox" name="most-filters" onClick={props.onMoreComments} />

    return (
        <div className="eposter-breadcrumbs-container">
            <div className="eposter-search-filter-bar">
                <div className="eposter-search-icon-magnifier">
                    <img alt="" src="/img/magnifier-icon-search-eposter.png" />
                </div>

                <div className="eposter-search-input">
                    <input onChange={props.onSearch} type="text" id="search-eposter" name="search-eposter" value={props.searchText} placeholder={t('poster_search')} />
                </div>

                <div className="eposter-search-icon-close">
                    <img onClick={props.cleanSearch} alt="" src="/img/close-icon-search-eposter.png" />
                </div>

                <div className="eposter-search-filter-separator"></div>

                {props.eventid != "4" ?
                    <span>
                        <div className="switch-eposter-search-filter">
                            <label className="switch">
                                {mostViewed}
                                <span className="slider round"></span>
                            </label>
                            &nbsp; {t('poster_most_viewed')}
                        </div>

                        <div className="switch-eposter-search-filter">
                            <label className="switch">
                                {bestScored}
                                <span className="slider round"></span>
                            </label>
                            &nbsp; {t('poster_best_rated')}
                        </div>


                        <div className="switch-eposter-search-filter last">
                            <label className="switch">
                                {mostCommented}
                                <span className="slider round"></span>
                            </label>
                            &nbsp; {t('poster_most_commented')}
                        </div>
                    </span>
                    : null}
            </div>

            {props.categorySelectedName ?

                props.eventid == "325" ?
                    <div>
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/a2c-eposter-logo2.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/acuna-fombona-eposter-logo.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/customimplants-eposter-logo.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/depuy-eposter-logo.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/mba-eposter-logo.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/stryker-eposter-logo.jpg" />
                        <img className="eposter-logos-top" src="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/zimmer-eposter-logo.jpg" />
                    </div>

                    : props.eventid == "4" ?
                        null
                        :

                        <div className="eposter-breadcrumbs-category" style={{ backgroundColor: props.categoryBackGroundColor }}>
                            {props.categorySelectedName}
                            &nbsp;<img onClick={props.cleanCategoryFilter} alt="" src="/img/close-icon-search-eposter.png" />
                        </div>


                : null}


        </div>
    );
}

export default EposterSearch;