import React, { Component } from 'react';
import configData from '../../config.json';
import AuthContext from '../../context/auth-context';
import axios from 'axios';
import { checkPropTypes } from 'prop-types';
import EmailValidator from 'email-validator';

class Registration extends Component {

    state = {
        render:'form',
        email : null, 
        email_valid:false,
        password : null, 
        password_check : null, 
        title : null, 
        name : null, 
        surname1 : null, 
        surname2 : null, 
        phone : null, 
        institution : null, 
        place : null, 
        society : null, 
        company : "-", 
        other: "1",
        policy:null
    }

    static contextType = AuthContext;


    onFormSaveHandler = () => {


        let config = {
            headers: {"content-type": "application/json"}
        };

        const myUser = {
            id: "0",
            email : this.state.email, 
            password : this.state.password,             
            title : this.state.title, 
            name : this.state.name, 
            surname : this.state.surname1 + ' ' + this.state.surname2, 
            phone : this.state.phone, 
            institution : this.state.institution, 
            place : this.state.place, 
            society : this.state.society, 
            company: this.state.company !== null ? this.state.company : "-",
            other: this.state.other,
            badge : this.state.name + ' ' + this.state.surname, 
            seo : "local", 
            locked : false, 
            photo : "", 
            event_id : this.context.event_settings.event_id, 
            isAdmin : false, 
            profile : this.state.other !== undefined && this.state.other == "1" ? "participant-full" : "participant"
        }


        if (this.state.email !== null &&
            this.state.password !== null &&
            this.state.title !== null &&
            this.state.name !== null &&
            this.state.surname1 !== null &&
            this.state.surname2 !== null &&
            this.state.phone !== null &&
            this.state.institution !== null &&
            this.state.place !== null &&
            this.state.society !== null &&
            this.state.other !== null) { 

                if (this.state.email_valid){
                    if(this.state.password.length > 4){
                        if(this.state.password === this.state.password_check ){
                            if (this.state.policy !== null) {
            
                                axios.post(configData.SERVER_API + '/users/', myUser, config)
                                    .then(response => {                    
                                        console.log(response.data)
                                        if (response.data.error !== undefined && response.data.error !== '0'){
                                            alert(response.data.message)
                                        } else {
                                            this.setState({render:'ok'})
                                        }
                                        
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                
                            }else {
                                alert('Por favor confirme que ha leído y acepta la Política de Privacidad.')
                            }
                        } else {
                            alert('Las contraseñas no coinciden.')
                        }
                    } else {
                        alert('La contraseña debe tener al menos 5 caracteres. ')
                    }

                } else {
                    alert('Formato de correo electrónico no válido.')
                }

        } else {
            alert('Por favor complete el formulario de registro.')
        }

    }


    onSetAnwserHandler = (event) => {
        switch (event.target.name) {
            case "registration-title":
                this.setState({ title: event.target.value !== "" ? event.target.value : null })
                break;
            case "registration-society":
                this.setState({ society: event.target.value !== "" ? event.target.value : null })
                break;
            case "registration-profesional-sanitario":
                this.setState({ other: event.target.value !== "" ? event.target.value : "1" })
                break;
            case "registration-privacy-policy":
                this.setState({ policy: event.target.value !== "" ? event.target.value : null })
                break;
            default:
                break;
        }
    }

    onCommentsHandler1 = (text) => {
        this.setState({ 
            email: text,
            email_valid: EmailValidator.validate(text)
        });
    }
    onCommentsHandler2 = (text) => {
        this.setState({ password: text });
    }
    onCommentsHandler3 = (text) => {
        this.setState({ password_check: text });
    }
    onCommentsHandler4 = (text) => {
        this.setState({ name: text });
    }
    onCommentsHandler5 = (text) => {
        this.setState({ surname1: text });
    }
    onCommentsHandler6 = (text) => {
        this.setState({ surname2: text });
    }
    onCommentsHandler7 = (text) => {
        this.setState({ phone: text });
    }
    onCommentsHandler8 = (text) => {
        this.setState({ institution: text });
    }
    onCommentsHandler9 = (text) => {
        this.setState({ place: text });
    }
    onCommentsHandler10 = (text) => {
        this.setState({ company: text });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {

        return (

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container">

                    {this.state.render == 'form' ? 
                    
                    <form className="form-registration" onSubmit={this.onSubmit}>

                        <h2>Registro</h2>

                        <h3>Acceso</h3>


                        <div className="input-wrapper">
                            <label htmlFor="registration-email">Email *</label>
                            <input type="Email" id="registration-email" name="registration-email" placeholder="Email" onChange={(event) => this.onCommentsHandler1(event.target.value)}></input>
                        </div>

                        <div className="input-wrapper">
                            <label htmlFor="registration-psw">Contraseña *</label>
                            <input type="password" id="registration-psw" name="registration-psw" placeholder="Contraseña" onChange={(event) => this.onCommentsHandler2(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-psw-check">Confirme Contraseña *</label>
                            <input type="password" id="registration-psw-check" name="registration-psw-check" placeholder="Contraseña" onChange={(event) => this.onCommentsHandler3(event.target.value)}></input>
                        </div>


                        <h3>Datos Personales</h3>

                        <div className="input-wrapper">
                            <label htmlFor="registration-title">Título *</label>
                            <select name="registration-title" id="registration-title" onChange={(event) => this.onSetAnwserHandler(event)}>
                                <option value="" defaultValue>Por favor seleccione</option>
                                <option value="Dr."> Dr. </option>
                                <option value="Dra."> Dra. </option>
                                <option value="Prof."> Prof. </option>
                                <option value="Enf"> Enf </option>
                                <option value="Enf MSc"> Enf MSc</option>
                                <option value="Enf PhD"> Enf PhD</option>
                                <option value="Fis"> Fis</option>
                                <option value="Fis MSc"> Fis MSc</option>
                                <option value="Fis PhD"> Fis PhD</option>
                                <option value="D."> D.</option>
                                <option value="Dña."> Dña.</option>
                                <option value="Sr."> Sr.</option>
                                <option value="Sra."> Sra.</option>
                                <option value="Enf MSc PhD"> Enf MSc PhD</option>
                                <option value="Excma. Sra. Doña"> Excma. Sra. Doña</option>
                                <option value="Dra. Dña."> Dra. Dña. </option>
                                <option value="Sr. D."> Sr. D.</option>
                                <option value="Sra. Dña."> Sra. Dña.</option>
                            </select>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-name">Nombre  *</label>
                            <input type="text" id="registration-name" name="registration-name" placeholder="Nombre" onChange={(event) => this.onCommentsHandler4(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-midlename">Primer Apellido  *</label>
                            <input type="text" id="registration-midlename" name="registration-midlename" placeholder="Primer Apellido" onChange={(event) => this.onCommentsHandler5(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-surname">Segundo Apellido  *</label>
                            <input type="text" id="registration-surname" name="registration-surname" placeholder="Segundo Apellido" onChange={(event) => this.onCommentsHandler6(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-movil">Móvil  *</label>
                            <input type="text" id="registration-movil" name="registration-movil" placeholder="Móvil" onChange={(event) => this.onCommentsHandler7(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-institution">Centro de trabajo  *</label>
                            <input type="text" id="registration-institution" name="registration-institution" placeholder="Centro de trabajo" onChange={(event) => this.onCommentsHandler8(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-city">Localidad  *</label>
                            <input type="text" id="registration-city" name="registration-city" placeholder="Localidad" onChange={(event) => this.onCommentsHandler9(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-society">Sociedad Científica *</label>
                            <select name="registration-society" id="registration-society" onChange={(event) => this.onSetAnwserHandler(event)}>
                                <option value="" defaultValue>Por favor seleccione</option>
                                <option value="70" other="0">Academia Española de Dermatología y Venereología (AEDV) </option>
                                <option value="148" other="0">Asociación Científica Española de Enfermería y Salud Escolar (ACEESE)</option>
                                <option value="73" other="0">Asociación de Especialistas en Enfermería del Trabajo (AET)</option>
                                <option value="72" other="0">Asociación de Enfermería Comunitaria (AEC)</option>
                                <option value="145" other="0">Asociación Despertando Enfermería  (ADeNfermer@)</option>
                                <option value="74" other="0">Asociación Española de Enfermeras de Nutrición y Dietética (ADENYD)&nbsp;</option>
                                <option value="75" other="0">Asociación Española de Enfermería de Prevención y Control de Infecciones (AEEPyCI)</option>
                                <option value="77" other="0">Asociación Española de Enfermería en Cardiología (AEEC)</option>
                                <option value="76" other="0">Asociación Española de Enfermería de Salud Mental (AEESME)&nbsp;</option>
                                <option value="78" other="0">Asociación Española de Enfermería Pediátrica (AEEP)</option>
                                <option value="79" other="0">Asociación Española de Enfermería Quirúrgica (AEEQ)</option>
                                <option value="80" other="0">Asociación Española de Especialistas en Medicina del Trabajo (AEEMT)</option>
                                <option value="81" other="0">Asociación Española de Fisioterapeutas (AEF)</option>
                                <option value="82" other="0">Asociación Española de Gastroenterología (AEG)</option>
                                <option value="83" other="0">Asociación Española de Genética Humana (AEGH)</option>
                                <option value="152" other="0">Asociación Española de Neuropsiquiatría-Profesionales de la Salud (AEN)</option>
                                <option value="84" other="0">Asociación Española de Vacunología (AEV)</option>
                                <option value="85" other="0">Asociación Española de&nbsp;Pediatría (AEP)</option>
                                <option value="86" other="0">Asociación Nacional de Comités de Ética de la Investigación (ANCEI)&nbsp;</option>
                                <option value="87" other="0">Asociación Nacional de Directivos de Enfermería (ANDE)</option>
                                <option value="88" other="0">Asociación Nacional de Enfermería y Vacunas (ANENVAC)</option>
                                <option value="91" other="0">Asociación Nacional de Medicina del Trabajo en el Ámbito Sanitario (ANMTAS)</option>
                                <option value="89" other="0">Asociación Nacional e Internacional de Enfermería Escolar (AMECE)</option>
                                <option value="146" other="0">Capítulo de Cirugía Endovascular (CCE)</option>
                                <option value="90" other="0">Federación de Asociaciones Científico Médicas Españolas (FACME)</option>
                                <option value="92" other="0">Federación de Asociaciones de Matronas de España (FAME)</option>
                                <option value="93" other="0">Federación Española de Enfermería del Trabajo (FEDEET)</option>
                                <option value="94" other="0">Grupo 40+ Iniciativa Enfermera</option>
                                <option value="96" other="0">Sociedad Científica Española de Enfermería (SCELE)</option>
                                <option value="95" other="0">International Nursing Network - Red Internacional de Enfermería</option>
                                <option value="97" other="0">Sociedad Española de Anatomía Patológica (SEAP-IAP)</option>
                                <option value="98" other="0">Sociedad Española de Anestesiología, Reanimación y Terapéutica del Dolor (SEDAR)</option>
                                <option value="99" other="0">Sociedad Española de Angiología y Cirugía Vascular (SEACV)</option>
                                <option value="100" other="0">Sociedad Española de Cardiología (SEC)</option>
                                <option value="101" other="0">Sociedad Española de Cuidados Paliativos (SECPAL)</option>
                                <option value="141" other="0">Sociedad Española del Dolor (SED)</option>
                                <option value="102" other="0">Sociedad Española de Endocrinología y Nutrición (SEEN)</option>
                                <option value="103" other="0">Sociedad Española de Enfermedades Infecciosas y Microbiología Clinica (SEIMC)</option>
                                <option value="150" other="0">Sociedad Española de Enfermería Geriátrica y Gerontológica (SEEGG)</option>
                                <option value="104" other="0">Sociedad Española de Enfermería Intensiva y Unidades Coronarias (SEEIUC)</option>
                                <option value="105" other="0">Sociedad Española de Enfermería Neurológica (SEDENE)</option>
                                <option value="149" other="0">Sociedad Española de Enfermería Radiológica (SEER)</option>
                                <option value="110" other="0">Sociedad Española de Geriatria y Gerontología (SEGG)</option>
                                <option value="106" other="0">Sociedad Española de Farmacia Clínica, Familiar y Comunitaria (SEFAC)</option>
                                <option value="107" other="0">Sociedad Española de Farmacia Hospitalaría (SEFH)</option>
                                <option value="108" other="0">Sociedad Española de Farmacogenética y Farmacogenómica (SEFF)</option>
                                <option value="109" other="0">Sociedad Española de Farmacología Clínica (SEFC)</option>
                                <option value="111" other="0">Sociedad Española de Ginecología y Obstetricia (SEGO)</option>
                                <option value="112" other="0">Sociedad Española de Hematología y Hemoterapia (SEHH)</option>
                                <option value="113" other="0">Sociedad Española de Hipertensión (SEH-LEHLA)</option>
                                <option value="114" other="0">Sociedad Española de Hospitalización a Domicilio (SEHAD).</option>
                                <option value="115" other="0">Sociedad Española de Inmunología (SEI)</option>
                                <option value="144" other="0">Sociedad Española de Medicina Aeroespacial (SEMA)</option>
                                <option value="147" other="0">Sociedad Española de Medicina de Urgencias y Emergencias Sanitarias (SEMES)</option>
                                <option value="116" other="0">Sociedad Española de Medicina Familiar y Comunitaria (SEMFyC)</option>
                                <option value="117" other="0">Sociedad Española de Medicina Intensiva, Crítica y Unidades Coronarias (SEMICYUC)</option>
                                <option value="118" other="0">Sociedad Española de Medicina Interna (SEMI)</option>
                                <option value="119" other="0">Sociedad Española de Medicina Preventiva y Salud Pública (SEMPSPH)</option>
                                <option value="120" other="0">Sociedad Española de Medicina y Seguridad en el Trabajo (SEMST)</option>
                                <option value="121" other="0">Sociedad Española de Médicos de Atención Primaria (SEMERGEN)</option>
                                <option value="122" other="0">Sociedad Española de Médicos Generales y de Familia (SEMG)</option>
                                <option value="123" other="0">Sociedad Española de Microbiología (SEM)</option>
                                <option value="124" other="0">Sociedad Española de Nefrología (S.E.N.)</option>
                                <option value="125" other="0">Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</option>
                                <option value="126" other="0">Sociedad Española de Neurología (SEN)</option>
                                <option value="127" other="0">Sociedad Española de Oftalmología (SEO)</option>
                                <option value="128" other="0">Sociedad Española de Oncología Médica (SEOM)</option>
                                <option value="129" other="0">Sociedad Española de Oncologia Radioterapica (SEOR)</option>
                                <option value="130" other="0">Sociedad Española de Patología Digestiva (SEPD)</option>
                                <option value="131" other="0">Sociedad Española de Periodoncia y Osteointegración (SEPA)</option>
                                <option value="132" other="0">Sociedad Española de Psiquiatría (SEP)</option>
                                <option value="133" other="0">Sociedad Española de Puericultura (S.E.P.)</option>
                                <option value="151" other="0">Sociedad Española de Medicina de Laboratorio (SEQC)</option>
                                <option value="134" other="0">Sociedad Española de Radiología Médica (SERAM)</option>
                                <option value="135" other="0">Sociedad Española de Rehabilitación y medicina física (SERMEF)</option>
                                <option value="136" other="0">Sociedad Española de Reumatología (SER)</option>
                                <option value="137" other="0">Sociedad Española de Sanidad Penitenciaria (SESP)</option>
                                <option value="138" other="0">Sociedad Española de Trasplante (SET)</option>
                                <option value="139" other="0">Sociedad Española de Trombosis y Hemostasia (SETH)</option>
                                <option value="140" other="0">Sociedad Española de Virología (SEV)</option>
                                <option value="143" other="0">Ninguno</option>
                                <option value="142" other="1">Otros</option>
                            </select>
                        </div>



                        <div className="input-wrapper">
                            <label htmlFor="registration-company">Empresa</label>
                            <input type="text" id="registration-company" name="registration-company" placeholder="Empresa" onChange={(event) => this.onCommentsHandler10(event.target.value)}></input>
                        </div>


                        <div className="input-wrapper">
                            <label htmlFor="registration-profesional-sanitario">¿Es usted profesional sanitario con capacidad para prescribir o dispensar medicamentos? *</label>
                            <select name="registration-profesional-sanitario" id="registration-profesional-sanitario" onChange={(event) => this.onSetAnwserHandler(event)}>
                                <option value="" defaultValue>Por favor seleccione</option>
                                <option value="1" other="0">Si</option>
                                <option value="0" other="0">No</option>
                            </select>
                        </div>


                        <h3>Política de privacidad</h3>

                        <div className="input-wrapper">
                            *<input type="checkbox" id="registration-privacy-policy" name="registration-privacy-policy" value="Bike" onChange={(event) => this.onSetAnwserHandler(event)}></input>
                            <label htmlFor="vehicle1"> He leído y acepto la Política de privacidad.</label>
                        </div>

                        <div className="registration-form-privacy-policy">


                            <p align="center">
                                <strong>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS DE LA SOCIEDAD ESPAÑOLA DE NEUMOLOGIA Y CIRUGIA TORÀCICA (SEPAR) </strong></p>
                            <p>
                                &nbsp;</p>
                            <p>
                                En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, y de la normativa vigente en materia de protección de datos, ponemos en su conocimiento:</p>
                            <p>
                                &nbsp;</p>
                            <p>
                                <strong>1.- RESPONSABLE DEL TRATAMIENTO</strong></p>
                            <p>
                                <strong>Responsable: </strong>SOCIEDAD ESPAÑOLA DE NEUMOLOGIA Y CIRUGIA TORÀCICA (SEPAR) – (NIF:G58952805)&nbsp; Dir. postal: C/ Provença nº 108 bajos (08029) Barcelona Teléfono: 934878565 e-mail: secretaria@separ.es</p>
                            <p>
                                <strong>Representante</strong>: Sra. Sara Portillo Bonany domiciliada a efectos de protección de datos en Dir. postal: C/ Provença nº 108 bajos (08029) Barcelona Teléfono: 934878565 e-mail: secretaria@separ.es</p>
                            <p>
                                &nbsp;</p>
                            <p>
                                <strong>2.- PRINCIPIOS SOBRE EL TRATAMIENTO DE LOS DATOS </strong></p>
                            <p>
                                En el tratamiento de tus datos personales, aplicaremos los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:</p>
                            <ul>
                                <li>
                                    <strong>Principio de licitud, lealtad y transparencia</strong>:&nbsp;Siempre vamos a requerir tu consentimiento para el tratamiento de tus datos personales para uno o varios fines específicos que te informaremos previamente con absoluta transparencia.</li>
                                <li>
                                    <strong>Principio de minimización de datos</strong>:&nbsp;Solo vamos a requerir datos estrictamente necesarios en relación con los fines para los que los requerimos. Los mínimos posibles.</li>
                                <li>
                                    <strong>Principio de limitación del plazo de conservación</strong>:&nbsp;los datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento, en función a la finalidad, te informaremos del plazo de conservación correspondiente, en el caso de suscripciones, periódicamente revisaremos nuestras listas y eliminaremos aquellos registros inactivos durante 3 años.</li>
                                <li>
                                    <strong>Principio de integridad y confidencialidad:</strong>&nbsp;Tus datos serán tratados de tal manera que se garantice una seguridad adecuada de los datos personales y se garantice confidencialidad. Debes saber que tomamos todas las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de nuestros usuarios por parte de terceros.<br />
		&nbsp;</li>
                            </ul>
                            <p>
                                <strong>3.- EXACTITUD Y VERACIDAD DE LOS DATOS </strong></p>
                            <p>
                                El titular de los datos es el único responsable de la veracidad y corrección de los datos que remita a SEPAR, de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción.<br />
	&nbsp;</p>
                            <p>
                                <strong>4. FINALIDADES</strong></p>
                            <p>
                                La recogida y tratamiento de los datos personales recabados mediante la página Web o cualquier otro portal se realiza con la finalidad de llevar a término la prestación de los servicios que SEPAR ofrece y más concretamente los que, seguidamente se exponen:</p>
                            <ul>
                                <li>
                                    Gestionar sus peticiones de participación en los cursos, reuniones&nbsp; y congresos que organiza SEPAR lo que puede implicar ofertar viajes y lugares donde alojarse.</li>
                                <li>
                                    Gestionar el cobro para la participación en los cursos, reuniones y congresos que organiza SEPAR, así como para pagar los gastos de viajes y alojamiento.</li>
                                <li>
                                    Poner en su conocimiento informaciones sobre los futuros cursos, reuniones y congresos que organiza o en los que colabora SEPAR junto con ofertas de viajes y alojamiento referidos a dichos eventos.</li>
                            </ul>
                            <p>
                                En ningún caso los datos personales de los usuarios serán utilizados para ninguna otra finalidad adicional.<br />
	&nbsp;</p>
                            <p>
                                <strong>5. DATOS QUE SE TRATAN</strong></p>
                            <p>
                                Los datos a tratar por SEPAR en el marco de la relación con el interesado y condicionado a las finalidades consentidas, se incluyen en las siguientes categorías:</p>
                            <ul>
                                <li>
                                    Datos identificativos y de contacto, por ejemplo, a título enunciativo y no limitativo: nombre, apellidos, teléfono, dirección, tratamiento o correo electrónico.</li>
                                <li>
                                    Datos comerciales o bancarios en función de las indicaciones recibidas.</li>
                                <li>
                                    Estas categorías pueden ampliarse a académicos o profesionales, dependiendo del propósito para el que se haya contactado con SEPAR<br />
		&nbsp;</li>
                            </ul>
                            <p>
                                <strong>6. CONSERVACIÓN DE LOS DATOS</strong></p>
                            <p>
                                Los datos personales proporcionados se conservarán mientras:</p>
                            <ul>
                                <li>
                                    Sean necesarios para dar respuesta a la relación contractual.</li>
                                <li>
                                    Para dar respuesta a las peticiones o solicitudes realizadas.</li>
                                <li>
                                    Mientras no se solicite la supresión por el interesado.</li>
                                <li>
                                    El tiempo necesario para dar cumplimiento a las obligaciones asumidas en relación con los cursos, reuniones o congresos que organiza SEPAR.<br />
		&nbsp;</li>
                            </ul>
                            <p>
                                <strong>7. DESTINATARIOS A LOS QUE SE COMUNICARÁN LOS DATOS.</strong></p>
                            <p>
                                Los datos del interesado podrán ser comunicados a los siguientes destinatarios:</p>
                            <p>
                                <strong><u>Asesoría/Gestoría:</u></strong> BECERRA ADVOCATS, S.C.P. pueden tener acceso a datos en el marco en su actividad como asesores legales. BECERRA ECONOMISTES S.L pueden tener acceso a datos en el marco de su actividad como asesores económicos y contables.</p>
                            <p>
                                <strong><u>Informática, web hosting, cloud computing</u>:</strong> Jointogether Group S.L puede acceder a datos en sus funciones de web hosting y de cloud computing, así como para la prestación de servicios técnicos.</p>
                            <p>
                                <strong><u>Agencia de Viajes</u>:</strong> VIAJES EL CORTE INGLÉS, S.A., puede acceder a datos como Agencia de Viajes con licencia C.I.C.- MA 59, con quien se tiene contratados los servicios como agencia de viajes, organizadora de congresos y eventos y quien presta servicios de Secretaría Técnica.</p>
                            <p>
                                Por otra parte, también podrán ser cedidos en los casos en que exista una obligación legal, de a las Administraciones Públicas pertinentes y entidades bancarias.</p>
                            <p>
                                <strong><u>Análisis de datos</u>:</strong> GOOGLE ANALYTICS: un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en California (Estados Unidos de América).</p>
                            <p>
                                Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar SEPAR a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de SEPAR&nbsp; (incluyendo la dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
                            <p>
                                Por otra parte, también podrán ser cedidos en los casos en que exista una obligación legal, de a las Administraciones Públicas pertinentes y entidades bancarias.</p>
                            <p>
                                &nbsp;</p>
                            <p>
                                <strong>TRANSFERENCIAS INTERNACIONALES</strong></p>
                            <p>
                                Los datos son susceptibles de ser accedidos desde fuera de la Unión Europea y el Espacio Económico Europeo, aunque con Estados en los que existen decisiones de adecuación a los niveles de protección establecidos por el RGPD de la Unión Europea, como los Estados Unidos de América, donde está establecido el escudo de privacidad (Decisión (UE) 2016/1250 de 12 julio de 2016).<br />
                                <br />
                                <strong>8. LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS</strong></p>
                            <p>
                                La base legal para gestionar la relación con los usuarios y otros interesados y para ofrecer información sobre los cursos, reuniones y congresos de SEPAR puede ser la ejecución de un contrato, o bien, el consentimiento inequívoco del interesado en caso de no existir una relación contractual previa.<br />
	&nbsp;</p>
                            <p>
                                <strong>ACEPTACIÓN Y CONSENTIMIENTO DEL USUARIO</strong></p>
                            <p>
                                El envío de los datos personales mediante los formularios disponibles en la Web o, si es el caso, el envío de mensajes por correo electrónico solicitando información o asistencia supone el consentimiento del Usuario para que sus datos personales sean utilizados de acuerdo con las finalidades anteriormente mencionadas y la aceptación de esta Política de Privacidad.<br />
	&nbsp;</p>
                            <p>
                                <strong>9. DERECHOS DEL INTERESADO</strong></p>
                            <p>
                                Cualquier persona tiene derecho a obtener confirmación sobre si en SEPAR estamos tratando datos personales que le conciernan o no. Las personas interesadas y usuarios tienen derecho a&nbsp;<strong>acceder</strong>&nbsp;a sus datos personales, así como a solicitar la&nbsp;<strong>rectificación</strong>&nbsp;de datos inexactos o, en su caso, solicitar su&nbsp;<strong>supresión</strong>&nbsp;cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán&nbsp;<strong>oponerse</strong>&nbsp;al tratamiento de sus datos. SEPAR dejará de tratar los datos salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. En determinadas circunstancias, los interesados podrán solicitar la&nbsp;<strong>limitación del tratamiento</strong>, de modo que SEPAR &nbsp;sólo los reservará y utilizará en los casos autorizados por la Ley.</p>
                            <p>
                                Cuando los datos se recogen en un formato estructurado, los interesados podrán pedir a SEPAR la&nbsp;<strong>transmisión</strong>&nbsp;directa de sus datos a otros responsables o recibir sus datos en formato electrónico, para almacenarlos en sus dispositivos propios, sin necesidad de transmitirlos a otros responsables.</p>
                            <p>
                                El interesado podrá ejercer dichos derechos, así como <strong>revocar su consentimiento</strong> al envío de comunicaciones, en cualquier momento mediante el envío de un correo electrónico a la dirección <a href="mailto:secretaria@separ.es">secretaria@separ.es</a> adjuntando fotocopia del DNI o documento identificativo sustitutorio e indicando claramente el derecho que desea ejercer. Asimismo, podrá realizar la solicitud por correo postal a la dirección indicada anteriormente.</p>
                            <p>
                                Finalmente, informamos a los interesados que pueden presentar una reclamación relacionada con el tratamiento de sus datos personales ante la Autoridad de Control&nbsp;<a href="http://www.aepd.es/" target="_blank">(www.aepd.es)</a>.<br />
	&nbsp;</p>
                            <p>
                                <strong>10. APLICABILIDAD A OTROS SITIOS WEB Y REDES SOCIALES</strong></p>
                            <p>
                                La página web de SEPAR puede contener enlaces a otros sitios. En caso de que se introdujeran enlaces a otros sitios, debe tenerse en cuenta que SEPAR no dispone de control alguno ni ostenta responsabilidad alguna sobre las políticas o medidas de protección de datos de otros sitios web.</p>
                            <p>
                                Por otra parte, la web de SEPAR contiene enlaces a las cuentas que SEPAR tiene en redes sociales (Blogger, Twitter, Facebook, <a href="https://www.youtube.com/user/HIPERTINHISTYLE" target="_blank">Youtube</a> e <a href="https://www.instagram.com/hipertin_official/" target="_blank">Instagram</a>).</p>
                            <p>
                                El tratamiento de los datos que se lleve a cabo de las personas que se hagan seguidoras en las redes sociales de las páginas oficiales de SEPAR se regirán por esta política de privacidad además de por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y aceptadas previamente por el usuario de SEPAR</p>
                            <p>
                                <br />
                                <strong>11.- NAVEGACIÓN Y USO DE COOKIES</strong></p>
                            <p>
                                La web de SEPAR utiliza cookies. Al navegar por la misma se pueden recoger datos no identificables, incluyendo direcciones de IP, registro de uso de nuestros servicios y demás datos que no pueden utilizarse para identificar al usuario. Entre los datos no identificativos están también los relacionados a tus hábitos de navegación a través de servicios de análisis de terceros. Para ellos se utiliza GOOGLE ANALYTICS.</p>
                            <p>
                                Utilizamos esta información para analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios alrededor del sitio y para recopilar información sobre nuestra base de usuarios en su conjunto. Si quieres saber más sobre la navegación en SEPAR puedes hacerlo a través de nuestra política de cookies (<span><strong><a href="https://2congresocovid.es/" target="_blank">AQUI</a></strong></span>).<br />
	&nbsp;</p>
                            <p>
                                <strong>12. SECRETO PROFESIONAL</strong><br />
                                <br />
	Las comunicaciones privadas entre los usuarios o visitantes y el personal de SEPAR a través de la web son confidenciales. El acceso a esta información está restringido mediante herramientas tecnológicas (contraseñas incluidas) y mediante controles internos. Todas las comunicaciones privadas entre SEPAR y los usuarios serán consideradas como confidenciales. Además, también tendrá la condición de confidencial la información de cualquier tipo que intercambien ambas partes, la información que ambas partes acuerden que tiene tal naturaleza y la información sobre la información confidencial. El usuario y el personal de SEPAR tienen acceso al contenido de los mensajes intercambiados entre ellos exclusivamente a través de la web.<br />
	&nbsp;</p>
                            <p>
                                <strong>13. CORREO COMERCIAL </strong></p>
                            <p>
                                De acuerdo con la LSSICE,&nbsp;SEPAR no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el Usuario. En consecuencia, en cada uno de los formularios habidos en la web, el Usuario tiene la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información comercial puntualmente solicitada. Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico,&nbsp;SEPAR &nbsp;se compromete a no enviar comunicaciones de carácter comercial sin identificarlas debidamente.<br />
	&nbsp;</p>
                            <p>
                                <strong>14. CAMBIOS EN LA POLÍTICA DE SEGURIDAD Y PROTECCIÓN DE DATOS</strong></p>
                            <p>
                                SEPAR se reserva el derecho de modificar su política de seguridad y protección de datos de forma discrecional, para adaptarla a novedades legislativas o jurisprudenciales, con efectos de la fecha de publicación de dicha modificación. Los cambios que afecten al tratamiento de datos personales se anunciarán en esta página con razonable antelación a su puesta en práctica.</p>



                        </div>




                        <div className="input-wrapper" style={{ textAlign: "center" }}>
                            <input type="submit" value="Registrar" className="btn-submit" onClick={this.onFormSaveHandler} />
                        </div>

                        <p>&nbsp;</p>


                    </form>

                    : 
                    
                    <form className="form-registration" onSubmit={this.onSubmit}>
                        <h2>Registro</h2>
                        <p>¡Bienvenido! Se ha registrado correctamente.</p>
                        <p>Para acceder al congreso virtual deberá cerrar esta ventana y iniciar sesión con usuario y contraseña.</p>
                        <p>Muchas gracias</p>
                    </form>
                    
                    }

                    

                </div>

                <div className="legal-text-btn-container">
                    <div onClick={this.props.onRegistrationFormClose} className="stand-disclaimer-close-btn-container">Cerrar</div>
                </div>

            </div>

        );
    }
}

export default Registration;



