
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoginArea from './LoginArea/LoginArea';
import axios from 'axios';
import AuthContext from '../../context/auth-context';
import Welcome from '../Welcome/Welcome';
import WelcomeBadge from '../Welcome/WelcomeBadge';
import { withTranslation } from 'react-i18next';
import configData from '../../config.json';
import ZepellinAnimation from '../../components/ZepellinAnimation/ZepellinAnimation';

class Entrance extends Component {

    state = {
        eventId: 0,
        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'entrance', ''),
        active: true
    }

    static contextType = AuthContext;

    componentDidMount() {

        this.onRouteChanged(this.props.location);

        let context_event_id = "";
        const storedSettings = localStorage.getItem('e-congress.events.settings');
        if (storedSettings) {
            context_event_id = JSON.parse(storedSettings).event_id;
        }


        if (this.props.match.params.congress !== "") {
            let data = {
                headers: { "content-type": "application/json", 'Accept': '*/*' },
                params: {}
            };


            axios.get(configData.SERVER_API + "/events/" + this.props.match.params.congress, data)
                .then(response => {

                    if (response.data !== undefined && response.data.length > 0) {
                        const event = response.data[0];

                        const event_event_id = event.event_id;

                        this.setState({
                            eventId: event.event_id,
                            backgroundImage: event.entrance_background,         // faz o set do state
                            active: event.active !== undefined ? event.active : true
                        })

                        this.context.event_id = event.event_id;   // faz o set do context
                        this.context.event_code = this.props.match.params.congress

                        const json_event_settings = {
                            code: event.code,
                            pwd_free: event.auth_pwd !== undefined ? !event.auth_pwd : false,
                            entrance_background: event.entrance_background,
                            event_id: event.event_id,
                            industry_background: event.industry_background,
                            lang: event.lang,
                            lobby_background: event.lobby_background,
                            logo: event.logo,
                            menu: event.menu,
                            name: event.name,
                            networking_background: event.networking_background,
                            room_background: event.room_background,
                            rooms_background: event.rooms_background,
                            meeting_background: event.meeting_background,
                            meetings_background: event.meetings_background,
                            scientific_background: event.scientific_background,
                            url_auth: event.url_auth,
                            badge: event.badge,
                            badge_sponsor: event.badge_sponsor,
                            badge_sponsor_main: event.badge_sponsor_main,
                            badge_position: event.badge_position,
                            badge_background: event.badge_background,
                            badge_sponsor: event.badge_sponsor,
                            virtual_secretariat: event.virtual_secretariat,
                            privacy_policy: event.privacy_policy,
                            my_profile: event.my_profile,
                            my_bag: event.my_bag,
                            my_credits: event.my_credits,
                            my_schedule: event.my_schedule
                        }

                        const currEventLang = localStorage.getItem('e-congress.event.lang');

                        this.context.event_setttings = json_event_settings;
                        localStorage.setItem('e-congress.events.settings', JSON.stringify(json_event_settings));
                        localStorage.setItem('e-congress.events.history', event.code);
                        localStorage.setItem('e-congress.event.lang', event.lang);

                        if (context_event_id !== event_event_id) {
                            window.location.reload();
                        }

                        if (currEventLang !== event.lang) {
                            window.location.reload();
                        }


                    } else {
                        // manda para a homepage para a pagina da Jointogether, com a lista de eventos activos
                        this.props.history.push('/');
                    }
                })
                .catch(error => {
                    console.log(error.message);
                })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged(this.props.location);
        }
    }


    onRouteChanged(location) {
        //console.log("ROUTE CHANGED - " + location.pathname);
    }




    render() {

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';


        return (
            <div>



                <LoginArea eventActive={this.state.active} event_id={this.state.eventId}></LoginArea>

                {this.state.eventId == "314" || this.state.eventId == "372" || this.state.eventId == "373" || this.state.eventId == "424" ?
                    <ZepellinAnimation event_id={this.state.eventId}></ZepellinAnimation>
                    : null}

            </div>

        );
    }
}

export default withTranslation()(withRouter(Entrance));
