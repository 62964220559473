import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
    secretariat_contact_request_EMIT,
    secretariat_contact_close,
    secretariat_contact_reply,
    secretariat_chat_message_post_EMIT
} from '../../store/actions/secretariat';

import SecretariatList from './SecretariatList'
import ContactRequest from '../Congress/IndustriaStand/ContactRequest'
import Text from './AssistantText'
/* import Voice from './AssistantVoice' */
import Video from './AssistantVideo'
import AuthContext from '../../context/auth-context';
/* import io from "socket.io-client";
import configData from './../../config.json'; */

class Assistant extends Component {

    state = {
        secretariatShow: false
    }

    static contextType = AuthContext;

    componentDidMount() { }


    // ---- CHAT START EVENTS ------------------------------------
    onMainTxtHandler = (id) => {
        this.props.onChatStart({
            chatActive: true,
            chatType: "txt",
            chatRoom: 'SECRETARIAT.' + this.context.id + '.' + id,
            chatRecipientId: id,
            emit: {
                room: 'SECRETARIAT',
                id: this.context.id,
                contactId: id,
                type: "txt",
                roomAddress: 'SECRETARIAT.' + this.context.id + '.' + id
            }
        })

        /* this.setState({
            chatActive:true,
            chatType:"txt",
            chatRoom:this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId:id
        })
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId:id,
            type: "txt",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room      */
    }
    onMainVoiceHandler = (id) => {
        this.props.onChatStart({
            chatActive: true,
            chatType: "voice",
            chatRoom: 'SECRETARIAT.' + this.context.id + '.' + id,
            chatRecipientId: id,
            emit: {
                room: 'SECRETARIAT',
                id: this.context.id,
                contactId: id,
                type: "voice",
                roomAddress: 'SECRETARIAT.' + this.context.id + '.' + id
            }
        })

        /* this.setState({
            chatActive:true,
            chatType:"voice",
            chatRoom:this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId:id
        })
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId:id,
            type: "voice",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room      */
    }
    onMainVideoHandler = (id) => {
        this.props.onChatStart({
            chatActive: true,
            chatType: "video",
            chatRoom: 'SECRETARIAT.' + this.context.id + '.' + id,
            chatRecipientId: id,
            emit: {
                room: 'SECRETARIAT',
                id: this.context.id,
                contactId: id,
                type: "video",
                roomAddress: 'SECRETARIAT.' + this.context.id + '.' + id
            }
        })

        /* this.setState({
            chatActive:true,
            chatType:"video",
            chatRoom:this.state.id + '.' + this.context.id + '.' + id,
            chatRecipientId:id
        })   
        const data = {
            room: this.state.id,
            id: this.context.id,
            contactId:id,
            type: "video",
            roomAddress: this.state.chatRoom
        }
        this.socket.emit('contact-request', data); // join the room      */
    }

    // ---- CHAT CLOSE EVENTS ---------------------------------------
    onCloseChatHandler = () => {
        this.props.onChatClose({
            chatRoom: null,
            chatActive: false
        })

        /* this.setState({
            chatRoom:null,
            chatActive:false
        }) */
    }


    // ---- CHAT MESSAGES ----------------------------------------
    sendChatMessage = (message) => {
        this.props.onMessagePost({
            sender_id: this.context.id,
            message: message
        })


        /* const data = {
            chatId:this.state.chatRoom, 
            sender_id:this.context.id,
            message:message}
        this.socket.emit('chat-message', data); */
    }



    // ---- REQUEST ----------------------------------------------
    acceptContactHandler = () => {
        this.props.onRequestReply({
            type: "accept",
            contactRequestActive: false,
            contactRequest: null,
            chatActive: true,
            chatMessages: []
        })

        /* this.setState({
            contactRequestActive: false,
            contactRequest: null,
            chatType: this.state.contactRequest.type, 
            chatRoom: this.state.id + '.' + this.state.contactRequest.id + '.' + this.context.id,
            chatActive: true,
            chatMessages:[]
        })         */
    }
    declineContactHandler = () => {
        this.props.onRequestReply({
            type: "decline",
            contactRequestActive: false,
            contactRequest: null
        })

        /* this.setState({
            contactRequestActive: false,
            contactRequest: null
        })  */
    }
    closeContactRequest = () => {
        this.props.onRequestReply({
            type: "close",
            contactRequestActive: false
        })

        /* this.setState({
            contactRequestActive: false
        }) */
    }



    // ---- LOCAL STATE ------------------------------------------
    onSecretariatHide = () => {
        this.setState({ secretariatShow: false })
    }
    onSecretariatShow = () => {
        this.setState({ secretariatShow: true })
    }
    onVoiceSetHandler = () => { }
    onVideoSetHandler = () => { }


    render() {

        const isActive = true;

        let logoPCO = "";

        if (this.context.event_id === "366") {
            logoPCO = null;
        }
        else if (this.context.event_id === "369" || this.context.event_id === "1" || this.context.event_id === "325" || this.context.event_id === "341") {
            logoPCO = <img width="90px" alt="" src="/img/logo_torres.png" />;
        }
        else if (this.context.event_id === "387" || this.context.event_id === "2" || this.context.event_id === "3" || this.context.event_id === "4" || this.context.event_id === "5") {
            logoPCO = <img width="90px" alt="" src="/img/help-es-letters2.png" />;
        }
        else if (this.context.event_id === "397") {
            logoPCO = <img width="90px" alt="" src="/img/help-en-letters.png" />;
        }
        else if (this.context.event_id === "575" || this.context.event_id === "656") {
            logoPCO = <img width="90px" alt="" src="/img/help-es-letters-secretaria-tecnica.png" />;
        }
        
        else {
            logoPCO = <img width="90px" alt="" src="/img/logo_el_corte_ingles_black_letters.png?ver=2" />;
        }

        let mySecretariatList;
        if (this.state.secretariatShow) {
            mySecretariatList = <SecretariatList
                closeSecretariatList={this.onSecretariatHide}
                onTxt={this.onMainTxtHandler}
                onVoice={this.onMainVoiceHandler}
                onVideo={this.onMainVideoHandler}
            ></SecretariatList>
        } else {
            mySecretariatList = null;
        }


        let myChat;
        if (this.props.chatActive) {

            if (this.props.chatType === "txt") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Text messages={this.props.chatMessages} userid={this.context.id} sendMessage={this.sendChatMessage} voice={this.onVoiceSetHandler} video={this.onVideoSetHandler} close={this.onCloseChatHandler} />
                    </div>
                </div>

            if (this.props.chatType === "voice") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={false} close={this.onCloseChatHandler} room={this.props.chatRoom} />
                    </div>
                </div>

            if (this.props.chatType === "video") myChat =
                <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        <Video video={true} close={this.onCloseChatHandler} room={this.props.chatRoom} />
                    </div>
                </div>


        } else {
            myChat = null;
        }


        const myContactRequest = this.props.contactRequestActive ?
            <ContactRequest
                request={this.props.contactRequest}
                closeContactRequest={this.closeContactRequest}
                accept={this.acceptContactHandler}
                decline={this.declineContactHandler} />
            : null

        if (isActive && (this.context.login(this.context.token) || this.context.autoLogin())) {

            return (
                <React.Fragment>
                    
                    {/*<div className="div-rovi-sponsor-track">
                        <p>Track Virtual<br />patrocinado<br />por:</p>
                        <img src="https://www.emma.events/static/virtualvenue/cnp2023/entrance/rovi-logo.jpg" />
                    </div>*/}
                    
                    <div className={'virtual-assistent-container cursor-only ' + this.context.event_code.toLowerCase()} onClick={this.onSecretariatShow}>

                        <div className={'virtual-assistant-avatar ' + this.context.event_code}>
                            <img alt="" src="/img/assistant-avatar-3.png" />
                        </div>

                        <div className="virtual-assistant-bar">
                            <div className="virtual-assitant-logo-left">
                                <img alt="" src="/img/virtual-assistant-logo1.png" />
                            </div>
                            <div className="virtual-assitant-separator"></div>
                            <div className="virtual-assitant-text"> {logoPCO}  </div>
                            <div className="virtual-assitant-logo-right">
                                {/* <img alt="" src="/img/virtual-assistant-logo2.png" /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='backdrop-virtual-assistant'>
                    <div className='virtual-assistant-chat-container'>
                        {assistant}
                    </div>
                </div> */}

                    {mySecretariatList}
                    {myChat}
                    {myContactRequest}

                </React.Fragment>
            );
        } else {

            return (
                <React.Fragment>
                    <div className="virtual-assistent-container cursor-only" onClick={this.onSecretariatShow}>
                        <div className="virtual-assistant-avatar">
                            <img alt="" src="/img/assistant-avatar-3.png" />
                        </div>
                        <div className="virtual-assistant-bar">
                            <div className="virtual-assitant-logo-left">
                                <img alt="" src="/img/virtual-assistant-logo1.png" />
                            </div>
                            <div className="virtual-assitant-separator"></div>
                            <div className="virtual-assitant-text"> {logoPCO} </div>
                            <div className="virtual-assitant-logo-right">
                                {/* <img alt="" src="/img/virtual-assistant-logo2.png" /> */}
                            </div>
                        </div>
                    </div>

                    {mySecretariatList}
                    {myChat}
                    {myContactRequest}

                </React.Fragment>
            );
        }



    }
}

const mapStateToProps = state => {
    return {
        chatType: state.secretariat.chatType,
        chatActive: state.secretariat.chatActive,
        chatRoom: state.secretariat.chatRoom,
        chatMessages: state.secretariat.chatMessages,
        chatRecipientId: state.secretariat.chatRecipientId,
        contactRequestActive: state.secretariat.contactRequestActive,
        contactRequest: state.secretariat.contactRequest
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChatStart: (data) => dispatch(secretariat_contact_request_EMIT(data)),
        onChatClose: (data) => dispatch(secretariat_contact_close(data)),
        onRequestReply: (data) => dispatch(secretariat_contact_reply(data)),
        onMessagePost: (data) => dispatch(secretariat_chat_message_post_EMIT(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assistant);