

export const PrivacyPolicyCNP2020 = `
<p>
Denominación Social: Fundación Española de Psiquiatría y Salud Mental <br/>
Domicilio Social: C/ Arturo Soria, 311, 1º B, 28033 Madrid <br/>
CIF: G82940776 <br/>
Teléfono: 91 383 4145 <br/>
Correo electrónico: fepsm@fepsm.org <br/>
Nombre de dominio: www.congresonacionaldepsiquiatria.es <br/>
Registro de Fundaciones del Ministerio de Educación con el número 566.
</p>
 
<p>Privacidad y protección de datos</p>
 
<p>Estas normas rigen para la web "congresonacionaldepsiquiatria.es" de titularidad de la Fundación Española de Psiquiatría y Salud Mental (en adelante FESPM).</p>
 
<p>Para saber cómo se procede con los datos personales, rogamos lea las siguientes normas que aconsejamos consultar con regularidad, dado que pueden ser actualizadas.</p>
 
<p>El usuario se hace responsable y garantiza que los datos personales que facilita a FEPSM son veraces y cuenta, cuando proceda, con la debida autorización para ello del titular de los mismos.</p>
 
<p>FEPSM tratará los datos de carácter personal vinculados a sus espacios webs respetando las exigencias de la legislación vigente, resaltando:</p>
 
<p>Reglamento de Protección de Datos</p>
 
<p>A partir del 25 de Mayo de 2018 es aplicable el nuevo Reglamento de Protección de Datos. El Reglamento UE 2016/679 armoniza la legislación en materia de Protección de Datos en toda la Unión Europea, incrementado la protección de las personas físicas titulares de datos personales y otorgándoles un mayor control sobre éstos.</p>
 
<p>En FEPSM siempre nos hemos preocupado por la protección de los datos personales. Por ello, hemos actualizado nuestra Política de Privacidad a fin de ajustarla a la nueva regulación e informarte sobre los cambios que afectan a tus derechos:</p>
 
<p>Los datos que FEPSM recaba por las diferentes vías (página web, correo electrónico, formularios electrónicos o en papel)  dentro de su actividad de servicios, pasan a formar parte de un fichero de tratamiento del cual es responsable.</p>
 
<p>Finalidades para las cuales se recaban datos:</p>
 
<p>
La gestión de la relación de los socios e inscritos; <br/> 
La prestación de servicios a los socios ya a personas o representantes de empresas relacionadas ; <br/> 
El mantenimiento de la relación institucional entre la FEPSM y las instituciones públicas o privadas que representan; <br/> 
El envío de comunicaciones sobre nuestras actividades, cursos y servicios.
</p>

<p>Duración de la conservación de los datos:</p>
 
<p>En el caso de los Proveedores, Clientes, Socios y Personal, conservamos sus datos mientras dure la relación contractual con FEPSM.</p>
 
<p>Legitimación de los tratamientos:</p>
 
<p>Los datos tratados por FEPSM son aquellos obtenidos con el consentimiento del interesado, así como aquellos suministrados voluntariamente por los interesados por cualquier medio.</p>
 
<p>Los datos necesarios para el mantenimiento de una relación contractual (contrato: prestación de servicios; laboral; mercantil; entre otros)</p>
 
<p>Destinatarios de los datos.</p>
 
<p>Los datos cedidos a terceros son datos identificativos y de contacto (nombre, apellidos, email y dirección), con el fin de organizar congresos y convenciones sobre psiquiatría.</p>
 
<p>En caso de cesión, será previamente informado de la cesión, finalidad e identificación del cesionario.</p>
 
<p>Derechos de los titulares de los datos:</p>
 
<p>Cualquier persona tiene derecho a conocer si FEPSM, realiza tratamientos de sus datos, o no. Y tiene derecho a:</p>
 
<p>
Acceder a sus datos personales. <br/> 
Solicitar la rectificación de los datos inexactos. <br/> 
Solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. <br/> 
Oponerse al tratamiento de tus datos, por motivos relacionados con su situación particular, solicitando que no sean tratados por FEPSM. <br/> 
En determinadas circunstancias, se podrá solicitar la limitación del tratamiento de tus datos, en cuyo caso únicamente se conservaran para el ejercicio o la defensa de reclamaciones. <br/> 
Retirar, el consentimiento prestado, sin que ello afecte a la licitud del tratamiento que se haya realizado con anterioridad a dicha retirada. <br/> 
Cuando se ejerciten los derechos de supresión, oposición, limitación o se retire el consentimiento, FEPSM dejará de tratar los datos, salvo por motivos legítimos imperiosos o el ejercicio o la defensa de posibles reclamaciones.
</p>

<p>El titular de los datos podrá ejercitar los derechos contemplados en el Capítulo III del Reglamento General de Protección de Datos, así como de la LOPD, dirigiéndose a Fundación Española de Psiquiatría y Salud Mental, en la calle Arturo Soria, 311, 1º B, 28033 Madrid (España) o si lo prefiere por correo electrónico por correo electrónico a través del siguiente: fepsm@fepsm.org</p>
 
<p>Siempre que se ejerciten los derechos expuestos, se deberán de acompañar a la solicitud una copia de su DNI o documento equivalente que permita comprobar la identidad.</p>
 
<p>Asimismo, si no está conforme con cómo se han atendido sus derechos, podrá presentar una reclamación ante la Agencia Española de Protección de Datos, a través de la página Web www.agpd.es.</p>
 
<p>Procedencia de los datos:</p>
 
<p>FEPSM obtiene los datos de carácter personal directamente del interesado a través de los siguientes canales:</p>
 
<p>
Correo electrónico <br/> 
Formularios/ Cuestionarios (Web , electrónicos o formato papel) <br/> 
Intercambio de tarjetas de presentación. <br/> 
Las categorías de datos: <br/> 
Datos de identificación <br/> 
Direcciones electrónicas <br/> 
Información comercial <br/> 
Datos económicos <br/> 
No se tratan datos especialmente protegidos. <br/> 
Envío de Comunicaciones comerciales:
</p>
 
<p>De conformidad con la LSSSI podrá darse de baja de cualquiera de los servicios de subscripción así como  manifestar su oposición a no recibir informaciones comerciales, enviando la petición a la dirección anteriormente indicada.</p>
 
<p>Medidas de seguridad:</p>
 
<p>FEPSM le informa de que aplica las medidas de seguridad necesarias para evitar el robo, alteración o acceso no autorizado a los datos, teniendo  en cuenta el estado de la técnica, los costes de aplicación, y la naturaleza, el alcance, el contexto y los fines del tratamiento, así como riesgos de probabilidad y gravedad variables para los derechos y libertades de las personas físicas, así como en caso de subcontratación de servicios exigirá y velará por que el  encargado del tratamiento aplique medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado a los riesgos existentes, conforme se recoge en el art. 32 del Reglamento General de Protección de Datos.</p>
 
<p>Política de cookies y del fichero de actividad.</p>
 
<p>En cumplimento del artículo 21 de la Ley 34/2002 de 11 de julio de servicios de sociedad de la información y comercio electrónico (LSSI), le informamos que este sitio web puede utilizar Cookies (pequeños archivos de información que el servidor envía al ordenador de quien accede a la página).</p>
 
<p>Las Cookies que utiliza este sitio web son Cookies técnicas:</p>
 
<p>Éstas Cookies se utilizan para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio web. Son de carácter temporal, y tienen como única finalidad hacer más eficaz la transmisión por una red de comunicaciones electrónicas o, en la medida que resultan estrictamente necesarias, para la prestación de un servicio expresamente solicitado por el usuario, y desaparecen al terminar la sesión. En ningún caso, este tipo de cookies se utilizan para recoger información de carácter personal.</p>
 
<p>Si se desactivan estas cookies, no podrá recibir correctamente nuestros contenidos y servicios.</p>
 
<p>Direcciones IP: Los servidores del sitio web podrán detectar de manera automática la dirección IP y el nombre de dominio utilizados por el usuario. Una dirección IP es un número asignado automáticamente a un ordenador cuando éste se conecta a Internet. Toda esta información es registrada en un fichero de actividad del servidor que permite el posterior procesamiento de los datos con el fin de obtener mediciones únicamente estadísticas que permitan conocer el número de impresiones de páginas, el número de visitas realizadas a los servicios web, el orden de visitas, el punto de acceso, etc.</p>
 
<p>Se puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en el mismo. Para ello, puede visitar la sección de Ayuda de su navegador donde encontrará  información sobre cómo cambiar la configuración del uso de cookies. La opción de bloqueo de Cookies en su navegador puede no permitirte el uso pleno de todas las funcionalidades del Website.</p>
 
<p>Para cambiar la configuración de las cookies, puede acceder a:</p>
 
<p>
Safari: https://www.apple.com/legal/privacy/es/cookies/ <br/> 
Google Chrome: https://support.google.com/chrome/answer/95647?hl=es <br/> 
Mozilla Firefox: https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en- <br/> 
Microsoft Internet Explorer: https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11.
</p>

<p>Derechos de Propiedad Intelectual e Industrial</p>
 
<p>Este Sitio Web se rige por las leyes españolas y se encuentra protegido por la legislación nacional e internacional sobre propiedad intelectual e industrial.</p>
 
<p>La web “congresonacionaldepsiquiatria.es” es titularidad de la Fundación Española de Psiquiatría y Salud Mental (FEPSM)</p>
 
<p>Todos los elementos (marcas, diseños, textos, enlaces, logos, imágenes, vídeos, elementos sonoros, software, bases de datos, códigos...) contenidos en la página de FEPSM congresonacionaldepsiquiatria.es están protegidos por el derecho nacional e internacional de propiedad intelectual. Estos elementos son propiedad exclusiva de FEPSM.</p>
 
<p>Cualquier reproducción, transmisión, adaptación, traducción, modificación, comunicación al público, o cualquier otra explotación de todo o parte del contenido de este Sitio Web, efectuada de cualquier forma o por cualquier medio, electrónico, mecánico u otro, están estrictamente prohibidos salvo autorización previa por escrito de FESPM. Cualquier infracción de estos derechos puede dar lugar a procedimientos extrajudiciales o judiciales civiles o penales que correspondan.</p>
 
<p>FEPSM no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el portal, los servicios o los contenidos del mismo.</p>
 
<p>La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los a los contenidos aportados por los Usuarios es de la exclusiva responsabilidad de los mismo.</p>
 
<p>Queda expresamente prohibida la copia o reproducción de la información publicada mediante cualquier soporte electrónico (páginas Web, bases de datos o publicaciones electrónicas) que permita la redifusión de cualquier información publicada en este sitio Web a múltiples usuarios sin el previo consentimiento por escrito de FEPSM.</p>
 
<p>Jurisdicción</p>
 
<p>FESPM y el usuario, con renuncia expresa a cualquier otro fuero, se someten para cualquier controversia que pudiera derivarse del acceso o uso de la Web, al de los Juzgados y Tribunales de la ciudad de Madrid (España).</p>
 
<p>Aceptación y contacto legal</p>
 
<p>Al navegar por la página web de FEPSM manifiesta que ha leído, comprende, conoce y acepta, sin reserva, todas las condiciones legales de la misma. Para la resolución de cualquier duda sobre el contenido o alcance de la presente política de privacidad póngase en contacto con nosotros en fepem@fepsm.org</p>
`