import React from 'react';
import Session from './Session';
import SessionDetailed from './SessionDetailed';

function RoomDetailed(props) {
    return (
        <div className="room-sessions-wrapper showcase nomargin">
            
            <div className="room-sessions-header-name">
                {props.name}
            </div>

            <div className="room-sessions-area showcase">

                <table border="0" cellSpacing="0" cellPadding="0" className="tbl-room-sessions-panel showcase">
                    <tbody>
                    <Session 
                            time="08:00" 
                            locked={true} 
                            title="LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO" 
                            companyLogo="/img/logo-company-sessions-days.png"
                            noTheme={false} />

                    <SessionDetailed
                            time="09:00" 
                            locked={false} 
                            title="LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO"                        
                            noTheme={false} 
                            withDetail={true} />
                    
                    <tr>
                        <td className="td-time">10:00</td>
                        <td className="td-title">CLINICAL PHENOTYPES AND COMORBIDITY IN SLEEP APNEA</td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time">11:00</td>
                        <td className="td-title">
                            reunión del grupo de investigadores emergentes del área tir 
                            <img alt="" src="/img/icon-show-detail.png" />
                        </td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time">12:00</td>
                        <td className="td-title">assemblea general de socios 
                            <img alt="" src="/img/icon-hide-detail.png" />
                            <br />
                            <div className="session-detail">
                                <span className="coauthors-opened-detail">B Basso Abad // J Gonzalez de Buitrago Amigo
                                C Surribas Murillo // M Sánchez Conejero
                                M Gutierrez Agujetas // MC Cabello Anoya</span>
                            </div>


                        </td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time">13:30</td>
                        <td className="td-title">entrega de becas y premios</td>
                        <td className="td-icon"><img alt="" src="/img/icon-lock.png" /></td>
                    </tr>

                    <tr>
                        <td className="td-time">15:00</td>
                        <td className="td-title">enfermidades pulmonares and comorbidity in sleep apnea</td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time">15:00</td>
                        <td className="td-title">enfermidades pulmonares and comorbidity in sleep apnea</td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time">15:00</td>
                        <td className="td-title">enfermidades pulmonares and comorbidity in sleep apnea</td>
                        <td className="td-icon">&nbsp</td>
                    </tr>

                    <tr>
                        <td className="td-time noborder">17:00</td>
                        <td className="td-title noborder">CLINICAL PHENOTYPES AND COMORBIDITY IN SLEEP APNEA</td>
                        <td className="td-icon noborder">&nbsp</td>
                    </tr>
                    
                    </tbody>
                </table>

            </div>


            <div className="slide-bar-opened-room"></div>
            <div className="slide-bar-btn-opened-room">
                <div className="slide-bar-btn-opened-room-container"></div>
            </div>

        </div>
    );
}

export default RoomDetailed;