import React from 'react';
import { useTranslation } from 'react-i18next';

function SpeakersCV(props) {

    const { t, i18n } = useTranslation();

    return (

        props.event_id === "3" ?

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container">






                    {props.speaker_cv == "Carmen-Fernandez-Capitan" ?
                        <div className="speakers-cv-tromboencuentros2021">
                            <h3><strong>Dra. Carmen Fernández Capitán</strong><br />Servicio de Medicina Interna. Hospital Universitario La Paz, Madrid.</h3>

                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Carmen-Fernandez-Capitan.jpg" /><br />

                            <p>Licenciada en Medicina y Cirugía por la Facultad de Medicina de la Universidad Complutense de Madrid, y especialista en Medicina Interna y doctora por la Universidad Autónoma de Madrid (UAM).</p>

                            <p>Desde el año 1981, ejerce su acti¬vidad profesional asistencial en el Servicio de Medicina Interna del Hospital Universitario La Paz de Madrid, siendo desde 2004 jefe de sección. Además, es coordinadora de la Unidad de Enfermedad Tromboembólica Venosa, coordinadora de Calidad y coordinadora de Consultas Externas del Servicio de Medicina Interna, y, desde 2016, es presidenta de la Comisión de Trombosis y Hemostasia del Hospital Universitario La Paz de Madrid.</p>

                            <p>En cuanto a su labor docente, ha ejercido como profesora honoraria y clínico colaborador docente de la Facultad de Medicina de la UAM y, desde 2009, es profesora asociada en el Departamento de Medicina de la UAM. Ha impartido docencia en varios cursos y másteres acreditados por la Universidad Autónoma de Barcelona, la Universidad Católica San Antonio de Murcia y la Universidad de Alcalá de Henares (UAH). </p>

                            <p>La actividad investigadora la ha desarrollado principalmente en el campo de la medicina interna y enfermedad tromboembólica venosa (ETEV), donde ha dirigido varias tesis doctorales, trabajos fin de Grado de Medicina y ha participado en numerosos estudios de investigación y ensayos clínicos. Ha sido autora o ha contribuido en más de 100 documentos científicos nacionales e internacionales y en capítulos de libros. Cuenta con más de 300 carteles, presentaciones orales o ponencias en congresos nacionales e internacionales de prestigio. </p>

                            <p>Además, es miembro de la Sociedad Española de Medicina Interna (SEMI) desde 1985 y, desde 2001, del grupo de trabajo de Enfermedad Tromboembólica de la SEMI. Es miembro del Registro Informatizado de Enfermedad Tromboembólica (RIETE) desde 2001 y del Grupo Multidisciplinar de Hipertensión Pulmonar del Hospital Universitario La Paz de Madrid desde 2006.</p>

                        </div>

                        : props.speaker_cv == "Vladimir-Salazar-Rosa" ?

                            <div className="speakers-cv-tromboencuentros2021">

                                <h3><strong>Dr. Vladimir Salazar Rosa</strong><br />Servicio de Medicina Interna. Hospital Universitario Virgen de la Arrixaca, Murcia.</h3>

                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Vladimir-Salazar-Rosa.jpg" /><br />


                                <p>Licenciado y doctor en Medicina por la Universidad de Murcia, especialista en Medicina Interna.</p>
                                <p>Ejerce su actividad profesional asistencial en el Servicio de Medicina Interna del Hospital Clínico Universitario Virgen de la Arrixaca de Murcia siendo el creador  y responsable de la Unidad de Enfermedad Tromboembólica Venosa, acreditada por la SEMI en nivel avanzado desde 2019.</p>
                                <p>Es miembro activo del registro RIETE y del Grupo de Tromboembolismo de la Sociedad Española de Medicina Interna (SEMI). La actividad investigadora la ha desarrollado principalmente en el campo de la enfermedad tromboembólica venosa y la anticoagulación, habiendo dirigido varias tesis doctorales y formado parte de tribunales de valoración de tesis en este campo.</p>
                                <p>Ha publicado más de 50 artículos indexados en PubMed, capítulos de libros, monografías, comunicaciones y ponencias invitadas a congresos y reuniones.</p>
                                <p>Forma parte del personal docente e investigador de la Universidad de Murcia y es profesor asociado en el grado de Medicina, así como en algunos Másteres  Universitarios  relacionados  con  el  ámbito  de  la  trombosis  y  la  anticoagulación.</p>
                                <p>Acreditado por la ANECAcomo profesor contratado doctor, profesor ayudante doctor y profesor universidad  privada  en  junio  2019.</p>
                                <p>Actualmente  es  vocal  de  la  Junta  Directiva  de  la  Sociedad Murciana de Medicina Interna (SOMIMUR).</p>




                            </div>


                            : props.speaker_cv == "Rocio-Velasco-Guzman-Lazaro" ?

                                <div className="speakers-cv-tromboencuentros2021">

                                    <h3><strong>Dra. Rocío Velasco Guzmán de Lázaro</strong><br />Servicio de Geriatría. Hospital de Cantoblanco - La Paz, Madrid.</h3>

                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Rocio-Velasco-Guzman-Lazaro.jpg" /><br />



                                    <p>Licenciada en Medicina y Cirugía por la Universidad Complutense de Madrid.</p>
                                    <p>Residencia en Geriatría en el Hospital Universitario de Getafe (Madrid).</p>
                                    <p>Especialista del Servicio de Geriatría del Hospital Universitario La Paz desde Abril 2013. (OPE 2009).</p>
                                    <p>Master en Administración y Dirección de Servicios Sanitarios (MADS) Instituto de Formación Continuada (IDEC). Universidad Pompeu y Fabra.</p>
                                    <p>Profesor asociado Universidad Europea de Madrid. Gestión Sanitaria y Salud Pública, 3º curso Grado de Medicina 2013.</p>
                                    <p>Colaborador clínico docente de la Universidad Autónoma de Madrid.</p>
                                    <p>Investigador/Colaborador en el estudio multicéntrico: Registro Nacional de Fracturas de Cadera durante los años 2016-2017.</p>
                                    <p>Subinvestigador en estudios clínicos controlados fase II, y III fundamental en Enfermedad de Alzheimer.</p>
                                    <p>Autor y coautor de publicaciones indexadas fundamentalmente en Paciente anciano con fractura de cadera.</p>
                                    <p>Premio a la mejor sesión clínica Hospital La Paz 2017: “ Envejecimiento y fragilidad”.</p>
                                    <p>Medalla de Bronce al Servicio de Ortogeriatría de La Paz. FSL. Fundación Internacional de Osteoporosis “Capture the Fracture”.</p>
                                    <p>Miembro de la Junta Directiva de la Asociación Española de Medicina Geriátrica.</p>



                                </div>

                                : props.speaker_cv == "Juan-Jose-Lopez-Nunez" ?

                                    <div className="speakers-cv-tromboencuentros2021">

                                        <h3><strong>Dr. Juan José López Núñez</strong><br />Servicio de Medicina Interna. Hospital Germans Trias i Pujol, Barcelona.</h3>

                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Juan-Jose-Lopez-Nunez.jpg" /><br />

                                        <p>Graduado en Medicina y Cirugía por la Universitat Autònoma de Barcelona (UAB), donde posteriormente continuó su formación académica con un Máster en Investigación Clínica y un Máster en Tratamiento y Patogénesis del SIDA. Además, ha realizado un Curso Extensión Universitaria en Ecografía Clínica en la Universitat de Barcelona y un Máster en Tratamiento Anticoagulante en la Universidad Católica San Antonio de Murcia.</p>

                                        <p>Desde el año 2010, ejerce su acti¬vidad profesional asistencial en el Servicio de Medicina Interna del Hospital Universitari Germans Trias i Pujol de Badalona (Barcelona), siendo desde 2015 médico adjunto en dicho servicio, y, desde 2018, forma parte del Comité de Enfermedad Tromboembólica Venosa del Hospital Universitari Germans Trias i Pujol de Badalona (Barcelona). Además, se encuentra realizando la tesis doctoral en la UAB, bajo la dirección del Dr. Manuel Monreal Bosch.</p>

                                        <p>En 2014, realizó su primera estancia en el prestigioso Brighan and Women's Hospital, Harvard Medical School (Boston, USA). En 2018, realizó su segunda estancia en el Hospital Infanta Cristina de Parla (Madrid). Y, finalmente, su última estancia fue realizada en el 2019 en el Servicio de Neumología del Hospital Universitario Ramón y Cajal de Madrid.</p>

                                        <p>En cuanto a su actividad investigadora, ha participado en 4 proyectos de investigación, 2 de ellos llevados a cabo en la Fundación para la Investigacion Biomédica del Hospital Carlos III de Madrid. Cuenta con 26 artículos científicos publicados y desarrollados principalmente en el campo de la enfermedad tromboembólica venosa (ETEV). Además, cuenta con varios trabajos presentados en congresos nacionales o internacionales.</p>

                                    </div>




                                    : props.speaker_cv == "Leyre-Diez" ?

                                        <div className="speakers-cv-tromboencuentros2021">

                                            <h3><strong>Dra. Leyre Díez</strong><br />Unidad de Cuidados Paliativos. Hospital La Paz. Madrid.</h3>

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Leyre-Diez.jpg" /><br />


                                            <p>Dra. Leyre Díez Porres. Especialista en Medicina Interna. Doctora en Medicina por la Universidad Autónoma de Madrid (Tesis doctoral en 2013: “Efectividad de un equipo de Cuidados Paliativos en los últimos días de vida”). Máster en Medicina Paliativa y tratamiento de soporte. Médico Adjunto y coordinadora de Calidad Asistencial de la Unidad de Cuidados Paliativos del Hospital Universitario La Paz (Madrid) desde 2008. Docente del Máster de Cuidados Paliativos y Tratamiento de Soporte de la Universidad Autónoma de Madrid-Hospital Universitario La Paz.</p>

                                        </div>

                                        : props.speaker_cv == "Carmen-Beato" ?

                                            <div className="speakers-cv-tromboencuentros2021">

                                                <h3><strong>Dra. Carmen Beato</strong><br />Servicio Oncología Médica del Hospital Virgen Macarena de Sevilla</h3>

                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Carmen-Beato.jpg" /><br />


                                                <p>Mi actividad fundamental es la asistencial. Es la que da sentido a mi trabajo y a la que dedico
                                                    mi mayor esfuerzo. Mi actividad en el contexto de un Hospital Universitario me da también
                                                    la oportunidad de dedicar una parte de mi tiempo a la docencia. Lo hago como tutora de
                                                    residentes MIR de Oncología Médica y como profesora colaboradora. La investigación clínica
                                                    representa la diversión, la oportunidad de desarrollar trabajo junto a compañeros y amigos
                                                    y generar nuevo conocimiento. Mi actividad más reciente está en relación al cuidado de
                                                    soporte en mi especialidad y sobre todo se centra en la enfermedad tromboembólica asociada
                                                    al cáncer, los aspectos psico-sociales de la enfermedad y la relación con especialidades
                                                    complementarias. Mi actividad en Grupos Cooperativos tiene lugar en la Sociedad Española
                                                    de Oncología Médica (Sección de Cuidados Continuos, de la que soy vocal), en el Grupo
                                                    Español de Cáncer de Origen Desconocido (Secretaria) y en el Grupo Español de Tumores
                                                    Huérfanos e Infrecuentes (vocal).</p>

                                            </div>


                                            : props.speaker_cv == "Maite-Antonio" ?

                                                <div className="speakers-cv-tromboencuentros2021">

                                                    <h3><strong>Dra. Maite Antonio</strong><br />Unidad de Oncohematogeriatría del Instituto Oncológico Catalán de Barcelona</h3>



                                                    <p>La Dra. Maite Antonio es doctora en medicina y cirugía por la Universidad de Barcelona, y especialista en Medicina Interna . Desde el año 2008 trabaja en el Insitiut Catalá d’Oncología donde ha compaginado lla actividad asistencial con la docència y la investigación. En los últimos años sus principales áreas de interés han sido el manejo de las complicacions tromboembólicas en el paciente oncológico y la atención al paciente de edad avanzada con cáncer. Actualmente es la coordinadora del Plan de Atención Integral al paciente de edad avanzada del Institut Català d’Oncologia.</p>

                                                </div>


                                                : props.speaker_cv == "Jose-Maria-Pedrajas" ?

                                                    <div className="speakers-cv-tromboencuentros2021">

                                                        <h3><strong>Dr. José María Pedrajas</strong><br />Servicio de Medicina Interna del Hospital Clínico de Madrid.</h3>

                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Jose-Maria-Pedrajas.jpg" /><br />


                                                        <p><strong>Formación:</strong></p>
                                                        <p>Licenciado en Medicina y Cirugía en el año 1987 por la Universidad Complutense de Madrid, mediante la modalidad de TESINA con calificación de SOBRESALIENTE.</p>
                                                        <p>Completado el programa de formación en la especialidad de Medicina Interna mediante el sistema M.I.R. en el Servicio de Medicina Interna del Hospital Universitario San Carlos, desde el año 1.998 al año 1.992.</p>
                                                        <p>Grado de Doctor en enero de 1996, con calificación de APTO CUM LAUDE por unanimidad, en la Universidad Complutense de Madrid.</p>
                                                        <p>Programa ICEPSS. Cuidados paliativos: Atención integral a enfermos terminales. Año 2000.</p>
                                                        <p>Máster en tratamiento anticoagulante impartido por la Universidad Católica de Murcia en 2016.</p>


                                                        <p><strong>Experiencia profesional:</strong></p>

                                                        <p>Desde el año 1993 he desempeñado sobre todo actividad de Medicina Interna en diferentes ámbitos: urgencias, hospitalización y consulta externa.</p>
                                                        <p>Responsable desde 1998 de la consulta monográfica de Enfermedad Tromboembólica venosa. Miembro activo del grupo RIETE (Registro Informatizado de Enfermedad Tromboembólica) desde 2005. </p>
                                                        <p>Formación en Cuidados Paliativos, con trabajo a tiempo completo en una Unidad de Cuidados Paliativos en el Hospital San Rafael de Madrid, en un equipo multidisciplinario. Trabajo muy frecuente con enfermos con dolor crónico en actividad ambulatoria hasta la actualidad. </p>
                                                        <p>Puesta en marcha y responsable funcional de la Unidad de Hospitalización a Domicilio desde 1997 a 2003, con un gran porcentaje de asistencia al manejo del manejo de dolor crónico y paliativo y otros síntomas prevalentes en pacientes en situación paliativa, pacientes crónicos y ancianos con grandes cargas de comorbilidad y numerosa medicación pautada y manejo de otros síntomas en el domicilio de los pacientes. Manejo de la TVP en ámbito ambulatorio sin ingreso hospitalario.</p>
                                                        <p>Miembro de la Unidad de Coordinación de Atención primaria (UCAP), del HCSC desde su creación en 2006 hasta la actualidad, especialista consultor en medicina interna de los centros de Salud nuestro ámbito de actuación.</p>

                                                        <p><strong>Investigación y Docencia:</strong></p>

                                                        <p>Publicaciones en revistas nacionales e internacionales, sobre todo en el ámbito de la enfermedad tromboembólica venosa. Asistencia a congresos nacionales e internacionales de Medicina Interna con decenas de comunicaciones.</p>
                                                        <p>Ponencias en los foros de enfermedad tromboémbolica venosa, director de 3 tesis doctorales en relación con la ETV. Comunicaciones y publicaciones nacionales e internacionales en este ámbito.</p>
                                                        <p>Participación en diversos ensayos clínicos en el ámbito de la ETV, entre otros:</p>
                                                        <p>-estudio AMPLIFY-EXT.</p>
                                                        <p>-estudio Xalia. Xarelto para la anticoagulación a largo plazo e inicial en el tromboembolismo venoso (TEV). </p>
                                                        <p>-estudio MARINER. Evaluación, en pacientes con procesos médicos, de rivaroxaban frente a placebo en la reducción del riesgo de tromboembolismo venoso después del alta hospitalaria (MARINER). </p>
                                                        <p>- Estudio multicéntrico, aleatorizado, con control activo de eficacia y seguridad que compara betrixaban en tratamiento prolongado con enoxaparina como tratamiento habitual para la prevención del tromboembolismo venoso en pacientes con enfermedad medicamente aguda.</p>
                                                        <p>-estudio IPEP: Tratamiento basado en la estratificación pronóstica vs tratamiento habitual en pacientes normotensos con tromboembolia de pulmón: ensayo clínico aleatorizado multicéntrico (IPEP). </p>
                                                        <p>-estudio SLICE: Ensayo clínico aleatorizado para evaluar la eficacia y seguridad clínicas de la búsqueda y tratamiento activos de la tromboembolia de pulmón (TEP) en pacientes hospitalizados por agudización de EPOC de causa desconocida (Estudio SLICE.</p>
                                                        <p>- Estudio de factores pronósticos del síndrome postrombótico (SPT) en España en pacientes con trombosis venosa profunda.</p>
                                                        <p>- A phase 3b, prospective, randomized, open-label, blind evaluator (PROBE) study evaluating the efficacy and safety of (LMW) heparin/edoxaban versus dalteparin in venous thromboembolism associated with cancer.</p>
                                                        <p>- Prevención de episodios de tromboembolia. Registro europeo sobre tromboembolia venosa. Estudio PREFER en TEV. </p>

                                                        <p>Profesor asociado de la Universidad Complutense de Madrid desde 2005, manteniendo docencia a los alumnos de patología general y patología médica. </p>
                                                        <p>Tutor de Médicos-Residentes hasta diciembre de 2020.</p>


                                                    </div>



                                                    : props.speaker_cv == "Begona-Fernandez-Jimenez" ?

                                                        <div className="speakers-cv-tromboencuentros2021">

                                                            <h3><strong>Dra. Begoña Fernandez Jimenez</strong><br />Servicio de Hematología. Hospital Universitario de Móstoles, Madrid</h3>

                                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Begona-Fernandez-Jimenez.jpg" /><br />


                                                            <p>Hematóloga del Hospital Universitario de Móstoles. Responsable de la Unidad de trombosis. Miembro del Grupo de Trombosis de la AMHH (Asociación Madrileña de Hematología y Hemoterapia).</p>






                                                        </div>



                                                        : props.speaker_cv == "Elisa-Llurba-Olive" ?

                                                            <div className="speakers-cv-tromboencuentros2021">

                                                                <h3><strong>Dra. Elisa Llurba Olivé</strong><br />Servicio de Ginecología y Obstetricia. Hospital de la Santa Creu i Sant Pau, Barcelona.</h3>

                                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Elisa-Llurba-Olive.jpg" /><br />


                                                                <p>Elisa Llurba es la <strong>Directora del Servicio de Obstetricia y Ginecología</strong> del Hospital Universitario de la Santa Creu i Sant Pau, profesora asociada de la Facultad de Medicina de la UAB desde 2013 y responsable de grupo de investigación clínica y básica del Instituto de investigación llamado Grupo de iIvestigación en Medicina Perinatal y de la Mujer, del Instituto de Investigación del Hospital de Santa Creu i de Sant Pau de Barcelona.</p>

                                                                <p>La Dra Llurba es la IP de grupo en Sant Pau en el marco de la RETICs 16/0022 titulada RED MATERNO INFANTIL Y DEL DESARROLLO (SAMID) (Instituto de SaludCarlos III dentro de las REDES TEMÁTICAS DE INVESTIGACIÓN COOPERATIVA (RETICS). Dentro de la RETICs, la Dra Llurba es la coordinadora de uno de los dos programas concedidos en la pasada convocatoria de las RETICs. Las principales líneas de investigación son:</p>

                                                                <ol>
                                                                    <li>Predicción y diagnóstico de preclampsia.</li>
                                                                    <li>Utilidad de los factores angiogénicas en las complicaciones placentarias</li>
                                                                    <li>Restricción de crecimiento y programación cardiovascular</li>
                                                                    <li>Preeclampsia y programación cardiovascular materna</li>
                                                                    <li>Ambiente y gestación</li>
                                                                </ol>

                                                                <p>Fruto de la investigación realizada durante los últimos 15 años tanto a nivel experimental como clínico son 71 publicaciones en revistas internacionales, con 2140 citaciones (h-index=21; RG score: 34.46), estando más del 66% de los artículos en revistas del primer cuartil.</p>

                                                            </div>



                                                            : props.speaker_cv == "Raquel-Lopez-Reyes" ?

                                                                <div className="speakers-cv-tromboencuentros2021">

                                                                    <h3><strong>Dra. Raquel López Reyes</strong><br />Servicio de Neumología. Hospital Universitario y Politécnico de La Fe, Valencia.</h3>

                                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Raquel-Lopez-Reyes.jpg" /><br />

                                                                    <p>La Dra. Raquel López Reyes es Licenciada y doctorada por la Universidad de Valencia, Médico especialista en neumología y facultativo especialista en Hospital Universitari i Politècnic La Fe desde 2014. </p>

                                                                    <p>La Dra. es miembro activo de registro RIETE y REHAP. También es coordinadora del grupo de circulación pulmonar de la Sociedad Valenciana de Neumología. Cuenta con gran actividad investigadora con publicaciones en revistas nacionales, internacionales, normativas y guías de práctica clínica y participación en ensayos clínicos. En elaboración del protocolo de diagnostico y tratamiento de embolia pulmonar aguda y del protocolo de diagnostico y tratamiento de la Hipertensión Pulmonar en el Hospital Universitario La Fe.</p>





                                                                </div>


                                                                : props.speaker_cv == "Rodrigo-Rial-Horcajo" ?

                                                                    <div className="speakers-cv-tromboencuentros2021">

                                                                        <h3><strong>Dr. Rodrigo Rial Horcajo</strong><br />Presidente del CEFyL. Servicio Angiología y Cirugía Vascular del Hospital HM Torrelodones.</h3>

                                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Rodrigo-Rial-Horcajo.jpg" /><br />


                                                                        <p><strong>EXPERIENCIA PROFESIONAL y TITULACIONES</strong><br /><br />

                                                                            Jefe Clínico de Cirugía Vascular y Endovascular del Hospital Universitario HM Torrelodones y HM Madrid.<br />
                                                                            Plaza estatutaria de Médico Especialista del Servicio de Angiología y Cirugía Vascular del Hospital Clínico San Carlos de Madrid. 1996- marzo 2020. Nivel IV de Carrera Profesional. Excedencia voluntaria.<br />
                                                                            Presidente del Capítulo Español de Flebología y Linfología de la SEACV. Miembro del Comité Científico (2013-2017). Vicepresidente (2015-2019).<br />
                                                                            Miembro de la Junta Directiva del Capítulo de Cirugía Endovascular de la SEACV. Miembro de la Junta Directiva de la Sociedad Española de Angiología y Cirugía Vascular (SEACV)<br />
                                                                            International Atomic Energy Agency (IAEA) Member. Radiation Protection Expert-Lecturer in RPoP. 2012-Presente. Director de Instalaciones de Rayos X con fines de Diagnóstico Médico. 5 julio de 2001. Acreditación de Segundo Nivel en Protección Radiológica en Práctica Intervencionista. 2009.<br />
                                                                            Especialista en Angiología y Cirugía Vascular 1994. Vía MIR. Hospital Clínico Universitario san Carlos. Madrid.<br />
                                                                            Título de Habilitación para ejercer en los Sistemas Públicos de los Estados de la Comunidad Europea. 1994.<br />
                                                                            En posesión del examen Educational Comission Foreign Medical Graduate Examination, superado en la convocatoria de Julio de 1988.
                                                                        </p>


                                                                        <p><strong>DOCENCIA</strong><br /><br />

                                                                            Profesor Docencia Práctica Universidad San Pablo CEU. Madrid. 2020-actual<br />
                                                                            Profesor Departamento de Cirugía de la Facultad de Medicina. Universidad Complutense. 1997-2020.<br />
                                                                            Tutor Curso Anual “Actualización en Patología Venosa. Academia de la vena” de la Sociedad Española de Angiología y Cirugía vascular (SEACV). 2015- Actualidad<br />
                                                                            Tutor Curso Anual “Cómo Escribir y Publicar un artículo Científico” de la SEACV. 2013-2015<br />
                                                                            Tutor Curso Anual “Metodología Científica en la Práctica Clínica” de la SEACV y del Capítulo Español de Flebología de la SEACV. 2013-2017<br />
                                                                            Co-Director y Profesor del Máster en Flebología y alteraciones estéticas de la extremidad inferior. Universidad Europea de Madrid. En curso.<br />
                                                                            Profesor del Máster en Técnicas de embolización. Universidad Francisco de Vitoria. En curso.
                                                                        </p>


                                                                        <p><strong>PUBLICACIONES Y CONFERENCIAS</strong><br /><br />

                                                                            Artículos originales y capítulos de libros: 69<br />
                                                                            Presentaciones Orales a Congresos Internacionales: 49<br />
                                                                            Presentaciones Orales Conferencias y Mesas Redondas Nacionales: 108. Tres Premios a la Mejor comunicación.
                                                                        </p>




                                                                    </div>



                                                                    : props.speaker_cv == "Beatriz-Pombo-Vide" ?

                                                                        <div className="speakers-cv-tromboencuentros2021">

                                                                            <h3><strong>Dra. Beatriz Pombo Vide</strong><br />Servicio de Medicina Interna del Hospital Universitario Lucus Augusti. Lugo.</h3>

                                                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Beatriz-Pombo-Vide.jpg" /><br />


                                                                            <p><strong>EXPERIENCIA PROFESIONAL Y TITULACIONES</strong></p>

                                                                            <p>

                                                                                Jefa de sección Hospital Lucus Augusti Servicio Medicina Interna<br />
                                                                                Coordinadora de la Unidad de tromboembolismo de Medicina Interna de Hospital Lucus Augusti y miembro del grupo gallego de ETEV<br />
                                                                                Licenciada en Medicina y Cirugía General<br />
                                                                                Máster en enfermedad tromboembólica. Universidad de Navarra <br />


                                                                            </p>


                                                                            <p><strong>DOCENCIA</strong></p>

                                                                            <p>

                                                                                Tutora de residentes de Medicina Interna en Hospital Lucus aAugusti. 2014-2018.


                                                                            </p>




                                                                        </div>



                                                                        : props.speaker_cv == "Edelmira-Marti-Saez" ?

                                                                            <div className="speakers-cv-tromboencuentros2021">

                                                                                <h3><strong>Dra. Edelmira Martí Saez</strong><br />Servicio de Hematología y Hemoterapia del Hospital Clínico Universitario de Valencia.</h3>

                                                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Edelmira-Marti-Saez.jpg" /><br />


                                                                                <p><strong>FORMACIÓN:</strong></p>

                                                                                <p>

                                                                                    Licenciada en Medicina por la Universidad de Valencia. Doctora en Medicina por la Universidad Autónoma de Barcelona. Máster en Tratamiento Anticoagulante por la Universidad Católica San Antonio de Murcia. Formación MIR en Hematología y Hemoterapia en Hospital de la Santa Creu i Sant Pau de Barcelona.


                                                                                </p>


                                                                                <p><strong>EXPERIENCIA PROFESIONAL: </strong></p>

                                                                                <p>

                                                                                    Desde febrero de 2016 facultativa especialista en Hematología en la Unidad de Hemostasia y Terapia Anticoagulante del Hospital Clínico Universitario de Valencia. Desde enero de 2020 responsable de dicha sección. <br />
                                                                                    Facultativa especialista en Hematología en Hospital de Manises de 2009 a 2016.<br />
                                                                                    Facultativa especialista en Hematología en la Unidad de Hemostasia del Hospital de la Santa Creu i Sant Pau de 2007 a 2009.



                                                                                </p>



                                                                                <p><strong>INVESTIGACIÓN:</strong></p>

                                                                                <p>

                                                                                    Autora y coautora de diversas publicaciones en el ámbito de la Hemostasia y Terapia Anticoagulante. <br />
                                                                                    Participación en diversos proyectos de investigación como investigadora principal y colaboradora.




                                                                                </p>




                                                                            </div>




                                                                            : props.speaker_cv == "Ignacio-Lojo-Rocamonde" ?

                                                                                <div className="speakers-cv-tromboencuentros2021">

                                                                                    <h3><strong>Dr. Ignacio Lojo Rocamonde</strong><br />Servicio de Angiología y Cirugía Vascular del Hospital Quirón de A Coruña.</h3>

                                                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Ignacio-Lojo-Rocamonde.jpg" /><br />


                                                                                    <p><strong>EXPERIENCIA PROFESIONAL Y TITULACIONES</strong></p>

                                                                                    <p>

                                                                                        Jefe de Servicio de Angiología, Cirugía Vascular y Endovascular. Hospital Quirón – Coruña<br />
                                                                                        Director de laboratorio vascular acreditado por el capítulo de diagnóstico no invasivo de la Sociedad Española. 2003.<br />
                                                                                        Monitor para procedimientos endovasculares en las siguientes áreas de la especialidad: procedimientos de embolización periférica (Medtronic), Procedimientos endovasculares periféricos (Disgamedica, Abbott, Balt y Terumo) <br />
                                                                                        Miembro del comité científico de la revista Journal of Cardio-Thoracic and Vascular Surgery<br />
                                                                                        Vocal del Capítulo Endovascular de la Sociedad Española de Angiología y Cirugía Vascular<br />
                                                                                        Presidente Sociedad Norte Angiología y Cirugía Vascular<br />
                                                                                        Presidente de la Sociedad Gallega de Angiología y Cirugía Vascular<br />
                                                                                        Vocal de docencia y formación médica continuada de Sociedad Española de Angiología y Cirugía Vascular<br />
                                                                                        Miembro de comité científico del capítulo endovascular de la Sociedad Española de Angiología y Cirugía Vascular (2007-2011)<br />
                                                                                        Miembro de la comisión asesora de la revista Angiología<br />
                                                                                        Vicepresidente 2º de la Sociedad Española de Angiología y Cirugía Vascular (SEACV)<br />
                                                                                        Codirector del campus virtual de la SEACV<br />
                                                                                        Miembro del comité de redacción (Revisor) de la Revista Angiología<br />
                                                                                        Advisory board de GRUPO SANOFI-ADVENTIS<br />
                                                                                        Adjunto Clínico de Angiología y Cirugía Vascular en el Complejo Hospitalario Universitario de Lugo: enero 1994 – marzo 1998. <br />
                                                                                        Adjunto Clínico especialista en Angiología y Cirugía Vascular en el Complejo Universitario de marzo Ourense. 1998.<br />
                                                                                        Jefe de Sección en Angiología y Cirugía Vascular en el Complejo Hospitalario Universitario de Ourense. 1998-2001.<br />
                                                                                        Jefe de Servicio de Angiología, Cirugía Vascular y Endovascular del Hospital USP-Santa Teresa de A Coruña. 2001.<br />
                                                                                        Licenciado en Medicina y Cirugía por la Universidad de Santiago de Compostela<br />
                                                                                        Formado por el Sistema MIR en el Complejo Hospitalario Universitario de Santiago de Compostela (CHUS) 1988-1993.



                                                                                    </p>


                                                                                    <p><strong>DOCENCIA</strong></p>

                                                                                    <p>

                                                                                        Director del primer Máster en Angiología y Cirugía Vascular Técnicas y Procedimientos en embolización. 2020-2021<br />
                                                                                        Profesor del Máster en Flebología Clínica y abordaje de las alteraciones estéticas del miembro inferior. 2020-2021. <br />
                                                                                        Organizador Capítulo de Cirugía Endovascular de la SEACV. Universidad Francisco Vitoria. Madrid.<br />
                                                                                        Profesor curso posgrado Experto en Terapéutica Endovascular de Síndromes Compresivos venosos. Universidad Alfonso X el Sabio, Madrid. 2021.



                                                                                    </p>



                                                                                    <p><strong>PUBLICACIONES Y CONFERENCIAS</strong></p>

                                                                                    <p>

                                                                                        Publicaciones: Revistas nacionales: 20; revistas internacionales: 6<br />
                                                                                        Organización Reuniones Científicas: 19<br />
                                                                                        Comunicaciones a congresos nacionales: 76; internacionales: 6<br />
                                                                                        Premio Martorell año 2011 al mejor trabajo presentado en el 57 Congreso Nacional de la SEACV<br />
                                                                                        Premio a la mejor comunicación de la XI Reuniao Galaico Duriense. Oporto. 2012.


                                                                                    </p>




                                                                                </div>




                                                                                : props.speaker_cv == "Enrique-Gallardo" ?

                                                                                    <div className="speakers-cv-tromboencuentros2021">

                                                                                        <h3><strong>Dr. Enrique Gallardo</strong><br />Servicio de Oncología Médica. Consorci Corporació Sanitària Parc Taulí.</h3>

                                                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Enrique-Gallardo.jpg" /><br />


                                                                                        <p>Trayectoria profesional.<br />
                                                                                            Especialista en Oncología Médica. Médico Adjunto del Servicio de Oncología del Hospital Universitari Vall d’Hebron de Barcelona en 1996 y coordinador de Ensayos Clínicos en el mismo Servicio entre 1996 y 1999. Médico Adjunto del Servicio de Oncología del Consorci Sanitari de Terrassa (Barcelona) hasta 2002. Desde 2002, Médico Adjunto del Servicio de Oncología en el Hospital Universitari Parc Taulí de Sabadell (Universidad Autónoma de Barcelona-UAB). En la actualidad, Médico Consultor en dicho Servicio. Co-responsable de la Unidad de Uro-Oncología. Miembro de los Comités Multidisciplinares de Tumores Genitourinarios, del Sistema Nervioso Central y Tiroides. Tutor de especialistas en formación de Oncología Médica en 2012-2016 y 2020. Coordinador del Grupo de Investigación en Cáncer del Institut d’Investigació i Innovació Parc Taulí-I3PT desde 2016. Miembro de los Comités Institucionales de Investigación e Innovación y de Mejoría de la Práctica Clínica.</p>

                                                                                        <p>Trayectoria académica<br />
                                                                                            Licenciado en Medicina y Cirugía por la Universidad de Sevilla en 1990. Residencia en Oncología Médica en el Hospital Universitari Vall d’Hebron entre 1992 y 1995. European Certification in Medical Oncology en 2008. Diplomatura en Gestión Clínica en Oncología Médica por la Fundación Gaspar Casal/Universitat Pompeu Fabra/Sociedad Española de Oncología Médica en 2014-2015. Programa de Organización Estratégica en Sociedades Médico-Científicas, ESADE, en 2015-2016. Diplomatura en Evaluación de Tecnologías Sanitarias por la Fundación Gaspar Casal/Universitat Pompeu Fabra/Sociedad Española de Oncología Médica en 2019-2020.</p>

                                                                                        <p>Actividades y participación en sociedades científicas<br />
                                                                                            Autor y co-autor de diversos artículos en revistas indexadas y comunicaciones en congresos nacionales e internacionales. Experiencia como investigador en una gran cantidad de ensayos clínicos en diversas áreas de la oncología, especialmente en tumores urológicos, incluyendo estudios en fase I, II, III y IV. Participación en el desarrollo de la Oncoguía de Cáncer de Próstata en Cataluña y en las guías de Cáncer de Próstata, Cáncer Renal y Cáncer de Vejiga de la SEOM (en colaboración con SOGUG) y de Cáncer de Tiroides y Tromboembolismo Venos y Cáncer de la SEOM. Miembro activo de sociedades y grupos cooperativos SEOM, ESMO, ASCO, SOGUG, GEINO, GETHI y EORTC. Vocal de la Junta Directiva de SOGUG entre 2005 y 2009; secretario entre 2015 y 2018. En la actualidad, vicepresidente de la Junta Directiva de SOGUG desde 2018. Vocal de la Sección de Trombosis y Cáncer de la SEOM.
                                                                                        </p>



                                                                                    </div>


                                                                                    : props.speaker_cv == "Virginia-Martinez" ?

                                                                                        <div className="speakers-cv-tromboencuentros2021">

                                                                                            <h3><strong>Dra. Virginia Martínez</strong><br />Servicio de Oncología Médica del Hospital. Universitario de La Paz.</h3>

                                                                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Virginia-Martinez.jpg" /><br />


                                                                                            <p>"Nacida en Alguazas (Murcia) el 29 de Abril de 1079. Licenciada en Medicina y Cirugía por la Universidad de Murcia (Octubre 1997 – Junio 2003). Especialista en Oncología Médica tras realizar la Residencia de Oncología Médica en el Hospital General Universitario Gregorio Marañón (2 de junio 2004-2 junio 2008). Durante estos años de residente desarrolló su actividad asistencial con pacientes con tumores sólidos y hematológicos, formándose no sólo en el manejo terapéutico e investigador, sino también en el cuidado de síntomas del paciente oncológico a través de una amplia rotación en el Sº de cuidados paliativos del mismo hospital y en urgencias oncológicas a través de guardias de especialidad propia.</p>
                                                                                            <p>Con el fin de ampliar sus conocimientos asistenciales y de investigación, realizó una rotación externa en el Melanoma and Sarcoma Service y en Gastrointestinal Service dentro del Memorial Sloan Kettering Cancer Center de Nueva York, bajo la supervisión de Dr Robert G. Maki, habiendo participado activamente en la actividad clínica y de investigación translacional en los departamentos de sarcomas, tumores digestivos y cáncer de mama así como fases I y desarrollo de nuevas moléculas. </p>
                                                                                            <p>Desde que finalizó su residencia en el Hospital General Universitario Gregorio Marañón, ha trabajado como Médico Adjunto del Servicio de Oncología Médica del Hospital Universitario “La Paz” desde el 16 de Junio 2008 hasta el momento actual. Desarrollando dicha labor tanto en la planta de Hospitalización de Oncología como en las Consultas externas de Hospital de Día. Especializándose en el tratamiento de tumores digestivos y cáncer de pulmón primero, y posteriormente tumores cerebrales, sarcomas y cáncer de mama. Convirtiéndose en responsable de la unidad de neurooncología y del GIST, y co-responsable de la unidad de cáncer de mama, con realización de labor asistencial, docente e investigacional (IdiPaz) en dichas áreas. Responsable de la unidad de trombosis asociada al cáncer del servicio. Y miembro de la comisión de Trombosis del Hospital La Paz desde su creación.</p>
                                                                                            <p>Ha participado activamente  en la docencia práctica de la disciplina Patología y Clínica Médicas III a los alumnos de sexto curso de la Licenciatura en Medicina y Cirugía de la Universidad Complutense de Madrid durante los Cursos Académicos 2006-2007 y 2007-2008. Posteriormente continuó está labor con los alumnos de sexto y cuarto de la  Licenciatura en Medicina y Cirugía de la Universidad Autónoma de Madrid desde 2008 hasta la actualidad.</p>
                                                                                            <p>Además ha colaborado en la docencia postgraduada a los facultativos Residentes de la especialidad de Oncología Médica y del resto de especialidades acreditadas, en el Hospital Universitario La Paz, desde su incorporación al Servicio de Oncología Médica de dicho hospital hasta el momento actual</p>
                                                                                            <p>Obtención del Certificado en Estudios Avanzados (D.E.A.) dentro del Programa de Doctorado de Medicina Interna, Universidad Complutense de Madrid (2006-2007). Obtención de Tesis doctoral con Sobresaliente cum Laude el 18 de diciembre de 2015 en la Universidad Autónoma de Madrid. Miembro activo de los diferentes grupos cooperativos de sus áreas de trabajo, siendo además en GEIS  parte de la junta directiva y responsable del área de trabajo en GIST. Forma parte de la sección de Trombosis de SEOM participando en diferentes proyectos investigacionales. Ha realizado publicaciones en congresos nacionales e internacionales, en libros y en revistas científicas.</p>




                                                                                        </div>




                                                                                        : props.speaker_cv == "Mercedes-Biosca" ?

                                                                                            <div className="speakers-cv-tromboencuentros2021">

                                                                                                <h3><strong>Dra. Mercedes Biosca</strong><br />Servicio de Oncología Médica del Hospital. Universitario de La Paz.</h3>

                                                                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Mercedes-Biosca.jpg" /><br />


                                                                                                <p>EXPERIENCIA PROFESIONAL<br />
                                                                                                    Internista adscrita al Servicio de Oncología Médica. Coordinadora de planta de hospitalización (2012-2019)<br />
                                                                                                    Directora clínica de Urgencias en el Hospital Universitario Vall d’Hebron de Barcelona.<br />
                                                                                                    Jefe de Departamento de Medicina Interna y Urgencias en el Hospital General de Sant Boi de Llobregat (Barcelona).<br />
                                                                                                    Consultora de Oncología Médica en el Institut Català d’Oncologia (ICO) del Hospital Duran i Reynals, en l’Hospitalet de Llobregat (Barcelona).


                                                                                                </p>


                                                                                                <p>EXPERIENCIA ACADÉMICA<br />

                                                                                                    Licenciatura en Medicina y Cirugía por la Universidad Autónoma de Barcelona, con la calificación de sobresaliente. Mención especial de la Academia de Ciencias Médicas de Barcelona en la convocatoria del Premio Extraordinario de Licenciatura.<br />
                                                                                                    Formación MIR en la especialidad de Medicina Interna en el Hospital Universitario Vall d’Hebron de Barcelona (1982-1985).<br />
                                                                                                    Curso de Posgrado de Gestión Hospitalaria «El jefe de servicio como product manager» de IDEC-Universitat Pompeu Fabra, en Barcelona (2008).<br />
                                                                                                    Curso ”Model de Gestió de Seguretat Clínica en els hospitals de Catalunya basat en EFQM”. 2009. Consorci Sanitario Integral<br />
                                                                                                    Curso ”Formación Lean ”. 2009. Consorci Sanitario Integral<br />
                                                                                                    SEOM. Fundación Gaspar Casal. Universidad Rey Juan Carlos: Diploma de Posgrado en Gestión Clínica en Oncología Médica. Octubre 2018- mayo 2019.
                                                                                                </p>

                                                                                                <p>ACTIVIDADES Y PARTICIPACIÓN EN SOCIEDADES CIENTÍFICAS

                                                                                                    Sociedad Española de Oncología Médica (SEOM). Miembro de la Sección de Trombosis y Cáncer<br />
                                                                                                    Academia de Ciencias Médicas de Cataluña y Baleares (ACMCB).<br />
                                                                                                    Sociedad Catalana de Medicina Interna (SCBMI).<br />
                                                                                                    Sociedad Española de Medicina Interna (SEMI).<br />
                                                                                                    Sociedad Catalana de Medicina de Urgencias y Emergencias (SCMUE).<br />
                                                                                                    Sociedad Española de Medicina de Urgencias y Emergencias (SEMES).

                                                                                                </p>


                                                                                                <p>PUBLICACIONES RECIENTES<br />
                                                                                                    Font C, Carmona-Bayonas A, Plasencia JM, Calvo-Temprano D, Sánchez M,
                                                                                                    Jiménez-Fonseca P, et al. Embolia pulmonar en el paciente oncológico: bases
                                                                                                    para el estudio EPIPHANY. Med Clin (Barc). 2014; 20(6): 304-306.

                                                                                                    <br />Carmona-Bayonas A, Biosca Gómez de Tejada M, Font C. Outpatient
                                                                                                    management of cancer-related pulmonary embolism: a propensity scorematched
                                                                                                    analysis of 803 patients from the EPIPHANY study. Ann Oncol. 2014;
                                                                                                    25 Supl 4: iv517-iv541.

                                                                                                    <br />Carmona-Bayonas A, Jiménez-Fonseca P, Virizuela Echaburu J, Antonio M,
                                                                                                    Font C, Biosca M, et al. Prediction of serious complications in patients with
                                                                                                    seemingly stable febrile neutropenia: validation of the Clinical Index of Stable
                                                                                                    Febrile Neutropenia in a prospective cohort of patients from the FINITE study. J
                                                                                                    Clin Oncol. 2015; 33(5): 465-471.

                                                                                                    <br />Jiménez-Fonseca P, Carmona-Bayonas A, Matos García I, Marcos Sánchez R,
                                                                                                    Castañón E, Antonio M, et al. A nomogram for predicting complications in
                                                                                                    patients with solid tumors and seemingly stable febrile neutropenia. Br J
                                                                                                    Cancer. 2016; 114(11): 1.191-1.198.

                                                                                                    <br />Carmona-Bayonas A, Font C, Jiménez-Fonseca P, Fenoy F, Otero R, Beato C,
                                                                                                    et al. On the necessity of new decision-making methods for cancer-associated,
                                                                                                    symptomatic, pulmonary embolism. Thromb Res. 2016; 143: 76-85.


                                                                                                    <br />Font C, Carmona-Bayonas A, Beato C, Reig O, Sáez A, Jiménez-Fonseca P, et al.; on behalf of the Asociación para la Investigación de la Enfermedad Tromboembólica de la Región de Murcia. Clinical features and short-term outcomes of cancer patients with suspected and unsuspected pulmonary embolism: the EPIPHANY study. Eur Respir J. 2017; 49(1): 1600282.
                                                                                                    <br />Carmona-Bayonas A, Jiménez-Fonseca P, Font C, Fenoy F, Otero R, Beato C, et al.; on behalf of the Asociación de Investigación de la Enfermedad Tromboembólica de la Región de Murcia. Predicting serious complications in patients with cancer and pulmonary embolism using decision tree modelling: the EPIPHANY Index. Br J Cancer. 2017; 116(8): 994-1.001.
                                                                                                    <br />Jiménez-Fonseca P, Carmona-Bayonas A, Font C, Plasencia-Martínez J, Calvo-Temprano D, Otero R, et al.; EPIPHANY study investigators and the Asociación de Investigación de la Enfermedad Tromboembólica de la Región de Murcia. The prognostic impact of additional intrathoracic findings in patients with cancer-related pulmonary embolism. Clin Transl Oncol. 2018; 20(2): 230-242.
                                                                                                    <br />Zugazagoitia J, Biosca M, Olivera J, Olmedo ME, Dómine M, Nadal E, et al. Incidence, predictors and prognostic significance of thromboembolic disease in patients with advanced ALK-rearranged non-small-cell lung cancer. Eur Respir J. 2018; 51(5). PII: 1702431.
                                                                                                    <br />SEOM clinical practice guideline: management and prevention of febrile neutropenia in adults with solid tumors (2018). : Clinical and Translational Oncology 2018 (21) :75 - 86
                                                                                                    <br />Noemie Kraaijpoel, MD ; Suzanne M. Bleker, MD, PhD ; Guy Meyer, MD; Isabelle Mahe, MD, PhD; Andres Muñoz, MD, PhD; Laurent Bertoletti, MD, PhD; Annemarieke Bartels-Rutten, MD, PhD; Jan Beyer-Westendorf, MD, PhD; Ettore Porreca, MD; Carine Boulon, MD; Nick van Es, MD, PhD ; Diana I. Iosub, MD; Francis Couturaud, MD, PhD; Mercedes Biosca, MD; Teresa Lerede, MD; Philippe Lacroix, MD, PhD; Anthony Maraveyas, MD, PhD; Anita Aggarwal, DO, PhD; Philippe Girard, MD; Harry R. Buller, MD, PhD; and Marcello Di Nisio, MD25, on behalf of the UPE investigators. Treatment and Long-Term Clinical Outcomes of Incidental Pulmonary Embolism in Patients With Cancer: An International Prospective Cohort Study. J Clin Oncol 2019 Jul 10;37(20): 1713-1720. DOI https://doi. org/10.1200/JCO.18. 01977.

                                                                                                    <br />Mulder F, Kraaijpoel N, Di Nisio M, Meyer G, Mahé I, Muñoz A, Bertoletti L, Biosca M, Maraveyas A, Büller HR, van Es N. The Ottawa score performs poorly in cancer patients with incidental pulmonary embolism.
                                                                                                    Thromb Res. 2019 Sep;181:59-63. doi: 10.1016/j.thromres.2019.07.005. Epub 2019 Jul 11.

                                                                                                    <br />J. Muñoz Langa, P. Jimenez Fonseca · A. Carmona Bayonas · E. M. de Castro. P. Pérez Segura. M. S. Cánovas. D. Gomez. L. O. Moran. M. Biosca G. de Tejada. E. Seguí. G. B. López. S. G. Adrián. M. C. Campos· V. P. Olmos. B. O. Portero. M. S. Moyano. J. A. S. Crespo. L. T. Sánchez. M. A. Rebollo. P. O. Rivas. J. P. Altozano. Á. R. Lescure. A. Muñoz Martín. Rationale, design and methodology of TESEO study: a registry of thrombosis and neoplasia of SEOM (Spanish Society of Medical Oncology). Clin Transl Oncol (2020). https://doi.org/10.1007/s12094-020-02472-x

                                                                                                    <br />Nerea Munoz-Uncetaa, Jon Zugazagoitia , Arancha Manzanoc , Elisabeth Jimenez-Aguilar, Maria E. Olmedod , Juan D. Cacho , Julio Oliveira ,Manuel Domine , Laura Ortega-Moran, Carlos Aguado ,Ana M. Luna , Lourdes Fernandez , Javier Perez , Carme Font , Carmen Salvador , Jesus Corral , Gretel Benitez , Silverio Ros , Mercedes Biosca , Virginia Calvo, Julia Martinez, Manuel Sanchez-Canovas , Rafael Lopez , Maria Sereno , Xabier Mielgo , Francisco Aparisi , Marta Carmona , Rafael Carrion, Santiago Ponce-Aix, , Marta Soares , Imanol Martinez-Salas , Marcial Garcia-Morillo , Oscar Juan-Vidal , Ana Blasco , Andres J. Munoz, Luis Paz-Ares. High risk of thrombosis in patients with advanced lung cancer harboring rearrangements in ROS1
                                                                                                    Grupo de trombosis y cancer SEOM. European Journal of Cancer 141 (2020) 193-198

                                                                                                    <br />Apixaban for the Treatment of Venous Thromboembolism Associated with Cancer.
                                                                                                    M. Sánchez Cánovas, M. Cejuela Solís, E. Coma Salvans, D. Casado Elia, D. Gómez Sánchez, P. Sampedro Domarco, R. Morales Giménez, M. Biosca Gómez de Tejada, D. Fernández Garay, C. Sánchez Cendra, S. Sequero López, V. Arrazubi Arrula, M. Justo de la Peña, M. Orillo Sarmiento, D. Moreno Muñoz, E. Martínez de Castro, A. Bernal Vidal, I. de la Haba Vacas, R. Sanchez Bayona, I. García Escobar, L. Ortega Morán, A.J. Muñoz Martín, M.J. Martínez Ortiz, P. Jiménez Fonseca, A. Carmona Bayonas .
                                                                                                    New Engl J Med March 29, 2020


                                                                                                    <br />AHigh risk of thrombosis in patients with advanced lung cancer harboring
                                                                                                    rearrangements in ROS1.
                                                                                                    Muñoz-Unceta N, Zugazagoitia J, Manzano A, Jiménez-Aguilar E, Olmedo ME,
                                                                                                    Cacho JD, Oliveira J, Dómine M, Ortega-Morán L, Aguado C, Luna AM,
                                                                                                    Fernández L, Pérez J, Font C, Salvador C, Corral J, Benítez G, Ros S, Biosca
                                                                                                    M, Calvo V, Martínez J, Sánchez-Cánovas M, López R, Sereno M, Mielgo X,
                                                                                                    Aparisi F, Carmona M, Carrión R, Ponce-Aix S, Soares M, Martínez-Salas I,
                                                                                                    García-Morillo M, Juan-Vidal O, Blasco A, Muñoz AJ, Paz-Ares L; Grupo de
                                                                                                    trombosis y cáncer SEOM.
                                                                                                    Eur J Cancer. 2020 Dec;141:193-198. doi: 10.1016/j.ejca.2020.10.002. Epub
                                                                                                    2020 Nov 7.

                                                                                                    <br />AThe prognostic value of respiratory symptoms and performance status in
                                                                                                    ambulatory cancer patients and unsuspected pulmonary embolism; analysis of
                                                                                                    an international, prospective, observational cohort study..
                                                                                                    Anthony Maraveyas, Noémie Kraaijpoel, George Bozas, Chao Huang,
                                                                                                    Isabelle Mahé, Laurent Bertoletti, Annemarieke Bartels-Rutten, Jan Beyer-
                                                                                                    Westendorf, Joel Constans, Diana Iosub, Francis Couturaud, Andres J. Muñoz,
                                                                                                    Mercedes Biosca, Teresa Lerede, Nick van Es, Marcello Di Nisio, the UPE
                                                                                                    investigators.
                                                                                                    J Thromb Haemost. 2021;00:1–10. DOI: 10.1111/jth.15489


                                                                                                </p>


                                                                                            </div>



                                                                                            : props.speaker_cv == "Pere-Cardona" ?

                                                                                                <div className="speakers-cv-tromboencuentros2021">

                                                                                                    <h3><strong>Dr. Pere Cardona</strong><br />Coordinador Patología Neurovascular del Hospital Universitario de Bellvitge.</h3>

                                                                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Pere-Cardona.jpg" /><br />


                                                                                                    <p>Trayectoria profesional.<br />
                                                                                                        Neurólogo (Hospital Universitario de Bellvitge 2003-Actualidad).<br />
                                                                                                        Formación en patología vascular cerebral  en el Hospital Universitario de Bellvitge, Hospital Alicante  y Hospital Josep Trueta Girona. Desarrollo e implantación Unidad de Ictus en el Hospital Universitario de Bellvitge 2003. Realización de estudios vasculares ulstrasonográficos y hemodinámicos cerebrales con desarrollo del Laboratorio de Neurosonología para el diagnóstico del ictus.<br />
                                                                                                        Participación en 2005 en la implantación del código ictus en el Área Metropolitana Sur de Barcelona de acuerdo al Plan Director de la Enfermedad Vascular Cerebral. <br />
                                                                                                        Creación de una red diagnóstica del accidente isquémico transitorio 24h/365 dentro del ámbito hospitalario y ambulatorio, con creación en 2009 de la Clínica de AIT.<br />
                                                                                                        Implantación de terapias de reperfusión (trombolisis y trombectomía), con aplicación y mejora de los tiempos de repefusión intrahospitalario. <br />
                                                                                                        Profesor asociado facultad de medicina, Universitat de Barcelona.<br />
                                                                                                        Participación como miembro de la Sociedad Española de Neurología en diferentes comunicaciones y grupos de trabajo del ictus. American Heart Association y European Stroke Organization en diferentes proyectos y ensayos clínicos internacionales. Formador para certificación estudios ultrasonográficos en patología vascular cerebral (SEN). Miembro de equipo de unidad de trasplantes y colaborador con Sociedad Española de Medicina Intensiva y Organización Nacional de Trasplantes.<br />
                                                                                                        Como investigador principal ha participado en diferentes ensayos clínicos randomizados internacionales tanto en fase aguda como preventiva del ictus.</p>

                                                                                                    <p>Publicaciones: <br />
                                                                                                        Ha participado en más de 50 publicaciones nacionales e internacionales en  diferentes aspectos de la patología vascular cerebral, en relación a equipos clínicos de tratamientos, equipos de básicos y nuevos biomarcadores o radiología con elementos diagnósticos e intervencionismo novedoso.<br />
                                                                                                        A su vez ha participado en publicaciones internacionales de presentación de resultados de estudios clínicos internacionales como RACECAT, REVASCAT, DAWN, SITS-MOST que cambiaron la práctica clínica habitual en el tratamiento del ictus.<br />
                                                                                                        Igualmente ha participado en la elaboración de guías de consenso de práctica clínica tanto nacionales en relación al uso de tratamientos hipolipemiantes, como antihipertensivo o antitrombóticos.</p>



                                                                                                </div>





                                                                                                : props.speaker_cv == "Ana-Margarita-Redondo" ?

                                                                                                    <div className="speakers-cv-tromboencuentros2021">

                                                                                                        <h3><strong>Dra. Ana Margarita Redondo</strong><br />Servicio de Hematología y Hemoterapia. Complejo Hospitalario de Navarra. Pamplona.</h3>

                                                                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/programme-speakers/Ana-Margarita-Redondo.jpg" /><br />

                                                                                                        <p>Trayectoria profesional</p>

                                                                                                        <ul>

                                                                                                            <li>MIR en Hematología y Hemoterapia. Hospital Virgen del Camino de Pamplona, actualmente Complejo Hospitalario de Navarra (CHN) (1989-1993).</li>
                                                                                                            <li>Médico adjunto de Hematología y Hemoterapia en el Hospital Virgen del Camino (febrero-junio de 1994).</li>
                                                                                                            <li>Médico adjunto de Hematología y Hemoterapia en Tudela (julio de 1994) y en el Hospital Bidasoa de Irún (agosto de 2014).</li>
                                                                                                            <li>Médico adjunto de Hematología y Hemoterapia del CHN (desde noviembre de 1994).</li>
                                                                                                            <li>Miembro del Grupo Técnico de Hematología y Hemoterapia (octubre de 2011-febrero de 2014).</li>
                                                                                                            <li>Jefe del Servicio de Hematología y Hemoterapia del CHN (octubre de 2012-febrero de 2014) y en la actualidad desde 30/4/2018.</li>
                                                                                                            <li>Secretaria del Comité de ETEV del CHN.</li>
                                                                                                            <li>Miembro del Comité de trasfusión del CHN desde 2019</li>


                                                                                                        </ul>


                                                                                                        <p>Trayectoria académica</p>

                                                                                                        <ul>

                                                                                                            <li>Licenciada en Medicina y Cirugía por la Universidad de Navarra (junio de 1989).</li>
                                                                                                            <li>Especialista en Hematología y Hemoterapia en el Hospital Virgen del Camino (diciembre de 1993).</li>
                                                                                                            <li>Tesis doctoral “cum laude” presentada en junio de 1996.</li>
                                                                                                            <li>Profesora clínica asociada de la Facultad de Medicina de la Universidad de Navarra. </li>
                                                                                                            <li>Posgrado de dos años en Patología Trombótica. Universidad de Navarra 2012-13</li>
                                                                                                            <li>Experto de Gestión Sanitaria por la Universidad pública de Navarra 2018-2019.</li>
                                                                                                            <li>Curso Modelo de Gestión avanzada en 2019</li>


                                                                                                        </ul>


                                                                                                        <p>Participación en sociedades científicas y grupos de trabajo</p>

                                                                                                        <ul>

                                                                                                            <li>Miembro de la Sociedad Española de Hematología y Hemoterapia (SEHH).</li>
                                                                                                            <li>Miembro de la Sociedad Española de Hemostasia y Trombosis (SETH).</li>
                                                                                                            <li>Miembro de la Asociación Vasco-Navarra de Hematología y Hemoterapia (ASOVASNA).</li>
                                                                                                            <li>Miembro del Grupo de Trabajo de Anticoagulación Oral en Atención Primaria, dependiente del Comité Técnico del Plan de Coordinación Primaria-Especializada del Sistema Navarro de Salud-Osasunbidea.</li>
                                                                                                            <li>Miembro del Grupo de Trabajo «Pautas de actuación preoperatorios en pacientes con tratamiento antiagregante y anticoagulante», del CHN.</li>
                                                                                                            <li>Miembro del Grupo de Enfermedades Cerebrovasculares, Área de Neurociencia, IdiSNA (desde noviembre de 2015).</li>


                                                                                                        </ul>


                                                                                                        <p>Actividad Investigadora</p>

                                                                                                        <ul>

                                                                                                            <li>Proyecto estratégico DESCARTHeS.  I+D Gobierno de Navarra</li>
                                                                                                            <li>Ensayo clínico NCT00439725: The Einstein-Extension Study .2009</li>
                                                                                                            <li>Ensayo clínico NCT00440193: Oral Direct Factor Xa Inhibitor Rivaroxaban in Patients with Acute Symptomatic Deep- Vein Thrombosis without Symptomatic Pulmonary Embolism: Einstein-DVT Evaluation 2010</li>
                                                                                                            <li>Ensayo clínico NCT00439777: Oral Direct Factor Xa Inhibitor Rivaroxaban in Patients with Acute Symptomatic Deep- Vein Thrombosis without Symptomatic Pulmonary Embolism: Einstein-PE Evaluation 2011</li>
                                                                                                            <li>Ensayo clínico BO661002-CV185-057: “ Ensayo para evaluar la eficacia y seguridad del uso del Apixaban en el tratamiento extendido de la trombosis venosa profunda y la embolia pulmonar”2011</li>
                                                                                                            <li>Ensayo PUHS: Proyecto para actualizar el estudio de Hemofilia congénita en España 2011</li>
                                                                                                            <li>Estudio AURIG 2011</li>
                                                                                                            <li>Ensayo clínico CV185-056, 2012</li>
                                                                                                            <li>Estudio Xalia 2012-14</li>
                                                                                                            <li>Ensayo HEROIC 2014</li>
                                                                                                            <li>Proyecto TEAM 2014-2017</li>


                                                                                                        </ul>


                                                                                                        <p>Publicaciones más relevantes</p>


                                                                                                        <ul>

                                                                                                            <li>Büller HR, Prins MH, Lensin AW, Decousus H, Jacobson BF, Minar E, et al.; EINSTEIN-PE Investigators. Oral rivaroxaban for the treatment of symptomatic pulmonary embolism. N Engl J Med. 2012; 366(14): 1.287-1.297.</li>
                                                                                                            <li>Alfonso A, Redondo M, Rubio T, Del Olmo B, Rodríguez-Wilhelmi P, García-Velloso MJ, et al. Screening for occult malignancy with FDG-PET/CT in patients with unprovoked venous thromboembolism. Int J Cancer. 2013; 133: 2.157-2.164. </li>
                                                                                                            <li>Casos clínicos: tratamiento multidisciplinar de un paciente con FANV tratado con Pradaxa que requiere el uso de Praxbind (idarucizumab) en situación de urgencia. Junio de 2016.</li>
                                                                                                            <li>Martí Sáez E, Medina Mallén MC, Stevenazzi Rivas M, López de la Fuente M, Caselllas Caro M, Aguinaco Culebreras R, et al. Manejo de las complicaciones vasculares gestacionales y trombosis en la mujer. Prog Obstet Ginecol. 2017; 60(2): 107-113.</li>


                                                                                                        </ul>


                                                                                                    </div>


                                                                                                    :

                                                                                                    null}



                </div>
                <div className="legal-text-btn-container">
                    <div onClick={props.ononSpeakersCVClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                </div>
            </div>


            : props.event_id === "4" ?

                <div className="backdrop-disclaimer-text">
                    <div className="legal-text-container">

                        {props.speaker_cv == "Carmen-Fernandez-Capitan" ?
                            <div className="speakers-cv-tromboencuentros2021">
                                <h3><strong>Dra. Carmen Fernández Capitán</strong><br />Servicio de Medicina Interna. Hospital Universitario La Paz, Madrid.</h3>

                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Carmen-Fernandez-Capitan.jpg" /><br />

                                <p><strong>Titulación académica</strong></p>

                                <p>- Licenciada en Medicina y Cirugía por la Facultad de Medicina de la Universidad Complutense de Madrid. 1980</p>
                                <p>- Especialista en Medicina Interna por la Universidad Autónoma de Madrid. 1984</p>
                                <p>- Doctora en Medicina por la Facultad de Medicina de la Universidad Autónoma de Madrid. Junio 2004</p>
                                <p>- Diplomatura postgrado en Enfermedad Tromboembólica Venosa  acreditada por la Universidad Autónoma de Barcelona. 2010.</p>

                                <p><strong>Trayectoria profesional</strong></p>

                                <p>- Residente del S. Medicina Interna del Hospital Universitario la Paz. Madrid (1981- 1984).</p>
                                <p>- Médico Adjunta del S. Medicina Interna del H. Universitario la Paz. Madrid (1985- 2004).</p>
                                <p>- Jefe de Sección del S. Medicina Interna del H. Universitario la Paz. Madrid (2004- actualidad).</p>
                                <p>- Coordinadora de la Unidad de Enfermedad Tromboembólica Venosa del HU La Paz. Madrid (2001-actualidad).</p>
                                <p>- Coordinadora de Calidad del Servicio de Medicina Interna del Hospital Universitario La Paz. (2005 hasta la actualidad.)</p>
                                <p>- Coordinadora de Consultas Externas de M. Interna del Hospital Universitario La Paz (2007- actualidad)</p>
                                <p>- Presidenta de la Comisión de Trombosis y Hemostasia del HU La Paz. Madrid. (2016-actualidad)</p>
                                <p>- Profesora Honoraria y Clínico Colaborador Docente de la Facultad de Medicina de la U. Autónoma de Madrid. (1987-2008).</p>
                                <p>- Profesora Asociada del Departamento de Medicina de la U Autónoma de Madrid (2009-actualidad).</p>

                                <p>- Profesora  en diversos cursos, masters en relación con la ETEV:<br />
                                    Cursos de Doctorado UAM sobre ETEV (2002, 2009, 2014)<br />
                                    “Diplomatura postgrado de ETEV” acreditada   por la Universidad Autónoma de Barcelona (2009 – 2010)<br />
                                    “Máster en tratamiento anticoagulante” acreditado   por la Universidad Católica San Antonio  de Murcia (1ª edición, 2015-2016; 2ª edición, 2017-2018 y 3ª 2021)<br />
                                    “Master en trombosis UAH” acreditada   por la Universidad de Alcalá de Henares (1ª edición, 2015-2016. 2ª edición, 2017-2018 y 3ª edición, 2019-2021)<br />
                                    “Máster en metodología de la investigación clínica en la tromboembolia de pulmón” acreditado por la Universidad de Alcalá de Henares (1ª edición, 2016-2017 y 2ª edición, 2019-2020).</p>

                                <p>- Directora de Tesis Doctorales y Trabajos Fin de grado de Medicina (UAM).</p>
                                <p>- Participación en numerosos estudios de investigación y ensayos clínicos sobre ETEV. </p>

                                <p><strong>Publicaciones</strong></p>

                                <p>Ha sido autora o ha contribuido en más de 100 documentos científicos nacionales e internacionales y capítulos de libros en los campos de la M. Interna y la Enfermedad Tromboembólica Venosa.</p>
                                <p>Cuenta con más de 300 carteles, presentaciones orales o ponencias en congresos nacionales e internacionales de prestigio</p>

                                <p><strong>Otros</strong></p>

                                <p>- Miembro de la Sociedad Española de M. Interna desde 1985.</p>
                                <p>- Miembro del grupo de trabajo de Enfermedad Tromboembólica de la SEMI desde 2001.</p>
                                <p>- Miembro del Registro Informatizado de Enfermedad TromboEmbólica (RIETE) desde 2001</p>
                                <p>- Miembro del Grupo Multidisciplinar de Hipertensión Pulmonar. Hospital Universitario La Paz desde 2006.</p>



                            </div>


                            : props.speaker_cv == "Jose-Luis-Ferreiro-Gutierrez" ?

                                <div className="speakers-cv-tromboencuentros2021">
                                    <h3><strong>Dr. José Luis Ferreiro Gutiérrez</strong><br />Servicio de Cardiología. Hospital Universitario de Bellvitge, Barcelona.</h3>

                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Jose-Luis-Ferreiro-Gutierrez.jpg" /><br />


                                    <p>El Dr. Ferreiro es en la actualidad médico adjunto de la Unidad de Cardiología Intervencionista y Director del Laboratorio de Investigación Traslacional en Trombosis, ambos pertenecientes al Servicio de Cardiología del Hospital Universitario de Bellvitge. Su actividad investigadora se ha centrado en el campo de los fármacos antitrombóticos, con un particular interés en el funcionalismo plaquetar, la respuesta a fármacos antiagregantes y la utilización de las pruebas de función plaquetar como herramienta para implementar una terapia individualizada. Ha sido Coordinador del Grupo de Trabajo en Trombosis Cardiovascular de la Sociedad Española de Cardiología en el periodo 2016-2020. Desde 2020, el Dr. Ferreiro es Coordinador Nacional de Guías de Práctica Clínica de la Sociedad Española de Cardiología y Nucleus Member del Working Group on Thrombosis de la European Society of Cardiology. En la actualidad cuenta con 102 artículos científicos publicados en revistas indexadas (índice h: 27), 7 capítulos de libros o monografías, más de 100 comunicaciones en congresos y es revisor científico de numerosas revistas nacionales e internacionales.</p>


                                </div>



                                : props.speaker_cv == "Pedro-Ruiz-Artacho" ?

                                    <div className="speakers-cv-tromboencuentros2021">
                                        <h3><strong>Dr. Pedro Ruiz Artacho</strong><br />Servicio de Medicina Interna. Clínica Universidad de Navarra, Madrid.</h3>

                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Pedro-Ruiz-Artacho.jpg" /><br />

                                        <p><strong>Trayectoria profesional</strong></p>

                                        <p>Actualmente es médico adjunto del Departamento de Medicina Interna de la Clínica Universidad de Navarra, Madrid</p>


                                        <p><strong>Trayectoria académica</strong></p>

                                        <p>Obtuvo su licenciatura de Medicina en la Universidad Autónoma de Madrid, la diplomatura de Bioestadística y el Máster de Investigación en Ciencias de la Salud en la Universidad Autónoma de Barcelona. Máster de Investigación en Tromboembolia Pulmonar en la Universidad de Alcalá de Henares. Se especializó en Medicina Interna en el Hospital Clínico San Carlos de Madrid y realizó su tesis doctoral sobre la enfermedad tromboembólica venosa en la Universidad Complutense de Madrid. </p>


                                        <p><strong>Actividades y participación en sociedades científicas</strong></p>

                                        <p>Co-coordinador del Grupo Nacional de Trabajo en ETV de la Sociedad Española de Medicina de Urgencias y Emergencias (SEMES). </p>
                                        <p>CIBER de Enfermedades Respiratorias, CIBERES, Madrid, Spain.</p>

                                        <p><strong>Publicaciones</strong></p>

                                        <p>Es autor o coautor de más de 50 artículos en publicaciones nacionales e internacionales.</p>



                                    </div>


                                    : props.speaker_cv == "Joan-Carles-Souto-Andres" ?

                                        <div className="speakers-cv-tromboencuentros2021">
                                            <h3><strong>Dr. Joan Carles Souto Andrés</strong><br />Servicio de Hematología. Hospital de la Santa Creu i Sant Pau, Barcelona.</h3>

                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Joan-Carles-Souto-Andres.jpg" /><br />

                                            <p>Licenciado en Medicina y Cirugía en la Extensión Universitaria de la UCB en Lleida en 1987.</p>
                                            <p>Especialista en Hematología y Hemoterapia en 1991. </p>
                                            <p>Doctor en Medicina y Cirugía por la UAB en 2001. </p>
                                            <p>En 1992 fue contratado como médico adjunto de la Unidad de Hemostasia y Trombosis del Servicio de Hematología, en el  Hospital de la Santa Creu i Sant Pau, de Barcelona. Ha sido miembro del staff de Hematología, ininterrumpidamente hasta la fecha. Actualmente es el responsable de la Sección de Diagnóstico e Investigación Traslacional de Enfermedades de la Hemostasia. </p>
                                            <p>Desarrolla su labor asistencial en la Consulta de tratamiento antitrombótico y de enfermedades tromboembólicas y hemorrágicas. Es miembro electo en 2017 del Consell Directiu del Cos Facultatiu del Hospital.</p>

                                            <p>Autor de 165 artículos científicos en revistas indexadas, en 35 como primer firmante y en 20 como último o corresponsal. El índice h es 36.</p>
                                            <p>Autor de 311 comunicaciones científicas a Congresos nacionales e Internacionales.</p>
                                            <p>Miembro del Equipo de Investigación en 21 Proyectos de Investigación competitivos, en 7 de ellos, como Investigador Principal. Ha participado como colaborador en 2 proyectos de la Unión Europea y en 1 del NIH (USA).</p>
                                            <p>Responsable principal de los proyectos científicos GAIT 1 y 2 (Genetic Analysis of Idiophatic Thrombophilia) desarrollado desde 1995 hasta el presente; ACOA (Alternative Control of Oral Anticoagulation) entre 2000 y 2005; RETROVE (Riesgo de Enfermedad TROmboembólica Venosa) iniciado en 2012; MIRTO (Modelling the Individual Risk of Thrombosis in Oncology), desde 2015. También, en 2019 ha iniciado como Investigador Principal, el proyecto y el registro MACACOD (Modelo de Aplicación Clínica de los Anticoagulantes Orales Directos). Actualmente dirige el estudio CORONA, con el objetivo de investigar la relación entre las terapias antitrombóticas y la enfermedad COVID-19.</p>

                                            <p>Miembro de Sociedades Científicas como SETH, AEHH, ISTH, ISMAA y ACMCB.</p>
                                            <p>Ha sido fundador y Director Científico de Monitor Medical, compañía privada dedicada a la promoción del autocontrol del tratamiento anticoagulante oral con fármacos AVK. Actualmente es Asesor científico de Devicare.</p>


                                        </div>


                                        : props.speaker_cv == "Joan-Carles-Reverter-Calatayud" ?

                                            <div className="speakers-cv-tromboencuentros2021">
                                                <h3><strong>Dr. Joan Carles Reverter Calatayud</strong><br />Servicio de Hematología y Hemoterapia. Hospital Clínic, Barcelona.</h3>

                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Joan-Carles-Reverter-Calatayud.jpg" /><br />



                                                <p>Jefe de Servicio del Servicio de Hemoterapia y Hemostasia del Hospital Clínic de Barcelona.</p>
                                                <p>Licenciado en Medicina y Cirugía en la Facultad de Medicina de la Universidad de Barcelona. Doctor en Medicina y Cirugía por la Universidad de Barcelona. Formación MIR en la especialidad de Hematología y Hemoterapia realizada en el Hospital Clínic de Barcelona.</p>
                                                <p>Formación post-doctoral como Research Assistant Professor en Mount Sinai Hospital, School of Medicine City University of New York.</p>
                                                <p>Presidente de la Sociedad Española de Trombosis y Hemostasia desde 2020. Ha sido presidente de la Sociedad Catalana de Hematología y Hemoterapia de 2009 a 2012. Ha sido secretario de la Sociedad Española de Trombosis y Hemostasia de 2002 a 2010 y de 2018 a 2020.</p>
                                                <p>Ha publicado más de 240 artículos en revistas internacionales en el campo de la hemostasia. </p>
                                                <p>Ha colaborado como autor en 33 capítulos de libro, siendo editor en dos de ellos. </p>
                                                <p>Ha presentado más de 200 conferencias sobre temas de la hemostasia, tanto en reuniones nacionales como internacionales.</p>



                                            </div>


                                            : props.speaker_cv == "Luis-Jara-Palomares" ?

                                                <div className="speakers-cv-tromboencuentros2021">
                                                    <h3><strong>Dr. Joan Carles Reverter Calatayud</strong><br />Servicio de Neumología. Hospital Universitario Virgen del Rocío, Sevilla.</h3>

                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Luis-Jara-Palomares.jpg" /><br />

                                                    <p><strong>TRAYECTORIA PROFESIONAL: </strong></p>

                                                    <p>1995-2001: Facultad de Medicina en Universidad de Córdoba</p>
                                                    <p>2002-2006: Formación como MIR en Hospital Universitario Virgen del Rocío, Sevilla</p>
                                                    <p>2006-2007: Hospital Gutiérrez Ortega, Valdepeñas, Ciudad Real</p>
                                                    <p>2007-2008: Hospital Santa Bárbara, Puertollano, Ciudad Real.</p>
                                                    <p>2008-2009: Hospital Sierra Norte, Constantina, Sevilla</p>
                                                    <p>2009-Actualidad: Hospital Universitario Virgen del Rocío, Sevilla</p>

                                                    <p><strong>FORMACIÓN. TRAYECTORIA ACADÉMICA:</strong></p>

                                                    <p>2009 Doctor en Medicina Cum Laude</p>
                                                    <p>Tutor clínico</p>
                                                    <p>Master en Tabaquismo, Master en Bioestadística aplicada a las ciencias de la Salud, Master en Trombosis</p>

                                                    <p><strong>ACTIVIDADES Y PARTICIPACIÓN EN SOCIEDADES CIENTÍFICAS</strong></p>

                                                    <p>Coordinador del Área de Circulación Pulmonar de SEPAR (2017-2021).</p>
                                                    <p>Coordinador del Grupo Emergente de Circulación Pulmonar de SEPAR (2016-2019).</p>
                                                    <p>Vocal del Área de Circulación Pulmonar de SEPAR (2010-2013).</p>
                                                    <p>Coordinador del grupo de ventilación mecánica de Neumosur (2014-2016).</p>
                                                    <p>Miembro del TEAM program (Thrombosis ExchAnge prograM).</p>
                                                    <p>Participación en ensayos clínicos nacionales e internacionales</p>
                                                    <p>Revisor de artículos para las revistas: Chest, Thrombosis Haemostasis, Journal Thrombosis Haemostasia (JTH), Thrombosis Research, Haematologica, Plos One, Int J Cardiol, JAT (Journal of Atherosclerosis and Thrombosis), Medicina Clínica, Revista Clínica Española, Advance in Therapy, Clinical and Applied Thrombosis Hemostasis, BMJ Case Report.</p>

                                                    <p><strong>PUBLICACIONES RECIENTES MAS RELEVANTES</strong></p>

                                                    <p>Barca-Hernando M, Ortega-Rivera R, Lopez-Ruz S, Elias-Hernandez T, Asensio- Cruz MI, Marin-Romero S, Toral J, Montero E, Sanchez V, Arellano E, Sanchez-Diaz JM, Real-Dominguez M, Otero-Candelera R, Jara-Palomares L. Prognostic Significance of Incidental Deep Vein Thrombosis in Patients with Cancer Presenting with Incidental Pulmonary Embolism. Cancers (Basel). 2020 Aug 13;12(8):2267.</p>

                                                    <p>van Es N, Ay C, Jara-Palomares L. Screening for Occult Cancer in Patients with Venous Thromboembolism: Past, Present, and Future. Hamostaseologie. 2020 Aug;40(3):270-279. </p>

                                                    <p>Jara-Palomares L, et al.; RIETE investigators. Derivation and validation of a clinical prediction rule for thrombolysis-associated major bleeding in patients with acute pulmonary embolism: the BACS score. Eur Respir J. 2020 Jul 23:2002336. </p>


                                                    <p>Sánchez-López V, Gao L, Ferrer-Galván M, Arellano-Orden E, Elías-Hernández T, Jara-Palomares L, et al. Differential biomarker profiles between unprovoked venous thromboembolism and cancer. Ann Med. 2020 Sep;52(6):310-320. </p>

                                                    <p>Marín-Romero S, Jara-Palomares L. Screening for occult cancer: where are we  in 2020? Thromb Res. 2020 Jul;191 Suppl 1:S12-S16.</p>

                                                    <p>de Miguel-Diez J, Blasco-Esquivias I, Rodriguez-Matute C, Bedate-Diaz P, Lopez-Reyes R, Fernandez-Capitan C, Garcia-Fuika S, Lobo-Beristain JL, Garcia-Lozaga A, Quezada CA, Murga-Arizabaleta I, Garcia-Ortega A, Rodríguez-Davila MA, Marin-Barrera L, Otero-Candelera R, Praena-Fernandez JM, Jara-Palomares L. Correlation between short-term air pollution exposure and unprovoked lung embolism. Prospective observational (Contamina-TEP Group). Thromb Res. 2020 Aug;192:134-140.</p>

                                                    <p>Jara-Palomares L et al.; RIETE investigators. Comparison of seven prognostic tools to identify low-risk pulmonary embolism in patients aged &#x3C; 50 years. Sci Rep. 2019 Dec 27;9(1):20064.</p>

                                                    <p>Marin-Barrera L, Muñoz-Martin AJ, Rios-Herranz E, Garcia-Escobar I, Beato C, Font C, Oncala-Sibajas E, Revuelta-Rodriguez A, Areses MC, Rivas-Jimenez V, Moreno-Santos MA, Ballaz-Quincoces A, Lopez-Saez JB, Gallego I, Elias-Hernandez T, Asensio-Cruz MI, Chasco-Eguilaz L, Garcia-Gonzalez G, Estevez-Garcia P, Otero R, Lima-Alvarez J, Jara-Palomares L. A Case-Control Analysis of the Impact of Venous Thromboembolic Disease on Quality of Life of Patients with Cancer: Quality of Life in Cancer (Qca) Study. Cancers (Basel). 2019 Dec 26;12(1):75.</p>

                                                    <p>Marín-Romero S, Elías-Hernández T, Asensio-Cruz MI, Ortega-Rivera R, Morillo-Guerrero R, Toral J, Montero E, Sánchez V, Arellano E, Sánchez-Díaz JM, Real-Domínguez M, Otero-Candelera R, Jara-Palomares L. Risk Of Recurrence After Withdrawal Of Anticoagulation In Patients With Unprovoked Venous Thromboembolism: External Validation Of The Vienna Nomogram And The Dash Prediction Score. Arch Bronconeumol. 2019 May 23. pii: S0300-2896(19)30226-1. </p>

                                                    <p>Jara-Palomares L, van Es N, Praena-Fernandez JM, Le Gal G, Otten HM, Robin P,  Piccioli A, Lecumberri R, Religa P, Rieu V, Rondina M, Beckers M, Prandoni P, Salaun PY, Di Nisio M, Bossuyt PM, Kraaijpoel N, Büller HR, Carrier M. Relationship between type of unprovoked venous thromboembolism and cancer location: An individual patient data meta-analysis. Thromb Res. 2019 Apr;176:79-84. </p>

                                                    <p>Jiménez D, Agustí A, Monreal M, Otero R, Huisman MV, Lobo JL, Quezada A, Jara-Palomares L, Hernando A, Tabernero E, Marcos P, Ruiz-Artacho P, Ballaz A, Bertoletti L, Couturaud F, Yusen R; SLICE investigators. The rationale, design, and methods of a randomized, controlled trial to evaluate the efficacy and safety of an active strategy for the diagnosis and treatment of acute pulmonary embolism during exacerbations of chronic obstructive pulmonary disease. Clin Cardiol. 2019 Mar;42(3):346-351. </p>

                                                    <p>Jara-Palomares L, Asensio-Cruz MI, Elias-Hernandez T, Marin-Romero S, Otero-Candelera R. Reply to comment on 'D-dimer and high-sensitivity C-reactive protein levels to predict venous thromboembolism recurrence after discontinuation of anticoagulation for cancer-associated thrombosis'. Br J Cancer. 2019 Feb;120(4):473-474. </p>

                                                    <p>Jara-Palomares L, Elias-Hernandez T, Asensio-Cruz MI, Marin-Romero S, Otero-Candelera R. Towards a precision medicine in venous thromboembolism associated to lung cancer. J Thorac Dis. 2018 Sep;10(Suppl 26):S3064-S3066. doi:  10.21037/jtd.2018.08.40. </p>

                                                    <p>Jara-Palomares L, Solier-Lopez A, Elias-Hernandez T, Asensio-Cruz MI, Blasco-Esquivias I, Sanchez-Lopez V, de la Borbolla MR, Arellano-Orden E, Suarez-Valdivia L, Marin-Romero S, Marin-Barrera L, Ruiz-Garcia A, Montero-Romero E, Navarro-Herrero S, Lopez-Campos JL, Serrano-Gotarredona MP, Praena-Fernandez JM, Sanchez-Diaz JM, Otero-Candelera R. D-dimer and high-sensitivity C-reactive protein levels to predict venous thromboembolism recurrence after discontinuation of anticoagulation for cancer-associated thrombosis. Br J Cancer. 2018 Oct;119(8):915-921.</p>

                                                    <p>Jiménez D, Bikdeli B, Barrios D, Morillo R, Nieto R, Guerassimova I, Muriel A, Jara-Palomares L, Moores L, Tapson V, Yusen RD, Monreal M; RIETE investigators. Management appropriateness and outcomes of patients with acute pulmonary embolism. Eur Respir J. 2018 May 10;51(5).</p>

                                                    <p>Otero R, Bertoletti L, Muriel A, Siniscalchi C, Jimenez C, Luis Lobo J, Kigitovica D, Quintavalla R, Rocci A, Jara-Palomares L, Monreal M; RIETE Investigators. Role of a clinical prediction score in a chronic thromboembolic pulmonary hypertension rule-out strategy. Eur Respir J. 2018 Apr 19;51(4). pii: 1702576.</p>




                                                </div>



                                                : props.speaker_cv == "Ramon-Lecumberri-Villamediana" ?

                                                    <div className="speakers-cv-tromboencuentros2021">
                                                        <h3><strong>Dr. Ramón Lecumberri Villamediana</strong><br />Servicio de Hematología. Clínica Universidad de Navarra, Pamplona.</h3>
                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Ramon-Lecumberri-Villamediana.jpg" /><br />
                                                        <p>-	Licenciado (1997) y Doctor (2003) en Medicina, Universidad de Navarra. </p>
                                                        <p>-	Especialista en Hematología y Hemoterapia (1998-2002). Clínica Universidad de Navarra. </p>
                                                        <p>-	Posición actual: Co-Director, Servicio de Hematología, Clínica Universidad de Navarra.</p>
                                                        <p>-	Profesor Titular (acreditado por ANECA) de la Facultad de Medicina, Universidad de Navarra.</p>
                                                        <p>-	Autor de 35 capítulos de libros y 80 artículos en revistas científicas internacionales.</p>
                                                        <p>-	Investigador principal en numerosos proyectos de investigación con financiación tanto pública como privada.</p>
                                                        <p>-	Director de 4 tesis doctorales.</p>
                                                        <p>-	Vocal de la junta directiva de la Sociedad Española de Trombosis y Hemostasia (SETH) (2012-2016). </p>
                                                        <p>-	Vocal de la junta directiva de la Sociedad Española de Hematología y Hemoterapia (2019- actualidad).</p>
                                                        <p>-	Coordinador del grupo de Trombosis y Cáncer de la SETH.</p>


                                                    </div>




                                                    : props.speaker_cv == "Pere-Domenech-Santasusana" ?

                                                        <div className="speakers-cv-tromboencuentros2021">
                                                            <h3><strong>Dr. Pere Doménech Santasusana</strong><br />Servicio de Hematología y Hemoterapia. Hospital Universitario de Bellvitge, Barcelona.</h3>
                                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Pere-Domenech-Santasusana.jpg" /><br />

                                                            <p>Licenciado en Medicina y Cirugía, Especialidad de Hematología y Hemoterapia. Jefe de Sección,  Unidad de Trombosis y Hemostasia, Servicio de Hematología, Hospital Universitario de Bellvitge, </p>

                                                            <p>Investigación clínica desarrollada en el área de la enfermedad tromboembólica venosa, la enfermedad cardioembólica y el tratamiento anticoagulante</p>

                                                            <p>Vicepresidente de la SETH. Miembro de la ISTH. Profesor Asociado de Medicina, Campus de Bellvitge, Universidad de Barcelona. Profesor de varios cursos presenciales y cursos on-line (CAPTA, TROTA, DIADHEMA, Puesta al día en tratamiento Antitrombótico). Director del curso on-line: Curso de formación para autoexpertos en autocontrol del TAO de la SETH. Ponente en congresos nacionales e internacionales y autor de artículos sobre temas relacionados con la enfermedad tromboembólica venosa, la enfermedad cardioembólica y el tratamiento anticoagulante.</p>


                                                        </div>


                                                        : props.speaker_cv == "Enrique-Gallardo-Diaz" ?

                                                            <div className="speakers-cv-tromboencuentros2021">
                                                                <h3><strong>Dr. Enrique Gallardo Díaz</strong><br />Servicio de Oncología. Hospital de Sabadell, Barcelona.</h3>
                                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Enrique-Gallardo-Diaz.jpg?ver=2" /><br />

                                                                <p>Licenciado en Medicina y Cirugía, Universidad de Sevilla en 1990.</p>

                                                                <p>Especialista en Oncología Médica, 1992-1995, Hospital Vall d’Hebron de Barcelona. </p>

                                                                <p>Adjunto y Coordinador de Ensayos Clínicos, 1996-1999, Servicio de Oncología, Hospital Vall d’Hebron. Adjunto del Servicio de Oncología, Hospital de Terrassa, 1999-2002. </p>

                                                                <p>Desde 2002, Adjunto del Servicio de Oncología del Hospital Universitari Parc Taulí de Sabadell, en la actualidad con la categoría de Médico Consultor, y co-responsable de la Unidad de Uro-Oncología. Miembro de los Comités de Tumores del Sistema Nervioso Central y de Cáncer de Tiroides.</p>

                                                                <p>Desde diciembre de 2015, miembro del Servicio de Oncología de Asistencia Sanitària. Hospital de Barcelona.</p>

                                                                <p>Tutor de residentes de Oncología Médica entre 2015 y 2017. Hospital Universitari Parc Taulí, Sabadell.</p>

                                                                <p>Coordinador de Investigación del Área de Cáncer. Parc Taulí Hospital Universitari. Institut d’Investigació i Innovació Parc Taulí I3PT.</p>

                                                                <p>Miembro del Comité Institucional de Investigación e Innovación. Parc Taulí Hospital Universitari. Institut d’Investigació i Innovació Parc Taulí I3PT.</p>

                                                                <p>Miembro del Comité Institucional para la Mejora de la Práctica Clínica. Parc Taulí Hospital Universitari. Institut d’Investigació i Innovació Parc Taulí I3PT. </p>

                                                                <p>Autor o co-autor de más de cien artículos, comunicaciones y capítulos de libros a nivel nacional e internacional. </p>

                                                                <p>Amplia experiencia investigadora clínica desde 1996, con participación en gran número de ensayos clínicos como investigador principal y subinvestigador, en fase I, II, III y IV en diversas áreas en Oncología. </p>

                                                                <p>Miembro activo de sociedades y grupos cooperativos, como ASCO, ESMO, EORTC, SEOM, SOGUG, GEINO y GETHI. </p>

                                                                <p>Vocal de la Junta Directiva del SOGUG entre 2005 y 2009. Secretario de la Junta Directiva del SOGUG entre 2015 y 2018. Desde noviembre de 2018, y en la actualidad, Vice-presidente de la Junta Directiva del SOGUG. </p>

                                                                <p>Sus áreas de especialización incluyen el tratamiento de tumores urológicos, del sistema nervioso central y endocrinológico, así como la enfermedad tromboembólica relacionada con el cáncer.</p>



                                                            </div>



                                                            : props.speaker_cv == "Elena-M-Pina-Pascual" ?

                                                                <div className="speakers-cv-tromboencuentros2021">
                                                                    <h3><strong>Dra. Elena Mª Pina Pascual</strong><br />Servicio de Hematología y Hemoterapia. Hospital Universitario de Bellvitge, Barcelona.</h3>
                                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Elena-M-Pina-Pascual.jpg" /><br />


                                                                    <p>Licenciada en Medicina y Cirugía por la Universidad Autónoma de Barcelona 9/8/1999. Especialista en Hematología y Hemoterapia a través del programa MIR en el Hospital Universitario de  Bellvitge 19/07/2005. Desde 2005 Adjunto del Servicio de Trombosis y Hemostasia del Hospital Universitario de Bellvitge. Coordinadora de la Unidad Funcional de la enfermedad tromboembólica venosa del Hospital de Bellvitge desde diciembre del 2007. Miembro de la comisión de Trombosis asociada a cáncer del Institut Català d’’Oncologia (ICO).</p>

                                                                </div>


                                                                : props.speaker_cv == "Ignacio-Leal-Lorenzo" ?

                                                                    <div className="speakers-cv-tromboencuentros2021">
                                                                        <h3><strong>Dr. Ignacio Leal Lorenzo</strong><br />Servicio de Cirugía Vascular. Clínica Universidad de Navarra, Madrid.</h3>
                                                                        <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Ignacio-Leal-Lorenzo.jpg" /><br />

                                                                        <p>Responsable Departamento Cirugía Vascular  Clínica Universidad de Navarra -Sede Madrid</p>
                                                                        <p>Profesor Clínico Asociado – Cardiología y Cirugía Cardiovascular</p>
                                                                        <p>Secretario de la Comisión Evaluadora del Capítulo de Diagnóstico Vascular de la Sociedad Española de Angiología y Cirugía Vascular.</p>
                                                                        <p>Licenciado en Medicina por la Universidad de Oviedo en el año 2005. Doctor en Ciencias Biomédicas por la Universidad Complutense de Madrid, Premio Extraordinario de Doctorado en el año 2012. Programa de Postgrado-Máster en Estadística para Ciencias de la Salud y Metodología de la Investigación Científica en la Universidad Autónoma de Barcelona y Erasmus Medical Center de Rotterdam. Ha participado como Investigador Principal en 10 proyectos de investigación internacionales, cuenta con más de 25 publicaciones en revistas indexadas y más de 50 colaboraciones en reuniones internacionales como ponente invitado. Centra su interés asistencial en el Diagnóstico No Invasivo de la patología vascular (Certificado RPVI por el colegio Americano de Ultrasonografía) y los procedimientos terapéuticos endovasculares. </p>


                                                                    </div>




                                                                    : props.speaker_cv == "Nuria-Ruiz-Gimenez-Arrieta" ?

                                                                        <div className="speakers-cv-tromboencuentros2021">
                                                                            <h3><strong>Dra. Nuria Ruiz-Giménez Arrieta</strong><br />Servicio de Medicina Interna. Hospital Universitario de la Princesa, Madrid.</h3>
                                                                            <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Nuria-Ruiz-Gimenez-Arrieta.jpg" /><br />


                                                                            <p>Licenciada en Medicina y Cirugía por la UAM en 1996. Especialista en Medicina Interna desde 2002 (Residencia en el Hospital La Princesa- Madrid). Doctora en Medicina por la Universidad  Autónoma de Madrid desde 2004. Diplomatura en Atención Especializada al paciente de Edad Avanzada y pluripatológico en el 2010. Curso online Oncogeriatría: Abordando la oncología en nuestros mayores  ( octubre de 2020- marzo de 2021). </p>
                                                                            <p>Trabajando como adjunta de Medicina Interna en el hospital Universitario de La Princesa. Madrid desde el año 2004. Responsable de la Unidad de Enfermedad Tromboembólica Venosa desde 2010.  Presidenta de la Comisión multidisciplinar de ETEV del Hospital Universitario de La Princesa desde 2019.</p>

                                                                            <p>Profesora Asociada en Ciencias de la Salud del departamento de Medicina de la Facultad de Medicina Universidad Autónoma de Madrid desde 2013. Coordinadora Docente de la asignatura de Geriatría de 5ª de Grado en el Hospital de la Princesa desde el curso 2013-2014. Profesora de Curso Esp.Universitario sobre  Enfermedad tromboembólica venosa y cáncer de la Universidad de Alcala. 4 ediciones. </p>

                                                                            <p>Integrante del Grupo de Trabajo de Enfermedad Tromboembólica de la Sociedad Española Medicina Interna (SEMI) e Investigadora del “Registro informatizado de pacientes con enfermedad tromboembólica venosa en España” (RIETE) desde octubre 2004.  Investigadora de Instituto de Investigación Hospital Universitario de La Princesa en la línea de Investigación “Nuevos avances diagnósticos y terapéuticos en enfermedades cardiovasculares”.</p>



                                                                        </div>

                                                                        : props.speaker_cv == "Olga-Gavin-Sebastian" ?

                                                                            <div className="speakers-cv-tromboencuentros2021">
                                                                                <h3><strong>Dra. Olga Gavín Sebastián</strong><br />Servicio de Hematología. Hospital Clínico Universitario Lozano Blesa, Zaragoza.</h3>
                                                                                <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Olga-Gavin-Sebastian.jpg" /><br />

                                                                                <p>Licenciado en Medicina y Cirugía en 1999</p>
                                                                                <p>Doctorado el 6 Noviembre 2007</p>
                                                                                <p>Título de Especialista en Medicina y Cirugía en 2006</p>

                                                                                <p>Miembro de la Asociación Española de Hematología y Hemoterapia (AEHH)</p>
                                                                                <p>Miembro de la Sociedad Española de Trombosis y Hemostasia (SETH)</p>
                                                                                <p>Miembro de la Sociedad Aragonesa de Hematología y Hemoterapia</p>
                                                                                <p>Miembro del Grupo de Trabajo de Trombosis y Hemostasia de la Sociedad Aragonesa de Hematología y Hemoterapia</p>
                                                                                <p>Miembro del Grupo de Ictus de Aragón</p>
                                                                                <p>Profesora asociada extraordinaria de la Facultad de Medicina </p>
                                                                                <p>Vocal de la Comisión de Trombosis del HCU</p>
                                                                                <p>Vocal de la junta directiva de la SETH</p>
                                                                                <p>Responsable técnico de Calidad área de Coagulación deL Servicio de Hematología y Hemoterapia</p>
                                                                                <p>Coordinador de numerosos cursos en el ámbito de la Trombosis y la Hemostasia</p>
                                                                                <p>Investigador principal e investigador colaborador en varios proyectos de investigación </p>
                                                                                <p>Participante como primer firmante en proyectos de mejora de calidad del Salud </p>
                                                                                <p>Ponente en numerosas reuniones, simposium, y congresos en el area de la Trombosis y Hemostasia</p>

                                                                                <p><strong>Cargo asistencial:</strong> Médico Adjunto (FEA) desde 2004 Servicio de Hematología-Hemoterapia. Hospital Clínico Universitario Lozano Blesa. Zaragoza</p>

                                                                            </div>


                                                                            : props.speaker_cv == "Vanesa-Roldan-Schilling" ?

                                                                                <div className="speakers-cv-tromboencuentros2021">
                                                                                    <h3><strong>Dra. Vanesa Roldán Schilling</strong><br />Servicio de Hematología y Hemoterapia. Hospital Morales Meseguer, Murcia.</h3>
                                                                                    <img className="tromboencuentros2021-pic-ponente" src="https://www.emma.events/static/virtualvenue/etev2021/speakers/Vanesa-Roldan-Schilling.jpg" /><br />

                                                                                    <p>Licenciada y Doctorada en Medicina por la Universidad de Granada. Realizó su Residencia en Hematología en el Hospital General Universitario de Alicante y desde 2005 trabaja en el Sº de Hematología y Oncología Médica del Hospital Universitario Morales Meseguer de Murcia, donde ocupa una plaza de Profesor Titular Vinculado de la Universidad de Murcia, siendo responsable de la Unidad de Trombosis y Hemostasia. Ha sido vocal de la Sociedad Española de Trombosis y Hemostasia y actualmente está vinculad al Grupo de Trabajo Cardiovascular de la Sociedad Española de Cardiología. Es Editor Asociado de la revista Thrombosis and Haemostasis. Ha dirigido 21 tesis doctorales y ha publicado más de 200 artículos en revistas indexadas. Ha participado en las Guías para el uso de anticoagulantes orales de acción directa, de la Sociedad Europea de Arritmias vinculada a la Sociedad Europea de Cardiología. En el ámbito nacional ha participado en el “Documento de consenso sobre el manejo perioperatorio de pacientes con tratamiento antitrombótico” y en las  “Recomendaciones sobre el tratamiento antitrombótico durante la pandemia COVID-19. Posicionamiento del Grupo de Trabajo de Trombosis Cardiovascular de la Sociedad Española de Cardiología”, ambas coordinadas desde la Sociedad Española de Cardiología. Sus principales líneas de investigación están ligadas al riesgo trombótico y hemorrágico en la fibrilación auricular y el tratamiento antitrombótico. </p>

                                                                                </div>

                                                                                :

                                                                                null}



                    </div>
                    <div className="legal-text-btn-container">
                        <div onClick={props.ononSpeakersCVClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                    </div>
                </div>

                : null
    );
}

export default SpeakersCV;