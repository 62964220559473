import React from 'react';

function LoginRecover(props) {
    return (
        <div className="login-area-container">
            <span className="login-title">Gracias!</span>

            <div className="form-fields-login-separator"></div>

            <div className="avatar-login-container">
                <img src="/img/login-recover-psw-ok-icon.png" alt="" /><br />
                        
            </div>

            <div className="form-fields-login-separator"></div>

            <div className="form-fields-login-separator"></div>

            <div className="login-recover-password-ok-instructions">
                Por favor revise<br />su correo electrónico!
            </div>
        </div>
    );
}

export default LoginRecover;