import React from 'react';
import { useTranslation } from 'react-i18next';

function StandDisclaimer(props) {

    const { t, i18n } = useTranslation();

    return (
        <div className="backdrop-disclaimer-text">
            <div className="stand-disclaimer-container">
                <p>{props.text}</p>
                {props.warningMedical == true ?
                    <div>
                        <p>&nbsp;</p>
                        <p>{props.warningMedicalText}</p>
                        <div className="btngroup">
                            <div onClick={props.onWarningClose} className="stand-disclaimer-close-btn-container">Si</div>
                            <div onClick={props.onwarningMedicalNegativeHandler} className="stand-disclaimer-close-btn-container">No</div>
                        </div>
                    </div>
                    :
                    <div onClick={props.onWarningClose} className="stand-disclaimer-close-btn-container">{t('stand_gallery_close')}</div>
                }




            </div>
        </div>
    );
}

export default StandDisclaimer;