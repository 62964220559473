import React, { Component } from 'react';
import AuthContext from '../../context/auth-context';
import axios from "axios";
import configData from '../../config.json';
import io from "socket.io-client";
import { Link } from 'react-router-dom';

class Questions extends Component {
    
    state = {
        questions: [],
    }

    static contextType = AuthContext;

    // props: sessionId

    componentDidMount(){
        this.initSocketConnection();
        this.setupSocketListeners();
    }

    initSocketConnection() {
        
        const server = configData.SOCKET_API_VOTING + "/voting" ;
        this.socket = io.connect(server, {transports: [ 'websocket' ], query: {token: this.context.token} });
        this.socket.on('connect', () => {
            this.socket.emit('admin-questions', { session_id: this.props.match.params.sessionId });
        });
        
    }

    setupSocketListeners() {
        // recebe todas as que estao guardadas
        this.socket.on("admin-questions", data => {            
            let questions = [];
            data.forEach(element => {
                questions.push(element);
            });
            this.setState({ questions:questions}); 
        });
        // adiciona uma mensagem nova, se ainda nao estiver

        this.socket.on("active", data => {
            this.socket.emit('admin-questions', { session_id: this.props.match.params.sessionId });
        });
        // remove uma mensagem
        this.socket.on("inactive", data => {
            this.socket.emit('admin-questions', { session_id: this.props.match.params.sessionId });
        });
        // substitui uma mensagem ao abrir
        this.socket.on("open", data => {
            this.socket.emit('admin-questions', { session_id: this.props.match.params.sessionId });
        });
        // substitui uma mensagem ao fechar
        this.socket.on("close", data => {
            this.socket.emit('admin-questions', { session_id: this.props.match.params.sessionId });
        });

      }

     onAction = (action, showResults, question_id)  => {
        const action_json = {            
            question_id: question_id,            
            show_results: showResults
        }        
        this.socket.emit(action, action_json);
     }

    render() {

        const renderQuestions = this.state.questions.map((el, index) => {            
            const linkpath = "https://www.e-congress.events/admin/sessions/" + this.props.match.params.sessionId +"/questions/" + el._id
            const link = <a target='_blank' href={linkpath}> [RESULTS PAGE] </a>
            
            const activeButton = el.active 
                ? <button disabled >show</button>
                : <button onClick={() => this.onAction("active", false, el._id)}>show</button>
            
            const activeResultsButton = el.active 
                ? <button disabled >show results</button>
                : <button onClick={() => this.onAction("active", true, el._id)}>show results</button>

            const inactiveButton = !el.active 
                ? <button disabled >hide</button>
                : <button onClick={() => this.onAction("inactive", false, el._id)}>hide</button>

            const openButton = el.open
                ? <button disabled >open</button>
                : <button onClick={() => this.onAction("open", false, el._id)}>open</button>

            const closeButton = !el.open
                ? <button disabled >close</button>
                : <button onClick={() => this.onAction("close", false, el._id)}>close</button>
            
            return (
            <React.Fragment>
            <p key={el._id}>
                {el.group}<br/>
                <b>{el.question}</b><br/>
                {activeButton} | 
                {activeResultsButton} |
                {inactiveButton} | 
                {openButton} |
                {closeButton}                
                {link}
            </p>            
            </React.Fragment>
            )
        })

        return (<div>{renderQuestions}</div>);
    }
}

export default Questions;