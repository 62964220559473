
import React, { Component } from 'react';
import {connect} from 'react-redux'
import {BrowserRouter, Route} from 'react-router-dom';
import Entrance from './containers/Entrance/Entrance';
import Congress from './containers/Congress/Congress';
import Admin from './containers/Admin/Admin'
import Question from './containers/Admin/Question'
import Questions from './containers/Admin/Questions'
/* import Assistant from './containers/Assistant/Assistant'; */
/* import configData from './config.json'; */
import AuthContext from './context/auth-context';
import jwt from 'jsonwebtoken';
import Room from './containers/Room/Room'
import Meeting from './containers/Meeting/Meeting'
/* import RoomTest from '../src/containers.test/Room/Room' */
import Welcome from './containers/Welcome/Welcome';
/* import io from "socket.io-client"; */
import {connectSocket} from './store/actions/global';
import {updateLive} from './store/actions/live';
/* import {getUA, isMobileOnly, isTablet, isDesktop,
  osVersion, osName, fullBrowserVersion, browserVersion,
  browserName, mobileVendor, mobileModel, engineName, engineVersion, deviceType} from "react-device-detect"; */
import HospitalityMeeting from './containers/HospitalityMeeting/HospitalityMeeting';

class App extends Component {
  
  static contextType = AuthContext;

  componentDidMount(){      

    // componentDidMount is called after the Render method
    // so the context is set there and can be used here 
    this.props.onConnectSocket(this.context.token);

    if (this.context.token){
      this.props.onUpdateLive(this.context.token);
    }

  }


  render()  {

    /* const device = {
      type:isMobileOnly ? 'Mobile' : isTablet ? 'Tabled' : isDesktop ? 'Desktop' : 'Console',
      userAgent:getUA,
      device:{
        deviceType:deviceType,
        osName:osName,
        osVersion:osVersion,
        mobileVendor:mobileVendor,
        mobileModel:mobileModel
      },
      browser:{
        fullBrowserVersion:fullBrowserVersion,
        browserVersion:browserVersion,
        browserName:browserName,
        engineName:engineName,
        engineVersion:engineVersion
      }
    } */
    


    // tries to login based on the token stored locally
    if (this.context.token === null || this.context.token === "" || this.context.token === undefined) {
      
      const storedToken = localStorage.getItem('e-congress.events.token');

      if (storedToken) {
        const decoded = jwt.decode(storedToken, {complete: true});
        if (decoded){
          this.context.token = storedToken;
          this.context.authenticated = true;
          this.context.exp = decoded.payload.exp;
          this.context.id = decoded.payload.id;
          this.context.email = decoded.payload.email;
          this.context.event_id = decoded.payload.event_id;
          this.context._id = decoded.payload._id;
          this.context.isAdmin = decoded.payload.isAdmin;
          this.context.profile = decoded.payload.profile;
        }
      }

    }

    // tries to complete the settings based on settins stored... if not possible
    if (this.context.event_code === null || this.context.event_code === "" || this.context.event_code === undefined) {
      const storedSettings = localStorage.getItem('e-congress.events.settings');
      if (storedSettings) {
        this.context.event_code = JSON.parse(storedSettings).code;
        this.context.event_settings = JSON.parse(storedSettings);
      }
    }
    
    return (      
      <BrowserRouter>
        <Route path="/" exact component={Welcome}></Route>
        <Route path="/congress" component={Congress}></Route>
        <Route path="/room/:roomId/:sessionId" component={Room}></Route>
        {/* <Route path="/room.test/:roomId/:sessionId" component={RoomTest}></Route> */}
        {/* <Switch>
          <Route path="/room/:roomId/:sessionId" component={Room}></Route>
          <Route path="/room/:roomId/" component={Room}></Route>
        </Switch> */}
        <Route path="/:congress" exact component={Entrance}></Route>
        {/* <Route path="/meeting/:meetingId" exact component={Meeting}></Route> */}
        <Route path="/meetings/:meetingId" exact component={HospitalityMeeting}></Route>
        <Route path="/admin/6009c8dd7998d0652435ce65" exact component={Admin}></Route>
        <Route path="/admin/sessions/:sessionId/questions/:questionId" exact component={Question}></Route>
        <Route path="/admin/sessions/:sessionId/questions" exact component={Questions}></Route>
      </BrowserRouter>  
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onConnectSocket: (token) => dispatch(connectSocket(token)),
      onUpdateLive: (token) => dispatch(updateLive(token))
  }
}

export default connect(null, mapDispatchToProps)(App);

