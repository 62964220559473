import React, { Component } from 'react';
import configData from '../../config.json';
import AuthContext from '../../context/auth-context';
import axios from 'axios';

class SurveySession extends Component {

    state = {
        complete: false,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        q9: null,
        q10: null,
        q11: null,
        q12: null,
    }

    static contextType = AuthContext;


    componentDidMount() {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/users/me/survey/session/" + this.props.sessionId, data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    this.setState({ complete: true })  // the user has already sent the session survey    
                }
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    onSurveySaveHandler = () => {


        let config = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };

        const mySurvey = {
            user_id: this.context.id,
            session_id: this.props.sessionId,
            q1: this.state.q1,
            q2: this.state.q2,
            q3: this.state.q3,
            q4: this.state.q4,
            q5: this.state.q5,
            q6: this.state.q6,
            q7: this.state.q7,
            q8: this.state.q8,
            q9: this.state.q9,
            q10: this.state.q10,
            q11: this.state.q11,
            q12: this.state.q12,
        }


        if (this.state.q1 !== null &&
            this.state.q2 !== null &&
            this.state.q3 !== null &&
            this.state.q4 !== null &&
            this.state.q5 !== null &&
            this.state.q6 !== null &&
            this.state.q7 !== null) {

            axios.post(configData.SERVER_API + '/users/me/survey/session/' + this.props.sessionId, mySurvey, config)
                .then(response => {
                    //this.setState({complete:true})
                    this.props.evaluateClose();
                })
                .catch(error => {
                    //console.log(error)
                })
        } else {
            alert('Por favor complete todas las preguntas.')
        }

    }

    onSetAnwserHandler = (event) => {
        switch (event.target.name) {
            case "credits-form1":
                this.setState({ q1: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form2":
                this.setState({ q2: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form3":
                this.setState({ q3: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form4":
                this.setState({ q4: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form5":
                this.setState({ q5: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form6":
                this.setState({ q6: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form7":
                this.setState({ q7: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form8":
                this.setState({ q8: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form9":
                this.setState({ q9: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form10":
                this.setState({ q10: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form11":
                this.setState({ q11: event.target.value !== "" ? event.target.value : null })
                break;

            default:
                break;
        }
    }

    onCommentsHandler = (text) => {
        this.setState({ q12: text });
    }

    onSubmit(event) {
        event.preventDefault();
    }


    render() {

        let sessionTitle;
        switch (this.props.sessionId) {
            case "5fa29fc56fdac74418a80a96":
                sessionTitle = "PLENARIA I";
                break;

            case "5fa29fc56fdac74418a80a9e":
                sessionTitle = "PLENARIA II";
                break;

            case "5fa29fc56fdac74418a80a97":
                sessionTitle = "PRO CON I";
                break;

            case "5fa29fc56fdac74418a80a9f":
                sessionTitle = "PRO CON II";
                break;

            case "5fa29fc56fdac74418a80a98":
                sessionTitle = "SEMINARIO";
                break;




            case "60487955aec8de2520bd0124":
                sessionTitle = "Jornada de Jóvenes Investigadores";
                break;

            case "60487955aec8de2520bd0123":
                sessionTitle = "Mesa Redonda: Tratamiento de la infección comunitaria: Entre expertos anda el juego";
                break;

            case "60487955aec8de2520bd012f":
                sessionTitle = "Inauguración: Conferencia inaugural 20 años de SEIP";
                break;

            case "60487955aec8de2520bd012c":
                sessionTitle = "Mesa Redonda: Resistencias antibióticas. PROA Pediátrico";
                break;

            case "60487955aec8de2520bd0133":
                sessionTitle = "Mesa Redonda: Patología importada";
                break;

            case "60487955aec8de2520bd0128":
                sessionTitle = "Encuentros con los expertos: Infecciones en pacientes especiales.";
                break;

            case "60487955aec8de2520bd0130":
                sessionTitle = "Encuentros con los expertos: Infección fúngica invasora";
                break;

            case "60487955aec8de2520bd012e":
                sessionTitle = "Abordaje de la Infecciones causadas por Gram+";
                break;

            case "60487955aec8de2520bd0137":
                sessionTitle = "Saturday Monrning Fever: Casos clínicos de Fiebre en Pediatría.";
                break;

            case "60487955aec8de2520bd0139":
                sessionTitle = "Mesa Redonda: Meningococo: novedades buenas y malas.";
                break;

            case "60487955aec8de2520bd013b":
                sessionTitle = "Conferencia Magistral: Un mundo sin VIH";
                break;

            case "60487955aec8de2520bd0135":
                sessionTitle = "Chagas congénito";
                break;

            case "60487955aec8de2520bd0138":
                sessionTitle = "Casos Clínico: Infección por VIH. Reunión conjunta con PLANTAIDS";
                break;



            case "609e821b175a011f0c4a5c7a":
                sessionTitle = "Inauguración Grande Covián";
                break;

            case "609e821b175a011f0c4a5c7b":
                sessionTitle = "Mesa Nutrición";
                break;

            case "609e821b175a011f0c4a5c7f":
                sessionTitle = "Mesa de Atención Primaria y Diabetes";
                break;

            case "609e821b175a011f0c4a5c83":
                sessionTitle = "Mesa U de Lípidos y Registro. El laboratorio y las pruebas de imagen en el proceso diagnóstico de las hiperlipemias";
                break;

            case "609e821b175a011f0c4a5c8d":
                sessionTitle = "Mesa biología vascular";
                break;

            case "609e821b175a011f0c4a5c89":
                sessionTitle = "Mesa Prevención Secundaria. Nuevas perspectivas en la prevención secundaria de la aterosclerosis prematura";
                break;

            case "609e821b175a011f0c4a5c8b":
                sessionTitle = "Mesa Dislipemia Aterogénica";
                break;

            case "609e821b175a011f0c4a5c7d":
                sessionTitle = "Mesa Redonda. ¿Qué no deberíamos hacer en pacientes con...?";
                break;


            default:
                break;
        }

        return (
            <div className="backdrop-disclaimer-text">
                <div className="session-survey-container">


                    {!this.state.complete ?

                        this.context.event_settings.event_id == "289" ?

                            <form className="form-my-credits form-session-survey" onSubmit={this.onSubmit}>

                                <p>Responda puntuando de 0 (mínimo) – a 10 (máximo)</p>

                                <div className="input-wrapper">
                                    <label htmlFor="credits-form1">Aplicabilidad de los conocimientos/habilidades adquiridos al puesto de trabajo:</label>
                                    <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>

                                <div className="input-wrapper">
                                    <label htmlFor="credits-form2">Adecuación de los horarios (clases/descansos) y duración de la actividad:</label>
                                    <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form3">Adecuación de los medios utilizados (presentaciones, material, vídeos, etc):</label>
                                    <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form4">Consecución de los objetivos propuestos en la actividad formativa:</label>
                                    <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form5">Adecuación de la metodología docente (contenido docente, ejercicios, participación...) de la actividad formativa:</label>
                                    <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form6">Satisfacción global con la actividad formativa:</label>
                                    <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form7">Recomendaría la actividad formativa: </label>
                                    <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form8">Valoración del ponente 1:</label>
                                    <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form9">Valoración del ponente 2:</label>
                                    <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form10">Valoración del ponente 3:</label>
                                    <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form11">Valoración de la moderación (si la hubiera):</label>
                                    <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                        <option value="" defaultValue>Por favor seleccione</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>


                                <div className="input-wrapper">
                                    <label htmlFor="credits-form12">Sugerencias y/o Comentarios: </label>
                                    <textarea id="credits-form12" name="credits-form12" rows="10" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value)}></textarea>
                                </div>

                                <div className="input-wrapper">
                                    <input type="submit" value="Enviar" className="btn-submit" onClick={this.onSurveySaveHandler} />
                                    &nbsp;
                                    <div className="stand-disclaimer-close-btn-container" onClick={this.props.evaluateClose} >Cerrar</div>
                                </div>


                            </form>


                            : this.context.event_settings.event_id == "391" ?


                                <form className="form-my-credits form-session-survey" onSubmit={this.onSubmit}>

                                    <p><strong>HOJA DE EVALUACIÓN DE LA SESIÓN {sessionTitle} </strong></p>

                                    <p>Señale su grado de acuerdo con las siguientes afirmaciones teniendo en cuenta que puntuar 0 supone estar totalmente en desacuerdo y puntual 10 supone estar totalmente de acuerdo. Recuerde que sus respuestas son anónimas.</p>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form1">Aplicabilidad de los conocimientos/habilidades adquiridos al puesto de trabajo:</label>
                                        <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form2">Adecuación de los horarios (clases/descansos) y duración de la actividad:</label>
                                        <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form3">Adecuación de los medios utilizados (presentaciones, material, vídeos, etc):</label>
                                        <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form4">Consecución de los objetivos propuestos en la actividad formativa:</label>
                                        <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form5">Adecuación de la metodología docente (contenido docente, ejercicios, participación...) de la actividad formativa:</label>
                                        <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form6">Satisfacción global con la actividad formativa:</label>
                                        <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form7">Recomendaría la actividad formativa:</label>
                                        <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form8">Valoración del ponente 1:</label>
                                        <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form9">Valoración del ponente 2:</label>
                                        <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form10">Valoración del ponente 3:</label>
                                        <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form11">Valoración de la moderación:</label>
                                        <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form12">Sugerencias y/o Comentarios:</label>
                                        <textarea id="credits-form12" name="credits-form12" rows="10" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value)}></textarea>
                                    </div>

                                    <div className="input-wrapper">
                                        <input type="submit" value="Enviar" className="btn-submit" onClick={this.onSurveySaveHandler} />
                        &nbsp;
                        <div className="stand-disclaimer-close-btn-container" onClick={this.props.evaluateClose} >Cerrar</div>
                                    </div>

                                </form>


                                :



                                <form className="form-my-credits form-session-survey" onSubmit={this.onSubmit}>

                                    <p><strong>HOJA DE EVALUACIÓN DE LA SESIÓN {sessionTitle} </strong></p>

                                    <p>Señale su grado de acuerdo con las siguientes afirmaciones teniendo en cuenta que puntuar 0 supone estar totalmente en desacuerdo y puntual 10 supone estar totalmente de acuerdo. Recuerde que sus respuestas son anónimas.</p>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form1">Aplicabilidad de los conocimientos/habilidades adquiridos al puesto de trabajo:</label>
                                        <select name="credits-form1" id="credits-form1" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form2">Adecuación de los horarios (clases/descansos) y duración de la actividad:</label>
                                        <select name="credits-form2" id="credits-form2" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form3">Adecuación de los medios utilizados (presentaciones, material, vídeos, etc):</label>
                                        <select name="credits-form3" id="credits-form3" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form4">Consecución de los objetivos propuestos en la actividad formativa:</label>
                                        <select name="credits-form4" id="credits-form4" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form5">Adecuación de la metodología docente (contenido docente, ejercicios, participación...) de la actividad formativa:</label>
                                        <select name="credits-form5" id="credits-form5" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form6">Satisfacción global con la actividad formativa:</label>
                                        <select name="credits-form6" id="credits-form6" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form7">Recomendaría la actividad formativa:</label>
                                        <select name="credits-form7" id="credits-form7" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form8">Valoración del ponente 1 (si lo hubiera):</label>
                                        <select name="credits-form8" id="credits-form8" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form9">Valoración del ponente 2 (si lo hubiera):</label>
                                        <select name="credits-form9" id="credits-form9" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form10">Valoración del ponente 3 (si lo hubiera):</label>
                                        <select name="credits-form10" id="credits-form10" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form11">Valoración de la moderación (si la hubiera):</label>
                                        <select name="credits-form11" id="credits-form11" onChange={(event) => this.onSetAnwserHandler(event)}>
                                            <option value="" defaultValue>Por favor seleccione</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>

                                    <div className="input-wrapper">
                                        <label htmlFor="credits-form12">Comentarios u observaciones:</label>
                                        <textarea id="credits-form12" name="credits-form12" rows="10" cols="50" defaultValue="" onChange={(event) => this.onCommentsHandler(event.target.value)}></textarea>
                                    </div>

                                    <div className="input-wrapper">
                                        <input type="submit" value="Enviar" className="btn-submit" onClick={this.onSurveySaveHandler} />
                        &nbsp;
                        <div className="stand-disclaimer-close-btn-container" onClick={this.props.evaluateClose} >Cerrar</div>
                                    </div>

                                </form>

                        :
                        <form className="form-my-credits form-session-survey">

                            <p><strong>HOJA DE EVALUACIÓN DE LA SESIÓN {sessionTitle} : COMPLETADA </strong></p>

                            <div className="input-wrapper">
                                <div className="stand-disclaimer-close-btn-container" onClick={this.props.evaluateClose} >Cerrar</div>
                            </div>

                        </form>
                    }

                </div>
            </div>
        );
    }
}

export default SurveySession;