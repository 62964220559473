import React from 'react';

function StandLogo(props) {

    const logo = props.link !== undefined && props.link !== ""
        ? <a href={props.link} target="_blank"><img alt="" src={props.logo} /></a>
        : <img alt="" src={props.logo} />
    return (
        <div className="industria-logo-entry-container">{logo}</div>
    );
}

export default StandLogo;