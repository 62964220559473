import React from 'react';

function FileGalleryFile(props) {
    
    const {type, img, title, desc, format, src} = props.file;
    
    return (
        <div className="flyer-viewer-entry">
            <a href={src} target="_blank"><img alt="" src={img} /></a>
            <div className="flyer-viewer-entry-bottom-icons">
                {/* <img src="/img/video-player-bag-icon.png" />
                <img src="/img/video-player-favourite-icon.png" />
                <img src="/img/video-player-download-icon.png" />
                <img src="/img/video-player-share-icon.png" /> */}
            </div>
        </div>
    );
}

export default FileGalleryFile;