import React, { Component } from 'react';

class Survey extends Component {
    render() {
        return (
            <React.Fragment>
            <div className="econgress-room-accordion-header">
                <div className="econgress-room-accordion-header-icon-container">
                    <img alt="" src="/img/icon-arrow-bottom-accordion.png" />
                </div>
                Encuesta 
            </div>
            <div className="econgress-room-survey-area-container">
                Ut tincidunt sagittis euismod. Phasellus leo leo, imperdiet id molestie a, rutrum id nisl. Donec eget justo quis nisi facilisis fringilla. Vivamus quam erat, luctus eu porttitor at, facilisis sit amet nisi. 
                <br /><br />
                <div className="econgress-room-right-panel-btn">PARTICIPAR</div>
            </div>
            </React.Fragment>
        );
    }
}

export default Survey;