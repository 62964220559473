import React, { Component } from 'react';
import ChatEntry from './ChatEntry';
import axios from "axios";
import io from "socket.io-client";
import AuthContext from '../../../../context/auth-context';
import { withTranslation } from 'react-i18next';
import configData from '../../../../config.json';

class Chat extends Component {

    constructor(props) {
        super();
        this.state = {
            messages: [],
            name: "",
            surname: ""
        }
        this.keyPress = this.keyPress.bind(this);
    }

    state = {
        messages: [],
        name: "",
        surname: ""
    }

    static contextType = AuthContext;

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {

        this.initSocketConnection();
        this.setupSocketListeners();
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    initSocketConnection() {

        const server = configData.SOCKET_API_CHAT + "/chat";
        this.socket = io.connect(server, { transports: ['websocket'], query: { token: this.context.token } });
        this.socket.on('connect', () => {
            this.socket.emit('sign-in', { session_id: this.props.sessionId });
        });

    }

    setupSocketListeners() {
        // adiciona uma mensagem nova
        this.socket.on("message", data => {
            if (data.session_id === this.props.sessionId) {
                let messages = this.state.messages;
                // check if the message is already there                

                messages.push(data);
                this.setState({ messages: messages });
            }
        });
        // adiciona uma mensagem nova
        this.socket.on("messagemessage-rejected", data => {
            if (data.session_id === this.props.sessionId) {
                let messages = this.state.messages;
                messages.push(data);
                this.setState({ messages: messages });
            }
        });
        // recebe todas as que estao guardadas
        this.socket.on("sign-in", data => {
            let messages = [];
            data.forEach(element => {
                messages.push(element);
            });
            this.setState({ messages: messages });
        });
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            if (e.target.value !== "") {

                const name = this.props.userName ? this.props.userName : "";
                const surname = this.props.userSurname ? this.props.userSurname : "";

                const message = {
                    session_id: this.props.sessionId,
                    user_id: this.context.id,
                    message: e.target.value,
                    approved: this.props.moderated !== undefined ? !this.props.moderated || this.props.isModerator : true,
                    name: name + ' ' + surname
                }
                this.socket.emit('message', message);
                e.target.value = '';
            }
        }
    }

    approveHandler = (id) => {
        // send notification to update state and repost the message
        this.socket.emit('message-approved', { _id: id });
        // remove the message from the current list
        const updateMessages = this.state.messages.filter((el) => el._id !== id)
        this.setState({ messages: updateMessages })
    }

    render() {

        let msgBlock = [];
        if (this.state.messages !== undefined && this.state.messages.length > 0) {
            this.state.messages.forEach(element => {
                if (this.props.isModerator) {
                    if (element.approved || element.user_id === this.context.id) {
                        msgBlock.push(<ChatEntry key={element._id} id={element._id} approveHandler={this.approveHandler} isModerator={true} pic={element.photo} name={element.name} text={element.message} date={element.date} approved={true} />)
                    } else {
                        msgBlock.push(<ChatEntry key={element._id} id={element._id} approveHandler={this.approveHandler} isModerator={true} pic={element.photo} name={element.name} text={element.message} date={element.date} approved={element.approved} />)
                    }
                } else {
                    if (element.approved || element.user_id === this.context.id) {
                        msgBlock.push(<ChatEntry key={element._id} id={element._id} approveHandler={this.approveHandler} isModerator={false} pic={element.photo} name={element.name} text={element.message} date={element.date} approved={true} />)
                    }
                }
            });
        }

        return (
            <React.Fragment>
                <div className="econgress-room-accordion-header">
                    <div className="econgress-room-accordion-header-icon-container">
                        <img alt="" src="/img/icon-arrow-bottom-accordion.png" />
                    </div>
                    {this.context.event_id == "387" || this.context.event_id == "2" || this.context.event_id == "3" || this.context.event_id == "4" || this.context.event_id == "5" ?
                        <span>Preguntas</span>
                        :
                        <span>Chat</span>}
                </div>
                <div className={`econgress-room-chat-area-container ` + this.props.classBlock}>

                    <div className="econgress-room-chat-text-input-container">
                        {/* <div className="econgress-room-chat-avatar">
                        <img alt="" src="/img/default-login-pic.png" />
                    </div> */}
                        <div className="econgress-room-chat-input-container">
                            <input onKeyDown={this.keyPress} type="text" id="econgress-room-chat-input" name="econgress-room-chat-input" placeholder={this.props.t('type_comment')} />
                        </div>
                    </div>

                    <div className="econgress-room-chat-content-container" id="room-chat-scroll-to-end">

                        {msgBlock}

                        <div style={{ float: "left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Chat);

