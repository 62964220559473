import React, { Component } from 'react';
import ChatHeader from '../../../components/Chat/ChatHeader';
import ChatFooter from '../../../components/Chat/ChatFooter';
import ChatEntry from '../../../components/Chat/ChatEntry';

class Chat extends Component {
    
    state = {show:this.props.isClosed}
    
    onShowHideHandler = () => {
        this.setState((prevState, props) => {
            return {
                show: !prevState.show
            };
        })
    }

    render() {

        const chat = this.state.show
            ?  <ChatHeader title={this.props.title} subtitle={this.props.subtitle} isClosed={true} 
                    showHide={this.onShowHideHandler}></ChatHeader>
            : <React.Fragment>
        
                <ChatHeader title={this.props.title} subtitle={this.props.subtitle} isClosed={false} 
                    showHide={this.onShowHideHandler}></ChatHeader>
        
                <div className="chat-area">
                    
                    <ChatEntry 
                        type="receiver"
                        pic="/img/chat-person-pic1.png"
                        text="Hi. Lorem ipsum dolor sit amet consectetuer gravida eu risus Integer vestibulum."
                        ></ChatEntry>
                    <ChatEntry 
                        type="receiver"
                        text="Yet another text from the same user."
                        ></ChatEntry>
                    <ChatEntry 
                        type="sender"
                        pic="/img/chat-person-pic2.png"
                        text="Hi. Lorem ipsum dolor sit amet consectetuer gravida eu risus Integer vestibulum."
                        ></ChatEntry>          
                </div>
                <ChatFooter></ChatFooter>
                </React.Fragment>

        return (
            <div className="window-chat-wrapper">{chat}</div>
                
        );
    }
}

export default Chat;
