

export const PrivacyPolicyPIEDIABETICO2021 = `
<h3>Política de privacidad</h3>

<div>Su identidad y privacidad son importantes para nosotros. Por eso desde este site, queremos cumplir con la Ley y ofrecerles garantías sobre su privacidad y cómo se van a tratar todos sus datos.</div>


<div>Lo primero de todo es dejar claro que los datos son suyos y por tanto puede recurrir a nosotros para que sean modificados o eliminados o lo que requiera, en lo que corresponde a este sitio web.</div>


<div>Estos términos de uso y política de privacidad serán válidos únicamente para los datos de carácter personal obtenidos en esta web, no siendo aplicable para aquella información recabada por terceros en otras páginas web, incluso si éstas se encuentran enlazadas por el sitio web.</div>


<div>En simposiopiediabetico2021.es hemos adecuado la web a las exigencias de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Así mismo, cumple también con el Reglamento (UE) 2016/679, GDPR y LSSI.</div>


<h3>¿QUIÉN GESTIONA MIS DATOS?</h3>


<div>La web simposiopiediabetico2021.es es responsabilidad de José Luis Lázaro Martínez con NIF 08945512F. El domicilio a efectos de comunicación es Calle de la Comunidad de Madrid, 7
Boadilla del Monte, Madrid 28660 España; Para cualquier duda o gestión de sus datos nos puede localizar en el mail diabetes@ucm.es</div>


<H3>¿QUÉ HACEMOS CON TUS DATOS?</h3>


<div>Trataremos la información que facilite con el fin de responder a las preguntas y consultas recibidas a través de los formularios de contacto y gestionar su inscripción en el evento objeto de esta web. Además de establecer una vinculación comercial segura si adquiere alguno de nuestros servicios. En caso de que así nos lo indique también gestionaremos su suscripción a nuestras listas de correo. Recogemos sus datos, a través de formularios en línea. Estos datos, por lo general serán: nombre, apellidos, teléfono y/o correo electrónico. Cuando nos contrate alguno de nuestros servicios es posible que le solicitemos más datos para establecer los pagos y la facturación.</div>
<div>Por tanto, y en resumen, trataremos sus datos para:</div>


<ul>
    
<li>Responder y/o actuar en consecuencia a la información que nos haga llegar a través de los diferentes formularios de contacto que se encuentren en la web.</li>

    <li>Establecer una relación comercial cercana y confiable.</li>

    <li>Moderar los comentarios que se realicen en las diferentes secciones que permitan la recogida de los mismos.</li>

    <li>Gestión de asistentes al evento y suscriptores.</li>

    <li>Garantizar el cumplimiento de las condiciones de uso y la ley aplicable. Esto puede incluir desarrollo de herramientas y algoritmos que ayudan a esta web a garantizar la confidencialidad de los datos personales que recoge.</li>

</ul>


<div>En ningún caso esta web cederá los datos personales de sus usuarios a terceros ajenos a la organización del congreso sin informar previamente y requerir su consentimiento.</div>



<div>No es necesario que facilite ningún dato de carácter personal para la navegación por esta web.</div>



<h3>LEGITIMACIÓN PARA EL TRATAMIENTO DE TUS DATOS</H3>



<div>La base legal para el tratamiento de sus datos es: <strong>EL CONSENTIMIENTO</strong>. Parece obvio, pero para nosotros es muy importante. Para contactar o realizar comentarios en esta web se requiere su consentimiento con esta política de privacidad, sin él no publicaremos ni recogeremos ningún dato suyo.</div>



<h3>¿COMUNICAMOS A ALGUIEN ESTOS DATOS?</h3>



<div>En un mundo globalizado como es el de internet, a la hora de crear esta web hemos recurrido a software de terceros para un mejor servicio, por lo tanto debo informarte de la relación de <a href="http://simposiopiediabetico2021.es/">simposiopiediabetico2021.es</a> con Viajes el Corte Inglés S.A., nuestra secretaría técnica y la encargada de la gestión de reservas y con 1&1 IONOS España S.L.U., que hospeda la página web, ambas, empresas que cumplen con la RGPD.</div>



<div>No obstante no existe una cesión de datos a los mismos, únicamente se establece esta relación para que puedas acceder a los servicios que brindan.</div>



<h3>SUS DATOS, SU USO Y SUS DERECHOS</h3>



<div>Son sus datos, por tanto tiene derecho a acceder a ellos, así como a pedirnos la rectificación de los que sean erróneos o solicitar que ya no los almacenemos si el motivo de la recopilación de los mismos ha terminado. Si tiene dudas de si en algún momento hemos almacenado algún dato suyo, tiene derecho a obtener confirmación por nuestra parte.</div>



<div>Todo esto nos lleva a confirmar que, si nos lo pide, dejaremos de tratar y almacenar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</div>



<div>Podrá ejercitar materialmente sus derechos por correo electrónico, adjuntando copia del DNI, a: diabetes@ucm.es</div>

<h3>¿CÓMO OBTENEMOS SUS DATOS?</h3>

<div>Mediante su consentimiento, eso ante todo. Y este consentimiento lo confirma en los formularios, al realizar un comentario en alguna sección o al contratar un servicio. Una vez más, gracias por su confianza.</div>



<h3>COMUNICACIÓN COMERCIAL</h3>



<div>De acuerdo con la LSSICE, no vamos a hacer SPAM. Por lo que no te enviaremos emails comerciales si no nos ha autorizado previamente.</div>



<H3>VERACIDAD DE DATOS</H3>



<div>Al igual que nuestra asociación tiene unas obligaciones para con los clientes, por su parte debe garantizar que los datos personales facilitados a través de los diferentes medios de esta web son veraces. Si alguno de los datos recogidos para la relación que mantiene con nosotros cambia, debe notificarlo. Por lo tanto, es importante que toda la información facilitada corresponda con su situación real, que está puesta al día y sea exacta. Si los datos no son exactos, el único responsable de ello es el cliente.</div>



<H3>¿POR CUÁNTO TIEMPO?</H3>



<div>Antes de nada aclarar que si almacenamos los datos es sólo por dos motivos. Por un lado sirve de histórico para consultas y contactos. Por otro lado así no hay que estar registrando todos los datos cada vez que necesite algo de nosotros. Dicho esto, y por motivos fiscales, almacenamos estos datos un periodo de 5 años desde la última vez que accedió a los mismos.</div>



<h3>MODIFICACIONES EN LA POLÍTICA</h3>



<div>En <a target="_blank" href="http://www.simposiopiediabetico2021.es/">simposiopiediabetico2021.es</a> nos reservamos el derecho a modificar esta política, entre otras cosas, para adaptarla a los cambios legales que sucedan y para optimizar la aplicación de la misma en el ámbito comercial. Eso sí, si estos cambian, le informaremos en todo momento para seguir contando con su consentimiento.</div>

`