import React from 'react';
import TextEntry from './TextEntry';
import { useTranslation } from 'react-i18next';
import {useEffect} from 'react'

function AssistantText(props) {
    
    const { t, i18n } = useTranslation(); 

    let msgs;
    if (props.messages !== undefined && props.messages.length > 0){
        msgs = props.messages.map(element => {
            
            const entryType = element.sender_id !== props.userid ? "participant" : "assistant";            

            return <TextEntry key={element._id} entryType={entryType} message={element.message} ></TextEntry>
        });
        scrollToBottom()
    }

    function postMessage(){
        const inputMsg = document.getElementById("virtual-assistant-chat-text");
        const msg = inputMsg.value;
        props.sendMessage(msg);
        inputMsg.value = '';
    }

    function useKeyboardEvent(key, callback) {
        useEffect(() => {
          const handler = function(event) {
            if (event.key === key) {
              callback()
            }
          }
          window.addEventListener('keydown', handler)
          return () => {
            window.removeEventListener('keydown', handler)
          }
        }, [])
      }

    useKeyboardEvent('Enter', () => {
        postMessage();
      })

    function scrollToBottom () {
        const messagesEnd = document.getElementById("messagesEnd")
        if (messagesEnd !== undefined && messagesEnd !== null){
            messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
      }

    return (
        <React.Fragment>
        <div className="virtual-assistant-chat-header">
            <div className="virtual-assistant-icon-network">
                <img alt="" src="/img/icon-chat-network.png" />
            </div>
            {/* <div className="virtual-assistant-sponsored-text">Sponsored by</div>
            <div className="virtual-assistant-company-logo">
                <img alt="" src="/img/chat-company-logo.png" />
            </div> */}
            <div className="virtual-assistant-close-icon cursor-only" onClick={props.close}>
                <img alt="" src="/img/icon-chat-close.png" />
            </div>
        </div>

        <div className="virtual-assistant-chat-subheader">
            <div className="virtual-assistant-chat-avatar">
                <img alt="" src="/img/virtual-assistant-avatar.png" />
            </div>
            <div className="virtual-assistant-chat-avatar-text">
                {/* <span className="virtual-assistant-name">Brad Pitt</span><br />
                <span className="virtual-assistant-desc">e-Congress</span> */}
            </div>
            {/* <div className="virtual-assistant-chat-call-icon cursor-only" onClick={props.voice}>
                <img alt="" src="/img/icon-call-virtual-assistant.png" />
            </div>
            <div className="virtual-assistant-chat-video-icon cursor-only" onClick={props.video}>
                <img alt="" src="/img/icon-video-virtual-assistant.png" />
            </div> */}
        </div>

        <div className="virtual-assistant-chat-area">
            {msgs}
            <div style={{ float:"left", clear: "both" }} id="messagesEnd"></div>
        </div>

        <div className="virtual-assistant-chat-footer">
            <div className="virtual-assistant-chat-input">
                <input type="text" id="virtual-assistant-chat-text" name="virtual-assistant-chat" placeholder={t('type_comment')} />
            </div>
            <div className="virtual-assistant-chat-send-button cursor-only" onClick={() => postMessage()}>
                <img alt="" src="/img/send-message-icon2.png"  />
            </div>
            {/* <div className="virtual-assistant-chat-send-attach">
                <img alt="" src="/img/icon-chat-virtual-assitant-attach-file.png" />
            </div>
            <div className="virtual-assistant-chat-send-photo">
                <img alt="" src="/img/icon-chat-virtual-assitant-photo.png" />
            </div>                 */}
        </div>
        </React.Fragment>
    );
}

export default AssistantText;