
import * as actionTypes from './actionTypes';
import configData from '../../config.json';
import axios from 'axios';

// action creators
export const live = (liveSession) =>{
    return {
        type: actionTypes.LIVE,
        liveSession: liveSession
    }
}

// async action creator to manage async code before dispatch
// not too much login into the action -> minor changes to prepare the stete
// heavy logic goes into the reducer

export const updateLive = ( token ) => {
    return (dispatch) => {
        let data = {
            headers: {
                "x-auth-token": token,
                "content-type": "application/json",
            },
            params: {}
        };
        axios.get(configData.SERVER_API + "/sessions/live/current", data)
        .then(response => {
            dispatch(live(response.data));
        })
        .catch(error => {
            console.log('error');
        })
    }
}