import React, { Component } from 'react';
import configData from '../../config.json';
import AuthContext from '../../context/auth-context';
import axios from 'axios';

class ResetPassword extends Component {

    state = {
        render: 'form',
        password : null, 
        password_check : null 
    }

    static contextType = AuthContext;


    onFormSaveHandler = () => {


        let config = {
            headers: {"content-type": "application/json"}
        };

        const myUser = {       
            event_id : this.context.event_settings.event_id, 
            password : this.state.password,
            reset_code: this.props.resetCode
        }


        if (this.state.password !== null) { 
                if(this.state.password.length > 4){
                    if(this.state.password === this.state.password_check ){
                        
                        axios.post(configData.SERVER_API + '/users/reset/', myUser, config)
                            .then(response => {                    
                                console.log(response.data)
                                if (response.data.error !== undefined && response.data.error !== '0'){
                                    alert(response.data.message)
                                } else {
                                    this.setState({render:'ok'})
                                }
                                
                            })
                            .catch(error => {
                                console.log(error)
                            })
            
                    } else {
                        alert('Las contraseñas no coinciden.')
                    }
                } else {
                    alert('La contraseña debe tener al menos 5 caracteres. ')
                }
                
        } else {
            alert('Por favor complete el formulario de registro.')
        }

    }

    onCommentsHandler2 = (text) => {
        this.setState({ password: text });
    }
    onCommentsHandler3 = (text) => {
        this.setState({ password_check: text });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {

        return (

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container recover-password-container">

                    {this.state.render == 'form' ? 
                    
                    <form className="form-registration" onSubmit={this.onSubmit}>
                        <h3>Definir contraseña</h3>

                        <div className="input-wrapper">
                            <label htmlFor="recover-password">Nueva contraseña:  *</label>
                            <input type="password" id="recover-password" name="recover-password" placeholder="Contraseña" onChange={(event) => this.onCommentsHandler2(event.target.value)}></input>
                        </div>

                        <div className="input-wrapper">
                            <label htmlFor="recover-password-check">Confirmar contraseña:  *</label>
                            <input type="password" id="recover-password-check" name="recover-password-check" placeholder="Contraseña" onChange={(event) => this.onCommentsHandler3(event.target.value)}></input>
                        </div>
                        <p>&nbsp;</p>

                        <div className="input-wrapper" style={{ textAlign: "center" }}>
                            <input type="submit" value="Confirmar" className="btn-submit" onClick={this.onFormSaveHandler}  />
                        </div>

                        <p>&nbsp;</p>
                    </form>

                    :

                    <form className="form-registration" onSubmit={this.onSubmit}>
                        <h3>Definir contraseña</h3>
                        <p>Contraseña cambiada correctamente!</p>
                        <p>Para acceder al congreso virtual deberá cerrar esta ventana y iniciar sesión con usuario y contraseña.</p>
                        <p>Muchas gracias</p>
                    </form>

                    }


                </div>

                <div className="legal-text-btn-container recover-password-btn-container">
                    <div onClick={this.props.onResetPasswordClose} className="stand-disclaimer-close-btn-container">Cerrar</div>
                </div>

            </div>

        );
    }
}

export default ResetPassword;



