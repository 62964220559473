import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';
import SpeakersCV from '../../../components/SpeakersCV/SpeakersCV';
import { Link } from 'react-router-dom';

class NetworkingCuenca2021 extends Component {



    static contextType = AuthContext;

    state = {

    }

    //componentDidMount() {}
    //componentDidUpdate() {}





    render() {



        if (this.context.event_settings.event_id == "2") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/urovi2021/lobby/urovi2021-lobby-bck2.jpg")';
        } else if (this.context.event_settings.event_id == "3") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/encuentrosexperto2021/lobby/encuentrosexperto2021-lobby.jpg")';
        } else if (this.context.event_settings.event_id == "4") {
            document.getElementById('root').style.backgroundImage = 'url("https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby.jpg")';
        }

        return (
            <React.Fragment>


                {this.context.event_settings.event_id == "320" ?
                    <div className="networking-cuenca-container">

                        <div className="networking-btn-text" style={{backgroundColor: "rgb(0, 0, 0)"}}>Click on the thematic area to join the discussion:</div>

                        <Link to="/meetings/612cac4c6c0c8302a6dbf593">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Population Health &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cac726c0c8302a6dbf597">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Health & Disease Surveillance &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cac7e6c0c8302a6dbf598">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Disease Control & Management &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cac8d6c0c8302a6dbf599">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Wildlife-Livestock-Human Interface &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cac996c0c8302a6dbf59a">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Global Changes & Ecosystem Health &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612caca86c0c8302a6dbf59e">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Climate Change & Health &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cacd76c0c8302a6dbf5a2">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Neglected & Emerging Diseases &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cace46c0c8302a6dbf5a3">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Vector-borne Diseases &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cad076c0c8302a6dbf5a7">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Disease Diagnosis &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cad306c0c8302a6dbf5ab">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Clinical Practice &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cad456c0c8302a6dbf5af">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Health Determinants &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cad546c0c8302a6dbf5b3">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Community Engagement in Wildlife Health &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cad896c0c8302a6dbf5b8">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Health & Conservation &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cadaa6c0c8302a6dbf5bc">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Health & Socioeconomics &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/meetings/612cadc26c0c8302a6dbf5c0">
                            <div className="networking-btn-container">
                                <div className="networking-btn-text">
                                    Biotechnology & Health &nbsp;
                                    <img src="/img/arrow-btns-cuenca.png" />
                                </div>
                            </div>
                        </Link>

                    </div>


                    :
                    null
                }

            </React.Fragment>
        );
    }
}

export default NetworkingCuenca2021;