import React from 'react';

function MyBagScientificGroup(props) {
    return (
        <tr>
            <th colSpan="5">{props.type}</th>
        </tr>
    );
}

export default MyBagScientificGroup;