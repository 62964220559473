import React, { Component } from 'react';

class NetworkingPerson extends Component {
    
    render() {

        let personClass;
        switch (this.props.type) {
            case "delegate":
                personClass = "networking-person-entry-container visitador-medico cursor-only";
                break;
            case "delegate-congress":
                personClass = "networking-person-entry-container visitador-medico cursor-only";
                break;
            case "delegate-assistant":
                personClass = "networking-person-entry-container visitador-medico cursor-only";
                break;
            case "vip":
                personClass = "networking-person-entry-container vip cursor-only";
                break;
            default:
                personClass = "networking-person-entry-container cursor-only";
                break;
        }

        let connectedClass;
        connectedClass = this.props.connected ? "networking-person-connection connected" : "networking-person-connection";

        const photo = this.props.photo !== undefined && this.props.photo !== "" 
            ? <img alt="" src={this.props.photo} />
            : <img alt="" src="/img/default-login-pic.png" />

        return (
                        
            <div className={personClass} onClick={() => this.props.selectPerson(this.props.id)}>
                <div className={connectedClass}></div>
                <div className="networking-person-entry-photo">
                    {photo}
                </div>
                <div className="networking-person-name-institution-container">
                    <strong>{this.props.name}</strong><br />
                    {this.props.company}
                </div>
            </div>

        );
    }
}

export default NetworkingPerson;