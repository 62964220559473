
import * as actionTypes from '../actions/actionTypes'
import { updateObject} from '../utility'

const initialState = {
    sessions:[]
}

// reducer the place to change the state of the store
// heavy logic goes here in the reducer

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case actionTypes.LIVE:
            return {sessions:action.liveSession};
            break;
        default:
            return state;
            break;
    }
     
} 

export default  reducer;