
import React from 'react';
import { OTSubscriber } from 'opentok-react';
import CheckBox from './CheckBox';

class Subscriber extends React.Component {
  
  state = {
    error: null,
    /* audio: this.props.audio,
    video: this.props.video */
  };

  /* setAudio = (audio) => { 
    this.setState({ audio });
  }

  setVideo = (video) => {
    this.setState({ video });
  } */

  onError = (err) => {
    
    this.setState({ error: `Failed to subscribe: ${err.message}` });
    
  }

  subcriberEventHandlers = {
    streamCreated: event => {
      console.log('Subscriber stream created!');
      console.log(event.stream)
    },
    streamDestroyed: event => {
      console.log('Subcriber stream destroyed!');
      console.log(event.stream)
    }
  };

  render() {
    return (
      <div className={this.props.myClass}>
        
        {this.state.error ? <div id="error">Sb. {this.state.error}</div> : null}

        <OTSubscriber
          eventHandlers={this.subcriberEventHandlers}
          properties={{
            subscribeToAudio: this.props.audio,
            subscribeToVideo: this.props.video,
            name:this.props.name,
            width:this.props.width,
            height:this.props.height,
            style:{
              nameDisplayMode:"on"
            }
          }}
          onError={this.onError}
        />
        {/* <CheckBox
            label="Conectar audio"
            initialChecked={this.state.audio}
            onChange={this.setAudio}
            />
            <CheckBox
            label="Conectar video"
            initialChecked={this.state.video}
            onChange={this.setVideo}
            /> */}
      </div>
    );
  }
}
export default Subscriber;
