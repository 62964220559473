import React from 'react';
import jwt from 'jsonwebtoken'


const authContext = React.createContext({
  token: null,
  exp: null,
  authenticated: false,
  event_id:0,
  event_code:"",
  id: 1,
  email:'',
  isAdmin:false,
  profile: null,
  event_settings: {},
  scientific_programme:[],
  days:[],
  rooms:[],
  day:"",
  room:"",
  login: (token) => {
    // check if the current token is valid -> return false
    try {
        const decoded = jwt.decode(token, {complete: true});
        const exp = decoded.payload.exp;
        if (exp > 0 && Date.now() >= exp * 1000) {
            return false;   // expired
          }   else {            
            return true;    // active
          }    
    } catch (error) {        
        return false;
    }
    
  },
  autoLogin: () => {
    //  check if there's a token in the datastorage and retrieves it and validates it
    const storedToken = localStorage.getItem('e-congress.events.token');    
    if (storedToken) {
        const decoded = jwt.decode(storedToken, {complete: true});        
        const exp = decoded.payload.exp;
        if (exp > 0 && Date.now() >= exp * 1000) {
            return false;
          }   else {            
            return true;
          }
    } else {
        return false;
    }
  },
  loadBackgroundImage: (settings, local, key) => {
    
    let backgroundImage;
    // check if the settings are loaded into the context
    if (settings.code !== undefined && settings.code !== "" && settings.code !== null){
      
      switch (local) {
        case 'entrance':
          backgroundImage = settings.entrance_background;
          break;
        case 'lobby':
          backgroundImage = settings.lobby_background;
          break;
        case 'lobby-scientific':
          backgroundImage = settings.scientific_background;    
          break;
        case 'networking':
          backgroundImage = settings.networking_background;
          break;
        case 'room':
          backgroundImage = settings.room_background;    
          break;
        case 'rooms':          
          const room = settings.rooms_background.filter((element) => element.room_id === key);
          if(room !== undefined && room.length>0){
            backgroundImage = room[0].background;
          } else {
            backgroundImage = settings.room_background;
          }
          break;
        case 'industria':
          backgroundImage = settings.industry_background;      
          break;
        case 'meeting':
            backgroundImage = settings.meeting_background;
            break;
        case 'meetings':          
          const meeting = settings.meetings_background.filter((element) => element.meeting_id === key);
          if(meeting !== undefined && meeting.length>0){
            backgroundImage = meeting[0].background;
          } else {
            backgroundImage = settings.meeting_background;
          }
          break;
      }    
        return backgroundImage;  
    }
  }
});

export default authContext;