import React from 'react';

function Stand(props) {

    const classType = "stand-container " + props.standType;

    const stand = props.link !== undefined && props.link !== ""
        ?

        props.link == "nolink" ?
            <div className="stand-sponsor-container" style={{ backgroundImage: props.logo }}></div>
            : <a href={props.link} target="_blank"><div className="stand-sponsor-container" style={{ backgroundImage: props.logo }}></div></a>

        :
        <div className="stand-sponsor-container" style={{ backgroundImage: props.logo }}></div>

    return (
        <div className={classType}> {stand}</div>
    );
}

export default Stand;