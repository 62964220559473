import React, { Component } from 'react';
import NetworkingPerson from './NetworkingPerson';
import NetworkingPersonDetail from './NetworkingPersonDetail';

class NetworkingDelegates extends Component {

    state = {
        showDelegates: false,
        search: "",
        person: ""
    }

    onShowDelegatesHandler = () => {
        this.setState((prevState) => {
            return {
                showDelegates: !prevState.showDelegates
            };
        })
    }

    onSearchHandler = (event) => {
        this.setState({ search: event.target.value })
    }

    onSelectPersonHandler = (personId) => {
        this.setState({ person: personId })
    }

    oncloseDetailHandler = () => {
        this.setState({ person: '' })
    }

    onVoiceHandler = (id) => {
        this.props.onVoice(id);
    }
    onVideoHandler = (id) => {
        this.props.onVideo(id);
    }

    render() {

        const persons = this.props.persons !== undefined && this.props.persons.length > 0
            ? this.props.persons
                .filter((e) => ((e.profile === "delegate" || e.profile === "delegate-congress" || e.profile === "delegate-assistant") && this.state.showDelegates || !this.state.showDelegates))
                .filter((s) => {
                    if (s.name === undefined || s.name === null) s.name = "";
                    if (s.company === undefined || s.company === null) s.company = "";
                    return (
                        (s.name.toUpperCase().search(this.state.search.toUpperCase()) !== -1 || this.state.search === '') ||
                        (s.company.toUpperCase().search(this.state.search.toUpperCase()) !== -1 || this.state.search === '')
                    )
                })
                .map((el) => {

                    return (

                        <NetworkingPerson
                            key={el.id}
                            id={el.id}
                            type={el.profile}
                            photo={el.photo}
                            name={el.name}
                            company={el.company}
                            connected={el.connected}
                            selectPerson={this.onSelectPersonHandler} />
                    )
                })
            : null


        const person = this.state.person !== ""
            ? this.props.persons.filter((el) => {
                return (el.id === this.state.person)
            }).map((element) => {
                return (
                    <NetworkingPersonDetail
                        key={element.id}
                        id={element.id}
                        name={element.name}
                        photo={element.photo}
                        company={element.company}
                        description={element.description}
                        closeDetail={this.oncloseDetailHandler}
                        onVoice={this.onVoiceHandler}
                        onVideo={this.onVideoHandler} />
                )
            })
            : null;

        const classContainer = this.state.person !== "" ? "networking-content-wrapper" : "networking-content-wrapper persons-list-full-width"

        return (

            <div className={classContainer}>

                <div className="networking-left-side-panel-container">

                    <div className="in-room-container">
                        In the lounge <img src="/img/networking-in-room-icon.png" /> <span className="in-room-number">[{this.props.persons.length}]</span>
                    </div>

                    <div className="switch-med-visitors-container">
                        Display all &nbsp;
                        <label className="switch">
                            <input type="checkbox" onClick={this.onShowDelegatesHandler} />
                            <span className="slider round"></span>
                        </label>
                        &nbsp; Delegates
                    </div>

                    <div className="in-room-search-container">
                        <div className="search-btn">
                            <img src="/img/search-btn-input.png" />
                        </div>
                        <div className="search-input">
                            <input type="text" id="search" onChange={this.onSearchHandler} name="search" className="in-room-search-input" placeholder="Search delegate..." />
                        </div>
                    </div>

                    <div className="networking-delegates-company-logo-container">
                        {this.props.eventId == "1" ?
                            <img src="https://www.emma.events/static/virtualvenue/portdebarcelona2021/networking/logo-networking-portbarcelona2021.jpg" />
                            :
                            <img src="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg" />
                        }
                    </div>

                    <div style={{ clear: `both` }}></div>

                    <div className="left-side-options-in-room-persons">

                        <div className="left-side-opt-in-room-persons delegates selected"></div>
                        {/* <div className="left-side-opt-in-room-persons settings"></div> */}

                    </div>

                    <div className="in-room-persons-list-container ">
                        {persons}
                    </div>

                </div>

                {person}

            </div>
        );
    }
}

export default NetworkingDelegates;