import React from 'react';
import { useTranslation } from 'react-i18next';

function LegalLinks(props) {

    const { t, i18n } = useTranslation();

    return (

        props.event_id === "387" || props.event_id === "2" || props.event_id === "3" || props.event_id === "4" || props.event_id === "5" ?

            <div className="legal-links-container">

                <a onClick={props.onTermsConditionsOpen} href="#" rel="noopener">Términos y condiciones de Uso</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a onClick={props.onPrivacyPolicyOpen} href="#" rel="noopener">Política de privacidad</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a onClick={props.onLegalNoticeOpen} href="#" rel="noopener">Aviso legal</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a onClick={props.onCookiesPolicyOpen} href="#" rel="noopener">Política de cookies</a>
                {props.event_id === "5" ?
                    <span className="cod-evento-rovi">ROV-08-21/004</span>
                    :
                    null}

            </div>

            : null
    );
}

export default LegalLinks;