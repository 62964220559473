import React, { Component } from 'react';
import configData from '../../config.json';
import AuthContext from '../../context/auth-context';
import axios from 'axios';
import EmailValidator from 'email-validator';

class RecoverPassword extends Component {

    state = {
        render:'form',
        email:null,
        email_valid:false,
    }

    static contextType = AuthContext;


    onFormSaveHandler = () => {

        let config = {
            headers: {"content-type": "application/json"}
        };

        const myUser = {            
            event_id: this.context.event_settings.event_id, 
            email : this.state.email, 
        }

        if (this.state.email !== null ) { 

                if (this.state.email_valid){
                    
                    axios.post(configData.SERVER_API + '/users/recover', myUser, config)
                        .then(response => {                    
                            console.log(response.data)
                            if (response.data.error !== undefined && response.data.error !== '0'){
                                alert(response.data.message)
                            } else {
                                this.setState({render:'ok'})
                            }
                            
                        })
                        .catch(error => {
                            console.log(error)
                        })
                

                } else {
                    alert('Formato de correo electrónico no válido.')
                }

        } else {
            alert('Por favor indique el correo electrónico.')
        }
    }

    onCommentsHandler = (text) => {
        this.setState({ 
            email: text,
            email_valid: EmailValidator.validate(text)
        });
    }

    onSubmit(event) {
        event.preventDefault();
    }


    render() {

        return (

            <div className="backdrop-disclaimer-text">
                <div className="legal-text-container recover-password-container">

                {this.state.render == 'form' ? 

                    <form className="form-registration" onSubmit={this.onSubmit}>

                        <h3>Recuperar contraseña</h3>

                        <p>Rellene su correo electrónico registrado para seguir con el proceso de recuperación de contraseña:</p>


                        <div className="input-wrapper">
                            <label htmlFor="registration-email">Email *</label>
                            <input type="Email" id="registration-email" name="registration-email" placeholder="Email" onChange={(event) => this.onCommentsHandler(event.target.value)}></input>
                        </div>


                        <p>&nbsp;</p>

                        <div className="input-wrapper" style={{ textAlign: "center" }}>
                            <input type="submit" value="Continuar" className="btn-submit" onClick={this.onFormSaveHandler} />
                        </div>

                        <p>&nbsp;</p>


                    </form>

                : 
                    <form className="form-registration" onSubmit={this.onSubmit}>
                        <h3>Recuperar contraseña</h3>
                        <p>¡El proceso de recuperación de contraseña se inició con éxito!</p>
                        <p>Recibirá un correo electrónico con los detalles para restablecer su contraseña.</p>
                        <p>Muchas gracias</p>
                    </form>
                }


                </div>

                <div className="legal-text-btn-container recover-password-btn-container">
                    <div onClick={this.props.onRecoverPasswordClose} className="stand-disclaimer-close-btn-container">Cerrar</div>
                </div>

            </div>

        );
    }
}

export default RecoverPassword;



