import React, { Component } from 'react';
import configData from '../../config.json';
import AuthContext from '../../context/auth-context';
import axios from 'axios'

class VotePopup extends Component {

    state = {
        checked:false,
        complete: false,
        message:"",
        voting_type: '',
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false,
        q7: false,
    }

    static contextType = AuthContext;

    componentDidMount() {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/users/me/survey/election/sea2021", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    if (response.data[0].access === "0"){
                        this.setState({ 
                            checked:true,
                            complete: true, 
                            message:"Acceso denegado. Votación disponible solo para socios SEA."
                        })
                    } else if (response.data[0].access === "-1"){
                        this.setState({ 
                            checked:true,
                            complete: true, 
                            message:"Votación cerrada!"
                        })
                    } else {
                        this.setState({ 
                            checked:true,
                            complete: true, 
                            message:"Ya hemos recibido su voto. Para obtener más información, póngase en contacto con la secretaría. Muchas gracias."
                        })
                    }
                    
                } else {
                    this.setState({ checked:true,complete: false})
                }
            })
            .catch(error => {
                this.setState({ checked:false})
                console.log(error.message);
            })
    }


    onSurveySaveHandler = () => {

        let config = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };

        const mySurvey = {
            user_id: this.context.id,
            election_id: 'sea2021',
            election_type: this.state.voting_type,
            q1: this.state.q1,
            q2: this.state.q2,
            q3: this.state.q3,
            q4: this.state.q4,
            q5: this.state.q5,
            q6: this.state.q6,
            q7: this.state.q7
        }


        //if (this.state.q1 || this.state.q2 || this.state.q3 || this.state.q4 || this.state.q5 || this.state.q6 || this.state.q7 ) {
        if (true) {

            axios.post(configData.SERVER_API + '/users/me/survey/election/sea2021', mySurvey, config)
                .then(response => {
                    //this.setState({complete:true})
                    this.setState({
                        complete :true,
                        message:"Ya hemos recibido su voto. Para obtener más información, póngase en contacto con la secretaría. Muchas gracias."});
                    setTimeout(() => {
                        this.props.close();    
                    }, 3000);
                })
                .catch(error => {
                    //console.log(error)
                })
        } else {
            alert('Para votar es necesario seleccionar al menos un candidato.')
        }

    }


    onSelectOpenCandidancy = () => {
        this.setState({ 
            voting_type:"open",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            q6: false,
            q7: false
         })
    }

    onSelectCloseCandidancy = () => {
        this.setState({ 
            voting_type:"close",
            q1: true,
            q2: true,
            q3: true,
            q4: true,
            q5: true,
            q6: true,
            q7: true
         })
    }

    onSetAnwserHandler = (event) => {
        switch (event.target.name) {
            case "candidate-1":
                this.setState({ q1: event.target.checked })
                break;

            case "candidate-2":
                this.setState({ q2: event.target.checked })
                break;

            case "candidate-3":
                this.setState({ q3: event.target.checked })
                break;

            case "candidate-4":
                this.setState({ q4: event.target.checked })
                break;

            case "candidate-5":
                this.setState({ q5: event.target.checked })
                break;

            case "candidate-6":
                this.setState({ q6: event.target.checked })
                break;

            case "candidate-7":
                this.setState({ q7: event.target.checked })
                break;

            default:
                break;
        }
    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {

        const classOpen = this.state.voting_type === "open" ? "stand-election-selection-btn-container selected" : "stand-election-selection-btn-container";
        const classClose = this.state.voting_type === "close" ? "stand-election-selection-btn-container selected" : "stand-election-selection-btn-container";

        return (
            <div className="video-gallery-container">
    
                <div className="video-gallery-wrapper voting-wrapper">
                
                {this.state.checked ?
                 !this.state.complete ?
                
                    this.props.eventid == "391" && this.props.standid == "sea" && this.props.votetitle == "ELECCIONES" ?
    
                        <div className="voting-form-wrapper">
    
                            <form className="form-my-credits form-session-survey" onSubmit={this.onSubmit}>
    
                                <h2>ELECCIONES JUNTA DIRECTIVA SEA  2021</h2>
                                {/* <h4>CANDIDATURA ABIERTA</h4> */}

                                <p>Selecione el tipo de boletín de candidatura:</p>
                                {/* <span className="cursor-only" onClick={this.onSelectOpenCandidancy}>CANDIDATURA ABIERTA</span> | <span className="cursor-only" onClick={this.onSelectCloseCandidancy}>CANDIDATURA CERRADA</span> */}
                                <div class="btngroup">
                                    <div class={classOpen} onClick={this.onSelectOpenCandidancy}> CANDIDATURA ABIERTA</div>
                                    <div class={classClose} onClick={this.onSelectCloseCandidancy}>CANDIDATURA CERRADA</div>
                                </div>
    
                                {  this.state.voting_type !== "" ?
                                <React.Fragment>
                                
                                <p>VICEPRESIDENTE 1º</p>
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-1" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-1" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">Carlos Guijarro Herráiz</div>
                                <div style={{ clear: `both` }}></div>
    
                                <p>SECRETARIO</p>
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-2" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-2" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    } 
                                </div>    
                                <div className="voting-assembly-text">Teresa Arrobas Velilla</div>
                                <div style={{ clear: `both` }}></div>
    
                                <p>TESORERO</p>
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-3" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-3" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">Núria Plana Gil</div>
                                <div style={{ clear: `both` }}></div>
    
                                <p>VOCALES</p>
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-4" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-4" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">José Javier Gómez Barrado</div>
                                <div style={{ clear: `both` }}></div>
    
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-5" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-5" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">Cristina Rodríguez Sinovas</div>
                                <div style={{ clear: `both` }}></div>

                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-6" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-6" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">Juan José Tamarit García</div>
                                <div style={{ clear: `both` }}></div>
    
    
                                <div className="login-my-profile-rgpd-check">
                                    {this.state.voting_type === "close" 
                                    ? <input type="checkbox" name="candidate-7" value="1" checked disabled />
                                    : <input type="checkbox" name="candidate-7" value="1" onClick={(event) => this.onSetAnwserHandler(event)} />
                                    }
                                </div>
                                <div className="voting-assembly-text">Agustín Blanco Echevarría</div>
                                <div style={{ clear: `both` }}></div>
    
                                <p>&nbsp;</p>
    
    
                                <div className="input-wrapper">
                                    <input type="submit" value="VOTAR" className="btn-submit" onClick={this.onSurveySaveHandler} />
                                </div>
    
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                </React.Fragment>

                                : null}
    
                            </form>
    
    
    
                        </div>
    
    
    
                        : null
    

                    : <div>{this.state.message}</div>
                : <div>Cargando ... </div>
                }
    
    
    
    
                </div>
    
                <div onClick={this.props.close} className="video-gallery-close-btn-container">CERRAR</div>
            </div>
        );
    }
}

export default VotePopup;
