import React from 'react';
import MyBagScientificGroup from '../../../../../components/MyBagScientific/MyBagScientificGroup';
import MyBagScientificPoster from '../../../../../components/MyBagScientific/MyBagScientificPoster';
import MyBagScientificVideo from '../../../../../components/MyBagScientific/MyBagScientificVideo';


function TabScientific(props) {
    return (
        <table border="0" cellSpacing="0" cellPadding="0" className="tbl-mybag-scientific">
            <tbody>

        <MyBagScientificGroup type="E-Poster" />
        <MyBagScientificPoster />
        <MyBagScientificPoster />

        <MyBagScientificGroup type="Video" />
        <MyBagScientificVideo />
        <MyBagScientificVideo />
            
            </tbody>
        </table>
    );
}

export default TabScientific;