import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

function Session(props) {

    const locked = props.locked ? <img alt="" src="/img/icon-lock.png" /> : "";
    const companyLogo = props.companyLogo ? <React.Fragment><br /><img alt="" src={props.companyLogo} /></React.Fragment> : null;
    const sponsoredText = props.sponsoredText ? <p className="sessions-list-sponsored-text">{props.sponsoredText}</p> : "";

    const isLiveSession = props.live.filter((el) => el._id === props._id)
    let live = "";
    if (isLiveSession !== undefined && isLiveSession.length > 0) {
        live = <div className="sessions-list-live-text">LIVE</div>;
    }

    let companyLogoShow = '';

    if (sponsoredText != "") { companyLogoShow = ""; } else { companyLogoShow = companyLogo; }

    return (
        <tr>
            <td className="td-time">
                {props.type != "On Demand Session"
                    ? <span>{props.time}</span>
                    : null
                }
            </td>
            <td className={props.noTheme ? `td-title not-theme` : `td-title`}>
                {props.externalURL !== undefined & props.externalURL !== ""
                    ? <a href={props.externalURL} target="_blank">
                        {props.title}
                        {companyLogoShow}
                        {sponsoredText}
                    </a>
                    : <Link key={props._id} to={`/room/${props.roomId}/${props._id}`}>
                        {props.title}
                        {companyLogoShow}
                        {sponsoredText}
                    </Link>
                }
            </td>
            <td className="td-icon">
                {live}
                {locked}
            </td>
        </tr>
    );
}

const mapStateToProps = state => {
    return {
        live: state.live.sessions
    }
}

export default connect(mapStateToProps)(Session);