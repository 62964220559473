import React, { Component } from 'react';
import { PrivacyPolicyCNP2020 } from '../../../../../containers/MyProfile/PrivacyPolicy-CNP2020'
import { PrivacyPolicyVPRACTICVM } from '../../../../../containers/MyProfile/PrivacyPolicy-VPRACTICVM'
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import configData from "../../../../../config.json";
import AuthContext from "../../../../../context/auth-context";
import { PrivacyPolicyVITDAH } from '../../../../MyProfile/PrivacyPolicy-VITDAH';

class MyProfile extends Component {


    state = {
        name: "",
        surname: "",
        photo: "",
        email: ""
    }

    static contextType = AuthContext;

    componentDidMount() {

        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: { _id: this.context._id }
        };

        axios.get(configData.SERVER_API + "/users/me", data)    // get the details of the user
            .then(response => {
                this.setState({
                    name: response.data.name !== undefined && response.data.name !== "" ? response.data.name : "",
                    surname: response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : "",
                    photo: response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png",
                    email: response.data.email !== undefined && response.data.email != "" ? " " + response.data.email : "",
                })
            })
            .catch(error => { console.log(error.message); })
    }

    render() {

        let titleText, welcomeText, policyText, policyIntro, policyCheckText
        let sponsor = "";

        switch (this.context.event_id) {
            //case '0': // CNP 2020

            case "289":
                titleText = '';
                welcomeText = '';
                policyIntro = '';
                policyText = '';
                sponsor = <div className="login-company-logo"><p>Lanyard y perfil patrocinado por:</p>
                    <img src="https://www.emma.events/static/virtualvenue/seip2021/lobby/seip2021-logo-mybag.jpg" />
                </div>
                break;
            case "314":
                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL  53 º CONGRESO VIRTUAL SEPAR 2020';
                policyIntro = `
                <p><strong>La Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente al 53 Congreso Nacional Virtual de la SEPAR, accede a ser grabado en las intervenciones en las que participe así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/separ2020/myprofile/separ2020-myprofile-logo.png" />
                </div>

                break;

            case "315":

                titleText = 'Política de Privacidad';
                welcomeText = 'V PRACTICVM DERMATOLOGIA PEDIATRICA 2021';
                policyIntro = ''
                policyText = PrivacyPolicyVPRACTICVM;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el curso."
                break;

            case "353":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al 32º Congreso Virtual de la Sociedad Española de Alergología e Inmunología Clínica 2020.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible para todos los asistentes.</p>

                <p>La SEAIC no se responsabiliza del contenido presentado en los expositores del Área de expositores. </p>
                
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la SEAIC se reserva el derecho de restricción de acceso al congreso.</p>`
                policyText = "<a href='https://www.seaic.org/inicio/privacidad' target='_blank'>POLÍTICA PRIVACIDAD https://www.seaic.org/inicio/privacidad</a>";
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                sponsor = <div className="login-company-logo">
                    <img src="/img/Allergy-Therapeutics-LogoTransparente-2019.png" />
                </div>
                break;

            case "367":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al XXIII Congreso Nacional de Psiquiatría 2020.';
                policyIntro = `
                <p>Al acceder al congreso su presencia e interacción en el mismo quedarán grabados y almacenados en el servidor. Su participación en los chats de sesiones será visible por todos los asistentes. </p>

                <p>La FEPSM, SEP y SEPB no se responsabiliza del contenido presentado en los expositores del Exhibition Area. </p>
                
                <p>Al acceder a la plataforma virtual, usted reconoce que la información que comparte es verídica y real. En caso de detectar que no es así, la FEPSM, SEP y SEPB se reservan el derecho de restricción de acceso al congreso. </p>`
                policyText = PrivacyPolicyCNP2020;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y almacenamiento de su actividad en el congreso."
                break;

            case "387":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al Curso de Actualización Avances en el manejo clínico del TDAH';
                policyIntro = `
                <p>En esta Política de Privacidad se recoge toda la información relativa al tratamiento que realiza el Laboratorios Farmacéuticos Rovi, S.A. (en adelante, “ROVI”) de los datos personales recabados a través de cualquiera de las Páginas Web que son titularidad de ROVI con motivo de reuniones y eventos científicos y profesionales (en adelante, denominadas “Página Web”), en función de la interacción que cada usuario tenga con ROVI.</p>

                <p>Se entenderá por Usuario (o Usuarios) la persona que acceda, navegue o utilice cualesquiera de las funcionalidades disponibles en la Página Web.</p>
                
                <p>Se entenderá por Asistente la persona que se asista o participe en los eventos organizados por ROVI, cuya inscripción y/o asistencia se realice a través de la Página Web.</p>`
                policyText = PrivacyPolicyVITDAH;
                policyCheckText = "*He leído y acepto la política de privacidad"
                break;

            case "2":

                titleText = 'Política de Privacidad';
                welcomeText = 'Bienvenido al Curso de Actualización Avances en el manejo clínico del TDAH';
                policyIntro = `
                <p>En esta Política de Privacidad se recoge toda la información relativa al tratamiento que realiza el Laboratorios Farmacéuticos Rovi, S.A. (en adelante, “ROVI”) de los datos personales recabados a través de cualquiera de las Páginas Web que son titularidad de ROVI con motivo de reuniones y eventos científicos y profesionales (en adelante, denominadas “Página Web”), en función de la interacción que cada usuario tenga con ROVI.</p>

                <p>Se entenderá por Usuario (o Usuarios) la persona que acceda, navegue o utilice cualesquiera de las funcionalidades disponibles en la Página Web.</p>
                
                <p>Se entenderá por Asistente la persona que se asista o participe en los eventos organizados por ROVI, cuya inscripción y/o asistencia se realice a través de la Página Web.</p>`
                policyText = PrivacyPolicyVITDAH;
                policyCheckText = "*He leído y acepto la política de privacidad"
                break;

            case "372":
                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 1ª REUNIÓN VIRTUAL ÁREAS SEPAR Y I SIMPOSIO MULTIDISCIPLINAR';
                policyIntro = `
                <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR</p> 
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España)</p> 
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 1ª Reunión Virtual Áreas SEPAR y I Simposio Multidisciplinar, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo"><p>Patrocinado Por:</p>
                    <img src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/myprofile/alk-myprofile.png" />
                </div>

                break;

            case "373":
                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 2ª REUNIÓN VIRTUAL ÁREAS SEPAR Y II SIMPOSIO MULTIDISCIPLINAR';
                policyIntro = `
                <p>La Sociedad Española de Neumología y Cirugía Torácica (SEPAR) pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 2ª Reunión Virtual Áreas SEPAR y II Simposio Multidisciplinar, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo"><p>Patrocinado Por:</p>
                    <img src="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/industria/logos/LOGOS_SEPAR_600x144_ASTRAZENECA.jpg" />
                </div>

                break;


            case "424":
                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 3ª REUNIÓN VIRTUAL ÁREAS SEPAR';
                policyIntro = `
                <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                <p>Rogamos tenga en cuenta que, en calidad de asistente a la 3ª Reunión Virtual Áreas SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                `;
                policyText = `
                <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                
                `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo"><p>Patrocinado Por:</p>
                    <img src="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/industria/logos/alk-logo-colaborador.jpg" />
                </div>

                break;

            case "425":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS A LA 4ª REUNIÓN VIRTUAL ÁREAS SEPAR ';
                policyIntro = `
                    <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR </p>
                    <p>(C/ Provença, 108, Bajos 2ª 08029  Barcelona - España) </p>
                    <p>Rogamos tenga en cuenta que, en calidad de asistente a la 4ª Reunión Virtual Áreas SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                    `;
                policyText = `
                    <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                    <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                    
                    `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."


                break;


            case "336":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL XXXI CONGRESO NACIONAL DE LA SEFC';
                policyIntro = `
                    <p>La Sociedad Española de Farmacología Clínica (SEFC) pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEFC. Los datos facilitados tienen carácter OBLIGATORIO para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEFC</p>
                    <p>(Santa Isabel, 51 (Ilustre Colegio de Médicos de Madrid) Madrid 28012 España)</p>
                    <p>Rogamos tenga en cuenta que, en calidad de asistente al XXXI Congreso Nacional de la SEFC, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma.</p>
                    `;
                policyText = `
                    <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                    <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEFC para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEFC en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                    
                    `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su consentimiento para el tratamiento de sus datos personales por parte de SEFC de acuerdo a lo expuesto."


                break;


            case "379":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL XXVI CONGRESO NEUMOMADRID 2021';
                policyIntro = `<p>La <strong>Sociedad Madrileña de Neumología y Cirugía Torácica (NEUMOMADRID)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de NEUMOMADRID. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a NEUMOMADRID</p>
                                <p>(C/ Cea Bermúdez, 46 28003 Madrid - España)</p>
                                <p>Rogamos tenga en cuenta que, en calidad de asistente al XXVI Congreso Neumomadrid 2021, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma.</p>`;
                policyText = `<hr /><p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                             <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la NEUMOMADRID para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la NEUMOMADRID en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>`;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de Neumomadrid de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/vivisol-mybag.jpg" />
                </div>
                break;


            case "383":

                titleText = 'Política de Privacidad';
                welcomeText = 'BIENVENIDOS AL 54º CONGRESO SEPAR';
                policyIntro = `
                        <p>La <strong>Sociedad Española de Neumología y Cirugía Torácica (SEPAR)</strong> pone en su conocimiento que dispone de un fichero automatizado con datos de carácter personal que se encuentra registrado en la Agencia Española de Protección de Datos. La finalidad de su creación, existencia y mantenimiento es el tratamiento de los datos con exclusivos fines de cumplir con el objeto social de la SEPAR. Los datos facilitados tienen carácter OBLIGATORIO  para poder tramitar su inscripción. En todo caso, Ud. tiene derecho a ejercitar los derechos que le reconoce la Ley Orgánica de Protección de Datos, mediante escrito dirigido a la SEPAR 
                        (C/ Provença, 108, Bajos 2ª 08029  Barcelona - España)</p>
                        <p>Rogamos tenga en cuenta que, en calidad de asistente al 54º Congreso SEPAR, accede a ser grabado en las intervenciones en las que participe, así como en las actividades de interacción con otros usuarios de la plataforma. </p>
                        `;
                policyText = `
                        <p>Enterado y conforme con lo expuesto en el apartado anterior:</p>
                        <p>CONSIENTO EXPRESAMENTE Y AUTORIZO a la SEPAR para que trate los datos personales que voluntariamente cedo, limitando la referida autorización al cumplimiento de los fines directamente relacionados con las funciones legítimas de la SEPAR en el ámbito de la institución, entes colaboradores e instituciones anejas, así como la cesión a los estamentos oficiales públicos y privados oportunos necesarios para el eficaz desempeño de sus atribuciones y el cumplimento de su objetivo.</p>
                        
                        `;
                policyCheckText = "*Aceptando está dando su conformidad a la Política de Privacidad y da su  consentimiento para el tratamiento de sus datos personales por parte de SEPAR de acuerdo a lo expuesto."
                sponsor = <div className="login-company-logo">
                    <img src="https://www.emma.events/static/virtualvenue/separ2020/myprofile/separ2020-myprofile-logo.png" />
                </div>

                break;


            default:
                titleText = '';
                welcomeText = '';
                policyIntro = '';
                policyText = ''
                policyCheckText = "";
                break;
        }

        let role;
        switch (this.props.profile) {
            case 'participant':
                role = "Asistente"
                break;
            case 'participant-pay-per-view':
                role = "Asistente"
                break;
            case 'secretariat':
                role = "Secretaria"
                break;
            case 'delegate':
                role = "Delegado"
                break;
            case 'delegate-stand':
                role = "Delegado"
                break;
            case 'delegate-congress':
                role = "Delegado"
                break;
            case 'delegate-assistant':
                role = "Delegado"
                break;
            case 'delegate-congress':
                role = "Delegado"
                break;
            case 'delegate-stand-checker':
                role = "Delegado"
                break;
            default:
                role = "Asistente"
                break;
        }

        return (


            <React.Fragment>
                <div style={{ clear: `both` }}></div>
                <div className="menu-tab-item selected">Mi Perfil  </div>
                <div style={{ clear: `both` }}></div>
                <div className="tabs-my-bag" id="tab-inserts">

                    {this.context.event_id === "353" || this.context.event_id === "314" || this.context.event_id === "342" || this.context.event_id === "289" || this.context.event_id === "315" || this.context.event_id === "378" || this.context.event_id === "372" || this.context.event_id === "373" || this.context.event_id === "397" || this.context.event_id === "376" || this.context.event_id === "391" || this.context.event_id === "325" || this.context.event_id === "341" || this.context.event_id === "263" || this.context.event_id === "320" || this.context.event_id === "351" || this.context.event_id === "379" || this.context.event_id === "337" || this.context.event_id === "383" || this.context.event_id === "424" || this.context.event_id === "425" || this.context.event_id === "336" ? <React.Fragment>

                        <div className="login-my-profile-spacer"></div>


                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">

                            <div className="avatar-profile-add">
                                {this.state.photo !== undefined && this.state.photo !== ""
                                    ? <img src={this.state.photo} style={{ borderRadius: `50%` }} />
                                    : <img src="/img/default-login-pic.png" />}
                            </div>
                        </div>

                        <div className="login-my-profile-spacer"></div>

                        <div className="login-my-profile-wrapper-split">
                            {sponsor}
                        </div>

                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>
                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-subtitle">Datos personales</span>
                        </div>

                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Nombre</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="name" name="name" placeholder="Nombre" readOnly disable="true" value={this.state.name} />
                            </div>
                        </div>

                        <div className="login-my-profile-spacer"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Apellidos</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="surname" name="surname" placeholder="Apellidos" readOnly disable="true" value={this.state.surname} />
                            </div>
                        </div>

                        <div style={{ clear: `both` }}></div>
                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">Email</span>
                            <div className="login-my-profile-form-field">
                                <input type="text" id="name" name="name" placeholder="Email" readOnly disable="true" value={this.state.email} />
                            </div>
                        </div>


                        <div style={{ clear: `both` }}></div>

                        <div className="login-my-profile-separator"></div>

                        <div className="login-my-profile-wrapper-split">
                            <span className="login-my-profile-form-label">ROL</span>
                            <div className="login-my-profile-roll">{role}</div>
                        </div>

                        <div style={{ clear: `both` }}></div>
                        <div className="login-my-profile-separator"></div>
                    </React.Fragment>

                        : null}


                    <div className="login-my-profile-wrapper-split">
                        <span className="login-my-profile-subtitle">{titleText}</span>
                    </div>

                    <div style={{ clear: `both` }}></div>
                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{welcomeText}</div>

                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{ReactHtmlParser(policyIntro)}</div>

                    <div className="login-my-profile-separator"></div>

                    <div style={{ fontFamily: `MyriadPro` }}>{ReactHtmlParser(policyText)}</div>

                    <div className="login-my-profile-separator"></div>


                </div>

            </React.Fragment>
        );
    }
}

export default MyProfile;