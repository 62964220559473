import React from 'react';
import { OTPublisher } from 'opentok-react';
import CheckBox from './CheckBox';

class Publisher extends React.Component { 

    state = {
      error: null
    };

    onError = (err) => {
      
      this.setState({ error: `Failed to publish: ${err.message}` });      
      // we send a message back to change the state to the previous and then back to it 

    }

    publisherEventHandlers = {
      otrnError: event =>{
        console.log(event.error)
      } ,
      otrError: event =>{
        console.log(event.error)
      },
      onStreamAvailableError : event =>{
        console.log(event.error)
      } ,
      streamCreated: event => {
        //console.log('Publisher stream created!');
        //console.log(event.stream.id)
      },
      streamDestroyed: event => {
        //console.log('Publisher stream destroyed!');
        //console.log(event.stream.id)
      }
    };
   
    render() {

  const oTP = <OTPublisher
    eventHandlers={this.publisherEventHandlers}
    properties={{
      publishAudio: this.props.audio,
      publishVideo: this.props.video,
      videoSource: this.props.videoSource === 'screen' ? 'screen' : undefined,
      name:this.props.name,
      width:this.props.width,
      height:this.props.height,
      resolution: "1280x720",
      style:{
        nameDisplayMode:"on",
        buttonDisplayMode:"off",
        backgroundImageURI:this.props.picture
      }
    }}
    onError={this.onError}
  />
      

    return (
      <div className={this.props.myClass}>
        
        {this.state.error ? <div id="error">Pb: {this.state.error}</div> : oTP}

      </div>)
    };
  
  }
  export default Publisher;