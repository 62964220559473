import React from 'react';

function MyBagScientificVideo(props) {
    return (
        <tr>
            <td className="td-preview">

                <div className="video-preview-container">

                    <div className="video-preview-wrapper">

                        <div className="video-preview-cat-color" style={{backgroundColor:`#f9b138`}}></div>
                        <div className="video-preview-img"><img src="/img/video-preview-tbl.png" alt="" /></div>

                    </div>

                    

                </div>

            </td>
            <td className="td-title">Lorem ipsum dolor sit amet consectetuer feugiat Vestibulum Nam id egestas. Quis vel a auctor orci Vestibulum mauris sem pede in venenatis.</td>
            <td className="td-authors">B Basso Abad // J Gonzalez de Buitrago Amigo // C Surribas Murillo // M Sanchez Conejero // M Gutierrez Agujetas</td>
            <td className="td-category">PEDIATRIA HOSPITALARIA</td>
            <td className="td-download">Download</td>
        </tr>
    );
}

export default MyBagScientificVideo;