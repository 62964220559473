import React from 'react';
import { Link } from 'react-router-dom';

function EposterCategory(props) {

    let PosterCategoryBackground = "";

    if (props.backgroundImage !== undefined && props.backgroundImage != "") {
        PosterCategoryBackground = { backgroundImage: `url(${props.backgroundImage})` };
    }
    else {
        PosterCategoryBackground = { backgroundColor: props.backgroundColor };
    }

    //alert(props.backgroundImage);

    if (props.eventid == "378") {

        return (

            <option key={props.id} id={props.id} value={props.id}>{props.name}</option>

        );
    }
    else {

        return (


            <div onClick={props.selectcategory} key={props.id} id={props.id} className="eposter-category-container" style={PosterCategoryBackground}>
                <div className="eposter-category-title-container" id={props.id}>
                    {props.name}
                </div>
                <div className="eposter-category-bottom-container" id={props.id}>
                    {props.sponsorLogo ?
                        <div className="eposter-category-bottom-logo" id={props.id}>
                            <img alt="" src={props.sponsorLogo} id={props.id} />
                        </div>
                        : null}
                    <div className="eposter-category-bottom-number" id={props.id}>[{props.qty}]</div>
                </div>
            </div>
        );
    }


}

export default EposterCategory;