import React from 'react';

function Live(props) {
    
    let classesname = "live-session-info " + props.classes;

    return (
         <div className={classesname}>
            <strong>{props.title}</strong>
            {/* <br />{props.sponsor} */}
        </div>
    );
}

export default Live;