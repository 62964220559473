import React from 'react';

function Rating(props) {
       
    const arrayRates = [1,2,3,4,5];
    
    let sourceFilled, sourceNotFilled

    if (props.transparent) {
        sourceFilled = "/img/eposter-rating-star-yellow.png"
        sourceNotFilled = "/img/eposter-rating-star-transparent.png"
    } else {
        sourceFilled = "/img/eposter-detail-star-filled-rating.png"
        sourceNotFilled = "/img/eposter-detail-star-not-filled-rating.png"
    }
    
    const Ratings = arrayRates.map((el, index) => {
        return (el <= props.rate) ?  <img onClick={props.scoreHandler} key={el} alt={el} src={sourceFilled} /> : <img onClick={props.scoreHandler} key={el} alt={el} src={sourceNotFilled} className="cursor-only" />
    })

    return (
        <React.Fragment>
        {Ratings}
        </React.Fragment>
    );
}

export default Rating;