import React, { Component } from 'react';
import MyCongressInfoEntry from './MyCongressInfoEntry'

class MyCongressInfoDetails extends Component {
    render() {

        const entries = this.props.notifications.map((el, index) => {
            return ( <MyCongressInfoEntry key={el._id} new={el.new} title={el.title} text={el.text} time={el.time}></MyCongressInfoEntry> )
        })

        return (
            <div className="right-info-more">

                <div className="right-info-more-wrapper">
                    <div className="right-info-more-title">&nbsp;</div>
                    <div className="right-info-more-close cursor-only" onClick={this.props.close}><img src="/img/icon-close.png" /></div>
                </div>
                {entries}

            </div>
        );
    }
}

export default MyCongressInfoDetails;