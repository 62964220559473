import React, { Component } from 'react';
import { connect } from 'react-redux'
import { connectSocket, disconnectSocket } from '../../../store/actions/global';
import { updateLive } from '../../../store/actions/live';
import axios from 'axios';
import Login from '../../../components/Login/Login';
import LoginRecover from '../../../components/Login/LoginRecover';
import LoginReset from '../../../components/Login/LoginReset';
import { browserHistory, Redirect, withRouter } from 'react-router';
import AuthContext from '../../../context/auth-context';
import jwt from 'jsonwebtoken'
import WelcomeBadge from '../../Welcome/WelcomeBadge';
import configData from "../../../config.json";
import MyProfile from '../../MyProfile/MyProfile';
import Cookies from '../../../components/Cookies/Cookies';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import Registration from '../../../components/Registration/Registration';
import RecoverPassword from '../../../components/RecoverPassword/RecoverPassword';
import ResetPassword from '../../../components/ResetPassword/ResetPassword';
import queryString from 'query-string';

class LoginArea extends Component {

    static contextType = AuthContext;

    state = {
        step: "login",
        profile: this.context.event_settings.privacy_policy,
        welcome: this.context.event_settings.badge,
        loginError: false,
        loginErrorCode: "",
        email: "",
        password: "",
        loggedIn: false,
        pwd_free: this.context.event_settings.pwd_free,
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        RegistrationFormPopUpOn: false,
        RecoverPasswordPopUpOn: false,
        ResetPasswordPopUpOn: queryString.parse(this.props.location.search).recoverpassword !== undefined ? true : false,
        ResetPasswordCode: queryString.parse(this.props.location.search).recoverpassword !== undefined ? queryString.parse(this.props.location.search).recoverpassword : ""
    }

    componentDidMount() {
        this.autoLoginHandler();
    }

    autoLoginHandler = () => {

        if (this.context.login(this.context.token) || this.context.autoLogin()) {

            // neither need to set the context -> already done in the App level (after referesh)
            // nor to connect the Socket -> already done in the App 

            let data = {
                headers: {
                    "x-auth-token": this.context.token,
                    "content-type": "application/json",
                },
                params: { _id: this.context._id }
            };

            axios.get(configData.SERVER_API + "/users/me", data)    // get the details of the user
                .then(response => {
                    const user = {
                        name: response.data.name !== undefined && response.data.name !== "" ? response.data.name : "",
                        surname: response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : "",
                        photo: response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png",
                        privacy_policy: response.data.privacy_policy,
                        privacy_policy_date: response.data.privacy_policy_date
                    }

                    if (this.context.event_settings.privacy_policy && !user.privacy_policy) {   // enteres the profile if set on Event and not true in User
                        this.setState({ user: user, loggedIn: true, step: "profile" });
                    } else if (this.context.event_settings.badge) {
                        this.setState({ user: user, loggedIn: true, step: "welcome" });
                    } else {
                        this.setState({ user: user, loggedIn: true });
                    }

                })
                .catch(error => { console.log(error.message); })

        }
    }

    emailHandler = (event) => {
        this.setState({ email: event.target.value, loginError: false })
    }
    passwordHandler = (event) => {
        this.setState({ password: event.target.value, loginError: false })
    }
    loginHandler = () => {

        if ((this.state.email != "" && this.state.password != "") || (this.state.email != "" && this.context.event_setttings.pwd_free)) {

            // checks if the user loggs in 
            axios.post(configData.SERVER_API + '/auth2', {
                "email": this.state.email,
                "password": this.state.password,
                "event_id": this.props.event_id
            },
                {
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            ).then(response => {

                localStorage.setItem('e-congress.events.token', response.data.token);

                const decoded = jwt.decode(response.data.token, { complete: true });

                this.context.token = response.data.token;
                this.context.authenticated = true;
                this.context.exp = decoded.payload.exp;
                this.context.id = decoded.payload.id;
                this.context.email = decoded.payload.email;
                this.context.event_id = decoded.payload.event_id;
                this.context._id = decoded.payload._id;
                this.context.isAdmin = decoded.payload.isAdmin;
                this.context.profile = decoded.payload.profile;

                // disconnect the previous socket and connect again with the token
                this.props.onDisconnectSocket();
                this.props.onConnectSocket(response.data.token);
                this.props.onUpdateLive(response.data.token);

                let config = {
                    headers: {
                        "x-auth-token": this.context.token,
                        "content-type": "application/json",
                    }
                };
                let params = {
                    "type": "login",
                    "details": "",
                    "user_id": this.context.id,
                    "event_id": this.context.event_id
                };

                axios.post(configData.SERVER_API + "/logs/", params, config)   // log the login
                    .then(response => { })
                    .catch(error => { })

                let data = {
                    headers: {
                        "x-auth-token": this.context.token,
                        "content-type": "application/json",
                    },
                    params: { _id: this.context._id }
                };

                axios.get(configData.SERVER_API + "/users/me", data)    // get the details of the user
                    .then(response => {
                        const user = {
                            name: response.data.name !== undefined && response.data.name !== "" ? response.data.name : "",
                            surname: response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : "",
                            photo: response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png",
                            privacy_policy: response.data.privacy_policy,
                            privacy_policy_date: response.data.privacy_policy_date
                        }

                        if (this.context.event_settings.privacy_policy && !user.privacy_policy) {   // enteres the profile if set on Event and not true in User
                            this.setState({ user: user, loggedIn: true, step: "profile" });
                        } else if (this.context.event_settings.badge) {
                            this.setState({ user: user, loggedIn: true, step: "welcome" });
                        } else {
                            this.setState({ user: user, loggedIn: true });
                        }

                    })
                    .catch(error => { console.log(error.message); })


            })
                .catch(error => {
                    const errorCode = error.response !== undefined && error.response.data !== undefined && error.response.data !== "" ? error.response.data : "100";
                    this.setState({ loginError: true, loginErrorCode: errorCode })
                })

        } else {
            this.setState({ loginError: true, loginErrorCode: "100" });
        }
    }

    onPolicyAcceptedHandler = () => {
        if (this.state.welcome) {
            this.setState({ step: 'welcome' })
        } else {
            this.props.history.push('/congress/lobby');
        }
    }


    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }


    onRegistrationFormCloseHandler = () => {
        this.setState({ RegistrationFormPopUpOn: false });
    }

    onRegistrationFormOpenHandler = () => {
        this.setState({ RegistrationFormPopUpOn: true });
    }


    onRecoverPasswordCloseHandler = () => {
        this.setState({ RecoverPasswordPopUpOn: false });
    }

    onRecoverPasswordOpenHandler = () => {
        this.setState({ RecoverPasswordPopUpOn: true });
    }


    onResetPasswordCloseHandler = () => {
        this.setState({ ResetPasswordPopUpOn: false });
    }


    render() {

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const RegistrationFormPopUpOn = this.state.RegistrationFormPopUpOn ? <Registration event_id={this.context.event_settings.event_id} onRegistrationFormClose={this.onRegistrationFormCloseHandler}></Registration> : null;
        const RecoverPasswordPopUpOn = this.state.RecoverPasswordPopUpOn ? <RecoverPassword event_id={this.context.event_settings.event_id} onRecoverPasswordClose={this.onRecoverPasswordCloseHandler}></RecoverPassword> : null;
        const ResetPasswordPopUpOn = this.state.ResetPasswordPopUpOn ? <ResetPassword event_id={this.context.event_settings.event_id} resetCode={this.state.ResetPasswordCode} onResetPasswordClose={this.onResetPasswordCloseHandler}></ResetPassword> : null;

        if (this.state.step === "welcome") {
            setTimeout(() => {
                this.props.history.push('/congress/lobby');
            }, 6000);
        }

        let component;

        if (this.state.step === 'login') component = <Login
            loginError={this.state.loginError}
            loginErrorCode={this.state.loginErrorCode}
            emailChange={this.emailHandler}
            passwordChange={this.passwordHandler}
            loginSubmit={this.loginHandler}
            passwordRecover={this.context.event_settings.url_auth}
            passwordFree={this.state.pwd_free}
            loginEventId={this.context.event_settings.event_id}
            loginEventCode={this.context.event_code}
            onRegistrationFormOpen={this.onRegistrationFormOpenHandler}
            onRecoverPasswordOpen={this.onRecoverPasswordOpenHandler}
            loginEventActive={this.props.eventActive} />;

        if (this.state.step === 'recover') component = <LoginRecover />;

        if (this.state.step === 'reset') component = <LoginReset />;


        if (this.state.loggedIn) {

            if (this.state.step === "profile") {

                return <MyProfile
                    userId={this.context.id}
                    eventId={this.context.event_id}
                    name={this.state.user.name}
                    surname={this.state.user.surname}
                    email={this.context.email}
                    profile={this.context.profile}
                    position={this.context.event_settings.badge_position}
                    userPhoto={this.state.user.photo}
                    userRole={this.context.profile}
                    privacyPolicy={this.state.user !== undefined ? this.state.user.privacy_policy : false}
                    onPolicyAccepted={this.onPolicyAcceptedHandler}></MyProfile>

            } else if (this.state.step === "welcome") {

                return <WelcomeBadge
                    eventName={this.context.event_settings.name}
                    eventCode={this.context.event_code}
                    eventId={this.context.event_id}
                    userName={this.state.user.name + ' ' + this.state.user.surname}
                    profile={this.context.profile}
                    position={this.context.event_settings.badge_position}
                    userPhoto={this.state.user.photo}
                    userRole={this.context.profile}
                    sponsorLogo={this.context.event_settings.badge_sponsor}
                    sponsorLogoMain={this.context.event_settings.badge_sponsor_main}
                    background={this.context.event_settings.badge_background}></WelcomeBadge>

            } else {

                if (this.context.event_id == "408") {
                    return <Redirect to="/congress/e-posters" push={true} />
                } else if (this.context.event_id == "410") {
                    return <Redirect to="/congress/lobby-scientific" push={true} />
                }
                else {
                    return <Redirect to="/congress/lobby" push={true} />
                }

                //return <Redirect to="/congress/lobby" push={true} />
                //return <Redirect to="/congress/e-posters" push={true} />

            }

        } else {

            return (
                <div>
                    {this.context.event_settings.event_id === "387" || this.context.event_settings.event_id === "2" || this.context.event_settings.event_id === "3" || this.context.event_settings.event_id === "4" || this.context.event_settings.event_id === "5" ?
                        <Cookies event_id={this.context.event_settings.event_id} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler}></Cookies>
                        : null}


                    {CookiesPolicyPopUp}
                    {LegalNoticePopUp}
                    {RegistrationFormPopUpOn}
                    {RecoverPasswordPopUpOn}
                    {ResetPasswordPopUpOn}

                    <div className="login-container">
                        <div className="login-header-container">
                            <div className="close-login-container">
                                {/* <img src="/img/icon-close-login-panel.png" alt="" /> */}
                            </div>
                        </div>
                        {component}

                    </div>
                </div>

            );

        }
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket()),
        onUpdateLive: (token) => dispatch(updateLive(token))
    }
}

export default connect(null, mapDispatchToProps)(withRouter(LoginArea));