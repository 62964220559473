import React, { Component } from 'react';
import configData from '../../../../../config.json';
import AuthContext from '../../../../../context/auth-context';
import axios from 'axios';
import MyBagCertificate from '../../../../../components/MyBagCertificate/MyBagCertificate';
import SurveySession from '../../../../Survey/SurveySession';

class MyCreditsSession extends Component {

    state = {
        complete: false,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        q9: null,
        q10: null,
        creditsList: [],
        sessionToEvaluate: null
    }

    static contextType = AuthContext;

    componentDidMount() {
        /* let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };
        
        axios.get(configData.SERVER_API + "/users/me/survey", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0){
                    axios.get(configData.SERVER_API + "/users/me/credits-congress", data)
                    .then(response => {
                        console.log(response.data)
                        this.setState({complete:true, creditsList:response.data})
                    })
                    .catch(error => {
                        this.setState({complete:true})
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            }) */
    }

    onSurveySaveHandler = () => {


        let config = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };

        const mySurvey = {
            user_id: this.context.id,
            q1: this.state.q1,
            q2: this.state.q2,
            q3: this.state.q3,
            q4: this.state.q4,
            q5: this.state.q5,
            q6: this.state.q6,
            q7: this.state.q7,
            q8: this.state.q8,
            q9: this.state.q9,
            q10: this.state.q10,
        }


        if (this.state.q1 !== null &&
            this.state.q2 !== null &&
            this.state.q3 !== null &&
            this.state.q4 !== null &&
            this.state.q5 !== null &&
            this.state.q6 !== null &&
            this.state.q7 !== null &&
            this.state.q8 !== null &&
            this.state.q9 !== null) {

            axios.post(configData.SERVER_API + '/users/me/survey', mySurvey, config)
                .then(response => {
                    this.setState({ complete: true })
                })
                .catch(error => {
                    //console.log(error)
                })
        } else {
            alert('Por favor complete todas las preguntas.')
        }

    }

    onSetAnwserHandler = (event) => {
        switch (event.target.name) {
            case "credits-form1":
                this.setState({ q1: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form2":
                this.setState({ q2: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form3":
                this.setState({ q3: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form4":
                this.setState({ q4: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form5":
                this.setState({ q5: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form6":
                this.setState({ q6: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form7":
                this.setState({ q7: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form8":
                this.setState({ q8: event.target.value !== "" ? event.target.value : null })
                break;

            case "credits-form9":
                this.setState({ q9: event.target.value !== "" ? event.target.value : null })
                break;

            default:
                break;
        }
    }

    onCommentsHandler = (text) => {
        this.setState({ q10: text });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    onEvaluateSession = (session_id) => {
        this.setState({ sessionToEvaluate: session_id })
    }

    onEvaluateClose = () => {
        this.setState({ sessionToEvaluate: null })
    }


    render() {

        let creditsList;
        if (this.state.creditsList !== undefined && this.state.creditsList.length > 0) {
            const creditsArray = this.state.creditsList.map((el) => {
                return (<MyBagCertificate key={el._id} title="Certificado de Creditos" file={el.file} />)
            })
            creditsList = <React.Fragment>
                <table border="0" cellSpacing="0" cellPadding="0" className="tbl-mybag-certificates">
                    <tbody>
                        {creditsArray}
                    </tbody>
                </table>
            </React.Fragment>
        }



        return (
            <React.Fragment>
                <div style={{ clear: `both` }}></div>
                <div className="menu-tab-item selected">
                    CRÉDITOS CONGRESO
                    {this.context.event_settings.event_id != "391" ?
                        <span> (Pendientes de resolución)</span>
                        : null}

                </div>
                <div style={{ clear: `both` }}></div>
                <div className="tabs-my-bag" id="tab-credits">
                    <div style={{ fontFamily: `MyriadPro` }}>

                        {this.context.event_settings.event_id == "289" ?

                            <div>
                                {this.state.complete}

                                <p>Informamos que, para obtener la acreditación, una vez ésta sea concedida, es necesario asistir en directo a las sesiones al menos un 80% de la duración de la misma y realizar la encuesta correspondiente al finalizar. La asistencia a las sesiones en diferido no está acreditada. </p>

                                <p>Sesiones con acreditación solicitada</p>


                                <p><strong>11 DE MARZO DE 2021</strong></p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0124')} className="cursor-only">-> Jornada de Jóvenes Investigadores</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0123')} className="cursor-only">-> Mesa Redonda: Tratamiento de la infección comunitaria: Entre expertos anda el juego</p>

                                <p><strong>12 DE MARZO DE 2021</strong></p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd012f')} className="cursor-only">-> Inauguración: Conferencia inaugural 20 años de SEIP</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd012c')} className="cursor-only">-> Mesa Redonda: Resistencias antibióticas. PROA Pediátrico</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0133')} className="cursor-only">-> Mesa Redonda: Patología importada</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0128')} className="cursor-only">-> Encuentros con los expertos: Infecciones en pacientes especiales.</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0130')} className="cursor-only">-> Encuentros con los expertos: Infección fúngica invasora.</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd012e')} className="cursor-only">-> Abordaje de la Infecciones causadas por Gram+</p>

                                <strong>13 DE MARZO DE 2021</strong><br />
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0137')} className="cursor-only">-> Saturday Monrning Fever: Casos clínicos de Fiebre en Pediatría.</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0139')} className="cursor-only">-> Mesa Redonda: Meningococo: novedades buenas y malas.</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd013b')} className="cursor-only">-> Conferencia Magistral: Un mundo sin VIH</p>
                                <p style={{ display: "none" }}>Mesa Redonda: Infecciones Prevalentes en Latinoamérica</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0135')} className="cursor-only">-> Chagas congénito</p>
                                <p onClick={() => this.onEvaluateSession('60487955aec8de2520bd0138')} className="cursor-only">-> Casos Clínico: Infección por VIH. Reunión conjunta con PLANTAIDS</p>


                                <p>&nbsp;</p>

                                <p>Una vez que la SIAFOC (Sistema de Acreditación n de la Formación Continuada) haya otorgado los créditos, estos serán asignados a quienes hayan cumplido los requisitos mencionados y serán enviados por correo electrónico.</p>




                            </div>


                            :

                            this.context.event_settings.event_id == "315" ?

                                <div>
                                    <p>REQUISITOS NECESARIOS PARA OBTENER LA ACREDITACIÓN</p>

                                    <p>Les informamos que para obtener los 6,1 créditos concedidos por la Agencia de Formación Continuada (AFOC), es necesario cumplir los tres requisitos siguientes:</p>

                                    <ol>
                                        <li>Asistir al 100% de las sesiones del curso</li>
                                        <li>Cumplimentar la encuesta de satisfacción</li>
                                        <li>Aprobar el test de conocimientos post-curso</li>
                                    </ol>

                                    <p>Para facilitar la asistencia a las ponencias, las mismas estarán disponibles en la plataforma del congreso <strong>hasta el día 21 de marzo de 2021</strong>. A partir de esa fecha no será posible acceder a ese requisito imprescindible para obtener la acreditación</p>

                                    <p>Las personas que hayan completado el 100% de la asistencia y los dos test indicados por la AFOC recibirán el correspondiente certificado acreditativo por correo electrónico</p>

                                    <p>Les recordamos que, según la normativa vigente de la AFOC, los médicos en formación no pueden obtener la acreditación oficial del curso.</p>

                                </div>

                                :

                                this.context.event_settings.event_id == "391" ?

                                    <div>
                                        <p>Para obtener los créditos de cada una de las sesiones debe de cumplir el requisito de asistir el 100% del tiempo que dura la sesión y cumplimentar la encuesta para cada una de las sesiones.</p>
                                        <p>&nbsp;</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c7a')} className="cursor-only">-> Inauguración Grande Covián</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c7b')} className="cursor-only">-> Mesa Nutrición</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c7f')} className="cursor-only">-> Mesa de Atención Primaria y Diabetes</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c83')} className="cursor-only">-> Mesa U de Lípidos y Registro. El laboratorio y las pruebas de imagen en el proceso diagnóstico de las hiperlipemias.</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c8d')} className="cursor-only">-> Mesa biología vascular</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c89')} className="cursor-only">-> Mesa Prevención Secundaria. Nuevas perspectivas en la prevención secundaria de la aterosclerosis prematura</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c8b')} className="cursor-only">-> Mesa Dislipemia Aterogénica</p>
                                        <p onClick={() => this.onEvaluateSession('609e821b175a011f0c4a5c7d')} className="cursor-only">-> Mesa Redonda. ¿Qué no deberíamos hacer en pacientes con...?</p>
                                    </div>

                                    :

                                    <div>

                                        <p>Informamos que, para obtener la acreditación, una vez ésta sea concedida, es necesario asistir en directo a las sesiones al menos un 80% de la duración de la misma y realizar la encuesta correspondiente al finalizar. La asistencia a las sesiones en diferido no está acreditada.  </p>

                                        <p>Sesiones con acreditación solicitada: </p>

                                        <p>Plenaria I, Plenaria II, Pro-Con I, Pro-Con II y Seminario.  </p>

                                        <p>Una vez que la Consejería de la Comunidad de Madrid haya otorgado los créditos, estos serán asignados a quienes hayan cumplido los requisitos mencionados y serán enviados por correo electrónico. </p>

                                        <p>&nbsp;</p>

                                        <p onClick={() => this.onEvaluateSession('5fa29fc56fdac74418a80a96')} className="cursor-only">-> Encuesta Sesión Plenaria I</p>
                                        <p onClick={() => this.onEvaluateSession('5fa29fc56fdac74418a80a9e')} className="cursor-only">-> Encuesta Sesión Plenaria II</p>
                                        <p onClick={() => this.onEvaluateSession('5fa29fc56fdac74418a80a97')} className="cursor-only">-> Encuesta Sesión Pro Con I</p>
                                        <p onClick={() => this.onEvaluateSession('5fa29fc56fdac74418a80a9f')} className="cursor-only">-> Encuesta Sesión Pro Con II</p>
                                        <p onClick={() => this.onEvaluateSession('5fa29fc56fdac74418a80a98')} className="cursor-only">-> Encuesta Sesión Seminario</p>

                                    </div>

                        }

                        {!this.state.complete
                            ? null
                            : creditsList
                        }

                    </div>
                </div>

                {this.state.sessionToEvaluate !== null && this.state.sessionToEvaluate !== ""
                    ? <SurveySession
                        sessionId={this.state.sessionToEvaluate}
                        evaluateClose={this.onEvaluateClose}></SurveySession>
                    : null}

            </React.Fragment>
        );
    }
}

export default MyCreditsSession;