import React, { Component } from 'react';
import { connect } from 'react-redux'
import { connectSocket, disconnectSocket } from '../../../store/actions/global';
import Profile from './Profile/Profile';
import Nav from './Nav/Nav';
import Search from './Search/Search';
import Live from '../../../components/Live/Live'
import Audiance from '../../../components/Audiance/Audiance'
import MyBagInfo from '../../../components/MyBagInfo/MyBagInfo'
import CongressInfo from './CongressInfo/CongressInfo';
import MyCongressInfoDetails from '../../../components/MyCongressInfo/MyCongressInfoDetails';
import AuthContext from "../../../context/auth-context";
import axios from 'axios';
import configData from '../../../config.json';
import LiveFeed from '../../../components/LiveFeed/LiveFeed';


class TopBar extends Component {

    static contextType = AuthContext;

    state = {
        showProfileMenu: false,
        showNotificationMenu: false,
        notifications: [],
        notifications_new: [],
        notificationsTotal: 0,
        autoOpen: true
    }


    showProfielMenuHandler = () => {
        this.setState((prevState, props) => {
            return {
                showProfileMenu: !prevState.showProfileMenu
            };
        })
    }

    showNotificationMenuHandler = () => {
        if (this.state.notificationsTotal > 0) {
            this.setState((prevState, props) => {
                return {
                    showNotificationMenu: !prevState.showNotificationMenu
                };
            })
        }
    }

    componentDidMount() {
        this.loadNotifications();
        /* setInterval(() => {
            this.loadNotifications();   
        }, 30000); */
    }

    loadNotifications() {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };


        axios.get(configData.SERVER_API + "/push-notifications/", data)
            .then(response => {

                let autoOpen;
                const notifications_all = response.data;
                const notification_new = response.data.filter((el) => {
                    return el.new === true;
                })

                if (notification_new !== undefined && notification_new.length !== this.state.notifications_new.length && this.state.autoOpen) {
                    autoOpen = true;
                } else {
                    autoOpen = false;
                }

                this.setState({
                    notifications: notifications_all,
                    notifications_new: notification_new,
                    notificationsTotal: notifications_all !== undefined ? notifications_all.length : 0,
                    showNotificationMenu: autoOpen ? autoOpen : this.state.showNotificationMenu,
                    autoOpen: !autoOpen
                });
            })
            .catch(error => {
                console.log(error.message);
            })
    }


    logoutHandler = () => {

        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.email = '';
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

        // close of the socket

    }


    render() {

        const classOpen = this.state.showNotificationMenu ? "right-info-item selected" : "right-info-item";

        const notifications = this.state.showNotificationMenu ? <MyCongressInfoDetails notifications={this.state.notifications} close={this.showNotificationMenuHandler}></MyCongressInfoDetails> : null;

        return (
            <div className={'topbar-container ' + this.context.event_code}>

                <Profile showProfileMenu={this.state.showProfileMenu} showHide={this.showProfielMenuHandler}></Profile>
                <Nav eventId={this.context.event_settings.event_id} logoutHandler={this.logoutHandler}></Nav>
                {/* <CongressInfo></CongressInfo> */}

                <div className="live-sessions-container">
                    <div className="live-sessions-wrapper">

                        {this.context.event_settings.event_id !== "382" && this.context.event_settings.event_id !== "4" ?
                            <div className="live-info">
                                <span className="live-text">LIVE</span>
                            </div>
                            : null}

                        {this.context.event_settings.event_id !== "382" && this.context.event_settings.event_id !== "4" ?
                            <div className="live-sessions-separator"></div>
                            : null}

                        <LiveFeed live={this.props.live}></LiveFeed>

                        {/* {liveSessions.length > 0
                        ? <div className="live-sessions-separator"></div> 
                        : null
                        } */}
                        {this.context.event_settings.event_id !== "382" && this.context.event_settings.event_id !== "387" && this.context.event_settings.event_id !== "2" && this.context.event_settings.event_id !== "390" && this.context.event_settings.event_id !== "3" && this.context.event_settings.event_id !== "4" && this.context.event_settings.event_id !== "5" && this.context.event_settings.event_id !== "327" && this.context.event_settings.event_id !== "410" ?
                            <div className="right-info-container">

                                <div className="right-info-wrapper" onClick={this.showNotificationMenuHandler}>
                                    <div className={classOpen}>
                                        <div className="number-wrapper">
                                            {this.state.notificationsTotal > 0
                                                ? <span className="span-number">{this.state.notificationsTotal}</span>
                                                : <br></br>}
                                        </div>

                                        <div className="right-info-icon-wrapper">
                                            <img alt="" src="/img/icon-megaphone.png" />
                                        </div>

                                    </div>
                                </div>

                                <MyBagInfo showHide={this.showProfielMenuHandler} eventId={this.context.event_settings.event_id}></MyBagInfo>

                                {/* <Audiance></Audiance> */}

                            </div> : null
                        }
                    </div>
                </div>

                {notifications}

                <Search></Search>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        live: state.live.sessions,
        forceLogout: state.global.forceLogout
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TopBar);