import React from 'react';

function Days(props) {
    
    const days = props.days;
    const day = props.defaultDay;

    const daysWidth = Math.round(100/days.length) + '%';
    let daysRender = days.map((item,index) => {
        if (day === item){
            return <div key={index} className="btn-day selected" style={{width:daysWidth}} onClick={() => props.changeDay(item)}>{item}</div>
        } else  {
            return <div key={index} className="btn-day" style={{width:daysWidth}} onClick={() => props.changeDay(item)}>{item}</div>
        }

    })  

    return (
        <div className="select-days-container">        
            <div className="select-days-wrapper">
                {daysRender}
            </div>
        </div>
    );
}

export default Days;