import React from 'react';

function ChatBar(props) {
    return (
        <div className="chat-container cursor-only" onClick={props.showHide}>        
            <div className="chat-bar">
                <div className="icon-chat">
                    <img alt="" src="/img/icon-chat.png" />
                </div>
                <div className="chat-bar-text"><strong>CHAT</strong>(175)</div>
                {/* <div className="chat-add">
                    <img alt="" src="/img/icon-chat-add.png" />
                </div>
                <div className="chat-settings">
                    <img alt="" src="/img/icon-chat-settings.png" />
                </div>
                <div className="chat-write">
                    <img alt="" src="/img/icon-chat-write.png" />
                </div> */}
            </div>
        </div>
    );
}

export default ChatBar;