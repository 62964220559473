import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from "../../../context/auth-context";
import axios from 'axios';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import configData from '../../../config.json';
import TermsConditions from '../../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../../components/PrivacyPolicy/PrivacyPolicy';




class Lobby extends Component {

    static contextType = AuthContext;

    state = {
        videoCenter: '',
        leftTopVideo: '',
        leftTop: '',
        leftTopLink: '',
        leftTopLinkTarget: '',
        rightTopVideo: '',
        rightTop: '',
        rightTopLink: '',
        rightTopLinkTarget: '',
        leftBottomVideo: '',
        leftBottom: '',
        leftBottomLink: '',
        leftBottomLinkTarget: '',
        rightBottomVideo: '',
        rightBottom: '',
        rightBottomLink: '',
        rightBottomLinkTarget: '',
        externalLink: '',
        externalLinkActive: '',
        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby', ''),
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false
    }

    componentDidMount() {

        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };
        axios.get(configData.SERVER_API + "/lobby/", data)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    const lobby = response.data[0];
                    this.setState({
                        videoCenter: lobby.videoCenter,
                        leftTopVideo: lobby.leftTopVideo !== undefined ? lobby.leftTopVideo : false,
                        leftTop: lobby.leftTop,
                        leftTopLink: lobby.leftTopLink,
                        leftTopLinkTarget: lobby.leftTopLinkTarget !== undefined ? lobby.leftTopLinkTarget : '',
                        rightTopVideo: lobby.rightTopVideo !== undefined ? lobby.rightTopVideo : false,
                        rightTop: lobby.rightTop,
                        rightTopLink: lobby.rightTopLink,
                        rightTopLinkTarget: lobby.rightTopLinkTarget !== undefined ? lobby.rightTopLinkTarget : '',
                        leftBottomVideo: lobby.leftBottomVideo !== undefined ? lobby.leftBottomVideo : false,
                        leftBottom: lobby.leftBottom,
                        leftBottomLink: lobby.leftBottomLink,
                        leftBottomLinkTarget: lobby.leftBottomLinkTarget !== undefined ? lobby.leftBottomLinkTarget : '',
                        rightBottomVideo: lobby.rightBottomVideo !== undefined ? lobby.rightBottomVideo : false,
                        rightBottom: lobby.rightBottom,
                        rightBottomLink: lobby.rightBottomLink,
                        rightBottomLinkTarget: lobby.rightBottomLinkTarget !== undefined ? lobby.rightBottomLinkTarget : '',
                        externalLink: lobby.externalLink !== undefined ? lobby.externalLink : '',
                        externalLinkActive: lobby.externalLinkActive !== undefined ? lobby.externalLinkActive : false,
                        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby', '')
                    });
                } else {
                    this.setState({
                        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby', '')
                    })
                }

            })
            .catch(error => {
                this.setState({
                    backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'lobby', '')
                })
                console.log(error.message);
            })


        /* let nBeeps = 0;
        let beep = setInterval(() => {
            if(nBeeps > 2 ) {
                clearInterval(beep);
            } else {
                nBeeps++;
                document.getElementById('audioBip').play();
            }
        }, 500); */

    }


    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }


    logoutHandler = () => {

        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.email = '';
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

        // close of the socket

    }

    render() {

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';



        const leftTopContent = this.state.leftTopLink !== "" && this.state.leftTopLink !== undefined
            //? <Link to={this.state.leftTopLink}><Banner banner={this.state.leftTop}></Banner></Link>
            //: <Banner banner={this.state.leftTop}></Banner>

            ? this.state.leftTopLinkTarget !== ""
                ? <Banner url={this.state.leftTopLink} target={this.state.leftTopLinkTarget} banner={this.state.leftTop}></Banner>

                :

                this.state.leftTopVideo == true ?
                    <Banner video={this.state.leftTopVideo} url={this.state.leftTopLink} banner={this.state.leftTop}></Banner>
                    : <Link to={this.state.leftTopLink}><Banner banner={this.state.leftTop}></Banner></Link>

            : <Banner banner={this.state.leftTop}></Banner>

        const leftBottomContent = this.state.leftBottomLink !== "" && this.state.leftBottomLink !== undefined
            //? <Link to={this.state.leftBottomLink}><Banner banner={this.state.leftBottom}></Banner></Link>
            //: <Banner banner={this.state.leftBottom}></Banner>

            ? this.state.leftBottomLinkTarget !== ""
                ? <Banner url={this.state.leftBottomLink} target={this.state.leftBottomLinkTarget} banner={this.state.leftBottom}></Banner>

                :

                this.state.leftBottomVideo == true ?
                    <Banner video={this.state.leftBottomVideo} url={this.state.leftBottomLink} banner={this.state.leftBottom}></Banner>
                    : <Link to={this.state.leftBottomLink}><Banner banner={this.state.leftBottom}></Banner></Link>

            : <Banner banner={this.state.leftBottom}></Banner>


        const rightTopContent = this.state.rightTopLink !== "" && this.state.rightTopLink !== undefined



            ? this.state.rightTopLinkTarget !== ""
                ? <Banner url={this.state.rightTopLink} target={this.state.rightTopLinkTarget} banner={this.state.rightTop}></Banner>

                :

                this.state.rightTopVideo == true ?
                    <Banner video={this.state.rightTopVideo} url={this.state.rightTopLink} banner={this.state.rightTop}></Banner>
                    : <Link to={this.state.rightTopLink}><Banner banner={this.state.rightTop}></Banner></Link>

            : <Banner banner={this.state.rightTop}></Banner>


        const rightBottomContent = this.state.rightBottomLink !== "" && this.state.rightBottomLink !== undefined
            //? <Link to={this.state.rightBottomLink}><Banner banner={this.state.rightBottom}></Banner></Link>
            //: <Banner banner={this.state.rightBottom}></Banner>


            ? this.state.rightBottomLinkTarget !== ""
                ? <Banner url={this.state.rightBottomLink} target={this.state.rightBottomLinkTarget} banner={this.state.rightBottom}></Banner>

                :

                this.state.rightBottomVideo == true ?
                    <Banner video={this.state.rightBottomVideo} url={this.state.rightBottomLink} banner={this.state.rightBottom}></Banner>
                    : <Link to={this.state.rightBottomLink}><Banner banner={this.state.rightBottom}></Banner></Link>

            : <Banner banner={this.state.rightBottom}></Banner>



        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;



        return (

            <div>

                <div>
                    {CookiesPolicyPopUp}
                    {LegalNoticePopUp}
                    {TermsConditionsPopUp}
                    {PrivacyPolicyPopUp}
                    <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>
                </div>

                <div className="lobby-content-container">

                    {this.context.event_settings.event_id === "4" ?

                        <div className="etev2021-lobby-container">

                            <div className="access-etev2021-lobby">

                                <a href="/congress/lobby-scientific"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-access-recordings.png" /></a>

                            </div>

                            <div className="btns-etev2021-lobby-container">

                                <div className="btns-etev2021-lobby">
                                    <a href="/congress/programme"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-programa.png" /></a>
                                </div>
                                <div className="btns-etev2021-lobby">
                                    <a href="/congress/e-posters"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-posters.png" /></a>
                                </div>
                                <div className="btns-etev2021-lobby">
                                    <a href="/congress/speakers"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-ponentes.png" /></a>
                                </div>


                            </div>


                            <div className="btns-etev2021-lobby-container-mobile">

                                <a href="/congress/programme"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-programa.png" /></a><br />
                                <a href="/congress/e-posters"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-posters.png" /></a><br />
                                <a href="/congress/speakers"><img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/etev2021-lobby-btn-ponentes.png" /></a>

                            </div>


                            <div className="rovi-etev2021-lobby-container">

                                <img src="https://www.emma.events/static/virtualvenue/etev2021/lobby/rovi-logo-lobby.png" />

                            </div>



                        </div>

                        :

                        <div className="screens-container">

                            {/* <audio src="/audio/1616.wav" id="audioBip" preload="auto"></audio> */}



                            <div className={'screens-wrapper ' + this.context.event_code.toLowerCase()}>

                                <div className="left-screen-wrapper">
                                    {leftTopContent}
                                    {leftBottomContent}
                                </div>

                                <div className="main-screen-wrapper">
                                    <div className={'top-mainscreen-wrapper ' + this.context.event_code.toLowerCase()}>
                                        <div className="top-left-mainscreen"></div>
                                        <div className="top-right-mainscreen"></div>
                                    </div>
                                    <div className={'main-screen ' + this.context.event_code.toLowerCase()}>
                                        <div className="preview-dark">

                                            {this.context.event_settings.event_id === "3" ?
                                                <div>
                                                    <p>Haga clinc en el boton para acceder a las grabaciones de los tromboencuentros.</p>
                                                    <p style={{ display: "none" }}>Puede hacer dejar de forma anticipada sus preguntas acerca del tema del encuentro. No debe incluir datos de carácter identificativo en las mismas.</p>
                                                    <p style={{ display: "none" }}>Haga clic en el botón para acceder en directo<br />17:30 / 18:45 - 30 NOVIEMBRE 2021<br />Manejo de la TVP residual y prevención de posibles secuelas</p>
                                                </div>
                                                : this.context.event_settings.event_id === "4" ?
                                                    <div>
                                                        <p>Puede hacer dejar de forma anticipada sus preguntas acerca del tema del encuentro. No debe incluir datos de carácter identificativo en las mismas.</p>
                                                        <p style={{ display: "none" }}>Haga clic en el botón para acceder en directo<br />17:30 / 18:45 - 26 MAYO 2021<br />Manejo del paciente frágil con ETV</p>
                                                    </div>
                                                    :
                                                    <iframe src={this.state.videoCenter} width="450" height="200" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                            }
                                        </div>
                                    </div>

                                    <div className="banner-central-btns">
                                        {this.state.externalLinkActive}
                                        {this.context.event_settings.event_id === "366" ?
                                            BannerCentralAEA2020()
                                            : this.context.event_settings.event_id === "314" ?
                                                BannerCentralSEPAR2020()
                                                : this.context.event_settings.event_id === "382" ?
                                                    //BannerCentralPSOE2021()
                                                    <BannerCentralPSOE2021 externalLinkActive={this.state.externalLinkActive} externalLink={this.state.externalLink} />
                                                    : this.context.event_settings.event_id === "387" ?
                                                        BannerCentralVITDAH()
                                                        : this.context.event_settings.event_id === "2" ?
                                                            BannerCentralUROVI2021()
                                                            : this.context.event_settings.event_id === "390" ?
                                                                BannerCentralASEPB21()
                                                                : this.context.event_settings.event_id === "378" ?
                                                                    BannerCentral2CONGRESOCOVID()
                                                                    : this.context.event_settings.event_id === "3" ?
                                                                        BannerCentralENCUENTROSEXPERTO2021()
                                                                        : this.context.event_settings.event_id === "4" ?
                                                                            BannerCentralETEV2021()
                                                                            : this.context.event_settings.event_id === "325" ?
                                                                                BannerCentralSEMCPT2021()
                                                                                : this.context.event_settings.event_id === "341" ?
                                                                                    BannerCentralESTS2021()
                                                                                    : this.context.event_settings.event_id === "263" ?
                                                                                        BannerCentralEACMFS2021()
                                                                                        : this.context.event_settings.event_id === "351" ?
                                                                                            BannerCentralSECEC2021()
                                                                                            : this.context.event_settings.event_id === "337" ?
                                                                                                BannerCentralAEASEROD2021()
                                                                                                : this.context.event_settings.event_id === "379" ?
                                                                                                    BannerCentralNEUMO2021()
                                                                                                    : this.context.event_settings.event_id === "5" ?
                                                                                                        BannerCentralVIITDAH2022()
                                                                                                        : this.context.event_settings.event_id === "391" ?
                                                                                                            <React.Fragment>
                                                                                                                {BannerCentralSEA2021()}
                                                                                                            </React.Fragment>
                                                                                                            : null}
                                    </div>


                                </div>


                                <div className="right-screen-wrapper">
                                    {rightTopContent}
                                    {rightBottomContent}

                                </div>




                            </div>

                            <div className="banner-central-btns-mobile">
                                {this.state.externalLinkActive}
                                {this.context.event_settings.event_id === "366" ?
                                    BannerCentralAEA2020()
                                    : this.context.event_settings.event_id === "314" ?
                                        BannerCentralSEPAR2020()
                                        : this.context.event_settings.event_id === "382" ?
                                            //BannerCentralPSOE2021()
                                            <BannerCentralPSOE2021 externalLinkActive={this.state.externalLinkActive} externalLink={this.state.externalLink} />
                                            : this.context.event_settings.event_id === "387" ?
                                                BannerCentralVITDAH()
                                                : this.context.event_settings.event_id === "2" ?
                                                    BannerCentralUROVI2021()
                                                    : this.context.event_settings.event_id === "390" ?
                                                        BannerCentralASEPB21()
                                                        : this.context.event_settings.event_id === "378" ?
                                                            BannerCentral2CONGRESOCOVID()
                                                            : this.context.event_settings.event_id === "3" ?
                                                                BannerCentralENCUENTROSEXPERTO2021()
                                                                : this.context.event_settings.event_id === "4" ?
                                                                    BannerCentralETEV2021()
                                                                    : this.context.event_settings.event_id === "391" ?
                                                                        BannerCentralSEA2021()
                                                                        : this.context.event_settings.event_id === "341" ?
                                                                            BannerCentralESTS2021()
                                                                            : this.context.event_settings.event_id === "263" ?
                                                                                BannerCentralEACMFS2021()
                                                                                : this.context.event_settings.event_id === "351" ?
                                                                                    BannerCentralSECEC2021()
                                                                                    : this.context.event_settings.event_id === "337" ?
                                                                                        BannerCentralAEASEROD2021()
                                                                                        : this.context.event_settings.event_id === "379" ?
                                                                                            BannerCentralNEUMO2021()
                                                                                            : this.context.event_settings.event_id === "5" ?
                                                                                                BannerCentralVIITDAH2022()
                                                                                                : null}
                            </div>

                            <div className="side-screens-mobile">

                                {leftTopContent}
                                {rightTopContent}
                                {leftBottomContent}
                                {rightBottomContent}

                            </div>

                        </div>

                    }


                </div>

            </div>
        );
    }

}


function Banner(props) {

    let externalURL = props.url !== undefined ? props.url : "";
    let target = props.target !== undefined ? props.target : "";

    let SideScreenImage = externalURL != "" ? <a href={externalURL} target={target}><img alt="" src={props.banner} /></a> : <img alt="" src={props.banner} />;

    let VideoIframe = <iframe src={props.url} width="300" height="150" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>;


    if (props.banner !== "") {

        if (props.video == true) {

            return (

                <div>
                    <div className="top-sidescreen-wrapper">
                        <div className="top-left-sidescreen"></div>
                        <div className="top-right-sidescreen"></div>
                    </div>
                    <div className="top-screen-bar"></div>
                    <div className="side-screen">
                        {VideoIframe}
                    </div>
                </div>

            );

        }
        else {
            return (

                <div>
                    <div className="top-sidescreen-wrapper">
                        <div className="top-left-sidescreen"></div>
                        <div className="top-right-sidescreen"></div>
                    </div>
                    <div className="top-screen-bar"></div>
                    <div className="side-screen">
                        {SideScreenImage}
                    </div>
                </div>

            );
        }


    }
    else {
        return (null);
    }


}


function BannerCentralSECEC2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a target="_blank" href="https://bit.ly/SECEC2021"><img src="https://www.emma.events/static/virtualvenue/secec2021/lobby/depuy-lobby.jpg" /></a>

                <br /><br />
                <a target="_blank" href="https://www.conmed.com/"><img src="https://www.emma.events/static/virtualvenue/secec2021/lobby/conmed-lobby.jpg" /></a>


                <br /><br />
                <a target="_blank" href="https://www.anikatherapeutics.com/"><img src="https://www.emma.events/static/virtualvenue/secec2021/lobby/anika-lobby.jpg" /></a>

                <br /><br />
                <a target="_blank" href="https://www.mathysmedical.com/en/homepage"><img src="https://www.emma.events/static/virtualvenue/secec2021/lobby/mathys-lobby-2.jpg" /></a>

            </div>

        </div>
    );
}



function BannerCentralAEASEROD2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a target="_blank" href="https://www.mba.eu/es/"><img src="https://www.emma.events/static/virtualvenue/aeaserod2021/lobby/mba-lobby.jpg" /></a>
            </div>

        </div>
    );
}


function BannerCentralNEUMO2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <img src="https://www.emma.events/static/virtualvenue/neumomadrid2021/lobby/neumo2021-hashtag.jpg" />
            </div>

        </div>
    );
}





function BannerCentralAEA2020(props) {
    return (
        <div className="main-screen-bottom">
            <div className="main-screen-bottom-text">
                <strong>BIENVENIDA</strong><br />
                Dr. Francesc Soler - Presidente de la AEA
            </div>
            {/* <div className="main-screen-bottom-logo">
                <img alt="" src="/img/logo-main-screen-bottom.png" />
            </div> */}
        </div>
    );
}


function BannerCentralSEPAR2020(props) {
    return (
        <div className="main-screen-bottom separ2020">

            <div className="logo-main-screen-bottom-left">
                <a href="/congress/industria"><img src="https://www.emma.events/static/virtualvenue/separ2020/lobby/expo-lobby.jpg" /></a>
            </div>
            <div className="logo-main-screen-bottom-right">
                <a target="_blank" href="https://www.congresosepar.com/enaras/"><img src="https://www.emma.events/static/virtualvenue/separ2020/lobby/enaras-lobby.png" /></a>
            </div>

        </div>
    );
}


function BannerCentralSEMCPT2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/congress/industria-stand/depuy"><img src="https://www.emma.events/static/virtualvenue/semcpt2021/lobby/depuy-lobby-logo.jpg" /></a>
                <br /><br />
                <a target="_blank" href="http://intranet.customimplants.es/stand_virtual.html"><img src="https://www.emma.events/static/virtualvenue/semcpt2021/lobby/custom-lobby-logo.jpg" /></a>
            </div>

        </div>
    );
}


function BannerCentralEACMFS2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/congress/industria-stand/kls"><img src="https://www.emma.events/static/virtualvenue/eacmfs2021/lobby/kls-lobby.jpg" /></a>

                <br /><br />
                <a href="/congress/industria-stand/medartis"><img src="https://www.emma.events/static/virtualvenue/eacmfs2021/lobby/medartis-lobby.jpg" /></a>

                <br /><br />
                <a href="/congress/industria-stand/stryker"><img src="https://www.emma.events/static/virtualvenue/eacmfs2021/lobby/srtyker-lobby.jpg" /></a>
            </div>

        </div>
    );
}


function BannerCentralESTS2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/congress/industria-stand/ethicon"><img src="https://www.emma.events/static/virtualvenue/ests2021/lobby/ests2021-lobby-ethicon.jpg" /></a>

                <br /><br />
                <a target="_blank" href="https://www.roche.es/"><img src="https://www.emma.events/static/virtualvenue/ests2021/lobby/ests2021-lobby-roche.jpg" /></a>

                <br /><br />
                <a href="/congress/industria-stand/medela"><img src="https://www.emma.events/static/virtualvenue/ests2021/lobby/ests2021-lobby-medela2.jpg" /></a>
            </div>

        </div>
    );
}


function BannerCentralPSOE2021(props) {

    let ExternalLink = props.externalLinkActive === true ? props.externalLink : "#";
    let ExternalLinkTarget = props.externalLinkActive === true ? "_blank" : "_self";

    return (
        <div className="main-screen-bottom psoe2021">
            <br />
            <a href={ExternalLink} target={ExternalLinkTarget} className="main-screen-bottom-full-btn">ACCESO AL COMITÉ FEDERAL<br />Habilitado desde el sábado a las 9.00horas</a>

        </div>
    );
}


function BannerCentralVITDAH(props) {
    return (
        <div className="main-screen-bottom vitdah">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-left">
                <a href="/room/1/603654c86b67505244464d48"><span>3 MARZO 2021</span></a>
            </div>
            <div className="logo-main-screen-bottom-right">
                <a href="/room/1/6036551b6b67505244464d49"><span>4 MARZO 2021</span></a>
            </div>

        </div>
    );
}



function BannerCentralUROVI2021(props) {
    return (
        <div className="main-screen-bottom urovi2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-left" style={{ display: "none" }}>
                <a href="/room/1/604f7bc78f500245648ffac2"><span>24 MARZO 2021</span></a>
            </div>
            <div className="logo-main-screen-bottom-right" style={{ display: "none" }}>
                <a href="/room/1/604f7bc78f500245648ffac3"><span>25 MARZO 2021</span></a>
            </div>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/1/604f7bc78f500245648ffac3"><span>ACCESO AL DIRECTO - 25 MARZO</span></a>
            </div>

        </div>
    );
}


function BannerCentralVIITDAH2022(props) {
    return (
        <div className="main-screen-bottom urovi2021 tromboencuentros2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/1/61728f84ceb7ae0418b82c2a"><span>ACCESO AL STREAMING</span></a>
            </div>

        </div>
    );
}


function BannerCentralENCUENTROSEXPERTO2021(props) {
    return (
        <div className="main-screen-bottom urovi2021 tromboencuentros2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/congress/lobby-scientific"><span>GRABACIONES</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/6181635bf1856d06d813ec05"><span>ACCESO AL STREAMING</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/6181635bf1856d06d813ec05"><span>ACCESSO A PREGUNTAS</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/615183b591796c236478baef"><span>ACCESO AL STREAMING</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/615183b591796c236478baef"><span>ACCESSO A PREGUNTAS</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/60d5cca589dd5d66c089fdbb"><span>ACCESO AL STREAMING</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/60d5cca589dd5d66c089fdbb"><span>ACCESSO A PREGUNTAS</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/60b9a7879b705b59a85ab6dd"><span>ACCESO AL STREAMING</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/60b9a7879b705b59a85ab6dd"><span>ACCESSO A PREGUNTAS</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/607d579b8a29800e5034a323"><span>ACCESO AL DIRECTO</span></a>
            </div>

            <div className="logo-main-screen-bottom-block" style={{ display: "none" }}>
                <a href="/room/1/607d579b8a29800e5034a323"><span>ACCESO AL DIRECTO - 06 MAYO</span></a>
            </div>

        </div>
    );
}


function BannerCentralSEA2021(props) {
    return (
        <div className="main-screen-bottom sea2021">
            <p>&nbsp;</p>
            <div className="logo-main-screen-bottom-block">
                <a href="/congress/lobby-scientific"><span>ACCESO A DIRECTOS</span></a>
            </div>
            <p>&nbsp;</p>
            <div className="logo-main-screen-bottom-block">
                <a href="/room/2859/609e821b175a011f0c4a5c8c"><span>ACCESO A LA ASAMBLEA</span></a>
            </div>
        </div>
    );
}

function BannerAsambleaSEA2021(props) {
    return (
        <div className="main-screen-bottom sea2021">
            <p>&nbsp;</p>




        </div>
    );
}


function BannerCentralETEV2021(props) {
    return (
        <div className="main-screen-bottom urovi2021 tromboencuentros2021">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/1/60b9f3ad9b705b59a85ab6ea"><span>ACCESO AL DIRECTO</span></a>
            </div>



        </div>
    );
}



function BannerCentralVPRACTICVM(props) {
    return (
        <div className="main-screen-bottom vpracticvm">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/2442/6049d2b8aec8de2520bd0474"><span>ACCEDER SÉSION - SABADO, 13 DE MARZO</span></a>
            </div>


        </div>
    );
}




function BannerCentralASEPB21(props) {
    return (
        <div className="main-screen-bottom ASEPB21">
            <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/1/604fe55f0edc3c6588ba69f4"><span><img src="/img/eposter-detail-message-arrow-icon.png" /> ACCEDER ASAMBLEA</span></a>
            </div>

        </div>
    );
}



function BannerCentral2CONGRESOCOVID(props) {
    return (
        <div className="main-screen-bottom CONGRESOCOVID">
            <p>&nbsp;</p>

            <div className="logo-main-screen-bottom-block">
                <a href="/room/1/607d86e98a29800e5034a328">
                    <img src="https://2congresocovid.es/static/upload/ow121/events/ev378/Site/congress-tv-btn.jpg" /><br /><br />
                    <span>
                        ACCEDER CONGRESS TV 16 DE ABRIL 2021
                    </span>
                </a>
            </div>

            <p>&nbsp;</p>

            <iframe src="https://player.vimeo.com/video/535777308" width="450" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>

            <p>&nbsp;</p>



        </div>
    );
}


export default Lobby;