import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EPoster from '../Eposter/EPoster';
import axios from 'axios';
import AuthContext from "../../../../";

class EpostersList extends Component {

    render() {

        let eposter_list = this.props.eposters;
        let content;

        if (eposter_list !== undefined && eposter_list.length > 0) {

            content = eposter_list
                .map((item, index) => {
                    return (
                        <Link to={this.props.url + '/' + item.id} key={item.id}>
                            <EPoster id={item.id} title={item.title} authors={item.authors} backgroundColor={item.category_color} eventid={this.props.eventid}
                                thumbnail={item.thumbnail} active={item.active} number={item.pid} comments={item.comments_total} views={item.views_total} score={item.rating} />
                        </Link>
                    )
                })
        } else {
            content = null;
        }

        return (
            <div className="eposter-content-container">
                {content}
            </div>
        );
    }
}

export default EpostersList;