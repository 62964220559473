

export const PrivacyPolicySEMNIM2021 = `
<h3>PROTECCIÓN DE BASE DE DATOS DE CARÁCTER PERSONAL</h3>

<p>La SOCIEDAD ESPAÑOLA DE MEDICINA NUCLEAR E IMAGEN MOLECULAR (en adelante, SEMNIM) con domicilio social en la ciudad de Madrid (España), C/ Alberto Bosch Nº13, 28014 Madrid, provista del CIF núm. G-78462934, e inscrita en el Registro General de Asociaciones del Ministerio del Interior Grupo 1 Sección 1 Número Nacional 9374, en cumplimiento de las estipulaciones de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), le informa que los datos que usted facilita en este formulario quedarán incorporados en un fichero automatizado de titularidad de SEMNIM  y serán tratados por esta entidad con la finalidad de disponer de los datos actualizados de los socios de la SEMNIM.</p>

<p>Con el envío del formulario a SEMNIM la persona interesada otorga su consentimiento para que SEMNIM pueda disponer de sus datos personales para la finalidad antes expuesta. Sin embargo, y de acuerdo con el artículo 6 de la LOPD, el interesado puede revocar, previa solicitud justificada, su consentimiento para el tratamiento de los datos personales facilitados o bien ejercitar los derechos de acceso, rectificación, cancelación y/o oposición respecto de los datos facilitados. Para ejercitar estos derechos, es preciso que la persona interesada se dirija mediante un correo electrónico a la siguiente dirección secretaria@semnin.es, o bien mediante una carta a Secretaría Técnica. SEMNIM. C/ Alberto Bosch Nº13, 28014 Madrid. En ambos casos, el afectado deberá aportar documentación acreditativa de su identidad.</p>

<p>Por su parte, SEMNIM se compromete a que los datos facilitados serán tratados de forma totalmente confidencial y, según todas las medidas de seguridad establecidas por la Ley, con el fin de evitar accesos y tratamientos no autorizados. SEMNIM le informa que sus datos no serán utilizados para otros usos que no sean estrictamente los reflejados en el formulario.</p>

<p>En cumplimiento del artículo 12.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, SEMNIM retendrá durante un periodo máximo de doce meses aquellos datos imprescindibles para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio. La obligación de retención de los datos no afectará al secreto de las comunicaciones. Los datos retenidos lo serán a efectos de seguridad, y no serán utilizados para fines distintos de los indicados en la citada ley</p>


`