

export const PrivacyPolicyVPRACTICVM = `
<h3>PROTECCIÓN DE DATOS PERSONALES</h3>

<p>En cumplimiento del Reglamento (UE) 2016/679, General de Protección de Datos, los datos requeridos para la participación en el V Practicvm de Dermatología Pediátrica serán tratados por la Dra. Ángela Hernández Martín como coordinadora del curso y responsable del tratamiento, con domicilio para el ejercicio de derechos en Alberto Bosch, 13 - 28014 Madrid (Madrid). Además, Viajes El Corte Inglés, S.A. tratará los datos como encargado del tratamiento para realizar la gestión administrativa y las funciones de la Secretaría Técnica del Curso, de acuerdo con la normativa de protección de datos y al contrato de acceso a datos por cuenta de terceros celebrado con el responsable.</p>

<p>Los datos personales serán tratados para gestionar la inscripción y participación del interesado en el V Practicvm de Dermatología Pediátrica, así como para informarle de próximas convocatorias y novedades del Practicvm.</p>

<p>Los datos serán tratados en base a la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales. Igualmente, para el envío de información de próximas convocatorias, la base legal es un interés legítimo del responsable, de conformidad con el art. 21 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico.</p>

<p>Los datos personales facilitados para la participación en el curso serán conservados mientras el interesado no solicite su supresión y puedan derivar responsabilidades legales y no serán comunicados a terceros, salvo obligación legal.</p>

<p>Puede ejercer sus derechos de acceso, rectificación, supresión y portabilidad de sus datos, de limitación y oposición a su tratamiento, mediante escrito acompañado de documento oficial que le identifique dirigido a Alberto Bosch, 13 - 28014 Madrid (Madrid). Igualmente, puede enviar el escrito mediante correo electrónico dirigido a secretaria@viajeseci.es, con el asunto “Derechos - V Practicvm de Dermatología Pediátrica”. En caso de disconformidad con el tratamiento, también tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es).</p>


`