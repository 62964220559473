import React from 'react';
import Session from './Session';
import SessionISIN2021 from './SessionISIN2021';

function Room(props) {



    const sessions = props.sessions.map(item => <Session key={item.id} type={item.type} title={item.title} time={item.time_start} locked={false} noTheme={false} externalURL={item.externalURL}></Session>)

    let sessionsComponent = [];
    props.sessions.forEach(item => {
        const dt = item.time_start !== undefined && item.time_start !== null && item.time_start !== "" ? item.time_start.split("T")[1].substring(0, 5) : "";
        sessionsComponent.push(<Session eventid={props.eventid} type={item.type} _id={item._id} k key={item._id} roomId={item.room_id} title={item.title} time={dt} locked={false} noTheme={false} companyLogo={item.sponsor_logo} sponsoredText={item.sponsored_text} externalURL={item.externalURL}></Session>);
    });

    return (


        <div className="room-sessions-wrapper">
            <div className="room-sessions-header-name">
                {props.name}
            </div>
            <div className="room-sessions-area">
                {/* <div className="open-room-icon-container">
                    <img alt="" src="/img/icon-amplifier-open-room-sessions.png" />
                </div> */}



                <table border="0" cellSpacing="0" cellPadding="0" className="tbl-room-sessions-panel">
                    <tbody>

                        {(props.eventid == "397") && (props.name == "Saturday, May 29th 2021" || props.name == "Saturday, June 5th 2021" || props.name == "Saturday, June 12th 2021" || props.name == "Saturday, June 19th 2021") ?
                            <SessionISIN2021 day={props.name}></SessionISIN2021>
                            :
                            sessionsComponent
                        }

                    </tbody>
                </table>

            </div>
        </div>

    );
}

export default Room;