import React, { Component } from 'react';
import MyBagInsert from '../../../../../components/MyBagInsert/MyBagInsert';
import AuthContext from '../../../../../context/auth-context'

class TabInserts extends Component {

    static contextType = AuthContext;

    render() {

        const eventId = this.context.event_settings.event_id;
        //console.log(this.context.event_settings)

        if (eventId === "303") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/mybag-vinisol.jpg"
                        logo="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/mybag-vinisol-logo.jpg"
                        title="VIVISOL Home Care Services"
                        desc="VIVISOL es uno de los proveedores líderes de servicios sanitarios a domicilio
                        y se especializa en particular en equipos para oxigenoterapia, ventilación
                        mecánica, diagnosis y tratamiento de apneas del sueño obstructivas, nutrición
                        artificial, asistencia médica remota y equipos médicos avanzados, y
                        ayudas y equipos para la prevención de lesiones dérmicas causadas por
                        largos periodos en cama."
                        file="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/VIVISOL_TRIPTICO_EPOC.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/NeumoMadrid2020-Programme.jpg"
                        logo="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/neumo-logo-programa.jpg"
                        title="Programa NEUMOMADRID 2020"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/neumomadrid2020/mybag/Programa_NeumoMadrid2020_05.pdf"
                    />

                    <div className="tab-insert-content-wrapper-separator"></div>

                </React.Fragment>
            )
        } else if (eventId === "358") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-logo.png"
                        title="ESTS 2020 Industry Satellite Symposium"
                        desc="Evolving paradigms in the treatment of stage IA-IIIB resectable NSCLC"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-1-new.png"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-2-new.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-logo.png"
                        title="ESTS 2020 Industry Satellite Symposium"
                        desc="Evolving paradigms in the treatment of stage IA-IIIB resectable NSCLC"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/astrazeneca-mybag-2-new.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/bd-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/bd-mybag-logo.png"
                        title="Progel Platinum™ Surgical Sealant"
                        desc="Clinically proven to seal air leaks and reduce length of stay by 2.1 days."
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/bd-mybag-1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-logo.png"
                        title="ECHELON FLEX™ Powered Staplers"
                        desc="Tissue-specific devices designed to improve precision in thoracic surgery. Associated with improved clinical and economic outcomes in VATS lobectomy procedures."
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-2.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-logo.png"
                        title="Peer-reviewed evidence"
                        desc="Use of ECHELON FLEX™ Powered Staplers has been associated with improved clinical and economic outcomes in thoracic surgery"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-3.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-logo.png"
                        title="Online webinar"
                        desc="What was once imagined is now provenUltrasonic technology in VATS"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/ethicon-mybag-3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/fengh-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/fengh-mybag-logo.png"
                        title="Fengh"
                        desc="Structure of Lunar™ F powered staplers. Structure of PSD powered staplers. Structure of MSD manual staplers. Structure of Multi-fire Clip Appliers"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/fengh-mybag-1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/kls-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/kls-mybag-logo.png"
                        title="Pulmonary laser-assisted metastasectomy is associated with prolonged survival in patients with colorectal cancer"
                        desc="Background: Pulmonary metastases develop in 10–15% of patients with colorectal cancer. Surgical metastasectomy currently provides the only hope for a cure for these patients."
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/kls-mybag-1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-logo.png"
                        title="Chest drainage with the Thopaz + digital chest drainage system"
                        desc="Medela Healthcare is a global manufacturer of medical vacuum solutions which are respected and trusted by doctors and healthcare professionals around the world."
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-2.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-logo.png"
                        title="Enhanced Recovery after Lung Surgery in 1 Minute"
                        desc="Recommendations were developed for a total of 45 enhanced recovery items covering topics in the entire patient journey from referral to discharge"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-3.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-logo.png"
                        title="Summary on Thopaz + NICE guideline"
                        desc="The National Institute for Health and Care Excellence (NICE) recommends adopting Thopaz+ for chest drainage management."
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medela-mybag-3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-logo.png"
                        title="THE CONFIDENCE TO KNOW BLEEDING WILL STOP"
                        desc="Intraoperative reliability and consistency. Helping to improve patient outcomes.That’s the Veriset™ hemostatic patch. "
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-2.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-logo.png"
                        title="YOUR VISION IS NOW A REALITY"
                        desc="Introducing the ILLUMISITE™ Platform"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-3.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-logo.png"
                        title="MEDTRONIC SURGICAL VISUALIZATION TECHNOLOGIES"
                        desc="PRODUCT CATALOG"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-4.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-logo.png"
                        title="ACCESS GRANTED."
                        desc="Enhance access and visualization1–5 throughout thoracoscopic procedures"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/medtronic-mybag-4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/reach-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/reach-mybag-logo.png"
                        title="iReach SRC"
                        desc="Powered Endoscopic Linear Stapler"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/reach-mybag-1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2020/mybag/roche-mybag-1.png"
                        logo="https://www.emma.events/static/virtualvenue/ests2020/mybag/roche-mybag-logo.png"
                        title="Immunotherapy: a potential option in the multimodality treatment for resectable NSCLC?"
                        desc="Enhance access and visualization1–5 throughout thoracoscopic procedures"
                        file="https://www.emma.events/static/virtualvenue/ests2020/mybag/roche-mybag-1.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "367") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-1.png"
                        logo=""
                        title="XXIII Congreso Nacional de Psiquiatría"
                        desc="CONGRESO VIRTUAL - Programa"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-2.png"
                        logo=""
                        title="[im] Médico"
                        desc="Innovación para el médico especialista de hospital y atención primaria: investigación médica, gestión, tecnología y servicios sanitarios"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-3.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-3-logo.png"
                        title="Schizophrenia: Looking for the future"
                        desc="Simposio satélite en el marco del: XXIII Congreso Nacional de Psiquiatría"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-4.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-4-logo.png"
                        title="Simposio - Virtual Janssen"
                        desc="Cambiando el Paradigma en el Abordaje de la Depresión: Una Visión Integral Desde el Conocimiento y la Experiencia"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-5.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-4-logo.png"
                        title="Simposio - Virtual Janssen"
                        desc="Abordaje de la Psicosis en el 2020: Terapéutica, Satisfacción, y Futuro"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-5.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-6.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-6-logo.png"
                        title="Simposium VIRTUAL en Depresión"
                        desc="Nuevas Evidencias en Embotamiento Emocional en Pacientes con Depresión"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-7.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-7-logo.png"
                        title="Angelini tiene el placer de invitarle al simposio:"
                        desc="Nueva Oportunidad para los Pacientes con Esquizofrenia"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-7.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-8.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-8-logo.png"
                        title="Visitando la Nevera de la Psiquiatría"
                        desc="Dieta en el Marco del Abordaje de los Pacientes con Depresión"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-8.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-10.png"
                        logo="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-10-logo.png"
                        title="Estimulación Magnética Transcraneal, TMS, rTMS, iTBS"
                        desc="¿Tiene pacientes que no responden a los antidepresivos?"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-10.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-9.png"
                        logo=""
                        title="XXIII Congreso Nacional de Psiquiatría"
                        desc="Posteres"
                        file="https://www.emma.events/static/virtualvenue/cnp2020/mybag/cnp2020-mybag-9.pdf"
                    />





                </React.Fragment>
            )

        } else if (eventId === "366") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag1.png"
                        logo=""
                        title="ENCUENTROS CON AEA"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/aea2020/mybag/ENCUENTROS_AEA_programa_17112020.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag-sn-logo.png"
                        title="NOVOSTITCH PRO"
                        desc="Meniscal Repair System"
                        file="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag3.png"
                        logo="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag-sn-logo.png"
                        title="REGENETEN"
                        desc="Implante bioinductivo"
                        file="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag-sn-logo.png"
                        title="HEALICOIL REGENESORB / KNOTLESS"
                        desc="Suture Anchor"
                        file="https://www.emma.events/static/virtualvenue/aea2020/mybag/aea2020-mybag4.pdf"
                    />




                </React.Fragment>
            )

        } else if (eventId === "347") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag1.png"
                        logo=""
                        title="SEGUNDO CONGRESO GEMAV"
                        desc="CONGRESO VIRTUAL - Programa"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/programa_gemav_26112020.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag2.png"
                        logo=""
                        title="FORMULARIO DE SOLICITUD"
                        desc="SOCIO GEMAV"
                        file="https://emma.events/society/members/form_gemav/"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag3.png"
                        logo=""
                        title="TERCER CONGRESO GEMAV"
                        desc="Madrid 2021"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag4.png"
                        logo=""
                        title="MANUAL DEL ACCESO VACULAR PARA PERSONAS CON ENFERMEDAD RENAL"
                        desc="Manual elaborado por el GEMAV, en colaboración con ADER y ALCER, y avalado por más de 50 Sociedades Científicas nacionales e internacionales"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag5.png"
                        logo=""
                        title="GUÍA CLÍNICA ESPAÑOLA DEL ACCESO VASCULAR PARA HEMODIÁLISIS"
                        desc="El acceso vascular para hemodiálisis es esencial para el enfermo renal tanto por su morbimortalidad asociada como por su repercusión en la calidad de vida..."
                        file="https://www.revistanefrologia.com/es-guia-clinica-espanola-del-acceso-articulo-S0211699517302175?referer=buscador"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag6.png"
                        logo="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag6-sponsor.png"
                        title="FOSQUEL - MÁS QUE ATRACCIÓN POR EL FÓSFORO"
                        desc="EFECTOS PLEIOTRÓPICOS: Más allá del control de la hiperfosfatemia, el carbonato de sevelámero incluye acciones sobre la inflamación, el estrés oxidativo, el perfil lipídico y la aterogénesis, la calcificación vascular, la disfunción endotelial..."
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag7.png"
                        logo=""
                        title="49TH EDTNA/ERCA INTERNATIONAL CONFERENCE"
                        desc="Ljubljana, Slovenia September 4–7, 2021"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag7.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag8.png"
                        logo=""
                        title="VAS2021, BERLÍN - 12º CONGRESO DE LA VASCULAR ACCESS SOCIETY"
                        desc="Del 7 al 10 de abril de 2021, Maritim Hotel Berlín"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag8.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag10.png"
                        logo="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag10-sponsor.png"
                        title="LA SIMULACIÓN MÉDICA, A UN PASO DE LA REALIDAD"
                        desc="Creación quirúrgica del Acceso Vascular, Punción Eco-Guiada, Colocación Eco-Guiada de Catéteres, Procedimientos Endovasculares"
                        file="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag10.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag11.jpg"
                        logo=""
                        title="Congreso SEDYT21"
                        desc="El XLII Congreso Anual de la Sociedad Española de Diálisis y Trasplantes se celebrará del 27 al 29 de mayo de 2021 en el Auditori Eutyches (PALAU DE CONGRESSOS DE TARRAGONA). Pueden enviar su comunicación hasta el próximo 31 de julio de 2021. Toda la información en www.congresosedyt.com"
                        file="https://www.congresosedyt.com/"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/gemav2020/mybag/gemav2020-mybag12.jpg"
                        logo=""
                        title="Fundación Española de Diálisis"
                        desc="Consulta las últimas noticias y eventos de la Fundación Española de Diálisis en www.fedialisis.com"
                        file="https://www.fedialisis.com/"
                    />


                </React.Fragment>
            )

        } else if (eventId === "353") {
            return (
                <React.Fragment>
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag1.png"
                        logo=""
                        title="32º CONGRESO SEAIC"
                        desc="CONGRESO VIRTUAL - Programa"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-sanofi-mybag-sponsor.png"
                        title="SIMPOSIO"
                        desc="DUPILUMAB: EXPERIENCIA EN LA PRÁCTICA CLÍNICA REAL EN ESPAÑA"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag3.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-astrazeneca-mybag-sponsor.png"
                        title="SIMPOSIO SATÉLITE"
                        desc="DEL 3 AL 6 EN ASMA"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-letipharma-mybag-sponsor.png"
                        title="DESAYUNO CON EL EXPERTO"
                        desc="RINITIS PERENNE - Alergia a ácaros en pacientes polisensibilizados"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag5.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-alk-mybag-sponsor.png"
                        title="DESAYUNO CON EL EXPERTO"
                        desc="La ilusión de volver"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag5.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag6.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-clsbehring-mybag-sponsor.png"
                        title="DESAYUNO CON EL EXPERTO"
                        desc="Angioedema hereditario - Un objetivo a la vista: profilaxis de largo plazo"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag7.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-gsk-mybag-sponsor.png"
                        title="ALMUERZO CON EL EXPERTO"
                        desc="CONTROLAR AL PACIENTE CON ASMA: LOS DETALLES MARCAN LA DIFERENCIA"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag7.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag8.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-novartis-mybag-sponsor.png"
                        title="ENCUENTRO CON EL EXPERTO"
                        desc="Alcanzar el control en la Urticaria crónica es posible"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag8.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag9.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-allergopharma-mybag-sponsor.png"
                        title="ALMUERZO CON EL EXPERTO"
                        desc="DIAGNÓSTICO MOLECULAR - un paso más allá"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag9.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag10.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-allergytherapeutics-mybag-sponsor.png"
                        title="SIMPOSIO"
                        desc="Las 13 claves de la Inmunoterapia"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag10.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag11.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-sanofi-mybag-sponsor.png"
                        title="SIMPOSIO"
                        desc="INFLAMACIÓN TIPO 2 EN ASMA GRAVE"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag11.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag12.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-martitor-mybag-sponsor.png"
                        title="TALLER DE EPICITÁNEAS"
                        desc="Dr. José Luis García Abujeta"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag12.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag13.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-abbvie-mybag-sponsor.png"
                        title="DESAYUNO CON EL EXPERTO"
                        desc="NUEVOS HORIZONTES EN DERMATITIS ATÓPICA"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag13.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag14.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-letipharma-mybag-sponsor.png"
                        title="SIMPOSIO"
                        desc="20 AÑOS INNOVANDO EN INMUNOTERAPIA"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag14.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag15.png"
                        logo="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-novartis-mybag-sponsor.png"
                        title="SIMPOSIO SATÉLITE"
                        desc="Evolución del asma en el tiempo y sus comorbilidades: De la experiencia a la nueva evidencia"
                        file="https://www.emma.events/static/virtualvenue/seaic2020/mybag/seaic2020-mybag15.pdf"
                    />


                </React.Fragment>
            )

        } else if (eventId === "363") {
            return (
                <React.Fragment>


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag5.png?ver=3"
                        logo=""
                        title="LAHRS2020"
                        desc="PROGRAMME"
                        file="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag5.pdf?ver=3"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2020/industria/logos/abbot-logo-sponsor.png"
                        title="NEW TOOLS AND APPROACHES FOR MAPPING AND ABLATION OF ATRIAL ARRHYTHMIAS"
                        desc="LAHRS LIVE WEBINAR | NOVEMBER 14TH 2020"
                        file="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2020/industria/logos/jnj-logo-sponsor.png"
                        title="Live Case: A Practice in Efficiency: AF Ablation with Dr. José Osório"
                        desc="Saturday, November 14th"
                        file="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag3.png"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2020/industria/logos/medtronic-logo-sponsor.png"
                        title="MEDTRONIC ACADEMY"
                        desc="EDUCACIÓN CRHF LATAM"
                        file="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2020/industria/logos/microport-logo-industria.png"
                        title="MicroPort - Electrophysiology"
                        desc="Product Brochure"
                        file="https://www.emma.events/static/virtualvenue/lahrs2020/mybag/lahrs2020-mybag4.pdf"
                    />




                </React.Fragment>
            )

        } else if (eventId === "314") {
            return (
                <React.Fragment>


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag17.png"
                        logo=""
                        title="SEPAR2020"
                        desc="PROGRAMA"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag17.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/clsbehring-logo-sponsor.png"
                        title="TRATAMIENTOS DEL DÉFICIT DE ALFA1 ANTITRIPSINA (DAAT)"
                        desc="¡El único aprobado para la autoadministración y con viales de 4g y 5g!"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/gsk-logo-industria.png"
                        title="Innovación que se respira"
                        desc="Anoro ELLIPTA, Trelegy ELLIPTA, Relvar ELLIPTA, Nucala mepolizumabsc"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag3.png?ver=2"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/olympus-logo-sponsor.png"
                        title="Let’s Be Clear"
                        desc="Elevamos el estándar de la endoscopia"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag3.pdf?ver=2"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/novartis-logo-sponsor.png"
                        title="Café con el profesor"
                        desc="Manejo del Paciente con Asma y Comorbilidad de la Vía Aérea Superior"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag5.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/roche-logo-sponsor.png"
                        title="Café con el profesor"
                        desc="Tips radiológicos para el diagnóstico diferencial de las EPID"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag5.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag6.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/janssen-logo-industria.png"
                        title="Café con el profesor"
                        desc="Actualización y nuevos retos en HAP. Patrocinado por Janssen"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag7.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/chiesi-logo-industria.png"
                        title="AIRE DE DEBATE"
                        desc="Triple Terapia en enfermedades de la vía aérea"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag7.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag8.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/astrazeneca-logo-industria.png"
                        title="Aires de debate"
                        desc="Optimizando estrategias en Asma: El Paciente 5.0"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag8.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag9.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/boehringer-logo-industria.png"
                        title="Aire de debate"
                        desc="¿Qué es progresión en fibrosis pulmonar? Perspectiva clínica"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag9.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag10.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/sanofo-pasteaur-logo-sponsor.png"
                        title="Café con el profesor"
                        desc="Taller sobre los efectos respiratorios de la gripe y la vacunación antigripal."
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag10.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag11.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/vertex-logo-sponsor.png"
                        title="Café con el profesor"
                        desc="FIBROSIS QUÍSTICA Y CFTR MODULADORES: EJEMPLO DE INNOVACIÓN EFICAZ Y SOSTENIBLE"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag11.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag12.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/novartis-logo-sponsor.png"
                        title="SESIÓN DE DEBATE"
                        desc="Evolución del asma en el tiempo y sus comorbilidades: De la experiencia a la nueva evidencia"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag12.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag13.png?ver=2"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/gebro-logo-sponsor.png"
                        title="Café con el profesor"
                        desc="PROYECTO RECOVID"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag13.pdf?ver=2"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag14.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/sanofo-genzyme-logo-sponsor.png"
                        title="INFLAMACIÓN TIPO 2"
                        desc="EN ASMA GRAVE"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag14.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag15.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/industria/logos/gsk-logo-industria.png"
                        title="Innovación que se respira"
                        desc="Medicina centrada en el paciente en la vida real: rasgos tratables en asma y epoc"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag15.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag16.png"
                        logo="https://www.emma.events/static/virtualvenue/separ2020/mybag/gilead-logo-sponsor.png"
                        title="Papel de Remdesivir"
                        desc="En el tratamiento de la COVID-19"
                        file="https://www.emma.events/static/virtualvenue/separ2020/mybag/separ2020-mybag16.pdf"
                    />




                </React.Fragment>
            )

        } else if (eventId === "369") {
            return (
                <React.Fragment>




                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/acumed-logo.png"
                        title="Acutrak 2®"
                        desc="Sistema de tornillo de compresión sin cabeza"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/acumed-logo.png"
                        title="Acumed®"
                        desc="Sistema de placas de fusión total de muñeca"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag3.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/acumed-logo.png"
                        title="Acumed®"
                        desc="Sistema para fracturas de mano"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/acumed-logo.png"
                        title="Arc Wrist Tower"
                        desc="For Wrist Arthroscopy And Fracture Reduction"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-acumed-mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="Prótesis de Scheker de sustitución total de la Articulación Radio-Cubital Distal"
                        desc="Guía de la Técnica"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="Dynavisc® - Gel de barrera antiadherencias"
                        desc="Para la cirugía de tendones y nervios periféricos"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag2.pdf"
                    />



                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag4.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="NEUROCAP®"
                        desc="Bioresorbable Peripheral Nerve Capping Device"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag5.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="NEUROLAC®"
                        desc="Peripheral Nerve Repair"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag8.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="VIVOSORB®"
                        desc="Temporary Tissue Support, Minimizing Adhesion Formation"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag8.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag3.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="Integra®"
                        desc="Freedom Wrist Arthroplasty System"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag3.pdf"
                    />



                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag6.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="Integra®"
                        desc="Silicone MCP Joint Prosthesis"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag6.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag7.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="Integra®"
                        desc="Silicone PIP Implant"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag7.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag9.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/medcomtech-logo.png"
                        title="XPERT WRIST 2.4 - VOLAR PLATES"
                        desc="Distal radius volar plating system"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-medcomtech-mybag9.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-adler-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/surgival-logo.png"
                        title="ANTEA"
                        desc="RADIAL HEAD PROSTHESIS - SURGICAL TECHNIQUE"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-adler-mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-adler-mybag2.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/surgival-logo.png"
                        title="ANTEA"
                        desc="The ANTEA Radial Head Prosthesis"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-adler-mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-osteotec-mybag1.png"
                        logo="https://www.emma.events/static/virtualvenue/cmm2020/industria/logos/surgival-logo.png"
                        title="Implante de dedo de silicona"
                        desc="Diseño clínicamente probado para MCP y PIP"
                        file="https://www.emma.events/static/virtualvenue/cmm2020/mybag/cmm2020-osteotec-mybag1.pdf"
                    />





                </React.Fragment>
            )

        } else if (eventId === "342") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/piediabetico2021-mybag.jpg"
                        logo=""
                        title="SIMPOSIO PIE DIABÉTICO 2021"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/piediabetico2021-mybag.pdf?ver=2"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/abbot-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/abbot-mybag-logo.jpg"
                        title="SIMPOSIO SATÉLITE ABBOTT - PIE DIABÉTICO"
                        desc="IMPORTANCIA DE LA DERIVACIÓN PRECOZ DE LOS PACIENTES"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/abbot-mybag.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/braun-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/braun-mybag-logo.jpg"
                        title="SIMPOSIO B. BRAUN"
                        desc="SEGURIDAD EN EL ABORDAJE DE LA HERIDA INFECTADA DE PIE DIABÉTICO"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/braun-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/convatec-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/convatec-mybag-logo.jpg"
                        title="Simposio de Abordaje Integral del Pie Diabético con el Prof. Dr. José Luís Lázaro"
                        desc="Haciendo frente a las heridas de difícil cicatrización mediante una estrategia de intervención temprana contra el biofilm: Higiene de la Herida."
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/convatec-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/molnlycke-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/molnlycke-mybag-logo.jpg"
                        title="Mölnlycke® tiene el placer de invitarle al simposio"
                        desc="Soluciones de valor para el manejo de las Úlceras de Pie Diabético"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/molnlycke-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/urgo-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/urgo-mybag-logo.jpg"
                        title="Octosulfato de Sacarosa:"
                        desc="Cómo reducir el tiempo de cicatrización con la máxima evidencia científica"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/urgo-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/podartis-mybag.jpg?ver=2"
                        logo="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/podartis-mybag-logo.jpg"
                        title="SIMPOSIO COMERCIAL 2 PODARTIS"
                        desc="PODIAPP UNA HERRAMIENTA INNOVADORA"
                        file="https://www.emma.events/static/virtualvenue/piediabetico2021/mybag/podartis-mybag.pdf?ver=3"
                    />





                </React.Fragment>
            )

        } else if (eventId === "289") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/seip2021-mybag.jpg"
                        logo=""
                        title="X CONGRESO DE SEIP"
                        desc="PROGRAMA"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/seip2021-mybag.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/bexero-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/mybag/gsk-mybag-logo.jpg"
                        title="BEXSERO y MENVEO"
                        desc="Evidencia en vida real"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/bexero-mybag.pdf"
                    />



                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/biomerieux-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/room/biomerieux-logo-sessions.jpg"
                        title="Respuestas más rápidas. Tranquilidad para los padres."
                        desc="Los resultados que los pediatras necesitan ahora con el diagnóstico sindrómico de BioFire para enfermedades infecciosas."
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/biomerieux-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/gsk-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/mybag/gsk-mybag-logo.jpg"
                        title="VACUNAS GSK"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/gsk-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/pfizer1-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/room/pfizer-logo-sessions.jpg"
                        title="Simposio Pfizer"
                        desc="Abordaje de las infecciones graves causadas por gram (+) en población pediátrica, ¿Existen novedades terapéuticas?"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/pfizer1-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/pfizer2-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/room/pfizer-logo-sessions.jpg"
                        title="Nimenrix"
                        desc="La vacuna antimeningocócica conjugada frente a los serogrupos ACWY con la indicación más amplia en Europa"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/pfizer2-mybag.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/msd-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/room/msd-logo-sessions.jpg"
                        title="Simposio MSD"
                        desc="VACUNAS MULTIVALENTES - EL RETO DE COMBINAR DISTINTOS SEROTIPOS"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/msd-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seip2021/mybag/sanofi-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seip2021/room/sanofi-logo-sessions.jpg"
                        title="VIRUS RESPIRATORIO SINCITIAL (VRS)"
                        desc="¿QUÉ ES Y A QUIÉN AFECTA?"
                        file="https://www.emma.events/static/virtualvenue/seip2021/mybag/sanofi-mybag.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "315") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/vpracticvm-mybag.jpg"
                        logo=""
                        title="V Practicvm"
                        desc="PROGRAMA"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/vpracticvm-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris-mybag-logo.jpg"
                        title="ELIDEL"
                        desc="La alternativa a los corticoides tópicos especialmente indicada en zonas de piel sensible"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris-mybag.pdf?ver=2"
                        fichatecnica="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris-mybag-ficha-tecnica.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris2-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris-mybag-logo.jpg"
                        title="TRECLINAC"
                        desc="Pocas cosas se toleran mejor"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris2-mybag.pdf"
                        fichatecnica="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/viatris2-mybag-ficha-tecnica.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leti-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leti-mybag-logo.jpg"
                        title="Skin. Science. Solutions"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leti-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/pierre-fabre-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/pierre-fabre-mybag-logo.jpg"
                        title="Skin. Science. Solutions"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/pierre-fabre-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/heel-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/heel-mybag-logo.jpg"
                        title="Dermaveel"
                        desc="La gama de productos Dermaveel cuida tu piel de una manera integral"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/heel-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/roche-posay-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/roche-posay-mybag-logo.jpg"
                        title="LIPIKAR"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/roche-posay-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/sanofi-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/sanofi-genzyme-mybag-logo.jpg"
                        title="AtopicME"
                        desc="EL CANAL DE INSTAGRAM PARA JÓVENES CON DERMATITIS ATÓPICA GRAVE"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/sanofi-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/abbvie-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/abbvie-mybag-logo.jpg"
                        title="Personas. Pasión. Posibilidades"
                        desc="Comprometidos con el futuro de la dermatología"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/abbvie-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/cantabria-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/cantabria-mybag-logo.jpg"
                        title="AVANZANDO JUNTOS"
                        desc="Cantabria Labs y la Pediatría"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/cantabria-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/mustela-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/mustela-mybag-logo.jpg"
                        title="AVANZANDO JUNTOS"
                        desc="Cantabria Labs y la Pediatría"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/mustela-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/ferrer-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/ferrer-mybag-logo.jpg"
                        title="Ozanex"
                        desc="Nuevo Antibiótico Tópico Bactericida"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/ferrer-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leo-pharma-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leo-pharma-mybag-logo.jpg"
                        title="Adventan y Protopic"
                        desc="DERMATITIS ATÓPICA BAJO CONTROL"
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/leo-pharma-mybag.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/eucerin-mybag.jpg"
                        logo="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/eucerin-mybag-logo.jpg"
                        title="DermoPure"
                        desc="CALMA LA PIEL DESECADA POR TRATAMIENTOS FARMACOLÓGICOS PARA EL ACNÉ."
                        file="https://www.emma.events/static/virtualvenue/vpracticvm/mybag/eucerin-mybag.pdf"
                    />




                </React.Fragment>
            )

        } else if (eventId === "378") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/2congresocovid/mybag/mybag1.jpg"
                        logo=""
                        title="II Congreso Nacional Covid-19"
                        desc="PROGRAMA"
                        file="https://www.emma.events/static/virtualvenue/2congresocovid/mybag/mybag1.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "372") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/industria/logos/LOGOS_SEPAR_600x144_FAES.jpg"
                        title="ROLUFTA, LAVENTAIR, REVINTY"
                        desc="Elige tratamiento sin cambiar de dispositivo"
                        file="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/separ-logo.jpg"
                        title="PROGRAMA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/mybag2.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "376") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag9.jpg"
                        logo=""
                        title="PROGRAMA"
                        desc="38 Congreso Nacional de la Sociedad Española de Medicina Nuclear"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag9.pdf?ver=2"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag10.jpg"
                        logo=""
                        title="ENCUESTA NACIONAL"
                        desc="PROTOCOLOS UTILIZADOS EN MEDICINA NUCLEAR EN LA PATOLOGÍA INFLAMATORÍA-INFECCIOSA ÓSEA EN ESPAÑA"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag10.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/boston-logo.jpg"
                        title="OPTIMIZACIÓN DE LA PLANIFICACIÓN DE RADIOEMBOLIZACIÓN CON MICROESFERAS DE Y-90"
                        desc="Impacto de la dosimetría personalizada de Therasphere en hepatocarcinoma: estudio DOSISPHERE"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/sirtex-logo2.jpg"
                        title="SIMPOSIO SIRTEX"
                        desc="SIR-Spheres® Y-90 : Optimizando el tratamiento SIRT de tumores hepáticos"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/spectrumdynamics-logo.jpg"
                        title="Simposio Auspiciado por Spectrum Dynamics Medical"
                        desc="Cordial Invitación"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/gehealthcare-logo.jpg"
                        title="Intelligently Efficient"
                        desc="PET/CT última generación de equipos digitales y algoritmos de reconstrucción"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/aaa-logo3.jpg"
                        title="ATRÁPAME SI SABES"
                        desc="Que no se te escape lo último en medicina nuclear avanzada"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag5.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/industria/logos/curiumpharmaspain-logo.jpg"
                        title="SIMPOSIO"
                        desc="Uso del PET/CT PSMA en el cáncer de próstata: entorno clínico y normativo"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag-logo-terumo.jpg"
                        title="PLATAFORMA HOLMIO"
                        desc="SIMPOSIO TERUMO"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag7.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag-logo-pfizer.jpg"
                        title="SOSPECHAR y DETECTAR"
                        desc="Amiloidosis cardíaca por transtiretina: ¿por dónde empezar?"
                        file="https://www.emma.events/static/virtualvenue/semnim2021/mybag/mybag8.pdf"
                    />




                </React.Fragment>
            )

        } else if (eventId === "397") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag1.jpg"
                        logo=""
                        title="ISIN SPRING VIRTUAL MEETING"
                        desc="Program"
                        file="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/isin2021/mybag/gvb-spes-logo.jpg"
                        title="INTRA OPERATIVE MONITORING"
                        desc="Welcome to GVB-SPES"
                        file="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/isin2021/room/inomed-room-sponsor.jpg"
                        title="ISIS XPERT"
                        desc="Improving surgical safety and efficacy"
                        file="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag4-logos.jpg"
                        title="Intraoperative Neuromonitoring For Neurosurgeons: Practical aspects and clinical applications"
                        desc="June 14 – 15, 2021 https://www.ionmrussia2020.com"
                        file="https://www.emma.events/static/virtualvenue/isin2021/mybag/mybag4.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "391") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag1.jpg"
                        logo=""
                        title="XXXIII Congreso Virtual de la Sociedad Española de Arteriosclerosis"
                        desc="PROGRAMA"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag1.pdf?ver=2"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/novartis-logo.jpg"
                        title="Novartis en Cardio-Renal-Metabólico (CRM)"
                        desc="Reimaginando las Enfermedades Cardiovasculares (ECV)"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/amgen-logo.jpg"
                        title="20 RAZONES PARA EL TRATAMIENTO CON"
                        desc="Repatha (evolocumab)"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/sanofi-logo.jpg"
                        title="ZENON"
                        desc="MÁXIMA POTENCIA HIPOLIPEMIANTE EN UN COMPRIMIDO ORAL"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/servier-logo.jpg"
                        title="SOLUCIONES SERVIER"
                        desc="HIPERCOLESTEROLEMIA: Combinación fija de ALTA INTENSIDAD // HIPERTENSIÓN: ADHERENCIA AL CONTROL 24H"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/rubio-logo.jpg"
                        title="LIPOSCALE"
                        desc="Mejore el control sobre el riesgo cardiovascular de sus pacientes con LIPOSCALE®"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag6.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/ferrer-logo.jpg"
                        title="Potencia de Alzil® y Alzil® Plus"
                        desc="Eficacia Hipolipemiante en disminución de c-LDL"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag7.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/seavirtual2021/industria/logos/viatris-logo.jpg"
                        title="praVafenix"
                        desc="En pacientes con Dislipemia Aterogénica"
                        file="https://www.emma.events/static/virtualvenue/seavirtual2021/mybag/mybag8.pdf"
                    />


                </React.Fragment>
            )

        } else if (eventId === "325") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/depuy-logo.jpg"
                        title="LO ÚLTIMO EN TECNOLOGÍA DE TORNILLOS"
                        desc="Tornillos de compresión canulados sin cabeza (CCHS) de DePuy Synthes"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag15.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/depuy-logo.jpg"
                        title="TECNOLOGÍA tornillos de rápida inserción"
                        desc="Tornillos autorroscantes de estilo desenroscable"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag15.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/exactech-logo.jpg"
                        title="Case Report"
                        desc="Two-Staged Revision Total Ankle Arthroplasty Surgery with Primary Total Ankle Arthroplasty System"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/exactech-logo.jpg"
                        title="Prótesis total de tobillo con inserto móvil"
                        desc="Técnica quirúrgica"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/exactech-logo.jpg"
                        title="ARTROPLASTIA TOTAL DE TOBILLO"
                        desc="INSERTO MÓVIL"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/exactech-logo.jpg"
                        title="Article"
                        desc="Mobile Anatomical Total Ankle Arthroplasty—Improvement of Talus Recentralization"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/zimmer-logo.jpg"
                        title="Sistema de Clavo para Artrodesis de Tobillo Phoenix"
                        desc="Con tecnología CoreLock de dos etapas"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag6.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/zimmer-logo.jpg"
                        title="Trabecular Metal™ Total Ankle"
                        desc="Técnica Quirúrgica"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag7.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/mba-logo.jpg"
                        title="Gama de sustitutivos óseos"
                        desc="DIVISIÓN DE BIOLÓGICOS"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag8.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag9.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/mba-logo.jpg"
                        title="Prevención del edema POSTOPERATORIO"
                        desc="Previene la formación de edema postoperatorio tras una cirugía ortopédica"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag9.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag10.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/mba-logo.jpg"
                        title="Reducción del edema PREOPERATORIO"
                        desc="Reducción del tiempo de entrada en quirófano en pacientes con fractura de tobillo que requieren Fijación interna (ORIF)"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag10.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag11.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/a2c-logo.jpg"
                        title="TÉCNICA QUIRÚRGICA"
                        desc="Prótesis total de tobillo Triple A"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag11.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag12.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/a2c-logo.jpg"
                        title="Ankle Fusion Plating System"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag12.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag13.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/a2c-logo.jpg"
                        title="PEDUS-R"
                        desc="Rearfoot System"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag13.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag14.jpg"
                        logo="https://www.emma.events/static/virtualvenue/semcpt2021/industria/logos/a2c-logo.jpg"
                        title="airlock"
                        desc="Anatomically contoured, low-profile forefoot and midfoot plates"
                        file="https://www.emma.events/static/virtualvenue/semcpt2021/mybag/mybag14.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "373") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/mybag/separ-logo.jpg"
                        title="PROGRAMA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/mybag/mybag2-logo.jpg"
                        title="Pharmate / Esteve Teijin"
                        desc="Fact Sheet 2021"
                        file="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/mybag/mybag2.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "341") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/bms-logo.jpg"
                        title="Transforming patient's lives through sicence"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/bms-logo.jpg"
                        title="Look for Bristol Myers Squibb at the 29th European Society of Thoracic Surgeons (ESTS) Meeting"
                        desc="Bristol Myers Squibb is a proud sponsor of the 29th ESTS Meeting and would like to thank all members for their dedication to patients."
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/astrazeneca-logo.jpg"
                        title="Adjuvant osimertinib"
                        desc="New expectations for adjuvant treatment of EGFRm lung cancer in the era of personalised medicine"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/astrazeneca-logo.jpg"
                        title="Synergy in care"
                        desc="MDTs for Resectable NSCLC"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medela-logo.jpg"
                        title="Millions of chest drainage"
                        desc="therapies are performed annually, sometimes even saving lives"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medela-logo.jpg"
                        title="ClotStop"
                        desc="Catheter for cardiothoracic drainage"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag6.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/roche-logo.jpg"
                        title="ESTS 2021 Industry Satellite Symposium"
                        desc="Integration of adjuvant immunotherapy into future clinical practice for resectable NSCLC: when, how and for whom?"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag7.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg"
                        title="EleVision | IR Platform"
                        desc="SEEING IS BELIEVING"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag8.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag9.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg"
                        title="YOUR VISION IS NOW A REALITY"
                        desc="Introducing the ILLUMISITE Platform"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag9.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag10.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg"
                        title="ACCESS GRANTED"
                        desc="Enhance access and visualization throughout thoracoscopic procedures"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag10.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag11.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/medtronic-logo.jpg"
                        title="COMPLEX REPAIRS"
                        desc="REQUIRE A DURABLE IMPLANT"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag11.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag12.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/kls-logo.jpg"
                        title="Limax"
                        desc="In Thoracic Surgery"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag12.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag13.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2"
                        title="Accurate anastomosis excellent performance"
                        desc="easyCS Circular Staplers for Single Use"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag13.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag14.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2"
                        title="easyEndo"
                        desc="Universal Linear Cutting Stapler and Loading Units for Single Use"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag14.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag15.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2"
                        title="easyUS"
                        desc="Ultrasonic Surgical System"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag15.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag16.jpg"
                        logo="https://www.emma.events/static/virtualvenue/ests2021/industria/logos/ezisurg-logo.jpg?ver=2"
                        title="easyEndo Lite"
                        desc="Linear Cutting Staplers and Loading Units for Single Use"
                        file="https://www.emma.events/static/virtualvenue/ests2021/mybag/mybag16.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "263") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/brainlab-logo.jpg"
                        title="Craniomaxillofacial"
                        desc="Planning"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/brainlab-logo.jpg"
                        title="Digital CMF Surgery"
                        desc="Technological Insights and Clinical Workflow"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/brainlab-logo.jpg"
                        title="Digital"
                        desc="CMF Surgery"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag3.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/cadskills-logo.jpg"
                        title="Additively Manufactured Subperiosteal Jaw Implant"
                        desc="Application in the atrophic maxilla"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/johnson-logo.jpg"
                        title="Let’s put it all together"
                        desc="With ease and efficiency"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/eacmfs2021/industria/logos/stryker-logo.jpg"
                        title="Facial iD"
                        desc="Orthognathics"
                        file="https://www.emma.events/static/virtualvenue/eacmfs2021/mybag/mybag6.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "320") {
            return (
                <div>
                    <React.Fragment>



                        <MyBagInsert
                            flyer="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/mybag1.jpg"
                            logo=""
                            title="Program"
                            desc=""
                            file="https://cuenca2020.com/static/upload/ow111/events/ev320/Site/files/Programa_Cuenca_virtual_V26_02092021.pdf"
                        />

                        <MyBagInsert
                            flyer="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/mybag3.jpg"
                            logo=""
                            title="Abstracts Book"
                            desc=""
                            file="https://cuenca2020.com/static/upload/ow111/events/ev320/Site/files/Libro_Abstracts_Cuenca_virtual_v21.pdf"
                        />


                        <MyBagInsert
                            flyer="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/mybag2.jpg"
                            logo=""
                            title="Internation Alliance against Health Risks in Wildlife Trade"
                            desc=""
                            file="https://www.emma.events/static/virtualvenue/cuenca2021/mybag/mybag2.pdf"
                        />






                    </React.Fragment>
                    <div className="text-my-bag">

                        <p>&nbsp;</p>

                        <p>Dear participant at the 69th WDA / 14th EWDA - Joint Virtual Conference,</p>

                        <p> As you may know, the EWDA hosts a <strong>Sustainability Committee</strong> (<a target="_blank" href="https://ewda.org/sustainability/">https://ewda.org/sustainability/</a>), with the goal of reducing the environmental impact of the EWDA while maintaining its overall mission. A first objective of the committee is to detect areas and processes within the EWDA and its membership which may be enhanced.</p>

                        <p> As a wildlife health researcher who is aware and concerned about the environmental impact of human actions, you may be willing to help the EWDA Sustainability Committee by thinking of your own research activity and its environmental impact. By completing the questionnaire (in 5-10 minutes) that you’ll find at <a target="_blank" href="https://forms.gle/Pis1gojGLZhby4pz7">https://forms.gle/Pis1gojGLZhby4pz7</a>, you will contribute to knowledge about the impact of wildlife health research activities and the potential ways to reduce it.</p>

                        <p>Thank you very much in advance for your participation.</p>

                        <p>Francisco Ruiz-Fons on behalf of EWDA Sustainability Committee</p>
                    </div>

                </div>

            )

        } else if (eventId === "351") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/secec2021/mybag/lima-1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/secec2021/industria/logos/lima-logo.jpg"
                        title="SMR"
                        desc="Modular Shoulder Replacement"
                        file="https://www.emma.events/static/virtualvenue/secec2021/mybag/lima-1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/secec2021/mybag/depuy-2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/secec2021/industria/logos/depuy-logo.jpg"
                        title="VELIS&trade;"
                        desc="Digital Surgery"
                        file="https://www.emma.events/static/virtualvenue/secec2021/mybag/depuy-2.html"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/secec2021/mybag/arthrex-1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/secec2021/industria/logos/arthrex-logo.jpg"
                        title="Arthroplasty"
                        desc="Next Generation in Repair and Reconstruction"
                        file="https://www.emma.events/static/virtualvenue/secec2021/mybag/arthrex-1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/secec2021/mybag/arthrex-2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/secec2021/industria/logos/arthrex-logo.jpg"
                        title="When You Treat Biceps ..."
                        desc="... Think Arthrex"
                        file="https://www.emma.events/static/virtualvenue/secec2021/mybag/arthrex-2.pdf"
                    />





                </React.Fragment>
            )

        } else if (eventId === "379") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/mybag1.jpg"
                        logo=""
                        title="NEUMOMADRID2021"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/mybag1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/vivisol-mybag.jpg"
                        title="VIVISOL"
                        desc="Home Care Services"
                        file="https://www.emma.events/static/virtualvenue/neumomadrid2021/mybag/mybag2.pdf"
                    />







                </React.Fragment>
            )

        } else if (eventId === "337") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/conmed-logo.jpg"
                        title="Bienvenidos a CONMED"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mba-logo.jpg"
                        title="Gama de productos MBA"
                        desc="Soluciones en cirugía reconstructiva de rodilla"
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/medcomtech-logo.jpg"
                        title="iTotalPS"
                        desc="Customized POSTERIOR-STABILIZED knee replacement system"
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/oyasama-logo.jpg"
                        title="iTotalPS"
                        desc="Customized POSTERIOR-STABILIZED knee replacement system"
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag4.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/zimmer-logo.jpg"
                        title="PURPOSE -BUILT."
                        desc="SEAMLESS INTEGRATION. OPTIMIZED CARE."
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/zimmer-logo.jpg"
                        title="PURPOSE -BUILT."
                        desc="SEAMLESS INTEGRATION. OPTIMIZED CARE."
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag6.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/palex-logo.jpg"
                        title="Proveedor global de soluciones avanzadas para los profesionales de la salud"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag7.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/viscobasic-logo.jpg"
                        title="LÍDERES EN PROTECCIÓN Y REGENERACIÓN ARTICULAR"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/aeaserod2021/mybag/mybag8.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "420") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag1.jpg"
                        logo=""
                        title="XXIV Congreso Nacional de Psiquiatría"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag1.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag2.jpg"
                        logo=""
                        title="XXIII SIMPOSIO DE TRASTORNOS BIPOLARES Y DEPRESIVOS"
                        desc="Actualización práctica"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag3.jpg"
                        logo=""
                        title="PSY"
                        desc="Programa anual mundial de actualización continuada en Psiquiatría"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag4.jpg"
                        logo=""
                        title="PostCAAP 2022"
                        desc="XVIII Curso"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag5.jpg"
                        logo=""
                        title="SIMPOSIO DEPRESIÓN"
                        desc="Avanzando en el abordaje de la depresión resistente: necesidades no cubiertas y nuevas perspectivas"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag5.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag6.jpg"
                        logo=""
                        title="KAPLAN & SADOCK"
                        desc="Sinopsis de Psiquiatría"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag6.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag7.jpg"
                        logo=""
                        title="23º Congreso de PATOLOGÍA DUAL"
                        desc="La Patología Dual como integradora de la salud mental desde las neurociencias"
                        file="https://www.emma.events/static/virtualvenue/cnp2021/mybag/mybag7.pdf"
                    />





                </React.Fragment>
            )

        } else if (eventId === "383") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag1.jpg"
                        logo=""
                        title="54º CONGRESO SEPAR"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag1.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/grifols-logo.jpg"
                        title="ALFACARE"
                        desc="SERVICIO DE TRATAMIENTO DOMICILARIO"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag2.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/oxygen-salud-logo.jpg"
                        title="OXIGEN salud"
                        desc="Confianza. Proximidad. Innovación."
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag3.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/sapio-life-logo.jpg"
                        title="RESPIRANDO EL FUTURO"
                        desc="Ayudando a nuestros pacientes desde hace más de 40 años"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag4.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/teva-logo.jpg"
                        title="SEFFALAIR® SPIROMAX®"
                        desc="COMBINACIÓN DE ICS/LABA EN EL DISPOSITIVO SPIROMAX"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag5.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/visio-logo.jpg"
                        title="Tavulus"
                        desc="Tratamiento de elección para el tratamiento de la EPOC en pacientes de bajo riesgo."
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag6.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag7.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/astrazeneca-logo.jpg"
                        title="Aires de debate en EPOC"
                        desc="Convergencias & divergencias"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag7.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag8.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/chiesi-logo.jpg"
                        title="Pasado, presente y futuro de la triple terapia fija en las Enfermedades Respiratorias"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag8.pdf?ver=3"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag9.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/gsk-logo.jpg"
                        title="AIRES DE DEBATE:"
                        desc="Evolución terapéutica de la Neumología ¿Qué cambio han supuesto los Biológicos?"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag9.pdf?ver=3"
                    />




                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag10.jpg"
                        logo="https://www.emma.events/static/virtualvenue/separ2021/industria/logos/sanofi-logo.jpg"
                        title="SIMPOSIO"
                        desc="INNOVANDO EN EL ABORDAJE CLÍNICO DEL ASMA GRAVE TIPO 2"
                        file="https://www.emma.events/static/virtualvenue/separ2021/mybag/mybag10.pdf?ver=3"
                    />





                </React.Fragment>
            )

        } else if (eventId === "405") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag0.jpg"
                        logo=""
                        title="LAHRS2021"
                        desc="Programme"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag0.pdf?ver=4"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/abbott-logo.jpg"
                        title="MANAGING COMPLEX ARRHYTHMIAS IN A DATA-DRIVEN WORLD"
                        desc="LAHRS LIVE WEBINAR"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag1.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/abbott-logo.jpg"
                        title="MAPPING WITHOUT COMPROMISE"
                        desc="ENSITE™ X EP SYSTEM WITH ENSITE™ OMNIPOL AR TECHNOLOGY (OT)"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag2.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/medtronic-logo.jpg"
                        title="Conoce Micra™"
                        desc="Introducción al marcapasos sin electrodo"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/johsnonjohnson-logo.jpg"
                        title="EP Program with Safe, Effective, and Efficient AF & VT Ablation Workflows"
                        desc="LIVE WEBINAR"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag4.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag5.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/aptmedical-logo.jpg"
                        title="From 3D curve decapolar catheter to more"
                        desc="Welcome to join our symposium"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag5.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag6.jpg"
                        logo="https://www.emma.events/static/virtualvenue/lahrs2021/industria/logos/cook-medical-logo.jpg"
                        title="Sistema de extracción simple y comprobado"
                        desc="ROTATIONAL TLE SYSTEM"
                        file="https://www.emma.events/static/virtualvenue/lahrs2021/mybag/mybag6.pdf"
                    />



                </React.Fragment>
            )

        } else if (eventId === "424") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/separ-logo.jpg"
                        title="PROGRAMA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/mybag1.pdf?ver=2"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/mybag2-logo.jpg"
                        title="Tavulus / Soprobec"
                        desc="VISO FARMACÉUTICA1"
                        file="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/mybag/mybag2.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "425") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/separ-logo.jpg"
                        title="PROGRAMA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag1.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag2.jpg"
                        logo="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag2-logo.jpg?ver=2"
                        title="¿Por qué elegir Ellipta para tu paciente con patología respiratoria?"
                        desc="Una alternativa sencilla para acompañarles en las distintas fases de su enfermedad"
                        file="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag2.pdf"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag3.jpg"
                        logo="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag3-logo.jpg?ver=2"
                        title="Tavulus / Soprobec"
                        desc="VISO FARMACÉUTICA1"
                        file="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag3.pdf"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag4.jpg"
                        logo="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag4-logo.jpg?ver=2"
                        title="Tu puedes ayudarle"
                        desc="Tienes la llave"
                        file="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/mybag/mybag4.pdf"
                    />

                </React.Fragment>
            )

        } else if (eventId === "336") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/sefc2022/mybag/mybag1.jpg"
                        logo="https://www.emma.events/static/virtualvenue/sefc2022/mybag/sefc-logo.jpg"
                        title="PROGRAMA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/sefc2022/mybag/mybag1.pdf?ver=3"
                    />



                </React.Fragment>
            )

        } else if (eventId === "489") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag1.jpg"
                        logo=""
                        title="XXIV Congreso Nacional de Psiquiatría"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag1.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag10.jpg"
                        logo=""
                        title="!NO TE PIERDAS NI UNA PUBLICACIÓN DEL CONGRESO!"
                        desc="RECOGE TU USB"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag10.png?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag2.jpg"
                        logo=""
                        title="TRASTORNOS AFECTIVOS"
                        desc="NEUROBIOLOGÍA, DIAGNÓSTICO Y TRATAMIENTO"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag2.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag3.jpg"
                        logo=""
                        title="La forma de entender el insomnio está cambiando"
                        desc="El insomnio puede deberse a varios factores"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag3.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag4.jpg"
                        logo=""
                        title="IM MÉDICO"
                        desc="Innovación para el médico especialista de hospital y atención primaria: investigación médica, gestión, tecnología y servicios sanitarios"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag4.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag5.jpg"
                        logo=""
                        title="SIMPOSIO DEPRESIÓN"
                        desc="La Depresión Mayor como centro del debate"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag5.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag6.jpg"
                        logo=""
                        title="SIMPOSIO ESQUIZOFRENIA"
                        desc="LA PSIQUIATRÍA DEL FUTURO, YA ES PRESENTE"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag6.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag7.jpg"
                        logo=""
                        title="Mentalia Salud"
                        desc="Cuidados y acompañamiento en salud mental"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag7.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag8.jpg"
                        logo=""
                        title="NEURAL NAVIGATOR"
                        desc="Neuronavegación para tratamientos con EMTr"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag8.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag9.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE"
                        desc="MÁS ALLÁ DE LOS SÍINTOMAS: nuevos objetivos de la psiquiatría en el manejo de la esquizofrenia"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag9.png?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag11.jpg"
                        logo=""
                        title="29 Symposium Internacional sobre Controversias en Psiquiatría"
                        desc="¿PSIQUIATRÍA DE PRECISIÓN?"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag11.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag12.jpg"
                        logo=""
                        title="XXIV SIMPOSIO DE TRASTORNOS BIPOLARES Y DEPRESIVOS"
                        desc="Ampliando conocimientos"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag12.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag13.jpg"
                        logo=""
                        title="PSY"
                        desc="Programa mundial de actualización continuada en Psiquiatría"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag13.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag14.jpg"
                        logo=""
                        title="INNOVACIÓN"
                        desc="QUE TE AYUDA A CONSTRUIR LA ALIANZA TERAPÉUTICA EN ESQUIZOFRENIA"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag14.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag15.jpg"
                        logo=""
                        title="TOP10 PSIQUIATRÍA"
                        desc="UNA PUESTA AL DÍA DE LOS AVANCES MÁS RELEVANTES EN LAS ÁREAS PRINCIPALES DE LA PSIQUIATRÍA"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag15.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag16.jpg"
                        logo=""
                        title="XXVI Congreso Nacional de Psiquiatría"
                        desc="23 A 25 DE NOVIEMBRE DE 2023 - SALAMANCA"
                        file="https://www.emma.events/static/virtualvenue/cnp2022/mybag/mybag16.pdf?ver=1"
                    />






                </React.Fragment>
            )

        } else if (eventId === "575") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag1.jpg"
                        logo=""
                        title="XXVI Congreso Nacional de Psiquiatría"
                        desc="Programa"
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag1.pdf?ver=2"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag2.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE - JANSSEN"
                        desc="El paciente en el centro: la evolución de psicofarmacología en patología mental grave."
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag2.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag3.jpg"
                        logo=""
                        title="I REUNIÓN GRUPO DE TRABAJO"
                        desc="Deterioro Cognitivo y Trastorno Mental Grave de la SEPSM."
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag3.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag4.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE - IDORSIA"
                        desc="¿Qué hay de nuevo en el insomnio? Un trastorno con afectación nocturna y diurna."
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag4.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag5.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE ALIANZA OTSUKA-LUNDBECK"
                        desc="Recorrido asistencial del paciente con esquizofrenia: ¿qué retos se nos plantean?"
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag5.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag6.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE - LUNDBECK"
                        desc="Sexualidad y Psiquiatría."
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag6.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag7.jpg"
                        logo=""
                        title="SIMPOSIO SATÉLITE - ROVI"
                        desc="De la innovación a la funcionalidad del paciente con esquizofrenia."
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag7.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag8.jpg"
                        logo=""
                        title="PROGRAMA SIMPOSIO DE TRASTORNOS BIPOLARES Y DEPRESIVOS"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag8.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag9.jpg"
                        logo=""
                        title="LACTIMAL, CRISOMET, LABILENO"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag9.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag10.jpg"
                        logo=""
                        title="TOP 10 PSIQUIATRÍA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag10.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag11.jpg"
                        logo=""
                        title="PROGRAMA CURSO DE PSICOFARMACOLOGÍA Y NEUROCIENCIA"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag11.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag12.jpg"
                        logo=""
                        title="REVISTA IM MEDICO"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag12.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag13.jpg"
                        logo=""
                        title="XII Congreso Internacional de Psiquiatría y Sexualidad"
                        desc=""
                        file="https://www.emma.events/static/virtualvenue/cnp2023/mybag/mybag13.pdf?ver=1"
                    />


                </React.Fragment>
            )

        } else if (eventId === "656") {
            return (
                <React.Fragment>

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag1.jpg?ver=1"
                        logo=""
                        title="QUVIVIQ - daridorexant"
                        desc="Es el primer DORA (antangonista dual de los receptors de las orexinas) aprobado en Europa indicado para el insomnio crónico"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag1.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag2.jpg?ver=1"
                        logo=""
                        title="XXVII Congreso Nacional de Psiquiatría"
                        desc="17 - 19 octubre 2024, Palacio de Congresos Kursaal, San Sebastián."
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag2.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag3.jpg?ver=1"
                        logo=""
                        title="EXPLORACIÓN NEUROPSICOLÓGICA en trastorno mental grave"
                        desc="Curso Online - 31 de octubre 2024"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag3.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag4.jpg?ver=1"
                        logo=""
                        title="Xeristar"
                        desc="Expande su luz con una gama más amplia"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag4.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag5.jpg?ver=1"
                        logo=""
                        title="Consenso sobre la detección y el manejo"
                        desc="de pacientes con depression y dolor como síntoma somático asociado"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag5.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag6.jpg?ver=1"
                        logo=""
                        title="XXVI SIMPOSIO DE TRASTORNOS BIPOLARES Y DEPRESIVOS"
                        desc="Necesidades no cubiertas"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag6.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag7.jpg?ver=1"
                        logo=""
                        title="OKEDI"
                        desc="Es el único ILD de 4 semanas que alcanza una concentración terapéutica media de 38,63 ng/mL..."
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag7.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag8.jpg?ver=1"
                        logo=""
                        title="XXVII CONGRESO NACIONAL de Psiquiatría"
                        desc="DE LA FARMACOCINÉTICA A LA CLÍNICA EN EL PACIENTE CON ESQUIZOFRENIA"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag8.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag9.jpg?ver=1"
                        logo=""
                        title="Tiempo y transformación"
                        desc="Avanzando en el tratamiento de la depresión y la esquizofrenia"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag9.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag10.jpg?ver=1"
                        logo=""
                        title="PALMEUX"
                        desc="Palmitato de paliperidona inyectable de liberación prolongada (PP1M)"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag10.pdf?ver=1"
                    />


                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag11.jpg?ver=1"
                        logo=""
                        title="IM MÉDICO - Septiembre-Octubre 2024"
                        desc="EXIT - EN EL LABERINTO DE LA DEPRESIÓN, EXISTE SALIDA"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag11.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag12.jpg?ver=1"
                        logo=""
                        title="SIMPOSIO SATÉLITE - Innovando para Alcanzar Más"
                        desc="en el tratamiento de la esquizofrenia"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag12.pdf?ver=1"
                    />
                    
                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag13.jpg?ver=1"
                        logo=""
                        title="RECOGE TU USB"
                        desc="con todos los pósters presentados"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag13.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag14.jpg?ver=1"
                        logo=""
                        title="Sistema HemoCue WBC DIFF"
                        desc="Diferencia innovadora para una atención más rápida"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag14.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag15.jpg?ver=1"
                        logo=""
                        title="MADRID 2025 - XV CONGRESO NACIONAL DE TRASTORNOS DE LA PERSONALIDAD:"
                        desc="Humanidad y ciencia"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag15.pdf?ver=1"
                    />
                   

                   <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag16.jpg?ver=1"
                        logo=""
                        title="TOP 10 PSIQUIATRÍA"
                        desc="UNA PUESTA AL DÍA DE LOS AVANCES MÁS RELEVANTES EN LAS ÁREAS PRINCIPALES DE LA PSIQUIATRÍA"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag16.pdf?ver=1"
                    />

                    <MyBagInsert
                        flyer="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag17.jpg?ver=1"
                        logo=""
                        title="Instituto Europeo de Salud Mental Perinatal"
                        desc="Fórmate en salud mental perinatal"
                        file="https://www.emma.events/static/virtualvenue/cnp2024/mybag/mybag17.pdf?ver=1"
                    />

                </React.Fragment>
            )

        }

        else {
            return (<div></div>)
        }




    }
}

export default TabInserts;