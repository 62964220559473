import React from 'react';
import Session from './Session';

function RoomDetailed(props) {
    
    const roomPreviousNext = props.isPrevious 
        ? "room-sessions-wrapper nomargin previous-room" 
        : "room-sessions-wrapper nomargin next-room";

    return (
        <div className={roomPreviousNext}>
              
            <div className="room-sessions-header-name">
                {props.name}
            </div>
            <div className="room-sessions-area">
                
                <table border="0" cellSpacing="0" cellPadding="0" className="tbl-room-sessions-panel">
                    <tbody>
                    <Session 
                        time="08:00" 
                        locked={true} 
                        title="LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO" 
                        companyLogo="/img/logo-company-sessions-days.png"
                        noTheme={false} />
                    <Session 
                        time="09:00" 
                        locked={false} 
                        title="LA PANDEMIA DEL SIGLO XXI: HEPATOPATÍA y SINDROME DE APENAS DEL SUEÑO" 
                        noTheme={true} />
                    <Session 
                        time="11:30" 
                        locked={true} 
                        title="enfermidades pulmonares and comorbidity in sleep apnea" 
                        companyLogo="/img/logo-company-sessions-days.png"
                        noTheme={false} />
                    </tbody>
                </table>

            </div>
        </div>

    );
}

export default RoomDetailed;