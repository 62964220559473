import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { connectSocket, disconnectSocket, logWatch, logPathname } from '../../store/actions/global';
import SessionOverview from './SessionOverview';
import SessionAgenda from './SessionAgenda';
import SessionFiles from './SessionFiles';
import NavBar from './NavBar/NavBar';
import Chat from './Interactions/Chat/Chat';
import QA from './Interactions/QA/QA';
import Voting from './Interactions/Voting/Voting';
import Survey from './Interactions/Survey/Survey';
import AuthContext from '../../context/auth-context';
import { Redirect } from 'react-router';
import axios from "axios";
import WithPermission from '../../hoc/WithPermission';
import { withTranslation } from 'react-i18next'
import configData from '../../config.json';
import TermsConditions from '../../components/TermsConditions/TermsConditions';
import CookiesPolicy from '../../components/CookiesPolicy/CookiesPolicy';
import LegalNotice from '../../components/LegalNotice/LegalNotice';
import LegalLinks from '../../components/LegalLinks/LegalLinks';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import Rating from './Interactions/Rating/Rating'


class Room extends Component {

    state = {
        sessionId: "",
        room_id: "",
        access: [],
        day: "",
        title: "",
        type: "",
        time_start: "",
        time_end: "",
        showDetails: false,
        showOverview: false,
        showAgenda: false,
        showFiles: false,
        rightColVisible: false,
        navBarVisible: false,
        chat: true,
        chat_moderated: false,
        qa: false,
        voting: false,
        survey: false,
        liveType: "Live",
        liveAgenda: false,
        on: null,
        isPublic: true,
        sponsor: false,
        isSponsored: false,
        sponsor_id: "",
        sponsor_logo: "",
        user: {},
        backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'rooms', this.props.match.params.roomId),
        session_moderators: [],
        user_is_moderator: false,
        agenda: [],
        CookiesPolicyPopUpOn: false,
        LegalNoticePopUpOn: false,
        TermsConditionsPopUpOn: false,
        PrivacyPolicyPopUpOn: false,
        VotingChatSurveyMobileOn: false,
        votingLinks: [],
        video_start: 0,
        rating:false,
        myScore: 0
    }

    player = null;
    video = {
        id: 0,
        watched: [],
        playing: false,
        session_credits: 1.2,
        duration: 0,
        single_watched: (watched) => {
            watched.sort((a, b) => a.start - b.start);   // first, sort the watched
            const mergeFn = (a, b) => ({ start: Math.min(a.start, b.start), end: Math.max(a.end, b.end) }); // take two watched, and merges them together
            const overlapFn = (a, b) => (b.start <= a.end);   // check if two watched overlap
            let current = watched[0];   // make current the first item of the array (start the array from 1 to not check the first item against itself)
            let result = [];
            for (var i = 1; i < watched.length; i++) {
                if (overlapFn(current, watched[i])) // if the current range overlapping with this range
                    current = mergeFn(current, watched[i]); // merge them into the current range
                else { // if not
                    result.push(current); // add the current accumulated range as result
                    current = watched[i]; // start accumulating another one from this range
                }
            }
            result.push(current); // add the last result
            return result;
        },
        seconds: () => {
            let total = 0
            const intervals = this.single_watched(this.watched);
            for (let i = 0; i < intervals.length; i++) {
                total += (intervals[i].end - intervals[i].start);
            }
            return total;
        },
        percentage: () => {
            return parseFloat((this.seconds / this.duration) * 100).toFixed(0)
        }
    }

    player_init() {

        try {

            this.iframe = document.querySelector('iframe')
            this.iframe.src = this.state.on;
            this.player = new window.Vimeo.Player(this.iframe);
            this.player.ready().then(
                this.player.getDuration().then((duration) => {
                    if (duration) {
                        this.video.duration = duration;
                    }
                })
            )

            this.player.on('play', function (data) {
                this.video.playing = true;
                //console.log('play')      
            });

            this.player.on('ended', (data) => {
                //console.log('ended')
                //console.log(this.video.watched)
                this.onSaveSessionTime()
                this.props.onWatch(this.currentTimeToBeSaved());
                // console.log('ended')
                // console.log(this.video.watched)
                // save watched intervals
            });

            this.player.on('error', (error) => {
                //console.log('error')
                //console.log(error)
                this.onSaveSessionTime()
                this.props.onWatch(this.currentTimeToBeSaved());
                // save watched intervals
            });

            this.player.on('loaded', (error) => {
                this.video.watched = [{ start: 0, end: 0 }]
                //console.log('loaded')
                // console.log(error)
            });

            this.player.on('pause', (data) => {
                this.video.playing = false;
                //console.log('pause')
                //console.log(this.video.watched)
                this.onSaveSessionTime()
                this.props.onWatch(this.currentTimeToBeSaved());
                // console.log(data.seconds)
                //console.log(this.video.watched)
                // save watched intervals
            });

            this.player.on('timeupdate', (data) => {
                //console.log('timeupdate')
                // para suportar o live feed, que pode começar ja a meio, fazemos set aqui do start
                if (this.video.watched[0].start == 0 && this.video.watched[0].end == 0) {
                    this.video.watched[0].start = data.seconds
                    this.video.watched[0].end = data.seconds
                }
                // se avança mais de 2 segundos.... inicia-se um novo intervalo
                if (data.seconds - this.video.watched[0].end > 2 || data.seconds - this.video.watched[0].end < -2) {
                    this.video.watched.push({ start: this.video.watched[0].start, end: this.video.watched[0].end })
                    this.video.watched[0].start = data.seconds
                    //console.log(this.video.watched);
                    // save do intervals 
                }
                this.video.watched[0].end = data.seconds
            });

            this.player.on('seeked', (data) => {
                //console.log('seeked: ' + data.seconds)
                //console.log(this.state.video_start)

                if (data.seconds < this.state.video_start) {
                    //console.log('setCurrentTime')
                    this.player.setCurrentTime(this.state.video_start)
                }
                //console.log(this.video.watched)
                this.onSaveSessionTime()
                this.props.onWatch(this.currentTimeToBeSaved());
            });

        } catch (error) {
            console.log(error)
        }


    }

    currentTimeToBeSaved = () => {
        const myTime = {
            user_id: this.context.id,
            session_id: this.props.match.params.sessionId,
            intervals: this.video.watched
        }
        return myTime;
    }

    onSaveSessionTime = () => {

        const myTime = this.currentTimeToBeSaved();

        let config = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            }
        };

        axios.post(configData.SERVER_API + '/users/me/videotime', myTime, config)
            .then(response => { })
            .catch(error => {
                //console.log(error)
            })

    }

    video_load(video_id) {
        this.playerDate.watched = [{ start: 0, end: 0 }]
        this.player.loadVideo(video_id).then(function (id) {
            console.log('Video loaded')
        }).catch(function (error) {
            switch (error.name) {
                case 'TypeError':   // The ID isn't a number
                    break;
                case 'PasswordError':   // The video is password-protected
                    break;
                case 'PrivacyError':    // The video is private
                    break;
                default:    // Some other error occurred
                    break;
            }
        });
    }

    video_unload() {
        this.video.watched = [{ start: 0, end: 0 }]
        this.player.unload().then(function () {
            //console.log('Video unloaded')
        });
    }

    static contextType = AuthContext;

    componentDidMount() {
        window.addEventListener("beforeunload", this.doSomethingBeforeUnload);
        this.loadUserDetails()
        this.loadSessionSettings(this.props.match.params.roomId, this.props.match.params.sessionId);
        this.loadVotingLinks(this.props.match.params.sessionId);
        this.loadUserRating(this.props.match.params.sessionId)
        this.onRouteChanged(this.props.location);
    }

    doSomethingBeforeUnload = (ev) => {
        ev.preventDefault();
        this.onSaveSessionTime();
        this.props.onWatch(this.currentTimeToBeSaved());

    }

    onRouteChanged(location) {
        this.props.onPathname({
            type: "ENTER",
            object: "SESSION",
            pathname: window.location.pathname
        });
    }

    componentDidUpdate(prevProps) {


        if (this.props.location !== prevProps.location) {
            this.onRouteChanged(this.props.location);
        }

    }

    componentWillUnmount() {
        //console.log('componentWillUnmount')
        this.onSaveSessionTime();
        this.props.onWatch(this.currentTimeToBeSaved());
        window.removeEventListener("beforeunload", this.doSomethingBeforeUnload);
    }

    logoutHandler = () => {

        this.onSaveSessionTime();
        this.props.onWatch(this.currentTimeToBeSaved());
        this.props.onDisconnectSocket();
        this.props.onConnectSocket(null);

        localStorage.removeItem('e-congress.events.token');
        localStorage.removeItem('e-congress.events.settings');
        localStorage.setItem('e-congress.events.history', this.context.event_code);

        this.context.token = null;
        this.context.exp = null;
        this.context.authenticated = false;
        this.context.id = null;
        this.context.event_id = null;
        this.context._id = null;
        this.context.isAdmin = false;
        this.context.profile = null;

        this.props.history.push('/' + this.context.event_code);

    }

    loadUserDetails() {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: { _id: this.context._id }
        };

        axios.get(configData.SERVER_API + "/users/me", data)
            .then(response => {
                const user = {
                    name: response.data.name !== undefined && response.data.name !== "" ? response.data.name : "",
                    surname: response.data.surname !== undefined && response.data.surname != "" ? " " + response.data.surname : "",
                    photo: response.data.photo !== undefined && response.data.photo !== "" ? response.data.photo : "/img/default-login-pic.png"

                }
                this.setState({ user: user })
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    loadUserRating(session_id) {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/users/me/rating/session/" + session_id, data)
            .then(response => {
                if (response){
                    this.setState({ myScore: response.data[0].rating * 1 })                
                } else {
                    this.setState({ myScore: 0 })                
                }
            })
            .catch(error => {
                this.setState({ myScore: 0 })
                console.log(error.message);
            })
    }

    // CUSTOM MADE FOR INTEGRATION WITH ODEC ----
    loadVotingLinks(session_id) {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/sessions/" + session_id + "/votinglinks/" + this.context.id, data)
            .then(response => {
                this.setState({ votingLinks: response.data })
            })
            .catch(error => {
                console.log(error.message);
            })
    }
    // end CUSTOM MADE INTEGRATION WITH ODEC ----

    loadSessionSettings(room_id, session_id) {
        let data = {
            headers: {
                "x-auth-token": this.context.token,
                "content-type": "application/json",
            },
            params: {}
        };

        axios.get(configData.SERVER_API + "/sessions/" + session_id, data)
            .then(response => {

                const isLive = response.data[0].isLive;
                const liveSource = response.data[0].live_source;
                const timeStartMin = response.data[0].live_start_time !== undefined ? response.data[0].live_start_time.split(':')[0] : "0";
                const timeStartSec = response.data[0].live_start_time !== undefined ? response.data[0].live_start_time.split(':')[1] : "0";

                //let videoUrl = response.data[0].live_type === "Live" ? response.data[0].vimeo.filter(i => i.type == liveSource)[0].url : "";
                let videoUrl = response.data[0].vimeo.filter(i => i.type == liveSource)[0].url;

                if (response.data[0].autoplay) {
                    videoUrl = videoUrl + '?autoplay=1';
                    if (response.data[0].muted) {
                        videoUrl = videoUrl + '&muted=1'
                    }
                } else {
                    if (response.data[0].muted) {
                        videoUrl = videoUrl + '?muted=1'
                    }
                }

                if (videoUrl !== "") {
                    videoUrl = videoUrl + '#t=' + timeStartMin + 'm' + timeStartSec + 's';
                }

                const dt_start = response.data[0].time_start ? response.data[0].time_start.split("T")[1].substring(0, 5) : "";
                const dt_end = response.data[0].time_end ? response.data[0].time_end.split("T")[1].substring(0, 5) : "";

                if (response.data[0].live_type !== "Live") { // it's ondemand -> check if it's time to show the video already 
                    const dateStart = response.data[0].time_start.replace(/T/g, ' ').replace(/Z/g, '')
                    const date_start = new Date(dateStart);
                    const d = new Date();
                    const d_new = dateAdd(d, "minute", 60)  // to be in the correct time zone
                    if (d_new < date_start) {   // current data < session start date -> not started
                        videoUrl = ""
                    }
                }

                //console.log('videoUrl: ' + videoUrl)

                this.setState({
                    sessionId: session_id,
                    room_id: room_id,
                    showDetails: response.data[0].live_type === "Live" ? false : false,
                    showAgenda: response.data[0].live_type === "Live" ? false : false,
                    access: response.data[0].access,
                    day: response.data[0].day,
                    title: response.data[0].title,
                    type: response.data[0].type,
                    time_start: dt_start,
                    time_end: dt_end,
                    rightColVisible: response.data[0].chat || response.data[0].qa || response.data[0].voting || response.data[0].survey ? true : false,
                    navBarVisible: this.state.navBarVisible,
                    chat: response.data[0].chat,
                    chat_moderated: response.data[0].chat_moderated,
                    qa: response.data[0].qa,
                    qa_moderated: response.data[0].qa_moderated,
                    voting: response.data[0].voting,
                    survey: response.data[0].survey,
                    liveType: response.data[0].live_type,
                    liveAgenda: response.data[0].live_agenda !== undefined ? response.data[0].live_agenda : false,
                    on: videoUrl,
                    isPublic: response.data[0].isPublic,
                    sponsor: response.data[0].sponsor,
                    isSponsored: response.data[0].isSponsores,
                    sponsor_id: response.data[0].sponsor_id,
                    sponsor_logo: response.data[0].sponsor_logo,
                    backgroundImage: this.context.loadBackgroundImage(this.context.event_settings, 'rooms', this.props.match.params.roomId),
                    session_moderators: response.data[0].session_moderators,
                    user_is_moderator: this.userIsModerator(response.data[0].session_moderators, this.context.id),
                    agenda: response.data[0].agenda,
                    video_start: timeStartMin * 60 + timeStartSec * 1,
                    rating: response.data[0].rating !== undefined ? response.data[0].rating : false
                });


                // LOG DE ENTRADA NA SESSION -----------------------------
                let config = {
                    headers: {
                        "x-auth-token": this.context.token,
                        "content-type": "application/json",
                    }
                };
                let params = {
                    "type": "room-entrance",
                    "details": "",
                    "user_id": this.context.id,
                    "event_id": this.context.event_id,
                    "stand_id": this.props.match.params.sessionId,
                    "stand": response.data[0].title
                };

                axios.post(configData.SERVER_API + "/logs/", params, config)
                    .then(response => { })
                    .catch(error => { })

                if (videoUrl !== undefined && videoUrl !== "") {
                    this.player_init();
                }

            })
            .catch(error => {
                console.log(error.message);
            })
    }

    exitHandler = () => {
        if (this.context.event_id === "387" || this.context.event_id === "2" || this.context.event_id === "390" || this.context.event_id === "3" || this.context.event_id === "5") {
            this.props.history.push('/congress/lobby')
        }
        else {
            this.props.history.push('/congress/lobby-scientific')
        }

    }

    switchRightColumnHandler = () => {
        this.setState((prevState, props) => {
            return { rightColVisible: !prevState.rightColVisible };
        })
    }

    switchNavBarHandler = () => {
        this.setState((prevState, props) => {
            return { navBarVisible: !prevState.navBarVisible };
        })
    }
    onSessionChangeHandler = (e) => {
        this.props.history.push('/room/' + e.roomId + '/' + e.sessionId);
        this.loadSessionSettings(e.roomId, e.sessionId)
        this.loadUserRating(e.sessionId)
        this.switchRightColumnHandler();
    }


    userIsModerator = (moderators, userId) => {
        if (moderators !== undefined && moderators.length > 0) {
            const moderator = moderators.filter((el) => el === userId);
            if (moderator !== undefined && moderator.length > 0) {
                return true;
            }
        } else {
            return false;
        }
    }

    showDetailsHandler = () => {
        if ((this.context.event_id === "314" || this.context.event_id === "289" || this.context.event_id === "263" || this.context.event_id === "378" || this.context.event_id === "372" || this.context.event_id === "373" || this.context.event_id === "376" || this.context.event_id === "397" || this.context.event_id === "325" || this.context.event_id === "341" || this.context.event_id === "320" || this.context.event_id === "379" || this.context.event_id === "383" || this.context.event_id === "424" || this.context.event_id === "327" || this.context.event_id === "425") && this.context.profile !== 'press') {

        } else {
            this.setState((prevState, props) => {
                return {
                    showDetails: !prevState.showDetails,
                    showAgenda: !prevState.showAgenda,
                };
            })
        }
    }

    onLoadVideoHandler = (videoId) => {
        this.setState({ on: "https://player.vimeo.com/video/" + videoId })
        //this.player_init();
    }

    onCloseAgenda = () => {
        this.setState({
            showDetails: false,
            showAgenda: false
        })

    }


    onCookiesPolicyCloseHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: false });
    }

    onCookiesPolicyOpenHandler = () => {
        this.setState({ CookiesPolicyPopUpOn: true });
    }

    onLegalNoticeCloseHandler = () => {
        this.setState({ LegalNoticePopUpOn: false });
    }

    onLegalNoticeOpenHandler = () => {
        this.setState({ LegalNoticePopUpOn: true });
    }

    onTermsConditionsCloseHandler = () => {
        this.setState({ TermsConditionsPopUpOn: false });
    }

    onTermsConditionsOpenHandler = () => {
        this.setState({ TermsConditionsPopUpOn: true });
    }

    onPrivacyPolicyCloseHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: false });
    }

    onPrivacyPolicyOpenHandler = () => {
        this.setState({ PrivacyPolicyPopUpOn: true });
    }

    MobileVotingChatSurvey = () => {

        if (this.state.VotingChatSurveyMobileOn == false) {
            this.setState({
                VotingChatSurveyMobileOn: true
            })
        }
        else {
            this.setState({
                VotingChatSurveyMobileOn: false
            })
        }

    }

    onScore = (e) => {        
        let config = {
            headers: {
              "x-auth-token": this.context.token,
              "content-type": "application/json",
            }};
        let params = { 
                "session_id" : this.props.match.params.sessionId, 
                "rating" : e.target.alt * 1,
                "user_id": this.context.id
            };

        axios.post(configData.SERVER_API + "/sessions/" + this.props.match.params.sessionId + "/ratings" , params, config)        
        .then(response => {                 
            this.setState({myScore:response.data.rating * 1})
        })
        .catch(error => {
            
        })
    }

    render() {

        if (this.props.forceLogout) {
            this.logoutHandler();
        }

        document.getElementById('root').style.backgroundImage = 'url("' + this.state.backgroundImage + '")';

        let player;

        if (document.getElementById('video-Id')) {
            //console.log(document.getElementById('video-Id').src)
        }

        let classVideo, classOpenCloseRightColumn, classOpenCloseNavBar

        if (this.state.rightColVisible) {
            classVideo = "left-column-cont";
            classOpenCloseRightColumn = "econgress-room-btn-side-panel-container close " + this.context.event_code.toLowerCase();
        } else {
            classVideo = "left-column-cont-total";
            classOpenCloseRightColumn = "econgress-room-btn-side-panel-container " + this.context.event_code.toLowerCase();
        }

        if (this.state.navBarVisible) {
            classOpenCloseNavBar = "bottom-open-close-videos-slider-wrapper close"
        } else {
            classOpenCloseNavBar = "bottom-open-close-videos-slider-wrapper"
        }

        let nEl = 0;
        let classBlock = "block-";

        if (this.state.chat) nEl = 1;
        if (this.state.qa) nEl++
        if (this.state.voting) nEl++
        if (this.state.survey) nEl++

        classBlock = classBlock + nEl;

        let RightColumnSponsoredClass = "";
        if (this.state.isSponsored == true) {
            RightColumnSponsoredClass = "right-column sponsored-true";
        }
        else {
            RightColumnSponsoredClass = "right-column sponsored-false";
        }

        if (this.context.event_settings.event_id == "342" || this.context.event_settings.event_id == "378" || this.context.event_settings.event_id == "372" || this.context.event_settings.event_id == "373" || this.context.event_settings.event_id == "376" || this.context.event_settings.event_id == "397" || this.context.event_settings.event_id == "320" || this.context.event_settings.event_id == "379" || this.context.event_settings.event_id == "424" || this.context.event_settings.event_id == "425") {
            RightColumnSponsoredClass = "right-column sponsored-true";
        }

        const CookiesPolicyPopUp = this.state.CookiesPolicyPopUpOn ? <CookiesPolicy event_id={this.context.event_settings.event_id} onCookiesPolicyClose={this.onCookiesPolicyCloseHandler}></CookiesPolicy> : null;
        const LegalNoticePopUp = this.state.LegalNoticePopUpOn ? <LegalNotice event_id={this.context.event_settings.event_id} onLegalNoticeClose={this.onLegalNoticeCloseHandler}></LegalNotice> : null;
        const TermsConditionsPopUp = this.state.TermsConditionsPopUpOn ? <TermsConditions event_id={this.context.event_settings.event_id} onTermsConditionsClose={this.onTermsConditionsCloseHandler}></TermsConditions> : null;
        const PrivacyPolicyPopUp = this.state.PrivacyPolicyPopUpOn ? <PrivacyPolicy event_id={this.context.event_settings.event_id} onPrivacyPolicyClose={this.onPrivacyPolicyCloseHandler}></PrivacyPolicy> : null;

        let BottomSessionsBtnClass = "bottom-open-close-videos-slider-container " + this.context.event_code;

        let RatingObject = this.state.rating ? <Rating rate={this.state.myScore} transparent={false} scoreHandler={this.onScore}></Rating> : null;

        return this.context.login(this.context.token) || this.context.autoLogin() ? (

            <React.Fragment>
                {this.state.showOverview ? <SessionOverview></SessionOverview> : null}
                {this.state.showAgenda ? <SessionAgenda key={this.state.sessionId} timeStart={this.state.time_start}
                    timeEnd={this.state.time_end}
                    title={this.state.title}
                    moderators={this.state.session_moderators}
                    agenda={this.state.agenda}
                    loadVideoHandler={this.onLoadVideoHandler}
                    closeAgenda={this.onCloseAgenda}
                    liveAgenda={this.state.liveAgenda}
                    eventCode={this.context.event_code}>
                </SessionAgenda>
                    : null}
                {this.state.showFiles ? <SessionFiles></SessionFiles> : null}

                <div className="top-bar-econgress-room">

                    <div className="btn-bars-econgress-room-container cursor-only">
                        <img alt="" src="/img/btn-bars-econgress-room.png" onClick={this.showDetailsHandler} />
                    </div>
                    {this.state.showDetails ?
                        <React.Fragment>
                            <div className="top-bar-btns-container cursor-only">
                                {/* <div className="top-bar-btn-wrapper">RESUMEN</div> */}
                                <div className="top-bar-btn-wrapper" onClick={this.onCloseAgenda} >Agenda</div>
                                {/* <div className="top-bar-btn-wrapper">Archivos</div> */}
                            </div>
                        </React.Fragment>
                        :
                        <div className="top-bar-title-wrapper">

                            {this.context.event_settings.event_id == "405" && this.state.type != "On Demand Session"
                                ? <span>
                                    {this.state.time_start ? this.state.time_start + " / " : ""}
                                    {this.state.time_end}
                                    {this.state.time_start || this.state.time_end ? " - " : ""}
                                </span>
                                : null
                            }

                            {this.state.title}

                        </div>}

                    
                    <div className="top-bar-rating-wrapper">
                        {RatingObject}
                    </div>
                        
                    <div className="top-exit-btn-container">
                        
                        <div className="exit-btn-wrapper" onClick={this.exitHandler}>
                            <div className="exit-btn-img"><img alt="" src="/img/exit-btn-icon.png" /></div>
                            {this.context.event_id == "3" || this.context.event_id == "4" || this.context.event_id == "5" ?
                                <div className="exit-btn-text">salida</div>
                                :
                                <div className="exit-btn-text">{this.props.t('exit')}</div>
                            }
                        </div>
                    </div>

                </div>

                <div className={classVideo}>
                    <div className="left-column">
                        <div className="video-stream-container">
                            <WithPermission userId={this.context.id} userEmail={this.context.email}
                                role={this.context.profile}
                                objectType={"session"}
                                objectSubType={this.state.type}
                                objectPermission={this.state.access}
                                isPublic={this.state.isPublic}
                                eventId={this.context.event_id}>

                                <div className="video-container">

                                    {this.state.on != "" ?
                                        <iframe src={this.state.on} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                        :

                                        this.context.event_id == "3" ?
                                            <div className="container-pre-stream-tromboencuentros2021">
                                                <p><span>Puede dejar de forma anticipada sus preguntas acerca del tema del encuentro. No debe incluir datos de carácter identificativo en las mismas.</span></p>
                                                <img src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/room/tromboencuentros2021-programme-room-30noviembre.jpg?ver=3" />
                                                <img style={{ display: "none" }} src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/room/tromboencuentros2021-programme-room2-26octubre.jpg?ver=2" />
                                                <img style={{ display: "none" }} src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/room/tromboencuentros2021-programme-room2-6julio.jpg?ver=4" />
                                                <img style={{ display: "none" }} src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/room/tromboencuentros2021-programme-room2-15junio.jpg?ver=2" />
                                                <img style={{ display: "none" }} src="https://www.emma.events/static/virtualvenue/encuentrosexperto2021/room/tromboencuentros2021-programme-room2.jpg" />
                                            </div>

                                            : this.context.event_id == "4" ?
                                                <div className="container-pre-stream-tromboencuentros2021">
                                                    <p><span>Desde aquí podrá ver las sesiones en directo del VI Ampliando Horizontes en la ETEV.<br /><br />Durante las sesiones podrá hacer sus preguntas a los ponentes y responder a las preguntas que se puedan plantear a la audiencia.</span></p>

                                                </div>

                                                : null


                                    }
                                </div>
                            </WithPermission>

                            <div className="container-chat-mobile">
                                {this.context.event_id == "387" || this.context.event_id == "2" || this.context.event_id == "3" || this.context.event_id == "4" || this.context.event_id == "5" ?
                                    <span onClick={this.MobileVotingChatSurvey} className="chat-mobile-btn">Abrir Preguntas</span>
                                    : <span onClick={this.MobileVotingChatSurvey} className="chat-mobile-btn">{this.props.t('open_chat')}</span>}

                            </div>

                        </div>
                    </div>
                </div>

                {this.state.VotingChatSurveyMobileOn == true ?

                    <div className="voting-chat-survey-mobile-container">

                        <div className="close-voting-chat-survey-mobile-container">
                            {this.context.event_id == "387" || this.context.event_id == "2" || this.context.event_id == "5" ?
                                <div onClick={this.MobileVotingChatSurvey} class="stand-disclaimer-close-btn-container">Cerrar</div>
                                : <div onClick={this.MobileVotingChatSurvey} class="stand-disclaimer-close-btn-container">{this.props.t('close_chat')}</div>}
                        </div>

                        <WithPermission userId={this.context.id} userEmail={this.context.email}
                            role={this.context.profile}
                            objectType={"session"}
                            objectSubType={this.state.type}
                            objectPermission={this.state.access}
                            isPublic={this.state.isPublic}
                            eventId={this.context.event_id}>

                            {this.state.isSponsored
                                ? <div className="logo-company-container-econgress-room">
                                    {this.state.sponsor_logo
                                        ? <img alt="" src={this.state.sponsor_logo} />
                                        : this.state.sponsor}
                                </div>
                                : null
                            }


                            {this.state.voting ? <Voting userName={this.state.user.name}
                                userSurname={this.state.user.surname}
                                userPhoto={this.state.user.photo}
                                sessionId={this.state.sessionId}
                                classBlock={classBlock}></Voting>
                                : null}
                            {this.state.chat ? <Chat userName={this.state.user.name}
                                userSurname={this.state.user.surname}
                                userPhoto={this.state.user.photo}
                                sessionId={this.state.sessionId}
                                classBlock={classBlock}
                                moderated={this.state.chat_moderated}
                                isModerator={this.state.user_is_moderator}></Chat>
                                : null}
                            {this.state.qa ? <QA></QA> : null}
                            {this.state.survey ? <Survey></Survey> : null}

                        </WithPermission>

                    </div>

                    : null}



                <WithPermission userId={this.context.id} userEmail={this.context.email}
                    role={this.context.profile}
                    objectType={"session"}
                    objectSubType={this.state.type}
                    objectPermission={this.state.access}
                    isPublic={this.state.isPublic}
                    eventId={this.context.event_id}>

                    {this.state.rightColVisible ?
                        <div className={RightColumnSponsoredClass}>
                            <div className="right-panel-logo-company-container-fixed">



                                {this.state.isSponsored
                                    ? <div className={"logo-company-container-econgress-room " + this.context.event_code}>
                                        {this.state.sponsor_logo
                                            ? <img alt="" src={this.state.sponsor_logo} />
                                            : this.state.sponsor}
                                    </div>
                                    :

                                    this.context.event_settings.event_id == "342" ?
                                        <div className="logo-company-container-econgress-room">
                                            <img alt="" src="https://www.emma.events/static/virtualvenue/piediabetico2021/room/piediabetico2021-chat-fixed-logo.jpg" />
                                        </div>

                                        : this.context.event_settings.event_id == "378" ?
                                            <div className="logo-company-container-econgress-room">
                                                <img alt="" src="https://www.emma.events/static/virtualvenue/2congresocovid/sponsors/covid-sponsor.jpg" />
                                            </div>

                                            : this.context.event_settings.event_id == "372" ?
                                                <div className="logo-company-container-econgress-room 1reunionvirtualareas2021">
                                                    <img alt="" src="https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/rooms/sponsor-1reunionvirtualareas2021.jpg" />
                                                </div>

                                                : this.context.event_settings.event_id == "373" ?
                                                    <div className="logo-company-container-econgress-room 1reunionvirtualareas2021">
                                                        <img alt="" src="https://www.emma.events/static/virtualvenue/2reunionvirtualareas2021/rooms/sponsor-2reunionvirtualareas2021.jpg" />
                                                    </div>

                                                    : this.context.event_settings.event_id == "376" ?
                                                        <div className="logo-company-container-econgress-room semnim2021">
                                                            <img alt="" src="https://www.emma.events/static/virtualvenue/semnim2021/room/semnim2021-sponsor.jpg" />
                                                        </div>

                                                        : this.context.event_settings.event_id == "397" ?
                                                            <div className="logo-company-container-econgress-room isin2021">
                                                                <img alt="" src="https://www.emma.events/static/virtualvenue/isin2021/room/room-chat-header-isin2021.png" />
                                                            </div>

                                                            : this.context.event_settings.event_id == "320" ?
                                                                <div className="logo-company-container-econgress-room">
                                                                    <img alt="" src="https://www.emma.events/static/virtualvenue/cuenca2021/room/cuenca2021-room-sponsor.png" />
                                                                </div>

                                                                : this.context.event_settings.event_id == "379" ?
                                                                    <div className="logo-company-container-econgress-room">
                                                                        <img alt="" src="https://www.emma.events/static/virtualvenue/neumomadrid2021/room/room-chat-logo-neumo2021.jpg" />
                                                                    </div>

                                                                    : this.context.event_settings.event_id == "424" ?
                                                                        <div className="logo-company-container-econgress-room">
                                                                            <img alt="" src="https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/rooms/3reunionvirtualareas2022-room-chat-logo.jpg" />
                                                                        </div>

                                                                        : this.context.event_settings.event_id == "425" ?
                                                                            <div className="logo-company-container-econgress-room">
                                                                                <img alt="" src="https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/rooms/4reunionvirtualareas2022-room-chat-logo.jpg" />
                                                                            </div>

                                                                            :
                                                                            null

                                }


                            </div>






                            <div className="right-panel-econgress-room-container">


                                {this.state.votingLinks !== undefined && this.state.votingLinks.length > 0
                                    ? <div className="logo-company-container-econgress-room">
                                        <a className="session-user-voting-button" href={this.state.votingLinks[0].link} target="_blank">VOTAR</a>
                                    </div>
                                    : null
                                }

                                {this.state.voting ? <Voting userName={this.state.user.name}
                                    userSurname={this.state.user.surname}
                                    userPhoto={this.state.user.photo}
                                    sessionId={this.state.sessionId}
                                    classBlock={classBlock}></Voting>
                                    : null}
                                {this.state.chat ? <Chat userName={this.state.user.name}
                                    userSurname={this.state.user.surname}
                                    userPhoto={this.state.user.photo}
                                    sessionId={this.state.sessionId}
                                    classBlock={classBlock}
                                    moderated={this.state.chat_moderated}
                                    isModerator={this.state.user_is_moderator}></Chat>
                                    : null}
                                {this.state.qa ? <QA></QA> : null}
                                {this.state.survey ? <Survey></Survey> : null}
                            </div>
                        </div> : null}


                    <div className="chat-mobile">chating</div>

                </WithPermission>

                <div className="clear"></div>

                <div onClick={this.switchRightColumnHandler} className={classOpenCloseRightColumn} ></div>



                {this.state.navBarVisible ?
                    <NavBar sessionDay={this.state.day}
                        sessionRoom={this.state.room_id}
                        changeSession={this.onSessionChangeHandler}
                        sessionId={this.state.sessionId} >
                    </NavBar>
                    : null}

                {this.context.event_id !== "4" ?
                    <div className={BottomSessionsBtnClass}>
                        <div className={classOpenCloseNavBar} onClick={this.switchNavBarHandler}>
                            <div className="bottom-open-close-videos-slider-text">{this.props.t('room_sessiones')} </div>
                        </div>
                    </div>
                    : null}


                {CookiesPolicyPopUp}
                {LegalNoticePopUp}
                {TermsConditionsPopUp}
                {PrivacyPolicyPopUp}
                <LegalLinks onPrivacyPolicyOpen={this.onPrivacyPolicyOpenHandler} onTermsConditionsOpen={this.onTermsConditionsOpenHandler} onLegalNoticeOpen={this.onLegalNoticeOpenHandler} onCookiesPolicyOpen={this.onCookiesPolicyOpenHandler} event_id={this.context.event_settings.event_id}></LegalLinks>


            </React.Fragment>
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = state => {
    return {
        forceLogout: state.global.forceLogout
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectSocket: (token) => dispatch(connectSocket(token)),
        onDisconnectSocket: () => dispatch(disconnectSocket()),
        onWatch: (data) => dispatch(logWatch(data)),
        onPathname: (data) => dispatch(logPathname(data))
    }
}

const dateAdd = (date, interval, units) => {
    if (!(date instanceof Date))
        return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
        case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
        case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
        case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
        case 'week': ret.setDate(ret.getDate() + 7 * units); break;
        case 'day': ret.setDate(ret.getDate() + units); break;
        case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
        case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
        case 'second': ret.setTime(ret.getTime() + units * 1000); break;
        default: ret = undefined; break;
    }
    return ret;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Room)));