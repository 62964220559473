import React from 'react';

function Welcome(props) {
    
    const storedEventCode = localStorage.getItem('e-congress.events.history');    // check the last accessed event
    
    try {
        if( storedEventCode !== undefined && storedEventCode !== "" & storedEventCode !== null)   {
            props.history.push('/' + storedEventCode);    
        } else {
            //enviamos para a seacv para que as pessoas consigam contnuar a entrar na seacv por defeito
            props.history.push('/seacv2020');
        }
    } catch (error) {
        console.log(error);
    }

    document.getElementById('root').style.backgroundImage = 'url("/img/login-background-3.jpg")';

    return (
        <div></div>
    );
}

export default Welcome;